import styled                   from 'styled-components';
import { LoginSignupFooter }    from '@components/LoginSignupFooter';
import { IInvitationLinkData }  from '@modules/Details/models/invitation';
import { SignupCTAs }           from './components/SignupCTAs.tsx';
import { SignupForm }           from './components/SignupForm';
import { SignupFormThirdParty } from './components/SignupFormThirdParty';
import { SignupLocation }       from './components/SignupLocation';
import { SignupNotifications }  from './components/SignupNotifications';

interface ISignupPageProps {
  signupLocationContainerVisible       : boolean;
  signupLocationVisible                : boolean;
  allowMap                             : boolean;
  signupFormContainerVisible           : boolean;
  signupFormVisible                    : boolean;
  signupFormThirdPartyVisible          : boolean;
  signupNotificationsVisible           : boolean;
  thirdPartyDefaultFirst               : string;
  thirdPartyDefaultLast                : string;
  handleSignupClick                    : () => void;
  showSignupLocation                   : () => void;
  showSignupForm                       : () => void;
  showSignupNotifications              : () => void;
  focusPreemHandleInput?               : boolean;
  focusFirstNameInput?                 : boolean;
  thirdPartySignupAndRedirect          : (providerName: string) => void;
  ctasContainerVisible                 : boolean;
  ctasVisible                          : boolean;
  taglineVisible                       : boolean;
  bgImageDimmed                        : boolean;
  bgImageVisible                       : boolean;
  invitationLinkData?                  : IInvitationLinkData;
}

export const SignupPage = ({
  signupLocationContainerVisible,
  signupLocationVisible,
  allowMap,
  handleSignupClick,
  signupFormContainerVisible,
  showSignupLocation,
  signupFormVisible,
  thirdPartyDefaultFirst,
  thirdPartyDefaultLast,
  signupFormThirdPartyVisible,
  signupNotificationsVisible,
  showSignupNotifications,
  focusFirstNameInput,
  showSignupForm,
  thirdPartySignupAndRedirect,
  ctasContainerVisible,
  ctasVisible,
  taglineVisible,
  bgImageDimmed,
  bgImageVisible,
  invitationLinkData
}: ISignupPageProps) => (
  <>
    <SignupPage.Content>
      {signupFormContainerVisible && (
        <SignupPage.Container>
          <SignupForm
            invitationLinkData = {invitationLinkData}
            signupFormVisible  = {signupFormVisible}
            onClickNext        = {showSignupLocation}
          />
        </SignupPage.Container>
      )}

      {signupLocationContainerVisible && (
        <SignupPage.Container>
          <SignupPage.Location isVisible={signupLocationVisible}>
            {allowMap && (
              <SignupLocation
                allowMap
                onSignupClick = {showSignupNotifications}
              />
            )}
          </SignupPage.Location>
        </SignupPage.Container>
      )}

      {signupNotificationsVisible && (
        <SignupPage.Container>
          <SignupNotifications
            signupNotificationsVisible = {signupNotificationsVisible}
            onClickNext                = {handleSignupClick}
          />
        </SignupPage.Container>
      )}

      {signupFormThirdPartyVisible && (
        <SignupFormThirdParty
          defaultFirstName    = {thirdPartyDefaultFirst}
          defaultLastName     = {thirdPartyDefaultLast}
          onClickNext         = {showSignupLocation}
          focusFirstNameInput = {focusFirstNameInput}
        />
      )}
    </SignupPage.Content>

      <SignupCTAs
        handleEmailClick     = {showSignupForm}
        ctasContainerVisible = {ctasContainerVisible}
        ctasVisible          = {ctasVisible}
        handleAppleClick     = {() => {
          thirdPartySignupAndRedirect("apple");
        }}
        handleGoogleClick    = {() => {
          thirdPartySignupAndRedirect("google");
        }}
      />

      <SignupPage.TagLine isVisible={taglineVisible} />

      <SignupPage.Background bgImageDimmed={bgImageDimmed} bgImageVisible={bgImageVisible} />

    <SignupPage.Gradient />
    <LoginSignupFooter />
  </>
);

SignupPage.Content = styled.div`
  position        : absolute;
  left            : 0%;
  top             : 0%;
  right           : 0%;
  bottom          : 0%;
  z-index         : 500;
  display         : flex;
  flex-direction  : column;
  justify-content : center;
  align-items     : center;

  @media screen and (min-width: 1920px) {
    position        : absolute;
    left            : 0%;
    top             : 0%;
    right           : 0%;
    bottom          : 0%;
    z-index         : 500;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    align-items     : center;
  }

  @media screen and (max-width: 767px) {
    position      : relative;
    margin-top    : 48px;
    margin-bottom : 48px;
  }
`;

SignupPage.Container = styled.div``;

SignupPage.Location = styled.div`
  display        : flex;
  flex-direction : column;
  align-items    : stretch;
  opacity        : 0;
  position       : relative;
  width          : 400px;
  z-index        : 900;
  transition     : opacity 300ms ease-in;
  opacity        : ${({ isVisible } : { isVisible : boolean }) => isVisible ? 1 : 0 };

  @media screen and (min-width: 1920px) {
    position: relative;
  }
  
  @media screen and (max-width: 479px) {
    width: 280px;
  }
`;

SignupPage.Gradient = styled.div`
  position        : fixed;
  left            : 0%;
  top             : 0%;
  right           : 0%;
  bottom          : 0%;
  z-index         : 50;
  background-image: radial-gradient(
    circle farthest-corner at 0% 0%,
    ${({ theme }) => theme.colors.primary.rubineLight},
    ${({ theme }) => theme.colors.neutralBlue[400]}
  );
  opacity: 1;
`;

SignupPage.TagLine = styled.div`
  opacity             : 0;
  position            : absolute;
  left                : 0%;
  top                 : 0%;
  right               : 0%;
  bottom              : 0%;
  z-index             : 100;
  background-image    : url('/static/media/Tagline.907cc06d.svg');
  background-position : 50% 50%;
  background-size     : contain;
  background-repeat   : no-repeat;
  transition          : opacity 300ms ease-in;
  opacity             : ${({ isVisible }: { isVisible: boolean }) => isVisible ? 0.24 : 0};

  @media screen and (max-width: 767px) {
    position            : relative;
    left                : 0%;
    top                 : 0%;
    right               : 0%;
    bottom              : 0%;
    height              : 100px;
    margin-top          : 56px;
    background-position : 50% 25%;
    width               : 100%;
  }

  @media screen and (max-width: 479px) {
    position            : absolute;
    left                : 0%;
    top                 : auto;
    right               : 0%;
    bottom              : 80px;
    height              : 100px;
    background-position : 50% 30%;
  }
`;

SignupPage.Background = styled.div`
  opacity             : 1;
  position            : fixed;
  left                : 0%;
  top                 : 0%;
  right               : 0%;
  bottom              : 0%;
  z-index             : 75;
  background-image    : url('static/media/Sign-Up-BG.012065c2.jpg');
  background-position : 50% 50%;
  background-size     : cover;
  background-repeat   : no-repeat;
  transition          : opacity 300ms ease-in;
  opacity             : ${({ bgImageDimmed, bgImageVisible} : { bgImageDimmed: boolean; bgImageVisible: boolean; }) => !bgImageDimmed &&  bgImageVisible ? 1: 0};
  ${({ bgImageDimmed} : { bgImageDimmed: boolean }) => bgImageDimmed && 'opacity: 0.24'};
`;
