import styled, { css }    from 'styled-components';
import MuiPhoneNumber     from 'material-ui-phone-number';
import { TextFieldProps } from '@mui/material';
import {
  scrimBlack,
  scrimWhite,
}                         from '@styles/theme';

type TPhoneInputProps = TextFieldProps & {
  onChange?: (value: string) => void;
  variant? : TextFieldProps['variant'];
  dark?    : boolean;
};

export const PhoneInput = ({
  variant,
  onChange,
  dark,
  ...props
}: TPhoneInputProps) => (
  <PhoneInput.Input
    {...props}
    variant        = {variant ?? 'filled'}
    defaultCountry = "us"
    onChange       = {(value) => onChange?.(value as string)}
    $dark          = {!!dark}
    $disabled      = {!!props.disabled}
    disableAreaCodes
  />
);

PhoneInput.Input = styled(MuiPhoneNumber)<{ $dark: boolean; $disabled: boolean; }>`
  width    : 318px;
  max-width: 100%;
  position : relative;

  ${({ $dark }) => $dark && css`
    label, label.Mui-disabled {
      color: ${scrimWhite(0.64)};
    }

    label.Mui-focused {
      opacity: 1;
      color: ${({ theme: { colors } }) => colors.type.light};
    }

    .MuiInputBase-root, .MuiInputBase-root.Mui-disabled {
      background-color: ${scrimBlack(0.16)} !important;

      input {
        background-color: transparent;
        color: ${scrimWhite(0.64)};
        -webkit-text-fill-color: unset;
      }
    }

    .Mui-focused.MuiInputBase-root {
      outline         : none;
      border          : none;
      box-shadow      : 0 0 0 2px ${({ theme: { colors } }) => colors.primary.white};
      background-color: ${scrimWhite(0.04)};

      input {
        color: ${({ theme: { colors } }) => colors.primary.white};
      }
    }
  `};

  ${({ $disabled }) => $disabled && css`
    button {
      pointer-events: none;
    }
  `};

  .MuiInputAdornment-root {
    margin-left: 5px;

    .MuiPhoneNumber-flagButton {
      min-width : 25px;
      min-height: 25px;
      width     : min-content;
    }

    svg {
      overflow: visible;
      max-width: 100%;
      max-height: 100%;
    }
  }
`;
