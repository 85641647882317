import styled from 'styled-components';

export const UVIcon = styled.div<{
  uv: number;
}>`
  align-items        : center;
  background-position: 0% 50%;
  background-repeat  : no-repeat;
  background-size    : cover;
  display            : flex;
  height             : 24px;
  flex               : 0 0 auto;
  width              : 24px;
  margin-right       : 5px;
  background-image   : ${({ uv = 0 }) =>
    `url(${
      require(`@images/Icon-Weather-UV-${uv > 4 ? 4 : uv}-Black.svg`)
    })`};
`;
