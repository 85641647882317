import { useEffect }         from 'react';
import { useHistory }        from 'react-router';
import {
  IInvitationLinkData,
  RIDE_INVITATION_DATA_KEY
}                            from '@modules/Details/models/invitation';
import { ContentHero }       from '@modules/Calendar/components/ContentHero';
import { WeekViewer }        from '@modules/Calendar/components/WeekViewer';
import { useSessionStorage } from '@utils/hooks/useSessionStorage';
import { 
  FROM_PATH_KEY, 
  withAuth
}                            from '@utils/HOCs/withAuth';
import { callApi }           from '@utils/apiCaller';
import { apiOrigin }         from '@config/api_config';

export const getGenLocationById = async (genLocationId: string) => {
  const { data } = await callApi(`${apiOrigin}/api/v1/genlocations/${genLocationId}`);

  return data;
};

const CalendarBase = () => {
  const { push }                = useHistory();
  const { getItem, removeItem } = useSessionStorage();

  useEffect(
    () => {
      const invitationData = getItem<IInvitationLinkData>(RIDE_INVITATION_DATA_KEY);
      const fromPathname   = getItem<{ pathname: string; }>(FROM_PATH_KEY);

      if (invitationData) {
        const { plannedRideId, eventDate } = invitationData;

        if (plannedRideId && eventDate) {
          const rideLink = `/ride/${plannedRideId}/${eventDate}/overview`;

          push(rideLink);
        }
        
        if (plannedRideId) {
          const rideLink = `/ride/${plannedRideId}/overview`;

          push(rideLink);
        }

        removeItem(RIDE_INVITATION_DATA_KEY);

        return;
      }

      if (fromPathname) {
        push(fromPathname.pathname);

        removeItem(FROM_PATH_KEY);
      }
    },
    [getItem, push, removeItem],
  );

  return (
    <>
      <ContentHero />
      <WeekViewer />
    </>
  );
}

export const Calendar = withAuth(CalendarBase);
