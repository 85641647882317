import styled, { css }  from 'styled-components';
import { isMobile }     from 'react-device-detect';
import { Grid, Button } from '@mui/material';
import { scrimBlack }   from '@styles/theme';
import { IApp }         from '@modules/Profile/containers/AppsContainer';

interface IAppConnectorProps extends IApp {
  showBottomBorder?: boolean;
}

export const AppConnector = ({
  isConnected,
  Logo,
  title,
  handleDisconnect,
  handleConnect,
  actions,
  showBottomBorder = false
}: IAppConnectorProps) => (
  <AppConnector.Wrapper
    container
    $showBottomBorder = {showBottomBorder}
    spacing           = {4}
    justifyContent    = "center"
    p                 = "20px 0"
  >
    <Grid item xs="auto" alignSelf="center">
      <AppConnector.LogoContainer connected={isConnected} $showBottomBorder={showBottomBorder}>
        {Logo}
      </AppConnector.LogoContainer>
    </Grid>

    <Grid item xs={12} sm>
      <AppConnector.InfoContainer container justifyContent="center" direction="column">
        <Grid item xs={12} sm alignSelf={isMobile ? 'center' : 'flex-start'}>
          <p><b>{title}</b></p>
        </Grid>

        <Grid item xs={12} sm alignSelf={isMobile ? 'center' : 'flex-start'}>
          <AppConnector.Actions>
            <ul>
              {actions?.map((action, index) => <li key={index}>{action}</li>)}
            </ul>
          </AppConnector.Actions>
        </Grid>

      </AppConnector.InfoContainer>
    </Grid>

    <Grid item xs="auto" md={2} alignSelf="center">
      {isConnected ? (
        <AppConnector.Button
          variant = "outlined"
          onClick = {handleDisconnect}
        >
          Disconnect
        </AppConnector.Button>
      ) : (
        <AppConnector.Button
          variant = "contained"
          onClick = {handleConnect}
        >
          Connect and Sync
        </AppConnector.Button>
      )}
    </Grid>
  </AppConnector.Wrapper>
);

AppConnector.Wrapper = styled(Grid)<{ $showBottomBorder: boolean }>`
  ${({ $showBottomBorder }) => $showBottomBorder && `
    &.MuiGrid-root {
      border-bottom: 1px solid ${ scrimBlack(0.08)}};
    }`};

  padding-top   : 40px;
  padding-bottom: 40px;
`;

AppConnector.LogoContainer = styled.div<{ $showBottomBorder: boolean, connected?: boolean }>`
  background-color: ${scrimBlack(0.04)};
  border-radius   : 50%;
  display         : flex;
  justify-content : center;
  align-items     : center;
  height          : 160px;
  width           : 160px;
  max-width       : 160px;
  max-height      : 160px;
  overflow        : hidden;
  && {
    padding: 0; 
  }

  ${({ $showBottomBorder }) => $showBottomBorder && `padding: 53px 25px`};

  ${({connected}) => connected && css`
    background-color: white;
  `}
`;

AppConnector.InfoContainer = styled(Grid)`
  text-align: left;

  .MuiGrid-item:first-child {
    font-size: ${({theme: {fontSizes: { l }}}) => l};
  }

  p {
    padding-top: 20px;
  }

  ${({theme: {mediaQueries: { sm }}}) => sm} {
    .MuiGrid-item:first-child {
      font-size: 22px;
    }

    p {
      padding-top: 0;
    }
  }
`;

AppConnector.Description = styled.div`
  text-align: center;

  ${({theme: {mediaQueries: { sm }}}) => sm} {
    text-align: left;
    font-size : ${({theme: {fontSizes: { l }}}) => l};
  }
`;

AppConnector.Description = styled.div`
  text-align: center;

  ${({theme: {mediaQueries: { sm }}}) => sm} {
    text-align: left;
    font-size : ${({theme: {fontSizes: { l }}}) => l};
  }
`;

AppConnector.Button = styled(Button)`
  &.MuiButton-root {
    margin: 10px 0;
  }
`;

AppConnector.Actions = styled.div`
  .MuiCheckbox-root {
    margin-left: -9px;
  }

  ul {
    padding-left : 20px;
    margin-top   : 0;
    margin-bottom: 0;

    ${({theme: {fontSizes: { l }, colors: {type: { dark }}}}) => css`
      font-size: ${ l };
      color    : ${ dark };
    `}
  }

  li {
    margin-bottom: 10px;
  }
`;
