import {
  Switch,
  Route,
  useLocation,
  Redirect,
  RouteComponentProps
}                                            from 'react-router-dom';
import styled, {
  ThemeProvider as StyledThemeProvider
}                                            from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import './utils/yupMethods';

import { Footer }                 from '@components/Footer';
import { Header }                 from '@components/Header';
import { Notification }           from '@components/Notification';
import { RideDetailsContainer }   from '@modules/Details/containers/RideDetails';
import { RouteDetailsContainer }  from '@modules/Details/containers/RouteDetails';
import { FourOhFour }             from '@components/FourOhFour';
import { Landing }                from '@modules/Landing';
import { Login }                  from '@modules/Auth/Login';
import { Signup }                 from '@modules/Auth/Signup';
import { Profile }                from '@modules/Profile';
import { Privacy }                from '@modules/Privacy';
import { Calendar }               from '@modules/Calendar';
import { PublicProfileContainer } from '@modules/Profile/containers/PublicProfileContainer';

import { theme }                      from '@styles/theme';
import { muiTheme }                   from '@styles/muiTheme';
import { BaseStyles }                 from '@styles/baseStyles';

import { showFeed }                   from '@config/api_config';
import { useAuth }                    from '@modules/Auth/contexts/AuthContext';
import { RecoveryPassword }           from '@modules/Auth/RecoveryPassword';
import { CTAContainer }               from '@modules/CreateModal/container';
import { Feed }                       from '@modules/Feed';
import { RedirectInvitation }         from '@components/RedirectInvitation';
import { withAuth }                   from '@utils/HOCs/withAuth';

export const App = () => {
  const { currentUser } = useAuth();
  const location        = useLocation();

  return (
    <MuiThemeProvider theme={muiTheme}>
      <StyledThemeProvider theme={theme}>
        <BaseStyles />
        <App.OverlayContainer className="overlay-container">
          <App.Container className="App">
            <Header />
            <Switch>
              {/* Public Routes */}
              <Route path="/landing"                component={Landing} />
              <Route path="/login"                  component={Login} />
              <Route path="/signup"                 component={Signup} />
              <Route path="/update-forgot-password" component={RecoveryPassword} />

              <Route
                exact
                path      = "/calendar"
                component = {() => <Redirect to="/" />}
              />

              <Route
                path      = "/privacy"
                component = {Privacy}
              />

              <Route
                exact
                path      = "/shared/ride/:linkUuid/:tab"
                component = {RideDetailsContainer}
              />

              <Route
                exact
                path      = "/ride/:rideId/:rideDate/:tab"
                component = {RideDetailsContainer}
              />

              <Route
                exact
                path      = "/ride/:rideId/:tab"
                component = {RideDetailsContainer}
              />

              {/* Routes with auth */}
              <Route exact path="/"                      component = {Calendar} />
              <Route exact path="/cta/:tab"              component = {CTAContainer} />
              <Route path="/profile"                     component = {Profile} />
              <Route exact path="/route/:routeId"        component = {withAuth(RouteDetailsContainer)} />
              <Route path="/user/:publicProfileUsername" component = {withAuth(PublicProfileContainer)} />
              <Route path="/rides"                       component = {RedirectInvitation} />

              {/* Feature flags */}
              {showFeed === 'true' && <Route exact path="/feed" component = {Feed} />}

              {/* Route for Garmin authentication callback. Only for local development */}
              <Route exact path="/callback" render={(props: RouteComponentProps) => (
                <Redirect to={{
                  pathname: "/profile/apps",
                  search  : props.location.search
                }} />
              )} />

              {/* 404 Route page */}
              <Route component={FourOhFour} />
            </Switch>
          </App.Container>
          <Notification />

          {(currentUser || location.pathname === "/landing") && <Footer />}
        </App.OverlayContainer>
      </StyledThemeProvider>
    </MuiThemeProvider>
  );
};

App.OverlayContainer = styled.div`
  height        : 100%;
  display       : flex;
  flex-direction: column;
`;

App.Container = styled.div`
  flex: 1 0 auto;
`;
