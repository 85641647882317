import {
  useCallback,
  useState,
}                         from 'react';
import { useDebounce }    from 'use-debounce';
import { TOrderSortType } from '@models/types';

interface IItem extends Object { id: string }

export interface IUseTableReturn {
  order?               : TOrderSortType;
  orderBy?             : string;
  onSort               : (orderBy: string, order: TOrderSortType) => void;
  onSelectRow          : (id: string) => void;
  selectedItems        : string[];
  setSelectedItems     : (items: string[]) => void;
  onSelectAll          : (items: IItem[]) => void;
  pageSize             : number;
  setPageSize          : (pageSize: number) => void;
  pageNumber           : number;
  setPageNumber        : (page: number) => void;
  setSearchText        : (text: string) => void;
  searchText           : string;
  debouncedSearchParam : string;
}

type TPaginationInitialValues = Partial<Pick<IUseTableReturn,
  'order' | 'orderBy' | 'pageSize' | 'pageNumber' | 'searchText' | 'selectedItems'>>

export const useTable = (initialValues?: TPaginationInitialValues): IUseTableReturn => {
  const [pageSize, setPageSize]           = useState<number>(initialValues?.pageSize ?? 20);
  const [pageNumber, setPageNumber]       = useState<number>(initialValues?.pageNumber ?? 0);
  const [order, setOrder]                 = useState<TOrderSortType>(initialValues?.order ?? 'desc');
  const [orderBy, setOrderBy]             = useState<string>(initialValues?.orderBy ?? '');
  const [selectedItems, setSelectedItems] = useState<string[]>(initialValues?.selectedItems ?? []);
  const [searchText, setSearchText]       = useState<string>(initialValues?.searchText ?? '');
  const [debouncedSearchParam]            = useDebounce(searchText.trim(), 250);

  const onSort = useCallback(
    (orderBy: string, order: TOrderSortType) => {
      setOrderBy(orderBy);
      setOrder(order);
      setPageNumber(0);
    },
    []
  );

  const onSelectRow = useCallback(
    (id: string) => {
      const isSelected = selectedItems.includes(id);

      if (isSelected) {
        return setSelectedItems(items => items.filter(item => item !== id));
      }

      return setSelectedItems(items => [...items, id]);
    },
    [selectedItems]
  );

  const onSelectAll = useCallback(
    (items: IItem[]) => {
      const isEmpty = !items.length;

      if (isEmpty) {
        return setSelectedItems([]);
      }

      const ids = items.map(item => item.id);

      return setSelectedItems([...ids]);
    },
    []
  );

  return {
    order,
    orderBy,
    onSort,
    onSelectRow,
    selectedItems,
    setSelectedItems,
    onSelectAll,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    setSearchText,
    searchText,
    debouncedSearchParam,
  }
}
