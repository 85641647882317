import styled                                     from 'styled-components';
import { Button, Grid, Modal }                    from '@mui/material';
import { ReactComponent as GreyChevronRightIcon } from '../../images/Icon-Chevron-R-Grey.svg';
import {  scrimWhite }                            from '../../styles/theme';
import { ModalContent }                           from '../Modal';

interface IConfirmationModalProps {
  onCancel               : () => void;
  title                  : string;
  onSubmit?              : () => void;
  isSubmitButtonDisabled?: boolean;
  content?               : JSX.Element;
  description?           : string;
  submitButtonText?      : string;
  cancelButtonText?      : string;
}

export const ConfirmationModal = ({
  onSubmit,
  onCancel,
  title,
  description,
  content,
  submitButtonText       = 'Submit',
  cancelButtonText       = 'Cancel',
  isSubmitButtonDisabled = false
}: IConfirmationModalProps) => (
  <Modal open>
    <ConfirmationModal.Content>
      <Grid
        container
        direction      = "column"
        alignItems     = "center"
        sx             = {{ rowGap: { xs: 1, sm: 4 }}}
        justifyContent = "center"
      >
        <Grid item container justifyContent="center">
          <Grid item xs={12}>
            <ConfirmationModal.Title>{title}</ConfirmationModal.Title>
          </Grid>

          <Grid item xs={12}>
            <ConfirmationModal.Description>
              {description}
            </ConfirmationModal.Description>
          </Grid>
        </Grid>

        {content && <Grid item container justifyContent="center">
          <Grid item>
            <ConfirmationModal.Description>
              {content}
            </ConfirmationModal.Description>
          </Grid>
        </Grid>}

        {onSubmit && <Grid item>
          <Button
            variant  = "contained"
            size     = "large"
            onClick  = {onSubmit}
            type     = "button"
            disabled = {isSubmitButtonDisabled}
          >
            {submitButtonText}
          </Button>
        </Grid>}

        <Grid item>
          <ConfirmationModal.LeavePageBtn
            endIcon = {<GreyChevronRightIcon />}
            onClick = {onCancel}
          >
            {cancelButtonText}
          </ConfirmationModal.LeavePageBtn>
        </Grid>
      </Grid>
    </ConfirmationModal.Content>
  </Modal>
);

ConfirmationModal.Content = styled(ModalContent)`
  width           : 550px;
  background-color: ${({theme: {colors: {type: {dark}}}}) => dark};
  border-radius   : 16px;
  padding         : 30px;
  max-width       : 60vw;
  overflow        : auto;

  ${({theme: {mediaQueries: {xs}}}) => xs } {
    font-size  : ${({ theme: {fontSizes: {xl}}}) => xl};
    line-height: 28px;
    padding    : 80px 60px;
  }
`;

ConfirmationModal.Title = styled.h3`
  text-align: center;
  color     : ${({theme: {colors: {primary: {white}}}}) => white};
  font-size : ${({ theme: {fontSizes: {l}}}) => l};
  width     : 100%;

  ${({theme: {mediaQueries: {sm}}}) => sm } {
    font-size   : 22px;
  }
`;

ConfirmationModal.Description = styled.p`
  color     : ${({theme: {colors: {type: {light}}}}) => light};
  font-size : ${({theme: {fontSizes: {m}}}) => m};
  text-align: center;
  line-height: 22px;

  ${({theme: {mediaQueries: {sm}}}) => sm } {
    font-size : ${({theme: {fontSizes: {l}}}) => l};
    line-height: 25px;
  }
`;

ConfirmationModal.LeavePageBtn = styled(Button)`
  &.MuiButton-root{
    color: ${scrimWhite(0.64)};
    padding: 0;

    ${({theme: {mediaQueries: {sm}}}) => sm } {
      padding: 8px 25px;
    }
  }

  &.MuiButton-root:hover {
    background-color: unset;
    color           : ${({theme: {colors: {primary: {white}}}}) => white};
  }

  &.MuiButton-root:hover path {
    fill: ${({theme: {colors: {primary: {white}}}}) => white};
  }
`;
