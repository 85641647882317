import axios, {
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler,
  Method,
} from 'axios';
// import { Notification } from "../components/Notification";

export const callApi = <T = any, P = any>(
  url     : string,
  method  : Method = "GET",
  data?   : P,
  config? : AxiosRequestConfig & { requestWithoutAuth?: boolean }
  ): Promise<AxiosResponse<T>> => {
  const axiosConfig: AxiosRequestConfig = {
    url,
    method,
    ...config,
  };

  if (data) {
    if (method.toUpperCase() === "GET") {
      axiosConfig.params = {
        ...config?.params,
        ...data,
      };
    } else {
      axiosConfig.data = data;
    }
  }

  /**
   * removing notifications for MVP, leaving the code in case we want them back in a hurry
   *
  request
    .then((response) => {
      if (method === "POST" || method === "PUT" || method === "DELETE") {
        Notification.enqueueSnackbar(
          "The operation was successful",
          "success"
        );
      }
    })
    .catch((error: AxiosError) => {
      TODO: Add error for Non-GET queries
      const message = error.response?.data.message;
      
      Notification.enqueueSnackbar(message || "Something went wrong", "error");
    });
    */
  return axios(axiosConfig) as AxiosPromise<T> & { cancel: Canceler };
};
