import { useMemo }                     from 'react';
import { useHistory }                  from 'react-router';
import {
  Button,
  Grid,
  IconButton,
  Link,
}                                      from '@mui/material';
import styled, { css }                 from 'styled-components';
import ShareIcon                       from '@mui/icons-material/Share';
import { scrimBlack }                  from '@styles/theme';
import { Avatar }                      from '@components/Avatar';
import { Logo }                        from '@components/Header/components/Logo';
import { RespondDropdown }             from '@components/RespondDropdown';
import { profilePicturesHost }         from '@config/api_config';
import { ReactComponent as CloseIcon } from '@images/Icon-Close-Lt-Grey.svg';
import { INVITATION_STATUS }           from '@modules/Details/models/invitation';
import { IUserBaseInfo }               from '@modules/Profile/models/UserInfo';
import { useDetails }                  from '@modules/Details/context';
import { ECtaTab }                     from '@modules/CreateModal/models/CreateModalContent';
import { useCta }                      from '@utils/hooks/useCta';

interface IHeaderProps {
  onClose                : () => void;
  isRideStarted?         : boolean;
  isOrganizer?           : boolean;
  setCancelModalStatus?  : (isOpen: boolean) => void;
  changeAttendanceStatus?: (status: INVITATION_STATUS) => void;
  isPreview?             : boolean;
  hide?                  : boolean;
  hideShare?             : boolean;
}

export const Header = ({
  isRideStarted,
  isOrganizer,
  setCancelModalStatus,
  changeAttendanceStatus,
  hide,
  onClose,
  hideShare = false,
}: IHeaderProps) => {
  const { push }        = useHistory<{ prevState: string }>();
  const { openCta }     = useCta();
  const {
    rideDetails,
    nearestRideDetails,
  } = useDetails();

  const CancelRideButton = useMemo(
    () => (
      isOrganizer && !isRideStarted ? (
        <Button
          color   = "secondary"
          variant = "contained"
          sx      = {{ width: 'max-content' }}
          onClick = {() => setCancelModalStatus?.(true)}
        >
          Cancel Ride
        </Button>
      ) : null
    ),
    [isOrganizer, isRideStarted, setCancelModalStatus]
  );

  const EditRideButton = useMemo(
    () => (
      isOrganizer && !isRideStarted ? (
        <Button
          color   = "secondary"
          variant = "contained"
          onClick = {() => openCta({ tab: ECtaTab.EDIT_RIDE, urlParams: { rideId: rideDetails?.id } })}
        >
          Edit
        </Button>
      ) : null
    ),
    [isOrganizer, isRideStarted, openCta, rideDetails?.id]
  );

  const ShareButton = useMemo(
    () => !hideShare ? (
      <IconButton
        onClick = {() => openCta({ tab: ECtaTab.SHARE_RIDE, urlParams: { id: nearestRideDetails?.id } })}
      >
        <ShareIcon />
      </IconButton>
    ) : null,
    [
      hideShare,
      openCta,
      nearestRideDetails?.id
    ]
  );

  const RideAttendanceDropdown = useMemo(
    () => (
      nearestRideDetails && !isRideStarted ? (
        <RespondDropdown
          initialResponse = {nearestRideDetails?.invitationStatus}
          label           = "RSVP"
          onChange        = {changeAttendanceStatus}
        />
      ) : null
    ),
    [changeAttendanceStatus, isRideStarted, nearestRideDetails]
  );

  return (
    <>
      <Header.Wrapper
        container
        height         = "80px"
        alignContent   = "center"
        hidden         = {hide}
        justifyContent = "space-between"
      >
        <Header.UserContainer item container xs="auto" alignItems="end" columnSpacing="10px" flexWrap="nowrap">
          {/* {organizerInfo && (
          // {!isPreview && organizerInfo && (
            <>
              <Grid item>
                <Avatar
                  name     = {organizerInfo?.lastName}
                  size     = {40}
                  photoUrl = {
                    organizerInfo?.profilePictureUrl &&
                    `${profilePicturesHost}${organizerInfo?.profilePictureUrl}`
                  }
                />
              </Grid>

              <Grid item>
                <Link onClick = {() => push(`/user/${organizerInfo?.username}`)}>
                  You&apos;ve been invited to a ride organized by {`${organizerInfo?.firstName} ${organizerInfo?.lastName}`} on XXX at YYY.
                </Link>

                <Header.UserInfo>
                  <p>
                    {title ? (
                      <b>{title}</b>
                    ) : (
                      <b>{`${organizerInfo?.lastName} ${organizerInfo?.firstName}`}</b>
                    )}
                  </p>
                </Header.UserInfo>
              </Grid>
            </>
          )} */}

          
            <Header.PreviewInfoContainer item>
              <Logo path="/" />
            </Header.PreviewInfoContainer>
          
        </Header.UserContainer>

        <Grid item container xs="auto" alignItems="center" spacing={2}>
          <Grid item sx={{ display: { xs: 'none', sm: 'block' } }} sm>
            {CancelRideButton}
          </Grid>

          <Grid item sx={{ display: { xs: 'none', sm: 'block' } }} sm>
            {EditRideButton}
          </Grid>

          <Grid item sx={{ display: { xs: 'none', sm: 'block' } }} sm>
            {ShareButton}
          </Grid>

          <Grid item sx={{ display: { xs: 'none', sm: 'block' } }} sm>
            {RideAttendanceDropdown}
          </Grid>

          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Header.Wrapper>
    </>
  );
}

Header.UserContainer = styled(Grid)`
  max-width: 60%;
  overflow : hidden;
`;

Header.UserInfo = styled.div`
  p {
    max-width    : 100%;
    white-space  : nowrap;
    overflow     : hidden;
    margin       : 0;
    width        : 100%;
    text-overflow: ellipsis;
  }

  ${({theme: {fontSizes: { l, xl }}}) => css`
    font-size: ${ l };

    ${({theme: {mediaQueries: { sm }}}) => sm} {
      font-size: ${ xl };
    }
  `};
`;

Header.Wrapper = styled(Grid)<{ hidden?: boolean }>`
  border-bottom   : 1px solid ${ scrimBlack(0.08) };
  padding         : 10px 10px 10px 20px;
  background-color: ${({theme: {colors: {primary: { white }}}}) => white };
  display         : ${({ hidden }) => hidden && 'none'};
  height          : 14%;

  ${Avatar.Wrapper}, img {
    width : 40px;
    height: 40px;
  }

  .MuiLink-root {
    font-size: ${({theme: {fontSizes: {s}}}) => s };
  }

  ${({theme: {mediaQueries: { sm }}}) => sm} {
    padding: 20px 20px 20px 110px;
    display: flex;

    ${Avatar.Wrapper}, img {
      width : 48px;
      height: 48px;
    }

    .MuiLink-root {
      font-size: ${({theme: {fontSizes: {m}}}) => m };
    }
  }
`;

Header.PreviewInfoContainer = styled(Grid)`
  height     : 100%;
  display    : flex;
  align-items: center;
  
  ${Header.UserInfo} {
    padding-top: 5px;
  }
`;
