import { useSelector }                  from 'react-redux';
import {
  useCallback,
  useState,
  MouseEvent,
  useEffect,
  lazy,
  Suspense
}                                       from 'react';
import { useLocation, useHistory }      from 'react-router-dom';
import styled, { css }                  from 'styled-components';
import {
  Button,
  IconButton,
}                                       from '@mui/material';
import { useNotifications }             from '@utils/hooks/useNotifications';

import {
  showLoginSignupButtons,
  createIconRotated,
}                                       from '@store/reducers/ui';
import iconChatGrey                     from '@images/Icon-Chat-Grey.svg';
import iconNotificationsGrey            from '@images/Icon-Notifications-Grey.svg';
import { ReactComponent as PlusIcon }   from '@images/Icon-Add-White.svg';
import { createButtonAnimation }        from '@modules/CreateModal';
import { ECtaTab }                      from '@modules/CreateModal/models/CreateModalContent';
import { useGetSelfInformationQuery }   from '@modules/Profile/queries';
import { currentLocation }              from '@store/reducers/location';
import { useCta }                       from '@utils/hooks/useCta';
import { useWindowSize }                from '@utils/hooks/useWindowSize';
import { HeaderButton }                 from './HeaderButton';
import { HeaderProfileDropdown }        from './HeaderProfileDropdown';

const NotificationsPopper = lazy(() => import('../../NotificationsPopper'));
const Chat                = lazy(() => import('../../Chat'));

interface IHeaderRight {
  messagesCount: number;
  currentUser  : any;
  isSmaller    : boolean;
}

export const HeaderRight = ({ messagesCount, currentUser, isSmaller }: IHeaderRight) => {
  const isAuthenticated                                   = currentUser !== null;
  const history                                           = useHistory();
  const { openCta }                                       = useCta();
  const isRotated                                         = useSelector(createIconRotated);
  const stateLocation                                     = useSelector(currentLocation);
  const location                                          = useLocation();
  const showButtons                                       = useSelector(showLoginSignupButtons);
  const [animateCreateBtn, setAnimateCreateBtn]           = useState(false);
  const [chatAnchorEl, setChatAnchorEl]                   = useState<null | HTMLElement>(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState<null | HTMLElement>(null);
  const { isMobile }                                      = useWindowSize();
  const { data: self }                                    = useGetSelfInformationQuery();
  const {
    refetchNotifications,
    unreadCount
  }                                                       = useNotifications();

  useEffect(
    () => {
      refetchNotifications();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [self]
  );

  useEffect(
    () => {
      if (notificationsAnchorEl) {
        refetchNotifications();
      }
    },
    [notificationsAnchorEl, refetchNotifications]
  );

  const handleCreateBtnClick = useCallback(
    () => {
      setAnimateCreateBtn(true);

      openCta({ tab: ECtaTab.CTAS });
    },
    [openCta]
  );

  const handleToggleChat = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setChatAnchorEl(event.currentTarget);
    },
    []
  );

  const handleToggleNotifications = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setNotificationsAnchorEl(event.currentTarget);
    },
    []
  );

  return (
    <HeaderRight.Container createBtnIsRotated={isRotated} isAuth={Boolean(self && Object.keys(stateLocation).length > 0)}>
      {isAuthenticated ? (
        <Suspense fallback>
           {self && Object.keys(stateLocation).length > 0 && <HeaderButton
            onHandleClick      = {handleToggleChat}
            icon               = {iconChatGrey}
            notificationsCount = {messagesCount}
          />}

          <Chat
            isOpen          = {Boolean(chatAnchorEl)}
            handleCloseChat = {() => setChatAnchorEl(null)}
            anchorEl        = {chatAnchorEl}
          />

          {self && Object.keys(stateLocation).length > 0 && <HeaderButton
            notificationsCount = {unreadCount}
            onHandleClick      = {handleToggleNotifications}
            icon               = {iconNotificationsGrey}
          />}

          <NotificationsPopper
            isOpen                   = {Boolean(notificationsAnchorEl)}
            handleCloseNotifications = {() => setNotificationsAnchorEl(null)}
            anchorEl                 = {notificationsAnchorEl}
          />

          <HeaderProfileDropdown />

          {self && Object.keys(stateLocation).length > 0 && (
            <HeaderRight.CreateBtn
              $rotate = {animateCreateBtn}
              onClick = {handleCreateBtnClick}
            >
              <PlusIcon />
            </HeaderRight.CreateBtn>
          )}
        </Suspense>
      ) : (showButtons && (
        <>
          {location.pathname !== "/login" && (
            <Button
              color   = "secondary"
              size    = {isSmaller || isMobile ? "small" : "large"}
              sx      = {{ marginRight: location.pathname !== "/signup" ? "20px" : "0px" }}
              variant = "contained"
              onClick = {() =>
                history.push("/login", {
                  from: location.pathname === "/signup" ? "signup" : "",
                })
              }
            >
              Log In
            </Button>
          )}
          {location.pathname !== "/signup" && (
            <Button
              size    = {isSmaller || isMobile ? "small" : "large"}
              variant = "contained"
              onClick = {() =>
                history.push("/signup", {
                  from: location.pathname === "/login" ? "login" : "",
                })
              }
            >
              {isMobile ? "Sign Up" : "Sign Up For Free"}
            </Button>
          )}
        </>
        )
      )}
    </HeaderRight.Container>
  );
};

HeaderRight.Container = styled.div<{ createBtnIsRotated: boolean, isAuth: boolean }>`
  display     : flex;
  align-items : center;
  align-self  : center;

  & > div {
    cursor: pointer;
  }

  & > div:not(:last-child) {
    margin-left: 16px;
  }

  ${({ isAuth }) => isAuth && `
    & > div:last-child {
      background-image: linear-gradient(180deg, #ed0d6c, #d10056);
      margin-left     : 8px;
    }
  `}

  ${({createBtnIsRotated}) => createBtnIsRotated && `
    & > div:last-child {
      transform       : rotate(45deg);
      transform-origin: 50% 50%;
    }
  `}
`;

HeaderRight.CreateBtn = styled(IconButton)<{$rotate: boolean}>`
  &.MuiIconButton-root, &.MuiIconButton-root:hover {
    background-color: ${({theme: {colors: {primary: {rubineDark}}}}) => rubineDark};
  }

  &.MuiIconButton-root:hover {
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 16%);
  }

  ${({$rotate}) => $rotate && css`
    animation: ${createButtonAnimation} 0.3s;
  `};
`;
