import { ReactNode } from "react";

export enum ECtaTab {
  CTAS            = 'actions',
  CREATE_RIDE     = 'create-ride',
  EDIT_RIDE       = 'edit-ride',
  ADD_CONNECTIONS = 'add-connections',
  INVITE_FRIENDS  = 'invite-friends',
  MY_ROUTES       = 'my-routes',
  SHARE_RIDE      = 'share-ride',
}

export interface ICreateModalContent {
  component: ReactNode;
  title?   : string | JSX.Element;
  backIcon?: boolean;
}

export enum ERouteFilter {
  favoriteRoutes,
  myRoutes,
  locationRoutes,
  importedRoutes
}

export enum ERouteFilterLabel {
  'Favorite Routes',
  'My Routes',
  'Location Routes',
  'Imported Routes'
}
