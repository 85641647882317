import { ProfilePage }                from '../pages/Profile';
import { useGetUserProfileInfoQuery } from '../queries';

export const ProfileContainer = () => {
  const { data: userInfo, isLoading } = useGetUserProfileInfoQuery();

  return (
    <ProfilePage userInfo={userInfo} isLoading={isLoading} />
  );
};
