import { 
  FormControlLabel, 
  Grid, 
  Radio, 
  RadioGroup 
}                                from '@mui/material';
import { useCallback, useState } from 'react';
import styled, { css }           from 'styled-components';
import { Modal }                 from '../../../../components/Modal';
import { EManagementType }       from '../../../Details/models/ride';

interface IRideManagementModal {
  isOpen      : boolean;
  defaultValue: EManagementType;
  onClose     : () => void;
  onSave      : (value: EManagementType) => void;
}

const MGMT_TYPE_INFO = {
  [EManagementType.USER]: {
    label      : 'Me',
    title      : 'I own Changes',
    description: 'Control when, where, and with whom the ride takes place. Rides owned by you can be Public rides which are visible to all connections, while private rides are visible to only participants.'
  },
  [EManagementType.GROUP]: {
    label      : 'Group',
    title      : 'Anyone Can Make Changes',
    description: 'Enable the community to make changes on ride details, including the route, when and where the ride takes place. This option is best for rides which are informally organized by the community and have no clear owner.'
  }
}

export const RideManagementModal = ({
  isOpen,
  defaultValue,
  onClose,
  onSave
}: IRideManagementModal) => {
  const [value, setValue] = useState<EManagementType>(defaultValue || EManagementType.USER);

  const handleSave = useCallback(
    () => {
      onSave(value);

      onClose();
    },
    [onClose, onSave, value]
  );

  return (
    <Modal
      isOpen         = {isOpen}
      width          = "800px"
      itemName       = "Ride Management"
      submitBtnLabel = "Select Ride Mgmt"
      title          = {`${MGMT_TYPE_INFO[value].label} (${MGMT_TYPE_INFO[value].title})`}
      onClose        = {onClose}
      onSave         = {handleSave}
    >
      <RideManagementModal.Content>
        <RadioGroup 
          defaultValue = {value}
          onChange     = {
            (_event: React.ChangeEvent<HTMLInputElement>, value: string) => setValue(value as EManagementType)
          }
        >
          <Grid container>
            {Object.keys(MGMT_TYPE_INFO).map((type: string) => {
              const mgmtType = type as EManagementType;
              return (
                <Grid key={type} item container xs={12} sm={6}>
                  <FormControlLabel
                    value   = {EManagementType[mgmtType]}
                    label   = {MGMT_TYPE_INFO[mgmtType].label}
                    control = {<Radio />}
                  />

                  <RideManagementModal.Info>
                    <div>
                      <b>{MGMT_TYPE_INFO[mgmtType].title}</b>
                    </div>

                    <div>
                      {MGMT_TYPE_INFO[mgmtType].description}
                    </div>
                  </RideManagementModal.Info>
                </Grid>
              );
            })}
         </Grid>
        </RadioGroup> 
      </RideManagementModal.Content>
    </Modal>
  );
}

RideManagementModal.Content = styled.div`
  padding: 24px;
`;

RideManagementModal.Info = styled.div`
  padding: 0 32px;

  ${({theme: {fontSizes: {m, s, l}}}) => css`
    div:first-child {
      font-size     : ${m};
      margin-top    : -8px;
      padding-bottom: 6px;
    }

    div:last-child {
      font-size    : ${s};
      line-height  : ${l};
    }
  `}
`;