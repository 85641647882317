import { Control}     from 'react-hook-form';
import styled         from 'styled-components';
import { Button }     from '@mui/material';
import { InputError } from '../../../../../components/InputError';
import { TextField }  from '../../../../../components/TextField';

interface ILoginFormProps {
  control                      : Control<any>
  isDirty                      : boolean;
  isValid                      : boolean;
  isSubmitting                 : boolean;
  submitForm                   : () => void;
  handleOpenForgotPasswordModal: () => void;
  error?                       : string;
}

export const LoginForm = ({
  control,
  isSubmitting,
  isValid,
  isDirty,
  error,
  submitForm,
  handleOpenForgotPasswordModal
}: ILoginFormProps) => (
  <form onSubmit={submitForm}> 
    <LoginForm.FormWrapper>
      <LoginForm.FormTitle>
        <h1>Log In</h1>
      </LoginForm.FormTitle>

      <TextField
        dark
        control     = {control}
        name        = "email"
        placeholder = "Email address"
      />

      <TextField
        dark
        control     = {control}
        name        = "password"
        placeholder = "Password"
        inputType   = "password"
      />

      <LoginForm.ErrorWrapper>
        {error && !isSubmitting && <InputError error={error} />}
      </LoginForm.ErrorWrapper>

      <LoginForm.FormContainer>
        <LoginForm.FormLeft>
          <a
            onClick = {handleOpenForgotPasswordModal}
          >
            <div>Forgot your password</div>
          </a>
        </LoginForm.FormLeft>

        <Button
          disabled = {isSubmitting || !isDirty || (isDirty && !isValid)}
          type     = "submit"
          variant  = "contained"
        >
          Log In
        </Button>
      </LoginForm.FormContainer>

      <LoginForm.SectionLabel>
        <p>OR</p>
      </LoginForm.SectionLabel>
    </LoginForm.FormWrapper>
  </form>
);

LoginForm.FormWrapper = styled.div`
  display       : flex;
  width         : 320px;
  flex-direction: column;
  align-items   : stretch;
  flex          : 0 0 auto;
  @media screen and (max-width:479px){
    width: 280px;
  }
`;

LoginForm.FormTitle = styled.div`
  position        : relative;
  display         : flex;
  margin          : 16px 0;
  flex-direction  : column;
  justify-content : center;
  align-self      : stretch;
  text-align      : center;

  h1 {
    color         : #FFF;
    margin-top    : 0px;
    margin-bottom : 8px;
    font-size     : 22px;
    line-height   : 124%;
    font-weight   : 700;
    letter-spacing: -0.4px;
  }
`;

LoginForm.FormContainer = styled.div`
  display         : flex;
  margin-top      : 12px;
  /** TODO: restore space-between when forgot password is built */
  /* justify-content : space-between; */
  justify-content : center;
  align-items     : center;
  align-self      : stretch;
  flex            : 0 0 auto;
`;

LoginForm.FormLeft = styled.div`
  display        : flex;
  flex-direction : column;
  align-items    : flex-start;
  flex           : 1;
  margin-top     : 7px;

  a {
    text-decoration: none;
    font-weight    : 700;
    transition     : color 200ms;
    color          : ${({ theme }) => theme.colors.primary.rubineLight};
    cursor         : pointer;
  }

  a:hover {
    color      : #FFF;
    font-size  : 14px;
  }
`;

LoginForm.SectionLabel = styled.div`
  margin-top    : 32px;
  margin-bottom : 32px;
  align-self    : stretch;
  text-align    : center;

  p {
    font-size      : 11px;
    line-height    : 140%;
    letter-spacing : 0.8px;
    text-transform : uppercase;
    color          : #FFF;
    margin-bottom  : 0px;
    opacity        : 0.64;
  }

  @media screen and (max-width: 767px) {
    margin-top    : 24px;
    margin-bottom : 24px;
  }
`;

LoginForm.HiddenInput = styled.input`
  display: none;
`;

LoginForm.ErrorWrapper = styled.div`
  position: relative;

  p {
    top: -10px;
  }
`;
