import { 
  useState,
  useCallback,
}                            from 'react';
import { Link }              from 'react-router-dom';
import { 
  EnrichedReaction, 
}                            from 'getstream';
import styled, { css }       from 'styled-components';
import MoreHorizIcon         from '@mui/icons-material/MoreHoriz';
import { humanizeTimestamp } from 'react-activity-feed/dist/utils';
import { 
  useTranslationContext
}                            from 'react-activity-feed';
import { 
  Chip, 
  IconButton, 
  Menu, 
  MenuItem, 
  TextField 
}                            from '@mui/material';
import { Send, ThumbUp }     from '@mui/icons-material';
import { Avatar }            from '@components/Avatar';
import { 
  useGetUserInfoByIdQuery 
}                            from '@modules/Profile/queries';
import { getUserAvatarUrl }  from '@utils/files_utils';
import { useGetStream }      from '@utils/hooks/useGetStream';

interface ICommentProps {
  onLike         : () => void;
  onRemoveComment: (commentId: string) => void;
  isOwnComment   : boolean;
  comment        : EnrichedReaction;
  onReply        : () => void;
}

export const  Comment = ({
  onLike,
  onRemoveComment,
  isOwnComment,
  comment,
  onReply
}: ICommentProps) => {
  const {
    created_at,
    updated_at,
    data,
    user,
    children_counts,
    own_children,
    id
  } = comment;

  const [commentAnchorEl, setCommentAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditing, setEditStatus]            = useState(false);
  const [text, setText]                       = useState(data?.text as string ?? '');
  const { data: userInfo }                    = useGetUserInfoByIdQuery({ userId: user?.id ?? '' }, { skip : Boolean(!user?.id) });
  const { tDateTimeParser }                   = useTranslationContext();
  const { handleUpdateComment }               = useGetStream();

  const handleSaveEditComment = useCallback(
    () => {
      handleUpdateComment(id, text)?.then(
        (response) => {
          setText(response?.data?.text as string ?? '');
          setEditStatus(false);
          setCommentAnchorEl(null);
        }
      )
    },
    [handleUpdateComment, id, text]
  );

  const handleClickCommentActions = useCallback(
    (event: any) => {
      setCommentAnchorEl(event.currentTarget);
    },
    []
  );

  const handleKeypress = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleSaveEditComment();
      }
    },
    [handleSaveEditComment]
  );

  return (
    <Comment.Wrapper>
      <div>
        <Link to={`/user/${userInfo?.username ?? ''}`}>
          <Avatar
            size     = {32}
            photoUrl = {getUserAvatarUrl(userInfo?.profilePictureUrl ?? '')}
          />
        </Link>
      </div>

      <Comment.ContentWrapper $isEditing={isEditing}>
        {isEditing ? (
          <Comment.InputContainer>
            <Comment.Input
              value       = {text}
              onChange    = {({ target: { value }}) => setText(value)}
              minRows     = {1}
              onKeyDown   = {handleKeypress}
              multiline
              fullWidth
            />

            <IconButton onClick={handleSaveEditComment}>
              <Send />
            </IconButton>
          </Comment.InputContainer>
        ) : (
          <>
            <Comment.Сontent>
              <Comment.Header>
                <Comment.Username to={`/user/${userInfo?.username ?? ''}`}>
                  {`${userInfo?.firstName ?? ''} ${userInfo?.lastName ?? ''}`}
                </Comment.Username> 

                {created_at !== updated_at && <Comment.Edited>Edited</Comment.Edited>}
              </Comment.Header>

              <Comment.Text>
                {text}
              </Comment.Text>

            </Comment.Сontent>

            <Comment.ActionsContainer>
              <Comment.Action
                label     = {children_counts?.['like'] ?? 0}
                icon      = {<ThumbUp />}
                size      = "small"
                $isActive = {Boolean(own_children?.['like']?.length ?? false)}
                onClick   = {onLike}
              />

              <Comment.Action
                label   = "Reply"
                size    = "small"
                onClick = {onReply}
              />

              <p>{humanizeTimestamp(created_at, tDateTimeParser)}</p>

              {isOwnComment && (
                <Comment.UserActionsWrapper>
                  <MoreHorizIcon onClick={handleClickCommentActions} />

                  <Menu
                    id            = {`post-action-${id}`}
                    anchorEl      = {commentAnchorEl}
                    open          = {Boolean(commentAnchorEl)}
                    onClose       = {() => setCommentAnchorEl(null)}
                    MenuListProps = {{ 'aria-labelledby': 'basic-button' }}
                  >
                    <MenuItem onClick = {() => setEditStatus(true)}>
                      Edit
                    </MenuItem>

                    <MenuItem onClick = {() => onRemoveComment(id)}>
                      Delete
                    </MenuItem>
                  </Menu>
                </Comment.UserActionsWrapper>
              )}
            </Comment.ActionsContainer>
          </>
        )}
      </Comment.ContentWrapper>
    </Comment.Wrapper>
  );
}

Comment.Wrapper = styled.div`
  display         : flex;
  align-items     : flex-start;
  gap             : 10px;
  margin-bottom   : 10px;
`;

Comment.Header = styled.div`
  display        : flex;
  align-items    : center;
  justify-content: space-between;
`;

Comment.Edited = styled.p`
  margin   : 0px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.neutralGrey[700]};
`;

Comment.ContentWrapper = styled.div<{ $isEditing: boolean }>`
  max-width: 90%;

  ${({ $isEditing }) => $isEditing && `
    width: 100%;
  `};
`;

Comment.Сontent = styled.div`
  background-color: ${({ theme }) => theme.colors.neutralGrey[300]};
  border-radius   : 30px;
  padding         : 10px 30px 2px 20px;
`;

Comment.Text = styled.p`
  margin-top   : 5px;
  margin-bottom: 5px;
  font-size    : ${({ theme: { fontSizes } }) => fontSizes.m};
  color        : ${({ theme: { colors } }) => colors.type.dark};
`;

Comment.Username = styled(Link)`
  color          : ${({ theme: { colors } }) => colors.type.dark};
  font-weight    : 600;
  text-decoration: none;
  transition     : ease .2s;

  &:hover {
    color: ${({ theme: { colors } }) => colors.primary.rubineLight};
  }
`;

Comment.ActionsContainer = styled.div`
  display    : flex;
  gap        : 15px;
  align-items: center;
  margin-top : 10px;
  margin-left: 10px;

  p {
    margin: 0;
    color : ${({ theme: { colors } }) => colors.type.medium};
    font-size: ${({ theme }) => theme.fontSizes.s};
  }
`;

Comment.Action = styled(Chip)<{ $isActive?: boolean; }>`
  font-weight     : 600;
  font-size       : ${({ theme: { fontSizes } }) => fontSizes.s};
  color           : ${({ $isActive, theme: { colors } }) => $isActive ? colors.primary.rubineDark : colors.type.medium};
  background-color: ${({ theme }) => theme.colors.neutralGrey[400]};
  padding         : 8px;

  ${({ $isActive, theme: { colors } }) => $isActive && css`
    svg {
      fill: ${colors.primary.rubineDark}; 
    }
  `}
`;

Comment.RepliesWrapper = styled.div`
  padding-top : 10px;
  padding-left: 46px;
`;

Comment.RepliesContainer = styled.div`
  display       : flex;
  flex-direction: column;
  gap           : 15px;
`;

Comment.UserActionsWrapper = styled.div`
  display        : flex;
  flex-direction : column;
  justify-content: center;

  svg {
    cursor: pointer;
  }
`;

Comment.InputContainer = styled.div`
  display                   : flex;
  gap                       : 10px;
  justify-content           : space-between;
  padding                   : 10px 20px;
  background-color          : ${({ theme: { colors } }) => colors.neutralGrey[100]};
  border-bottom-left-radius : 8px;
  border-bottom-right-radius: 8px;

  button {
    border-radius: 0%;
  }
`;

Comment.Input = styled(TextField)`
  .MuiInputBase-root {
    border-radius: 20px;
  }

  input {
    padding         : 10px 15px;
    border-radius   : 15px;
    background-color: #EEEEED;
  }
`;