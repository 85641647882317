import { 
  useContext, 
  useCallback, 
  useState, 
  useEffect 
}                                          from 'react';
import styled                              from 'styled-components';
import { 
  Button, 
  Divider, 
  Grid, 
  TextField
}                                          from '@mui/material';
import { SettingsContext }                 from '../../../../../containers/SettingsContainer';
import { FieldWrapper }                    from '../../../../FieldWrapper';
import { TitleBlock }                      from '../../../../TitleBlock';
import { HEART_RATE_ZONES_COLORS }         from '../../../../../utils/colorZones';
import { privacyTabs }                     from '../../../../../utils/tabs';
import { ZoneField }                       from '../../../../ZoneField';
import { formattedZones }                  from '../../../../../../../utils/commonFunctions';
import { SETTINGS_SECTIONS }               from '../../../../../utils/settingsSections';
import { ErrorText }                       from '../../../../../../../components/InputError';
import { useCalculateHeartRateZonesQuery } from '../../../../../queries';

export const HeartRateZone = () => {
  const { 
    register, 
    getValues, 
    formState: { 
      errors 
    },
    trigger
  }                                     = useContext(SettingsContext);
  const [maxHeartRate, setMaxHeartRate] = useState<number | null>(getValues().maxHeartRate ?? null)
  const { data: heartRateZones }        = useCalculateHeartRateZonesQuery(maxHeartRate, { skip: !maxHeartRate });
  const [minMaxZones, setMinMaxZones]   = useState(formattedZones(heartRateZones));

  useEffect(
    () => {
      setMinMaxZones(formattedZones(heartRateZones))
    },
    [heartRateZones]
  );

  const handleAutoCalculate = useCallback(
    () => {
      trigger('maxHeartRate').then((isValid) => {
        if (isValid) {
          setMaxHeartRate(getValues().maxHeartRate ?? null);
        }
      });
    },
    [getValues, trigger]
  );

  return (
    <FieldWrapper>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <TitleBlock 
            tabs         = {[privacyTabs[1]]}
            defaultValue = {privacyTabs[1].id}
            label        = {SETTINGS_SECTIONS.heartRateZone.title}
            description  = {SETTINGS_SECTIONS.heartRateZone.description}
            tooltip      = {SETTINGS_SECTIONS.heartRateZone.tooltip}
          />
        </Grid>

        <Grid item md={1} sx={{ display: { xs: 'none', md: 'block' } }} />

        <Grid 
          item 
          container
          xs             = {12}
          sm             = {7}
          direction      = "column"
          sx             = {{ rowGap: { xs: 3, md: 0 } }}
          justifyContent = "space-around"
        >
          <Grid container spacing={2} justifyContent="end">
            <Grid item xs={12} sm={6}>
              <HeartRateZone.Error>
                {errors.restingHeartRate && <ErrorText>{errors.restingHeartRate.message ?? ''}</ErrorText>}
                {errors.maxHeartRate && <ErrorText>{errors.maxHeartRate.message ?? ''}</ErrorText>}
              </HeartRateZone.Error>
            </Grid>
            <Grid item xs={12} sm="auto">
              <HeartRateZone.Input 
                fullWidth
                label    = "Resting"
                variant  = "filled"
                type     = "number"
                error    = {!!errors.restingHeartRate}
                {...register('restingHeartRate')}
                inputProps={{
                  maxLength: '3',
                }}
              />
            </Grid>

            <Grid item xs={12} sm="auto">
              <HeartRateZone.Input
                fullWidth 
                label   = "Max"
                variant = "filled"
                type    = "number"
                error   = {!!errors.maxHeartRate}
                {...register('maxHeartRate')}
                inputProps={{
                  maxLength: '3'
                }}
              />
            </Grid>
          </Grid>

          <Grid container item sx={{ justifyContent: { xs: 'center', sm: 'end' } }}>
            <HeartRateZone.Button
              sx      = {{ width: { xs: '100%', sm: 'auto' } }}
              variant = "outlined"
              onClick = {handleAutoCalculate}
            >
              Auto Calculate
            </HeartRateZone.Button>
          </Grid>

          <Grid item xs paddingTop="30px" sx={{ display: { xs: 'block', sm: 'none' } }}>
            <Divider />
          </Grid>

          <Grid item container spacing={2} justifyContent="start" sx={{ flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
            {HEART_RATE_ZONES_COLORS.map((color, index) => (
              <Grid item xs={12} key={color}>
                <ZoneField 
                  fullWidth
                  label     = {`Zone ${index + 1}`} 
                  lineColor = {color} 
                  value     = {minMaxZones.length ? `${minMaxZones[index][0]}-${minMaxZones[index][1]}` : ''}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </FieldWrapper>
  )
};

HeartRateZone.Button = styled(Button)`
  &.MuiButton-root {
    justify-content: center;
  }
`;

HeartRateZone.Error = styled.div`
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
  height         : 100%;
  
  p {
    width         : 100%;
    text-align    : right;
    margin-bottom : 0px;
  }
`;

HeartRateZone.Input = styled(TextField)`
  &.MuiTextField-root {
    width: 112px;
  }
`;