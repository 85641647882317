import React                from 'react';
import ReactDOM             from 'react-dom';
import { BrowserRouter }    from 'react-router-dom';
import { Provider }         from 'react-redux';
import { SnackbarProvider } from 'notistack';
import TagManager           from 'react-gtm-module'
import { PersistGate }      from 'redux-persist/integration/react';

import { AuthProvider }     from './modules/Auth/contexts/AuthContext';
import { App }              from './App';
import { store, persistor } from './store';

const tagManagerArgs = {
  gtmId: 'GTM-PR83NPQ',
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW_ENV
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <AuthProvider>
            <SnackbarProvider maxSnack={3}>
              <App />
            </SnackbarProvider>
          </AuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
