import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
}                                       from 'react';
import styled                           from 'styled-components';
import { useHistory }                   from 'react-router';
import { orderBy as sortBy }            from 'lodash';
import { Button, Checkbox }             from '@mui/material';
import FavoriteIcon                     from '@mui/icons-material/Favorite';
import FavoriteBorderIcon               from '@mui/icons-material/FavoriteBorder';
import {
  IRouteParams,
  IRouteTableRow
}                                       from '@models/RouteModel';
import { preemFilesHost }               from '@config/api_config';
import {
  IRoutesFilters,
  RouteFiltersModal
}                                       from '@modules/Profile/pages/Routes/components/RouteFiltersModal';
import { TMeasurementSystem }           from '@modules/Profile/models/Settings';
import {
  CTAContext,
  TRoute,
}                                       from '@modules/CreateModal';
import { RouteDetails }                 from '@components/RouteDetails';
import { ConfirmationModal }            from '@components/ConfirmationModal';
import { Avatar }                       from '@components/Avatar';
import { IConfigCell, TableData }       from '@components/TableData';
import { IDropdownOption }              from '@components/RoundedDropdownInput';
import { getDistance, getElevation }    from '@utils/metrics_utils';
import { useTable }                     from '@utils/hooks/useTable';
import { formatRideDate }               from '@utils/commonFunctions';
import {
  useDeleteUserRouteMutation,
  useGetUserGenLocationsAttachedToRoutesQuery,
  useGetUserGenLocationsQuery,
  useGetUserRoutesQuery,
  useGetUserSettingsQuery,
  useUpdateUserRouteMutation
}                                       from '../../../Profile/queries';
import { MEASUREMENT_SYSTEMS }          from '../../../Profile/utils/options';
import { DEFAULT_ROUTE_THUMBNAIL_PATH } from '@components/RouteDetails';

const Title = styled.div`
  display: flex;

  && p {
    margin-left      : 15px;
    width            : auto;
    background-color : transparent;
  }
`;

export const MyRoutes = () => {
  const history                                     = useHistory();
  const [isRouteDetailsOpen, setRouteDetailsStatus] = useState<boolean>(false);
  const [selectedViewRoute, setSelectedViewRoute]   = useState<IRouteTableRow>({} as IRouteTableRow);
  const [selectedLocation, setSelectedLocation]     = useState<IDropdownOption | null>(null);
  const [filters, setFilters]                       = useState<IRouteParams>({});
  const [areFiltersOpen, setFiltersOpen]            = useState<boolean>(false);
  const {
    selectedRoute,
    setSelectedRoute,
    rideForm,
  }                                                 = useContext(CTAContext);
  const [selectedItem, setSelectedItem]             = useState<TRoute | undefined>(selectedRoute);
  const [removeRouteId, setRemoveRouteId]           = useState<string | null>(null);
  const { data: userSettings }                      = useGetUserSettingsQuery();
  const [deleteRoute]                               = useDeleteUserRouteMutation();
  const [updateRoute]                               = useUpdateUserRouteMutation();

  const {
    orderBy,
    order,
    onSort,
    pageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    debouncedSearchParam,
  } = useTable({ pageSize: 10 });

  const { data, refetch } = useGetUserRoutesQuery({
    pageNumber,
    pageSize,
    sortDirection : order,
    name          : debouncedSearchParam,
    sortColumn    : orderBy || 'date',
    ...(selectedLocation ? { genLocationId: String(selectedLocation.id) } : {}),
    ...filters,
  });

  const { data: userGenLocations } = useGetUserGenLocationsQuery();

  const { data: routesGenLocations } = useGetUserGenLocationsAttachedToRoutesQuery();

  const locationOptions = useMemo(
    () => {
      const routeGenLocationIds    = routesGenLocations?.map(({ id }) => id) ?? [];
      const uniqueUserGenLocations = userGenLocations?.filter(({ id }) => !routeGenLocationIds.includes(id)) ?? [];

      return sortBy([...uniqueUserGenLocations, ...(routesGenLocations ?? [])], ['name'], ['asc']);
    },
    [routesGenLocations, userGenLocations],
  );

  const {
    content,
    totalPages    = 0,
    totalElements = 0,
    } = data ?? {};

  const handleSaveFilters = useCallback(
    ({ distance, elevation }: IRoutesFilters) => {
      setFilters({
        distanceMin : distance[0],
        distanceMax : distance[1],
        elevationMin: elevation[0],
        elevationMax: elevation[1],
      });

      setFiltersOpen(false);
    },
    [],
  );

  const configCells: IConfigCell<IRouteTableRow>[] = useMemo(
    () => [
      {
        label     : 'Date',
        orderBy   : 'creationDate',
        headAlign : 'left',
        width     : '15%',
        render  : ({ creationDate, routeThumbnailPaths }) => (
          <Title>
            <Avatar
              photoUrl = {`${preemFilesHost}${routeThumbnailPaths?.[0] ?? DEFAULT_ROUTE_THUMBNAIL_PATH}`}
              size     = {40}
            />
            <p>{formatRideDate(new Date(creationDate))}</p>
          </Title>
        ),
        isBold    : true
      },
      {
        label   : 'Route Title',
        orderBy : 'name',
        key     : 'name',
        width   : '25%',
      },
      {
        label   : 'Location',
        key     : 'locationName',
        orderBy : 'locationName',
      },
      {
        label         : 'Distance',
        orderBy       : 'distance',
        headAlign     : 'center',
        cellFlexAlign : 'center',
        width         : '10%',
        render        : (route) => `${getDistance(userSettings?.measurementSystem, route.distance)} ${userSettings?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL ? 'mi' : route.distance >= 1000 ? 'km' : 'm'}`
      },
      {
        label         : 'Elevation',
        orderBy       : 'elevation',
        headAlign     : 'center',
        cellFlexAlign : 'center',
        width         : '10%',
        render        : (route) => `${getElevation(userSettings?.measurementSystem, route.elevation)} ${userSettings?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL ? 'ft' : 'm'}`
      },
      {
        label         : 'Favorite',
        orderBy       : 'favorite',
        headAlign     : 'center',
        cellFlexAlign : 'center',
        width         : '10%',
        render        : ({ isFavorite, id, name }: IRouteTableRow) => (
          <Checkbox
            checked     = {!isFavorite}
            onChange    = {() => updateRoute({ id, name, isFavorite: !isFavorite })}
            icon        = {<FavoriteIcon htmlColor="#D63169" />}
            checkedIcon = {<FavoriteBorderIcon htmlColor="#D63169" />}
          />
        )
      }
    ],
    [updateRoute, userSettings?.measurementSystem]
  );

  const rowActions = useMemo(
    () => [
      {
        label  : 'View Route',
        onClick : (route: IRouteTableRow) => {

          history.push(`/route/${route.id}`);
        }
      },
      {
        label  : 'Edit Route',
        onClick : (route: IRouteTableRow) => {
          setRouteDetailsStatus(true);

          setSelectedViewRoute(route);
        },
      },
      {
        label  : 'Remove',
        onClick : ({ id }: IRouteTableRow) => setRemoveRouteId(id),
      },
    ],
    [history]
  );

  const handleSelectRoutes = useCallback(
    () => {
      setSelectedRoute(selectedItem);

      rideForm.setValue('routeId', selectedItem?.id);
      rideForm.setValue('duration', undefined);

      history.goBack();
    },
    [
      history,
      rideForm,
      selectedItem,
      setSelectedRoute
    ],
  );

  const handleSelectRow = useCallback(
    (routeId: string) => {
      const selectedRoute = data?.content.find(({ id }) => id === routeId);

      setSelectedItem(selectedRoute);
    },
    [data]
  );

  const handleDeleteRoutes = useCallback(
    (id: string) => {
      deleteRoute(id).then(
        () => {
          setRemoveRouteId(null);
        }
      );
    },
    [deleteRoute],
  );

  useEffect(
    () => {
      refetch();
    },
    [refetch]
  );

  return (
    <MyRoutes.Wrapper>
      <TableData
        configCells         = {configCells}
        data                = {content || []}
        rowActions          = {rowActions}
        onSelectRow         = {handleSelectRow}
        selectedItems       = {[selectedItem?.id as string]}
        order               = {order}
        orderBy             = {orderBy}
        onSort              = {onSort}
        title               = "Routes"
        totalPages          = {totalPages}
        totalElements       = {totalElements}
        setPageNumber       = {setPageNumber}
        searchText          = {searchText}
        setSearchText       = {setSearchText}
        actionButtonLabel   = "Filter Results"
        onActionButtonClick = {() => setFiltersOpen(true)}
        dropdownItem        = {selectedLocation}
        dropdownOptions     = {locationOptions}
        dropdownPlaceholder = "Select Location"
        onDropdownChange    = {setSelectedLocation}
        isSingleSelect
      />

      <Button
        variant = "contained"
        onClick = {handleSelectRoutes}
        disabled={!selectedItem?.id}
      >
        Select Route
      </Button>

      {isRouteDetailsOpen && (
        <RouteDetails
          isOpen            = {isRouteDetailsOpen}
          handleClose       = {() => setRouteDetailsStatus(false)}
          selectedViewRoute = {selectedViewRoute}
          updateRoute       = {updateRoute}
        />
      )}

      {removeRouteId && (
        <ConfirmationModal
          title            = "Are you sure you want to remove route?"
          submitButtonText = "Remove Route"
          onSubmit         = {() => handleDeleteRoutes(removeRouteId)}
          onCancel         = {() => setRemoveRouteId(null)}
        />
      )}

      <RouteFiltersModal
        isOpen            = {areFiltersOpen}
        measurementSystem = {userSettings?.measurementSystem as TMeasurementSystem}
        onClose           = {() => setFiltersOpen(false)}
        onSubmit          = {handleSaveFilters}
      />
    </MyRoutes.Wrapper>
  )
}

MyRoutes.Wrapper = styled.div`
  width     : 100%;
  text-align: center;

  .MuiToolbar-root {
    background-color: #E8E8E7;
  }
  
  p {
    padding-left: 10px;
  }

  td p {
    background-color: #FFFFFF;
  }

  li button {
    background-color: #E8E8E7 !important;
  }
`;
