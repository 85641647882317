import {
  useCallback,
  useState
}                          from 'react';
import styled              from 'styled-components';
import { scrimBlack }      from '@styles/theme';
import { IGenLocation }    from '@modules/Profile/models/genLocation';
import { IRideInvitation } from '../../../../models';
import { TabType }         from '../TabsetDay';
import { Content }         from './components/Content';
import { Header }          from './components/Header';
import { MobileDayViewer } from './components/MobileDayViewer';

interface IDayColumnProps {
  date            : Date;
  invitationCards : IRideInvitation[];
  rideCards       : IRideInvitation[];
  isMobile        : boolean;
  dayIndex        : number;
}

export const DayColumn = ({
  date,
  invitationCards,
  rideCards,
  isMobile,
  dayIndex,
}: IDayColumnProps) => {
  const [selectedTab, setSelectedTab]             = useState<TabType>(TabType.MY_RIDES);
  const [selectedMobileTab, setSelectedMobileTab] = useState<TabType | null>(null);
  const [activeLocation, setActiveLocation]       = useState<IGenLocation>();

  const handleSelectTab = useCallback(
    (tab: TabType) => {
      setSelectedTab(tab);

      if (isMobile) {
        setSelectedMobileTab(tab);
      }
    },
    [isMobile]
  );

  return isMobile ? (
    <DayColumn.MobileWrapper>
      <Header
        date              = {date}
        invitationCards   = {invitationCards}
        rideCards         = {rideCards}
        selectedTab       = {selectedTab}
        onSelectTab       = {handleSelectTab}
        isMobile          = {isMobile}
        activeLocation    = {activeLocation}
        setActiveLocation = {setActiveLocation}
        dayIndex          = {dayIndex}
      />

      {selectedMobileTab !== null && (
        <MobileDayViewer
          isOpen
          dayIndex          = {dayIndex}
          date              = {date}
          invitationCards   = {invitationCards}
          rideCards         = {rideCards}
          selectedTab       = {selectedMobileTab}
          handleSelectTab   = {handleSelectTab}
          setActiveLocation = {setActiveLocation}
          activeLocation    = {activeLocation}
          onClose           = {() => setSelectedMobileTab(null)}
        />
      )}
    </DayColumn.MobileWrapper>
  ) : (
    <DayColumn.Wrapper $dayIndex={dayIndex}>
      <Header
        date              = {date}
        invitationCards   = {invitationCards}
        rideCards         = {rideCards}
        selectedTab       = {selectedTab}
        onSelectTab       = {setSelectedTab}
        isMobile          = {isMobile}
        activeLocation    = {activeLocation}
        setActiveLocation = {setActiveLocation}
        dayIndex          = {dayIndex}
      />

      <Content
        date            = {date}
        invitationCards = {invitationCards}
        rideCards       = {rideCards}
        selectedTab     = {selectedTab}
        activeLocation  = {activeLocation}
      />
    </DayColumn.Wrapper>
  )
};

DayColumn.Wrapper = styled.div<{ $dayIndex: number }>`
  border-right  : ${({ $dayIndex }) => $dayIndex === 6 ? 'none' : `2px solid ${scrimBlack(0.08)}`};

  ${Header.Wrapper} {
    ${({ $dayIndex }) => $dayIndex === 6 && 'padding-right: 0px !important'};
  }

  ${Content.Wrapper} {
    ${({ $dayIndex }) => $dayIndex === 0 && 'padding-left: 0px !important'};
    ${({ $dayIndex }) => $dayIndex === 6 && 'padding-right: 0px !important'};
  }
`;

DayColumn.MobileWrapper = styled.div`
  display       : flex;
  flex-direction: column;
`;
