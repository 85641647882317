import { 
  useCallback, 
  useEffect, 
  useState 
}                               from 'react';
import { useForm }              from 'react-hook-form';
import { 
  useHistory, 
  useLocation, 
}                               from 'react-router';
import styled                   from 'styled-components';
import * as Yup                 from 'yup';
import { yupResolver }          from '@hookform/resolvers/yup';
import { RecoveryPasswordForm } from './components/RecoveryPasswordForm';
import { ConfirmationModal }    from '@components/ConfirmationModal';
import { 
  useUpdatePasswordMutation,
  useVerifyResetCodeMutation
}                               from '../../Profile/queries';

interface IRecoveryPasswordForm {
  password       : string;
  confirmPassword: string;
}

const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Required"),
  confirmPassword: Yup.string()
    .min(8, "Must be at least 8 characters")
    .oneOf([Yup.ref("password")], "Passwords don't match")
    .required("Required"),
})

const initialValues: IRecoveryPasswordForm = {
  password       : '',
  confirmPassword: '',
}

export const RecoveryPassword = () => {
  const history                                     = useHistory();
  const [updatePassword]                            = useUpdatePasswordMutation();
  const [verifyResetCode]                           = useVerifyResetCodeMutation();
  const [isFormVisible, setFormVisible]             = useState<boolean>(false);
  const [dimBgImage, setDimBgImage]                 = useState<boolean>(false);
  const [isSuccessModalOpen, setSuccessModalStatus] = useState<boolean>(false);
  const { search }                                  = useLocation();
  const oobCode                                     = new URLSearchParams(search).get('oobCode');

  useEffect(
    () => {
      if (oobCode) {
        verifyResetCode({ oobCode }).unwrap().catch(
          () => {
            history.replace('/login');
          }
        );
      }
    },
    [history, oobCode, verifyResetCode]
  );

  // Show the input form
  useEffect(
    () => {
      let useEffectGate = true;

      let imgTimeout = setTimeout(() => {
        useEffectGate === true && setDimBgImage(true);
      }, 100);

      let formTimeout = setTimeout(() => {
        useEffectGate === true && setFormVisible(true);
      }, 600);

      return () => {
        useEffectGate = false;
        clearTimeout(imgTimeout);
        clearTimeout(formTimeout);
      };
    },
    []
  );

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid },
  } = useForm<IRecoveryPasswordForm>({
    defaultValues: initialValues,
    resolver     : yupResolver(validationSchema),
    mode         : 'onChange',
  });

  const onSubmit = useCallback(
    async (values: IRecoveryPasswordForm) => {
      if (oobCode) {
        updatePassword({ oobCode, newPassword: values.password }).then(
          (response: any) => {
            if (!response?.data?.error?.message) {
              setSuccessModalStatus(true);
            }
          }
        );
      }
    },
    [oobCode, updatePassword]
  );

  const onBackToLoginClick = useCallback(
    () => {
      setSuccessModalStatus(false);

      history.replace('/login');
    },
    [history]
  );

  return (
    <>
      <RecoveryPassword.Wrapper $dimBgImage={dimBgImage} />

      <RecoveryPassword.Gradient />

      <RecoveryPassword.Container isFormVisible={isFormVisible}>
        <RecoveryPasswordForm
          onSubmit     = {handleSubmit(onSubmit)}
          control      = {control}
          error        = {''}
          isDirty      = {isDirty}
          isValid      = {isValid}
          isSubmitting = {isSubmitting}
        />
      </RecoveryPassword.Container>

      {isSuccessModalOpen && (
        <ConfirmationModal
          onCancel         = {onBackToLoginClick}
          title            = "Success!"
          description      = "Your password has been reset. Let's ride!"
          cancelButtonText = "Go back to login"
        />
      )}
    </>
  )
}

RecoveryPassword.Wrapper = styled.div<{ $dimBgImage: boolean }>`
  opacity             : 1;
  position            : fixed;
  left                : 0;
  top                 : 0;
  right               : 0;
  bottom              : 0;
  z-index             : 75;
  background-image    : url('static/media/Sign-Up-BG.012065c2.jpg');
  background-position : 50% 50%;
  background-size     : cover;
  background-repeat   : no-repeat;
  transition          : opacity 300ms ease-in;

  ${({ $dimBgImage }) => $dimBgImage && 'opacity: 0.24'};
`;

RecoveryPassword.Gradient = styled.div`
  position         : fixed;
  left             : 0;
  top              : 0;
  right            : 0;
  bottom           : 0;
  z-index          : 50;
  background-image :  radial-gradient(circle farthest-corner at 0% 0%, #ed0d6c, #00457a);
  opacity          : 1;
`;

RecoveryPassword.Container = styled.div`
    position        : absolute;
    left            : 0;
    top             : 0;
    right           : 0;
    bottom          : 0;
    z-index         : 500;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    align-items     : center;
    opacity         : 0;
    transition      : opacity 300ms ease-in;
    opacity         : ${({ isFormVisible }: { isFormVisible: boolean }) => isFormVisible ? 1 : 0};
    
  @media screen and (max-width: 767px) {
    position      : relative;
    margin-top    : 68px;
    margin-bottom : 48px;
  }
`;
