import { useMemo }      from 'react';
import styled           from 'styled-components';
import { Button, Grid } from '@mui/material';
import { scrimBlack }   from '../../styles/theme';
import { 
  IUserInfo,
  IUserLocation, 
  IUserInfoMetrics
}                       from '../../modules/Profile/models/UserInfo';

interface IUserInfoProps {
  data?              : IUserInfo;
  onLocationClick?   : () => void;
  onConnectionsClick?: () => void;
}

export const UserTitleStatistics = ({
  data,
  onLocationClick,
  onConnectionsClick
}: IUserInfoProps) => {
  const getMetricsValue = useMemo(
    () => (metrics: IUserInfoMetrics) => {
      if (metrics?.conversionFn) {
        return metrics?.conversionFn(data?.[metrics.key]) || '—';
      }
      return data?.[metrics.key] || '—';
    },
    [data]
  );

  const userInfoMetrics: IUserInfoMetrics[] = useMemo(
    () => [{
        label       : 'MEMBER SINCE',
        key         : 'memberSince',
        conversionFn: (value) => value ? new Date(value as string).getFullYear().toString() : '—'
      }, {
        label       : 'LOCATION',
        key         : 'location',
        onClick     : onLocationClick,
        conversionFn: (value) => (value as IUserLocation)?.placeName
      }, {
        label  : 'CONNECTIONS',
        key    : 'connectionsCount',
        onClick: onConnectionsClick
      }], 
    [onConnectionsClick, onLocationClick]
  );

  return (
    <UserTitleStatistics.Container
      item
      container 
      xs       = {12}
      flexWrap = "nowrap"
    >
      {userInfoMetrics.map(metrics => (
        <Grid 
          item
          key = {metrics.key}
          xs  = {12}
          md  = "auto"
        >
          {(!!metrics.onClick && !!data?.[metrics.key]) 
          ? <Button 
              onClick = {metrics.onClick}
              size    = "small"
            >
              {getMetricsValue(metrics)}
            </Button>
          : <UserTitleStatistics.Value>
              {getMetricsValue(metrics)}
            </UserTitleStatistics.Value>
          }
            
          <UserTitleStatistics.Label>
            {metrics.label}
          </UserTitleStatistics.Label>
        </Grid>
      ))}
    </UserTitleStatistics.Container>
  );
};

UserTitleStatistics.Container = styled(Grid)`
  &.MuiGrid-container {
    border-bottom: 1px solid ${scrimBlack(0.08)};
    padding      : 20px 0;
    overflow-x   : scroll;
    white-space  : nowrap;
    text-align   : left;
    margin-left  : 0;

    ${({theme: {mediaQueries: {sm}}}) => sm} {
      overflow-x: auto;
    }
  }

  .MuiGrid-item {
    padding-left: 30px;

    ${({theme: {mediaQueries: {sm}}}) => sm} {
      padding-left: 60px;
    }
  }

  .MuiGrid-item:first-child {
    padding-left: 0;
  }

  .MuiButton-root {
    padding    : 0;
    font-size  : ${({theme: {fontSizes: {l}}}) => l};
    line-height: ${({theme: {fontSizes: {l}}}) => l};
  }
`;

UserTitleStatistics.Label = styled.div`
  color         : ${({theme: {colors: {type: {medium}}}}) => medium};
  font-weight   : 600;
  font-size     : 10px;
  letter-spacing: 0.8px;
`;

UserTitleStatistics.Value = styled.div`
  font-weight   : 700;
  font-size     : ${({theme: {fontSizes: {l}}}) => l};
  letter-spacing: -0.2px;
`;