import { Fragment }                 from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Divider,
  DialogTitle,
  Button,
  DialogContent,
  Box
}                                   from '@mui/material';
import { Footer }                   from '@components/Footer';
import { SettingsPage }             from '../../../pages/Settings';
import {
  SETTINGS_SECTIONS,
  SettingsSectionsType
}                                   from '../../../utils/settingsSections';
import { Weight }                   from './components/Weight';
import {
  ReactComponent as ChevronRightIcon
}                                   from '../../../../../images/Icon-Chevron-R-Rubine.svg';
import {
  ReactComponent as ChevronLeftIcon
}                                   from '../../../../../images/Icon-Chevron-L-Rubine.svg';
import { HeartRateZone }            from './components/HeartRateZones';
import { PowerZones }               from './components/PowerZones';
import { Name }                     from './components/Name';
import { Age }                      from './components/Age';
import { GeneralSettings }          from './components/GeneralSettings';
import { Nationality }              from './components/Nationality';
import { ProfileUrl }               from './components/ProfileUrl';
import { Pronouns }                 from './components/Pronouns';
import { Height }                   from './components/Height';
import { Locations }                from './components/Locations';
import { MyAccount }                from './components/MyAccount';
import { NotificationSettings }     from './components/NotificationSettings';
import { RideStartPrivacy }         from './components/RideStartPrivacy';

const mobileSections: { [key in SettingsSectionsType]: JSX.Element } = {
  heartRateZone            : <HeartRateZone />,
  powerZones               : <PowerZones />,
  name                     : <Name />,
  profileUrl               : <ProfileUrl />,
  nationality              : <Nationality />,
  pronouns                 : <Pronouns />,
  birthdate                : <Age />,
  height                   : <Height />,
  weight                   : <Weight />,
  locations                : <Locations />,
  rideStartPrivacy         : <RideStartPrivacy />
}

interface ISettingsFormProps {
  setOpenedSection : (section: SettingsSectionsType | null) => void;
  openedSection    : SettingsSectionsType | null;
}

export const SettingsForm = ({
  setOpenedSection,
  openedSection,
}: ISettingsFormProps) => (
  <>
    <GeneralSettings />
    <MyAccount />
    <NotificationSettings />

    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
      <HeartRateZone />
      <PowerZones />
      <Name />
      <ProfileUrl />
      <Nationality />
      <Pronouns />
      <Age />
      <Height />
      <Weight />
      <RideStartPrivacy />
      <Locations />
    </Box>

    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
      <SettingsPage.MobileList>
        {(Object.keys(SETTINGS_SECTIONS) as Array<SettingsSectionsType>).map((sectionName) => (
          <Fragment key={sectionName}>
            <ListItem onClick={() => setOpenedSection(sectionName)}>
              <ListItemButton>
                <ListItemText primary={SETTINGS_SECTIONS[sectionName].title} />

                <ListItemIcon>
                  <ChevronRightIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <Divider light />
          </Fragment>
        ))}
      </SettingsPage.MobileList>

      <SettingsPage.Dialog
        fullScreen
        open    = {!!openedSection}
        onClose = {() => setOpenedSection(null)}
      >
        <DialogTitle>
          <Button
            startIcon = {<ChevronLeftIcon />}
            onClick   = {() => setOpenedSection(null)}
          >
            Settings
          </Button>
        </DialogTitle>

        <DialogContent>
          {openedSection ? mobileSections[openedSection] : null}
        </DialogContent>

        <Footer />
      </SettingsPage.Dialog>
    </Box>
  </>
);
