import { ReactElement } from 'react';
import { ITab }         from '../../../models/tab';

export enum StatisticsPeriod {
  ALL   = 'all',
  YEAR  = 'year',
  MONTH = 'month',
  WEEK  = 'week'
}

export const statisticsTabs: ITab<StatisticsPeriod>[] = [{
  label: 'All Time',
  value: StatisticsPeriod.ALL
}, {
  label: 'This Year',
  value: StatisticsPeriod.YEAR
}, {
  label: 'This Month',
  value: StatisticsPeriod.MONTH
}, {
  label: 'This Week',
  value: StatisticsPeriod.WEEK
}];

export interface IUserStatistics {
  userRidesCount    : number;
  userRidesDuration : number;
  userRidesElevation: number;
  userRidesDistance : number;
  userRidesAvgSpeed : number;
}

export interface IStatisticsMetrics<T> {
  icon         : ReactElement;
  label        : string;
  key          : (keyof T);
  tooltip?     : string;
  units?       : (units?: string) => string;
  conversionFn?: (units?: string, value?: T[keyof T]) => string | undefined;
}