import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
}                        from 'react';
import styled            from 'styled-components';
import { useForm }       from 'react-hook-form';
import * as Yup          from 'yup';
import { getAuth }       from '@firebase/auth';
import { Button, Grid }  from '@mui/material';
import { yupResolver }   from '@hookform/resolvers/yup';
import { 
  ACCOUNT_TYPE_TABS, 
  useAuth 
}                        from '@modules/Auth/contexts/AuthContext';
import { ISettingsForm } from '@modules/Profile/models/Settings';
import { TextField }     from '@components/TextField';
import { Tabset }        from '@components/Tabset';
import { Avatar }        from '@components/Avatar';
import { parseNames }    from '@utils/string_utils';
import { 
  isUserOldEnough,
  isAgeValid
}                        from '@utils/birthdate_utils';

interface ISignupFormThirdPartyProps {
  defaultFirstName    : string;
  defaultLastName     : string;
  focusFirstNameInput?: boolean;
  onClickNext         : () => void;
}

interface ISignupFormThirdParty extends Pick<ISettingsForm['user'], 'firstName' | 'lastName'> {
  preemHandle  : string;
  signupCode   : string;
  isClubAccount: boolean;
  birthdate    : string;
}

const NAME_REGEX = /[^a-zA-Z,.'\-]/;

const validationSchema = Yup.object({
  firstName   : Yup.string()
  .trim("Name cannot be empty or contain trailing spaces")
  .min(2, "Must be at least 2 characters")
  .test('special-characters-test', 'No numbers or special characters allowed', (name = '') => !NAME_REGEX.test(name))
  .required("Required"),
  lastName    : Yup.string()
  .trim("Name cannot be empty or contain trailing spaces")
  .min(2, "Must be at least 2 characters")
  .test('special-characters-test', 'No numbers or special characters allowed', (name = '') => !NAME_REGEX.test(name))
  .required("Required"),
  preemHandle : Yup.string()
    .required("Required")
    .min(6, "Must be at least 6 characters"),
  signupCode : Yup.string()
    .required("Required")
    .min(6, "Must be exactly 6 characters")
    .max(6, "Must be exactly 6 characters"),
    birthdate: Yup.string()
    .test('age-validation','Sorry! You must be 13 years or older to sign up', (date) => isUserOldEnough(date))
    .test('age-validation','Please select a valid date', (date) => isAgeValid(date))
    .required("Required"),
});

/**
 * @description SignupForm is a Signup sub-component that renders the text input form for the signup flow
 * @component
 * @property {Function} onClickNext Method to invoke when the Next button is clicked
 * @example
 * <SignupForm />
 */
export const SignupFormThirdParty = ({
  onClickNext,
  defaultFirstName,
  defaultLastName,
  focusFirstNameInput,
}: ISignupFormThirdPartyProps) => {
  const [error, setError]               = useState<string>('');
  const { currentUser, completeSignup } = useAuth();
  const firstNameInputRef               = useRef() as React.MutableRefObject<any>;
  const auth                            = getAuth();

  const initialValues = useMemo<ISignupFormThirdParty>(
    () => {
      const parsedUserName = parseNames(currentUser?.displayName ?? '');

      return ({
        signupCode   : '',
        firstName    : defaultFirstName || parsedUserName.firstName,
        lastName     : defaultLastName || parsedUserName.lastName,
        preemHandle  : '',
        isClubAccount: false,
        birthdate    : '',
      });
    },
    [
      currentUser?.displayName,
      defaultFirstName,
      defaultLastName
    ],
  );

  const {
    getValues,
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm<ISignupFormThirdParty>({
    defaultValues : initialValues,
    resolver      : yupResolver(validationSchema),
    mode          : 'onChange',
  });

  // trigger focus event on firstName input textbox
  useEffect(() => {
    let delay = setTimeout(() => {
      if (firstNameInputRef && firstNameInputRef.current) {
        firstNameInputRef.current.focus();
      }
    }, 300);

    return () => {
      clearTimeout(delay);
    };
  }, [focusFirstNameInput]);

  const handleOnClick = useCallback(
    (values: ISignupFormThirdParty): void => {
      if (currentUser) {
        const payload = {
          email        : currentUser.email ?? '',
          firstName    : values.firstName,
          lastName     : values.lastName,
          displayName  : `${values.firstName} ${values.lastName}`,
          preemHandle  : values.preemHandle,
          signupCode   : values.signupCode,
          isClubAccount: values.isClubAccount,
          uid          : currentUser.uid,
          birthdate    : values.birthdate
        }

        completeSignup({
          email        : payload.email,
          firstName    : payload.firstName,
          lastName     : payload.lastName,
          displayName  : payload.displayName,
          preemHandle  : payload.preemHandle,
          signupCode   : payload.signupCode,
          uid          : payload.uid,
          isClubAccount: payload.isClubAccount,
          birthdate    : payload.birthdate
        })
        .then(onClickNext)
        .catch((error: { response: { data: { message: string }}}) => {
          setError(error.response?.data?.message);
        })
      }},
      [completeSignup, currentUser, onClickNext]
  );

  return (
    <>
      <SignupFormThirdParty.Wrapper>
        <Avatar
          withMargin
          photoUrl = {auth.currentUser?.photoURL || ''}
          name     = {auth.currentUser?.displayName || ''}
        />

        <form onSubmit={handleSubmit(handleOnClick)}>
          <SignupFormThirdParty.Head>
            <h1>Complete Your Profile</h1>
          </SignupFormThirdParty.Head >


          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <h4>Account Type</h4>
            </Grid>

            <Grid item>
              <Tabset 
                dark
                tabs         = {ACCOUNT_TYPE_TABS}
                name         = "isClubAccount"
                control      = {control}
                defaultValue = {false}
              />
            </Grid>
          </Grid>

          <TextField
            alphanumeric
            dark
            name               = "signupCode"
            control            = {control}
            placeholder        = "Signup Code"
            showValidationIcon = {getValues().signupCode.length === 6}
          />

          <TextField
            dark
            name        = "firstName"
            control     = {control}
            placeholder = "First Name"
            setRef      = {firstNameInputRef}
          />

          <TextField
            dark
            name        = "lastName"
            control     = {control}
            placeholder = "Last Name"
          />

          <TextField
            alphanumeric
            dark
            name        = "preemHandle"
            control     = {control}
            placeholder = "Preem Handle"
          />

          <TextField
            control     = {control}
            name        = "birthdate"
            inputType   = "date"
            placeholder = "Date of Birth"
            dark
          />

          <Button
            fullWidth
            type     = "submit"
            variant  = "contained"
            size     = "large"
            disabled = {isSubmitting || !isDirty || (isDirty && !isValid)}
          >
            Next
          </Button>
        </form>
      </SignupFormThirdParty.Wrapper>
      {error && <SignupFormThirdParty.ErrorWrapper>{error}</SignupFormThirdParty.ErrorWrapper>}
    </>
  );
};

SignupFormThirdParty.Wrapper = styled.div`
  display        : flex;
  flex-direction : column;
  align-items    : center;

  .MuiButton-root {
    justify-content: center;
  }

  h4 {
    color: #FFF;
  }
`;

SignupFormThirdParty.Head = styled.div`
  position        : relative;
  display         : flex;
  margin          : 16px 0;
  flex-direction  : column;
  justify-content : center;
  align-self      : stretch;
  text-align      : center;

  @media screen and (min-width: 1920px) {
    margin-bottom: 16px;
  }

  h1 {
    color         : #FFF;
    margin-top    : 0px;
    font-size     : 22px;
    margin-bottom : 0px;
  }
`;

SignupFormThirdParty.ErrorWrapper = styled.div`
  color           : ${({ theme }) => theme.colors.primary.white};
  text-align      : center;
  margin-top      : 20px;
  font-size       : 22px;
  line-height     : 124%;
  font-weight     : 700;
`;
