import { useEffect, useState } from 'react';
import { Grid }                from '@mui/material';
import styled, { css }         from 'styled-components';
import { format }              from 'date-fns';
import { scrimBlack }          from '../../../../../../styles/theme';
import { UserStatisticsTabs }  from '../../../../../../components/UserStatisticsTabs';
import { profilePicturesHost } from '../../../../../../config/api_config';
import { UserTitleStatistics } from '../../../../../../components/UserTitleStatistics';
import { IntensityTooltip }    from '../../../../../../components/IntensityTooltip';
import {
  getPronounString,
  IUpperStatistics,
  PublicProfileStatistics,
  PUBLIC_PROFILE_UPPER_METRICS
}                              from '../../../../models/statistics';
import { UserGallery }         from '../../../../components/UserGallery';
import { IUserInfo }           from '../../../../models/UserInfo';
import {
  useGetPublicProfileStatisticsQuery,
  useGetUserProfilePhotosQuery,
  useGetUserSettingsQuery
}                              from '../../../../queries';
import { ProfileStatistics }   from '../../../Profile/components/ProfileStatistics';
import { StatisticsPeriod }    from '../../../../../../components/UserStatisticsTabs/model/statistics';
import { getStartDayByPeriod } from '../../../../../../utils/metrics_utils';

interface IProfileTabProps {
  userInfo?: IUserInfo;
}

export const ProfileTab = ({
  userInfo,
}: IProfileTabProps) => {
  const {data: settings}                        = useGetUserSettingsQuery();
  const units                                   = settings?.measurementSystem;
  const [statisticsPeriod, setStatisticsPeriod] = useState<StatisticsPeriod>();
  const [fromDate, setFromDate]                 = useState<string>();
  const [toDate, setToDate]                     = useState<string>();
  const { data: profilePhotos }                 = useGetUserProfilePhotosQuery({ userId: userInfo?.userId ?? '' });
  const { data: statistics }                    = useGetPublicProfileStatisticsQuery({
    username: userInfo?.username || '',
    from: fromDate,
    to: toDate
  }, {
    skip: !userInfo?.username
  });

  useEffect(
    () => {
      if (statisticsPeriod && statisticsPeriod !== StatisticsPeriod.ALL) {
        setFromDate(getStartDayByPeriod(statisticsPeriod));
        setToDate(format(new Date(), 'yyyy-MM-dd'));
      }
    },
    [statisticsPeriod]
  );

  const userData: IUpperStatistics = {...statistics, ...userInfo};

  const getValue = (metric: PublicProfileStatistics) => {
    if (metric.key === 'pronounSubject') {
      return getPronounString(userData);
    }

    const metricValue = userData?.[metric.key];
    if (metric.conversionFn) {
      return metric.conversionFn(units, metricValue)
    }
    return metricValue;
  }

  return (
    <ProfileTab.Container>
      <UserGallery
        editable      = {false}
        userPhoto     = {`${profilePicturesHost}${userInfo?.profilePictureUrl}`}
        profilePhotos = {profilePhotos || []}

      />

      <ProfileTab.UpperBlock container>
        <Grid item xs={12} sm={4} />

        <Grid item xs={12} sm={8} container>
          {!!userInfo?.userBio && (
            <ProfileTab.Section item xs={12} $withBorder>
              {userInfo?.userBio}
            </ProfileTab.Section>
          )}

          <ProfileTab.Section item xs={12}>
            <UserTitleStatistics
              data               = {userInfo}
              onConnectionsClick = {() => {}}
              onLocationClick    = {() => {}}
            />
          </ProfileTab.Section>

          <ProfileTab.Section
            item
            container
            $withBorder
            xs            = {12}
            alignItems    = "center"
            columnSpacing = "60px"
          >
            {PUBLIC_PROFILE_UPPER_METRICS.map((metric) => getValue(metric) ? (
              <ProfileTab.GeneralStatisticsMetrics item xs="auto" key={metric.key} container>
                {metric.icon}

                <div>
                  {getValue(metric)}
                </div>

                <span>
                  {metric.units && metric.units(units)}
                </span>

                {metric.tooltip && <IntensityTooltip />}
              </ProfileTab.GeneralStatisticsMetrics>
              ) : null)}
          </ProfileTab.Section>
        </Grid>

        <Grid item container xs={12} justifyContent="center">
          <UserStatisticsTabs onPeriodChanged={setStatisticsPeriod} />
        </Grid>
      </ProfileTab.UpperBlock>

      <ProfileTab.DownBlock>
        <ProfileStatistics units={units} data={statistics}/>
      </ProfileTab.DownBlock>
    </ProfileTab.Container>
  );
}

ProfileTab.Container = styled.div`
  background-color: ${({theme: {colors: {neutralGrey}}}) => neutralGrey[200]};
`;

ProfileTab.Section = styled(Grid)<{$withBorder?: boolean}>`
  padding   : 0 20px 20px;
  word-break: break-all;

  ${({$withBorder}) => $withBorder && css`
    border-bottom: 1px solid ${scrimBlack(0.08)};
  `}

  ${({theme: {mediaQueries: {sm}}}) => sm} {
    padding  : 0 0 20px;
  }
`;

ProfileTab.Description = styled(ProfileTab.Section)`
  font-size: ${({theme: {fontSizes: {s}}}) => s};

  ${({theme: {mediaQueries: {sm}}}) => sm} {
    font-size: ${({theme: {fontSizes: {m}}}) => m};
  }
`;

ProfileTab.UpperBlock = styled(Grid)`
  padding         : 40px 0 0;
  font-weight     : 500;
  box-shadow      : 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04);
  background-color: ${({theme: {colors: {neutralGrey}}}) => neutralGrey[100]};
`;

ProfileTab.GeneralStatisticsMetrics = styled(Grid)`
  svg {
    align-self: center;
  }

  div {
    font-weight : 700;
    font-size   : ${({theme: {fontSizes: {l}}}) => l};
    padding-left: 10px;
    align-self  : center;
  }

  span {
    ${({theme: {colors: {type: {medium}}, fontSizes: {s}}}) => css`
      color       : ${medium};
      font-size   : ${s};
      padding-left: 4px;
      align-self  : center;
    `}
  }
`;

ProfileTab.DownBlock = styled(Grid)`
  padding: 44px 8% 110px;
`;
