import { 
  useCallback,
  useState 
}                          from 'react';
import { 
  StaticDatePicker,
  LocalizationProvider,
  PickersDay,
  PickersDayProps
}                          from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import AdapterDateFns      from '@mui/lab/AdapterDateFns';
import styled, { css }     from 'styled-components';
import {format}            from 'date-fns';
import { isSameDay }       from 'date-fns/esm';
import { Modal }           from '../../../../components/Modal';
import { scrimBlack }      from '../../../../styles/theme';

interface IDatePickerModalProps {
  isOpen       : boolean;
  defaultValue : Date;
  selectedDays?: Date[];
  onClose      : () => void;
  onSave       : (date: Date) => void;
}

const DAY_TYPES = ['No Ride Plan', 'Planned Ride', 'Today', 'Selected Day'];

export const DatePickerModal = ({
  isOpen,
  defaultValue = new Date(),
  selectedDays = [],
  onClose,
  onSave
}: IDatePickerModalProps) => {
  const [value, setValue] = useState<Date>(defaultValue);
  
  const handleSave = useCallback(
    () => {
      onSave(value);

      onClose();
    },
    [onClose, onSave, value]
  );

  const renderDay = (date: Date, _selectedDates: (Date | null)[], pickersDayProps: PickersDayProps<Date> & React.RefAttributes<HTMLDivElement>) => {
    const withRide = selectedDays?.some((day) => isSameDay(new Date(day), date));
    return (
      <DatePickerModal.Day
        key                 = {date.toString()}
        withRide            = {withRide}
        outsideCurrentMonth = {pickersDayProps.outsideCurrentMonth}
      >
        <div />
        <PickersDay {...pickersDayProps} showDaysOutsideCurrentMonth/>
      </DatePickerModal.Day>
    );
  };

  return (
    <Modal
      isOpen         = {isOpen}
      width          = "400px"
      itemName       = "Start Date"
      submitBtnLabel = "Select Date"
      title          = {format(value, 'EEEE, MMM dd')}
      onClose        = {onClose}
      onSave         = {handleSave}
    >
      <DatePickerModal.Content>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker 
            displayStaticWrapperAs = "desktop"
            value                  = {value}
            onChange               = {value => value && setValue(value)}
            renderDay              = {renderDay as (date: Date, _selectedDates: (Date | null)[], pickersDayProps: PickersDayProps<Date>) => JSX.Element}
            renderInput            = {(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <DatePickerModal.DayTypesBlock>
          <Grid container>
            {DAY_TYPES.map(type => (
              <Grid key={type} item>
                <div />

                <span>{type}</span>  
              </Grid>
            ))}
          </Grid>
        </DatePickerModal.DayTypesBlock>
      </DatePickerModal.Content>
    </Modal>
  );
}

DatePickerModal.Content = styled.div`
  ${({theme: {colors: {neutralGrey, type: {medium}}, fontSizes: {m}}}) => css`

    .MuiCalendarPicker-root {
      max-height: 57vh;
    }

    .MuiCalendarPicker-root div:first-child {
      padding: 0;
    }

    .MuiCalendarPicker-root div[role='presentation'] {
      background-image: linear-gradient(180deg, ${neutralGrey[400]}, ${neutralGrey[500]});
      border-radius   : 20px;
      box-shadow      : 0 1px 2px 0 rgb(0 0 0 / 4%), 0 4px 8px 0 rgb(0 0 0 / 4%);
      color           : ${medium};
      font-size       : ${m};
      padding-left     : 16px;
    }

    .MuiCalendarPicker-root div[role='row'] {
      margin-bottom: 12px;
      padding-top  : 1px;
    }
  `};
`;

DatePickerModal.DayTypesBlock = styled(Grid)`
  ${({theme: {colors: {type: {medium}, neutralGrey, primary: {rubineLight}}}}) => css`
    padding  : 0 24px;
    font-size: 9px;
    color    : ${medium};

    .MuiGrid-container {
      border-top: 1px solid ${scrimBlack(0.08)};
      padding   : 8px 0 20px;
    }

    .MuiGrid-item {
      align-items : center;
      display     : flex;
      margin-right: 16px;
    }

    .MuiGrid-item div {
      border-radius: 11px;
      width        : 16px;
      height       : 16px;
      display      : inline-flex;
      margin-right : 4px;
    }

    .MuiGrid-item:nth-child(1) div {
      background-color: ${scrimBlack(0.16)};
    }

    .MuiGrid-item:nth-child(2) div {
      background-color: ${neutralGrey[600]};
    }

    .MuiGrid-item:nth-child(3) div {
      background-color: ${rubineLight};
    }

    .MuiGrid-item:nth-child(4) div {
      border: 3px solid ${rubineLight};
    }
  `};
`;

DatePickerModal.Day = styled.div<{outsideCurrentMonth: boolean, withRide?: boolean}>`
  ${({
    theme: {colors: {primary: {rubineLight, white}, type: {medium, dark}, neutralGrey}},
    outsideCurrentMonth,
    withRide
  }) => css`
    position: relative;

    &:hover > div {
      opacity: ${!outsideCurrentMonth && '1'};
    }

    & > div {
      box-shadow   : 0 0 0 2px ${white}, 0 0 0 5px ${dark};
      position     : absolute;
      width        : 26px;
      height       : 26px;
      border-radius: 50%;
      top          : 2px;
      right        : 8px;
      opacity      : 0;
      transition   : opacity 0.5s;
    }

    .MuiPickersDay-root {
      width : 26px;
      height: 26px;
      margin: 0 8px;
      cursor: pointer;

      &, &:hover, &.Mui-selected, &.Mui-selected, &.Mui-selected:focus {
        background-color: ${withRide ? neutralGrey[600]: scrimBlack(0.16)};
        color           : ${withRide ? white : medium};
        font-weight     : 700;
      }

      &.Mui-selected {
        box-shadow: 0 0 0 2px ${white}, 0 0 0 5px ${rubineLight};
      }

      &.MuiPickersDay-dayOutsideMonth {
        cursor : default;
        opacity: 0.32;
      }

      &.MuiPickersDay-today, &.MuiPickersDay-today:hover, &.MuiPickersDay-today:focus {
        border          : none;
        background-color: ${rubineLight};
        color           : ${white};
      }
    }
  `};
`;