import styled               from 'styled-components';
import { Grid, IconButton } from '@mui/material';
import PhotoCameraIcon      from '@mui/icons-material/PhotoCamera';
import { EPostType }        from '@models/Feed/post';

interface IAddPostProps {
  onAddPost: (postType: EPostType) => void;
}

export const AddPost = ({
  onAddPost
}: IAddPostProps) => (
  <AddPost.Wrapper>
    <Grid container spacing={2} alignItems="center" wrap="nowrap"> 
      <Grid item flexGrow={1}>
        <AddPost.Input onClick={() => onAddPost(EPostType.Text)}>
          <p>{"What's on your mind, User?"}</p>
        </AddPost.Input>
      </Grid>

      <Grid item>
        <Grid container spacing={1.5} xs wrap="nowrap">
          <Grid item>
            <AddPost.IconButton onClick={() => onAddPost(EPostType.Photo)}>
              <PhotoCameraIcon />
            </AddPost.IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </AddPost.Wrapper>
);

AddPost.Wrapper = styled.div`
  display         : flex;
  align-items     : center;
  height          : 110px;
  background-color: #FFF;
  padding         : 0 30px;
  box-shadow      : 0px 0px 15px rgb(0 0 0 / 10%);
  border-radius   : 10px;
  margin-bottom   : 30px;
`;

AddPost.Input = styled.div`
  display         : flex;
  align-items     : center;
  height          : 50px;
  box-shadow      : inset 0px 2px 0px 0px rgb(0 0 0 / 10%);
  background-color: ${({ theme }) => theme.colors.neutralGrey[400]};
  border-radius   : 5px;
  padding-left    : 20px;
  cursor          : pointer;

  p {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.neutralGrey[600]};
  }
`;

AddPost.IconButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.neutralGrey[400]};
  box-shadow      : inset -28px -4px 20px 0px rgb(0 0 0 / 15%);
`;