import { 
  useCallback, 
  useContext 
}                          from 'react';
import { 
  Grid, 
  InputAdornment, 
  TextField 
}                          from '@mui/material';
import styled              from 'styled-components';
import { SettingsContext } from '../../../../../containers/SettingsContainer';
import { 
  timeTabs, 
  unitsTabs, 
  weatherTabs 
}                          from '../../../../../utils/tabs';
import { 
  Tabset, 
  TabsetItem 
}                          from '../../../../../../../components/Tabset';
import { InputError }      from '../../../../../../../components/InputError';

interface ITabsetProps {
  title: string;
  tabs : TabsetItem<string, string>[];
  name : string;
}

const TABSETS: ITabsetProps[] = [{
  title: 'Units',
  tabs : unitsTabs,
  name : 'measurementSystem'
}, {
  title: 'Weather',
  tabs : weatherTabs,
  name : 'temperatureUnits'
}, {
  title: 'Time',
  tabs : timeTabs,
  name : 'timeType'
}];

export const GeneralSettings = () => {
  const { register, control, formState: { errors }, setValue } = useContext(SettingsContext);

  const handlePreemHandleChange = useCallback(
    event => {
      const value = event.target.value.replace(/\s/g, "");

      setValue('user.username', value, { shouldDirty: true });
    },
    [setValue]
  );

  return (
    <GeneralSettings.Wrapper>
      <Grid container spacing={7} alignItems="center">
        <Grid item xs={12} sm="auto">
          <TextField 
            fullWidth
            {...register('user.username')}
            label        = "Preem Handle"
            variant      = "filled"
            InputProps   = {{
              startAdornment: <InputAdornment position="start">@</InputAdornment>,
            }}
            error    = {!!errors.user?.username}
            onChange = {handlePreemHandleChange}
          />
          {errors.user?.username && <InputError error={errors.user.username.message ?? ''} />}
        </Grid>

        {TABSETS.map(tabset => (
          <Grid key={tabset.name} item xs={12} sm="auto">
            <GeneralSettings.Container container alignItems="baseline" justifyContent="space-between">
              <Grid item xs={5} sm="auto">
                <h4>{tabset.title}</h4>
              </Grid>

              <Grid item xs={7} sm="auto">
                <Tabset 
                  tabs    = {tabset.tabs}
                  control = {control}
                  name    = {tabset.name}
                />
              </Grid>
            </GeneralSettings.Container>
          </Grid>
        ))}
      </Grid>
    </GeneralSettings.Wrapper>
  )
}

GeneralSettings.Wrapper = styled.div`
  padding-bottom : 35px;
  padding-top    : 35px;
  border-bottom  : 1px solid ${({ theme }) => theme.colors.neutralGrey[400]};

  .MuiFilledInput-root {
    padding-left: 7px;
  }

  .MuiFilledInput-input {
    letter-spacing: -0.2px;
    padding       : 15px 12px 0 15px;
  }

  .MuiInputAdornment-root p {
    font-weight: bold;
    position   : absolute;
    color      : #000;
    z-index    : 2;
    left       : 15px;
    font-size  : ${({theme: {fontSizes: {m}}}) => m};
    font-family: Inter, sans-serif;
  }
`;

GeneralSettings.Container = styled(Grid)`
  .MuiToggleButtonGroup-root {
    width: 100%;

    ${({theme: {mediaQueries: {sm}}}) => sm} {
      width: initial;
    }
  }

  .MuiToggleButtonGroup-root .MuiButtonBase-root {
    width: 50%;

    ${({theme: {mediaQueries: {sm}}}) => sm} {
      width: initial;
    }
  }

  h4 {
    margin: 0 15px 0 0;
  }
`;
