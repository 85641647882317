export const getAirQualityInt = (airQuality: number) => {
  if (airQuality <= 50) {
    return 0;
  }
  if (airQuality <= 100) {
    return 1;
  }
  if (airQuality <= 150) {
    return 2;
  }
  if (airQuality <= 200) {
    return 3;
  }
  if (airQuality <= 300) {
    return 4;
  }
  if (airQuality > 300) {
    return 5;
  }
  return 1;
}
