import styled                         from 'styled-components';
import { ReactComponent as PlusIcon } from '../../images/Icon-Add-Rubine.svg';
import { ISettingsForm }              from '../../modules/Profile/models/Settings';

interface IUserTitle {
  data?                : Partial<ISettingsForm['user']> & Pick<ISettingsForm, 'nationality'>;
  showEmptyNationality?: boolean;
}

export const UserTitle = ({
  showEmptyNationality = true,
  data
}: IUserTitle) => (
  <UserTitle.Container>
    {showEmptyNationality && <UserTitle.NationalityBtn>
      <PlusIcon />
    </UserTitle.NationalityBtn>}

    {data && 
      <UserTitle.Data>
        <div>{`${data?.firstName} ${data?.lastName}`}</div>
        <div>@{data?.username}</div>
      </UserTitle.Data>
    }
  </UserTitle.Container>
);

UserTitle.Container = styled.div`
  text-align  : left;
  font-weight : 700;
  display     : flex;
  align-items : center;
`;

UserTitle.Data = styled.div`
  display         : flex;
  flex-direction  : column;
  justify-content : center;
  margin-left     : 10px;

  div:first-child {
    font-size: 22px;
  }

  div:last-child {
    font-size: 13px;
  }
`;

UserTitle.NationalityBtn = styled.div`
  display          : flex;
  width            : 36px;
  height           : 36px;
  justify-content  : center;
  align-items      : center;
  border-radius    : 100%;
  background-color : ${({theme: {colors: {neutralGrey}}}) => neutralGrey[400]};
  cursor           : pointer;

  &:hover {
    box-shadow : 0 4px 16px 0 rgb(0 0 0 / 16%);
    transform  : scale(1.05);
  }
`;