import { Button, Grid }                  from '@mui/material';
import { useHistory }                    from 'react-router';
import styled                            from 'styled-components';
import axios                             from 'axios';
import { useState, useCallback }         from 'react';
import { ConfirmationModal }             from '@components/ConfirmationModal';
import { ReactComponent as ChevronIcon } from '@images/Icon-Chevron-R-Rubine.svg';
import { FilesUploader }                 from '@components/FilesUploader';
import { filterZip, isZip }              from '@utils/commonFunctions';
import { useUploadRideHistoryMutation }  from '@modules/Profile/queries';

const CancelToken = axios.CancelToken;
const source      = CancelToken.source();

const ALLOWED_FILE_TYPES: Array<string> = ['.zip', '.fit', '.gpx', '.tcx', '.fit.gz', '.gpx.gz', '.tcx.gz'];

export const isFileValid = (fileName: string): string => {
  const typeIndex = ALLOWED_FILE_TYPES.findIndex((allowedType: string) => fileName.toLowerCase().endsWith(allowedType.toLowerCase()));

  return typeIndex !== -1 ? '' : 'Must be ZIP, TCX, GPX, FIT or GZ';
}

interface IRideHistoryUploader {
  refetch: () => void;
}

export const RideHistoryUploader = ({ refetch }: IRideHistoryUploader) => {
  const { push }                                  = useHistory();
  const [uploadingProgress, setUploadingProgress] = useState<number>(0);
  const [changedLocation, setChangedLocation]     = useState<string>('');
  const [uploadRide, { isError }]                 = useUploadRideHistoryMutation();

  const uploadRideData = useCallback(
    async (file: File) => {
      const formData     = new FormData();
      const isZippedFile = isZip(file, file.name);

      if (isZippedFile) {
        const fileToUpload = await filterZip(file);

        formData.append('file', fileToUpload);
      } else {
        formData.append('file', file);
      }

      formData.append('name', file.name);

      uploadRide(formData)
        .then(() => {
          refetch();
          setUploadingProgress(100);
          setTimeout(() => setUploadingProgress(0), 1000);
        });
    },
    [refetch, uploadRide]
  );

  const handleCancelChangeLocation = useCallback(
    () => {
      setChangedLocation('');
    },
    []
  );

  const handleAcceptChangeLocation = useCallback(
    () => {
      setChangedLocation('');
      push(changedLocation);
    },
    [changedLocation, push]
  );

  return (
    <>
      {changedLocation && (
        <ConfirmationModal
          onSubmit         = {handleCancelChangeLocation}
          onCancel         = {handleAcceptChangeLocation}
          title            = "File upload in progress"
          description      = "If you leave this page your uploads can be failed."
          submitButtonText = "Waiting for upload to finish"
          cancelButtonText = "Don't wait and Leave Page"
        />
      )}

      <RideHistoryUploader.Container item xs={12} sm={6} container alignItems="center" flex-wrap="nowrap">
        <Grid item container xs={12} sm={6} flexDirection="column" alignItems="flex-start">
          <p>Import Ride Data</p>

          The most effective way to build your PREEM profile is to import your history of ride data (.fit, .gpx, .zip).

          <Button
            size    = "small"
            endIcon = {<ChevronIcon />}
            onClick = {() => { window.open('https://gopreem.zendesk.com/hc/en-us/articles/5645104018203-Importing-Ride-History-Data', '_blank') }}
          >
            Learn How to Locate Your Ride Data
          </Button>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FilesUploader
            onUpload            = {uploadRideData}
            progress            = {uploadingProgress}
            isError             = {isError}
            cancelFn            = {source.cancel}
            validateExtensionFn = {isFileValid}
            multiple            = {false}
            accept              = ".fit, application/octet-stream, .gz, .zip, .gpx"
          />
        </Grid>
      </RideHistoryUploader.Container>
    </>
  )
}

RideHistoryUploader.Container = styled(Grid)`
  padding       : 24px;
  border-radius : 8px;
  font-weight   : 500;
  letter-spacing: -0.2px;
  text-align    : left;

  &.MuiGrid-root {
    margin: 30px 0;
  }

  ${({theme: {fontSizes: {s, m}, colors: {primary: {white}}}}) => `
    background-color: ${white};
    font-size       : ${s};

    p {
      font-weight: 700;
      font-size  : ${m};
    }
  `};

  ${({theme: {mediaQueries: {sm}}}) => sm} {
    ${({theme: {fontSizes: {xl, m} }}) => `
      font-size: ${m};

      p {
        font-size: ${xl};
      }
    `};
    
    &.MuiGrid-root {
      margin-top: 0;
    }

    & > .MuiGrid-item:first-child {
      padding-right: 14px;
    }

    & > .MuiGrid-item:last-child {
      padding-left: 14px;
    }
  }

  .MuiButton-text {
    margin-left : -24px;
    max-height  : fit-content;
  }
`;
