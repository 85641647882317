import { useContext }          from 'react';
import styled                  from 'styled-components';
import { Tabset, TabsetItem }  from '../../../../components/Tabset';
import { AppTooltip }          from '../../../../components/AppTooltip';
import { scrimBlack }          from '../../../../styles/theme';
import { SettingsContext }     from '../../containers/SettingsContainer';

interface ITitleBlockProps<T, S> {
  label         : string;
  description   : string;
  defaultValue? : boolean;
  name?         : string;
  tabs?         : TabsetItem<T, S>[];
  tooltip?      : string;
}

export const TitleBlock = <T, S>({ label, description, tabs, name, defaultValue, tooltip }: ITitleBlockProps<T, S>) => {
  const { control } = useContext(SettingsContext);

  return (
    <TitleBlock.Wrapper>
      <TitleBlock.Container>
        <p>{label}</p>

        <AppTooltip tooltipText={tooltip && tooltip.length ? tooltip : "This tooltip does not currently have a value. Please contact support to let us know you found this message!"} />
      </TitleBlock.Container>

      <TitleBlock.Description>{description}</TitleBlock.Description>

      {tabs && (
        <Tabset 
          tabs         = {tabs}
          name         = {name}
          control      = {control}
          defaultValue = {defaultValue}
        />
      )}
    </TitleBlock.Wrapper>
  )
}

TitleBlock.Wrapper = styled.div`
  display       : flex;
  text-align    : center;
  flex-direction: column;
  align-items   : center;
  border-bottom : 1px solid ${scrimBlack(0.08)};
  padding-bottom: 25px;

  ${({theme: {mediaQueries: {sm}}}) => sm} {
    display      : block;
    text-align   : left;
    padding      : 20px 0 50px;
    border-bottom: none;
  }
`;

TitleBlock.Container = styled.div`
  display     : flex;
  align-items : center;
  width       : max-content;

  p {
    margin     : 0 6px 0 0;
    align-self : flex-start;
    color      : ${({theme: {colors: {neutralBlue}}}) => neutralBlue[300]};
    font-size  : ${({theme: {fontSizes: {xl}}}) => xl};
    font-weight: bold;

    ${({theme: {mediaQueries: {sm}}}) => sm } {
      color: ${({theme: {colors: {primary: {black}}}}) => black};
    }
  }

  button {
    margin-top: -6px;
    padding   : 0;

    ${({theme: {mediaQueries: {sm}}}) => sm} {
      margin : -6px 10px 0 -10px;
      padding: 8px;
    }
  }

  div {
    align-self: center;
  }
`;

TitleBlock.Description = styled.p`
  font-weight    : 500;
  font-size      : 11px;
  line-height    : 16px;
  letter-spacing : -0.2px;
  margin-bottom  : 25px;

  ${ ({ theme: { mediaQueries: {sm} } }) => sm } {
    font-size   : 12px;
    line-height : 18px;
  }
`;