import { useContext }        from 'react';
import { Grid }              from '@mui/material';
import { SettingsContext }   from '../../../../../containers/SettingsContainer';
import { Dropdown }          from '../../../../../../../components/Dropdown';
import { FieldWrapper }      from '../../../../FieldWrapper';
import { TitleBlock }        from '../../../../TitleBlock';
import {
  PRONOUNS_SUBJECT_OPTIONS, 
  PRONOUNS_OBJECT_OPTIONS, 
  PRONOUNS_POSESSIVE_OPTIONS 
}                            from '../../../../../utils/options';
import { privacyTabs }       from '../../../../../utils/tabs';
import { SETTINGS_SECTIONS } from '../../../../../utils/settingsSections';

export const Pronouns = () => {
  const { control } = useContext(SettingsContext);

  return (
    <FieldWrapper>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={4}>
          <TitleBlock 
            tabs        = {privacyTabs}
            name        = "isPronounsPrivate"
            label       = {SETTINGS_SECTIONS.pronouns.title}
            description = {SETTINGS_SECTIONS.pronouns.description}
            tooltip     = {SETTINGS_SECTIONS.pronouns.tooltip}
          />
        </Grid>

        <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }} />
        
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} justifyContent="end">
            <Grid item xs={12} md={4}>
              <Dropdown 
                label        = "Subject"
                options      = {PRONOUNS_SUBJECT_OPTIONS}
                control      = {control}
                name         = "pronounSubject"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Dropdown 
                label        = "Object"
                options      = {PRONOUNS_OBJECT_OPTIONS}
                control      = {control}
                name         = "pronounObject"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Dropdown 
                label        = "Possessive"
                options      = {PRONOUNS_POSESSIVE_OPTIONS}
                control      = {control}
                name         = "pronounPossessive"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FieldWrapper>
  )
}
