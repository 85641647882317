import styled                 from 'styled-components';
import MoreHorizIcon          from '@mui/icons-material/MoreHoriz';
import { IRecommendation }    from '@models/Feed/recommendations';
import { RecommendationCard } from '../RecommendationCard';

interface IRecommedationsProps {
  recommendations    : IRecommendation[];
  handleAddConnection: (userId: string) => void;
  handleOpenProfile  : (username: string) => void;
}

export const Recommendations = ({
  recommendations,
  handleAddConnection,
  handleOpenProfile
}: IRecommedationsProps) => (
  <Recommendations.Wrapper>
    <Recommendations.Header>
      <h3>Riders You Should Follow</h3>

      <div>
        <p>
          See all
        </p>

        <MoreHorizIcon />
      </div>
    </Recommendations.Header>

    <Recommendations.ConnectionsContainer>
      {recommendations.map(recommendation => (
        <RecommendationCard
          key                 = {recommendation.userId}
          data                = {recommendation}
          handleAddConnection = {handleAddConnection}
          handleOpenProfile   = {handleOpenProfile}
        />
      ))}
    </Recommendations.ConnectionsContainer>

  </Recommendations.Wrapper>
);

Recommendations.Wrapper = styled.div`
  height          : 420px;
  margin-top      : 30px;
  margin-bottom   : 30px;
  padding         : 10px 20px 0px 20px;
  border-radius   : 10px;
  background-color: ${({ theme }) => theme.colors.neutralGrey[100]};
  box-shadow      : 0px 0px 15px rgb(0 0 0 / 10%);
`;

Recommendations.ConnectionsContainer = styled.div`
  display : flex;
  overflow: auto;
`;

Recommendations.Header = styled.div`
  width          : 100%;
  height         : 60px;
  display        : flex;
  justify-content: space-between;

  div {
    display    : flex;
    align-items: center;
    gap        : 10px;
  }

  p {
    color      : ${({ theme }) => theme.colors.primary.rubineLight};
    font-weight: bold;
    font-size  : 16px;
    cursor     : pointer;
  }

  svg {
    cursor: pointer;
  }
`;
