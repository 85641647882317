import { add }                 from 'date-fns';
import { DAY_IN_MILLISECONDS } from '@utils/time_utils';

export type TNumberRange = [number, number];

const MIN_RANGE_VALUE         = 0;
const MAX_DISTANCE_KILOMETERS = 240;
const MAX_DISTANCE_MILES      = 150;
const MAX_ELEVATION_METERS    = 3000;
const MAX_ELEVATION_FEET      = 9000;
const MAX_RIDE_LENGTH         = 24;

const maxDateValue = new Date().getTime();
const minDateValue = add(maxDateValue, { years: -2 }).getTime();

export const DISTANCE_RANGE: TNumberRange       = [MIN_RANGE_VALUE, MAX_DISTANCE_KILOMETERS];
export const DISTANCE_RANGE_MILES: TNumberRange = [MIN_RANGE_VALUE, MAX_DISTANCE_MILES];
export const ELEVATION_RANGE: TNumberRange      = [MIN_RANGE_VALUE, MAX_ELEVATION_METERS];
export const ELEVATION_RANGE_FEET: TNumberRange = [MIN_RANGE_VALUE, MAX_ELEVATION_FEET];
export const RIDE_LENGTH_RANGE: TNumberRange    = [MIN_RANGE_VALUE, MAX_RIDE_LENGTH];
export const DATE_RANGE: TNumberRange           = [minDateValue, maxDateValue];

export const DISTANCE_STEP    = 1;
export const ELEVATION_STEP   = 20;
export const RIDE_LENGTH_STEP = 1;
export const DATE_STEP        = DAY_IN_MILLISECONDS;
