import styled                 from 'styled-components';
import { Grid }               from '@mui/material';
import { ConnectionsTable }   from '@modules/Profile/components/ConnectionsTable';
import { PageHeader }         from '@modules/Profile/components/PageHeader';
import { InvitationsTable }   from '@modules/Profile/components/InvitationsTable';
import { ECtaTab }            from '@modules/CreateModal/models/CreateModalContent';
import { useCta }             from '@utils/hooks/useCta';
import { Layout }             from '@components/Layout';
import { CreateButton }       from '@components/CreateButton';

export const CommunityPage = () => {
  const { openCta } = useCta();

  return (
    <Layout>
      <Grid container alignItems="center" justifyContent="space-between" sx={{ marginBottom: '70px', marginTop: '20px' }}>
        <Grid item xs={12} md={5}>
          <PageHeader>
            Community
          </PageHeader>

          <CommunityPage.Header>
            <p>
              {`Invite friends to Preem, find and add connections, and manage the connections you've already made in the Preem community.`}
            </p>
          </CommunityPage.Header>
        </Grid>

        <Grid
          container
          item
          xs      = {12}
          md      = {7}
          spacing = {2}
          sx      = {{ justifyContent: { xs: 'start', md: 'end' }, marginTop: { xs: 0, md: '20px' }}}
        >
          <Grid item>
            <CreateButton
              label         = "Invite a Friend"
              onHandleClick = {() => openCta({ tab: ECtaTab.INVITE_FRIENDS })}
            />
          </Grid>

          <Grid item>
            <CreateButton
              label         = "Add a Connection"
              onHandleClick = {() => openCta({ tab: ECtaTab.ADD_CONNECTIONS })}
            />
          </Grid>
        </Grid>
      </Grid>

      <InvitationsTable />

      <ConnectionsTable />
    </Layout>
  )
}

CommunityPage.Header = styled.div`
  width      : 100%;
  text-align : left;

  p {
    font-size      : 15px;
    line-height    : 23px;
    letter-spacing : -0.2px;

    ${({theme: {mediaQueries: {sm}}}) => sm } {
      font-size   : ${({ theme: {fontSizes: {xl}}}) => xl};
      line-height : 28px;
    }
  }
`;

CommunityPage.ConnectionTitle = styled.p`
  text-align    : left;
  font-size     : 18px;
  margin-bottom : 20px;

  span {
    font-size: ${({theme: {fontSizes: {l}}}) => l};
  }

  ${({theme: {mediaQueries: {sm}}}) => sm} {
    font-size: ${({theme: {fontSizes: {xl}}}) => xl};

    span {
      font-size: 22px;
    }
  }
`;
