import {
  lazy,
  Suspense,
  useState
}                                  from 'react';
import { useHistory, useParams }   from 'react-router';
import styled, { css }             from 'styled-components';
import { Dialog, Grid }            from '@mui/material';
import { LoaderWrapper }           from '../../../../components/LoaderWrapper';
import { Footer }                  from '../../components/Footer';
import { Header }                  from '../../components/Header';
import {
  useGetRouteDetailsQuery,
}                                  from '../../queries';
import { WeatherDetails }          from '../../components/WeatherDetails';
import { RightBlockHeader }        from '../../components/RightBlockHeader';
import {
  RIGHT_PANEL_CONTENT,
  RIGHT_PANEL_CONTENT_TYPE
}                                  from '../../models/rightPanelContent';
import { scrimBlack }              from '../../../../styles/theme';
import { Popup }                   from '../../components/Popup';

const RideRouteDetails = lazy(() => import('../../components/RideRouteDetails'));

export const RouteDetailsContainer = () => {
  const history                                   = useHistory();
  const { routeId }                               = useParams<{ routeId: string }>();
  const [rightPanelContent, setRightPanelContent] = useState<RIGHT_PANEL_CONTENT_TYPE>(RIGHT_PANEL_CONTENT_TYPE.ROUTE_DETAILS);
  const previousContent                           = RIGHT_PANEL_CONTENT[rightPanelContent].previousContent;
  const rightPanelHeader                          = RIGHT_PANEL_CONTENT[rightPanelContent].title;

  const {
    data: routeDetails,
    isLoading,
    isSuccess,
  } = useGetRouteDetailsQuery({ id: routeId });

  RouteDetailsContainer.changeRightContent = (rightPanelContent: RIGHT_PANEL_CONTENT_TYPE) => {
    setRightPanelContent(rightPanelContent);
  };

  return (
    <Dialog
      open
      fullScreen
    >
      <LoaderWrapper isLoading={isLoading && !isSuccess}>
        <Header
          hide          = {false}
          onClose       = {() => history.push('/profile/my-routes')}
          hideShare
        />

        <RouteDetailsContainer.Content container>
          <Grid item xs={12} sm={5} overflow="auto">
            <Suspense fallback>
              <RideRouteDetails routeId={routeId} />
            </Suspense>

            <WeatherDetails routeDetails={routeDetails} />
          </Grid>

          <RouteDetailsContainer.RightPanel item xs={12} sm={7} $withPadding>
          {rightPanelHeader &&
            <RightBlockHeader
              title       = {rightPanelHeader}
              onBackClick = {() => previousContent && setRightPanelContent(previousContent)}
            />
          }

          {RIGHT_PANEL_CONTENT[rightPanelContent].content}
          </RouteDetailsContainer.RightPanel>

        </RouteDetailsContainer.Content>

        <Footer />

        <Popup />
      </LoaderWrapper>
    </Dialog>
  );
}

RouteDetailsContainer.Content = styled(Grid)`
  overflow-y: auto;

  ${({theme: {mediaQueries: { sm }}}) => sm} {
    height    : calc(100% - 92px);
    overflow-y: unset;
  }
`;

RouteDetailsContainer.RightPanel = styled(Grid)<{ $withPadding?: boolean}>`
  ${({ theme: {colors: { neutralGrey }}, $withPadding}) => css`
    height    : 100%;
    padding   : ${$withPadding && '20px'};
    overflow-y: auto;
    border-top: ${neutralGrey[400]};

    ${({theme: {mediaQueries: { sm }}}) => sm} {
      background: ${neutralGrey[100]};
      padding   : ${$withPadding && '32px 55px 24px 40px'};
      box-shadow: inset -1px 9px 10px 1px ${ scrimBlack(0.04) };
      border    : none;
    }
  `}
`;


RouteDetailsContainer.changeRightContent = (_rightPanelContent: RIGHT_PANEL_CONTENT_TYPE) => {};
