import { AppBar }      from "@mui/material";
import styled          from "styled-components";

import { ISubsection } from "../Subsection";

interface INavigationBar {
  onHandleNavItemClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>)=>void;
  subsections         : ISubsection[];
}

export const NavigationBar = ({ onHandleNavItemClick, subsections }: INavigationBar) => {
  return (
    <NavigationBar.Wrapper position="sticky">
      {subsections &&
        subsections.map((subsection) => {
          return (
            subsection.anchor !== "top" && (
              <NavigationBar.Button
                data-to-scrollspy-id = {subsection.anchor}
                href                 = {`#${subsection.anchor}`}
                key                  = {subsection.anchor}
                onClick              = {(e) => {onHandleNavItemClick(e)}}
              >
                {subsection.header}
              </NavigationBar.Button>
            )
          );
        }
      )}
    </NavigationBar.Wrapper>
  );
};

NavigationBar.Wrapper = styled(AppBar)`
  background-color: ${({ theme }) => theme.colors.primary.white};
  box-shadow      : 0 2px 8px 0 rgb(0 0 0 / 10%);
  display         : flex;
  flex-direction  : row;
  justify-content : center;
  padding-bottom  : 0px;
  border-radius   : 0px;
`;

NavigationBar.Button = styled.a`
  text-decoration    : none;
  color              : ${({ theme }) => theme.colors.type.medium};
  font-weight        : 700;
  margin             : 0 1px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  transition         : all 200ms ease-in-out;
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    font-size: 13px;
    padding  : 13px 6px;
    &:first-of-type {
      margin-left: 0px;
    }
    &:last-of-type {
      margin-right: 0px;
    }
  }

  ${({theme: {mediaQueries: {md}}}) => md} {
    font-size: 16px;
    padding  : 28px 16px;
  }
  

  &.active-scroll-spy {
    border-bottom-color: ${({ theme }) => theme.colors.neutralBlue[300]};
    color              : ${({ theme }) => theme.colors.neutralBlue[300]};
  }

  &:hover {
    border-bottom-color: ${({ theme }) => theme.colors.primary.rubineLight};
    color              : ${({ theme }) => theme.colors.primary.rubineLight};
  }
`;