import styled from 'styled-components';

export enum WindDirectionColor {
  Black  = 'Black',
  Grey   = 'Grey',
  LtGrey = 'Lt-Grey',
  NB300  = 'NB-300',
  NB400  = 'NB-400',
  Rubine = 'Rubine',
  White  = 'White',
}

export const WindDirection = styled.div<{
  color?   : WindDirectionColor;
  direction: string;
}>`
  align-items        : center;
  background-position: 0% 50%;
  background-repeat  : no-repeat;
  background-size    : cover;
  display            : flex;
  height             : 16px;
  flex               : 0 0 auto;
  width              : 16px;
  background-image   : ${({ color, direction }) =>
    `url(${
      require(`@images/icons/weather/Icon-Weather-Wind-${direction.toUpperCase()}-${
        color ? color : WindDirectionColor.Black
      }.svg`)
    })`};
`;
