import {
  useCallback,
  useEffect,
  useMemo,
  useState
}                                     from 'react';
import { useParams, useHistory }      from 'react-router';
import { isAfter }                    from 'date-fns';
import { Dialog }                     from '@mui/material';
import { PrivateRideErrorPage }       from '@modules/Details/components/PrivateRideErrorPage';
import { LoaderWrapper }              from '@components/LoaderWrapper';
import {
  cutDateFromISO, getDateUTCFromISO,
}                                     from '@utils/time_utils';
import { IRouteModel }                from '@models/RouteModel';
import { ConfirmationModal }          from '@components/ConfirmationModal';
import { useGetSelfInformationQuery } from '@modules/Profile/queries';
import { useSessionStorage }          from '@utils/hooks/useSessionStorage';
import {
  RIGHT_PANEL_CONTENT,
  RIGHT_PANEL_CONTENT_TYPE
}                                     from '../../models/rightPanelContent';
import {
  useCancelPlannedRideMutation,
  useGetNearestRideDetailsQuery,
  useGetRideDetailsQuery,
  useGetRouteDetailsQuery,
  usePutRideInvitationResponseMutation
}                                     from '../../queries';
import {
  IInvitationLinkData,
  INVITATION_STATUS,
  RIDE_INVITATION_DATA_KEY,
}                                     from '../../models/invitation';
import {
  EFrequencyType,
  IRide,
  INearestRideDetails,
}                                     from '../../models/ride';
import { Content }                    from '../../components/Content';
import { Footer }                     from '../../components/Footer';
import { DetailsContext }             from '../../context';
import { useAuth }                    from '../../../Auth/contexts/AuthContext';
import { Header }                     from '../../components/Header';

export const RideDetailsContainer = () => {
  const { currentUser }                           = useAuth();
  const isAuthorized                              = !!currentUser;
  const { getItem, setItem }                      = useSessionStorage();
  const [cancelRide]                              = useCancelPlannedRideMutation();
  const [updateInvitation]                        = usePutRideInvitationResponseMutation();
  const { data: selfInfo }                        = useGetSelfInformationQuery(undefined, { skip: !isAuthorized });
  const { rideId, rideDate, linkUuid }            = useParams<{ rideId: string; rideDate: string; linkUuid?: string; }>();
  const { location: { state }, push }             = useHistory<{ prevState: string }>();
  const [isCancelModalOpen, setCancelModalStatus] = useState<boolean>(false);
  const [rightPanelContent, setRightPanelContent] = useState<RIGHT_PANEL_CONTENT_TYPE>(RIGHT_PANEL_CONTENT_TYPE.RIDE_DETAILS);
  const previousContent                           = RIGHT_PANEL_CONTENT[rightPanelContent].previousContent;

  const {
    data      : rideDetails,
    isLoading : isRideDetailsLoading,
  } = useGetRideDetailsQuery({
    id      : linkUuid ? linkUuid : rideId,
    isShared: Boolean(linkUuid)
  });

  const {
    data      : nearestRideDetails,
    isLoading : isNearestRideDetailsLoading,
    error     : nearestRideDetailsError
  } = useGetNearestRideDetailsQuery(
    {
      id      : linkUuid ? linkUuid : rideId,
      isShared: Boolean(linkUuid),
      rideDate,
    },
    {
      pollingInterval: 5000,
    }
    );

  const {
    data     : routeDetailsData,
    isLoading: isRouteDetailsLoading,
  } = useGetRouteDetailsQuery(
    { id: linkUuid ? linkUuid : rideDetails?.routeId ?? '', isShared: Boolean(linkUuid) },
    { skip: !rideDetails?.routeId }
    );

  const isOrganizer = selfInfo?.id === rideDetails?.organizer?.id;

  useEffect(
    () => {
      if (!isAuthorized) {
        const invitationData = getItem<IInvitationLinkData>(RIDE_INVITATION_DATA_KEY);

        if (invitationData && rideDetails) {
          const plannedRideId = rideDetails?.id;
          const eventDate     = cutDateFromISO(nearestRideDetails?.date ?? '');

          const newData: IInvitationLinkData = {
            ...invitationData,
            plannedRideId,
            eventDate,
          };

          setItem(RIDE_INVITATION_DATA_KEY, newData);
        }
      }
    },
    [getItem, isAuthorized, nearestRideDetails?.date, rideDetails, setItem],
  );

  const isLoading = useMemo(
    () => isRideDetailsLoading || isNearestRideDetailsLoading || isRouteDetailsLoading,
    [isNearestRideDetailsLoading, isRideDetailsLoading, isRouteDetailsLoading]
  );

  RideDetailsContainer.changeRightContent = (rightPanelContent: RIGHT_PANEL_CONTENT_TYPE) => {
    setRightPanelContent(rightPanelContent);
  };

  const hasAccessToViewRide = useMemo(
    () => {
      switch (true) {
        case (nearestRideDetailsError as { status: number })?.status === 400: {
          return false;
        }
        case rideDetails?.isPublic: {
          return true
        }
        case !isAuthorized && !linkUuid: {
          return false
        }
        case isAuthorized || Boolean(linkUuid): {
          return true
        }
        default: {
          return false
        }
      }
    },
    [isAuthorized, linkUuid, nearestRideDetailsError, rideDetails?.isPublic]
  );


  const changeAttendanceStatus = useCallback(
    (status: INVITATION_STATUS) => {
      if (isAuthorized) {
        updateInvitation({
          rideId: rideId || rideDetails?.id || '',
          status,
          date: nearestRideDetails && nearestRideDetails.date.substring(0, 10)
        });
      } else {
        if (status === INVITATION_STATUS.ACCEPTED) {
          const plannedRideId = rideDetails?.id;
          const eventDate     = cutDateFromISO(nearestRideDetails?.date ?? '');

          const newData: IInvitationLinkData = {
            plannedRideId,
            eventDate,
          };

          setItem(RIDE_INVITATION_DATA_KEY, newData);
        }

        push('/signup');
      }
    },
    [
      isAuthorized,
      setItem,
      nearestRideDetails,
      push,
      rideDetails?.id,
      rideId,
      updateInvitation
    ],
  );

  const isRideStarted = useMemo(
    () => {
      if (nearestRideDetails) {
        const today          = new Date();
        const rideDateLocal  = getDateUTCFromISO(nearestRideDetails.date);

        return !isAfter(rideDateLocal, today);
      }

      return true;
    },
    [nearestRideDetails]
  );

  const handleConfirmCancelRide = useCallback(
    () => {
      cancelRide({ rideId })
        .unwrap()
        .then(() => {
          setCancelModalStatus(false);

          push(state?.prevState || '');
      });
    },
    [
      cancelRide,
      push,
      rideId,
      state?.prevState
    ],
  );

  const Details = useMemo(
    () => (
      <Dialog
        open
        fullScreen
      >
        <DetailsContext.Provider
          value={{
            rideDetails       : rideDetails as IRide,
            nearestRideDetails: nearestRideDetails as INearestRideDetails,
            routeDetails      : routeDetailsData as IRouteModel,
            isPreview         : !isAuthorized,
            rideDate,
            rideId,
            isOrganizer,
          }}
        >
          <Header
            isRideStarted          = {isRideStarted}
            isOrganizer            = {isOrganizer}
            setCancelModalStatus   = {setCancelModalStatus}
            changeAttendanceStatus = {changeAttendanceStatus}
            isPreview              = {!isAuthorized}
            hide                   = {!!previousContent}
            onClose                = {() => push(state?.prevState || '')}
            hideShare              = {Boolean(linkUuid)}
          />
          <Content
            rightPanelContent = {rightPanelContent}
            isRecurringRide   = {rideDetails?.frequency === EFrequencyType.WEEKLY && !linkUuid}
          />

          {!previousContent && (
            <Footer
              isRideStarted          = {isRideStarted}
              isOrganizer            = {isOrganizer}
              setCancelModalStatus   = {setCancelModalStatus}
              changeAttendanceStatus = {changeAttendanceStatus}
            />
          )}
        </DetailsContext.Provider>

        {isCancelModalOpen &&
          <ConfirmationModal
            onSubmit         = {handleConfirmCancelRide}
            onCancel         = {() => setCancelModalStatus(false)}
            title            = "Are you sure you want to cancel the ride?"
            description      = "This action will remove the ride from all ride calendars."
            submitButtonText = "Yes, cancel"
            cancelButtonText = "Don't cancel the ride"
          />
        }
      </Dialog>
    ),
    [
      changeAttendanceStatus,
      handleConfirmCancelRide,
      isAuthorized,
      isCancelModalOpen,
      isRideStarted,
      linkUuid,
      nearestRideDetails,
      previousContent,
      push,
      rideDate,
      rideDetails,
      rideId,
      rightPanelContent,
      routeDetailsData,
      state?.prevState,
      isOrganizer
    ]
  );

  const renderDetails = useCallback(
    () => hasAccessToViewRide ? Details : <PrivateRideErrorPage />,
    [Details, hasAccessToViewRide]
  );

  return (
    <>
      <LoaderWrapper isLoading={isLoading}>
        {renderDetails()}
      </LoaderWrapper>
    </>
  );
};

RideDetailsContainer.changeRightContent = (_rightPanelContent: RIGHT_PANEL_CONTENT_TYPE) => {};
