import {
  useState,
  useMemo,
  useCallback
}                                    from 'react';
import { StreamChat }                from 'stream-chat';
import { connect }                   from 'getstream';
import {
  useGetGetStreamTokenQuery,
  useGetSelfInformationQuery
}                                    from '@modules/Profile/queries';
import {
  getStreamKey,
  getStreamAppId,
}                                    from '@config/api_config';
import {
  Attachments,
  IPost,
  ISubmitActivity,
}                                    from '@models/Feed/post';
import { getDiffObject }             from '@utils/commonFunctions';
import { axiosBaseQuery }            from '@utils/baseQuery';
import { METHOD }                    from '@models/Request';

export const useGetStream = () => {
  const { data: tokenData }               = useGetGetStreamTokenQuery();
  const { data: self }                    = useGetSelfInformationQuery();
  const [messagesCount, setMessagesCount] = useState<number>(0);
  const [isLoading, setIsLoading]         = useState<boolean>(false);

  const chatClient = StreamChat.getInstance(getStreamKey || '', {
    enableInsights  : true,
    enableWSFallback: true,
  });

  const feedClient = useMemo(
    () => {
      if (self?.id && tokenData?.token) {
        const client = connect(getStreamKey ?? '', tokenData?.token, getStreamAppId ?? '');
        const feed   = client.feed('owner', self?.id ?? '', tokenData?.token ?? '');

        return feed;
      }

      return null;
    },
    [self?.id, tokenData?.token]
  );

  const uploadImageViaGetStream = useCallback(
    async (file: File) => {
      if (feedClient) {
        const { file: url } = await feedClient.client.images.upload(file);

        return url;
      }

      return '';
    },
    [feedClient]
  );

  const uploadImages = useCallback(
    async (files: File[]) => {
      let result: string[] = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        const url = await uploadImageViaGetStream(file);

        result.push(url);
      }

      return result;
    },
    [uploadImageViaGetStream]
  );

  const handleCreatePost = useCallback(
    async ({
      text,
      og,
      location,
      images,
      mentions,
    }: ISubmitActivity) => {
      const attachments = {} as Attachments;

      if (og) {
        attachments.og = og;
      }

      if (images.length) {
        const files = images.map(image => image.file);
        attachments.images = await uploadImages(files);
      }

      return feedClient?.addActivity({
        text,
        actor        : `SU:${self?.id ?? ''}`,
        verb         : 'post',
        object       : {},
        location     : location || null,
        mentions,
        attachments,
      });
    },
    [feedClient, self?.id, uploadImages]
  );

  const handleUpdatePost = useCallback(
    async ({
      activity,
      images,
      location,
      mentions,
      text,
      og
    }: ISubmitActivity & { activity: IPost }) => {
      const oldImages = images.filter(({ state }) => state === 'uploaded').map(({ url }) => url);
      const newImages = images.filter(({ state }) => state === 'finished').map(({ file }) => file);

      const attachments = {
        ...activity.attachments,
        og,
        images: [
          ...oldImages,
          ...await uploadImages(newImages)
        ],
      };

      const updatedFields = getDiffObject<Pick<IPost, 'text' | 'location' | 'attachments' | 'mentions'>>({ attachments, location, text, mentions }, activity);

      return axiosBaseQuery({
        url   : `feeds/activities/${activity.id}`,
        method: METHOD.PATCH,
        body  : updatedFields,
      });
    },
    [uploadImages]
  );

  const handleUpdateComment = useCallback(
    (id: string, text: string) => feedClient?.client.reactions.update(id, { text }),
    [feedClient?.client.reactions]
  );

  return {
    chatClient,
    feedClient,
    setMessagesCount,
    handleUpdateComment,
    messagesCount,
    handleCreatePost,
    handleUpdatePost,
    isLoading,
    token: tokenData?.token
  }
};
