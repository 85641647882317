export enum EProcessingJobStatus {
  DOWNLOADING         = 'DOWNLOADING',
  UPLOADING           = 'UPLOADING',
  DOWNLOADING_FROM_S3 = 'DOWNLOADING_FROM_S3',
  UNZIPPING           = 'UNZIPPING',
  PROCESSING          = 'PROCESSING',
  COMPLETE            = 'COMPLETE'
}

export interface IProcessingJob {
  id                 : string,
  userId             : string,
  status             : EProcessingJobStatus,
  name               : string,
  s3KeyName          : string,
  duplicateRideNames : string | null;
  dateCreated        : string;
}
