import { useState }        from 'react';
import { Link }            from 'react-router-dom';
import styled              from 'styled-components';

import {
  Tabs,
  Tab,
  IconButton,
  Box,
  Menu,
  ListItemButton,
  ListItemText,
  List,
  ListItemIcon
}                           from '@mui/material';
import MenuIcon             from '@mui/icons-material/Menu';
import { useAuth }          from '../../../Auth/contexts/AuthContext';
import { getUserAvatarUrl } from '../../../../utils/files_utils';
import { UserTitle }        from '../../../../components/UserTitle';
import { tabsData }         from '../..';
import { ISettingsForm }    from '../../models/Settings';
import { UserGallery }      from '../UserGallery';
import { 
  useGetSelfInformationQuery, 
  useGetUserProfilePhotosQuery 
}                           from '../../queries';

interface IHeaderProps {
  settings?: ISettingsForm;
  pathname : string;
}

export const Header = ({
  settings,
  pathname,
}: IHeaderProps) => {
  const [tabsAnchorEl, setTabsAnchorEl] = useState<HTMLElement | null>(null);
  const { currentUser }                 = useAuth();
  const userInfo                        = {nationality: settings?.nationality, ...settings?.user};
  const { data: self }                  = useGetSelfInformationQuery();
  const { data: profilePhotos }         = useGetUserProfilePhotosQuery({ userId: self?.id ?? '' }, {skip: !self});

  return (
    <>
      <HeaderContainer>
        <UserGallery 
          editable
          userPhoto     = {getUserAvatarUrl(settings?.profilePictureUrl, currentUser?.photoURL || '')}
          profilePhotos = {profilePhotos || []}
        />

        <TabsContainer>
          <UserTitle data={userInfo}/>

          <Tabs 
            value         = {pathname}
            variant       = "scrollable"
            scrollButtons
            sx            = {{ display: { xs: 'none', sm: 'flex' } }}
          >
            {tabsData.map((tab) => (
              <Tab
                key          = {tab.value}
                label        = {tab.label}
                icon         = {tab.icon}
                to           = {tab.value}
                value        = {tab.value}
                component    = {Link}
                iconPosition = "start"
                replace
              />
            ))}
          </Tabs>

          <Box sx={{ display: { xs: 'block', sm: 'none' }}}>
            <IconButton onClick={event => setTabsAnchorEl(event.currentTarget)}>
              <MenuIcon />
            </IconButton>

            <Menu
              open     = {!!tabsAnchorEl}
              onClose  = {() => setTabsAnchorEl(null)}
              anchorEl = {tabsAnchorEl}
            >
              <TabsList>
                {tabsData.map(tab => (
                  <ListItemButton
                    key       = {tab.value}
                    component = {Link}
                    to        = {tab.value}
                    selected  = {pathname === tab.value}
                    onClick   = {() => setTabsAnchorEl(null)}
                  >
                    <ListItemIcon>
                      {tab.icon}
                    </ListItemIcon>
                    <ListItemText>{tab.label}</ListItemText>
                  </ListItemButton>
                ))}
              </TabsList>
            </Menu>
          </Box>
        </TabsContainer>
      </HeaderContainer>
    </>
  )
}

const HeaderContainer = styled.div`
  padding-top: 48px;

  ${({theme: {mediaQueries: {md}}}) => md} {
    padding-top: 64px;
  }

  ${ ({ theme: { mediaQueries: {xl} } }) => xl } {
    padding-top: 64px;
  }
`;

const TabsList = styled(List)`
  .MuiTypography-root {
    font-weight: 700;
  }

  .MuiButtonBase-root {
    color: ${({theme: {colors: {neutralGrey}}}) => neutralGrey[700]};
  }

  .MuiButtonBase-root.Mui-selected path {
    fill: ${({theme: {colors: {neutralBlue}}}) => neutralBlue[300]};
  }

  .MuiButtonBase-root.Mui-selected {
    color: ${({theme: {colors: {neutralBlue}}}) => neutralBlue[300]};
  }
`;

const TabsContainer = styled.div`
    height           : 80px;
    background-color : ${({theme: {colors: {primary: {white}}}}) => white};
    display          : flex;
    justify-content  : space-between;
    align-items      : center;
    padding          : 0 8%;

    .MuiTab-root {
      padding  : 28px 16px;
      font-size: 16px;
    }

    .MuiTab-root:not(.Mui-selected):hover path {
      fill: #ED0D6C;
    }

    .Mui-selected path {
      fill: ${({theme: {colors: {neutralBlue}}}) => neutralBlue[300]};
    }
`;
