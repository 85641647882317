import { ITab } from '@models/tab';

export enum ERideTab {
  NEXT_RIDE    = 'next-ride',
  RIDE_HISTORY = 'ride-history',
  RIDE_INVITE  = 'ride-invite',
}

export const RIDE_TABS: ITab<ERideTab>[] = [{
  label: 'Next Ride',
  value: ERideTab.NEXT_RIDE
}, {
  label: 'Ride History',
  value: ERideTab.RIDE_HISTORY
}];
