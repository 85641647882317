import { useContext }      from 'react';
import {
  Button,
  Grid,
  TextField
}                          from '@mui/material';
import { SettingsContext } from '../../../../../containers/SettingsContainer';
import { FieldWrapper }    from '../../../../FieldWrapper';
import { TitleBlock }      from '../../../../TitleBlock';
import { privacyTabs }     from '../../../../../utils/tabs';

export const MyAccount = () => {
  const {
    register,
    toggleManageAccount
    // formState: { errors }
  } = useContext(SettingsContext);

  return (
    <FieldWrapper>
      <Grid container spacing={4} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={4} md={4} sx={{ display: { xs: 'none', sm: 'block' }}}>
          <TitleBlock
            tabs         = {[privacyTabs[1]]}
            label        = "My Account"
            description  = "Own your presence on Preem by modyfying your account email, password or by deleting your account on Preem"
            tooltip      = ""
          />
        </Grid>

        <Grid item xs={12} sm={7} md={7} lg={7}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={12} sm={8} md={5} lg={8}>
              <TextField
                label   = "Account Email"
                variant = "filled"
                // error   = {!!errors?.user?.email}
                disabled
                fullWidth
                {...register('user.email')}
              />
              {/* {errors.user?.email && <InputError error={errors.user.email.message ?? ''} />} */}
            </Grid>

            <Grid item xs={12} sm="auto" md="auto" lg="auto" textAlign="end">
              <Button
                variant = "outlined"
                onClick = {() => toggleManageAccount()}
              >
                Manage Account
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FieldWrapper>
  );
}
