import {
  useMemo,
  useRef,
}                            from 'react';
import styled                from 'styled-components';
import { ScrollMenu }        from 'react-horizontal-scrolling-menu';
import { addDays, format }   from 'date-fns';
import { getDateUTCFromISO } from '@utils/time_utils';
import { IRideInvitation }   from '@modules/Calendar/models';
import { DayColumn }         from '@modules/Calendar/components/WeekViewer/components/DayColumn';
import { useWindowSize }     from '@utils/hooks/useWindowSize';
import { FloatingArrows }    from '../StickyArrows';

interface IRidePlansProps {
  invitationCards: IRideInvitation[];
  rideCards      : IRideInvitation[];
  weekStart      : Date;
}

/**
 * This does not feel natural when there is content hidden on the vertical axis
 */
// const onWheel = (apiObj: scrollVisibilityApiType, ev: React.WheelEvent) => {
//   const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

//   if (isTouchpad) {
//     ev.stopPropagation();
//     return;
//   }

//   if (ev.deltaY < 0) {
//     apiObj.scrollNext();
//   } else if (ev.deltaY > 0) {
//     apiObj.scrollPrev();
//   }
// }

export const RidePlans = ({
  invitationCards,
  rideCards,
  weekStart,
}: IRidePlansProps) => {
  const calendarRef  = useRef<HTMLDivElement | null>(null);
  const { isMobile } = useWindowSize();

  const daysArray = useMemo(
    () =>
      [...Array(7)].map((_value, index: number) =>
        format(addDays(weekStart, index), "yyyy-MM-dd")
      ),
    [weekStart]
  );

  const rideCardsByDays = useMemo(
    () => {
      const result = daysArray.reduce((acc: any[], currentDate: string) => {
        const matchingCards = rideCards.filter(rideCard => {
          const rideDateFormatted = format(
            getDateUTCFromISO(rideCard.ride?.startDate ?? ''),
            'yyyy-MM-dd'
          );

          return rideDateFormatted === currentDate;
        });

        if (acc.length) {
          acc = [...acc, matchingCards];
        } else {
          acc = [matchingCards];
        }

        return acc;
      }, []);

      return result;
    },
    [daysArray, rideCards]
  );

  const invitationsByDays = useMemo(
    () => {
      const result = daysArray.reduce((acc: any[], currentDate: string) => {
        const matchingInvitations = invitationCards.filter(invitationCard => {
          const rideDateFormatted = format(
            getDateUTCFromISO(invitationCard.ride?.startDate ?? ''),
            'yyyy-MM-dd'
          );

          return rideDateFormatted === currentDate;
        });

        if (acc.length) {
          acc = [...acc, matchingInvitations];
        } else {
          acc = [matchingInvitations];
        }

        return acc;
      }, []);

      return result;
    },
    [daysArray, invitationCards]
  );

  return isMobile ? (
    <>
      {daysArray.map((date, index) => (
        <DayColumn
          key             = {date}
          date            = {addDays(weekStart, index)}
          dayIndex        = {index}
          invitationCards = {invitationsByDays[index]}
          rideCards       = {rideCardsByDays[index]}
          isMobile        = {isMobile}
        />
      ))}
    </>
  ) : (
    <div ref={calendarRef}>
      <ScrollMenu>
        {daysArray.map((date, index) => (
          <DayColumn
            key             = {date}
            date            = {addDays(weekStart, index)}
            dayIndex        = {index}
            invitationCards = {invitationsByDays[index]}
            rideCards       = {rideCardsByDays[index]}
            isMobile        = {false}
          />
        )) as any}
      <FloatingArrows height={calendarRef.current?.clientHeight ?? 800} />
      </ScrollMenu>
    </div>
  );
};

RidePlans.OutlineLeftEmptyContainer = styled.div`
  margin-top: 360.5px;

  ${({theme: {mediaQueries: { xs }}}) => xs} {
    padding-left: 20px;
  }
  ${({theme: {mediaQueries: { md }}}) => md} {
    padding-left: 20px;
  }
  ${({theme: {mediaQueries: { lg }}}) => lg} {
    padding-left: 4%;
  }
  ${({theme: {mediaQueries: { xl }}}) => xl} {
    padding-left: 8%;
  }
  ${({theme: {mediaQueries: { xxl }}}) => xxl} {
    padding-left: 16%;
  }
`;

RidePlans.OutlineRightEmptyContainer = styled.div`
  width: 100%;
  margin-top: 360.5px;
  ${({theme: {mediaQueries: { xs }}}) => xs} {
    padding-right: 20px;
  }
  ${({theme: {mediaQueries: { md }}}) => md} {
    padding-right: 20px;
  }
  ${({theme: {mediaQueries: { lg }}}) => lg} {
    padding-right: 4%;
  }
  ${({theme: {mediaQueries: { xl }}}) => xl} {
    padding-right: 8%;
  }
  ${({theme: {mediaQueries: { xxl }}}) => xxl} {
    padding-right: 16%;
  }
`;

RidePlans.OuterContainerOutline = styled.div<{ $width: string }>`
  height          : 360.5px;
  background-color: transparent;
  width           : ${({ $width }) => $width};
  position        : absolute;
  box-shadow      : 0 1px 8px 0 rgb(0 0 0 / 4%), 0 4px 16px 0 rgb(0 0 0 / 8%);
  pointer-events  : none;
`;
