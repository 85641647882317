import {
  useCallback,
  useEffect,
  useMemo,
  useState
}                                     from 'react';
import {
  addDays,
  format,
  getDate,
  nextMonday,
  nextSunday,
  previousMonday,
  previousSunday,
  parseISO,
  differenceInMinutes
}                                     from 'date-fns';
import { orderBy }                    from 'lodash';
import { getDateUTCFromISO }          from '@utils/time_utils';
import { LoaderWrapper }              from '@components/LoaderWrapper';
import { IRideInvitation }            from '../../models';
import { IMetricsData }               from '../WeekPicker/components/WeekPickerMetrics';
import { INVITATION_STATUS }          from '../../../Details/models/invitation';
import { useGetRideInvitationsQuery } from '../../../Details/queries';
import { WeekPicker }                 from '../WeekPicker';
import { RidePlans }                  from './components/RidePlans';
import { useGetSelfInformationQuery } from '../../../Profile/queries';

export const WeekViewer = () => {
  const weekStartMonday                          = true; /** TODO: Post-MVP, this gets hooked up to the settings API */
  const [activeWeek, setActiveWeek]              = useState({ startDate: new Date(), endDate: addDays(new Date(), 6) });
  const weekStartFormatted                       = format(activeWeek.startDate, 'yyyy-MM-dd');
  const weekEndFormatted                         = format(activeWeek.endDate, 'yyyy-MM-dd');
  const { data: self, isLoading: isLoadingSelf } = useGetSelfInformationQuery();
  const {
    data     : invitations,
    isLoading: isLoadingInvitations,
    isSuccess,
    refetch,
  } = useGetRideInvitationsQuery(
    { from: weekStartFormatted, to: weekEndFormatted },
    { pollingInterval: 10000 }
  );

  const isCurrentWeekActive = format(new Date(), "yyyy-MM-dd") === format(activeWeek.startDate, "yyyy-MM-dd");

  const startDate = getDate(activeWeek.startDate),
    startMonth    = format(activeWeek.startDate, 'MMMM'),
    endDate       = format(activeWeek.endDate, 'd'),
    endMonth      = format(activeWeek.endDate, 'MMMM');

  const activeWeekLabel = `${startMonth} ${startDate} - ${startMonth === endMonth ? '' : `${endMonth} `}${endDate}`;
  const isLoadingData   = isLoadingInvitations || isLoadingSelf;

  const setCurrentWeekActive = useCallback(
    () => {
      const startDate = new Date();
      const endDate   = addDays(startDate, 6);

      setActiveWeek({ startDate, endDate });
    },
    []
  );

  const setNextWeekActive = useCallback(
    () => {
      const nextWeekStart = weekStartMonday ? nextMonday(activeWeek.startDate) : nextSunday(activeWeek.startDate);
      const nextWeekEnd   = addDays(nextWeekStart, 6);

      setActiveWeek({ startDate: nextWeekStart, endDate: nextWeekEnd})
    },
    [activeWeek.startDate, weekStartMonday],
  );

  const setPrevWeekActive = useCallback(
    () => {
      const previousWeekStart = weekStartMonday
        ? previousMonday(activeWeek.startDate)
        : previousSunday(activeWeek.endDate);
      const previousWeekEnd   = addDays(previousWeekStart, 6);

      setActiveWeek({ startDate: previousWeekStart, endDate: previousWeekEnd});
    },
    [activeWeek.endDate, activeWeek.startDate, weekStartMonday]
  );

  useEffect(
    () => {
      setCurrentWeekActive();
    },
    [] // eslint-disable-line
  );

  // update invitations on week change
  useEffect(
    () => {
      refetch();
      // updateRidesAndInvitations();
    },
    [activeWeek.startDate] // eslint-disable-line
  );

  const [rideCards, invitationCards] = useMemo(
    () => {
      let rideCardBuilder       : IRideInvitation[] = [];
      let invitationCardBuilder : IRideInvitation[] = [];

      if (invitations && weekStartFormatted !== weekEndFormatted) {
        const invitationDates: string[] = Object.keys(invitations);

        invitationDates.forEach((invitationDate) => {
          invitations[invitationDate].forEach((invitation: IRideInvitation) => {
              const invitationLocalStartTime = format(getDateUTCFromISO(invitation.ride.startDate), 'yyyy-MM-dd');
              const isInvitationInActiveWeek = invitationLocalStartTime >= weekStartFormatted && invitationLocalStartTime <= weekEndFormatted;

              if (self?.id === invitation.organizer.id) {
                isInvitationInActiveWeek && rideCardBuilder.push(invitation);
              } else {
                const isInvitationPending = invitation.invitationStatus === INVITATION_STATUS.DECLINED ||
                  invitation.invitationStatus === INVITATION_STATUS.PENDING

                if (isInvitationPending) {
                  isInvitationInActiveWeek && invitationCardBuilder.push(invitation);
                } else {
                  isInvitationInActiveWeek && rideCardBuilder.push(invitation);
                }
              }
          });
        });

        if (rideCardBuilder && rideCardBuilder.length) {
          rideCardBuilder = orderBy(rideCardBuilder, [(card) => card.ride.startDate], ['asc']);
        }

        if (invitationCardBuilder && invitationCardBuilder.length) {
          invitationCardBuilder = orderBy(invitationCardBuilder, [(card) => card.ride.startDate], ['asc']);
        }
      }

      return [rideCardBuilder, invitationCardBuilder];
    },
    [invitations, self?.id, weekEndFormatted, weekStartFormatted],
  );

  const metrics = useMemo<IMetricsData>(
    () => {
      const { totalElevation, totalDistance, totalMinutes, validCardCounter } = rideCards.reduce((accum, rideCard) => {
        if (rideCard.invitationStatus !== INVITATION_STATUS.DECLINED && rideCard.ride.startDate) {
          accum.totalElevation += rideCard.ride.elevationGain || 0;
          accum.totalDistance  += rideCard.ride.distance || 0;
          accum.totalMinutes   += differenceInMinutes(parseISO(rideCard.ride.endDate), parseISO(rideCard.ride.startDate));
          accum.validCardCounter++;
        }

        return accum;
      }, {
        totalElevation  : 0,
        totalDistance   : 0,
        totalMinutes    : 0,
        validCardCounter: 0,
      });

      const totalHours       = Math.floor(totalMinutes / 60);
      const remainingMinutes = totalMinutes - (totalHours * 60);

      return {
        distance: totalDistance,
        ascent  : totalElevation,
        hours   : totalHours,
        minutes : remainingMinutes,
        rides   : validCardCounter || 0
      };
    },
    [rideCards],
  );

  return (
    <>
      <LoaderWrapper isLoading={isLoadingData}>
        <WeekPicker
          activeWeekLabel     = {activeWeekLabel}
          isCurrentWeekActive = {isCurrentWeekActive}
          metrics             = {metrics}
          onBackClick         = {setPrevWeekActive}
          onForwardClick      = {setNextWeekActive}
          onTodayClick        = {setCurrentWeekActive}
        />

        {isSuccess && <RidePlans
          invitationCards  = {invitationCards}
          rideCards        = {rideCards}
          weekStart        = {activeWeek.startDate}
        />}

      </LoaderWrapper>
    </>
  );
};
