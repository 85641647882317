import { useLayoutEffect, useState } from 'react';
import { throttle }                  from 'lodash';
import styled                        from 'styled-components';
import { 
  Button, 
  Grid, 
  CircularProgress
}                                    from '@mui/material';

interface ISaveFormMessage {
  isLoading: boolean;
  onCancel : () => void;
  onSubmit : () => void;
}

export const SaveFormMessage = ({ 
  isLoading,
  onCancel, 
  onSubmit
}: ISaveFormMessage) => {
  const [isVisible, setIsVisible] = useState(false);

  useLayoutEffect(() => {
    const handleScroll = throttle(() => {
      const footer = document.getElementById('footer');

      if (!footer) {
        setIsVisible(false);
      }

      const top = footer?.getBoundingClientRect().top || 0;
      const visibility = top > 0 && top < window.innerHeight;
      setIsVisible(visibility);
    }, 250);

    window.addEventListener("scroll", handleScroll);
    
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return(
    <SaveFormMessage.Wrapper position={isVisible ? 'static' : 'fixed'}>
      <Grid container alignItems="center" rowSpacing='10px'>
        <Grid
          item
          container 
          xs={12} 
          sm 
          direction="column"
        >
          <Grid item>
            <h4><p>You have made changes</p></h4>
          </Grid>
          <Grid item>
            <p>Make sure to press the “Save Changes” button to confirm your changes.</p>
          </Grid>
        </Grid>

        <Grid
          item
          container
          md
          sx             = {{ justifyContent: { xs: 'center', md: 'flex-end' }}}
          columnSpacing  = "14px"
        >
          <Grid item>
            <Button 
              color    = "secondary"
              variant  = "contained"
              disabled = {isLoading}
              onClick  = {onCancel}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <SaveFormMessage.Button 
              variant = "contained"
              onClick = {onSubmit}
            >
              {isLoading ? 
                <CircularProgress size={25} color="inherit"/>
                : <>Save changes</>
              }
            </SaveFormMessage.Button>
          </Grid>
        </Grid>
      </Grid>
    </SaveFormMessage.Wrapper>
  );
}

SaveFormMessage.Wrapper = styled.div<{position: string}>`
  background-color: ${({ theme: {colors: {type: {dark} } } }) => dark};
  color           : ${({ theme: {colors: {type: {light} } } }) => light};
  padding         : 11px 0;
  bottom          : 0;
  width           : 100%;
  z-index         : 1000;
  position        : ${({position}) => position};

  ${({theme: {mediaQueries: {sm}}}) => sm } {
    padding: 18px 0;
  }

  & > .MuiGrid-container {
    margin : 0 auto;
    width  : 84%;
  }

  h4 {
    margin: 0;
    color : ${({theme: {colors: {primary: {white} } } }) => white};
  }

  p {
    text-align: center;

    ${({theme: {mediaQueries: {md}}}) => md } {
      text-align: left;
    } 
  }
`;

SaveFormMessage.Button = styled(Button)`
  &.MuiButton-root{
    padding: 8px 12px;
  }
`;