import {
  useMemo,
  useState
}                                  from 'react';
import { useHistory }              from 'react-router';
import styled, { css }             from 'styled-components';
import {
  Grid,
  IconButton,
  Link,
  Tab,
  Tabs
}                                  from '@mui/material';
import MoreVertIcon                from '@mui/icons-material/MoreVert';
import { profilePicturesHost }     from '@config/api_config';
import { IResponseWithPagination } from '@models/types';
import { ERideStatus }             from '@modules/Details/models/ride';
import { Avatar }                  from '@components/Avatar';
import { Loader }                  from '@components/Loader';
import {
  ATTENDANCE_TABS,
  IInvitation,
  INVITATION_ICONS,
  INVITATION_STATUS
}                                  from '../../../models/invitation';
import {
  useGetRideAttendanceQuery,
  useGetRideInviteesQuery,
}                                  from '../../../queries';
import { useDetails }              from '../../../context';

export const Attendance = () => {
  const { push }                    = useHistory();
  const [currentTab, setCurrentTab] = useState<INVITATION_STATUS | null>(null);
  const {
    rideId,
    nearestRideDetails,
    rideDate
  } = useDetails();

  const { data: invitees, isFetching: isInviteesFetching } = useGetRideInviteesQuery({
    rideId,
    date       : rideDate,
    pageNumber : 0,
    pageSize   : 50 // TEMP,
  }, { skip: nearestRideDetails.status === ERideStatus.COMPLETED });
  
  const { data: attendees, isFetching: isAttendeesFetching } = useGetRideAttendanceQuery({ 
    rideId, 
    date       : rideDate,
    pageNumber : 0,
    pageSize   : 50 // TEMP
  }, { skip: nearestRideDetails.status !== ERideStatus.COMPLETED });

  const data: IResponseWithPagination<any[]> = useMemo(
    () => invitees || attendees || {} as IResponseWithPagination<any[]>,
    [attendees, invitees]
  );

  const isFetching = useMemo(
    () => isInviteesFetching || isAttendeesFetching || false,
    [isAttendeesFetching, isInviteesFetching]
  );

  return (
    <Attendance.Container>
      {nearestRideDetails.status !== ERideStatus.COMPLETED && (
        <Attendance.Tabs
          value    = {currentTab}
          onChange = {(_event, value) => setCurrentTab(value)}
        >
          {ATTENDANCE_TABS.map(tab => {
            const amount = tab.value
            ? data?.content?.filter((user: { status: string }) => user.status === tab.value).length
            : data?.content?.length;

            return (
              <Tab
                iconPosition = "start"
                key          = {tab.value}
                value        = {tab.value}
                icon         = {tab.icon}
                label        = {<b>{amount || 0}</b>}
                disabled     = {!amount && !!tab.value}
              />
          )})}
        </Attendance.Tabs>
      )}

      {!isFetching && !!data?.content?.length && (
        <Attendance.Table>
          {data?.content?.filter((invitation: any) => invitation.status === currentTab || !currentTab).map((invitation: IInvitation, index: number) => (
            <Attendance.Invitation
              container
              key            = {index}
              justifyContent = "space-between"
              alignItems     = "center"
              p              = "15px 60px 15px 50px"
            >
              <Grid item container xs="auto" alignItems="center" columnSpacing="10px">
                <Grid item>
                  <Avatar
                    photoUrl = {invitation.profilePictureUrl && `${profilePicturesHost}${invitation.profilePictureUrl}`}
                    size     = {40}
                    name     = {invitation.lastName}
                  />
                </Grid>

                <Grid item>
                  <Link onClick = {() => push(`/user/${invitation.username}`)}>
                    {invitation.firstName} {invitation.lastName}
                  </Link>
                </Grid>
              </Grid>

              <Grid item container alignItems="center" xs="auto">
                {INVITATION_ICONS[invitation.status]}

                <IconButton>
                  <MoreVertIcon />
                </IconButton>
              </Grid>
            </Attendance.Invitation>
          ))}
        </Attendance.Table>
      )}

      {isFetching && <Loader />}

      {!isFetching && !data?.content?.length && (
        <Attendance.EmptyValue>
          <b>No Attendees to Display</b>
        </Attendance.EmptyValue>
      )}
    </Attendance.Container>
  )
}

Attendance.Container = styled.div`
  margin    : 0 -20px 0 -20px;
  text-align: center;
  height    : calc(100% - 60px);

  ${({ theme: {mediaQueries: { sm }}}) => sm } {
    margin: 0 -55px 0 -40px;
    height: calc(100% - 108px);
  }
`;

Attendance.Tabs = styled(Tabs)`
  box-shadow      : 0px 1px 2px rgb(0 0 0 / 4%), 0px 4px 8px rgb(0 0 0 / 4%);
  background-color: ${({ theme: {colors: { neutralGrey }}}) => neutralGrey[100] };

  .MuiTabs-flexContainer {
    justify-content: center;
  }

  .MuiTab-root {
    color    : ${({ theme: {colors: {type: { dark }}}}) => dark };
    min-width: 35px;
  }

  .Mui-disabled {
    opacity: 0.4;
  }
`;

Attendance.Invitation = styled(Grid)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralGrey[400]};
`;

Attendance.EmptyValue = styled.div`
  ${({theme: {fontSizes: { l }, colors: {type: { medium }}}}) => css`
    font-size  : ${l};
    color      : ${medium};
    padding-top: 40px;
  `}
`;

Attendance.Table = styled.div`
  overflow-y: auto;
  height    : calc(100% - 52px);

  ${({theme: {mediaQueries: { sm }}}) => sm} {
    height: 100%;
  }
`;

