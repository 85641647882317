import {
  useCallback,
  useState
}                          from 'react';
import {
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
  StaticDateRangePicker
}                          from '@mui/lab';
import { 
  Grid, 
  TextField, 
  TextFieldProps 
}                          from '@mui/material';
import AdapterDateFns      from '@mui/lab/AdapterDateFns';
import styled, { css }     from 'styled-components';
import { format }          from 'date-fns';
import { Modal }           from '../Modal';
import { scrimBlack }      from '../../styles/theme';

interface IDateRangePickerProps {
  isOpen      : boolean;
  defaultValue: [Date, Date];
  onClose     : () => void;
  onSave      : (values: [Date, Date]) => void;
}

const DAY_TYPES = ['Today', 'Selected Day'];

export const DateRangePickerModal = ({
  isOpen,
  defaultValue = [new Date(), new Date()],
  onClose,
  onSave
}: IDateRangePickerProps) => {
  const [values, setValues] = useState<[Date, Date]>(defaultValue);

  const handleSave = useCallback(
    () => {
      onSave(values);

      onClose();
    },
    [onClose, onSave, values]
  );

  const handleSelectDates = (dates: [Date | null, Date | null]) => {
    if (Number(dates[1]) > new Date().getTime()) return;

    setValues([dates[0] || values[0], dates[1] || values[1]] as [Date, Date]);
  };

  const renderDay = (date: Date, pickersDayProps: PickersDayProps<Date> & React.RefAttributes<HTMLDivElement>) => {
    const isSelected = date >= values[0] && date <= values[1];
    const isBoundary = date.toDateString() === values[0].toDateString() || date.toDateString() === values[1].toDateString();
    const isDisabled = date > new Date();

    return (
      <DateRangePickerModal.Day
        key        = {date.toString()}
        isBoundary = {isBoundary}
        isSelected = {isSelected}
        isDisabled = {isDisabled}
      >
        <div />

        <PickersDay {...pickersDayProps} showDaysOutsideCurrentMonth disabled={isDisabled} /> 
      </DateRangePickerModal.Day>
    );
  };

  return (
    <Modal
      isOpen         = {isOpen}
      width          = "fit-content"
      itemName       = "Start Dates"
      submitBtnLabel = "Select Dates"
      title          = {`${format(values[0], 'yyyy, MMM dd')} - ${format(values[1], 'yyyy, MMM dd')}`}
      onClose        = {onClose}
      onSave         = {handleSave}
    >
      <DateRangePickerModal.Content>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDateRangePicker
            displayStaticWrapperAs = "desktop"
            value                  = {values}
            onChange               = {handleSelectDates}
            renderDay              = {renderDay}
            renderInput            = {(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <DateRangePickerModal.DayTypesBlock>
          <Grid container>
            {DAY_TYPES.map(type => (
              <Grid key={type} item>
                <div />

                <span>{type}</span>
              </Grid>
            ))}
          </Grid>
        </DateRangePickerModal.DayTypesBlock>
      </DateRangePickerModal.Content>
    </Modal>
  );
}

DateRangePickerModal.Content = styled.div`
  ${({theme: {colors: {neutralGrey, type: {medium}}, fontSizes: {m}}}) => css`

    .MuiCalendarPicker-root {
      max-height: 57vh;
    }

    .MuiCalendarPicker-root div:first-child {
      padding: 0;
    }

    .MuiCalendarPicker-root div[role='presentation'] {
      background-image: linear-gradient(180deg, ${neutralGrey[400]}, ${neutralGrey[500]});
      border-radius   : 20px;
      box-shadow      : 0 1px 2px 0 rgb(0 0 0 / 4%), 0 4px 8px 0 rgb(0 0 0 / 4%);
      color           : ${medium};
      font-size       : ${m};
      padding-left     : 16px;
    }

    .MuiCalendarPicker-root div[role='row'] {
      margin-bottom: 12px;
      padding-top  : 1px;
    }
  `};
`;

DateRangePickerModal.DayTypesBlock = styled(Grid)`
  ${({theme: {colors: {type: {medium}, primary: {rubineLight}}}}) => css`
    padding  : 0 24px;
    font-size: 9px;
    color    : ${medium};

    .MuiGrid-container {
      border-top: 1px solid ${scrimBlack(0.08)};
      padding   : 8px 0 20px;
    }

    .MuiGrid-item {
      align-items : center;
      display     : flex;
      margin-right: 16px;
    }

    .MuiGrid-item div {
      border-radius: 11px;
      width        : 16px;
      height       : 16px;
      display      : inline-flex;
      margin-right : 4px;
    }

    .MuiGrid-item:nth-child(1) div {
      background-color: ${rubineLight};
    }

    .MuiGrid-item:nth-child(2) div {
      border: 3px solid ${rubineLight};
    }
  `};
`;

DateRangePickerModal.Day = styled.div<{
  isSelected : boolean,
  isBoundary : boolean,
  isDisabled : boolean,
}>`
  ${({
  theme: {
    colors : { primary: { rubineLight, white },
    type   : { medium, dark },
  }},
  isSelected,
  isBoundary,
  isDisabled,
}) => css`
    position : relative;
    margin   : 3px 0;

    &:hover > div {
      opacity: ${!isDisabled && '1'};
    }

    & > div {
      box-shadow   : 0 0 0 2px ${white}, 0 0 0 5px ${dark};
      position     : absolute;
      width        : 26px;
      height       : 26px;
      border-radius: 50%;
      top          : 2px;
      right        : 8px;
      opacity      : 0;
      transition   : opacity 0.5s;
    }

    .MuiPickersDay-root {
      width : 26px;
      height: 26px;
      margin: 0 8px;
      cursor: pointer;

      ${isSelected && `box-shadow: 0 0 0 2px ${white}, 0 0 0 3px ${rubineLight};`}

      ${isBoundary && `box-shadow: 0 0 0 2px ${white}, 0 0 0 5px ${rubineLight};`}
      
      &, &:hover, &.Mui-selected, &.Mui-selected, &.Mui-selected:focus {
        background-color: ${scrimBlack(0.16)};
        color           : ${medium};
        font-weight     : 700;
      }

      &.MuiPickersDay-dayOutsideMonth, &.Mui-disabled {
        cursor : default;
        opacity: 0.32;
      }

      &.MuiPickersDay-today, &.MuiPickersDay-today:hover, &.MuiPickersDay-today:focus {
        border          : none;
        background-color: ${rubineLight};
        color           : ${white};
      }
    }
  `};
`;
