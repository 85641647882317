import { createApi }      from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../utils/baseQuery';
import {
  WeatherData
}                         from '../models';

export const PLANNED_RIDES = 'plannedrides';
const WEATHER              = 'weather';

const WEATHER_BY_GEN_LOCATION_ID = `${WEATHER}/genlocations`;
const DAILY_WEATHER_BY_RIDE_ID   = `${WEATHER}/${PLANNED_RIDES}`;

export const homeApi = createApi({
  baseQuery   : axiosBaseQuery,
  reducerPath : 'homeApi',
  tagTypes    : [
    'DailyWeather',
    'User',
    'PublicRides'
  ],
  endpoints: (builder) => ({
    // GET Weather by Gen Location ID
    getWeatherByGenLocationId: builder.query<WeatherData[], { genLocationId: string }>({
      query        : ({ genLocationId }) => ({ url: `${WEATHER_BY_GEN_LOCATION_ID}/${genLocationId}` }),
      providesTags : ['DailyWeather'],
    }),

    // GET Daily Forecast by Planned Ride ID
    getDailyWeatherByRideId: builder.query<WeatherData[], { rideId: string }>({
      query       : ({ rideId }) => ({ url: `${DAILY_WEATHER_BY_RIDE_ID}/${rideId}` }),
      providesTags: ['DailyWeather'],
    })
  }),
});

export const {
  useGetDailyWeatherByRideIdQuery,
  useGetWeatherByGenLocationIdQuery
} = homeApi;
