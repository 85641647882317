import styled                from 'styled-components';
import { Button, Grid }      from '@mui/material';
import { CardTypes }         from '@modules/Calendar/models';
import { INVITATION_STATUS } from '@modules/Details/models/invitation';
import { RespondDropdown }   from '@components/RespondDropdown';
import { theme }             from '@styles/theme';

interface IControlsBlock {
  isRideStarted           : boolean;
  cardType                : CardTypes;
  invitationStatus        : INVITATION_STATUS;
  onChangeAttendanceStatus: (status: INVITATION_STATUS) => void;
  onDetailsClick?         : () => void;
}

export const ControlsBlock = ({
  isRideStarted,
  cardType,
  invitationStatus,
  onDetailsClick,
  onChangeAttendanceStatus,
}: IControlsBlock) => (
  <ControlsBlock.Wrapper
    container
    alignItems     = "center"
    justifyContent = "space-between"
  >
    <Grid item>
      <Button
        color   = "primary"
        onClick = {() => onDetailsClick?.()}
        size    = "medium"
        variant = "contained"
      >
        Details
      </Button>
    </Grid>
    
    {!isRideStarted && (
      <Grid item>
        <RespondDropdown
          isOnCard
          defaultBgColor  = {theme.colors.neutralGrey[600]}
          initialResponse = {invitationStatus || INVITATION_STATUS.PENDING}
          label           = {cardType === CardTypes.GroupRide ? "Attend" : "Respond"}
          onChange        = {onChangeAttendanceStatus}
        />
      </Grid>
    )}
  </ControlsBlock.Wrapper>
);

ControlsBlock.Wrapper = styled(Grid)`
  padding: 10px;
`;
