import { TabsetItem } from '../../../components/Tabset';
import { TIME_TYPES } from '../../../models/TimeTypes';

export enum ZonesEnum {
  Six   = 6,
  Seven = 7,
  Eight = 8
}

export const privacyTabs: TabsetItem<boolean, string>[] = [
  { id: false, label: 'Public' },
  { id: true, label: 'Private' },
];

export const timeTabs: TabsetItem<string, string>[] = [
  { id: TIME_TYPES.HOURS_12, label: '12 Hour' },
  { id: TIME_TYPES.HOURS_24, label: '24 Hour' },
];

export const unitsTabs: TabsetItem<string, string>[] = [
  { id: 'IMPERIAL', label: 'Mi / Ft' },
  { id: 'METRIC', label: 'Km / M' },
];

export const weatherTabs: TabsetItem<string, string>[] = [
  { id: 'FAHRENHEIT', label: 'Fahrenheit' },
  { id: 'CELSIUS',   label: 'Celsius' },
];

export const zonesTabs: TabsetItem<ZonesEnum, string>[] = [
  { id: ZonesEnum.Six,   label: '6' },
  { id: ZonesEnum.Seven, label: '7' },
  { id: ZonesEnum.Eight, label: '8' },
];
