import { 
  useCallback, 
  useContext 
}                          from 'react';
import { Box, Grid }       from '@mui/material';
import styled              from 'styled-components';
import { TextInput }       from '../../../../../components/TextInput';
import { SettingsContext } from '../../../containers/SettingsContainer';
import { ISettingsForm }   from '../../../models/Settings';

export const ChangePassword = () => {
  const {
    setValue,
    clearErrors,
    formState: {
      errors
    }
  } = useContext(SettingsContext);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, fieldName: keyof ISettingsForm) => {
      setValue(fieldName, event.target.value);

      if (errors[fieldName]) {
        clearErrors(fieldName);
      }
    },
    [clearErrors, errors, setValue]
  );

  return (
    <Box sx={{ marginTop: { xs: '-50px', md: '-80px' }}}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <ChangePassword.TextContainer>
            <p>To <strong>protect your account</strong>, please ensure that your password:</p>

            <li>Is at least 8 characters long</li>
            <li>Does not match or significantly contain your username, e.g. `username123`</li>
            <li>Is not member of this <strong>list of common passwords</strong></li>
          </ChangePassword.TextContainer>

          <TextInput
            dark
            placeholder = "New Password"
            inputType   = "password"
            name        = "password"
            onChange    = {event => handleOnChange(event, 'password')}
            error       = {errors.password?.message ?? ''}
          />

          <TextInput
            dark
            placeholder = "Confirm Password"
            inputType   = "password"
            name        = "confirmPassword"
            onChange    = {event => handleOnChange(event, 'confirmPassword')}
            error       = {errors.confirmPassword?.message ?? ''}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

ChangePassword.TextContainer = styled.div`
  margin-bottom: 50px;

  p {
    color: #FFF;
    text-align: left;
  }

  li {
    color: #FFF;
    margin-bottom: 15px;
    text-align: initial;
    margin-left: 20px;
  }
`;