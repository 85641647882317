import mapboxgl       from 'mapbox-gl';
import MapboxGeocoder, { 
  GeocoderOptions 
}                     from '@mapbox/mapbox-gl-geocoder';

export const useMapbox = ({
  getItemValue
}: Partial<GeocoderOptions>) => {
  mapboxgl.accessToken = process.env.REACT_APP_PREEM_MAPBOX_ACCESS_TOKEN || '';

  const geocoder = new MapboxGeocoder({ 
    accessToken: mapboxgl.accessToken,
    getItemValue
  });

  return {
    geocoder
  }
}