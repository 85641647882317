import { Grid }                       from '@mui/material';
import styled                         from 'styled-components';
import IconButton                     from '@mui/material/IconButton';
import { useDetails }                 from '@modules/Details/context';
import { IRideStatistics }            from '@modules/Details/models/statistics';
import { useGetRidePhotosQuery }      from '@modules/Details/queries';
import { Popup, POPUP_TYPES }         from '@modules/Details/components/Popup';
import { useGetUserSettingsQuery }    from '@modules/Profile/queries';
import LogoBg                         from '@images/Pattern-L-A16-16-9.svg';
import { ReactComponent as PlusIcon } from '@images/Icon-Add-Rubine.svg';
import { getRidePhotoUrl }            from '@utils/files_utils';
import { cutDateFromISO }             from '@utils/time_utils';

interface IRecurringStatisticsProps<T> {
  statisticsMetrics: IRideStatistics<T>[];
  data             : T;
  withGallery?     : boolean;
}

export const RecurringStatistics = <T, > ({
  statisticsMetrics,
  data,
  withGallery = true,
}: IRecurringStatisticsProps<T>) => {
  const { rideId, rideDetails } = useDetails();
  const { data: settings }      = useGetUserSettingsQuery();

  const { data: ridePhotos } = useGetRidePhotosQuery({
    rideId,
    rideDate   : cutDateFromISO(rideDetails?.date ?? ''),
    pageNumber : 0,
    pageSize   : 50 // temp solution
  });

  return (
    <RecurringStatistics.Wrapper>
      {withGallery && <RecurringStatistics.Container>
        <RecurringStatistics.AddPhoto hasContent={Boolean((ridePhotos?.content ?? []).length)}>
          <IconButton size="large" onClick={() => Popup.open(POPUP_TYPES.PHOTO, { rideId, rideDate: cutDateFromISO(rideDetails?.date ?? '') })}>
            <PlusIcon />
          </IconButton>
        </RecurringStatistics.AddPhoto>

        <RecurringStatistics.PhotosWrapper hasContent={Boolean((ridePhotos?.content ?? []).length)}>
          {(ridePhotos?.content ?? []).map((photo, index) => (
            <RecurringStatistics.Photo
              key     = {photo.id}
              onClick = {() => Popup.open(POPUP_TYPES.PHOTO_PREVIEW, { selectedPhotoIndex: index, photos: ridePhotos?.content ?? [], rideId })}
              image   = {getRidePhotoUrl(photo.fileLink)}
            />
          ))}
        </RecurringStatistics.PhotosWrapper>
      </RecurringStatistics.Container>}

      <RecurringStatistics.MetricsList container flexWrap="nowrap" columnSpacing="30px">
        {statisticsMetrics.map(metrics => {
          const key            = metrics.key;
          const value          = data?.[key] ?? '—';
          const conversionFn   = metrics.conversionFn;
          const formattedValue = (conversionFn && value && value !== '—') ? conversionFn(value, settings?.measurementSystem ?? 'IMPERIAL') : value;

          return (
            <Grid item container alignItems="center" flexDirection="column" key={key as string} xs="auto">
              <RecurringStatistics.Value>
                {formattedValue}
              </RecurringStatistics.Value>

              <RecurringStatistics.Name>
                {metrics.label}
              </RecurringStatistics.Name>
            </Grid>
          );
        })}
      </RecurringStatistics.MetricsList>
    </RecurringStatistics.Wrapper>
  );
}

RecurringStatistics.Wrapper = styled.div`
  background-image: ${({ theme: {colors: {primary: { rubineLight, purple }}}}) => `
    url(${LogoBg}), radial-gradient(circle farthest-corner at 0% 0%, ${ rubineLight }, ${ purple })
  `};
  background-position: 0% 50%, 0px 0px;
  background-size    : cover,  auto;
`;

RecurringStatistics.Container = styled.div`
  display: flex;
`;

RecurringStatistics.MetricsList = styled(Grid)`
  padding   : 24px 24px 32px;
  overflow-x: auto;

  &::-webkit-scrollbar-track {
    opacity: 0;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .3);
  }
`;

RecurringStatistics.Value = styled.div`
  font-size  : 22px;
  font-weight: 700;
  color      : ${({ theme: {colors: {primary: {chartreuse}}}}) => chartreuse};
`;

RecurringStatistics.Name = styled.div`
  opacity       : 0.64;
  font-size     : 11px;
  color         : white;
  white-space   : nowrap;
  letter-spacing: 0.8px;
`;

RecurringStatistics.PhotosWrapper = styled.div<{ hasContent: boolean }>`
  display     : flex;
  overflow-x  : auto;
  min-height  : ${({ hasContent }) => hasContent ? '170px' : '70px'};
  width       : 100%;
`;

RecurringStatistics.Photo = styled.div<{ image: string }>`
  position           : relative;
  flex-basis         : 12.5%;
  box-sizing         : border-box;
  min-height         : 96px;
  min-width          : 12.5%;
  cursor             : pointer;
  background-image   : ${({ image }) => `url(${image})`};
  background-size    : cover;
  background-repeat  : no-repeat;
  background-position: center;

  &:hover {
    border    : ${({ theme }) => `3px solid ${theme.colors.primary.rubineLight}`};
    box-shadow: 0 0 0 1px transparent;
  }
`;

RecurringStatistics.AddPhoto = styled.div<{ hasContent: boolean }>`
  position   : relative;
  flex-basis : 12.5%;
  box-sizing : border-box;
  min-width  : 96px;
  min-height : 96px;

  button {
    position         : absolute;
    top              : ${({ hasContent }) => hasContent ? '64px' : '25px'};
    left             : 35px;
    z-index          : 1000;
    background-color : #FFFFFF;
  }

  button:hover {
    background-color  : #FFFFFF;
    box-shadow        : 0 4px 16px 0 rgb(0 0 0 / 16%);
    border            : none;
    -webkit-transform : scale(1.03);
    -ms-transform     : scale(1.03);
    transform         : scale(1.03);
  }
`;
