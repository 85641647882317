import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createIcon: {
    rotated: false,
  },
  createOverlay: {
    displayComponent: "ctas",
    rendered: false,
    visible: false,
  },
  detailsOverlay: {
    rendered: false,
    visible: false,
  },
  header: {
    showLoginSignupButtons: true,
    smaller: false,
  },
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setCreateIconRotated: (state, action) => {
      state.createIcon.rotated = action.payload;
    },
    setCreateOverlayRendered: (state, action) => {
      state.createOverlay.rendered = action.payload;
    },
    setCreateOverlayVisible: (state, action) => {
      state.createOverlay.visible = action.payload;
    },
    setDisplayComponent: (state, action) => {
      state.createOverlay.displayComponent = action.payload;
    },
    setDetailsOverlayRendered: (state, action) => {
      state.detailsOverlay.rendered = action.payload;
    },
    setDetailsOverlayVisible: (state, action) => {
      state.detailsOverlay.visible = action.payload;
    },
    setShowLoginSignupButtons: (state, action) => {
      state.header.showLoginSignupButtons = action.payload;
    },
    setSmaller: (state, action) => {
      state.header.smaller = action.payload;
    },
  },
});

export const {
  setDisplayComponent,
  setCreateIconRotated,
  setCreateOverlayRendered,
  setCreateOverlayVisible,
  setDetailsOverlayRendered,
  setDetailsOverlayVisible,
  setShowLoginSignupButtons,
  setSmaller,
} = uiSlice.actions;

export const createIconRotated = (state: stateType) =>
  state.ui.createIcon.rotated;
export const createOverlayRendered = (state: stateType) =>
  state.ui.createOverlay.rendered;
export const createOverlayVisible = (state: stateType) =>
  state.ui.createOverlay.visible;

export const detailsOverlayRendered = (state: stateType) =>
  state.ui.detailsOverlay.rendered;
export const detailsOverlayVisible = (state: stateType) =>
  state.ui.detailsOverlay.visible;
export const displayComponent = (state: stateType) =>
  state.ui.createOverlay.displayComponent;
export const showLoginSignupButtons = (state: stateType) =>
  state.ui.header.showLoginSignupButtons;
export const smaller = (state: stateType) => state.ui.header.smaller;

export default uiSlice.reducer;

export type UIState = {
  createIcon: {
    rotated: boolean;
  };
  createOverlay: {
    displayComponent: string;
    rendered: boolean;
    visible: boolean;
  };
  detailsOverlay: {
    rendered: boolean;
    visible: boolean;
  };
  header: {
    showLoginSignupButtons: boolean;
    smaller: boolean;
  };
};

export type stateType = {
  ui: UIState;
};
