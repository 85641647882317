import styled from 'styled-components';

interface InputErrorProps {
  error: string;
}

export const InputError = ({ error }: InputErrorProps) => (
  <InputError.Error>{error}</InputError.Error>
);

export const ErrorText = styled.p`
  color      : ${({ theme }) => theme.colors.messaging.red};
  font-weight: 600;
  font-size  : ${({ theme: {fontSizes: {s}} }) => s};
  line-height: 1.1;
`;

InputError.Error = styled(ErrorText)`
  position   : absolute;
  margin-top : 5px;
`;
