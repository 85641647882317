import { useState }             from 'react';
import styled                   from 'styled-components';
import { IconButton, Tooltip }  from '@mui/material';
import { Event }                from '@mui/icons-material';
import {
  RangedInput,
  TRangedInputProps,
}                               from '../RangedInput';
import { DateRangePickerModal } from '../DateRangePickerModal';
import { TNumberRange }         from '../../modules/Profile/utils/rangeValues';
import { formatRideDate }       from '../../utils/commonFunctions';
import { DAY_IN_MILLISECONDS }  from '../../utils/time_utils';

type TRangedDateInputProps = Omit<TRangedInputProps, 'min' | 'max' | 'renderValues' | 'onChange'> & {
  value    : [number, number];
  onChange : (values: TNumberRange) => void;
  max?     : number;
  min?     : number;
};

export const RangedDateInput = ({
  value,
  max,
  min,
  onChange,
  ...props
}: TRangedDateInputProps) => {
  const initialMaxValue = max ?? new Date().getTime();
  const initialMinValue = min ?? initialMaxValue - 365 * DAY_IN_MILLISECONDS;

  const [valueLimits, setValueLimits]         = useState<TNumberRange>([initialMinValue, initialMaxValue]);
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);

  const handleSelectDate = (values: [Date, Date]) => {
    const newValues = [values[0].getTime(), values[1].getTime()];

    const newLimits = [
      newValues[0] < valueLimits[0] ? newValues[0] : valueLimits[0],
      newValues[1] > valueLimits[1] ? newValues[1] : valueLimits[1]
    ];

    setValueLimits(newLimits as TNumberRange);
    onChange(newValues as TNumberRange);
  };

  const renderValues = () =>  (
    <RangedDateInput.Values>
      <p>{formatRideDate(new Date(value[0]))} - {formatRideDate(new Date(value[1]))}</p>

      <Tooltip
        title     = "Pick dates"
        placement = "top"
        arrow
      >
        <IconButton onClick = {() => setDatePickerOpen(true)}>
          <Event />
        </IconButton>
      </Tooltip>
    </RangedDateInput.Values>
  );

  return (
    <>
      <RangedInput
        value        = {value as TNumberRange}
        max          = {valueLimits[1]}
        min          = {valueLimits[0]}
        step         = {DAY_IN_MILLISECONDS}
        renderValues = {renderValues}
        onChange     = {(_event, values) => onChange(values as TNumberRange)}
        {...props}
      />

      {isDatePickerOpen && (
        <DateRangePickerModal
          isOpen
          defaultValue = {[new Date(value[0]), new Date(value[1])]}
          onClose      = {() => setDatePickerOpen(false)}
          onSave       = {handleSelectDate}
        />
      )}
    </>
  );
};

RangedDateInput.Values = styled.div`
  display     : flex;
  align-items : center;

  p {
    margin: 0;
  };
`;
