import { useHistory } from 'react-router';
import { Grid }       from '@mui/material';
import styled         from 'styled-components';

import {
  CardTypes,
  IRideInvitation
}                     from '../../../../../models';

import { DayCard }    from '../DayCard';

export const Invitations = ({ invitationCards, date }: IInvitationsProps) => {
  const { push } = useHistory();

  return (
    <>
      {invitationCards &&
        invitationCards.map((invitationCard) => (
          <DayCard
            key            = {`${invitationCard.plannedRideId}${invitationCard.ride.startDate}`}
            cardType       = {CardTypes.Invitation}
            invitation     = {invitationCard}
            date           = {date}
            onDetailsClick = {() => push(`ride/${invitationCard.plannedRideId}/${date}`, { prevState: '' })}
          />
        )
      )}

      {(!invitationCards || invitationCards?.length === 0) && (
        <NoData>No invitations</NoData>
      )}
    </>
  );
};

interface IInvitationsProps {
  date            : string;
  invitationCards?: IRideInvitation[];
}

export const NoData = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.neutralGrey[200]};
  color           : ${({ theme }) => theme.colors.type.medium};
  font-size       : 16px;
  font-weight     : 700;
  height          : 64px;
  letter-spacing  : -0.2px;
  line-height     : 21.12px;
`;
