export enum EWeatherCode {
  'Unknown'             = 0,
  'Clear'               = 1000,
  'Cloudy'              = 1001,
  'Mostly Clear'        = 1100,
  'Partly Cloudy'       = 1101,
  'Mostly Cloudy'       = 1102,
  'Fog'                 = 2000,
  'Light Fog'           = 2100,
  'Light Wind'          = 3000,
  'Wind'                = 3001,
  'Strong Wind'         = 3002,
  'Drizzle'             = 4000,
  'Rain'                = 4001,
  'Light Rain'          = 4200,
  'Heavy Rain'          = 4201,
  'Snow'                = 5000,
  'Flurries'            = 5001,
  'Light Snow'          = 5100,
  'Heavy Snow'          = 5101,
  'Freezing Drizzle'    = 6000,
  'Freezing Rain'       = 6001,
  'Light Freezing Rain' = 6200,
  'Heavy Freezing Rain' = 6201,
  'Ice Pellets'         = 7000,
  'Heavy Ice Pellets'   = 7101,
  'Light Ice Pellets'   = 7102,
  'Thunderstorm'        = 8000,
}

export enum EAirQualityNum {
  'Good',
  'Moderate',
  'Unhealthy for Sensitive Groups',
  'Unhealthy',
  'Very Unhealthy',
  'Hazardous'
}

export const AIR_QUALITY_STATUS = {
  [EAirQualityNum.Good]                              : 'Good',
  [EAirQualityNum.Moderate]                          : 'Moderate',
  [EAirQualityNum['Unhealthy for Sensitive Groups']] : 'Unhealthy for Sensitive Groups',
  [EAirQualityNum.Unhealthy]                         : 'Unhealthy',
  [EAirQualityNum['Very Unhealthy']]                 : 'Very Unhealthy',
  [EAirQualityNum.Hazardous]                         : 'Hazardous',
}

export interface IWeatherDetails {
  humidity                : number;
  precipitationProbability: number;
  temperatureMax          : number;
  temperatureMin          : number;
  uvIndex                 : number;
  weatherCode             : number;
  windSpeedMax            : number;
  windSpeedMin            : number;
  windDirection           : number;
  airQuality              : EAirQualityNum;
  weatherDescription      : string;
  epaIndexAvg             : number;
}

interface IInterval {
  startTime : string;
  values    : IWeatherDetails;
}

export interface ITimeline {
  endTime   : string;
  startTime : string;
  timestep  : string;
  intervals : IInterval[];
}

interface IRouteWeather {
  timeline: ITimeline;
}

export interface IRideWeather {
  data: {
    route: IRouteWeather[];
  }
}