import {
  useCallback,
  useContext,
  useState
}                                           from 'react';
import { Button, Grid, Link, Checkbox }     from '@mui/material';
import styled, { css }                      from 'styled-components';
import { profilePicturesHost }              from '@config/api_config';
import { scrimBlack }                       from '@styles/theme';
import { Loader }                           from '@components/Loader';
import { Avatar }                           from '@components/Avatar';
import { ECtaTab }                          from '@modules/CreateModal/models/CreateModalContent';
import { useCta }                           from '@utils/hooks/useCta';
import { CreateRideForm }                   from '../CreateRideForm';
import { ReactComponent as ChevronIcon }    from '../../../../images/Icon-Chevron-R-Rubine.svg';
import { ReactComponent as EmptyCheckbox }  from '../../../../images/Empty-Light-Checkbox.svg';
import { ReactComponent as FilledCheckbox } from '../../../../images/Filled-Light-Checkbox.svg';
import { useGetUserConnectionsQuery }       from '../../../Profile/queries';
import { PublicProfileContainer }           from '../../../Profile/containers/PublicProfileContainer';
import { CTAContext }                       from '../..'

export const ConnectionsSelector = () => {
  const { openCta }                     = useCta();
  const { data, isFetching }            = useGetUserConnectionsQuery({});
  const {
    selectedConnections,
    setSelectedConnections,
    rideForm,
  }                                     = useContext(CTAContext);
  const [selectedUser, setSelectedUser] = useState<string>();
  const connections                     = data?.content;

  const setConnections = useCallback(
    (userIds: string[]) => {
      setSelectedConnections(userIds);
      rideForm?.setValue('inviteeIds', userIds);
    },
    [rideForm, setSelectedConnections]
  );

  const checkAllConnections = useCallback(
    () => {
      const isNotSelected = connections?.length !== selectedConnections?.length;
      let newValue: string[] = [];

      if (connections && isNotSelected) {
        newValue = connections.map(connection => connection.id);
      }

      setConnections(newValue);
    },
    [selectedConnections?.length, setConnections, connections]
  );

  const toggleConnection = useCallback(
    (userId: string) => {
      const checked = selectedConnections.includes(userId);
      let newValue: string[];

      if (checked) {
        newValue = selectedConnections.filter(id => id !== userId);
      } else {
        newValue = [...selectedConnections, userId];
      }

      setConnections(newValue);
    },
    [selectedConnections, setConnections]
  );

  const TableContent = (
    <>
      <RideFormSelectorTable item>
        {connections?.length ? (
          <ConnectionsSelector.AllConnectionsRow container alignItems="center">
            <Grid item xs={2}>
              <Avatar
                size = {40}
                name = {connections?.length.toString() || '0'}
              />
            </Grid>

            <Grid item xs={8}>
              <b>All Connections</b>
            </Grid>

            <Grid item xs={2}>
              <Checkbox
                checkedIcon = {<FilledCheckbox />}
                icon        = {<EmptyCheckbox/>}
                checked     = {connections?.length === selectedConnections?.length}
                onChange    = {checkAllConnections}
              />
            </Grid>
          </ConnectionsSelector.AllConnectionsRow>
        ) : (
          <RideEmptySelectorLabel>
            <b>No Connections to Display</b>
          </RideEmptySelectorLabel>
        )}

        {connections?.map(connection => (
          <RideFormSelectorRow container key={connection?.id} alignItems="center">
            <Grid item xs={2}>
              <Avatar
                name     = {connection.lastName}
                size     = {40}
                photoUrl = {connection.profilePictureUrl && `${profilePicturesHost}${connection.profilePictureUrl}`}
              />
            </Grid>

            <Grid item xs={8}>
              <Link onClick={() => setSelectedUser(connection.username)}>
                <RideEllipsisName>@{connection?.username}</RideEllipsisName>
              </Link>
            </Grid>

            <Grid item xs={2}>
              <Checkbox
                checkedIcon = {<FilledCheckbox />}
                icon        = {<EmptyCheckbox/>}
                checked     = {selectedConnections.includes(connection.id)}
                onChange    = {() => toggleConnection(connection.id)}
              />
            </Grid>
          </RideFormSelectorRow>
        ))}
      </RideFormSelectorTable>

      <CreateRideForm.BlockFooter item container justifyContent="space-between" flexWrap="nowrap">
        <Grid item>
          <Button
            size    = "small"
            endIcon = {<ChevronIcon />}
            onClick = {() => openCta({ tab: ECtaTab.ADD_CONNECTIONS })}
          >
            Add Connections
          </Button>
        </Grid>

        <Grid item>
          <Button
            size    = "small"
            endIcon = {<ChevronIcon />}
            onClick = {() => openCta({ tab: ECtaTab.INVITE_FRIENDS })}
          >
            Invite a Friend
          </Button>
        </Grid>
      </CreateRideForm.BlockFooter>
    </>
  );

  return (
    <Grid container direction="column" justifyContent="space-between" height="100%">
      {isFetching
        ? <Loader />
        : TableContent
      }

      {!!selectedUser && (
        <PublicProfileContainer
          username = {selectedUser}
          onClose  = {() => setSelectedUser('')}
        />
      )}
    </Grid>
  );
};

export const RideFormSelectorTable = styled(Grid)`
  height    : calc(100% - 56px);
  overflow-y: auto;
`;

export const RideFormSelectorRow = styled(Grid)`
  border-bottom: 1px solid ${ scrimBlack(0.08) };
  padding      : 12px 16px;
`;

export const RideEllipsisName = styled.div`
  overflow     : hidden;
  text-overflow: ellipsis;
`;

export const RideEmptySelectorLabel = styled.div`
  ${({ theme: {
    colors   : {type: { medium }},
    fontSizes: { l }
  }}) => css`
    color          : ${medium};
    font-size      : ${l};
    display        : flex;
    height         : 100%;
    width          : 100%;
    align-items    : center;
    justify-content: center;
  `}
`;

ConnectionsSelector.AllConnectionsRow = styled(RideFormSelectorRow)`
  .MuiGrid-item:first-child div {
    background-color: #00518E;
    color           : white;
    font-size       : 14px;
    border          : unset;
  }
`;
