import { ISubsection }      from "../components/Subsection";

import ConnectBrands        from "../../../images/landing/Connect - Brands.png";
import ConnectCommunity     from "../../../images/landing/Connect - Community.png";
import ConnectMatch         from "../../../images/landing/Connect - Match.png";
import ConnectShare         from "../../../images/landing/Connect - Share.png";
import DiscoverClub         from "../../../images/landing/Frame.svg";
import DiscoverGroupRide    from "../../../images/landing/Discover - Group Ride.png";
import DiscoverRider        from "../../../images/landing/Discover - Rider.png";
import DiscoverRoute        from "../../../images/landing/Discover - Route.png";
import PlanChat             from "../../../images/landing/Plan - Chat.png"
import PlanDress            from "../../../images/landing/Plan - How To Dress.png"
import PlanFriends          from "../../../images/landing/Plan - Friends.png"
import PlanRides            from "../../../images/landing/Plan - Rides.png"
import ReliveComments       from "../../../images/landing/Relive - Comments.png"
import ReliveFeed           from "../../../images/landing/Relive - Feed.png"
import RelivePhotos         from "../../../images/landing/Relive - Photos.png"
import ReliveRideHistory    from "../../../images/landing/Relive - Ride History.png"

export const Subsections: ISubsection[] = [
  { /** this section will not render a button. it's used to make the page load at the top until scrolled */
    anchor: "top",
    header:"",
    subheaderLine1:"",
    subheaderLine2:"",
  },
  {
    anchor        : "discover",
    header        : "Discover",
    subheaderLine1: "Tap into local knowlege to find the best routes, group rides, riders and clubs",
    subheaderLine2: "based on your location, preferences and cycling data.",
    images        : [
      { url: DiscoverRoute, label: "Routes" },
      { url: DiscoverGroupRide, label: "Group Rides" },
      { url: DiscoverRider, label: "Riders" },
      { url: DiscoverClub, label: "Clubs" },
    ],
  },
  {
    anchor        : "connect",
    header        : "Connect",
    subheaderLine1: "Link up with offline cycling friends and find new ones, join community pages,",
    subheaderLine2: "and follow the brands you love.",
    images        : [
      { url: ConnectShare, label: "Share & Invite" },
      { url: ConnectMatch, label: "Common Interests" },
      { url: ConnectCommunity, label: "Communities" },
      { url: ConnectBrands, label: "Brands" },
    ],
  },
  {
    anchor        : "plan",
    header        : "Plan",
    subheaderLine1: "Plan rides with your friends or community, keep everybody in the loop with",
    subheaderLine2: "group chat, and use forecasted weather to prepare for the day's ride conditions.",
    images        : [
      { url: PlanDress, label: "Weather along the route" },
      { url: PlanRides, label: "Routes, Ride Invitations & Group Rides" },
      { url: PlanFriends, label: "Invite Friends" },
      { url: PlanChat, label: "Ride Group Chat" },
    ],
  },
  {
    anchor        : "relive",
    header        : "Relive",
    subheaderLine1: "Look back on memorable moments, share media, and connect with new",
    subheaderLine2: "riders to celebrate shared experiences with your community.",
    images        : [
      { url: RelivePhotos, label: "Share Photos" },
      { url: ReliveComments, label: "Commments" },
      { url: ReliveFeed, label: "Activity Feed" },
      { url: ReliveRideHistory, label: "Group Ride History" },
    ],
  },
  {
    anchor        : "whypreem",
    header        : "Why Preem",
    subheaderLine1: "Preem offers cyclists worldwide and the brands that support them a new",
    subheaderLine2: "way to build community and maximize their cycling experience.",
    cta           : {
      label: "Learn More about Preem",
      link: "/landing#discover",
    },
  },
];
