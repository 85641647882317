import { useCallback, useState }          from 'react';
import styled, { css }                    from 'styled-components';
import { useForm }                        from 'react-hook-form';
import {
  Button,
  Grid,
  IconButton,
  TextField
}                                         from '@mui/material';
import { IPhotoForm }                     from '@modules/Details/models/rideGallery';
import { Modal }                          from '../Modal';
import { Notification }                   from '../Notification';
import {
  ISaveLocationData,
  LocationModal
}                                         from '../LocationModal';
import { ReactComponent as CloseIcon }    from '../../images/Icon-Close-Lt-Grey.svg';
import { ReactComponent as LocationIcon } from '../../images/Icon-Location-Grey.svg';
import {
  INVALID_PHOTOS_TYPE_MSG,
  PHOTO_ALLOWED_TYPES
}                                         from '../../modules/Profile/components/UserGallery';
import {
  useUploadRidePhotosMutation,
  useUploadRoutePhotosMutation
}                                         from '@modules/Details/queries';

interface IAddPhotoModalProps {
  isOpen    : boolean;
  onClose   : () => void;
  rideId?   : string;
  rideDate? : string;
  routeId?  : string;
}

export const AddPhotoModal = ({
  isOpen,
  onClose,
  rideId,
  rideDate,
  routeId
}: IAddPhotoModalProps) => {
  const [uploadRidePhotos]                          = useUploadRidePhotosMutation();
  const [uploadRoutePhotos]                         = useUploadRoutePhotosMutation();
  const [files, setFiles]                           = useState<any[]>([]);
  const [locationModalState, setLocationModalState] = useState(false);

  const addPhotos = useCallback(
    (event) => {
      const files        = Array.from(event.target.files);
      const hasWrongType = files.some((file: any) => !PHOTO_ALLOWED_TYPES.includes(file.type));

      if (hasWrongType) {
        Notification.enqueueSnackbar(INVALID_PHOTOS_TYPE_MSG, 'error');
      } else {
        setFiles(files);
      }
    },
    []
  );

  const {
    register,
    // handleSubmit,
    setValue,
    getValues
  } = useForm<IPhotoForm>({ mode: 'onChange' });

  const saveLocation = useCallback(
    ({ lat, lng, placeName }: ISaveLocationData) => {
      setValue('location', {
        latitude : lat,
        longitude: lng,
        placeName: placeName ?? ''
      });

      setLocationModalState(false);
    },
    [setValue]
  );

  const removeFile = useCallback(
    (fileIndex: number) => {
      setFiles(files.filter((_file, index) => index !== fileIndex));
    },
    [files]
  );

  const handleSave = useCallback(
    () => {
      const formData = new FormData();
      files.forEach(file => { formData.append('files', file) });

      if (rideId && rideDate) {
        uploadRidePhotos({ rideId, rideDate, body: formData }).then(() => onClose());
      }

      if (routeId) {
        uploadRoutePhotos({ routeId, body: formData }).then(() => onClose());
      }
    },
    [files, onClose, rideDate, rideId, routeId, uploadRidePhotos, uploadRoutePhotos]
  );

  return (
    <Modal
      isOpen         = {isOpen}
      width          = "400px"
      itemName       = "Add a Photo"
      submitBtnLabel = "Add Photo"
      title          = {files.length ? `${files.length} ${files.length > 1 ? 'Files' : 'File'} selected` : 'No File Selected'}
      onSave         = {handleSave}
      onClose        = {onClose}
    >
      <form>
        <AddPhotoModal.InputWrapper>
          {!files.length && (
            <label htmlFor="ride-photo-upload-btn">
              <input
                multiple
                id       = "ride-photo-upload-btn"
                name     = "ride-photo-upload-btn"
                type     = "file"
                onChange = {addPhotos}
              />

              <AddPhotoModal.EmptyInput
                container
                flexDirection  = "column"
                alignItems     = "center"
                justifyContent = "center"
                height         = "100%"
                rowSpacing     = "10px"
              >
                <Grid item>
                  No File Selected
                </Grid>

                <Grid item>
                  <Button
                    variant   = "outlined"
                    color     = "primary"
                    component = "span"
                  >
                    Browse
                  </Button>
                </Grid>
              </AddPhotoModal.EmptyInput>
            </label>
          )}

          {!!files.length && (
            <Grid container padding="20px">
              {files.map((file: File, index: number) => (
                <Grid item container justifyContent="space-between" key={file.name} alignItems="center">
                  <AddPhotoModal.FileName item>
                    <b>{file.name}</b>
                  </AddPhotoModal.FileName>

                  <Grid item>
                    <IconButton onClick={() => removeFile(index)}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </AddPhotoModal.InputWrapper>

        {!routeId && <Grid container padding="20px" rowSpacing="10px">
          <Grid item xs={12}>
            <TextField
              fullWidth
              label   = "Caption"
              variant = "filled"
              {...register('caption')}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label      = "Add Location"
              variant    = "filled"
              value      = {getValues('location.placeName') || ''}
              InputProps = {{endAdornment: <LocationIcon />}}
              onClick    = {() => setLocationModalState(true)}
            />
          </Grid>
        </Grid>}

        {locationModalState && !routeId && (
          <LocationModal
            isOpen
            defaultValue       = {getValues('location')}
            key                = {`${new Date()}`}
            onClose            = {() => setLocationModalState(false)}
            handleSaveLocation = {saveLocation}
            itemName           = "Start Location"
          />
        )}
      </form>
    </Modal>
  );
}

AddPhotoModal.InputWrapper = styled.div`
  overflow-y      : auto;
  height          : 200px;
  background-color: ${({theme: {colors: {neutralGrey}}}) => neutralGrey[400]};

  input {
    display: none;
  }
`;

AddPhotoModal.EmptyInput = styled(Grid)`
  ${({theme: {colors: { type: {medium}}, fontSizes: {m}}}) => css`
    font-size  : ${m};
    color      : ${medium};
    font-weight: 500;
  `}
`;

AddPhotoModal.FileName = styled(Grid)`
  font-size: 12px;
`;
