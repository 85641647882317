import {
  useContext,
  useState,
}                                     from 'react';
import styled                         from 'styled-components';
import {
  Collapse,
  Grid,
  IconButton,
  Typography,
}                                     from '@mui/material';
import { Close }                      from '@mui/icons-material';
import { ReactComponent as PlusIcon } from '@images/Icon-Add-White.svg';
import { Dropdown }                   from '@components/Dropdown';
import { AppTooltip }                 from '@components/AppTooltip';
import { CheckboxFormField }          from '@components/CheckboxFormField';
import {
  ISaveLocationData,
  LocationModal,
}                                     from '@components/LocationModal';
import { FieldWrapper }               from '@modules/Profile/components/FieldWrapper';
import {
  useGetUserLocationsQuery,
  useCreateUserLocationMutation,
  useDeleteUserLocationMutation,
}                                     from '@modules/Profile/queries';
import { TitleBlock }                 from '@modules/Profile/components/TitleBlock';
import { SETTINGS_SECTIONS }          from '@modules/Profile/utils/settingsSections';
import { SettingsContext }            from '@modules/Profile/containers/SettingsContainer';
import {
  MEASUREMENT_SYSTEMS,
  PRIVACY_AREA_OPTIONS,
  PRIVACY_AREA_OPTIONS_MILES,
}                                     from '@modules/Profile/utils/options';

export const RideStartPrivacy = () => {
  const { control, watch }          = useContext(SettingsContext);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { data: maskedLocations }   = useGetUserLocationsQuery({ isMasked: true });
  const [createLocation]            = useCreateUserLocationMutation();
  const [deleteLocation]            = useDeleteUserLocationMutation()

  const handleSaveLocation = (data: ISaveLocationData) => createLocation({ ...data, isMasked: true });

  return (
    <>
      <FieldWrapper>
        <Grid container spacing={4} alignItems="center" justifyContent="space-between">
          <Grid item xs={12} md={4}>
            <TitleBlock
              label        = {SETTINGS_SECTIONS.rideStartPrivacy.title}
              description  = {SETTINGS_SECTIONS.rideStartPrivacy.description}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <Dropdown
                      label   = "Privacy Area"
                      options = {watch('measurementSystem') === MEASUREMENT_SYSTEMS.IMPERIAL ? PRIVACY_AREA_OPTIONS_MILES : PRIVACY_AREA_OPTIONS}
                      control = {control}
                      name    = "maskingDistance"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <CheckboxFormField
                      label   = "Set Privacy Area around all Rides"
                      name    = "showRoutesMasked"
                      control = {control}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <RideStartPrivacy.AddLocation
                          size     = "small"
                          disabled = {watch('showRoutesMasked')}
                          onClick  = {() => setModalOpen(true)}
                        >
                          <PlusIcon />
                        </RideStartPrivacy.AddLocation>
                      </Grid>

                      <Grid item>
                        <RideStartPrivacy.AddAddress paragraph={false} $isDisabled={watch('showRoutesMasked')}>
                          Add Address
                          <AppTooltip tooltipText="To set privacy area for rides starting and ending that specific address" />
                        </RideStartPrivacy.AddAddress>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {!!maskedLocations?.length && (
                <Grid item>
                  <Collapse in={!watch('showRoutesMasked')}>
                    <Grid container spacing={0} direction="column">
                      {maskedLocations.map(({ id, placeName }) => (
                        <Grid key={id} item>
                          <Typography
                            variant   = "subtitle1"
                            sx        = {{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}
                            paragraph = {false}
                          >
                            {placeName}

                            <IconButton onClick={() => deleteLocation(id)}>
                              <Close />
                            </IconButton>
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Collapse>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </FieldWrapper>

      {isModalOpen && (
        <LocationModal
          isOpen
          isRideLocation
          onClose            = {() => setModalOpen(false)}
          handleSaveLocation = {handleSaveLocation}
        />
      )}
    </>
  );
};

RideStartPrivacy.AddAddress = styled(Typography)<{ $isDisabled?: boolean }>`
  display    : flex;
  align-items: center;
  user-select: none;
  color      : ${({ $isDisabled, theme: { colors } }) => $isDisabled ? `${colors.type.dark}30` : colors.type.dark};
`;

RideStartPrivacy.AddLocation = styled(IconButton)`
  &:disabled {
    background-color: ${({ theme: { colors: { primary: { rubineDark }}}}) => `${rubineDark}50`};
  }

  &, &:hover {
    background-color: ${({ theme: { colors: { primary: { rubineDark }}}}) => rubineDark};
  }
`;
