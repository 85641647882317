import styled         from 'styled-components';
import {
  ButtonBase,
  Collapse,
  Grow,
  Popper
}                     from '@mui/material';
import {
  ArrowDropDown,
  Close,
}                     from '@mui/icons-material';
import {
  usePopupState,
  bindHover,
  bindPopper,
}                     from 'material-ui-popup-state/hooks';
import {
  HighlightBorder,
  LocationLink as Link
}                     from '../../modules/Calendar/components/ContentHero/components/ContentHeroLocationSelectorDropdown';
import { scrimBlack } from '../../styles/theme';

interface IDropdownArrowProps {
  $flipped: boolean;
}
const DropdownArrow = styled(ArrowDropDown)<IDropdownArrowProps>`
  transition: all 300ms ease-in-out;
  ${({ $flipped }) => $flipped && `transform:rotate(180deg)`}
`;

export interface IDropdownOption {
  id   : string | number;
  name : string;
}

interface IRoundedDropdownInputProps<T extends IDropdownOption = IDropdownOption> {
  selectedItem : T | undefined | null;
  onChange     : (option: T | null) => void;
  options      : T[];
  placeholder? : string;
  isClearable?   : boolean;
}

export const RoundedDropdownInput = <T extends IDropdownOption = IDropdownOption>({
  onChange,
  selectedItem,
  options,
  placeholder,
  isClearable,
}: IRoundedDropdownInputProps<T>) => {

  const handleMenuItemClick = (option: T) => {
    popupState.close();
    onChange(option);
  };

  const popupState = usePopupState({
    variant: "popper",
    popupId: "locationSelectorGrid",
  });

  return (
    <RoundedDropdownInput.OuterWrapper>
      <RoundedDropdownInput.Trigger {...bindHover(popupState)}>
        <span>{selectedItem ? selectedItem.name : placeholder ?? 'Select Item'}</span>

        <DropdownArrow $flipped={popupState.isOpen} />
      </RoundedDropdownInput.Trigger>

      <RoundedDropdownInput.Wrapper
        disablePortal
        placement="bottom"
        transition
        {...bindPopper(popupState)}
      >
        {({ TransitionProps }) => (
          <Grow in={popupState.isOpen} timeout={300} {...TransitionProps}>
            <RoundedDropdownInput.ContentWrapper>
              {options.map((option, index) => (
                <Link
                  onClick  = {() => handleMenuItemClick(option)}
                  key      = {`MenuItem${index}`}
                  selected = {Boolean(selectedItem && selectedItem.id === option.id)}
                >
                  <HighlightBorder />
                  <div>{option.name}</div>
                </Link>
              ))}

              <Collapse
                in      = {Boolean(isClearable && selectedItem)}
                timeout = {600}
              >
                <RoundedDropdownInput.ClearOption onClick={() => onChange(null)}>
                  Clear <Close fontSize="small" />
                </RoundedDropdownInput.ClearOption>
              </Collapse>
            </RoundedDropdownInput.ContentWrapper>
          </Grow>
        )}
      </RoundedDropdownInput.Wrapper>
    </RoundedDropdownInput.OuterWrapper>
  );
};


RoundedDropdownInput.OuterWrapper = styled.div`
  display       : flex;
  flex-direction: column;
  align-items   : center;
`;

RoundedDropdownInput.Wrapper = styled(Popper)`
  border-radius : 8px;
  z-index       : 100000;
  padding-top   : 0;
  padding-bottom: 0;
`;

RoundedDropdownInput.Trigger = styled(ButtonBase)`
  display         : flex;
  height          : 32px;
  width           : 200px;
  justify-content : space-between;
  margin-left     : 0;
  font-size       : 13px;
  font-weight     : 700;
  padding-left    : 16px;
  padding-right   : 8px;
  color           : ${({ theme }) => theme.colors.type.medium};
  border-radius   : 20px;
  background-image: ${({ theme }) => theme.colors.gradientVertical.grey};
  box-shadow      : 0 1px 2px 0 ${scrimBlack(0.04)}, 0 4px 8px 0 ${scrimBlack(0.04)};
  transition      : all 200ms ease-in-out;
  &:focus {
    outline: 0;
  }
  &:hover {
    box-shadow: 0 4px 16px 0 ${scrimBlack(0.16)};
  }

  &:active {
    background-image: ${({ theme }) => theme.colors.gradientVertical.shadow},
      ${({ theme }) => theme.colors.gradientVertical.grey};
    box-shadow      : 0 0 4px 0 ${scrimBlack(0.16)};
  }

  span {
    display      : inline-block;
    width        : 150px;
    overflow     : hidden;
    white-space  : nowrap;
    text-overflow: ellipsis;
  }
`;

RoundedDropdownInput.ContentWrapper = styled.div`
  min-width       : 200px;
  border-radius   : 8px;
  padding-top     : 8px;
  background-color: ${({ theme }) => theme.colors.primary.white};
`;

RoundedDropdownInput.ClearOption = styled.a`
  align-items               : center;
  height                    : 56px;
  background                : ${({ theme }) => theme.colors.neutralGrey[100]};
  display                   : flex;
  justify-content           : center;
  color                     : ${({ theme }) => theme.colors.primary.rubineLight};
  font-size                 : 14px;
  font-style                : normal;
  font-weight               : 700;
  line-height               : 14px;
  border-bottom-left-radius : 8px;
  border-bottom-right-radius: 8px;
  &:hover {
    color : ${({ theme }) => theme.colors.primary.rubineDark};
    cursor: pointer;
  }
`;
