import { Container }         from '@mui/material';
import styled                from 'styled-components';
import { isMobile }          from 'react-device-detect';
import { Link, useLocation } from 'react-router-dom';
import ErrorIcon             from '@mui/icons-material/Error';

export const FourOhFour = () => {
  const location = useLocation();

  return (
    <FourOhFour.Wrapper>
      <Container maxWidth="lg">
        <ErrorIcon />
        
        <h1>404 - Route ({location.pathname}) not found</h1>

        <div>
          If you need more help, please contact{" "}
          <a href="mailto:support@gopreem.com">support@gopreem.com</a>.
        </div>

        <FourOhFour.LinkWrapper>
          <Link to="/">Click here to go home</Link>.
        </FourOhFour.LinkWrapper>
      </Container>
    </FourOhFour.Wrapper>
  );
};

FourOhFour.Wrapper = styled.div`
  margin-top : ${isMobile ? '10px' : '100px'};
  text-align : center;
  height     : 1200px;

  svg {
    font-size : 120px;
    color     : ${({ theme }) => theme.colors.neutralGrey[500]}; 
  }
`;

FourOhFour.LinkWrapper = styled.div`
  margin-top: 30px;
`;
