import styled, { css } from 'styled-components';

export const WeatherIcon = styled.div<{
  condition   : string;
  contentHero?: boolean;
  isMobile?   : boolean;
  sticky?     : boolean;
}>`${({ contentHero, sticky, condition, isMobile }) => css`
  background-position: center;
  background-size    : contain;
  background-repeat  : no-repeat;
  margin-right       : ${contentHero ? '8px' : isMobile ? '0' : '16px'};
  width              : ${contentHero ? '56px' : sticky || isMobile ? '88px' : '104px'};
  height             : ${contentHero ? '56px' : sticky || isMobile ? '88px' : '104px'};
  background-image   : ${`url(${require(`@images/${getIconUrl(condition)}`)})`};
`}`;

const getIconUrl = (condition: string) => {
  switch (condition) {
    case "light-wind":
    case "windy":
    case "strong-wind":
      return "Icon-Illus-Weather-Windy.svg";

    case "clear":
      return "Icon-Illus-Weather-Clear.svg";

    case "mostly-clear":
      return "Icon-Illus-Weather-Mostly-Clear.svg";

    case "cloudy":
      return "Icon-Illus-Weather-Cloudy.svg";

    case "fog":
    case "light-fog":
      return "Icon-Illus-Weather-Fog.svg";

    case "freezing-drizzle":
    case "freezing-rain":
    case "heavy-freezing-rain":
    case "light-freezing-rain":
      return "Icon-Illus-Weather-Freezing-Rain.svg";

    case "heavy-rain":
      return "Icon-Illus-Weather-Heavy-Rain.svg";

    case "heavy-ice-pellets":
    case "ice-pellets":
    case "light-ice-pellets":
      return "Icon-Illus-Weather-Ice-Pellets.svg";

    case "mostly-cloudy":
      return "Icon-Illus-Weather-Mostly-Cloudy.svg";

    case "partly-cloudy":
      return "Icon-Illus-Weather-Partly-Cloudy.svg";

    case "light-rain":
    case "rain":
      return "Icon-Illus-Weather-Rain.svg";

    case "drizzle":
      return "Icon-Illus-Weather-Rain-Drizzle.svg";

    case "flurries":
    case "heavy-snow":
    case "light-snow":
    case "snow":
      return "Icon-Illus-Weather-Snow.svg";

    case "thunderstorm":
    default:
      return "Icon-Illus-Weather-Thunderstorm.svg";
  }
};
