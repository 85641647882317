import { MouseEvent }        from 'react';
import styled                from 'styled-components';
import { OGAPIResponse }     from 'getstream';
import { IconButton, Grid }  from '@mui/material';
import CancelIcon            from '@mui/icons-material/Cancel';
import { EOgDataType }       from '@models/Feed/post';

interface ILinkPreview {
  data     : OGAPIResponse;
  onRemove?: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
}

export const LinkPreview = ({
  data,
  onRemove
}: ILinkPreview) => (
  <LinkPreview.Wrapper onClick={() => window.open(data.url)}>
    {onRemove && (
      <LinkPreview.RemoveButton onClick={onRemove}>
        <CancelIcon />
      </LinkPreview.RemoveButton>
    )}

    {data.images && data.images.length > 0 && (data.type === EOgDataType.Article || data.type === EOgDataType.Video) && <LinkPreview.Picture imageUrl={data.images[0].image} style={{ height: '300px' }} />}

    <Grid container>
      {data.images && (data.type === EOgDataType.Website || !data.type) && (
        <Grid item xs={12} md={2}>
          <LinkPreview.Picture imageUrl={data.images[0].image} />
        </Grid>
      )}

      <Grid item xs={12} md={(data.images && (data.type === EOgDataType.Website || !data.type)) ? 10 : 12}>
        <LinkPreview.DescriptionText>
          <p>{data.site_name?.toUpperCase()}</p>
          <p>{data.title}</p>
          <p>{data.description}</p>
        </LinkPreview.DescriptionText>
      </Grid>
    </Grid>
  </LinkPreview.Wrapper>
);

LinkPreview.Wrapper = styled.div`
  position        : relative;
  width           : 100%;
  height          : 100%;
  margin-top      : 10px;
  background-color: ${({ theme }) => theme.colors.neutralGrey[100]};
  box-sizing      : border-box;
  overflow        : hidden;
  cursor          : pointer;
`;

LinkPreview.Picture = styled.div<{ imageUrl?: string }>`
  height                 : 100%;
  background-image       : ${({ imageUrl }) => `url(${imageUrl})`};
  background-position    : center;
  background-size        : cover;
  background-repeat      : no-repeat;
`;

LinkPreview.DescriptionText = styled.div`
  width           : 100%;
  padding         : 5px 10px 15px 10px;
  text-overflow   : ellipsis;
  white-space     : nowrap;
  background-color: ${({ theme }) => theme.colors.neutralGrey[300]};

  p {
    margin: 0px;
  }

  p:first-child {
    margin-top   : 15px;
    margin-bottom: 5px;
  }

  p:nth-child(2) {
    font-weight  : bold;
    font-size    : 16px;
    overflow     : hidden;
    text-overflow: ellipsis;
    width        : 95%;
  }

  p:last-child {
    font-size    : 14px;
    overflow     : hidden;
    text-overflow: ellipsis;
    width        : 95%;
    margin-top   : 10px;
  }
`;

LinkPreview.RemoveButton = styled(IconButton)`
  position        : absolute;
  right           : 0px;
  top             : 0px;
  color           : ${({ theme }) => theme.colors.primary.rubineLight};

  svg {
    background-color: #FFF;
    border-radius   : 100%;
  }
`;