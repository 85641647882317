import { useContext }      from 'react';
import styled              from 'styled-components';
import {
  Button,
  Grid,
  Typography
}                          from '@mui/material';
import { TextInput }       from '@components/TextInput';
import { PhoneInput }      from '@components/PhoneInput';
import { SettingsContext } from '@modules/Profile/containers/SettingsContainer';

interface IManageAccountProps {
  onChangePassword: () => void;
  onChangePhone   : () => void;
  onDeleteAccount : () => void;
}

export const ManageAccount = ({
  onChangePassword,
  onChangePhone,
  onDeleteAccount
}: IManageAccountProps) => {
  const { getValues } = useContext(SettingsContext);

  return (
    <ManageAccount.Wrapper>
      <Grid container spacing={4}>
        <Grid item>
          <TextInput
            dark
            placeholder = "Account Email"
            name="email"
            disabled
          />
        </Grid>

        {/* <Grid item xs>
        <Button
          variant  = "contained"
          onClick  = {() => {}}
          type     = "button"
          fullWidth
        >
          Save Email
        </Button>
      </Grid> */}
      </Grid>

      <Grid container spacing={4}>
        <Grid item>
          <TextInput
            dark
            placeholder = "Password"
            name        = "password"
            disabled
          />
        </Grid>

        <Grid item xs>
          <Button
            variant = "contained"
            onClick = {onChangePassword}
            type    = "button"
            fullWidth
          >
            Change Password
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item>
          <PhoneInput
            label = "Phone Number"
            name  = "password"
            value = {getValues('phoneNumber')}
            disabled
            dark
          />
        </Grid>

        <Grid item xs>
          <Button
            variant = "contained"
            onClick = {onChangePhone}
            type    = "button"
            fullWidth
          >
            Change Phone
          </Button>
        </Grid>
      </Grid>

      <Typography sx={{ marginTop: { xs: '50px', md: '20px' } }}>Delete Account</Typography>

      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={7.3}>
          <p>Deleting your account will permanently remove your account from Preem, including your profile information, ride history, connections, and rides.</p>
        </Grid>

        <Grid item xs>
          <Button
            variant = "contained"
            onClick = {onDeleteAccount}
            type    = "button"
            fullWidth
          >
            Delete Account
          </Button>
        </Grid>
      </Grid>
    </ManageAccount.Wrapper>
  );
};

ManageAccount.Wrapper = styled.div`
  .MuiTypography-root {
    color: #FFF;
  }

  button {
    justify-content: center;
  }

  p {
    text-align: left;
  }
`;
