export type SettingsSectionsType =
  'heartRateZone' | 'powerZones' | 'name' |
  'profileUrl' | 'nationality' | 'pronouns' |
  'birthdate' | 'height' | 'weight' |
  'locations' | 'rideStartPrivacy';
  // | 'rideStartPrivacy';

interface ISettingsSection {
  title       : string;
  description : string;
  tooltip     : string;
}

export const SETTINGS_SECTIONS: { [key in SettingsSectionsType]: ISettingsSection } = {
  heartRateZone: {
    title       : 'Heart Rate Zones',
    description : 'Heart rate zones are a way to monitor how hard you’re training. The five heart rate zones are calculated as a percentage of your maximum heart rate to describe how hard you’re riding.',
    tooltip     : 'Why we ask: Preem collects information about your heart rate to help approximate how long it may take to complete a route. We will never share this information.'
  },
  powerZones: {
    title       : 'Power Zones',
    description : 'Power zones are a way to monitor how hard you’re training by way of a power meter. Power zones are calculated as a percentage of your FTP to describe how hard you’re riding.',
    tooltip     : 'Why we ask: Preem collects information about your power zones to help approximate how long it may take to complete a route. We will never share this information.'
  },
  name: {
    title       : 'Name',
    description : 'Providing an actual first and last name along with your Preem handle makes it easier for people to connect and ride with you. And who doesn’t like making new friends on a ride?',
    tooltip     : 'Why we ask: Preem publicly displays your name to make it easier for your friends to find you on the platform.'
  },
  profileUrl: {
    title       : 'ProfileUrl',
    description : 'Your profile URL is created by adding your Preem handle to the gopreem.com URL. This is a preview of the way your URL will be publicly displayed and is also an easy way to help others find you on Preem.',
    tooltip     : 'Your ProfileUrl is automatically generated upon signup so that you can make it easy for your friends and family to connect with you on Preem. Changing your Preem handle will change the extension on your Url.'
  },
  nationality: {
    title       : 'Nationality',
    description : 'Providing your nationality will display your nation’s flag next to your name on your profile page, which can be hidden from the public by switching this setting to ‘Private’. We don’t make use of this data, we just think the worldwide reach of cycling is neat.',
    tooltip     : 'Why we ask: Providing nationality makes it easier for other users to tell if they’ve found the right ‘you’ on our platform.'
  },
  pronouns: {
    title       : 'Pronouns',
    description : 'Providing your preferred pronouns helps encourage an environment of inclusivity and respect. These qualities make for an awesome cycling comunity.',
    tooltip     : 'Why we ask: We want to make sure that Preem is a space where members can be their authentic selves. We will never share this information.'
  },
  birthdate: {
    title       : 'Birthdate',
    description : 'We calculate age from your birthdate. While it is not necessary to display your age publicly, it may make it easier for other users to identify and choose to connect with you on Preem.',
    tooltip     : 'Why we ask: Providing your age makes it easier for other users to tell if they’ve found the right ‘you’ on our platform and for you to find other Preem members who share your interests.'
  },
  height: {
    title       : 'Height',
    description : 'Providing your height will make it easier to estimate your expected completion speed and time for routes and rides you have not previously ridden. ',
    tooltip     : 'Why we ask: Providing your height makes it easier for other users to tell if they’ve found the right ‘you’ on our platform. We also use this information to approximate how long it may take to complete a route. We will never share this information.'
  },
  weight: {
    title       : 'Weight',
    description : 'Providing your weight allows us to use real and estimated power-to-weight ratios to estimate expected route completion time as well as making it easier to find rides which best match your fitness level.',
    tooltip     : 'Why we ask: Preem collects information about your weight to help approximate how long it may take to complete a route. We will never share this information.'
  },
  locations:    {
    title       : 'Locations',
    description : 'This location is the same one we asked for when you signed up. It determines the location weather and public rides displayed in your Ride Calendar by default when you use Preem.',
    tooltip     : 'Why we ask: Preem collects information about your location in order to serve the most relevant information about weather conditions, local routes, and group rides coming up in your area.'
  },
  rideStartPrivacy : {
    title       : 'Ride Start Privacy',
    description : 'Option to set privacy area around the start and end locations of your ride. This helps protect you by obscuring your home location from the rest of the Preem community.',
    tooltip     : ''
  },
};
