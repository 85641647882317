import axios, {
  AxiosError,
  AxiosRequestConfig,
}                       from 'axios';
import { Notification } from '@components/Notification';
import { METHOD }       from '@models/Request';
import { apiOrigin }    from '@config/api_config';

// export const axiosBaseQuery = async (query: any): Promise<any> => {
//   try {
//     const isQuery = typeof query === 'string';


//     if (isQuery) {
//       return await callApi(`${apiOrigin}/api/v1/${query}`);
//     }

//     return await callApi(`${apiOrigin}/api/v1/${query.url}`, query.method, query.body);
//   } catch (axiosError) {
//     const error = axiosError as AxiosError;

//     return error?.response ?? error;
//   }
// }

interface IAxiosBaseQuery {
  url            : string
  successMessage?: string;
  method?        : AxiosRequestConfig['method'];
  headers?       : AxiosRequestConfig['headers'];
  body?          : AxiosRequestConfig['data'];
  params?        : AxiosRequestConfig['params'];
}

export const axiosBaseQuery = async ({
  url,
  method = METHOD.GET,
  headers,
  body,
  params,
  successMessage,
}: IAxiosBaseQuery) => {
  const axiosConfig: AxiosRequestConfig = {
    url: `${apiOrigin}/api/v1/${url}`,
    method,
    headers,
    ...params,
  };

  if (body) {
    if (method === METHOD.GET) {
      axiosConfig.params = {
        ...params?.params,
        ...body,
      };
    } else {
      axiosConfig.data = body;
    }
  }

  try {
    const result = await axios(axiosConfig);

    if (successMessage) {
      Notification.enqueueSnackbar(successMessage, 'success');
    }

    return { data: result.data }
  } catch (axiosError) {
    let err = axiosError as AxiosError;

    if (method !== METHOD.GET) {
      if (Array.isArray(err.response?.data?.errors)) {
        const errors = err.response?.data?.errors;

        errors.forEach(({ message }: { message: string }) => Notification.enqueueSnackbar(message, 'error'));
      } else {
        const message = err.response?.data.message;

        Notification.enqueueSnackbar(message || 'Something went wrong', 'error');
      }
    }

    return {
      error: {
        status: err.response?.status,
        data  : err.response?.data || err?.message,
      },
    }
  }
};
