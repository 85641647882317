import { DropdownOptionModel } from '../../../components/Dropdown';
import { TMeasurementSystem }  from '../models/Settings';

export const FEET_OPTIONS: DropdownOptionModel[] = [
  {
    name  : '2′',
    value : '2'
  },
  {
    name  : '3′',
    value : '3'
  },
  {
    name  : '4′',
    value : '4'
  },
  {
    name  : '5′',
    value : '5'
  },
  {
    name  : '6′',
    value : '6'
  },
  {
    name  : '7′',
    value : '7'
  },
  {
    name  : '8′',
    value : '8'
  }
];

export const INCHES_OPTIONS: DropdownOptionModel[] = [
  {
    name  : '0″',
    value : '0'
  },
  {
    name  : '1″',
    value : '1'
  },
  {
    name  : '2″',
    value : '2'
  },
  {
    name  : '3″',
    value : '3'
  },
  {
    name  : '4″',
    value : '4'
  },
  {
    name  : '5″',
    value : '5'
  },
  {
    name  : '6″',
    value : '6'
  },
  {
    name  : '7″',
    value : '7'
  },
  {
    name  : '8″',
    value : '8'
  },
  {
    name  : '9″',
    value : '9'
  },
  {
    name  : '10″',
    value : '10'
  },
  {
    name  : '11″',
    value : '11'
  }
];

export const MONTH_OPTIONS: DropdownOptionModel[] = [
  {
    name  : 'January',
    value : '01'
  },
  {
    name  : 'Fabruary',
    value : '02'
  },
  {
    name  : 'March',
    value : '03'
  },
  {
    name  : 'April',
    value : '04'
  },
  {
    name  : 'May',
    value : '05'
  },
  {
    name  : 'June',
    value : '06'
  },
  {
    name  : 'July',
    value : '07'
  },
  {
    name  : 'August',
    value : '08'
  },
  {
    name  : 'September',
    value : '09'
  },
  {
    name  : 'October',
    value : '10'
  },
  {
    name  : 'November',
    value : '11'
  },
  {
    name  : 'December',
    value : '12'
  }
];

export const NATIONALITIES_OPTIONS: DropdownOptionModel[] = [
  {
      name  : 'Afghanistan',
      value : 'AFGHAN'
  },
  {
      name  : 'Albania',
      value : 'ALBANIAN'
  },
  {
      name  : 'Algeria',
      value : 'ALGERIAN'
  },
  {
      name  : 'American Samoa',
      value : 'SAMOAN_AMERICAN'
  },
  {
      name  : 'Andorra',
      value : 'ANDORRAN'
  },
  {
      name  : 'Angola',
      value : 'ANGOLAN'
  },
  {
      name  : 'Anguilla',
      value : 'ANGUILLAN'
  },
  {
      name  : 'Antigua and Barbuda',
      value : 'CITIZEN_OF_ANTIGUA_AND_BARBUDA'
  },
  {
      name  : 'Argentina',
      value : 'ARGENTINE'
  },
  {
      name  : 'Armenia',
      value : 'ARMENIAN'
  },
  {
      name  : 'Aruba',
      value : 'ARUBAN'
  },
  {
      name  : 'Australia',
      value : 'AUSTRALIAN'
  },
  {
      name  : 'Austria',
      value : 'AUSTRIAN'
  },
  {
      name  : 'Azerbaijan',
      value : 'AZERBAIJANI'
  },
  {
      name  : 'Bahamas',
      value : 'BAHAMIAN'
  },
  {
      name  : 'Bahrain',
      value : 'BAHRAINI'
  },
  {
      name  : 'Bangladesh',
      value : 'BANGLADESHI'
  },
  {
      name  : 'Barbados',
      value : 'BARBADIAN'
  },
  {
      name  : 'Belarus',
      value : 'BELARUSIAN'
  },
  {
      name  : 'Belgium',
      value : 'BELGIAN'
  },
  {
      name  : 'Belize',
      value : 'BELIZEAN'
  },
  {
      name  : 'Benin',
      value : 'BENINESE'
  },
  {
      name  : 'Bermuda',
      value : 'BERMUDIAN'
  },
  {
      name  : 'Bhutan',
      value : 'BHUTANESE'
  },
  {
      name  : 'Bolivia (Plurinational State of)',
      value : 'BOLIVIAN'
  },
  {
      name  : 'Bosnia and Herzegovina',
      value : 'CITIZEN_OF_BOSNIA_AND_HERZEGOVINA'
  },
  {
      name  : 'Botswana',
      value : 'BOTSWANAN'
  },
  {
      name  : 'Brazil',
      value : 'BRAZILIAN'
  },
  {
      name  : 'Brunei Darussalam',
      value : 'BRUNEIAN'
  },
  {
      name  : 'Bulgaria',
      value : 'BULGARIAN'
  },
  {
      name  : 'Burkina Faso',
      value : 'BURKINAN'
  },
  {
      name  : 'Burundi',
      value : 'BURUNDIAN'
  },
  {
      name  : 'Cabo Verde',
      value : 'CAPE_VERDEAN'
  },
  {
      name  : 'Cambodia',
      value : 'CAMBODIAN'
  },
  {
      name  : 'Cameroon',
      value : 'CAMEROONIAN'
  },
  {
      name  : 'Canada',
      value : 'CANADIAN'
  },
  {
      name  : 'Cayman Islands',
      value : 'CAYMAN_ISLANDER'
  },
  {
      name  : 'Central African Republic',
      value : 'CENTRAL_AFRICAN'
  },
  {
      name  : 'Chad',
      value : 'CHADIAN'
  },
  {
      name  : 'Chile',
      value : 'CHILEAN'
  },
  {
      name  : 'China',
      value : 'CHINESE'
  },
  {
      name  : 'Christmas Island',
      value : 'CHRISTMAS_ISLANDER'
  },
  {
      name  : 'Cocos (Keeling) Islands',
      value : 'COCOS_ISLANDER'
  },
  {
      name  : 'Colombia',
      value : 'COLOMBIAN'
  },
  {
      name  : 'Comoros',
      value : 'COMORAN'
  },
  {
      name  : 'Congo (Democratic Republic of the)',
      value : 'CONGOLESE_DRC'
  },
  {
      name  : 'Congo',
      value : 'CONGOLESE_CONGO'
  },
  {
      name  : 'Cook Islands',
      value : 'COOK_ISLANDER'
  },
  {
      name  : 'Costa Rica',
      value : 'COSTA_RICAN'
  },
  {
      name  : 'Croatia',
      value : 'CROATIAN'
  },
  {
      name  : 'Cuba',
      value : 'CUBAN'
  },
  {
      name  : 'Curaçao',
      value : 'CURACAOAN'
  },
  {
      name  : 'Cyprus',
      value : 'CYPRIOT'
  },
  {
      name  : 'Czech Republic',
      value : 'CZECH'
  },
  {
      name  : "Côte d'Ivoire",
      value : 'IVORIAN'
  },
  {
      name  : 'Denmark',
      value : 'DANISH'
  },
  {
      name  : 'Djibouti',
      value : 'DJIBOUTIAN'
  },
  {
      name  : 'Dominican Republic',
      value : 'DOMINICAN'
  },
  {
      name  : 'Ecuador',
      value : 'ECUADOREAN'
  },
  {
      name  : 'Egypt',
      value : 'EGYPTIAN'
  },
  {
      name  : 'El Salvador',
      value : 'SALVADOREAN'
  },
  {
      name  : 'Equatorial Guinea',
      value : 'EQUATORIAL_GUINEAN'
  },
  {
      name  : 'Eritrea',
      value : 'ERITREAN'
  },
  {
      name  : 'Estonia',
      value : 'ESTONIAN'
  },
  {
      name  : 'Ethiopia',
      value : 'ETHIOPIAN'
  },
  {
      name  : 'Falkland Islands (Malvinas)',
      value : 'FALKLANDER'
  },
  {
      name  : 'Faroe Islands',
      value : 'FAROESE'
  },
  {
      name  : 'Fiji',
      value : 'FIJIAN'
  },
  {
      name  : 'Finland',
      value : 'FINNISH'
  },
  {
      name  : 'France',
      value : 'FRENCH'
  },
  {
      name  : 'French Guiana',
      value : 'FRENCH_GUIANESE'
  },
  {
      name  : 'French Polynesia',
      value : 'FRENCH_POLYNESIAN'
  },
  {
      name  : 'Gabon',
      value : 'GABONESE'
  },
  {
      name  : 'Gambia',
      value : 'GAMBIAN'
  },
  {
      name  : 'Georgia',
      value : 'GEORGIAN'
  },
  {
      name  : 'Germany',
      value : 'GERMAN'
  },
  {
      name  : 'Ghana',
      value : 'GHANAIAN'
  },
  {
      name  : 'Gibraltar',
      value : 'GIBRALTARIAN'
  },
  {
      name  : 'Greece',
      value : 'GREEK'
  },
  {
      name  : 'Greenland',
      value : 'GREENLANDIC'
  },
  {
      name  : 'Grenada',
      value : 'GRENADIAN'
  },
  {
      name  : 'Guadeloupe',
      value : 'GUADELOUPEAN'
  },
  {
      name  : 'Guam',
      value : 'GUAMANIAN'
  },
  {
      name  : 'Guatemala',
      value : 'GUATEMALAN'
  },
  {
      name  : 'Guinea',
      value : 'GUINEAN'
  },
  {
      name  : 'Guinea-Bissau',
      value : 'CITIZEN_OF_GUINEA_BISSAU'
  },
  {
      name  : 'Guyana',
      value : 'GUYANESE'
  },
  {
      name  : 'Haiti',
      value : 'HAITIAN'
  },
  {
      name  : 'Honduras',
      value : 'HONDURAN'
  },
  {
      name  : 'Hong Kong',
      value : 'HONG_KONGER'
  },
  {
      name  : 'Hungary',
      value : 'HUNGARIAN'
  },
  {
      name  : 'Iceland',
      value : 'ICELANDIC'
  },
  {
      name  : 'India',
      value : 'INDIAN'
  },
  {
      name  : 'Indonesia',
      value : 'INDONESIAN'
  },
  {
      name  : 'Iran (Islamic Republic of)',
      value : 'IRANIAN'
  },
  {
      name  : 'Iraq',
      value : 'IRAQI'
  },
  {
      name  : 'Ireland',
      value : 'IRISH'
  },
  {
      name  : 'Isle of Man',
      value : 'MANX'
  },
  {
      name  : 'Israel',
      value : 'ISRAELI'
  },
  {
      name  : 'Italy',
      value : 'ITALIAN'
  },
  {
      name  : 'Jamaica',
      value : 'JAMAICAN'
  },
  {
      name  : 'Japan',
      value : 'JAPANESE'
  },
  {
      name  : 'Jordan',
      value : 'JORDANIAN'
  },
  {
      name  : 'Kazakhstan',
      value : 'KAZAKH'
  },
  {
      name  : 'Kenya',
      value : 'KENYAN'
  },
  {
      name  : 'Kiribati',
      value : 'CITIZEN_OF_KIRIBATI'
  },
  {
      name  : "Korea (Democratic People's Republic of)",
      value : 'NORTH_KOREAN'
  },
  {
      name  : 'Korea (Republic of)',
      value : 'SOUTH_KOREAN'
  },
  {
      name  : 'Kuwait',
      value : 'KUWAITI'
  },
  {
      name  : 'Kyrgyzstan',
      value : 'KYRGYZ'
  },
  {
      name  : "Lao People's Democratic Republic",
      value : 'LAO'
  },
  {
      name  : 'Latvia',
      value : 'LATVIAN'
  },
  {
      name  : 'Lebanon',
      value : 'LEBANESE'
  },
  {
      name  : 'Lesotho',
      value : 'MOSOTHO'
  },
  {
      name  : 'Liberia',
      value : 'LIBERIAN'
  },
  {
      name  : 'Libya',
      value : 'LIBYAN'
  },
  {
      name  : 'Liechtenstein',
      value : 'LIECHTENSTEIN_CITIZEN'
  },
  {
      name  : 'Lithuania',
      value : 'LITHUANIAN'
  },
  {
      name  : 'Luxembourg',
      value : 'LUXEMBOURGER'
  },
  {
      name  : 'Macao',
      value : 'MACANESE'
  },
  {
      name  : 'Macedonia (the former Yugoslav Republic of)',
      value : 'MACEDONIAN'
  },
  {
      name  : 'Madagascar',
      value : 'MALAGASY'
  },
  {
      name  : 'Malawi',
      value : 'MALAWIAN'
  },
  {
      name  : 'Malaysia',
      value : 'MALAYSIAN'
  },
  {
      name  : 'Maldives',
      value : 'MALDIVIAN'
  },
  {
      name  : 'Mali',
      value : 'MALIAN'
  },
  {
      name  : 'Malta',
      value : 'MALTESE'
  },
  {
      name  : 'Marshall Islands',
      value : 'MARSHALLESE'
  },
  {
      name  : 'Martinique',
      value : 'MARTINIQUAIS'
  },
  {
      name  : 'Mauritania',
      value : 'MAURITANIAN'
  },
  {
      name  : 'Mauritius',
      value : 'MAURITIAN'
  },
  {
      name  : 'Mayotte',
      value : 'MAORAIS'
  },
  {
      name  : 'Mexico',
      value : 'MEXICAN'
  },
  {
      name  : 'Micronesia (Federated States of)',
      value : 'MICRONESIAN'
  },
  {
      name  : 'Moldova (Republic of)',
      value : 'MOLDOVAN'
  },
  {
      name  : 'Monaco',
      value : 'MONEGASQUE'
  },
  {
      name  : 'Mongolia',
      value : 'MONGOLIAN'
  },
  {
      name  : 'Montenegro',
      value : 'MONTENEGRIN'
  },
  {
      name  : 'Montserrat',
      value : 'MONTSERRATIAN'
  },
  {
      name  : 'Morocco',
      value : 'MOROCCAN'
  },
  {
      name  : 'Mozambique',
      value : 'MOZAMBICAN'
  },
  {
      name  : 'Myanmar',
      value : 'BURMESE'
  },
  {
      name  : 'Namibia',
      value : 'NAMIBIAN'
  },
  {
      name  : 'Nauru',
      value : 'NAURUAN'
  },
  {
      name  : 'Nepal',
      value : 'NEPALESE'
  },
  {
      name  : 'Netherlands',
      value : 'DUTCH'
  },
  {
      name  : 'New Caledonia',
      value : 'NEW_CALEDONIAN'
  },
  {
      name  : 'New Zealand',
      value : 'NEW_ZEALANDER'
  },
  {
      name  : 'Nicaragua',
      value : 'NICARAGUAN'
  },
  {
      name  : 'Niger',
      value : 'NIGERIEN'
  },
  {
      name  : 'Nigeria',
      value : 'NIGERIAN'
  },
  {
      name  : 'Niue',
      value : 'NIUEAN'
  },
  {
      name  : 'Norfolk Island',
      value : 'NORFOLK_ISLANDER'
  },
  {
      name  : 'Norway',
      value : 'NORWEGIAN'
  },
  {
      name  : 'Oman',
      value : 'OMANI'
  },
  {
      name  : 'Pakistan',
      value : 'PAKISTANI'
  },
  {
      name  : 'Palau',
      value : 'PALAUAN'
  },
  {
      name  : 'Palestine, State of',
      value : 'PALESTINIAN'
  },
  {
      name  : 'Panama',
      value : 'PANAMANIAN'
  },
  {
      name  : 'Papua New Guinea',
      value : 'PAPUA_NEW_GUINEAN'
  },
  {
      name  : 'Paraguay',
      value : 'PARAGUAYAN'
  },
  {
      name  : 'Peru',
      value : 'PERUVIAN'
  },
  {
      name  : 'Philippines',
      value : 'FILIPINO'
  },
  {
      name  : 'Pitcairn',
      value : 'PITCAIRN_ISLANDER'
  },
  {
      name  : 'Poland',
      value : 'POLISH'
  },
  {
      name  : 'Portugal',
      value : 'PORTUGUESE'
  },
  {
      name  : 'Puerto Rico',
      value : 'PUERTO_RICAN'
  },
  {
      name  : 'Qatar',
      value : 'QATARI'
  },
  {
      name  : 'Republic of Kosovo',
      value : 'KOSOVAN'
  },
  {
      name  : 'Romania',
      value : 'ROMANIAN'
  },
  {
      name  : 'Russian Federation',
      value : 'RUSSIAN'
  },
  {
      name  : 'Rwanda',
      value : 'RWANDAN'
  },
  {
      name  : 'Réunion',
      value : 'REUNIONESE'
  },
  {
      name  : 'Saint Barthélemy',
      value : 'SAINT_BARTHELEMY_ISLANDER'
  },
  {
      name  : 'Saint Helena, Ascension and Tristan da Cunha',
      value : 'ST_HELENIAN'
  },
  {
      name  : 'Saint Kitts and Nevis',
      value : 'KITTITIAN'
  },
  {
      name  : 'Saint Lucia',
      value : 'ST_LUCIAN'
  },
  {
      name  : 'Saint Martin (French part)',
      value : 'SAINT_MARTINOIS'
  },
  {
      name  : 'Saint Pierre and Miquelon',
      value : 'PIERRAIN'
  },
  {
      name  : 'Saint Vincent and the Grenadines',
      value : 'VINCENTIAN'
  },
  {
      name  : 'Samoa',
      value : 'SAMOAN'
  },
  {
      name  : 'San Marino',
      value : 'SAMMARINESE'
  },
  {
      name  : 'Sao Tome and Principe',
      value : 'SAO_TOMEAN'
  },
  {
      name  : 'Saudi Arabia',
      value : 'SAUDI_ARABIAN'
  },
  {
      name  : 'Senegal',
      value : 'SENEGALESE'
  },
  {
      name  : 'Serbia',
      value : 'SERBIAN'
  },
  {
      name  : 'Seychelles',
      value : 'CITIZEN_OF_SEYCHELLES'
  },
  {
      name  : 'Sierra Leone',
      value : 'SIERRA_LEONEAN'
  },
  {
      name  : 'Singapore',
      value : 'SINGAPOREAN'
  },
  {
      name  : 'Slovakia',
      value : 'SLOVAK'
  },
  {
      name  : 'Slovenia',
      value : 'SLOVENIAN'
  },
  {
      name  : 'Solomon Islands',
      value : 'SOLOMON_ISLANDER'
  },
  {
      name  : 'Somalia',
      value : 'SOMALI'
  },
  {
      name  : 'South Africa',
      value : 'SOUTH_AFRICAN'
  },
  {
      name  : 'South Sudan',
      value : 'SOUTH_SUDANESE'
  },
  {
      name  : 'Spain',
      value : 'SPANISH'
  },
  {
      name  : 'Sri Lanka',
      value : 'SRI_LANKAN'
  },
  {
      name  : 'Sudan',
      value : 'SUDANESE'
  },
  {
      name  : 'Suriname',
      value : 'SURINAMESE'
  },
  {
      name  : 'Swaziland',
      value : 'SWAZI'
  },
  {
      name  : 'Sweden',
      value : 'SWEDISH'
  },
  {
      name  : 'Switzerland',
      value : 'SWISS'
  },
  {
      name  : 'Syrian Arab Republic',
      value : 'SYRIAN'
  },
  {
      name  : 'Taiwan',
      value : 'TAIWANESE'
  },
  {
      name  : 'Tajikistan',
      value : 'TAJIK'
  },
  {
      name  : 'Tanzania, United Republic of',
      value : 'TANZANIAN'
  },
  {
      name  : 'Thailand',
      value : 'THAI'
  },
  {
      name  : 'Timor-Leste',
      value : 'EAST_TIMORESE'
  },
  {
      name  : 'Togo',
      value : 'TOGOLESE'
  },
  {
      name  : 'Tokelau',
      value : 'TOKELAUAN'
  },
  {
      name  : 'Tonga',
      value : 'TONGAN'
  },
  {
      name  : 'Trinidad and Tobago',
      value : 'TRINIDADIAN'
  },
  {
      name  : 'Tunisia',
      value : 'TUNISIAN'
  },
  {
      name  : 'Turkey',
      value : 'TURKISH'
  },
  {
      name  : 'Turkmenistan',
      value : 'TURKMEN'
  },
  {
      name  : 'Turks and Caicos Islands',
      value : 'TURKS_AND_CAICOS_ISLANDER'
  },
  {
      name  : 'Tuvalu',
      value : 'TUVALUAN'
  },
  {
      name  : 'Uganda',
      value : 'UGANDAN'
  },
  {
      name  : 'Ukraine',
      value : 'UKRAINIAN'
  },
  {
      name  : 'United Arab Emirates',
      value : 'EMIRATI'
  },
  {
      name  : 'United Kingdom of Great Britain and Northern Ireland',
      value : 'BRITISH'
  },
  {
      name  : 'United States of America',
      value : 'AMERICAN'
  },
  {
      name  : 'Uruguay',
      value : 'URUGUAYAN'
  },
  {
      name  : 'Uzbekistan',
      value : 'UZBEK'
  },
  {
      name  : 'Vanuatu',
      value : 'CITIZEN_OF_VANUATU'
  },
  {
      name  : 'Venezuela (Bolivarian Republic of)',
      value : 'VENEZUELAN'
  },
  {
      name  : 'Viet Nam',
      value : 'VIETNAMESE'
  },
  {
      name  : 'Virgin Islands (British)',
      value : 'BRITISH_VIRGIN_ISLANDER'
  },
  {
      name  : 'Virgin Islands (U.S.)',
      value : 'US_VIRGIN_ISLANDER'
  },
  {
      name  : 'Wallis and Futuna',
      value : 'WALLISIAN'
  },
  {
      name  : 'Western Sahara',
      value : 'SAHRAWI'
  },
  {
      name  : 'Yemen',
      value : 'YEMENI'
  },
  {
      name  : 'Zambia',
      value : 'ZAMBIAN'
  },
  {
      name  : 'Zimbabwe',
      value : 'ZIMBABWEAN'
  },
  {
      name  : 'Åland Islands',
      value : 'ALANDIC'
  }
];

export const MEASUREMENT_SYSTEMS: { [key in TMeasurementSystem]: TMeasurementSystem } = {
  METRIC   : 'METRIC',
  IMPERIAL : 'IMPERIAL'
}

export const PRONOUNS_SUBJECT_OPTIONS: DropdownOptionModel[] = [
  {
    name  : 'He',
    value : 'HE'
  },
  {
    name  : 'She',
    value : 'SHE'
  },
  {
    name  : 'They',
    value : 'THEY'
  }
];

export const PRONOUNS_OBJECT_OPTIONS: DropdownOptionModel[] = [
  {
    name  : 'Him',
    value : 'HIM'
  },
  {
    name  : 'Her',
    value : 'HER'
  },
  {
    name  : 'Them',
    value : 'THEM'
  }
];

export const PRONOUNS_POSESSIVE_OPTIONS: DropdownOptionModel[] = [
  {
    name  : 'His',
    value : 'HIS'
  },
  {
    name  : 'Hers',
    value : 'HERS'
  },
  {
    name  : 'Their',
    value : 'THEIR'
  }
];

export const PRIVACY_AREA_OPTIONS: DropdownOptionModel[] = [
  {
    name  : '200m',
    value : 200
  },
  {
    name  : '500m',
    value : 500
  },
  {
    name  : '1km',
    value : 1000
  },
  {
    name  : '1.5km',
    value : 1500
  },
];

export const PRIVACY_AREA_OPTIONS_MILES: DropdownOptionModel[] = [
  {
    name  : '1/8 mi',
    value : 200,
  },
  {
    name  : '1/4 mi',
    value : 500,
  },
  {
    name  : '1/2 mi',
    value : 1000,
  },
  {
    name  : '1 mi',
    value : 1500,
  },
];

export enum DISTANCE_UNITS {
  KM = 'km',
  M  = 'm',
  MI = 'mi',
  FT = 'ft',
  CM = 'cm',
}

export enum WEIGHT_UNITS {
  POUNDS = 'lb',
  KG     = 'kg'
}
