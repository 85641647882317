import styled         from 'styled-components';
import { Button }     from '@mui/material';
import { Control }    from 'react-hook-form';
import { TextField }  from '../../../../../components/TextField';
import { InputError } from '../../../../../components/InputError';

interface IRecoveryPasswordFormProps {
  control     : Control<any>
  isDirty     : boolean;
  isValid     : boolean;
  isSubmitting: boolean;
  onSubmit    : () => void;
  error?      : string;
}

export const RecoveryPasswordForm = ({
  onSubmit,
  control,
  isDirty,
  isValid,
  isSubmitting,
  error
}: IRecoveryPasswordFormProps) => (
  <form onSubmit={onSubmit}>
    <RecoveryPasswordForm.FormWrapper>
      <h1>Create New Password</h1>

      <RecoveryPasswordForm.TextContainer>
        <p>To <strong>protect your account</strong>, please ensure that your password:</p>

        <li>Is at least 8 characters long</li>
        <li>Does not match or significantly contain your username, e.g. `username123`</li>
        <li>Is not member of this <strong>list of common passwords</strong></li>
      </RecoveryPasswordForm.TextContainer>

      <TextField
        dark
        control     = {control}
        name        = "password"
        placeholder = "New Password"
        inputType   = "password"
      />

      <TextField
        dark
        control     = {control}
        name        = "confirmPassword"
        placeholder = "Confirm Password"
        inputType   = "password"
      />

      <RecoveryPasswordForm.ErrorWrapper>
        {error && !isSubmitting && <InputError error={error} />}
      </RecoveryPasswordForm.ErrorWrapper>

      <RecoveryPasswordForm.FormContainer>
        <Button
          disabled = {isSubmitting || !isDirty || (isDirty && !isValid)}
          type     = "submit"
          variant  = "contained"
        >
          Reset Password
        </Button>
      </RecoveryPasswordForm.FormContainer>
    </RecoveryPasswordForm.FormWrapper>
  </form>
);

RecoveryPasswordForm.FormWrapper = styled.div`
  display       : flex;
  width         : 320px;
  flex-direction: column;
  align-items   : stretch;
  flex          : 0 0 auto;

  h1 {
    color: #FFF;
  }

  @media screen and (max-width:479px){
    width: 280px;
  }
`;

RecoveryPasswordForm.FormTitle = styled.div`
  position        : relative;
  display         : flex;
  margin          : 16px 0;
  flex-direction  : column;
  justify-content : center;
  align-self      : stretch;
  text-align      : center;
`;

RecoveryPasswordForm.FormContainer = styled.div`
  display         : flex;
  margin-top      : 12px;
  /** TODO: restore space-between when forgot password is built */
  /* justify-content : space-between; */
  justify-content : center;
  align-items     : center;
  align-self      : stretch;
  flex            : 0 0 auto;
`;

RecoveryPasswordForm.FormLeft = styled.div`
  display        : flex;
  flex-direction : column;
  align-items    : flex-start;
  flex           : 1;
  margin-top     : 7px;

  a {
    text-decoration: none;
    font-weight    : 700;
    transition     : color 200ms;
    color          : ${({ theme }) => theme.colors.primary.rubineLight}
  }

  a:hover {
    color      : #FFF;
    font-size  : 14px;
  }
`;

RecoveryPasswordForm.SectionLabel = styled.div`
  margin-top    : 32px;
  margin-bottom : 32px;
  align-self    : stretch;
  text-align    : center;

  p {
    font-size      : 11px;
    line-height    : 140%;
    letter-spacing : 0.8px;
    text-transform : uppercase;
    color          : #FFF;
    margin-bottom  : 0px;
    opacity        : 0.64;
  }

  @media screen and (max-width: 767px) {
    margin-top    : 24px;
    margin-bottom : 24px;
  }
`;

RecoveryPasswordForm.HiddenInput = styled.input`
  display: none;
`;

RecoveryPasswordForm.ErrorWrapper = styled.div`
  position: relative;

  p {
    top: -10px;
  }
`;

RecoveryPasswordForm.TextContainer = styled.div`
  margin-bottom: 20px;

  p {
    color: #FFF;
  }

  li {
    color: #FFF;
    margin-left: 15px;
    margin-bottom: 15px;
  }
`;