import styled                               from 'styled-components';
import { Grid }                             from '@mui/material';
import { Avatar }                           from '@components/Avatar';
import { RideTypeBadge }                    from '@components/RideTypeBadge';
import { profilePicturesHost }              from '@config/api_config';
import {
  DISTANCE_UNITS,
  MEASUREMENT_SYSTEMS
}                                           from '@modules/Profile/utils/options';
import { IRideInvitation }                  from '@modules/Calendar/models';
import { TMeasurementSystem }               from '@modules/Profile/models/Settings';
import {
  metersToFeet,
  metersToMiles
}                                           from '@utils/conversion_utils';
import { ReactComponent as ElevationIcon }  from '@images/Icon-Metric-Elevation-White.svg';
import { ReactComponent as IntensityIcon }  from '@images/Icon-Metric-Power-Intensity-White.svg';
import { ReactComponent as AttendanceIcon } from '@images/Icon-Metric-Attendance-White.svg';
import { ReactComponent as DistanceIcon }   from '@images/Icon-Metric-Distance-White.svg';

interface IRideBlockProps {
  invitation       : IRideInvitation;
  measurementSystem: TMeasurementSystem;
}

export const RideBlock = ({
  invitation,
  measurementSystem,
}: IRideBlockProps) => {
  const photoUrl = invitation.organizer.profilePictureUrl && !invitation.organizer.profilePictureUrl.includes("null")
    ? `${profilePicturesHost}${invitation.organizer.profilePictureUrl}`
    : undefined

  const distance = measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL
      ? metersToMiles(invitation.ride.distance ?? 0)
      : Math.round((invitation.ride.distance ?? 0) / 1000);

  const elevation = measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL
    ? Math.round(metersToFeet(invitation.ride.elevationGain ?? 0))
    : Math.round(invitation.ride.elevationGain ?? 0);

  return (
    <RideBlock.Wrapper>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <Avatar
            name     = {`${invitation.organizer.firstName} ${invitation.organizer.lastName}`}
            size     = {40}
            photoUrl = {photoUrl}
          />
        </Grid>

        <Grid
          item
          container
          xs         = {10}
          direction  = "column"
          alignItems = "flex-start"
        >
          <Grid item>
            <RideBlock.OrganizerHandle>
              {`@${invitation.organizer.username}`}
            </RideBlock.OrganizerHandle>
          </Grid>
          <Grid item>
            <RideBlock.RideName>
              {invitation.ride.name}
            </RideBlock.RideName>
          </Grid>
        </Grid>
      </Grid>

      <RideBlock.InvitationDetails
        container
        display        = "flex"
        flexDirection  = "row"
        alignItems     = "center"
        justifyContent = "space-between"
      >
        {invitation.ride.type && (
          <Grid item xs="auto">
            <RideTypeBadge rideType={invitation.ride.type} />
          </Grid>
        )}

        <Grid item container spacing="4px" xs="auto">
          <Grid item>
            <AttendanceIcon />
          </Grid>
          <Grid item>
            <RideBlock.MetricsValue>
              {invitation?.attendees?.count || '—'}
            </RideBlock.MetricsValue>
          </Grid>
        </Grid>

        <Grid item container spacing="4px" xs="auto">
          <Grid item>
            <DistanceIcon />
          </Grid>

          <Grid item xs="auto">
            <RideBlock.MetricsValue>
              {distance || '—'}
            </RideBlock.MetricsValue>

            <RideBlock.MetricsUnits>
              {measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL
                ? DISTANCE_UNITS.MI
                : DISTANCE_UNITS.KM}
            </RideBlock.MetricsUnits>
          </Grid>
        </Grid>

        <Grid item container spacing="4px" xs="auto">
          <Grid item>
            <ElevationIcon />
          </Grid>
          <Grid item>
            <RideBlock.MetricsValue>
              {elevation || '—'}
            </RideBlock.MetricsValue>

            <RideBlock.MetricsUnits>
              {measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL
                ? DISTANCE_UNITS.FT
                : DISTANCE_UNITS.M}
            </RideBlock.MetricsUnits>
          </Grid>
        </Grid>

        <Grid item container spacing="4px" xs="auto">
          <Grid item>
            <IntensityIcon />
          </Grid>

          <Grid item>
            <RideBlock.MetricsValue>
              {invitation.ride.intensity || "—"}
            </RideBlock.MetricsValue>
            {invitation.ride.intensity && (<RideBlock.MetricsUnits>/5</RideBlock.MetricsUnits>)}
          </Grid>
        </Grid>
      </RideBlock.InvitationDetails>
    </RideBlock.Wrapper>
  );
};

RideBlock.Wrapper = styled.div`
  padding         : 16px;
  background-color: ${({ theme: { colors: { neutralGrey } } }) => neutralGrey[700]};
`;

RideBlock.MetricsValue = styled.span`
  font-size     : ${({ theme: { fontSizes: { s } } }) => s};
  font-weight   : 700;
  margin-right  : 2px;
  text-transform: capitalize;
`;

RideBlock.MetricsUnits = styled.span`
  font-weight: 500;
  font-size  : 9px;
`;

RideBlock.RideBlock = styled.div`
  padding         : 16px;
  background-color: ${({ theme: { colors: { neutralGrey } } }) => neutralGrey[700]};
`;

RideBlock.InvitationDetails = styled(Grid)`
  padding-top: 12px;
  color      : ${({ theme: { colors: { primary: { white } } } }) => white};

  ${RideBlock.MetricsUnits} {
    color: ${({ theme: { colors: { type: { light } } } }) => light};
  }

  svg {
    width : 18px;
    height: 18px;
  }
`;

RideBlock.RideName = styled.div`
  font-weight: 700;
  line-height: 14px;
  color      : ${({ theme: { colors: { primary: { white } } } }) => white};
`;

RideBlock.OrganizerHandle = styled.div`
  font-weight  : 500;
  font-size    : 10px;
  line-height  : 12px;
  margin-bottom: 4px;
  color        : ${({ theme: { colors: { type: { light } } } }) => light};
`;
