import * as Yup from 'yup';

export interface INonMemberInvitation {
  emails        : string[];
  message       : string;
  eventDate?    : string;
  plannedRideId?: string;
}

export interface INonMemberInvitationForm {
  emails        : {value: string}[];
  message       : string;
  eventDate?    : string;
  plannedRideId?: string
}

export const invitationFormValidationSchema = Yup.object().shape({
  message: Yup.string().required('Message is required'),
  emails : Yup.array().of(
    Yup.object().shape({
      value: Yup.string().email('Invalid email address').required('Required')
    })
  ).uniqueValueInArray('value', 'Email is not unique')
});

export const invitationDefaultValues: INonMemberInvitationForm = {
  emails : [{value: ''}],
  message: ''
};
