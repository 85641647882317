import { createApi }                from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery }           from '@utils/baseQuery';
import { appendUrlParams }          from '@utils/commonFunctions';
import { IRouteModel, IRoutePhoto } from '@models/RouteModel';
import { METHOD }                   from '@models/Request';
import {
  IResponseWithPagination,
  TPagination
}                                   from '@models/types';
import {
  IRideWeather,
  ITimeline
}                                   from '@models/rideWeather';
import {
  IInvitation,
  INVITATION_STATUS
}                                   from '../models/invitation';
import {
  IRide,
  INearestRideDetails,
  IRidePhoto,
}                                   from '../models/ride';
import {
  IAttendingRider,
  IRecurringRideStatistics,
  IReccuringRideHistoryStatistics
}                                   from '../models/statistics';
import { IRideHistoryResponse }     from '../models/rideHistory';
import {
  IRideInvitation,
  IRideInvitations
}                                   from '../../Calendar/models';
import { IRideAttendee }            from '../models/attendees';

const RIDE_INVITATIONS = `rideinvitations`;
const PLANNED_RIDES    = 'plannedrides';
const ROUTES           = 'routes';

const PLANNED_RIDES_LINKS              = `${PLANNED_RIDES}/links`;
const PLANNED_RIDE                     = `${PLANNED_RIDES}/:id`;
const PLANNED_RIDE_LINK                = `${PLANNED_RIDES_LINKS}/:id`
const RIDE_DETAILS_EVENTS              = `${PLANNED_RIDE}/events`;
const NEAREST_RIDE_DETAILS             = `${RIDE_DETAILS_EVENTS}`;
const NEAREST_RIDE_DETAILS_LINK        = `${PLANNED_RIDE_LINK}/events`;
const RIDE_ATTENDEES                   = `${RIDE_DETAILS_EVENTS}/attendees`
const RIDE_INVITEES                    = `${RIDE_DETAILS_EVENTS}/invitees`;
const UPDATE_ATTENDANCE                = `${RIDE_DETAILS_EVENTS}/invitations`;
const PLANNED_RIDES_STATISTICS         = `${PLANNED_RIDE}/statistics`;
const TOP_ATTENDING_RIDERS             = `${PLANNED_RIDES_STATISTICS}/riders/attendance`;
const TOP_ATTENDING_CONNECTIONS        = `${PLANNED_RIDES_STATISTICS}/connections/attendance`;
const RIDE_DETAILS                     = `${PLANNED_RIDES}/:id/events`;
const RIDE_DETAILS_LINK                = `${PLANNED_RIDES_LINKS}/:id/events`;
const REMOVE_ROUTE_FROM_RIDE           = `${PLANNED_RIDES}/:id/routes`;
const RIDE_EVENT_WEATHER               = `${RIDE_DETAILS}/weather`;
const RIDE_EVENT_WEATHER_LINK          = `${RIDE_DETAILS_LINK}/weather`;
const PLANNED_RIDES_HISTORY_STATISTICS = `${PLANNED_RIDES_STATISTICS}/general`;
const PLANNED_RIDES_HISTORY            = `${PLANNED_RIDE}/history`;
const PLANNED_RIDE_PHOTOS              = `${PLANNED_RIDE}/photos`;
const PUBLIC_RIDES_URL                 = `${PLANNED_RIDES}/public/events`;
const ROUTE_TRACKPOINTS_PRIVATE        = `${ROUTES}/:id/trackpoints/private`;
const ROUTE_TRACKPOINTS_MASKED         = `${ROUTES}/:id/trackpoints/masked`;
const ROUTE_WEATHER                    = `${ROUTES}/:id/weather`;
const ROUTE_PHOTOS                     = `${ROUTES}/:id/photos`;
const ROUTE_PHOTOS_DELETE              = `${ROUTES}/photos`;
const PLANNED_RIDE_ROUTE_LINK          = `${PLANNED_RIDES}/links/:id/route`;
const PLANNED_RIDE_TRACKPOINTS_LINK    = `${PLANNED_RIDES}/links/:id/trackpoints`;

export const detailsApi = createApi({
  baseQuery   : axiosBaseQuery,
  reducerPath : 'detailsApi',
  tagTypes    : [
    'Invitations',
    'RideDetailsWithAttendance',
    'PlannedRides',
    'RideAttendance',
    'RideDetailsWithRoute',
    'RideDetails',
    'RideHistory',
    'RideStatistics',
    'RideHistoryStatistics',
    'TopAttendingRiders',
    'TopAttendingConnections',
    'RideEventWeather',
    'RidePhotos',
    'RideInvitees',
    'PublicRides',
    'RouteDetails',
    'RouteTrackpoints',
    'RouteWeather',
    'RoutePhotos'
  ],
  endpoints   : (builder) => ({
    // GET Ride Details with Attendance Info
    getNearestRideDetails: builder.query<INearestRideDetails, { id: string, rideDate?: string, isShared?: boolean }>({
      query       : ({ id, rideDate, isShared = false }) => ({
        url:
          isShared ? `${NEAREST_RIDE_DETAILS_LINK.replace(':id', id)}`
          : (rideDate ? `${NEAREST_RIDE_DETAILS.replace(':id', id)}?date=${rideDate}`: NEAREST_RIDE_DETAILS.replace(':id', id))
      }),
      providesTags: ['RideDetailsWithAttendance']
    }),

    // GET User Planned Rides Dates
    getRidesDates: builder.query<string[], void>({
      query            : () => ({ url: PLANNED_RIDES }),
      transformResponse: (response: (IRide & {date: string})[]) => response.map(ride => ride?.date),
      providesTags     : ['PlannedRides']
    }),

    // CREATE User Planned Ride
    createPlannedRide: builder.mutation<void, Omit<IRide, 'startLocation' | 'timeZoneId' | 'routeDetails' | 'date' | 'status' | 'routeType'>>({
      query: (body: IRide) => ({
        url   : PLANNED_RIDES,
        method: METHOD.POST,
        successMessage: 'Ride has been created',
        body
      }),
      invalidatesTags: ['PlannedRides', 'Invitations']
    }),

    //GET Ride Attendance
    getRideAttendance: builder.query<IResponseWithPagination<IRideAttendee[]>, TPagination & { rideId: string, date: string}>({
      query: ({
        rideId,
        date,
        pageNumber,
        pageSize,
        // sortColumn,
        // sortDirection
      }) => ({ url: `${RIDE_ATTENDEES.replace(':id', rideId)}?date=${date}&pageNumber=${pageNumber}&pageSize=${pageSize}` }),
      providesTags     : ['RideAttendance'],
      keepUnusedDataFor: 0,
    }),

    //GET Ride Invitees
    getRideInvitees: builder.query<IResponseWithPagination<IInvitation[]>, TPagination & { rideId: string, date: string, status?: INVITATION_STATUS }>({
      query: ({
        rideId,
        status,
        date,
        pageNumber,
        pageSize,
        sortColumn,
        sortDirection
      }) => ({ url: appendUrlParams(RIDE_INVITEES.replace(':id', rideId), { date, status, pageNumber, pageSize, sortColumn, sortDirection }) }),
      providesTags     : ['RideInvitees'],
      keepUnusedDataFor: 0,
    }),

    // GET Ride invitations
    getRideInvitations: builder.query<IRideInvitations, { from: string, to:string }>({
      query: ({ from, to }) => ({
        url: `${RIDE_INVITATIONS}?from=${from}&to=${to}`
      }),
      providesTags: ['Invitations'],
    }),

    // GET Group Rides (Public Rides) by Gen Location ID and Date
    getPublicRides: builder.query<IRideInvitation[], { from: string, to: string, genLocationId: string}>({
      query             : ({ from, to, genLocationId  }) => ({ url: appendUrlParams(PUBLIC_RIDES_URL, { from, to, genLocationId }) }),
      transformResponse : (data: Record<string, IRideInvitation>) => {
        const values         = Object.values(data) as any[];
        const formattedArray = [].concat.apply([], values);

        return formattedArray;
      },
      providesTags      : (_result, _error, { from, to }) => [{ type: 'PublicRides', id: `${from}-${to}` }],
    }),

    // PUT Ride Invitation Status
    putRideInvitationResponse: builder.mutation<void, { rideId: string, status: string, date? : string }>({
      query: ({ status, rideId, date }) => ({
        url    : date ? `${UPDATE_ATTENDANCE.replace(':id', rideId)}?date=${date}`: UPDATE_ATTENDANCE.replace(':id', rideId),
        method : METHOD.PUT,
        body   : { status }
      }),
      invalidatesTags: (_result, _error, { date }) => [
        { type: 'Invitations' },
        { type: 'RideDetailsWithAttendance' },
        { type: 'RideAttendance' },
        { type: 'TopAttendingRiders' },
        { type: 'PublicRides', id: `${date}-${date}` },
      ],
    }),

    // GET Ride Details
    getRideDetails: builder.query<IRide, { id: string, isShared?: boolean }>({
      query            : ({ id, isShared = false }) => ({ url: isShared ? PLANNED_RIDE_LINK.replace(':id', id) : PLANNED_RIDE.replace(':id', id) }),
      providesTags     : ['RideDetails']
    }),

    // GET Recurring Ride Statistics
    getRecurringRideStatistics: builder.query<IRecurringRideStatistics, { id: string }>({
      query       : ({ id }) => ({ url: PLANNED_RIDES_STATISTICS.replace(':id', id) }),
      providesTags: ['RideStatistics']
    }),

    // GET Top Attending Riders
    getTopAttendingRiders: builder.query<IAttendingRider[], { id: string, top?: number }>({
      query       : ({ id, top }) => {
        const formattedUrl = TOP_ATTENDING_RIDERS.replace(':id', id);

        return { url: top ? `${formattedUrl}?top=${top}` : formattedUrl };
      },
      providesTags: ['TopAttendingRiders']
    }),

    // GET Top Attending Connections
    getTopAttendingConnections: builder.query<IAttendingRider[], { id: string, top?: number }>({
      query       : ({ id, top }) => {
        const formattedUrl = TOP_ATTENDING_CONNECTIONS.replace(':id', id);

        return { url: top ? `${formattedUrl}?top=${top}` : formattedUrl };
      },
      providesTags: ['TopAttendingConnections']
    }),

    // GET Ride Event Weather
    getRideEventWeather: builder.query<ITimeline[], { id: string, rideDate?: string, isShared?: boolean }>({
      query             : ({ id, rideDate, isShared = false }) => ({
        url: isShared ? `${RIDE_EVENT_WEATHER_LINK.replace(':id', id)}`
          : (rideDate ? `${RIDE_EVENT_WEATHER.replace(':id', id)}?date=${rideDate}` : RIDE_EVENT_WEATHER.replace(':id', id))
      }),
      transformResponse : (response: IRideWeather) => response.data.route.map(route => route.timeline),
      providesTags      : ['RideDetails', 'RideEventWeather']
    }),

    // GET Ride History Statistics
    getRideHistoryStatistics: builder.query<IReccuringRideHistoryStatistics, { rideId: string }>({
      query       : ({ rideId }) => ({ url: PLANNED_RIDES_HISTORY_STATISTICS.replace(':id', rideId) }),
      providesTags: ['RideHistoryStatistics']
    }),

    // GET Ride History
    getRideHistory: builder.query<IRideHistoryResponse, TPagination & { rideId: string }>({
      query : ({
        rideId,
        pageNumber,
        pageSize,
        sortColumn,
        sortDirection
      }) => ({ url: `${PLANNED_RIDES_HISTORY.replace(':id', rideId)}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}` }),
      providesTags: ['RideHistory']
    }),

    // GET Ride Photos
    getRidePhotos: builder.query<IResponseWithPagination<IRidePhoto[]>, TPagination & { rideId: string, rideDate?: string }>({
      query        : ({
        rideId,
        rideDate,
        pageNumber,
        pageSize,
      }) => ({
        url: rideDate ? `${PLANNED_RIDE_PHOTOS.replace(':id', rideId)}?date=${rideDate}&pageNumber=${pageNumber}&pageSize=${pageSize}`
        : `${PLANNED_RIDE_PHOTOS.replace(':id', rideId)}?pageNumber=${pageNumber}&pageSize=${pageSize}`
      }),
      providesTags : ['RidePhotos']
    }),

    // POST Planned Ride Photos
    uploadRidePhotos: builder.mutation<void, { rideId: string, rideDate: string, body: FormData }>({
      query: ({ rideId, rideDate, body }) => ({
        url    : `${PLANNED_RIDE_PHOTOS.replace(':id', rideId)}?date=${rideDate}`,
        method : METHOD.POST,
        body
      }),
      invalidatesTags: ['RidePhotos']
    }),

    // DELETE Planned Ride Photos
    deleteRidePhotos: builder.mutation<void, {fileLinks: string[], rideId: string}>({
      query: ({ fileLinks, rideId }) => ({
        url   : `${PLANNED_RIDE_PHOTOS.replace(':id', rideId)}`,
        method: METHOD.DELETE,
        body: {
          fileLinks
        }
      }),
      invalidatesTags: ['RidePhotos']
    }),

    // UPDATE User Planned Ride
    updatePlannedRide: builder.mutation<void, Partial<IRide>>({
      query: ({ id, ...body }) => ({
        url           : `${PLANNED_RIDES}/${id}`,
        method        : 'PATCH',
        successMessage: 'Ride has been updated',
        body
      }),
      invalidatesTags: [
        'Invitations',
        'RideDetailsWithAttendance',
        'PlannedRides',
        'RideAttendance',
        'RideDetailsWithRoute',
        'RideDetails',
        'RideHistory',
        'RideStatistics',
        'RideHistoryStatistics',
        'TopAttendingRiders',
        'TopAttendingConnections',
        'RideEventWeather',
        'RidePhotos',
        'RideInvitees',
        'RouteTrackpoints',
      ]
    }),

    removeRouteFromRide: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url   : REMOVE_ROUTE_FROM_RIDE.replace(':id', id),
        method: METHOD.DELETE,
      }),
    }),

    // GET Route Details by ID
    getRouteDetails: builder.query<IRouteModel, { id: string, isShared?: boolean }>({
      query       : ({ id, isShared = false }) => ({ url: isShared ? PLANNED_RIDE_ROUTE_LINK.replace(':id', id) : `${ROUTES}/${id}`}),
      providesTags: ['RouteDetails']
    }),

    // GET Route Trackpoints by ID
    getRouteTrackpoints: builder.query<{ type: string, coordinates: [number, number, number][] }, { id: string, isShared?: boolean, isPrivate?: boolean }>({
      query: ({ id, isShared = false, isPrivate = false }) => {
        const routeTrackpointsUrl = isPrivate ? ROUTE_TRACKPOINTS_PRIVATE : ROUTE_TRACKPOINTS_MASKED;

        return {
          url: isShared ?
            PLANNED_RIDE_TRACKPOINTS_LINK.replace(':id', id) :
            routeTrackpointsUrl.replace(':id', id),
        };
      },
      transformResponse: (response: { trackpoints: string }) => ({ ...JSON.parse(response.trackpoints) }),
      providesTags     : ['RouteTrackpoints']
    }),

    // GET Route Weather by ID
    getRouteWeather: builder.query<ITimeline[], { routeId: string, date: string }>({
      query            : ({ routeId, date }) => ({ url: `${ROUTE_WEATHER.replace(':id', routeId)}?date=${date}` }),
      transformResponse: (response: IRideWeather) => response.data.route.map(route => route.timeline),
      providesTags     : ['RouteWeather']
    }),

    // GET Route Photos
    getRoutePhotos: builder.query<IResponseWithPagination<IRoutePhoto[]>, TPagination & { routeId: string }>({
      query        : ({
        routeId,
        pageNumber,
        pageSize,
      }) => ({ url: `${ROUTE_PHOTOS.replace(':id', routeId)}?pageNumber=${pageNumber}&pageSize=${pageSize}` }),
      providesTags : ['RoutePhotos']
    }),

    // POST Route Photos
    uploadRoutePhotos: builder.mutation<void, { routeId: string, body: FormData }>({
      query: ({ routeId, body }) => ({
        url    : `${ROUTE_PHOTOS.replace(':id', routeId)}`,
        method : METHOD.POST,
        body
      }),
      invalidatesTags: ['RoutePhotos', 'RidePhotos']
    }),

    // DELETE Route Photos
    deleteRoutePhotos: builder.mutation<void, { fileLinks: string[] }>({
      query: ({ fileLinks }) => ({
        url    : ROUTE_PHOTOS_DELETE,
        method : METHOD.DELETE,
        body: {
          fileLinks
        }
      }),
      invalidatesTags: ['RoutePhotos', 'RidePhotos']
    }),

    // POST Generate preview link
    generatePreviewLink: builder.mutation<{ uuid: string }, { id: string; }>({
      query: ({ id }) => ({
        url    : PLANNED_RIDES_LINKS,
        method : METHOD.POST,
        body   : { id },
      }),
    }),

    // POST Cancel Ride
    cancelPlannedRide: builder.mutation<void, { rideId: string; }>({
      query: ({ rideId }) => ({
        url           : `${PLANNED_RIDES}/${rideId}/cancel`,
        method        : METHOD.POST,
        successMessage: 'Ride has been canceled'
      }),
    }),
  }),
});

export const {
  useGetRideInvitationsQuery,
  useGetRidesDatesQuery,
  useGetNearestRideDetailsQuery,
  useGetRideDetailsQuery,
  useGetRecurringRideStatisticsQuery,
  useGetTopAttendingRidersQuery,
  useGetTopAttendingConnectionsQuery,
  useGetRideEventWeatherQuery,
  useGetRideHistoryStatisticsQuery,
  useGetRideHistoryQuery,
  useGetRidePhotosQuery,
  useGetRideAttendanceQuery,
  useGetRideInviteesQuery,
  useGetRouteDetailsQuery,
  useRemoveRouteFromRideMutation,
  useGetRouteTrackpointsQuery,
  useGetRouteWeatherQuery,
  useGetRoutePhotosQuery,
  useDeleteRidePhotosMutation,
  useDeleteRoutePhotosMutation,

  useCreatePlannedRideMutation,
  usePutRideInvitationResponseMutation,
  useUploadRidePhotosMutation,
  useUpdatePlannedRideMutation,
  useGetPublicRidesQuery,
  useUploadRoutePhotosMutation,
  useGeneratePreviewLinkMutation,
  useCancelPlannedRideMutation
} = detailsApi;
