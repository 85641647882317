import { useCallback }       from 'react';
import { useHistory }        from 'react-router-dom';
import { ECtaTab }           from '@modules/CreateModal/models/CreateModalContent';
import { useSessionStorage } from '@utils/hooks/useSessionStorage';
import { appendUrlParams }   from '@utils/commonFunctions';

export const CTA_PREVIOUS_PAGE_KEY = 'cta-previous-path';

type TUrlParam = Record<string | symbol, string | number | boolean | undefined | null>;

interface ICtaOpenParams {
  tab       : ECtaTab,
  urlParams?: TUrlParam | null,
}

interface IUseCtaReturn {
  openCta : (params: ICtaOpenParams) => void;
  closeCta: (disableRedirectToPreviousPage?: boolean) => void;
}

export const useCta = (): IUseCtaReturn => {
  const history = useHistory();
  const {
    setItem,
    getItem,
    removeItem,
  } = useSessionStorage();

  const openCta = useCallback(
    ({ tab, urlParams }: ICtaOpenParams) => {
      const { pathname } = history.location;

      if (!pathname.includes('/cta')) {
        setItem(CTA_PREVIOUS_PAGE_KEY, { pathname });
      }

      const url = urlParams ? appendUrlParams(`/cta/${tab}`, urlParams) : `/cta/${tab}`;

      history.push(url);
    },
    [history, setItem],
  );

  const closeCta = useCallback(
    (disableRedirectToPreviousPage?: boolean) => {
      const memoizedPathname = getItem<{ pathname: string; }>(CTA_PREVIOUS_PAGE_KEY);

      if (memoizedPathname) {
        removeItem(CTA_PREVIOUS_PAGE_KEY);
      }

      history.push(disableRedirectToPreviousPage ? '/' : memoizedPathname?.pathname ?? '/');
    },
    [getItem, history, removeItem],
  );

  return { openCta, closeCta };
};
