import { useMemo }  from 'react';
import {
  Redirect,
  useLocation
}                   from 'react-router-dom';
import { withAuth } from '@utils/HOCs/withAuth';

const RedirectInvitationBase = () => {
  const { search } = useLocation();

  const rideLink = useMemo(
    () => {
      const searchParams = new URLSearchParams(search);

      const plannedRideId = searchParams.get('ride');
      const eventDate     = searchParams.get('date');

      if (plannedRideId && eventDate) {
        return `/ride/${plannedRideId}/${eventDate}/overview`;
      }

      if (plannedRideId) {
        return `/ride/${plannedRideId}/overview`;
      }

      return '/';
    },
    [search],
  );

  return <Redirect to={rideLink} />;
};

export const RedirectInvitation = withAuth(RedirectInvitationBase);
 