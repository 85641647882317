import { useMemo }                 from 'react';
import { format }                  from 'date-fns';
import { isIOS, isAndroid }        from 'react-device-detect';
import { useParams }               from 'react-router-dom';
import styled, { css }             from 'styled-components';
import { Grid }                    from '@mui/material';
import { Button }                  from '@mui/material';
import { IUserBaseInfo }           from '@modules/Profile/models/UserInfo';
import {
  getDateUTCFromISO,
  getTimeFormat,
}                                  from '@utils/time_utils';
import { useGetUserSettingsQuery } from '../../../Profile/queries';

interface IInvitationBannerProps {
  attendeesCount         : number;
  isPreview?             : boolean;
  isRideStarted?         : boolean;
  organizerInfo?         : IUserBaseInfo;
  title?                 : string;
  when?                  : string;
}

export const InvitationBanner = ({
  attendeesCount,
  organizerInfo,
  title,
  when,
}: IInvitationBannerProps) => {
  const { data: settings } = useGetUserSettingsQuery();
  const { linkUuid }       = useParams<{ linkUuid: string; }>();
  const timeFormat         = getTimeFormat(settings?.timeType);

  const appLink = useMemo(
    () => {
      let deepLink = '';

      const encodedCurrentLink = encodeURIComponent(window.location.href);

      if (isIOS) {
        deepLink = `https://gopreem.page.link/?link=${encodedCurrentLink}&ibi=com.gopreem.preem&isi=6443659970&efr=1`;
      }

      if (isAndroid) {
        // deepLink = `https://gopreem.page.link/?link=${encodedCurrentLink}&ibi=com.gopreem.preem&isi=6443659970&efr=1`;
      }

      return deepLink;
    },
    []
  );


  return (
      <InvitationBanner.Wrapper
        container
        alignContent   = "center"
        justifyContent = "space-between"
      >
        <InvitationBanner.InvitedMessage>
          <InvitationBanner.InvitedMessageHighlight>
            You&apos;re invited&nbsp;
          </InvitationBanner.InvitedMessageHighlight>
           to {title} on {format(getDateUTCFromISO(when ?? ''), 'P')} at {format(getDateUTCFromISO(when ?? ''), timeFormat)} organized by {`${organizerInfo?.firstName} ${organizerInfo?.lastName}`}.
        </InvitationBanner.InvitedMessage>
        <InvitationBanner.AttendRow>
          <Button
            variant  = "contained"
            disabled = {false}
            href     = {appLink}
            target   = "_blank"
            >
            Join Ride
          </Button>
          <InvitationBanner.Attendees>
            {attendeesCount === 0 ? 'Be the first to respond' : `${attendeesCount} Attendee${attendeesCount > 1 ? 's' : ''}`}
          </InvitationBanner.Attendees>
        </InvitationBanner.AttendRow>

      </InvitationBanner.Wrapper>
  );
}

InvitationBanner.Wrapper = styled(Grid)`
  background-color: ${({theme: {colors: {primary: { white }}}}) => white };
  padding         : 25px 10px 10px 20px;
  display         : flex;
  align-items     : center;
  justify-content : center;
  max-width       : 610px;
  margin          : auto;
  
  ${({theme: {mediaQueries: { md }}}) => md} {
    padding: 50px 15px 15px 20px;
  }
`;

InvitationBanner.InvitedMessage = styled.div`
  color       : ${({ theme: { colors: { primary }}}) => primary.purple};
  font-size   : ${({ theme: { fontSizes: { hero_s }}}) => hero_s};
  font-weight : bold;
  
  ${({theme: {mediaQueries: {md}}}) => md} {
    font-size: ${({ theme: { fontSizes: { hero_l }}}) => hero_l};
  }
`;

InvitationBanner.InvitedMessageHighlight = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.primary.rubineLight};
  `};
`;

InvitationBanner.AttendRow = styled.div`
  align-items     : center;
  display         : flex;
  justify-content : flex-start;
  padding         : 30px 0px;
  width           : 100%;
`;
InvitationBanner.Attendees = styled.span`
  font-size    : ${({ theme: { fontSizes: { s }}}) => s};
  padding-left : 20px;
`;