import {
  useCallback,
  useState
}                             from 'react';
import styled                 from 'styled-components';
import { Grid }               from '@mui/material';
import { Modal }              from '../../../../../../components/Modal';
import { RangedInput }        from '../../../../../../components/RangedInput';
import {
  TNumberRange,
  DISTANCE_RANGE,
  DISTANCE_STEP,
  ELEVATION_RANGE,
  ELEVATION_STEP,
  RIDE_LENGTH_RANGE,
  RIDE_LENGTH_STEP,
  DATE_RANGE,
  DATE_STEP,
  DISTANCE_RANGE_MILES,
  ELEVATION_RANGE_FEET
}                             from '../../../../utils/rangeValues';
import { RangedDateInput }    from '../../../../../../components/RangedDateInput';
import { TMeasurementSystem } from '../../../../models/Settings';
import {
  DISTANCE_UNITS,
  MEASUREMENT_SYSTEMS
}                             from '../../../../utils/options';
import {
  feetToMeters,
  milesToMeters
}                             from '../../../../../../utils/conversion_utils';
import {
  cutDateFromISO,
  hoursToSecs
}                             from '../../../../../../utils/time_utils';

export interface IRideFilters {
  distance : TNumberRange;
  elevation: TNumberRange;
  duration : TNumberRange;
  rideDate : [string, string];
}

interface IFilterModalProps {
  isOpen           : boolean;
  measurementSystem: TMeasurementSystem;
  onClose          : () => void;
  onSubmit         : (values: IRideFilters) => void;
}

export const RideFiltersModal = ({
  isOpen,
  measurementSystem,
  onClose,
  onSubmit,
}: IFilterModalProps) => {
  const isImperialSystem = measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL;

  const distanceRange  = isImperialSystem ? DISTANCE_RANGE_MILES : DISTANCE_RANGE;
  const elevationRange = isImperialSystem ? ELEVATION_RANGE_FEET : ELEVATION_RANGE

  const [distance, setDistance]   = useState<TNumberRange>(distanceRange);
  const [elevation, setElevation] = useState<TNumberRange>(elevationRange);
  const [duration, setDuration]   = useState<TNumberRange>(RIDE_LENGTH_RANGE);
  const [rideDate, setRideDate]   = useState<TNumberRange>(DATE_RANGE);

  const handleSave = useCallback(
    () => {
      const distanceValues = isImperialSystem ?
        [milesToMeters(distance[0]), milesToMeters(distance[1])] : [distance[0] * 1000, distance[1] * 1000];

      const elevationValues = isImperialSystem ?
        [feetToMeters(elevation[0]), feetToMeters(elevation[1])] : elevation;

      const durationValues = [hoursToSecs(duration[0]), hoursToSecs(duration[1])]

      onSubmit({
        distance : distanceValues as TNumberRange,
        elevation: elevationValues as TNumberRange,
        duration : durationValues as TNumberRange,
        rideDate : [cutDateFromISO(rideDate[0]), cutDateFromISO(rideDate[1])],
      });
    },
    [distance, duration, elevation, isImperialSystem, onSubmit, rideDate],
  );

  return (
    <Modal
      isOpen         = {isOpen}
      submitBtnLabel = "Save Filters"
      itemName       = "Routes"
      onSave         = {handleSave}
      onClose        = {onClose}
    >
      <RideFiltersModal.Container container>
        <Grid item xs={12}>
          <RangedInput
            label        = "Distance"
            value        = {distance}
            onChange     = {(_event, value) => setDistance(value as TNumberRange)}
            step         = {DISTANCE_STEP}
            max          = {distanceRange[1]}
            min          = {distanceRange[0]}
            valuePostfix = {isImperialSystem ? DISTANCE_UNITS.MI : DISTANCE_UNITS.KM}
          />
        </Grid>

        <Grid item xs={12}>
          <RangedInput
            label        = "Elevation"
            value        = {elevation}
            onChange     = {(_event, value) => setElevation(value as TNumberRange)}
            step         = {ELEVATION_STEP}
            max          = {elevationRange[1]}
            min          = {elevationRange[0]}
            valuePostfix = {isImperialSystem ? DISTANCE_UNITS.FT : DISTANCE_UNITS.M}
          />
        </Grid>

        <Grid item xs={12}>
          <RangedInput
            label        = "Ride Length"
            value        = {duration}
            onChange     = {(_event, value) => setDuration(value as TNumberRange)}
            step         = {RIDE_LENGTH_STEP}
            max          = {RIDE_LENGTH_RANGE[1]}
            min          = {RIDE_LENGTH_RANGE[0]}
            valuePostfix = "hr"
          />
        </Grid>

        <Grid item xs={12}>
          <RangedDateInput
            label    = "Ride Date"
            value    = {rideDate}
            onChange = {setRideDate}
            min      = {DATE_RANGE[0]}
            max      = {DATE_RANGE[1]}
            step     = {DATE_STEP}
          />
        </Grid>
      </RideFiltersModal.Container>
    </Modal>
  );
};

RideFiltersModal.Container = styled(Grid)`
  .MuiFormControl-root {
    padding-top    : 24px;
    padding-bottom : 24px;
    border-radius  : 0;
    height         : auto;
  }
`;
