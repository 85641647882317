import React  from 'react';
import styled from 'styled-components';

interface IFieldWrapperProps {
  children: React.ReactNode;
}

export const FieldWrapper = ({ children }: IFieldWrapperProps) => (
  <FieldWrapper.Container>
    {children}
  </FieldWrapper.Container>
);

FieldWrapper.Container = styled.div`
  text-align     : left;
  padding-top    : 20px;
  border-bottom  : none;

  ${ ({ theme: { mediaQueries: {sm} } }) => sm } {
    border-bottom  : 1px solid ${({ theme }) => theme.colors.neutralGrey[400]};
  }
`;