import { useMemo }     from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = <TParams extends { [K in keyof TParams]?: string }>(): TParams => {
  const { search } = useLocation();

  return useMemo(
    () => Object.fromEntries(new URLSearchParams(search).entries()) as TParams,
    [search]
  );
}
