import {
  Control,
  Controller,
  Path
}                      from 'react-hook-form';
import { ElementType } from 'react';
import styled, { css } from 'styled-components';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
}                      from '@mui/material';

export interface DropdownOptionModel {
  name  : string;
  value?: string | number;
}

interface IDropdownProps<T> {
  name          : Path<T>;
  control       : Control<T, object>;
  label         : string;
  options       : DropdownOptionModel[];
  value?        : string;
  defaultValue? : string | number;
  fullWidth?    : boolean;
  fontSize?     : string;
  error?        : boolean;
  iconComponent?: ElementType;
  field?        : unknown;
  onChange?     : (event: SelectChangeEvent<unknown>) => void;
  disabled?     : boolean;
}

export const Dropdown = <T, >({
  control,
  name,
  label,
  fullWidth,
  defaultValue,
  fontSize,
  iconComponent,
  error,
  onChange,
  disabled,
  value,
  options
}: IDropdownProps<T>) => (
  <Controller
    name    = {name}
    control = {control}
    render  = {({ field }) => (
      <FormControl variant="filled" sx={{ width: '100%' }}>
        <InputLabel>{label}</InputLabel>

        <Dropdown.Select
          variant        = "filled"
          fullWidth      = {fullWidth}
          defaultValue   = {defaultValue || ''}
          fontSize       = {fontSize}
          IconComponent  = {iconComponent}
          $iconTransform = {iconComponent && 'none'}
          error          = {error}
          name           = {field.name}
          value          = {value || field.value}
          onChange       = {onChange || field.onChange}
          disabled       = {disabled}
          ref            = {field.ref}
          disableUnderline
        >
          {options.map(option => (
            <MenuItem key={option.value} value={option.value ?? undefined}>
              <Dropdown.Highlight className="dropdown-highlight"/>
              {option.name}
            </MenuItem>
          ))}
        </Dropdown.Select>
      </FormControl>
    )}
  />
);

Dropdown.Highlight = styled.div`
  display                   : block;
  width                     : 3px;
  height                    : 28px;
  background-color          : transparent;
  position                  : absolute;
  margin-left               : -16px;
  border-top-right-radius   : 4px;
  border-bottom-right-radius: 4px;
  transition                : background-color 200ms ease-in-out;
`

Dropdown.Select = styled(Select)<{fontSize?: string, error?:boolean, $iconTransform?: string}>`
  ${({
    theme: {fontSizes: {m}, colors: {neutralGrey, messaging: {red}}},
    fontSize,
    error,
    $iconTransform
  }) => css`
    &.MuiInputBase-root .MuiSelect-select {
      font-size: ${fontSize || m}
    }

    &.MuiInputBase-root.Mui-focused {
      box-shadow: ${error ? 'none' : `0 0 0 2px ${neutralGrey[800]}`};
    }

    .MuiSelect-select {
      box-shadow: ${error && `0 0 0 2px ${red}`};
    }

    .MuiSelect-icon { 
      transform: ${$iconTransform};
    }
  `}
`;
