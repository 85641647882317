import React, { useRef } from 'react';
import styled            from 'styled-components';
import { 
  areMessageUIPropsEqual, 
  MessageContextValue, 
  messageHasAttachments, 
  messageHasReactions, 
  MessageInput, 
  MessageText, 
  MessageUIComponentProps, 
  MML, 
  Modal, 
  useComponentContext, 
  useMessageContext,
  Avatar as DefaultAvatar,
  EditMessageForm as DefaultEditMessageForm,
  MessageDeleted as DefaultMessageDeleted,
  MessageOptions as DefaultMessageOptions,
  MessageRepliesCountButton as DefaultMessageRepliesCountButton,
  MessageStatus as DefaultMessageStatus,
  MessageTimestamp as DefaultMessageTimestamp,
  ReactionSelector as DefaultReactionSelector,
  ReactionsList as DefaultReactionList,
}                         from 'stream-chat-react';
import { 
  DefaultAttachmentType, 
  DefaultChannelType, 
  DefaultCommandType, 
  DefaultEventType, 
  DefaultMessageType, 
  DefaultReactionType, 
  DefaultUserType 
}                         from 'stream-chat-react/dist/types/types';

const Wrapper = styled.div`
  && .str-chat__message-text-inner {
    background-color : ${({ isMyMessage }: { isMyMessage: boolean }) => isMyMessage ? '#255F9F' : '#ECEBEB'};
    color            : ${({ isMyMessage }: { isMyMessage: boolean }) => isMyMessage ? '#FFFFFF' : '#000'};
  }
`;

type MessageSimpleWithContextProps<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = MessageContextValue<At, Ch, Co, Ev, Me, Re, Us>;

const MessageWithContext = <
  At extends DefaultAttachmentType = DefaultAttachmentType, 
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
  props: MessageSimpleWithContextProps<At, Ch, Co, Ev, Me, Re, Us>,
) => {
  const {
    additionalMessageInputProps,
    clearEditingState,
    editing,
    endOfGroup,
    firstOfGroup,
    groupedByUser,
    handleAction,
    handleOpenThread,
    handleRetry,
    isMyMessage,
    isReactionEnabled,
    message,
    onUserClick,
    onUserHover,
    reactionSelectorRef,
    showDetailedReactions,
    threadList,
  } = props;

  const {
    Attachment,
    Avatar = DefaultAvatar,
    EditMessageInput = DefaultEditMessageForm,
    MessageDeleted = DefaultMessageDeleted,
    MessageOptions = DefaultMessageOptions,
    MessageRepliesCountButton = DefaultMessageRepliesCountButton,
    MessageStatus = DefaultMessageStatus,
    MessageTimestamp = DefaultMessageTimestamp,
    ReactionSelector = DefaultReactionSelector,
    ReactionsList = DefaultReactionList,
  } = useComponentContext<At, Ch, Co, Ev, Me, Re, Us>('MessageSimple');

  const messageWrapperRef = useRef<HTMLDivElement | null>(null);

  const hasAttachment = messageHasAttachments(message);
  const hasReactions = messageHasReactions(message);

  const messageClasses = isMyMessage()
    ? 'str-chat__message str-chat__message--me str-chat__message-simple str-chat__message-simple--me'
    : 'str-chat__message str-chat__message-simple';

  if (message.customType === 'message.date') {
    return null;
  }

  if (message.deleted_at || message.type === 'deleted') {
    return <MessageDeleted message={message} />;
  }

  return (
    <Wrapper isMyMessage={isMyMessage()}>
      {editing && (
        <Modal onClose={clearEditingState} open={editing}>
          <MessageInput
            clearEditingState = {clearEditingState}
            Input             = {EditMessageInput}
            message           = {message}
            {...additionalMessageInputProps}
          />
        </Modal>
      )}
      {
        <div
          className={`
						${messageClasses}
						str-chat__message--${message.type}
						str-chat__message--${message.status}
						${message.text ? 'str-chat__message--has-text' : 'has-no-text'}
						${hasAttachment ? 'str-chat__message--has-attachment' : ''}
            ${hasReactions && isReactionEnabled ? 'str-chat__message--with-reactions' : ''}
            ${message.pinned ? 'pinned-message' : ''}
            ${groupedByUser ? 'str-chat__virtual-message__wrapper--group' : ''}
            ${firstOfGroup ? 'str-chat__virtual-message__wrapper--first' : ''}
            ${endOfGroup ? 'str-chat__virtual-message__wrapper--end' : ''}
					`.trim()}
          key={message.id}
          ref={messageWrapperRef}
        >
          <MessageStatus />
          {message.user && (
            <Avatar
              image       = {message.user.image}
              name        = {message.user.name || message.user.id}
              onClick     = {onUserClick}
              onMouseOver = {onUserHover}
              user        = {message.user}
            />
          )}
          <div
            className   = 'str-chat__message-inner'
            data-testid = 'message-inner'
            onClick     = {
              message.status === 'failed' && message.errorStatusCode !== 403
                ? () => handleRetry(message)
                : undefined
            }
          >
            <>
              <MessageOptions messageWrapperRef={messageWrapperRef} />
              {hasReactions && !showDetailedReactions && isReactionEnabled && (
                <ReactionsList reverse />
              )}
              {showDetailedReactions && isReactionEnabled && (
                <ReactionSelector ref={reactionSelectorRef} />
              )}
            </>
            {message.attachments?.length && !message.quoted_message ? (
              <Attachment actionHandler={handleAction} attachments={message.attachments} />
            ) : null}
            <MessageText message={message} />
            {message.mml && (
              <MML
                actionHandler = {handleAction}
                align         = {isMyMessage() ? 'right' : 'left'}
                source        = {message.mml}
              />
            )}
            {!threadList && !!message.reply_count && (
              <div className='str-chat__message-simple-reply-button'>
                <MessageRepliesCountButton
                  onClick     = {handleOpenThread}
                  reply_count = {message.reply_count}
                />
              </div>
            )}
            {(!groupedByUser || endOfGroup) && (
              <div className={`str-chat__message-data str-chat__message-simple-data`}>
                {!isMyMessage() && message.user ? (
                  <span className='str-chat__message-simple-name'>
                    {message.user.name || message.user.id}
                  </span>
                ) : null}
                <MessageTimestamp calendar customClass='str-chat__message-simple-timestamp' />
              </div>
            )}
          </div>
        </div>
      }
    </Wrapper>
  );
};

const MemoizedMessage = React.memo(
  MessageWithContext,
  areMessageUIPropsEqual,
) as typeof MessageWithContext;

/**
 * The default UI component that renders a message and receives functionality and logic from the MessageContext.
 */
export const Message = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
  props: MessageUIComponentProps<At, Ch, Co, Ev, Me, Re, Us>,
) => {
  const messageContext = useMessageContext<At, Ch, Co, Ev, Me, Re, Us>('MessageSimple');

  return <MemoizedMessage {...messageContext} {...props} />;
};