import styled              from 'styled-components';

import { IRideInvitation } from '../../../../models';

import { GroupRides }      from '../Rides/GroupRides';
import { Invitations }     from '../Rides/Invitations';
import { MyRides }         from '../Rides/MyRides';
import { TabType }         from '../TabsetDay';

interface ITabsetContentProps {
  date            : string;
  selectedTab     : TabType;
  invitationCards?: IRideInvitation[];
  rideCards?      : IRideInvitation[];
  groupRideCards? : IRideInvitation[];
}

export const TabsetContent = ({
  invitationCards,
  rideCards,
  groupRideCards,
  selectedTab,
  date
}: ITabsetContentProps) => {
  const components       = [MyRides, Invitations, GroupRides];
  const DynamicComponent = components[selectedTab];

  return (
    <TabsetContent.Wrapper>
      <DynamicComponent 
        invitationCards = {invitationCards}
        rideCards       = {rideCards}
        groupRideCards  = {groupRideCards}
        date            = {date}
      />
    </TabsetContent.Wrapper>
  );
};

TabsetContent.Wrapper = styled.div`
  text-align     : center;
  display        : flex;
  flex-direction : column;
  justify-content: center;
`;
