import {
  useEffect,
  useMemo,
  useState
}                                              from 'react';
import styled                                  from 'styled-components';
import { useParams }                           from 'react-router';
import { format }                              from 'date-fns';
import { Grid }                                from '@mui/material';
import { ReactComponent as PrecipitationIcon } from '@images/Icon-Weather-Precipitation-Black.svg';
import { ReactComponent as WindIcon }          from '@/images/Icon-Weather-Windy-Black.svg';
import { ReactComponent as HumidityIcon }      from '@/images/Icon-Weather-Humidity-Black.svg';
import {
  AIR_QUALITY_STATUS,
  EWeatherCode,
  ITimeline,
  IWeatherDetails
}                                              from '@models/rideWeather';
import { IRouteModel }                         from '@models/RouteModel';
import {
  TemperatureUnitType,
  WindSpeedUnitType
}                                              from '@models/CalendarCardModel';
import { getAirQualityInt }                    from '@modules/Details/utils';
import { getWeatherConditionClassName }        from '@utils/string_utils';
import {
  degreesToCompass,
  toFarenheit,
  toMPH
}                                              from '@utils/conversion_utils';
import {
  getRideStartEndTime,
  getTimeFormat,
}                                              from '@utils/time_utils';
import { WeatherIcon }                         from '@components/WeatherIcon';
import { WindDirection }                       from '@components/WindDirection';
import { UVIcon }                              from '@components/UVIcon';
import { AirQualityIcon }                      from '@components/AirQualityIcon';
import { WeatherTimelineSlider }               from '@components/WeatherTimelineSlider';
import { useDetails }                          from '../../context';
import {
  useGetRideEventWeatherQuery,
  useGetRouteWeatherQuery
}                                              from '../../queries';
import { useGetUserSettingsQuery }             from '../../../Profile/queries';
import { MEASUREMENT_SYSTEMS }                 from '../../../Profile/utils/options';

const currentTime = new Date().toISOString();

interface IWeatherDetailsProps {
  routeDetails? : IRouteModel;
  horizontal?   : boolean;
}

export const WeatherDetails = ({ routeDetails, horizontal }: IWeatherDetailsProps) => {
  const {
    rideDate,
    rideDetails,
    routeDetails: routeDetailsContext,
  } = useDetails();

  const route = useMemo(
    () => routeDetails ?? routeDetailsContext,
    [routeDetails, routeDetailsContext]
  );

  const {
    tab,
    rideId,
    routeId,
    linkUuid,
  }                            = useParams<{ rideId?: string, routeId?: string, tab: string; linkUuid?: string; }>();
  const { data: settings }     = useGetUserSettingsQuery();
  const { data: routeWeather } = useGetRouteWeatherQuery({ routeId: routeId ?? '', date: currentTime }, { skip: !routeId });
  const { data: rideWeather  } = useGetRideEventWeatherQuery({
      id      : linkUuid ? linkUuid : rideId ?? '',
      rideDate: tab ?  rideDate : undefined,
      isShared: Boolean(linkUuid)
    }, { skip: Boolean(routeId) }
  );

  const [currentWeather, setCurrentWeather] = useState<IWeatherDetails | null>(null);
  const [startTime, setStartTime]           = useState<string>('');
  const [endTime, setEndTime]               = useState<string>('');

  const weather = useMemo<ITimeline[]>(
    () => rideWeather ?? routeWeather ?? [],
    [rideWeather, routeWeather]
  );

  const displayStartTime = routeId ? 'Now' : startTime;

  const hasRoute = Boolean(routeId || rideDetails?.routeId);

  const showWeatherSlider = Boolean(
    routeId ||
    rideDetails?.routeId ||
    (rideDetails?.startLocation && (rideDetails?.duration ?? 0))
  ) && !!weather.length;

  useEffect(
    () => {
      if (weather?.length) {
        setCurrentWeather(weather[0].intervals[0].values);
      }
    },
    [weather]
  );

  useEffect(
    () => {
      if (route?.distance) {
        const startEnd = getRideStartEndTime(route?.distance, rideDetails?.date ?? currentTime, settings?.timeType);

        setStartTime(startEnd.startTimeFormatted);
        setEndTime(startEnd.endTimeFormatted);
      }

      if (!route && rideDetails?.startLocation && (rideDetails?.duration && rideDetails?.duration > 0)) {
        const rideStartTime          = new Date(rideDetails.date);
        const rideEndTime            = new Date(rideDetails.date).setSeconds(rideDetails.duration);
        const rideStartTimeFormatted = format(rideStartTime, getTimeFormat(settings?.timeType));
        const rideEndTimeFormatted   = format(rideEndTime, getTimeFormat(settings?.timeType));

        setStartTime(rideStartTimeFormatted);
        setEndTime(rideEndTimeFormatted);
      }
    },
    [rideDetails?.date, rideDetails?.duration, rideDetails?.startLocation, route, route?.distance, settings, weather]
  );

  const minMaxWind = useMemo(
    (): string | number => {
      if (currentWeather && currentWeather.windSpeedMin && currentWeather.windSpeedMax) {
        const windSpeedMin =
          settings?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL ? Math.round(toMPH(currentWeather.windSpeedMin)) : Math.round(currentWeather.windSpeedMin);

        const windSpeedMax =
          settings?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL ? Math.round(toMPH(currentWeather.windSpeedMax)) : Math.round(currentWeather.windSpeedMax);

        return windSpeedMin === windSpeedMax ? windSpeedMax : `${currentWeather.windSpeedMin.toFixed()}-${currentWeather.windSpeedMax.toFixed()}`;
      }

      return '—';
    },
    [currentWeather, settings?.measurementSystem]
  );

  return (
    horizontal ?
    (
      <>
        {(currentWeather && (
          <WeatherDetails.HorizontalWrapper>
            <WeatherDetails.HorizontalSectionTitle>Weather Forecast</WeatherDetails.HorizontalSectionTitle>
            <WeatherDetails.HorizontalRowContainer>
              <WeatherDetails.HorizontalContainer>
                <WeatherIcon
                  condition={getWeatherConditionClassName(currentWeather.weatherCode)}
                />

                <WeatherDetails.HorizontalTemperature>
                  <div>
                    <span>
                      {`${Math.round(settings?.temperatureUnits === TemperatureUnitType.f
                        ? toFarenheit(currentWeather?.temperatureMax ?? 0)
                        : currentWeather?.temperatureMax ??  0)}˚`
                      }
                    </span>
                    <span>{settings?.temperatureUnits === TemperatureUnitType.c ? 'C' : 'F'}</span>
                  </div>
                  <p>{EWeatherCode[currentWeather.weatherCode]}</p>
                </WeatherDetails.HorizontalTemperature>
              </WeatherDetails.HorizontalContainer>
              <WeatherDetails.HorizontalColumn>
                <WeatherDetails.HorizontalRow>
                  <PrecipitationIcon />
                  <strong>{currentWeather.precipitationProbability ? `${(currentWeather.precipitationProbability).toFixed()}%` : '0'}</strong>
                  <span>Precipitation</span>
                </WeatherDetails.HorizontalRow>

                <WeatherDetails.HorizontalRow>
                  <WindIcon />
                  <strong>{minMaxWind}</strong>
                  <span>{settings?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL ? WindSpeedUnitType.mph : WindSpeedUnitType.kph}</span>
                  <WindDirection
                    direction={degreesToCompass(currentWeather.windDirection || 0)}
                  />
                </WeatherDetails.HorizontalRow>

                <WeatherDetails.HorizontalRow>
                <HumidityIcon />
                <strong>{currentWeather.humidity ? `${(currentWeather.humidity).toFixed()}%` : '0'}</strong>
                <span>Humidity</span>
                </WeatherDetails.HorizontalRow>
                {
                  currentWeather?.epaIndexAvg &&
                  <WeatherDetails.HorizontalRow>
                    <AirQualityIcon quality={getAirQualityInt(currentWeather.epaIndexAvg)} width="18px" />
                    <strong>{AIR_QUALITY_STATUS[getAirQualityInt(currentWeather.epaIndexAvg)]}</strong>
                    <span>Air Quality ({currentWeather.epaIndexAvg} AQI)</span>
                  </WeatherDetails.HorizontalRow>
                }
              </WeatherDetails.HorizontalColumn>
            </WeatherDetails.HorizontalRowContainer>
          </WeatherDetails.HorizontalWrapper>
        )) ?? 'No Weather Data'}
      </>
    )
    : 
    <>
      {currentWeather && (
        <WeatherDetails.Wrapper>
          <Grid container>
            <Grid item xs>
              <>
                <WeatherDetails.Container>
                  <WeatherIcon
                    condition={getWeatherConditionClassName(currentWeather.weatherCode)}
                  />

                  <WeatherDetails.Temperature>
                    <div>
                      <span>{`${Math.round(settings?.temperatureUnits === TemperatureUnitType.f ? toFarenheit(currentWeather?.temperatureMax ?? 0): currentWeather?.temperatureMax ??  0)}˚`}</span>
                      <span>{settings?.temperatureUnits === TemperatureUnitType.c ? 'C' : 'F'}</span>
                    </div>
                    <p>{EWeatherCode[currentWeather.weatherCode]}</p>
                  </WeatherDetails.Temperature>
                </WeatherDetails.Container>

                <WeatherDetails.Container>
                  <PrecipitationIcon />
                  <strong>{currentWeather.precipitationProbability ? `${(currentWeather.precipitationProbability).toFixed()}%` : '0'}</strong>
                  <span>Precipitation</span>
                </WeatherDetails.Container>

                <WeatherDetails.Container>
                  <WindIcon />
                  <strong>{minMaxWind}</strong>
                  <span>{settings?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL ? WindSpeedUnitType.mph : WindSpeedUnitType.kph}</span>
                  <WindDirection
                    direction={degreesToCompass(currentWeather.windDirection || 0)}
                  />
                </WeatherDetails.Container>

                <WeatherDetails.Container>
                  <HumidityIcon />
                  <strong>{currentWeather.humidity ? `${(currentWeather.humidity).toFixed()}%` : '0'}</strong>
                  <span>Humidity</span>
                </WeatherDetails.Container>

                <WeatherDetails.Container>
                  <UVIcon uv={currentWeather.uvIndex}/>
                  <strong>Moderate</strong>
                  <span>UV</span>
                </WeatherDetails.Container>

                <WeatherDetails.Container>
                  {
                    currentWeather?.epaIndexAvg &&
                    <>
                      <AirQualityIcon quality={getAirQualityInt(currentWeather.epaIndexAvg)} />
                      <strong>{AIR_QUALITY_STATUS[getAirQualityInt(currentWeather.epaIndexAvg)]}</strong>
                      <span>Air Quality ({currentWeather.epaIndexAvg} AQI)</span>
                    </>
                  }
                </WeatherDetails.Container>
              </>
            </Grid>
          </Grid>
        </WeatherDetails.Wrapper>
      )}

      <WeatherDetails.Footer>
        {showWeatherSlider && <WeatherTimelineSlider
          cardSize          = {false}
          timelines         = {weather || []}
          setCurrentWeather = {setCurrentWeather}
          startTime         = {displayStartTime}
          endTime           = {endTime}
          hasRoute          = {hasRoute}
        />}
      </WeatherDetails.Footer>
    </>
  )
};

WeatherDetails.Wrapper = styled.div`
  display          : flex;
  background-color : #FFFFFF;
  padding          : 20px 70px 0 70px;
`;

WeatherDetails.Container = styled.div`
  display       : flex;
  align-items   : center;
  margin-bottom : 10px;
  color         : gray;

  span {
    font-size    : 12px;
    line-height  : 12px;
    font-weight  : 400;
    margin-right : 5px;
    margin-top   : 3px;
  }

  strong {
    color        : #000000;
    margin-right : 5px;
    font-size    : 16px;
    line-height  : 16px;
    font-weight  : 700;
  }

  svg {
    margin-right: 5px;
  }
`;

WeatherDetails.Footer = styled.div`
  margin-left  : auto;
  margin-right : auto;
  width        : 80%;
`;

WeatherDetails.Temperature = styled.div`
  margin-left : -15px;
  margin-top  : 10px;

  div {
    display: flex;
    color  : #000;

    span:first-child {
      font-size   : 32px;
      line-height : 100%;
      font-weight : 700;
    }

    span:last-child {
      font-size   : 16px;
      line-height : 16px;
      font-weight : 700;
      margin-left : -3px;
    }
  }

  p {
    color       : #000;
    font-size   : 16px;
    line-height : 16px;
    font-weight : 700;
    margin-top  : 5px;
  }
`;

WeatherDetails.Hero = styled.div`
  height           : 100%;
  width            : 100%;
  background-size  : 160px;
  background-repeat: no-repeat;
  background-image : ${() =>
  `url(${require(`@images/Weather-Hero-Default.png`)})`};
`;

// Horizontal styles

WeatherDetails.HorizontalWrapper = styled.div`
  background-color : #FFFFFF;
  padding          : 10px 0px 0px 10px;
  border-bottom    : 1px solid ${({ theme }) => theme.colors.neutralGrey[500]};
`;

WeatherDetails.HorizontalContainer = styled.div`
  display       : flex;
  align-items   : center;
  margin-bottom : 10px;
  color         : gray;
  margin-right  : 5px;
  max-width     : 45%;
  
  span {
    font-size    : 10px;
    line-height  : 12px;
    font-weight  : 400;
    margin-right : 3px;
    margin-top   : 3px;
  }

  strong {
    color        : #000000;
    margin-right : 3px;
    font-size    : 14px;
    line-height  : 16px;
    font-weight  : 700;
  }

  svg {
    margin-right: 5px;
  }
`;

WeatherDetails.HorizontalColumn = styled.div`
  display        : flex;
  flex-direction : column;
  margin-right   : 10px;
  
  span {
    font-size    : 10px;
    line-height  : 12px;
    font-weight  : 400;
    margin-right : 3px;
    margin-top   : 3px;
  }

  strong {
    color        : #000000;
    margin-right : 3px;
    font-size    : 12px;
    line-height  : 16px;
    font-weight  : 700;
  }

  svg {
    margin-right: 3px;
    width       : 18px;
  }
`;

WeatherDetails.HorizontalRow = styled.div`
  display        : flex;
  flex-direction : row;
  margin-bottom  : 5px;

  first-child {
   width: 55%
  }

`;

WeatherDetails.HorizontalFooter = styled.div`
  margin-left  : auto;
  margin-right : auto;
  width        : 80%;
`;

WeatherDetails.HorizontalTemperature = styled.div`
  margin-left : -15px;
  margin-top  : 10px;

  div {
    display: flex;
    color  : #000;

    span:first-child {
      font-size   : 23px;
      line-height : 100%;
      font-weight : 700;
    }

    span:last-child {
      font-size   : 12px;
      line-height : 16px;
      font-weight : 700;
      margin-left : -3px;
    }
  }

  p {
    color       : #000;
    font-size   : 12px;
    line-height : 16px;
    font-weight : 700;
    margin-top  : 5px;
    white-space : nowrap;
  }
`;

WeatherDetails.HorizontalHero = styled.div`
  height           : 100%;
  width            : 100%;
  background-size  : 160px;
  background-repeat: no-repeat;
  background-image : ${() =>
    `url(${require(`@images/Weather-Hero-Default.png`)})`};
`;
  
WeatherDetails.HorizontalSectionTitle = styled.span`
  color     : ${({ theme }) => theme.colors.neutralGrey[600]};
  font-size : 13px;
`;

WeatherDetails.HorizontalRowContainer = styled.div`
  display         : flex;
  flex-direction  : row;
  justify-content : space-around;
  align-items     : center;
  padding-bottom  : 15px;
`;
