import { TextField } from '@mui/material';
import styled        from 'styled-components';

interface IZoneFieldProps {
  label      : string;
  lineColor  : string;
  value      : string;
  fullWidth? : boolean;
}

export const ZoneField = ({ label, lineColor, value, fullWidth }: IZoneFieldProps) => (
  <ZoneField.Wrapper>
    <ZoneField.Line lineColor={lineColor} />
    <TextField
      label     = {label}
      variant   = "filled"
      value     = {value}
      fullWidth = {fullWidth}
      disabled
    />
  </ZoneField.Wrapper>
);

ZoneField.Wrapper = styled.div`
  position: relative;

  && input {
    background-color        : #DFDFDE;
    -webkit-text-fill-color : #000;
  }
`;

ZoneField.Line = styled.div`
  position         : absolute;
  width            : 4px;
  height           : 38px;
  left             : 8px;
  bottom           : 8px;
  background-color : ${({ lineColor }: { lineColor: string }) => lineColor};
  border-radius    : 50px;
  z-index          : 1;
`;
