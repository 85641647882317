export enum ERequestStatus {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing'
}

export interface IRecommendation {
  userId                      : string;
  displayName                 : string;
  profilePictureUrl           : string;
  mutualFriendsCount          : number;
  username                    : string;
  status                      : ERequestStatus;
  topFriendsProfilePictureUrls: string[];
}
