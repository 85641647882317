export enum ENotificationAction {
  rideInvitationReceived          = 'RIDE_INVITATION_RECEIVED',
  connectionRequest               = 'CONNECTION_REQUEST',
  processingJobComplete           = 'PROCESSING_JOB_COMPLETE',
  rideInviteResponseReceived      = 'RIDE_INVITE_RESPONSE_RECEIVED',
  rideEventCancelled              = 'RIDE_EVENT_CANCELLED',
  operationFailed                 = 'OPERATION_FAILED',
  plannedRideEdited               = 'PLANNED_RIDE_EDITED',
  rideEventReminder               = 'RIDE_EVENT_REMINDER',
  connectionAccepted              = 'CONNECTION_ACCEPTED',
  invitedUserJoinedViaLink        = 'NEW_USER_JOINED_VIA_LINK',
  garminFetchHistoricalDataFailed = 'GARMIN_FETCH_HISTORICAL_DATA_FAIL'
}

export interface INotificationAction {
  inviteId?         : string;
  connectionId?     : string;
  rideDate?         : string;
  plannedRideId?    : string;
  rideName?         : string;
  userName?         : string;
  organizerName?    : string;
  displayName?      : string;
  inviteeName?      : string;
  rideFileCount?    : number;
  ridesCreatedCount?: number;
  message?          : string;
}

export interface INotification {
  id             : string;
  userId         : string;
  originatorId   : string;
  actionType     : ENotificationAction,
  action         : INotificationAction,
  dateRead       : Date | null;
  dateCreated    : Date;
  dateUpdated    : Date;
  originatorUser : {
    id                : string;
    username          : string;
    firstName         : string;
    lastName          : string;
    profilePictureUrl : string | null;
  },
}

export interface INotificationResponse extends Omit<INotification, 'action'> {
  action: string;
}
