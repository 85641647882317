import { useHistory }     from 'react-router';
import {
  format,
}                         from 'date-fns-tz';
import { EFrequencyType } from '@modules/Details/models/ride';
import {
  CardTypes,
  IRideInvitation
}                         from '../../../../../models';

import { DayCard }        from '../DayCard';

export const MyRides = ({ rideCards, date }: IMyRidesProps) => {
  const { push } = useHistory();

  const getIsRideInPast = (rideDate:string): boolean =>{
    const now             = new Date();
    const nowUTCFormatted = format(now, `yyyy-MM-dd'T'HH:mm:ss'Z`);

    return nowUTCFormatted > rideDate;
  }

  return (
    <>
      {rideCards?.map((rideCard) => (
        <DayCard
          key            = {`${rideCard.plannedRideId}${rideCard.ride.startDate}`}
          cardType       = {CardTypes.RidePlan}
          invitation     = {rideCard}
          date           = {date}
          onDetailsClick = {() => {
            push(
              `ride/${rideCard.plannedRideId}/${date}/${getIsRideInPast(rideCard.ride.startDate) && rideCard?.ride.frequency === EFrequencyType.WEEKLY ? 'ride-history' : ''}`,
              { prevState: '' }
            )
          }}
        />
      ))}
    </>
  );
};

interface IMyRidesProps {
  date       : string;
  rideCards? : IRideInvitation[];
}
