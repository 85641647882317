import { useContext }        from 'react';
import { Grid, TextField }   from '@mui/material';
import styled                from 'styled-components';
import { SettingsContext }   from '../../../../../containers/SettingsContainer';
import { FieldWrapper }      from '../../../../FieldWrapper';
import { TitleBlock }        from '../../../../TitleBlock';
import { privacyTabs }       from '../../../../../utils/tabs';
import { SETTINGS_SECTIONS } from '../../../../../utils/settingsSections';

export const ProfileUrl = () => {
  const { getValues } = useContext(SettingsContext);

  return (
    <FieldWrapper>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={4}>
          <TitleBlock 
            tabs         = {[privacyTabs[0]]}
            defaultValue = {privacyTabs[0].id}
            label        = {SETTINGS_SECTIONS.profileUrl.title}
            description  = {SETTINGS_SECTIONS.profileUrl.description}
            tooltip      = {SETTINGS_SECTIONS.profileUrl.tooltip}
          />
        </Grid>
        
        <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }} />

        <Grid item xs={12} md={6}>
          <Grid container spacing={2} justifyContent="end">
            <Grid item xs={12}>
              <ProfileUrl.TextField 
                disabled
                fullWidth 
                label   = "Profile URL"
                variant = "filled"
                value   = {`www.gopreem.com/user/${getValues().user.username}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FieldWrapper>
  )
}

ProfileUrl.TextField = styled(TextField)`
  .MuiFilledInput-input {
    -webkit-text-fill-color: black;
  }
`;