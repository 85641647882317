import {
  useState,
  KeyboardEvent,
  MutableRefObject
}                                      from 'react';
import styled, { css, keyframes }      from 'styled-components';
import { ReactComponent as CheckIcon } from '../../images/Icon-RSVP-Going.svg';
import { ReactComponent as CloseIcon } from '../../images/Icon-RSVP-Not-Going.svg';
import { scrimBlack, scrimWhite }      from '../../styles/theme';

/**
 * @description TextInput renders a text input
 * @component
 * @property  {boolean}  [alphanumeric]        When true, checks input against regex to allow only alpha, numeric, and _ characters
 * @property  {boolean}  [dark]                Enables dark styling when true
 * @property  {string}   [inputType]           Type of input (text/password/number/etc)
 * @property  {string}   name                  Name of input
 * @property  {string}   [placeholder]         Placeholder for the text input
 * @property  {boolean}  [setRef]              Ref passed in through parent
 * @property  {boolean}  [showValidationIcon]  When true, shows an icon in the TextFieldFormik to indicate field validation status
 * @example
 * <TextInput
 *   alphanumeric
 *   dark
 *   inputType='text'
 *   name='preem-handle'
 *   placeholder='Preem Handle'
 *   showValidationIcon
 *  />
 */

export type TTextInputProps = JSX.IntrinsicElements['input'] & {
  alphanumeric?      : boolean;
  dark?              : boolean;
  inputType?         : string;
  name               : string;
  error?             : string;
  touched?           : boolean;
  placeholder?       : string;
  setRef?            : MutableRefObject<any>;
  showValidationIcon?: boolean;
};

export const TextInput = ({
  alphanumeric,
  dark,
  inputType,
  name,
  placeholder,
  setRef,
  showValidationIcon,
  value,
  error,
  touched,
  ...props
}: TTextInputProps): JSX.Element => {
  const [focused, setFocused] = useState(false);

  /**
   * @description checkAlphanumeric tests against a regex pattern to limit input to alphanumeric characters and underscores
   * @function
   * @param {KeyboardEvent} e  The keyboard event that contains details about the key pressed
   */
  function checkAlphanumeric(e: KeyboardEvent<HTMLDivElement>) {
    const key = e.key;
    if (/[^a-zA-Z0-9_]/.test(key)) {
      e.preventDefault();
    }
  }

  return (
    <TextInput.Wrapper>
      <TextInput.InputContainer>
        <TextInput.Input
          {...props}
          name       = {name}
          type       = {inputType || 'text'}
          onFocus    = {() => setFocused(true)}
          onBlur     = {() => setFocused(false)}
          onKeyPress = {(e) => alphanumeric && checkAlphanumeric(e)}
          placeholder = {placeholder}
          ref         = {setRef || null}
          value       = {value}
          dark        = {!!dark}
          focused     = {focused}
          hasValue    = {!!value && `${value}`.length > 0}
          hasError    = {!!((`${value}`.length > 2 || touched) && error)}
        />
        {showValidationIcon &&
          (error ? (
            <TextInput.CloseIcon />
          ) : (
            <TextInput.CheckIcon />
          ))}
      </TextInput.InputContainer>

      <TextInput.Label
        htmlFor   = {name}
        dark     = {!!dark}
        focused  = {focused}
        hasValue = {!!value && `${value}`.length > 0}
      >
        {placeholder}
      </TextInput.Label>
      <TextInput.Error hasError = {!!((`${value}`.length > 2 || touched) && error)}>
        {error}
      </TextInput.Error>
    </TextInput.Wrapper>
  );
};

const showErrorAnimation = keyframes`
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
`;

const hideErrorAnimation = keyframes`
  0% {
    display: block;
    opacity: 1;
    transform: scaleY(1);
  }
  99% {
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top;
  }
  100% {
    display: none;
    opacity: 0;
  }
`;

TextInput.Wrapper = styled.div`
  display       : flex;
  flex-direction: column;
  align-items   : flex-start;
  width         : 320px;

  @media screen and (max-width: 479px) {
    & {
      align-self: center;
      display: block;
      width: 280px;
    }
  }
`;

TextInput.InputContainer = styled.div`
  display    : flex;
  align-items: center;
`;

TextInput.CloseIcon = styled(CloseIcon)`
  margin-left: -2em;
`;

TextInput.CheckIcon = styled(CheckIcon)`
  margin-left: -2em;
`;

TextInput.Error = styled.div<{ hasError: boolean; }>`
  margin-bottom: 22px;
  font-size    : 12px;
  color        : ${({ theme: { colors } }) => colors.messaging.red};
  font-weight  : 600;
  text-align   : left;
  padding      : 4px;
  
  ${({ hasError }) => hasError ? css`
    display            : block;
    animation-name     : ${showErrorAnimation};
    animation-duration : 200ms;
    animation-fill-mode: forwards;
    animation-direction: alternate;
  ` : css`
    display            : none;
    animation-name     : ${hideErrorAnimation};
    animation-duration : 200ms;
    animation-fill-mode: forwards;
  `}
`;

TextInput.Input = styled.input<{ dark: boolean; focused: boolean; hasError: boolean; hasValue: boolean; }>`
  outline         : none;
  border          : none;
  position        : relative;
  display         : flex;
  height          : 38px;
  padding-top     : 5px;
  padding-bottom  : 5px;
  padding-left    : 16px;
  border-radius   : 4px;
  color           : ${({ theme: { colors } }) => colors.type.dark};
  background-color: ${scrimBlack(0.04)};
  width           : 300px;
  box-shadow      : none;
  font-size       : 14px;
  line-height     : 14px;
  font-weight     : 700;
  transition      : all 0.3s ease-in-out;
  
  &::placeholder {
    color: ${({ theme: { colors } }) => colors.type.medium};
  }

  ${({ dark }) => dark && css`
    color           : ${scrimWhite(0.64)};
    background-color: ${scrimBlack(0.16)};
    
    &::placeholder {
      color: ${scrimWhite(0.64)};
    }
  `}

  ${({ focused }) => focused && css`
    outline         : none;
    border          : none;
    padding-top     : 10px;
    padding-bottom  : 0;
    color           : ${({ theme: { colors } }) => colors.type.dark};
    background-color: ${scrimBlack(0.08)};
    box-shadow      : 0 0 0 2px ${({ theme: { colors } }) => colors.type.dark};
    
    &::placeholder {
      opacity: 0;
    }
  `}

  ${({ dark, focused }) => dark && focused && css`
    color           : ${({ theme: { colors } }) => colors.primary.white};
    box-shadow      : 0 0 0 2px ${({ theme: { colors } }) => colors.primary.white};
    background-color: ${scrimWhite(0.04)};
  `}

  ${({ hasError }) => hasError && css`
    box-shadow: 0 0 0 2px ${({ theme: { colors } }) => colors.messaging.red};
  `}

  ${({ hasValue }) => hasValue && css`
    padding-top   : 10px;
    padding-bottom: 0;
  `}
`;

TextInput.Label = styled.label<{ dark: boolean; focused: boolean; hasValue: boolean; }>`
  color         : ${({ theme: { colors } }) => colors.type.medium};
  opacity       : 0;
  display       : block;
  position      : relative;
  pointer-events: none;
  top           : -36px;
  left          : 16px;
  text-align    : left;
  font-size     : 14px;
  line-height   : 14px;
  font-weight   : 700;
  transition    : all 0.1s ease-in;

  ${({ dark }) => dark && `color: ${scrimWhite(0.64)}`};
  
  ${({ focused }) => focused && css`
    opacity  : 1;
    font-size: 9px;
  `}

  ${({ hasValue }) => hasValue && css`
    opacity  : 0.64;
    font-size: 9px;
  `}
`;
