import * as yup                from 'yup';
import {
  convertCMtoMeters,
  convertPoundsToKgs,
  convertToMeters
}                              from '@utils/commonFunctions';
import { roundToPrecision }    from '@utils/metrics_utils';
import { TemperatureUnitType } from '@models/CalendarCardModel';
import { TIME_TYPES }          from '@models/TimeTypes';
import { MEASUREMENT_SYSTEMS } from '@modules/Profile/utils/options';
import { IUserInfo }           from '@models/UserInfo';

export type TMeasurementSystem = 'METRIC' | 'IMPERIAL';
export type TTimeType          = 'HOURS_12' | 'HOURS_24';

export interface IHeartRateZones {
  zone1Min: number;
  zone1Max: number;
  zone2Min: number;
  zone2Max: number;
  zone3Min: number;
  zone3Max: number;
  zone4Min: number;
  zone4Max: number;
  zone5Min: number;
  zone5Max: number;
}

export interface IPowerZones {
  zone1Min : number;
  zone1Max : number;
  zone2Min : number;
  zone2Max : number;
  zone3Min : number;
  zone3Max : number;
  zone4Min : number;
  zone4Max : number;
  zone5Min : number;
  zone5Max : number;
  zone6Min : number;
  zone6Max : number;
  zone7Min?: number;
  zone7Max?: number;
  zone8Min?: number;
  zone8Max?: number;
}

export interface ISettingsForm {
  measurementSystem           : TMeasurementSystem;
  temperatureUnits            : TemperatureUnitType;
  timeType                    : TTimeType;
  restingHeartRate            : number;
  maxHeartRate                : number;
  functionalThresholdPower    : number;
  powerZonesCount             : number;
  nationality?                : string;
  isNationalityPrivate        : boolean;
  pronounSubject?             : string;
  pronounObject?              : string;
  pronounPossessive?          : string;
  isPronounsPrivate           : boolean;
  birthdate?                  : string;
  isBirthdatePrivate          : boolean;
  isHeightPrivate             : boolean;
  password?                   : string;
  confirmPassword?            : string;
  weight?                     : number | null;
  weightPounds?               : number;
  isWeightPrivate             : boolean;
  isHomeAddressAsStartLocation: boolean;
  height?                     : number | null;
  bio?                        : string;
  dayOfBirth?                 : string;
  yearOfBirth?                : string;
  monthOfBirth?               : string;
  heightCentimeters?          : number;
  heightFeet?                 : string;
  heightInches?               : string;
  profilePictureUrl           : IUserInfo['profilePictureUrl'];
  phoneNumber                 : string;
  code                        : string;
  isEmailNotification         : boolean;
  isPhoneNotification         : boolean;
  privacyDistanceMiles?       : number | null;
  maskingDistance             : number | null;
  showRoutesMasked?           : boolean;
  heartRateZones?             : IHeartRateZones;
  powerZones?                 : IPowerZones;
  user                        : Pick<IUserInfo, 'username' | 'firstName' | 'lastName'> & Required<Pick<IUserInfo, 'email'>>,
}

export const FSettingsForm = (data?: Partial<ISettingsForm>, isSubmitting?: boolean): ISettingsForm => {
  const height = data?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL ?
    convertToMeters(data?.heightFeet, data?.heightInches) :
    convertCMtoMeters(data?.heightCentimeters);

  const weight = data?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL ?
    roundToPrecision(convertPoundsToKgs(data?.weightPounds), 4) :
    data?.weight;

  const birthdate = data?.yearOfBirth && data.monthOfBirth && data.dayOfBirth && `${data.yearOfBirth}-${data.monthOfBirth}-${data.dayOfBirth}`;

  return {
    birthdate                   : isSubmitting ? birthdate : data?.birthdate,
    measurementSystem           : data?.measurementSystem ?? MEASUREMENT_SYSTEMS.IMPERIAL,
    temperatureUnits            : data?.temperatureUnits ?? TemperatureUnitType.f,
    timeType                    : data?.timeType ?? TIME_TYPES.HOURS_12,
    restingHeartRate            : data?.restingHeartRate ?? 28,
    maxHeartRate                : data?.maxHeartRate ?? 0,
    functionalThresholdPower    : data?.functionalThresholdPower ?? 0,
    powerZonesCount             : data?.powerZonesCount ?? 6,
    nationality                 : data?.nationality,
    isNationalityPrivate        : data?.isNationalityPrivate ?? false,
    pronounSubject              : data?.pronounSubject,
    pronounObject               : data?.pronounObject,
    pronounPossessive           : data?.pronounPossessive,
    phoneNumber                 : data?.phoneNumber ?? '',
    code                        : data?.code ?? '',
    isEmailNotification         : data?.isEmailNotification ?? false,
    isPhoneNotification         : data?.isPhoneNotification ?? false,
    isPronounsPrivate           : data?.isPronounsPrivate ?? false,
    isBirthdatePrivate          : data?.isBirthdatePrivate ?? false,
    isHeightPrivate             : data?.isHeightPrivate ?? false,
    isWeightPrivate             : data?.isWeightPrivate ?? false,
    isHomeAddressAsStartLocation: data?.isHomeAddressAsStartLocation ?? false,
    maskingDistance             : data?.maskingDistance ?? null,
    showRoutesMasked            : data?.showRoutesMasked ?? false,
    user                        : data?.user ?? { email: '', username  : '', firstName : '', lastName  : ''},
    profilePictureUrl           : data?.profilePictureUrl ?? '',
    weight                      : isSubmitting && weight ? weight : data?.weight ?? null,
    height                      : isSubmitting && height ? height : data?.height ?? null,
  };
};

export interface ILocation {
  id            : string;
  genLocationId : string;
  userId        : string;
  placeName     : string;
  longitude     : number;
  latitude      : number;
  isPrimary     : boolean;
  isMasked      : boolean;
  address?      : string;
}

export interface ILocationSubmit {
  lat              : number,
  lng              : number,
  cityName         : string,
  stateName        : string,
  placeName?       : string;
  isForPlannedRide?: boolean;
  isPrimary?       : boolean,
  address?         : string
  isMasked?        : boolean;
}

export const SETTINGS_SCHEMA = yup.object().shape({
  user: yup.object().shape({
    username  : yup.string().min(3).max(20).required().label('Preem Handle'),
    firstName : yup.string().min(2).max(50).required().label('First Name'),
    lastName  : yup.string().min(2).max(50).required().label('Last Name')
  }),
  restingHeartRate        : yup.number().nullable(true).transform((v) => (v === '' || Number.isNaN(v) ? null : v)).min(28).label('Resting'),
  maxHeartRate            : yup.number().nullable(true).transform((v) => (v === '' || Number.isNaN(v) ? null : v)).max(225).min(0).label('Max'),
  functionalThresholdPower: yup.number().nullable(true).transform((v) => (v === '' || Number.isNaN(v) ? null : v)).max(500).min(0).label('FTP'),
  weight                  : yup.number().nullable(true).transform((v) => (v === '' || Number.isNaN(v) ? null : v)).min(0).label('Weight'),
  heightCentimeters       : yup.number().nullable(true).when(
    'measurementSystem', {
      is  : 'METRIC',
      then: yup.number().nullable(true).transform((v) => (v === '' || Number.isNaN(v) ? null : v)).min(0).label('Height')
    }
  ),
});
