import{ 
  ReactElement, 
  useEffect, 
  useRef, 
  useState,
  useCallback 
}                                          from 'react';
import { useLocation, Link }               from 'react-router-dom';
import { useSelector }                     from 'react-redux';
import styled                              from 'styled-components';
import DateRangeIcon                       from '@mui/icons-material/DateRange';
import { ReactComponent as ProfileIcon }   from '@images/Icon-Profile-Circle-Grey.svg';
import { ReactComponent as CommunityIcon } from '@images/Icon-Create-Circle-Grey.svg';
import { ReactComponent as AppsIcon }      from '@images/Icon-Social-Link-Grey.svg';
import { ReactComponent as HistoryIcon }   from '@images/Icon-Metric-Ride-History-Grey.svg';
import { ReactComponent as RoutesIcon }    from '@images/Icon-Metric-Routes-Grey.svg';
import { ReactComponent as SettingsIcon }  from '@images/Icon-Settings-Grey.svg';
import { ReactComponent as LogoutIcon }    from '@images/Icon-Logout-Grey.svg';
import { smaller }                         from '@store/reducers/ui';
import iconDropdownArrowGrey               from '@images/Icon-Dropdown-Arrow-Grey.svg';
import { useAuth }                         from '@modules/Auth/contexts/AuthContext';
import { scrimBlack }                      from '@styles/theme';
import { 
  useGetUserSettingsQuery,
  useGetSelfInformationQuery
}                                          from '@modules/Profile/queries';
import { getUserAvatarUrl }                from '@utils/files_utils';
import { currentLocation }                 from '@store/reducers/location';
import { HeaderButton }                    from './HeaderButton';

interface IDropdownLink {
  icon : ReactElement;
  href : string;
  title: string;
}

const dropdownLinks: IDropdownLink[] = [{
  icon : <ProfileIcon />,
  href : '/profile',
  title: 'Profile'
}, {
  icon : <DateRangeIcon />,
  href : '/calendar',
  title: 'Calendar'
}, {
  icon : <CommunityIcon />,
  href : '/profile/community',
  title: 'Community'
}, {
  icon : <AppsIcon />,
  href : '/profile/apps',
  title: 'Apps'
}, {
  icon: <HistoryIcon />,
  href : '/profile/ride-history',
  title: 'Ride History'
}, {
  icon : <RoutesIcon />,
  href : '/profile/my-routes',
  title: 'My Routes'
}, {
  icon : <SettingsIcon />,
  href : '/profile/settings',
  title: 'Settings'
}];

export const HeaderProfileDropdown = () => {
  const { currentUser }           = useAuth();
  let   location                  = useLocation();
  const { data: settings }        = useGetUserSettingsQuery();
  const { data: self }            = useGetSelfInformationQuery();
  const photoUrl                  = getUserAvatarUrl(settings?.profilePictureUrl, currentUser?.photoURL || '');
  const [isVisible, setIsVisible] = useState(false);
  let   isSmaller                 = useSelector(smaller);
  const stateLocation             = useSelector(currentLocation);
  const clickOutsideContainer     = useRef<HTMLDivElement>(null);
  const { signout }               = useAuth();

  const checkMouseOver = (e: MouseEvent) => {
    const visibility = (null !== clickOutsideContainer.current) 
      && clickOutsideContainer.current.contains(e.target as Node);
    setIsVisible(visibility);
  }

  // redirect users to the login page after logout
  const handleClickLogout = useCallback(
    (event) => {
      event.preventDefault();

      signout();
    },
    [signout]
  );

  useEffect(() => {
    document.addEventListener("mouseover", checkMouseOver);
    return () => {
      document.removeEventListener("mouseover", checkMouseOver);
    };
  }, []);

  return (
    <HeaderProfileDropdown.Toggle
      ref={clickOutsideContainer}
    >
      <HeaderButton
        onHandleClick      = {() => setIsVisible(!isVisible)}
        onHandleMouseOver  = {() => setIsVisible(true)}
      >
        {photoUrl ? <HeaderProfileDropdown.Avatar src={photoUrl} /> : (self?.displayName ?? '').substring(0, 1)}
      </HeaderButton>
      
      <HeaderProfileDropdown.Arrow
        src     = {iconDropdownArrowGrey}
        alt     = ""
        rotated = {isVisible}
      />

      <HeaderProfileDropdown.List
        small   = {isSmaller}
        visible = {isVisible}
      >
        {self && Object.keys(stateLocation).length > 0 && dropdownLinks.map(link => (
          <HeaderProfileDropdown.Link
            key      = {link.href}
            to       = {link.href}
            onClick  = {() => setIsVisible(false)}
            selected = {location.pathname === link.href}
          >
            <div />
            {link.icon}
            <span>{link.title}</span>
          </HeaderProfileDropdown.Link>
        ))}

        <HeaderProfileDropdown.Link
          to       = ''
          onClick  = {handleClickLogout}
          selected = {false}
        >
          <div />
          <LogoutIcon />
          <span>Log Out</span>
        </HeaderProfileDropdown.Link>
      </HeaderProfileDropdown.List>
    </HeaderProfileDropdown.Toggle>
  );
};

HeaderProfileDropdown.Toggle = styled.div`
  display       : flex;
  line-height   : 100%;
  padding-bottom: 20px;
  margin-top    : 20px;
  position      : relative;

  img {
    width: 100%;
  }
`;

HeaderProfileDropdown.Arrow = styled.img<{rotated: boolean}>`
  transition: all 200ms ease-in-out;

  ${({rotated}) => rotated && `
    transform: rotate(-180deg);
  `}
`;

HeaderProfileDropdown.List = styled.nav<{small: boolean, visible: boolean}>`
  display         : none;
  padding         : 8px 0;
  border-radius   : 8px;
  background-color: ${({theme: {colors: {primary: {white}}}}) => white};
  box-shadow      : 0 8px 24px 0 ${scrimBlack(0.08)}, 0 16px 48px 0 ${scrimBlack(0.16)};
  position        : absolute;
  min-width       : 150px;
  top             : 60px;
  right           : -35px;
  transition      : top 100ms ease-in;
  cursor          : pointer;

  ${({visible}) => visible && `
    display: block;
  `};

  ${({theme: {mediaQueries: {sm}}}) => sm} {
    top: 50px;
  } 

  ${({theme: {mediaQueries: {md}}}) => md} {
    top: 48px;
  } 

  ${({theme: {mediaQueries: {lg}}}) => lg} {
    ${({small}) => small && `
      top: 50px;
    `}
  } 
`;

HeaderProfileDropdown.Link = styled(Link)<{selected: boolean}>`
  display        : flex;
  height         : 32px;
  margin-top     : 2px;
  margin-bottom  : 2px;
  align-items    : center;
  transition     : all 200ms ease-in-out;
  color          : ${({theme: {colors: {type: {medium}}}}) => medium};
  font-size      : 13px;
  line-height    : 100%;
  font-weight    : 700;
  letter-spacing : -0.25px;
  text-decoration: none;

  img {
    width: unset;
  }

  div {
    background-color          : ${({theme: {colors: {primary: {white}}}}) => white};
    width                     : 3px;
    align-self                : stretch;
    border-top-right-radius   : 4px;
    border-bottom-right-radius: 4px;
    transition                : background-color 200ms ease-in;
  }

  ${({selected, theme: {colors: {neutralBlue, primary: {rubineLight}}}}) => selected ? `
    color: ${neutralBlue[200]};

    div {
      background-color: ${neutralBlue[200]};
    };

    & g:last-child path, & svg > path {
      fill: ${neutralBlue[200]};
    };
  ` : `
    &:hover {
      color: ${rubineLight}
    };

    &:hover svg > path {
      fill: ${rubineLight}
    };

    &:hover g:last-child path {
      fill: ${rubineLight}
    };

    &:hover div {
      background-color: ${rubineLight}
    };
  `}

  svg {
    margin-left: 12px;
    width: 20px;
    height: 20px;
  }

  span {
    padding: 0 24px 0 10px;
  }
`;

HeaderProfileDropdown.Avatar = styled.div<{src: string}>`
  width               : 40px;
  height              : 40px;
  border-radius       : 100%;
  background-image    : ${({ src }) => `url(${src})`};
  background-position : 50% 50%;
  background-size     : cover;
  background-repeat   : no-repeat;
`;