/**
 * TODO:
 * refactor animation calls
 */

import { useEffect, useState }           from 'react';
import { useDispatch }                   from 'react-redux';
import { useHistory, useLocation }       from 'react-router-dom';
import {
  IInvitationLinkData,
  RIDE_INVITATION_DATA_KEY
}                                        from '@modules/Details/models/invitation';
import { useSessionStorage }             from '@utils/hooks/useSessionStorage';
import { useAuth }                       from '../contexts/AuthContext';
import { setShowLoginSignupButtons }     from '../../../store/reducers/ui';
import {
  showComponentsAndContainers,
  hideComponentsAndContainers,
}                                        from '../../../utils/display_utils';
import { parseNames }                    from '../../../utils/string_utils';
import { getDisplayName, getUserStatus } from '../../../services/user_service';
import { SignupPage }                    from './SignupPage';

interface StateType {
  from?          : string;
  needsHandle?   : boolean;
  needsLocation? : boolean;
}

export const Signup = () => {
  const { signupWithApple, signupWithGoogle } = useAuth();
  const dispatch                              = useDispatch();
  const history                               = useHistory();
  const { setItem }                           = useSessionStorage();
  const historyLocation                       = useLocation<StateType>();

  // we have to block the rendering of the map with allowMap so we don't get weird side effects
  const [allowMap, setAllowMap]                                             = useState(false);
  const [bgImageDimmed, setBgImageDimmed]                                   = useState(false);
  const [bgImageVisible, setBgImageVisible]                                 = useState(false);
  const [ctasVisible, setCtasVisible]                                       = useState(false);
  const [ctasContainerVisible, setCtasContainerVisible]                     = useState(false);
  const [focusFirstNameInput, setFocusFirstNameInput]                       = useState(false);
  const [focusPreemHandleInput, setFocusPreemHandleInput]                   = useState(false);
  const [landed, setLanded]                                                 = useState(false);
  const [signupFormVisible, setSignupFormVisible]                           = useState(false);
  const [signupFormContainerVisible, setSignupFormContainerVisible]         = useState(false);
  const [signupFormThirdPartyVisible, setSignupFormThirdPartyVisible]       = useState(false);
  const [, setSignupFormThirdPartyContainerVisible ]                        = useState(false);
  const [signupLocationVisible, setSignupLocationVisible]                   = useState(false);
  const [signupLocationContainerVisible, setSignupLocationContainerVisible] = useState(false);
  const [signupNotificationsVisible, setSignupNotificationsVisible]         = useState(false);
  const [taglineVisible, setTaglineVisible]                                 = useState(false);
  const [thirdPartyDefaultFirst, setThirdPartyDefaultFirst]                 = useState('');
  const [thirdPartyDefaultLast, setThirdPartyDefaultLast]                   = useState('');
  const [useEffectGate, setUseEffectGate]                                   = useState(true);
  const [invitationLinkData, setInvitationLinkData]                         = useState<IInvitationLinkData>();

  useEffect(
    () => {
      if (invitationLinkData) {
        return;
      }

      const searchParams = new URLSearchParams(historyLocation.search);

      if (searchParams.get('uuid') || searchParams.get('linkUuid')) {

        const invitationData: IInvitationLinkData = {
          inviteCode : searchParams.get('uuid'),
          linkUuid   : searchParams.get('linkUuid'),
        };

        setItem(RIDE_INVITATION_DATA_KEY, invitationData);

        setInvitationLinkData(invitationData);

        history.replace('/signup');
      }
    },
    [history, historyLocation.search, invitationLinkData, setItem]
  );

  // make timeouts clearable on unmount
  let bgTimeout       = setTimeout(() => {}, 1);
  let taglineTimeout  = setTimeout(() => {}, 1);
  let showCTAsTimeout = setTimeout(() => {}, 1);

  // Show the Signup Landing page with transitions
  function showSignupLanding(): void {
    // make the transition from the login page prettier
    if (
      historyLocation.state &&
      historyLocation.state.from === 'login' &&
      bgImageDimmed === false &&
      useEffectGate === true
    ) {
      setBgImageDimmed(true);
      setLanded(true);
    }

    if (!landed && useEffectGate === true) {
      bgTimeout = setTimeout(() => {
        setBgImageDimmed(false);
        setBgImageVisible(true);
      }, 300);
      taglineTimeout = setTimeout(() => {
        setTaglineVisible(true);
      }, 800);
      showCTAsTimeout = setTimeout(() => {
        showComponentsAndContainers(
          [{ label: 'CTAs', set: setCtasVisible }],
          [{ label: 'CTAsContainer', set: setCtasContainerVisible }]
        );
      }, 500);
      setLanded(true);
    }
  }

  function clearAllTimeouts() {
    clearTimeout(bgTimeout);
    clearTimeout(taglineTimeout);
    clearTimeout(showCTAsTimeout);
    clearTimeout(showSignupFormTimeout);
    clearTimeout(showSignupLocationTimeout);
    clearTimeout(showThirdPartySignupFormTimeout);
    clearTimeout(showSignupNotificationsTimeout);
  }
  // clear all the timeouts to avoid side effects
  useEffect(() => {
    return () => {
      setUseEffectGate(false);
      clearAllTimeouts();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Hide all of the elements to make sure nothing gets left visible
  function hideEverything(): void {
    hideComponentsAndContainers(
      [
        { label: 'CTAs', set: setCtasVisible },
        { label: 'SignupForm', set: setSignupFormVisible },
        { label: 'SignupLocation', set: setSignupLocationVisible },
        { label: 'ThirdPartyForm', set: setSignupFormThirdPartyVisible },
      ],
      [
        { label: 'CTAsContainer', set: setCtasContainerVisible },
        {
          label: 'SignupFormContainer',
          set: setSignupFormContainerVisible,
        },
        {
          label: 'SignupLocationContainer',
          set: setSignupLocationContainerVisible,
        },
        {
          label: 'ThirdPartyFormContainer',
          set: setSignupFormThirdPartyContainerVisible,
        },
      ]
    );
    setTaglineVisible(false);
    setAllowMap(false);
  }

  // make timeout clearable on unmount
  let showSignupFormTimeout = setTimeout(() => {}, 1);
  // Show the Signup text input form
  function showSignupForm(): void {
    setBgImageDimmed(true);
    dispatch(setShowLoginSignupButtons(false));
    hideEverything();
    showSignupFormTimeout = setTimeout(() => {
      showComponentsAndContainers(
        [{ label: 'SignupForm', set: setSignupFormVisible }],
        [{ label: 'SignupFormContainer', set: setSignupFormContainerVisible }]
      );
    }, 300);
  }

  // make timeout clearable on unmount
  let showSignupLocationTimeout = setTimeout(() => {}, 1);
  // Show the Signup location picker
  function showSignupLocation(): void {
    hideEverything();
    setBgImageDimmed(true);
    setAllowMap(true);
    showSignupLocationTimeout = setTimeout(() => {
      showComponentsAndContainers(
        [{ label: 'SignupLocation', set: setSignupLocationVisible }],
        [
          {
            label: 'SignupLocationContainer',
            set: setSignupLocationContainerVisible,
          },
        ]
      );
    }, 300);
  }


  let showSignupNotificationsTimeout = setTimeout(() => {}, 1);
  function showSignupNotifications(): void {
    hideEverything();
    setBgImageDimmed(true);
    showSignupNotificationsTimeout = setTimeout(() => {
      showComponentsAndContainers(
        [{ label: 'SignupNotifications', set: setSignupNotificationsVisible }],
        [
          {
            label: 'SignupLocationContainer',
            set: setSignupLocationContainerVisible,
          },
        ]
      );
    }, 300);
  }

  // make timeout clearable on unmount
  let showThirdPartySignupFormTimeout = setTimeout(() => {}, 1);
  // Show the third-party signup form
  function showThirdPartySignupForm(): void {
    hideEverything();
    setBgImageDimmed(true);
    showThirdPartySignupFormTimeout = setTimeout(() => {
      showComponentsAndContainers(
        [
          {
            label: 'ThirdPartySignupForm',
            set: setSignupFormThirdPartyVisible,
          },
        ],
        [
          {
            label: 'ThirdPartySignupFormContainer',
            set: setSignupFormThirdPartyContainerVisible,
          },
        ]
      );
      /**
       * focus firstName/preemHandle field, based on whether displayName isTuple
       * examples:
       * displayName = "Dave Baumgartner" => setFocusPreemHandleInput
       * displayName = "David the Baumgartner" => setFocusFirstNameInput
       * displayName = "Dave" => setFocusFirstNameInput
       * displayName = "Sir David Baumgartner, The Legend Continues" => setFocusFirstNameInput
       */
      let { firstName, lastName, isTuple } = parseNames(getDisplayName());
      setThirdPartyDefaultFirst(firstName);
      setThirdPartyDefaultLast(lastName);
      if (isTuple === true) {
        setFocusPreemHandleInput(!focusPreemHandleInput);
      } else {
        setFocusFirstNameInput(!focusFirstNameInput);
      }
    }, 300);
  }

  /** When signup is clicked, take user to homepage
   * they're already logged in by this point, so it's just a redirect
   */
  function handleSignupClick(): void {
    hideEverything();
    setTimeout(() => {
      history.push("/");
    }, 500);
  }

  /**
   * @description Let the user sign in with a third-party provider, then show input form to allow name adjustments and handle selection
   * @param {string} providerName Currently either google or apple, can be expanded
   */
  function thirdPartySignupAndRedirect(providerName: string) {
    let googlePromise;
    let applePromise;

    if (providerName === 'google') {
      googlePromise = new Promise((resolve, reject) => {
        signupWithGoogle()
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      });
      applePromise = new Promise((_resolve, reject) => {
        reject('rejecting to avoid apple call in google signup');
      });
    } else {
      applePromise = new Promise((resolve, reject) => {
        signupWithApple()
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      });
      googlePromise = new Promise((_resolve, reject) => {
        reject('rejecting to avoid google call in apple signup');
      });
    }
    const promises = [applePromise, googlePromise];
    Promise.any(promises)
      .then((_value: any /** TODO: fix any */) => {
        getUserStatus().then((result: any) => {
          if (result.needsHandle) {
            let { firstName, lastName } = parseNames(getDisplayName());
            setThirdPartyDefaultFirst(firstName);
            setThirdPartyDefaultLast(lastName);
            showThirdPartySignupForm();
          } else if (result.needsLocation) {
            showSignupLocation();
          } else {
            history.push('/');
          }
        });
      })
      .catch((error) => {
        console.log(`third party login issue: `, error);
        /** TODO: proper error handling once it's designed */
      });
  }

  // route user to proper stage of signup process if needed
  useEffect(() => {
    getUserStatus() // check if user has handle & location
      .then((result) => {
        if (result.loggedIn === false) {
          showSignupLanding();
        } else if (result.needsHandle === true) {
          let { firstName, lastName } = parseNames(getDisplayName());
          setThirdPartyDefaultFirst(firstName);
          setThirdPartyDefaultLast(lastName);
          showThirdPartySignupForm();
        } else if (result.needsLocation === true) {
          showSignupLocation();
        } else {
          history.push("/");
        }
      })
      .catch(() => {
        showSignupLanding();
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SignupPage
        invitationLinkData                   = {invitationLinkData}
        signupLocationContainerVisible       = {signupLocationContainerVisible}
        signupLocationVisible                = {signupLocationVisible}
        signupFormContainerVisible           = {signupFormContainerVisible}
        allowMap                             = {allowMap}
        handleSignupClick                    = {handleSignupClick}
        signupFormVisible                    = {signupFormVisible}
        showSignupLocation                   = {showSignupLocation}
        thirdPartyDefaultFirst               = {thirdPartyDefaultFirst}
        thirdPartyDefaultLast                = {thirdPartyDefaultLast}
        signupFormThirdPartyVisible          = {signupFormThirdPartyVisible}
        signupNotificationsVisible           = {signupNotificationsVisible}
        showSignupNotifications              = {showSignupNotifications}
        focusPreemHandleInput                = {focusPreemHandleInput}
        focusFirstNameInput                  = {focusFirstNameInput}
        thirdPartySignupAndRedirect          = {thirdPartySignupAndRedirect}
        showSignupForm                       = {showSignupForm}
        ctasContainerVisible                 = {ctasContainerVisible}
        ctasVisible                          = {ctasVisible}
        taglineVisible                       = {taglineVisible}
        bgImageDimmed                        = {bgImageDimmed}
        bgImageVisible                       = {bgImageVisible}
      />
    </>
  );
};
