import {
  useCallback,
  useState,
}                                         from 'react';
import { Button }                         from '@mui/material';
import styled                             from 'styled-components';
import { Notification }                   from '@components/Notification';
import { useGeneratePreviewLinkMutation } from '@modules/Details/queries';
import { useQueryParams }                 from '@utils/hooks/useQueryParams';

export const ShareRide = () => {
  const [generateLink]                    = useGeneratePreviewLinkMutation();
  const [generatedLink, setGeneratedLink] = useState<string>();
  const { id }                            = useQueryParams<{ id: string; }>();

  const copyLink = useCallback(
    () => {
      if (generatedLink) {
        setTimeout(async () => await navigator.clipboard.writeText(generatedLink).then(() => {
          Notification.enqueueSnackbar('Link copied', 'success');
        }, () => {
          Notification.enqueueSnackbar('Something went wrong...', 'success');
        }))
      } else {
        generateLink({ id })
          .unwrap()
          .then(result => {
            const uuid = (result as any)?.uuid;

            const shareLink =
              `${process.env.REACT_APP_PREEM_WEB_URL}/shared/ride/${uuid}/overview`;

            setGeneratedLink(shareLink)

            setTimeout(async () => await navigator.clipboard.writeText(shareLink).then(() => {
              Notification.enqueueSnackbar('Link copied', 'success');
            }, () => {
              Notification.enqueueSnackbar('Something went wrong...', 'success');
          }));
        });
      }
    },
    [generateLink, generatedLink, id]
  );

  return (
    <ShareRide.Container>
      <ShareRide.Header>
        <b>Copy this link so you can finally kick the group text</b>
      </ShareRide.Header>

      <Button
        color   = "secondary"
        variant = "contained"
        onClick = {copyLink}
      >
        Copy Link to Join Ride
      </Button>

    </ShareRide.Container>
  );
};

ShareRide.Container = styled.div`
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  background-color: ${({theme: {colors: {primary: { white }}}}) => white};
  border-radius   : 8px;
  margin          : 0 auto;
  padding         : 60px 0;
  width           : 352px;
  max-width       : 99%;
  max-height      : 80%;
  overflow        : hidden;

  p {
    font-size   : 14px;
    color       : ${({ theme }) => theme.colors.primary.rubineDark};
    font-weight : bold;
    cursor      : pointer;
  }

  button {
    margin-top: 30px;
  }
`;

ShareRide.Header = styled.div`
  text-align: center;
  font-size : ${({theme: {fontSizes: {xl}}}) => xl};
  padding   : 0 10px 35px;
`;
