import styled                           from 'styled-components';
import { SignupButton }                 from '../../../../../components/SignupButton';
import { ReactComponent as GoogleIcon } from '../../../../../images/Icon-Sign-Up-Google.svg'
import { ReactComponent as AppleIcon }  from '../../../../../images/Icon-Sign-Up-Apple.svg';

interface ILoginButtonsProps {
  thirdPartyLogin: (providerName: string) => void;
}

export const LoginButtons = ({ thirdPartyLogin }: ILoginButtonsProps) => (
  <LoginButtons.ButtonsWrapper>
    <SignupButton 
      onHandleClick={() => thirdPartyLogin("google")} 
      title="Log in with Google" 
      icon={<GoogleIcon />} 
    />

    <SignupButton
      onHandleClick = {() => thirdPartyLogin("apple")}
      title         = "Log in with Apple"
      icon          = {<AppleIcon />}
    />
    {/* <SignupButton
      onHandleClick={() => {
        loginNotImplemented("Facebook");
      }}
      buttonClasses="facebook"
    >
      Log in with Facebook
    </SignupButton> */}
  </LoginButtons.ButtonsWrapper>
);

LoginButtons.ButtonsWrapper = styled.div`
  display        : flex;
  width          : 320px;
  flex-direction : column;

  @media screen and (max-width: 767px) {
    width: 280px;
  }
  
  @media screen and (max-width: 479px) {
    width: 260px;
  }
`;