import styled, { css }                         from 'styled-components';
import { ReactComponent as PrecipitationIcon } from '@images/Icon-Weather-Precipitation-Black.svg';
import { ReactComponent as TemperatureIcon }   from '@/images/Icon-Weather-Temperature-Black.svg';
import { ReactComponent as WindIcon }          from '@/images/Icon-Weather-Windy-Black.svg';
import { isDateInPast }                        from '@utils/commonFunctions';
import { scrimBlack }                          from '../../../../../../../../styles/theme';
import { degreesToCompass }                    from '../../../../../../../../utils/conversion_utils';
import { getWeatherConditionClassName }        from '../../../../../../../../utils/string_utils';
import { Weather }                             from '../../../../../../../../models/CalendarCardModel';
import { WeatherIcon }                         from '../../../../../../../../components/WeatherIcon';
import { WindDirection }                       from '../../../../../../../../components/WindDirection';

/**
 * @description WeatherForecast renders the weather forecast in the plan day columns on the homepage
 * @component
 * @prop {Weather | undefined} forecast Use the forecast to show weather, or set it to undefined to render Weather Forecast Unavailable
 * @example
 * <WeatherForecast />
 */

interface IWeatherForecastProps {
  forecast : Weather | undefined;
  date     : Date;
  isMobile?: boolean;
}

export const WeatherForecast = ({
  forecast,
  isMobile,
  date
}: IWeatherForecastProps) => {
  if (isDateInPast(date)) {
    return <WeatherForecast.Empty isMobile={!!isMobile} />
  }

  if (typeof forecast === "undefined") {
    return (
      <WeatherForecast.Unavailable isMobile={!!isMobile}>
        Weather Forecast Unavailable
      </WeatherForecast.Unavailable>
    );
  }

  return (
    <WeatherForecast.Wrapper isMobile={!!isMobile}>
      <WeatherIcon
        condition={getWeatherConditionClassName(forecast.condition)}
        isMobile={!!isMobile}
      />

      <WeatherForecast.MetricsWrapper>
        <WeatherForecast.MetricWrapper>
          <WeatherForecast.Metric>
            <TemperatureIcon />

            <WeatherForecast.MetricText>
              <WeatherForecast.MetricValue isMobile={!!isMobile}>
                {forecast.maxTemperature &&
                  Math.round(forecast.maxTemperature)}
                &deg; /{" "}
                {forecast.minTemperature &&
                  Math.round(forecast.minTemperature)}
                &deg;{" "}
              </WeatherForecast.MetricValue>

              <WeatherForecast.MetricLabel>
                {forecast.temperatureUnits?.substring(0,1)}
              </WeatherForecast.MetricLabel>
            </WeatherForecast.MetricText>
          </WeatherForecast.Metric>
        </WeatherForecast.MetricWrapper>

        {!isMobile && (
          <WeatherForecast.MetricWrapper>
            <WeatherForecast.Metric>
              <WindIcon />

              <WeatherForecast.MetricText>
                <WeatherForecast.MetricValue>
                  {forecast.maxWindSpeed && Math.round(forecast.maxWindSpeed)} /{" "}
                  {forecast.minWindSpeed && Math.round(forecast.minWindSpeed)}{" "}
                </WeatherForecast.MetricValue>
                <WeatherForecast.MetricLabel>
                  {forecast.windSpeedUnits}
                </WeatherForecast.MetricLabel>
              </WeatherForecast.MetricText>

              <WindDirection
                direction={degreesToCompass(forecast.windDirection)}
              />
            </WeatherForecast.Metric>
          </WeatherForecast.MetricWrapper>
        )}

        {!isMobile && (
          <WeatherForecast.MetricWrapper>
            <WeatherForecast.Metric>
              <PrecipitationIcon />

              <WeatherForecast.MetricText>
                <WeatherForecast.MetricValue>
                  {forecast?.precipitation}%
                </WeatherForecast.MetricValue>

                <WeatherForecast.MetricLabel>
                  Precipitation
                </WeatherForecast.MetricLabel>
              </WeatherForecast.MetricText>
            </WeatherForecast.Metric>
          </WeatherForecast.MetricWrapper>
        )}
      </WeatherForecast.MetricsWrapper>
    </WeatherForecast.Wrapper>
  );
};

WeatherForecast.Unavailable = styled.div<{ isMobile: boolean; sticky?: boolean; }>`
  display         : flex;
  flex-direction  : column;
  justify-content : center;
  align-self      : center;
  text-align      : center;
  border-radius   : 16px;
  background-color: ${scrimBlack(0.04)};
  color           : ${({ theme }) => theme.colors.type.dark};
  width           : ${({ isMobile }) => isMobile ? '50%' : '296px'};
  height          : 104px;
  font-weight     : 700;
  margin-bottom   : ${({ sticky }: { sticky?: boolean }) =>
    sticky ? "4px" : "16px"};
`;

WeatherForecast.Wrapper = styled.div<{ isMobile: boolean }>`
  display       : flex;
  align-items  : center;

  ${({ isMobile }) => isMobile ? css`
    width         : fit-content;
    flex-direction: column;
  ` : css`
    margin: 0 auto 16px auto;
  `}
`; //pdh-weather-forecast

WeatherForecast.MetricsWrapper = styled.div`
  display       : flex;
  flex-direction: column;
`; //rr-detail-w-forecast-copy

WeatherForecast.MetricWrapper = styled.div`
  margin-bottom: 8px;
  padding-left : 0px;
  flex         : 0 0 auto;
  @media screen and (max-width: 767px) {
    margin-right : 0px;
    margin-bottom: 8px;
  }
`; //pdh-weather-li

WeatherForecast.Metric = styled.div`
  display    : flex;
  height     : 24px;
  align-items: center;
  flex       : 0 0 auto;
  svg {
    margin-right: 5px;
  }
`; //li-weather

WeatherForecast.MetricText = styled.div`
  display    : flex;
  align-items: baseline;
  flex       : 0 0 auto;
  white-space: nowrap;
`; //data-li-copy

WeatherForecast.MetricValue = styled.div<{ isMobile?: boolean }>`
  margin-right: 2px;
  flex        : 0 0 auto;
  font-size   : ${({ isMobile }) => isMobile ? '14px' : '16px'};
  line-height : 16px;
  font-weight : 700;
`; //data-li-metric type metric-l

WeatherForecast.MetricLabel = styled.div`
  color      : ${({ theme }) => theme.colors.type.medium};
  font-size  : 12px;
  line-height: 12px;
  font-weight: 400;
  @media screen and (max-width: 767px) {
    font-size: 10px;
  }
`; //type medium unit-l

WeatherForecast.Empty = styled.div<{ isMobile: boolean; sticky?: boolean; }>`
  display         : flex;
  flex-direction  : column;
  justify-content : center;
  align-self      : center;
  text-align      : center;
  border-radius   : 16px;
  height          : 104px;
  font-weight     : 700;
  width           : ${({ isMobile }) => isMobile ? '50%' : '296px'};
  margin-bottom   : ${({ sticky }: { sticky?: boolean }) =>
    sticky ? "4px" : "16px"};
`;