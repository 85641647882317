import { useCallback }   from 'react';
import { 
  useHistory, 
  useLocation, 
  useParams 
}                        from 'react-router-dom';
import { useAuth }       from '@modules/Auth/contexts/AuthContext';
import { PublicProfile } from '../pages/PublicProfile';
import { 
  useSetConnectionWithUserMutation,
  useGetPublicProfileShortInfoQuery,
  useGetUserSettingsQuery
 }                       from '../queries';

 interface IPublicProfileContainerProps {
   username?: string;
   onClose? : () => void;
 }

export const PublicProfileContainer = ({
  username,
  onClose
}: IPublicProfileContainerProps) => {
  const { isAuthorized }               = useAuth();
  const { publicProfileUsername }      = useParams<{ publicProfileUsername: string }>();
  const { data: shortInfo, isLoading } = useGetPublicProfileShortInfoQuery({ username: (username || publicProfileUsername) });
  const [setConnectionWithUser]        = useSetConnectionWithUserMutation();
  const { goBack, push }               = useHistory();
  const { key }                        = useLocation();
  const { data: userData }             = useGetUserSettingsQuery();

  const handleClose = useCallback(
    () => {
      if (username && onClose) {
        return onClose();
      }

      if (isAuthorized) {
        return key ? goBack() : push('/');
      }
        
      push('/login');
    },
    [goBack, isAuthorized, key, onClose, push, username]
  );

  const handleSettingConnection = useCallback(
    () => {
      if (shortInfo?.userId) {
        setConnectionWithUser({ userId: shortInfo?.userId });
      }
    },
    [shortInfo, setConnectionWithUser]
  );
  
  return (
    <PublicProfile 
      data                  = {shortInfo}
      isLoading             = {isLoading}
      setConnectionWithUser = {(userData?.user.username === shortInfo?.username) ? undefined : handleSettingConnection}
      onClose               = {handleClose}
    />
  );
}
