import { useMemo }                             from 'react';
import styled, { css }                         from 'styled-components';
import { Grid, Link }                          from '@mui/material';
import { scrimBlack }                          from '@styles/theme';
import { WeatherIconMono }                     from '@components/WeatherIconMono';
import { WindDirection }                       from '@components/WindDirection';
import { WeatherTimelineSlider }               from '@components/WeatherTimelineSlider';
import {
  getWeatherConditionClassName,
  getWeatherConditionDisplayName,
}                                              from '@utils/string_utils';
import { getThumbnailByResolution }            from '@utils/commonFunctions';
import { preemFilesHost }                      from '@config/api_config';
import {
  degreesToCompass,
  toFarenheit,
  toMPH,
}                                              from '@utils/conversion_utils';
import {
  TemperatureUnitType,
  WindSpeedUnitType,
}                                              from '@models/CalendarCardModel';
import {
  ITimeline,
  IWeatherDetails,
}                                              from '@models/rideWeather';
import { MEASUREMENT_SYSTEMS }                 from '@modules/Profile/utils/options';
import {
  IRideInvitation,
  WeatherQuality,
}                                              from '@modules/Calendar/models';
import { TMeasurementSystem }                  from '@modules/Profile/models/Settings';
import { ELocationType }                       from '@modules/Details/models/ride';
import { ReactComponent as TemperatureIcon }   from '@images/icons/weather/Icon-Weather-Temperature-Black.svg';
import { ReactComponent as PrecipitationIcon } from '@images/icons/weather/Icon-Weather-Precipitation-Black.svg';
import { ReactComponent as WindIcon }          from '@images/Icon-Weather-Windy-Black.svg';

interface IWeatherDetailsProps {
  invitation        : IRideInvitation;
  measurementSystem : TMeasurementSystem;
  temperatureUnits  : TemperatureUnitType;
  startTime         : string;
  endTime           : string;
  isRideStarted     : boolean;
  isEditLinkDisabled: boolean;
  setCurrentWeather : (data: IWeatherDetails) => void;
  onOpenEdit        : () => void;
  currentWeather?   : IWeatherDetails | null;
  routeWeather?     : ITimeline[];
}

export const WeatherBlock = ({
  invitation,
  currentWeather,
  measurementSystem,
  temperatureUnits,
  routeWeather,
  startTime,
  endTime,
  isRideStarted,
  isEditLinkDisabled,
  setCurrentWeather,
  onOpenEdit,
}: IWeatherDetailsProps) => {
  const isVirtualRide      = invitation.ride.rideLocationType === ELocationType.VIRTUAL;
  const routeThumbnailPath = getThumbnailByResolution(invitation.ride.routeThumbnailPaths)
    ?? getThumbnailByResolution(invitation.ride.startLocation?.thumbnailPaths);

  const showWeatherSlider = useMemo(
    () => {
      if (isRideStarted) {
        return false;
      }

      if (!routeWeather?.length) {
        return false;
      }

      return Boolean(invitation.ride.distance || (invitation.ride.genLocation && invitation.ride.startDate !== invitation.ride.endDate));
    },
    [
      invitation.ride.distance,
      invitation.ride.endDate,
      invitation.ride.genLocation,
      invitation.ride.startDate,
      isRideStarted,
      routeWeather?.length
    ],
  );

  return (
    <WeatherBlock.Wrapper container>
      {routeThumbnailPath ? (
        <>
          <Grid item xs={5}>
            <WeatherBlock.Map imageUrl={`${preemFilesHost}${routeThumbnailPath}`} />
          </Grid>

          <WeatherBlock.WeatherConditionsBlock
            container
            item
            direction  = "column"
            rowSpacing = "10px"
            xs         = {7}
          >
            <Grid item container alignItems="center" columnSpacing="4px">
              <WeatherIconMono
                condition={getWeatherConditionClassName(currentWeather?.weatherCode ??  0)}
              />
              <Grid item>
                <WeatherBlock.MetricsValue>
                  {currentWeather
                    ? getWeatherConditionDisplayName(currentWeather?.weatherCode ?? 0)
                    : "\u2014"}
                </WeatherBlock.MetricsValue>
              </Grid>
            </Grid>

            <Grid item container columnSpacing="4px">
              <TemperatureIcon />

              <Grid item>
                <WeatherBlock.MetricsValue>
                  {currentWeather
                    ? `${Math.round(temperatureUnits === TemperatureUnitType.f ? toFarenheit(currentWeather?.temperatureMax ?? 0): currentWeather?.temperatureMax ?? 0)}˚`
                    : "\u2014"}
                </WeatherBlock.MetricsValue>

                <WeatherBlock.MetricsUnits>
                  {temperatureUnits === TemperatureUnitType.c ? 'C' : 'F'}
                </WeatherBlock.MetricsUnits>
              </Grid>
            </Grid>

            <Grid item container columnSpacing="4px">
              <PrecipitationIcon />

              <Grid item>
                <WeatherBlock.MetricsValue>
                  {currentWeather
                    ? currentWeather?.precipitationProbability ?? 0
                    : "\u2014"}
                  %{" "}
                </WeatherBlock.MetricsValue>

                <WeatherBlock.MetricsUnits>Precipitation</WeatherBlock.MetricsUnits>
              </Grid>
            </Grid>

            <Grid item container columnSpacing="4px">
              <WindIcon />

              <Grid item>
                <WeatherBlock.MetricsValue>
                  {currentWeather
                    ? `${
                      measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL
                        ? Math.round(toMPH(currentWeather?.windSpeedMax ?? 0))
                        : Math.round(currentWeather?.windSpeedMax ?? 0)
                    }`
                    : "\u2014"
                  }
                </WeatherBlock.MetricsValue>
              </Grid>

              <Grid item>
                <WeatherBlock.MetricsUnits>
                  {measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL ? WindSpeedUnitType.mph : WindSpeedUnitType.kph}
                </WeatherBlock.MetricsUnits>
              </Grid>

              {currentWeather && (
                <Grid item>
                  <WindDirection
                    direction={degreesToCompass(currentWeather?.windDirection ?? 0)}
                  />
                </Grid>
              )}
            </Grid>
          </WeatherBlock.WeatherConditionsBlock>
        </>
      ) : (
        <WeatherBlock.NoLocationWrapper>
          {isVirtualRide && <WeatherBlock.RideLocationTypeLabel>Virtual Ride</WeatherBlock.RideLocationTypeLabel>}

          <WeatherBlock.NoLocationLabel $disabled={isEditLinkDisabled} onClick={onOpenEdit}>
            {isEditLinkDisabled ? 'No location or route selected' : 'Choose a location for weather'}
          </WeatherBlock.NoLocationLabel>
        </WeatherBlock.NoLocationWrapper>
      )}

      {showWeatherSlider && (
        <WeatherBlock.SliderBlock
          container
          alignContent   = "center"
          justifyContent = "center"
        >
          <Grid item xs={10}>
            <WeatherTimelineSlider
              cardSize
              timelines         = {routeWeather || []}
              setCurrentWeather = {setCurrentWeather}
              startTime         = {startTime}
              endTime           = {endTime}
              hasRoute          = {Boolean(invitation.ride.distance)}
            />
          </Grid>
        </WeatherBlock.SliderBlock>)}
    </WeatherBlock.Wrapper>
  );
};

WeatherBlock.NoLocationWrapper = styled.div`
  display         : flex;
  flex-direction  : column;
  justify-content : center;
  align-self      : center;
  text-align      : center;
  border-radius   : 16px;
  background-color: ${scrimBlack(0.04)};
  color           : ${({ theme }) => theme.colors.type.dark};
  width           : 296px;
  height          : 104px;
  font-weight     : 700;
  margin          : 16px auto;
`;

WeatherBlock.NoLocationLabel = styled(Link)<{ $disabled: boolean; }>`
  ${({ $disabled, theme: { colors } }) => $disabled && css`
    pointer-events: none;
    color         : ${colors.type.medium};
  `};
`;

WeatherBlock.RideLocationTypeLabel = styled.p`
  color: ${({ theme: { colors } }) => colors.type.medium}
`;

WeatherBlock.MetricsValue = styled.span`
  font-size     : ${({theme: {fontSizes: {s}}}) => s};
  font-weight   : 700;
  margin-right  : 2px;
  text-transform: capitalize;
`;

WeatherBlock.MetricsUnits = styled.span`
  font-weight: 500;
  font-size  : 9px;
`;

WeatherBlock.Wrapper = styled(Grid)<{ $weatherQuality?: WeatherQuality }>`
  display         : flex;
  border          : 6px solid ${({ theme }) => theme.colors.neutralGrey[600]};
  background-color: ${({ theme: { colors: { primary: { white } } } }) => white};

  ${WeatherBlock.MetricsUnits} {
    color: ${({ theme: { colors: { type: { medium } } } }) => medium};
  }

  svg {
    width : 18px;
    height: 18px;
  }
`;

WeatherBlock.Map = styled.div`
  height             : 100%;
  background-image   : ${({ imageUrl }: { imageUrl: string } ) => `url(${imageUrl})`};
  background-size    : cover;
  background-repeat  : no-repeat;
  background-position: center;
`;

WeatherBlock.WeatherConditionsBlock = styled(Grid)`
  padding: 10px 15px;
`;

WeatherBlock.SliderBlock = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.neutralGrey[200]};
  color           : ${({ theme }) => theme.colors.type.medium};
  font-size       : 16px;
  font-weight     : 700;
  height          : 64px;
  letter-spacing  : -0.2px;
  line-height     : 21.12px;
`;
