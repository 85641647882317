/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState }         from 'react';
import { IconButton, Collapse }        from '@mui/material';
import styled                          from 'styled-components';
import Cookies                         from 'universal-cookie';
import { scrimWhite }                  from '../../../../styles/theme';
import { ContentHeroLocationSelector } from './components/ContentHeroLocationSelector';
import { ContentHeroCTAs }             from './components/ContentHeroCTAs';
// import { ContentHeroMetrics }           from './components/ContentHeroMetrics';
import { ContentHeroOnboarding }       from './components/ContentHeroOnboarding';

// import { ILocation }                    from '../../../Profile/models/Settings';
// import { currentLocation }              from '../../../../state/reducers/location';

import {
  checkAppsConnected,
  checkProfileCompleted,
  checkRideHistoryImported,
}                                      from '../../../../services/user_service';

import { ExpandLess, ExpandMore }      from '@mui/icons-material/';
import ContentHeroBackgroundPattern    from "../../../../images/Pattern-L-A16-16-9.svg";

/**
 * @description ContentHero is a component that renders the content hero at the top of the user's homepage
 * @component
 * @example
 * <ContentHero />
 */
export const ContentHero = () => {
  // const contentHeroLocation: ILocation                    = useSelector(currentLocation);
  const [showOnboarding, setShowOnboarding]               = useState<boolean>(true);
  const [isConnectedWithApps, setIsConnectedWithApps]     = useState<boolean>(false);
  const [isProfileCompleted, setIsProfileCompleted]       = useState<boolean>(false);
  const [isRideHistoryImported, setIsRideHistoryImported] = useState<boolean>(false);
  const [isChecked, setCheckedStatus]                     = useState<boolean>(false);
  const cookies                                           = new Cookies();

  const toggleOnboarding = () => {
    const cookieValueAsString = cookies.get('isContentHeroExpanded');
    const cookieValue         = cookieValueAsString === "false" ? false : true;
    cookies.set("isContentHeroExpanded", !cookieValue);
    setShowOnboarding(!cookieValue);
  }

  const checkOnboardingCookie = () =>{
    const cookieValueAsString = cookies.get('isContentHeroExpanded');
    const cookieValue         = cookieValueAsString === "false" ? false : true;
    setShowOnboarding(cookieValue);
  }

  useEffect(
    () => {
      checkOnboardingCookie();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      checkAppsConnected().then((response) => {
        setIsConnectedWithApps(response);
      });

      checkProfileCompleted().then((response) => {
        setIsProfileCompleted(response);
      });

      checkRideHistoryImported().then((response) => {
        setIsRideHistoryImported(response);
      });

      setCheckedStatus(true);
    }, 
    []
  );

  return (
    <ContentHero.Container>
      <ContentHero.TopWrapper>
        <ContentHero.LocationSummary>
          <ContentHero.ToggleButtonWrapper>
            <ContentHeroLocationSelector />

            {/* <ContentHero.ToggleButton onClick={toggleOnboarding}>
              {showOnboarding ? <ExpandLess /> : <ExpandMore />}
            </ContentHero.ToggleButton> */}
          </ContentHero.ToggleButtonWrapper>
          {/* <ContentHeroMetrics {...metrics} /> */}
        </ContentHero.LocationSummary>

        {/* <ContentHero.CTAWrapper>
          <ContentHeroCTAs />
        </ContentHero.CTAWrapper> */}
      </ContentHero.TopWrapper>

      {/* {isChecked && (
        <>
          {!isConnectedWithApps || !isProfileCompleted || !isRideHistoryImported ? (
            <Collapse in={showOnboarding}>
              <ContentHero.Rule />
              <ContentHeroOnboarding
                isConnectedWithApps   = {isConnectedWithApps}
                isProfileCompleted    = {isProfileCompleted}
                isRideHistoryImported = {isRideHistoryImported}
              />
            </Collapse>
          ) : (
            <ContentHero.BottomPadding />
          )}
        </>
      )} */}
    </ContentHero.Container>
  );
};

ContentHero.Container = styled.div`
  display            : flex;
  min-height         : 100vh;
  padding-top        : 64px;
  padding-bottom     : 20px;
  padding-right      : 6%;
  padding-left       : 6%;
  flex-direction     : column;
  align-items        : stretch;
  background-color   : ${({ theme }) => theme.colors.neutralGrey[200]};
  position           : relative;
  z-index            : 30;
  min-height         : auto;
  background-position: 0% 50%, 0px 0px;
  background-size    : cover, auto;
  background-repeat  : no-repeat, repeat;
  background-image   : url(${ContentHeroBackgroundPattern}),
    ${({ theme }) => theme.colors.gradientRadial.rubinePurple};

  ${({theme: {mediaQueries: { xs }}}) => xs} {
    padding-left : 20px;
    padding-right: 20px;
    padding-top  : 48px;
  }
  ${({theme: {mediaQueries: { md }}}) => md} {
    padding-top   : 64px;
    padding-right : 20px;
    padding-bottom: 40px;
    padding-left  : 20px;
  }
  ${({theme: {mediaQueries: { lg }}}) => lg} {
    padding-top  : 64px;
    padding-right: 6%;
    padding-left : 6%;
  }
  ${({theme: {mediaQueries: { xl }}}) => xl} {
    padding-top  : 80px;
    padding-right: 8%;
    padding-left : 8%;
  }
  ${({theme: {mediaQueries: { xxl }}}) => xxl} {
    padding-left : 16%;
    padding-right: 16%;
  }
`;

ContentHero.TopWrapper = styled.div`
  width     : 100%;
  max-width : 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

ContentHero.LocationSummary = styled.div`
  display        : flex;
  margin-top     : 48px;
  margin-bottom  : 24px;
  justify-content: flex-start;
  align-items    : center;
  align-self     : flex-start;
  flex           : 0 0 auto;
  width          : 100%;
  @media screen and (max-width: 991px) {
    margin-top    : 32px;
    flex-direction: column;
    align-items   : stretch;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 0px;
  }
`;

ContentHero.ToggleButton = styled(IconButton)`
  color  : white;
  height : 40px;
  width  : 40px;
`;

ContentHero.ToggleButtonWrapper = styled.div`
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  width          : 100%;
  //max-width      : 100%;
  //overflow-x     : auto;
`;

ContentHero.BottomPadding = styled.div`
  height: 38px;
`;

ContentHero.Rule = styled.div`
  display         : block;
  background-color: ${scrimWhite(0.32)};
  height          : 1px;
  width           : 100%;
  margin          : 48px 0;
  @media screen and (max-width: 991px) {
    margin-top   : 40px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 767px) {
    margin-top   : 16px;
    margin-bottom: 8px;
  }
`;

ContentHero.CTAWrapper = styled.div`
  /** remove upper (duplicate) scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width   : none;  /* Firefox */
  ${({theme: {mediaQueries: { sm }}}) => sm} {
    margin-left : -20px;
    margin-right: -20px;
    padding     : 16px 0 16px 20px;
  }
  ${({theme: {mediaQueries: { md }}}) => md} {
    margin  : 0;
    padding : 0;
    overflow: visible;
  }

`;
