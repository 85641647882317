import {
  ReactNode,
  useCallback,
  useMemo,
  useState,
}                                        from 'react';
import styled, { css }                   from 'styled-components';
import { Button, Grid, Input }           from '@mui/material';
import { ConfirmationModal }             from '@components/ConfirmationModal';
import { ReactComponent as PlusIcon }    from '@images/Icon-Add-Rubine.svg';
import { ReactComponent as ProfileIcon } from '@images/Icon-Profile-Circle-Grey.svg';
import { Notification }                  from '@components/Notification';
import TemporaryBG                       from '@images/Pattern-L-A16-16-9.svg';
import { PhotoPreview }                  from '@components/PhotoPreview';
import { getProfilePhotoUrl }            from '@utils/files_utils';
import {
  useUpdateUserAvatarMutation,
  useUpdateUserProfilePhotosMutation,
  useDeleteUserAvatarMutation,
  useDeleteUserProfilePhotoMutation
}                                        from '../../queries';
import { IUserProfilePhoto }             from '../../models/UserInfo';

export const PHOTO_ALLOWED_TYPES     = ['image/jpeg', 'image/png'];
export const INVALID_PHOTOS_TYPE_MSG = 'Must be JPG, JPEG or PNG';

interface IUserGalleryProps {
  editable      : boolean;
  userPhoto     : string;
  profilePhotos : IUserProfilePhoto[];
}

export const UserGallery = ({
  editable,
  userPhoto,
  profilePhotos
}: IUserGalleryProps) => {
  const [isPreviewPhotoOpen, setPreviewPhotoStatus] = useState(false);
  const [isDeleteModalOpen, setDeleteModalStatus]   = useState(false);
  const [updateUserAvatar]                          = useUpdateUserAvatarMutation();
  const [updateProfilePhotos]                       = useUpdateUserProfilePhotosMutation();
  const [deleteUserAvatar]                          = useDeleteUserAvatarMutation();
  const [deleteUserProfilePhoto]                    = useDeleteUserProfilePhotoMutation();


  const uploadAvatar = useCallback(
    (event) => {
      const file = event.target.files[0];
      const isFileTypeAllowed = PHOTO_ALLOWED_TYPES.indexOf(file?.type) >= 0;

      if (isFileTypeAllowed) {
        const formData = new FormData();

        formData.append('profilePicture', file);
        formData.append('originalFilename', file.name);
        updateUserAvatar(formData);
      } else {
        Notification.enqueueSnackbar(INVALID_PHOTOS_TYPE_MSG, 'error');
      }
    },
    [updateUserAvatar]
  );

  const uploadProfilePhotos = useCallback(
    (event) => {
      const files = event.target.files;

      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i])
      }

      updateProfilePhotos(formData);

      event.target.value = '';
    },
    [updateProfilePhotos]
  );

  const deleteAvatar = useCallback(
    () => {
    // Modify url to only include file path of photo
      const splitUrl        = userPhoto.split('/');
      const photoPathtIndex = splitUrl.indexOf('profile-pictures');
      const photoPath       = splitUrl.slice(photoPathtIndex + 1).join('/');

      deleteUserAvatar({fileLinks: [photoPath]})

      setDeleteModalStatus(false);
    },
    [userPhoto, deleteUserAvatar]
  )
    

  const deleteProfilePhoto = useCallback(
    (photoPath: string) => {
      deleteUserProfilePhoto({fileLinks: [photoPath]});
    },
    [deleteUserProfilePhoto]
  );

  /**
   * This may break if our path convention changes
   */
  // const isThirdPartyAvatar = useCallback(
  //   () => {
  //     if (userPhoto.includes('profile-pictures') && userPhoto.includes('uploads')) {
  //       return false;
  //     }
  //     return true;
  //   },
  //   [userPhoto]
  // );

  const UploadAvatarComponent = useMemo(
    () => (children: ReactNode) => (
      <UserGallery.Input htmlFor="avatar-upload-btn">
        <input
          id       = "avatar-upload-btn"
          type     = "file"
          onChange = {uploadAvatar}
          accept   = "image/jpeg, image/png"
          multiple = {false}
        />

        {children}
      </UserGallery.Input>
    ),
    [uploadAvatar]
  );

  const UploadPhotosComponent = useMemo(
    () => () => (
      <label htmlFor="profile-photos-upload-btn">
        <Input
          id         = "profile-photos-upload-btn"
          type       = "file"
          inputProps = {{ accept: "image/jpeg, image/png", multiple: true }}
          sx         = {{ display: 'none' }}
          onChange   = {uploadProfilePhotos}
        />

        <AddPhotosButton
          startIcon = {<PlusIcon />}
          variant   = "outlined"
          component = "span"
        >
          Add Photos
        </AddPhotosButton>
      </label>
    ),
    [uploadProfilePhotos]
  );

  const EmptyPhoto = (
    <UserGallery.UploadPhoto>
      <ProfileIcon />
      {editable && <span>Upload Photo</span>}
    </UserGallery.UploadPhoto>
  );

  const AvatarComponent = () => {
    if (userPhoto) {
      return (
        <>
        <AvatarButton src={userPhoto}>
          {editable && UploadAvatarComponent(
            <div>Edit Photo</div>
            )}
        </AvatarButton>
          {
          /**
           * Uncomment to enable avatar photo deletion
           */
          
          /* {editable &&  !isThirdPartyAvatar() && 
            <DeleteButton 
              className = 'delete'
              onClick   = {() => setDeleteModalStatus(true)}
            >
                X
            </DeleteButton>
          } */}
        </>
      );
    }
    if (editable) {
      return UploadAvatarComponent(EmptyPhoto);
    }
    return EmptyPhoto;
  };

  return (
    <UserGallery.Wrapper editable={editable}>
      <Grid
        container
        columns = {11}
        sx      = {{ display: { xs: 'none', sm: 'flex' } }}
      >
        <Grid
          xs={2}
          container
        >
          <UserGallery.Placeholder
            item
            xs = {12}
          >
            <UserGallery.Photo src={getProfilePhotoUrl(profilePhotos?.[0]?.fileLink ?? '')} hideLeftBorder />
          </UserGallery.Placeholder>

          <UserGallery.Placeholder
            item
            xs = {6}
          >
            <UserGallery.Photo src={getProfilePhotoUrl(profilePhotos?.[1]?.fileLink ?? '')} hideLeftBorder />
          </UserGallery.Placeholder>

          <UserGallery.Placeholder
            item
            xs
          >
            <UserGallery.Photo src={getProfilePhotoUrl(profilePhotos?.[2]?.fileLink ?? '')} />
          </UserGallery.Placeholder>
        </Grid>

        <Grid
          item
          container
          xs        = {2}
          direction = "column"
        >
          <UserGallery.Placeholder
            item
            xs          = {12}
            borderLeft  = {1}
            borderRight = {1}
          >
            <UserGallery.Photo src={getProfilePhotoUrl(profilePhotos?.[3]?.fileLink ?? '')} />
          </UserGallery.Placeholder>
        </Grid>

        <Grid
          item
          xs={2}
          container
        >
          <UserGallery.Placeholder
            item
            xs           = {12}
          >
            <UserGallery.Photo src={getProfilePhotoUrl(profilePhotos?.[4]?.fileLink ?? '')} />
          </UserGallery.Placeholder>
          <UserGallery.Placeholder
            item
            xs          = {12}
          >
            <UserGallery.Photo src={getProfilePhotoUrl(profilePhotos?.[5]?.fileLink ?? '')} />
          </UserGallery.Placeholder>
        </Grid>

        <Grid
          item
          container
          xs        = {2}
          direction = "column"
        >
          <UserGallery.Placeholder
            item
            xs          = {12}
          >
            <UserGallery.Photo src={getProfilePhotoUrl(profilePhotos?.[6]?.fileLink ?? '')} />
          </UserGallery.Placeholder>
        </Grid>

        <Grid
          item
          xs={2}
          container
        >
          <UserGallery.Placeholder
            item
            xs           = {12}
          >
            <UserGallery.Photo src={getProfilePhotoUrl(profilePhotos?.[7]?.fileLink ?? '')} />
          </UserGallery.Placeholder>
          <UserGallery.Placeholder
            item
            xs          = {6}
          >
            <UserGallery.Photo src={getProfilePhotoUrl(profilePhotos?.[8]?.fileLink ?? '')} />
          </UserGallery.Placeholder>
          <UserGallery.Placeholder
            item
            xs
          >
            <UserGallery.Photo src={getProfilePhotoUrl(profilePhotos?.[9]?.fileLink ?? '')} />
          </UserGallery.Placeholder>
        </Grid>

        <Grid
          item
          container
          xs        = {2}
          direction = "column"
        >
          <UserGallery.Placeholder
            item
            xs         = {12}
            borderLeft = {1}
          >
            <UserGallery.Photo src={getProfilePhotoUrl(profilePhotos?.[10]?.fileLink ?? '')} />
          </UserGallery.Placeholder>
        </Grid>
      </Grid>

      <Grid container sx={{ display: { xs: 'flex', sm: 'none' } }}>
        <UserGallery.Placeholder
          item
          xs           = {12}
          borderBottom = {1}
        >
          <UserGallery.Photo src={getProfilePhotoUrl(profilePhotos?.[0]?.fileLink ?? '')} />
        </UserGallery.Placeholder>
        <UserGallery.Placeholder
          item
          xs          = {6}
        >
          <UserGallery.Photo src={getProfilePhotoUrl(profilePhotos?.[1]?.fileLink ?? '')} />
        </UserGallery.Placeholder>
        <UserGallery.Placeholder
          item
          xs         = {6}
        >
          <UserGallery.Photo src={getProfilePhotoUrl(profilePhotos?.[2]?.fileLink ?? '')} />
        </UserGallery.Placeholder>
      </Grid>

      {editable && UploadPhotosComponent()}

      {AvatarComponent()}

      {profilePhotos.length > 0 && <PreviewButton
        variant   = "outlined"
        component = "span"
        onClick   = {() => setPreviewPhotoStatus(true)}
      >
        View Photos
      </PreviewButton>}

      {isPreviewPhotoOpen && (
        <PhotoPreview
          photos             = {profilePhotos}
          onClose            = {() => setPreviewPhotoStatus(false)}
          selectedPhotoIndex = {0}
          urlGeneratorFn     = {getProfilePhotoUrl}
          onDelete           = {deleteProfilePhoto}
          editable           = {editable}
        />
      )}

      {
        isDeleteModalOpen && (
          <ConfirmationModal
            onSubmit               = {deleteAvatar}
            onCancel               = {() => setDeleteModalStatus(false)}
            title                  = "Are you sure you want to delete this photo?"
            submitButtonText       = "Delete"
            cancelButtonText       = "Cancel"
          />
      )}

    </UserGallery.Wrapper>
  );
}

UserGallery.Photo = styled.div<{src: string, hideLeftBorder?: boolean}>`
  background-image    : ${({ src }) => `url(${src})`};
  background-size     : cover;
  background-position : center;
  border              : 3px solid white;
  height              : calc(100% - 3px);
  width               : calc(100% - 3px);

  border-left: ${({ hideLeftBorder })=> hideLeftBorder ? "3px solid transparent" : "3px solid #fff"};
`;

UserGallery.Input = styled.label`
  margin-bottom: 0;

  input {
    display: none;
  }
`;

UserGallery.Placeholder = styled(Grid)<{borderTop?: 1 | 0, borderRight?: 1 | 0, borderBottom?: 1 | 0, borderLeft?: 1 | 0}>`
  background-color : ${({ theme: { colors: { neutralGrey } } }) => neutralGrey['500']};
  height           : 150px;

  &.MuiGrid-root{
    border-top   : ${({ borderTop }) => borderTop && '2px solid #fff'};
    border-right : ${({ borderRight }) => borderRight && '2px solid #fff'};
    border-bottom: ${({ borderBottom }) => borderBottom && '2px solid #fff'};
    border-left  : ${({ borderLeft }) => borderLeft && '2px solid #fff'};
  }
`;

UserGallery.Placeholder = styled(Grid)<{borderTop?: 1 | 0, borderRight?: 1 | 0, borderBottom?: 1 | 0, borderLeft?: 1 | 0}>`
  height: 150px;
  border: none;

  &:last-child ${UserGallery.Photo} {
    border-right: none;
  }
`;

const AddPhotosButton = styled(Button)`
  top : 20px;
  left: 20px;

  ${ ({ theme: { mediaQueries: {md} } }) => md } {
    top : 43%;
    left: 7%;
  }

  &.MuiButton-root, &.MuiButton-root:hover {
    position: absolute;
  }
` as any;

const PreviewButton = styled(Button)`
  top   : 20px;
  right : 20px;

  ${ ({ theme: { mediaQueries: {md} } }) => md } {
    top   : 43%;
    right : 7%;
  }

  &.MuiButton-root, &.MuiButton-root:hover {
    position: absolute;
  }
` as any;

UserGallery.UploadPhoto = styled.div`
  ${({ theme: {colors: {
    primary: { white, rubineLight }, neutralGrey
  }}}) => css`
    background-color: ${white};

    svg {
      width      : 160px;
      height     : 160px;
      margin-left: -10px;
      margin-top : -10px;

      ${ ({ theme: { mediaQueries: {md} } }) => md } {
        width : 180px;
        height: 180px;
      }
    }

    path {
      fill: ${neutralGrey[300]};
    }

    span {
      position : absolute;
      color    : ${rubineLight};
      top      : 80px;
      left     : 16px;
      font-size: 16px;

      ${({ theme: {mediaQueries: {md}}}) => md } {
        top : 90px;
        left: 26px;
      }
    }
  `};
`;

// const DeleteButton = styled.button`
// ${({ 
//   theme: {
//     colors      : {primary: {white}},
//     mediaQueries: {sm}
//   }}) => css`
//     position        : absolute;
//     top             : 80px;
//     left            : calc(50% + 4rem);
//     z-index         : 2;
//     background-color: rgb(204, 45, 45);
//     color           : ${white};
//     font-weight     : 600;
//     cursor          : pointer;
//     visibility      : hidden;
//     border-radius   : 100%;
//     height          : 28px;
//     width           : 28px;

//     &:hover {
//       visibility: visible;
//     }
  
//     ${sm} {
//       left      : calc(50% + 6vw);
//       visibility: visible;
//     }
// `}`

const AvatarButton = styled.div<{src?: string}>`
  ${({
    theme: { colors: {neutralGrey, primary: {white}}},
    src
  }) => css`
    border             : 4px solid white;
    overflow           : hidden;
    background-size    : cover;
    background-position: center;
    text-align         : center;
    background-image   : url(${src});
    background-color   : ${neutralGrey[400]};
    display            : flex;
    align-items        : center;

    &:hover + button {
      visibility: visible;
    }

    div {
      width           : 100%;
      height          : 100px;
      position        : absolute;
      top             : 95px;
      background-color: rgba(0, 0, 0, 0.4);
      color           : ${white};
      padding-top     : 6px;
      font-weight     : 500;
      cursor          : pointer;
      transition      : top 0.3s;
      font-size       : 16px;

      ${({ theme: { mediaQueries: {md}}}) => md } {
        top: 115px;
      }
    }

    div:hover {
      transition: top 0.3s;
      top       : 85px;

      ${({ theme: { mediaQueries: {md} }}) => md } {
        top: 105px;
      }
    }
`}`;

UserGallery.Wrapper = styled.div<{editable: boolean}>`
  position           : relative;
  font-weight        : 700;
  background-size    : cover, auto;
  background-position: 0% 100%, 0px 0px;
  background-repeat  : no-repeat, repeat;
  background-image   : url(${TemporaryBG}), ${({ theme }) => theme.colors.gradientRadial.rubinePurple};

  ${({editable}) => editable && css`
    ${AddPhotosButton}:hover, ${UserGallery.UploadPhoto}:hover {
      cursor    : pointer;
      box-shadow: 0 4px 16px 0 rgb(0 0 0 / 16%);
      transform : scale(1.03);
    }
  `}

  ${AvatarButton}, ${UserGallery.UploadPhoto} {
    position     : absolute;
    top          : calc(50% - 70px);
    left         : calc(50% - 70px);
    border-radius: 100%;
    width        : 140px;
    height       : 140px;

    ${ ({ theme: { mediaQueries: {md} } }) => md } {
      width : 160px;
      height: 160px;
      top   : calc(50% - 80px);
    }
  }
`;
