import { Checkbox, FormControlLabel }       from '@mui/material';
import { Control, Controller, Path }        from 'react-hook-form';
import styled, { css }                      from 'styled-components';
import { ReactComponent as EmptyCheckbox }  from '../../images/Empty-Light-Checkbox.svg';
import { ReactComponent as FilledCheckbox } from '../../images/Filled-Light-Checkbox.svg';

interface ICheckboxFormField<T> {
  label  : string;
  name   : Path<T>;
  control: Control<T, object>;
}

export const CheckboxFormField = <T, >({
  control,
  label,
  name
}: ICheckboxFormField<T>) => {
  return (
    <Controller
      name         = {name}
      control      = {control}
      render       = {({ field }) => (
        <CheckboxFormField.Checkbox
          control={
            <Checkbox
              {...field}
              onChange    = {field.onChange}
              checked     = {Boolean(field.value)}
              icon        = {<EmptyCheckbox />}
              checkedIcon = {<FilledCheckbox />}
            />
          }
          label={label}
        />
      )}
    />
  );
}

CheckboxFormField.Checkbox = styled(FormControlLabel)`
  ${({theme: {fontSizes: {m}, colors: {type: {medium}}}}) => css`
    .MuiFormControlLabel-label {
      font-size: ${m};
      color    : ${medium};
    }
  `}
`;