import styled, { css } from 'styled-components';
import { AppTooltip }  from '../AppTooltip';

const INTENSITY_TOOLTIP_OPTIONS: {value: number, name: string, options: string}[] = [{
  value  : 5,
  name   : 'Race',
  options: '3.5+',
}, {
  value  : 4,
  name   : 'Hard',
  options: '3.0-3.5',
}, {
  value  : 3,
  name   : 'Moderate',
  options: '2.5-3.0',
}, {
  value  : 2,
  name   : 'Tempo',
  options: '2.0-2.5',
}, {
  value  : 1,
  name   : ' Social',
  options: '1.0-2.0',
}];

export const IntensityTooltip = () => {
  return (
    <AppTooltip 
      withArrow 
      tooltipText={
        <IntensityTooltip.Text>
          {INTENSITY_TOOLTIP_OPTIONS.map(option => (
            <div key={option.value}>
              <IntensityTooltip.LargeText>{option.value}</IntensityTooltip.LargeText>
              /5 
              <IntensityTooltip.WhiteText>&nbsp; &nbsp;{option.name}</IntensityTooltip.WhiteText>
              {`/${option.options} w/kg`}
            </div>
          ))}
        </IntensityTooltip.Text>
      }
    />
  )
}

IntensityTooltip.Text = styled.div`
  ${({theme: {fontSizes: { s }, colors: {type: { light }}}}) => css`
    font-size: ${ s };
    font-size: 10px;
    color    : ${ light };
    padding  : 20px 10px;
  `}
`;

IntensityTooltip.WhiteText = styled.span`
  color: ${({theme: {colors: {primary: { white }}}}) => white};
`;

IntensityTooltip.LargeText = styled(IntensityTooltip.WhiteText)`
  ${({theme: {fontSizes: { l }}}) => css`
    font-size  : ${ l };
    font-weight: 700;
  `}
`;