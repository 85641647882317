import {
  ClipboardEvent,
  MouseEvent,
  ChangeEvent
}                         from 'react';
import {
  OGAPIResponse
}                         from 'getstream';
import {
  ImagePreviewer,
  ImageUpload
}                         from 'react-file-utils';
import styled             from 'styled-components';
import {
  Chip,
  TextField,
}                         from '@mui/material';
import { IConnection }    from '@modules/Profile/models/Community';
import { Avatar }         from '@components/Avatar';
import { Loader }         from '@components/Loader';
import { LinkPreview }    from '../../../LinkPreview';

interface IMainProps {
  onPaste                : (event: ClipboardEvent<HTMLDivElement>) => Promise<void>;
  isLoadingOg            : boolean;
  handleRemovePreviewLink: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  displayName            : string;
  selectedLocation       : MapboxGeocoder.Result | null;
  handleInput            : (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  showImagePreviewer     : boolean;
  text                   : string;
  imageUploads           : ImageUpload[];
  mentions               : IConnection[];
  handleRemoveImage      : (id: string) => void;
  handleClickMention     : (user: IConnection) => void;
  handleRemoveMention    : (id: string) => void;
  handleFiles            : (files: File[]) => void;
  photoUrl?              : string;
  ogData?                : OGAPIResponse;
}

export const Main = ({
  onPaste,
  isLoadingOg,
  ogData,
  handleRemovePreviewLink,
  photoUrl,
  handleInput,
  displayName,
  selectedLocation,
  showImagePreviewer,
  mentions,
  handleClickMention,
  handleRemoveImage,
  handleRemoveMention,
  handleFiles,
  text,
  imageUploads,
}: IMainProps) => (
  <Main.Wrapper>
    <Main.Profile>
      <Avatar photoUrl={photoUrl} size={40} />
      <strong>{displayName}</strong>

      {selectedLocation && (
        <>
          <span>in</span>
          <strong>{selectedLocation.text}</strong>
        </>
      )}
    </Main.Profile>

    <TextField
      name        = "text"
      placeholder = "What's on your mind?"
      type        = "url"
      minRows     = {7}
      onPaste     = {onPaste}
      onChange    = {handleInput}
      value       = {text}
      multiline
      fullWidth
    />

    {!!mentions.length && (
      <Main.Mentions>
        {mentions.map((user) => (
          <Chip
            key      = {user.id}
            label    = {`@${user.username}`}
            onClick  = {() => handleClickMention(user)}
            onDelete = {() => handleRemoveMention(user.id)}
          />
        ))}
      </Main.Mentions>
    )}

    {showImagePreviewer && (
      <ImagePreviewer
        imageUploads = {imageUploads}
        handleRemove = {handleRemoveImage}
        handleFiles  = {handleFiles}
        multiple
      />
    )}

    {isLoadingOg && <Loader />}
    {ogData && <LinkPreview data={ogData} onRemove={handleRemovePreviewLink} />}
  </Main.Wrapper>
);

Main.Wrapper = styled.div`
  .rfu-image-previewer {
    margin-top: 20px;
  }
`;

Main.Profile = styled.div`
  display      : flex;
  align-items  : center;
  gap          : 5px;
  margin-bottom: 15px;

  div {
    margin-right: 10px;
  }
`;

Main.Mentions = styled.div`
  margin-top: 15px;
  display   : flex;
  gap       : 10px;
  flex-wrap : wrap;
`;

Main.TextField = styled(TextField)`
  white-space: pre-wrap;
`;
