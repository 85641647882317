import { useMemo }                from 'react';
import { 
  useDeleteUserConnectionMutation, 
  useDeleteUserConnectionsMutation, 
  useGetUserConnectionsQuery 
}                                 from '@modules/Profile/queries';
import { Link }                   from '@mui/material';
import { useHistory }             from 'react-router';
import styled                     from 'styled-components';
import { IConnection }            from '@modules/Profile/models/Community';
import { IConfigCell, TableData } from '@components/TableData';
import { Avatar }                 from '@components/Avatar';
import { getProfilePhotoUrl }     from '@utils/files_utils';
import { useTable }               from '@utils/hooks/useTable';

const Title = styled.div`
  display    : flex;
  align-items: center;

  a {
    margin-left: 15px;
  }
`;

export const ConnectionsTable = () => {
  const [deleteConnection]  = useDeleteUserConnectionMutation();
  const [deleteConnections] = useDeleteUserConnectionsMutation();
  const { push }            = useHistory();
  const {
    orderBy,
    order,
    onSort,
    onSelectRow,
    selectedItems,
    onSelectAll,
    pageSize,
    pageNumber,
    setPageNumber,
    searchText,
    debouncedSearchParam,
    setSearchText,
  }                        = useTable({order: 'asc'});

  const { 
    data,
    isLoading,
  }  = useGetUserConnectionsQuery({
    pageNumber,
    pageSize,
    search        : Number(debouncedSearchParam?.length) >= 2 ? debouncedSearchParam : undefined,
    sortColumn    : orderBy,
    sortDirection : order
  }, { pollingInterval: 10000 });

  const configCells = useMemo<IConfigCell<IConnection>[]>(
    () => [
      {
        label  : 'Name',
        orderBy: 'name',
        render : ({ firstName, lastName, profilePictureUrl, username }) => (
          <Title>
            <Avatar
              photoUrl = {profilePictureUrl && getProfilePhotoUrl(profilePictureUrl)}
              name     = {firstName}
              size     = {40}
            />
    
            <Link onClick = {() => push(`/user/${username}`)}>
              {firstName} {lastName}
            </Link>
          </Title>
        ),
        width  : 350,
        isBold : true
      },
      {
        label  : 'Location',
        key    : 'locationName',
        orderBy: 'location',
        width  : 1300,
      },
    ],
    [push]
  );

  const rowActions = useMemo(
    () => [
      {
        label: 'Remove',
        onClick : ({ id }: IConnection) => {
          deleteConnection(id).then(() => onSelectRow(id));
        }
      }
    ],
    [deleteConnection, onSelectRow]
  );

  const tableActions = useMemo(
    () => [
      {
        label  : 'Remove',
        onClick : (ids: string[]) => {
          const userIds = (data?.content ?? [])
            .filter(connection => ids.includes(connection.id))
            .map(connection => connection.id)

            deleteConnections(userIds).then(() => onSelectAll([]));
        }
      },
    ],
    [data?.content, deleteConnections, onSelectAll]
  );

  return (
    <TableData
      configCells       = {configCells}
      data              = {data?.content || []}
      isLoading         = {isLoading}
      rowActions        = {rowActions}
      tableActions      = {tableActions}
      onSelectRow       = {onSelectRow}
      onSelectAll       = {onSelectAll}
      searchText        = {searchText}
      totalPages        = {data?.totalPages ?? 0}
      searchPlaceholder = "Name"
      setSearchText     = {setSearchText}
      selectedItems     = {selectedItems}
      setPageNumber     = {setPageNumber}
      order             = {order}
      orderBy           = {orderBy}
      onSort            = {onSort}
      title             = "Connections"
      totalElements     = {data?.totalElements ?? 0}
      emptyTable        = {() => (
        <ConnectionsTable.EmptyTable>
          <p>Add Some Connections</p>
        </ConnectionsTable.EmptyTable>
      )}
    />
  );
}

ConnectionsTable.EmptyTable = styled.div`
  width           : 100%;
  display         : flex;
  align-items     : center;
  justify-content : center;
  min-height      : 450px;
  background-color: #E8E8E7;
  margin-bottom   : 70px;

  p {
    color       : ${({ theme }) => theme.colors.primary.rubineLight};
    font-weight : 700;
  }
`;