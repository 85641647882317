import { WeatherCondition } from '@models/CalendarCardModel';

export const phoneRegex = /\+\d{11,}/gm;

export type ParsedNames = {
  displayName: string;
  firstName: string;
  lastName: string;
  isTuple: boolean;
};

export function parseNames(displayName: string): ParsedNames {
  displayName = displayName.trim().replace("+", " ");
  let names = displayName.split(" ");
  if (names.length === 2) {
    // We have first and last
    return {
      displayName: displayName,
      firstName: names[0],
      lastName: names[1],
      isTuple: true,
    };
  }
  return {
    displayName: displayName,
    firstName: displayName,
    lastName: "",
    isTuple: false,
  };
}

export function getWeatherConditionClassName(weatherCondition: number): string {
  let iconClass: string = "";
  const conditionString = WeatherCondition[weatherCondition];
  if (conditionString && conditionString.length > 0) {
    iconClass = conditionString.toLowerCase().replaceAll("_", "-");
  }
  return iconClass;
}

export function getWeatherConditionDisplayName(
  weatherCondition: number
): string {
  let displayName: string = "";
  displayName = WeatherCondition[weatherCondition]
    .toLowerCase()
    .replaceAll("_", " ");
  return displayName;
}

export function formatNumberWithCommas(numberWithoutCommas:number) {
  return numberWithoutCommas.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * @description Utility function to define conditional class names with less markup
 * @param  {Array} classes a comma-separated list of static classes and/or conditions
 * @return {String} returns a filtered string of classes
 * @example
 * classList(
 *  "basic-class-example",
 *  booleanCondition && "conditional-class-example"
 * )
 *
 */
export function classList(
  ...classes: (boolean | string | undefined)[]
): string {
  return classes.filter((className) => !!className).join(" ");
}

export function getFormatDuration(duration: number) {
  const hours     = (duration / 60);
  const rhours    = Math.floor(hours);
  const days      = rhours / 24;
  const rdays     = Math.floor(days);
  const leftHours = rhours - (rdays * 24);
  const minutes   = (hours - rhours) * 60;
  const rminutes  = Math.round(minutes);

  const formattedDays    = rdays >= 10 ? rdays : `0${rdays}`;
  const formattedHours   = leftHours >= 10 ? leftHours : `0${leftHours}`;
  const formattedMinutes = rminutes >= 10 ? rminutes : `0${rminutes}`;

  return `${formattedDays}:${formattedHours}:${formattedMinutes}`;
}
