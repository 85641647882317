import { createTheme }       from '@mui/material/styles';
import { scrimBlack, theme } from './theme';

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: theme.colors.primary.rubineDark,
    }
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          background: theme.colors.neutralGrey[400],
          border: 'none'
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        'InputProps': {
          disableUnderline: true
        },
      },
      styleOverrides: {
        root: {
          borderTop   : `1px solid ${theme.colors.neutralGrey[500]}`,
          borderRadius: '4px',
          border      : 'none',
          fontFamily  : 'Inter, sans-serif',

          '.MuiFilledInput-root': {
            boxShadow: `inset 0 1px 0 0 ${scrimBlack(0.08)}`,
            border   : 'none',

            '&.Mui-focused': {
              boxShadow: '0 0 0 2px #24272a'
            },

            '&.Mui-error': {
              boxShadow: `0 0 0 2px ${theme.colors.messaging.red}`,
            }
          },

          'input': {
            fontWeight     : 'bold',
            backgroundColor: '#DFDFDE',
            fontSize       : theme.fontSizes.m,
            marginTop      : '1px'
          },

          'textarea': {
            lineHeight  : '20px',
            fontSize    : theme.fontSizes.m,
            fontWeight  : 'bold',
            paddingRight: '5px',
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border       : '1px solid #484850',
            borderRadius : '5px 5px 0 0'
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.MuiAutocomplete-inputRoot': {
            backgroundColor: '#DFDFDE',
            fontSize       : `${theme.fontSizes.m}!important`,
            input: {
              fontWeight     : 'bold',
              backgroundColor: '#DFDFDE',
              fontSize       : `${theme.fontSizes.m}!important`,
              marginTop      : '1px'
            },
          }
        },
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderTop      : `1px solid ${theme.colors.neutralGrey[500]}`,
          borderRadius   : '4px',
          backgroundColor: '#DFDFDE !important',
          fontFamily     : 'Inter',

          '&:before': {
            borderBottom: 'none !important'
          },

          '&:after': {
            borderBottom: 'none !important'
          }
        },
        input: {
          outline     : 'none',
          border      : 'none',
          position    : 'relative',
          height      : '34px',
          padding     : '15px 12px 0 17px',
          borderRadius: '4px',
          boxShadow   : 'none',
          fontSize    : '18px',
          lineHeight  : '14px',
          transition  : 'all 0.3s ease-in-out'
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transition : 'all 0.2s ease-in',
          textAlign  : 'left',
          fontSize   : '15px',
          marginLeft : '5px',

          '&': {
            fontWeight: 'bold',
            color     : theme.colors.type.medium
          },

          '&.Mui-focused': {
            color: theme.colors.type.medium
          },

          '&.Mui-error': {
            color: theme.colors.type.medium
          }
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          backgroundColor : `${theme.colors.primary.white}`,
          border          : 'none',
          boxShadow       : '0 4px 8px 0 rgb(0 0 0 / 4%), 0 1px 2px 0 rgb(0 0 0 / 4%)',

          '&:hover': {
            backgroundColor : theme.colors.primary.white,
            boxShadow       : '0 4px 16px 0 rgb(0 0 0 / 16%)',
            border          : 'none',
            transform       : 'scale(1.03)'
          },

          '&': {
            fontWeight: 'bold',
          }
        },
        containedPrimary: {
          color          : theme.colors.primary.white,
          fontWeight     : 700,
          backgroundImage: `linear-gradient(180deg, ${theme.colors.primary.rubineLight}, ${theme.colors.primary.rubineDark})`,

          '&:hover': {
            backgroundColor : theme.colors.primary.rubineLight,
            boxShadow       : '0 4px 16px 0 rgb(0 0 0 / 16%)',
            transform       : 'scale(1.03)'
          },

          '&.Mui-disabled': {
            opacity: 0.32,
            color  : theme.colors.primary.white,
          }
        },
        containedSecondary: {
          backgroundImage: 'linear-gradient(180deg, #e5e5e4, #bbb)',
          color          : theme.colors.type.medium,
          fontWeight     : 700,
          boxShadow      : '0 4px 8px 0 rgb(0 0 0 / 4%), 0 1px 2px 0 rgb(0 0 0 / 4%)',

          '&:hover': {
            boxShadow: '0 4px 16px 0 rgb(0 0 0 / 16%)',
            transform: 'scale(1.03)'
          }
        },
        textPrimary: {
          '&': {
            fontWeight: '700',
          },
          '&:hover': {
            backgroundColor: 'unset',
            filter         : 'brightness(72%)'
          }
        },
        textSizeMedium: {
          fontSize: theme.fontSizes.l
        },
        textSizeLarge: {
          fontSize: theme.fontSizes.xl
        },
        textSizeSmall: {
          fontSize: theme.fontSizes.m
        },
        sizeSmall: {
          maxHeight: '32px'
        },
        root: {
          borderRadius  : '24px',
          color         : `${theme.colors.primary.rubineLight}`,
          textTransform : 'initial',
          fontFamily    : 'Inter, sans-serif',
          justifyContent: 'unset',
          padding       : '8px 25px',
          transition    : 'all 200ms ease-in-out',
          '@media screen and (max-width: 320px)': {
            padding       : '8px 10px',
            justifyContent: 'center'
          }
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorSecondary: {
          backgroundImage: 'linear-gradient(180deg, #e5e5e4, #bbb)',
          color          : theme.colors.type.medium,
          boxShadow      : '0 4px 8px 0 rgb(0 0 0 / 4%), 0 1px 2px 0 rgb(0 0 0 / 4%)',

          '&:hover': {
            boxShadow: '0 4px 16px 0 rgb(0 0 0 / 16%)',
            transform: 'scale(1.03)'
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderTop    : `1px solid ${theme.colors.neutralGrey[500]}`,
          borderRadius : '4px',
        },
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
        },
        filled: {
          outline     : 'none',
          border      : 'none',
          position    : 'relative',
          borderRadius: '4px',
          boxShadow   : 'none',
          fontSize    : '18px',
          lineHeight  : '14px',
          fontWeight  : 'bold',
          alignItems  : 'center',
          overflow    : 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace  : 'nowrap',
          padding     : '23px 32px 13px 17px',
          minHeight   : 'unset'
        },
        icon: {
          right: 0,
          left : 'calc(100% - 30px)'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&' : {
            fontWeight: '700'
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#DDDDDC',

          '&:not(.Mui-checked) + .MuiFormControlLabel-label': {
            color: theme.colors.type.medium
          }
        },
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          height        : '20px',
          outline       : 'none',
          border        : 'none',
          position      : 'relative',
          paddingTop    : '4px !important',
          paddingBottom : '10px !important',
          paddingLeft   : '11px !important',
          borderRadius  : '4px',
          boxShadow     : 'none',
          fontSize      : '18px',
          lineHeight    : '14px',
          transition    : 'all 0.3s ease-in-out',
          overflow      : 'hidden',
        }
      }
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          minWidth: '344px !important',
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color        : theme.colors.type.medium,
          fontWeight   : 700,
          textTransform: 'none',
          fontFamily   : 'Inter, sans-serif',

          '&:hover': {
            color: theme.colors.primary.rubineLight
          },

          '&.Mui-selected': {
            color: theme.colors.neutralBlue[300]
          },
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '.MuiTabs-indicator': {
            backgroundColor: theme.colors.neutralBlue[300],
            borderRadius   : '8px 8px 0 0',
            height         : '4px',
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius   : '8px',
          height         : '8px',
          backgroundColor: scrimBlack(0.08)
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor : '#FFFFFF',
          height          : '63px'
        },
        head: {
          height: '40px',
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&': {
            paddingTop    : '8px',
            paddingBottom : '8px',
            color         : '#00000099',
            fontSize      : '13px',
            lineHeight    : '100%',
            fontWeight    : '700',
            letterSpacing : '-0.25px',
            textDecoration: 'none',
            whiteSpace    : 'nowrap'
          },
          '&[data-value="ACCEPTED"]:hover': {
            color:theme.colors.type.dark,
          },
          '&[data-value="MAYBE"]:hover': {
            color:theme.colors.type.dark,
          },
          '&[data-value="DECLINED"]:hover': {
            color:theme.colors.type.dark,
          },
          '&:hover': {
            color          : theme.colors.primary.rubineLight,
            backgroundColor: 'transparent',

            '.dropdown-highlight': {
              backgroundColor: theme.colors.primary.rubineLight
            },

            '.MuiTouchRipple-root': {
              opacity   : '1',
            }
          },

          '.MuiTouchRipple-root': {
            opacity   : '0',
            transition: 'opacity 1s'
          },

          '&.Mui-selected': {
            color          : theme.colors.neutralBlue[300],
            backgroundColor: 'transparent',

            '.dropdown-highlight': {
              backgroundColor: theme.colors.neutralBlue[300],
            },

            '&:hover': {
              backgroundColor: 'transparent',

              '.dropdown-highlight': {
                backgroundColor: theme.colors.neutralBlue[300]
              }
            },

            '.MuiTouchRipple-root': {
              opacity   : '1',
            }
          }
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&:hover' : {
            color: theme.colors.primary.rubineLight,
          },
          '&.Mui-active' : {
            color: theme.colors.neutralBlue[400],

            '& > svg': {
              color    : `${theme.colors.neutralBlue[400]} !important`
            }
          },
          '&.Mui-active:hover' : {
            color: theme.colors.primary.rubineLight,

            '& > svg': {
              color    : `${theme.colors.primary.rubineLight} !important`
            }
          },
        },
        icon: {
          fontSize : '28px !important',
          color    : `${theme.colors.primary.rubineLight} !important`
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 2%)',
          borderRadius  : '8px',
          paddingTop    : '4px',
          paddingBottom : '4px'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          cursor        : 'pointer',

          '&:hover': {
            filter        : 'brightness(72%)',
            textDecoration: 'underline'
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: '0 8px 24px 0 rgb(0 0 0 / 8%), 0 16px 48px 0 rgb(0 0 0 / 16%)'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.colors.type.dark
        },
        arrow: {
          color: theme.colors.type.dark
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paperFullScreen: {
          padding: 0
        }
      }
    },
  },
});
