import { differenceInYears }             from 'date-fns';
import CakeIcon                          from '@mui/icons-material/Cake';
import HomeIcon                          from '@mui/icons-material/Home';
import EmojiPeopleIcon                   from '@mui/icons-material/EmojiPeople';
import { 
  IStatisticsMetrics, 
  IUserStatistics 
}                                        from '../../../components/UserStatisticsTabs/model/statistics';
import { kgToPounds }                    from '../../../utils/conversion_utils';
import { getDistance, getDistanceUnits } from '../../../utils/metrics_utils';
import { ReactComponent as CyclingIcon } from '../../../images/Icon-Sport-Cycling-Black.svg';
import { ReactComponent as HeightIcon }  from '../../../images/Icon-Height.svg';
import { ReactComponent as WeightIcon }  from '../../../images/Icon-Weight.svg';
import { ISettingsForm }                 from '../../Profile/models/Settings';
import { 
  DISTANCE_UNITS, 
  MEASUREMENT_SYSTEMS, 
  PRONOUNS_OBJECT_OPTIONS, 
  PRONOUNS_POSESSIVE_OPTIONS, 
  PRONOUNS_SUBJECT_OPTIONS, 
  WEIGHT_UNITS 
}                                        from '../../Profile/utils/options';
import { 
  convertMetersToCm, 
  convertMetersToFeet, 
  convertMetersToInches 
}                                        from '../../../utils/commonFunctions';
import { DropdownOptionModel }           from '../../../components/Dropdown';

export type IUpperStatistics        = Partial<ISettingsForm> & Partial<IUserStatistics>;
export type PublicProfileStatistics = Omit<IStatisticsMetrics<IUpperStatistics>, 'label'>;

export const PUBLIC_PROFILE_UPPER_METRICS: PublicProfileStatistics[] = [{
  icon        : <HomeIcon />,
  key         : 'userRidesDistance',
  units       : getDistanceUnits,
  conversionFn: (units, value = 0) => getDistance(units, value as number).toString()
}, {
  icon        : <CyclingIcon />,
  key         : 'userRidesCount',
  units       : () => 'Rides',
  conversionFn: (_units, value = 0) => value?.toString(),
}, {
  icon: <EmojiPeopleIcon />,
  key : 'pronounSubject'
}, {
  icon        : <CakeIcon />,
  key         : 'birthdate',
  conversionFn: (_units, value) => value ? differenceInYears(new Date(), new Date(value as string)).toString(): ''
}, {
  icon        : <HeightIcon />,
  key         : 'height',
  units       : (units) => units === MEASUREMENT_SYSTEMS.IMPERIAL ? '': DISTANCE_UNITS.CM,
  conversionFn: (units, value) => {
    if (!value) {
      return '';
    }

    const height = value as number;
    if (units === MEASUREMENT_SYSTEMS.IMPERIAL) {
      return `${convertMetersToFeet(height)}'${convertMetersToInches(height)}''`;
    }
    return convertMetersToCm(height).toString();
  }
}, {
  icon        : <WeightIcon />,
  key         : 'weight',
  units       : (units) => units === MEASUREMENT_SYSTEMS.IMPERIAL ? WEIGHT_UNITS.POUNDS: WEIGHT_UNITS.KG,
  conversionFn: (units, value) => {
    const  weight = (units === MEASUREMENT_SYSTEMS.IMPERIAL) ? kgToPounds(value as number): value;
    return weight ? weight.toString().split('.')[0]                                       : '';
  }
}];

export const getPronounString = (statistics: IUpperStatistics) => {
  const result: string[] = [];
  const getOptionName = (options: DropdownOptionModel[], value = '') => {
    const optionName = options.find(options => options.value === value)?.name;
    optionName && result.push(optionName);
  }
  getOptionName(PRONOUNS_SUBJECT_OPTIONS, statistics.pronounSubject);
  getOptionName(PRONOUNS_OBJECT_OPTIONS, statistics.pronounObject);
  getOptionName(PRONOUNS_POSESSIVE_OPTIONS, statistics.pronounPossessive);
  return result.join(', ');
}