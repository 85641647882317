
import { DropdownOptionModel } from '@components/Dropdown';
import {
  EFrequencyType,
  ELocationType,
  ERideTypes
}                              from '../../Details/models/ride';

export const RIDE_TYPES_OPTIONS: DropdownOptionModel[] = [
  { name: 'Adaptive', value: ERideTypes.ADAPTIVE },
  { name: 'Bike Packing', value: ERideTypes.BIKE_PACKING },
  { name: 'Cyclocross', value: ERideTypes.CROSS },
  { name: 'Gravel', value: ERideTypes.GRAVEL },
  { name: 'MTB', value: ERideTypes.MTB },
  { name: 'Recumbent', value: ERideTypes.RECUMBENT },
  { name: 'Road', value: ERideTypes.ROAD },
  { name: 'Touring', value: ERideTypes.TOURING },
  { name: 'Track', value: ERideTypes.TRACK },
  { name: 'Triathlon', value: ERideTypes.TRIATHLON },
  { name: 'Urban', value: ERideTypes.URBAN },
  { name: 'Other', value: ERideTypes.OTHER },
];

export const RIDE_DROP_OPTIONS: DropdownOptionModel[] = [{
  name : 'Drop',
  value: 'true'
}, {
  name : 'No Drop',
  value: 'false'
}];

export const LOCATION_TYPE_OPTIONS: DropdownOptionModel[] = [{
  name : 'Outdoor',
  value: ELocationType.OUTDOOR,
}, {
  name : 'Indoor',
  value: ELocationType.INDOOR,
}, {
  name : 'Virtual',
  value: ELocationType.VIRTUAL,
}];

export const PRIVACY_OPTIONS: DropdownOptionModel[] = [{
  name: 'Private',
  value: 'false'
}, {
  name: 'Public',
  value: 'true'
}];

export const INTENSITY_OPTIONS: DropdownOptionModel[] = [{
  name : '5 - Race 3.5+ w/kg',
  value: '5'
}, {
  name : '4 - Hard 3.0-3.5 w/kg',
  value: '4'
}, {
  name : '3 - Moderate 2.5-3.0 w/kg',
  value: '3'
}, {
  name : '2 - Tempo 2.0-2.5 w/kg',
  value: '2'
}, {
  name : '1 - Social 1.0-2.0 w/kg',
  value: '1'
}];

export const FREQUENCY_OPTIONS: DropdownOptionModel[] = [{
  name : 'Once',
  value: EFrequencyType.ONCE
}, {
  name : 'Weekly',
  value: EFrequencyType.WEEKLY
}];
