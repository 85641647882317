import { ReactElement }                     from 'react';
import { Grid, styled }                     from '@mui/material';
import { useCta }                           from '@utils/hooks/useCta';
import { ECtaTab }                          from '@modules/CreateModal/models/CreateModalContent';
import { CreateButton }                     from '@components/CreateButton';
import { ReactComponent as InviteIcon }     from '@images/Icon-Invite-Friend-Rubine.svg';
import { ReactComponent as ConnectionIcon } from '@images/Icon-Add-Connection-Rubine.svg';
import { ReactComponent as CyclingIcon }    from '@images/Icon-Sport-Cycling-Rubine.svg';

interface ICreateMenuButton {
  label      : string;
  icon       : ReactElement;
  contentName: ECtaTab;
}

const menuButtons: ICreateMenuButton[] = [{
  label      : 'Create a Ride',
  icon       : <CyclingIcon />,
  contentName: ECtaTab.CREATE_RIDE,
}, {
  label      : 'Add Connections',
  icon       : <ConnectionIcon />,
  contentName: ECtaTab.ADD_CONNECTIONS,
}, {
  label      : 'Invite Friends',
  icon       : <InviteIcon />,
  contentName: ECtaTab.INVITE_FRIENDS,
}]

export const CreateContentMenu = () => {
  const { openCta } = useCta();

  return (
    <CreateContentMenu.Container container rowSpacing="20px" margin="auto">
      {menuButtons.map(button => (
        <Grid key={ button.label } item container justifyContent="center" xs={ 12 }>
          <CreateButton
            label         = {button.label}
            icon          = {button.icon}
            onHandleClick = {() => openCta({ tab: button.contentName })}
          />
        </Grid>
      ))}
    </CreateContentMenu.Container>
  )
}

CreateContentMenu.Container = styled(Grid)`
  .MuiButton-root {
    width     : 300px;
    flex-grow : initial;
  }
`;
