import { Button, Grid }                  from '@mui/material';
import styled                            from 'styled-components';
import axios                             from 'axios';
import { useState, useCallback }         from 'react';
import { ReactComponent as ChevronIcon } from '../../../../../../images/Icon-Chevron-R-Rubine.svg';
import { FilesUploader }                 from '../../../../../../components/FilesUploader';
import { apiOrigin }                     from '../../../../../../config/api_config';
import { callApi }                       from '../../../../../../utils/apiCaller';
import { getUploadingProgress }          from '../../../../../../utils/files_utils';
import { USER_ROUTES_URL }               from '../../../../queries';

const CancelToken = axios.CancelToken;
const source      = CancelToken.source();

const  ALLOWED_FILE_TYPES: Array<string> = ['.gpx', '.tcx'];
export const ROUTES_UPLOAD_URL           = `${apiOrigin}/api/v1/${USER_ROUTES_URL}`;

export const isRouteFileValid = (fileName: string): string => {
  const typeIndex = ALLOWED_FILE_TYPES.findIndex((allowedType: string) => fileName.toLowerCase().endsWith(allowedType.toLowerCase()));

  return typeIndex !== -1 ? '' : 'Must be TCX or GPX';
}

interface IRoutesUploader {
  isRideWithGpsConnected : boolean;
  handleImportRideWithGps: () => void;
  refetch                : () => void;
}

export const RoutesUploader = ({
  refetch,
  isRideWithGpsConnected,
  handleImportRideWithGps,
}: IRoutesUploader) => {
  const [uploadingProgress, setUploadingProgress] = useState<number>(0);
  const [uploadingError, setUploadingError]       = useState<boolean>();

  const uploadRouteData = useCallback(
    (file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', file.name);

      callApi(ROUTES_UPLOAD_URL, 'POST', formData, {
        onUploadProgress: (progressEvent) => {
          const percent = getUploadingProgress(progressEvent);
          setUploadingProgress(percent);
        },
        cancelToken: source.token
      })
        .then(
          () => refetch()
        )
        .catch(
          () => setUploadingError(true)
        ).finally(
          () => {
            setTimeout(() => {
              setUploadingProgress(0);
              setUploadingError(false);
            }, 1000);
          }
        );
    },
    [refetch]
  );

  return (
    <RoutesUploader.Container item xs={12} sm={6} container alignItems="center" flex-wrap="nowrap">
      <Grid item container xs={12} sm={6} flexDirection="column" alignItems="flex-start">
        <p>Import Route</p>

        Import route files (.gpx, .tcx) to add new routes to your ride plans.

        <Button
          size    = "small"
          endIcon = {<ChevronIcon />}
          onClick = {() => { window.open('https://gopreem.zendesk.com/hc/en-us/articles/5648515763355-Managing-Routes', '_blank') }}
        >
          How to Create a Route
        </Button>

        <Button
          size    = "small"
          endIcon = {<ChevronIcon />}
          onClick = {handleImportRideWithGps}
        >
          {isRideWithGpsConnected ? 'Import from Ride with Gps' : 'Connect to Ride with GPS'}
        </Button>
      </Grid>

      <Grid item container xs={12} sm={6} flexDirection="column" alignItems="flex-start">
        <div>
          <FilesUploader
            onUpload            = {uploadRouteData}
            progress            = {uploadingProgress}
            isError             = {uploadingError}
            cancelFn            = {source.cancel}
            validateExtensionFn = {isRouteFileValid}
            accept              = ".tcx, .gpx"
            multiple
          />
        </div>
      </Grid>
    </RoutesUploader.Container>
  )
}

RoutesUploader.Container = styled(Grid)`
  padding       : 24px;
  border-radius : 8px;
  font-weight   : 500;
  letter-spacing: -0.2px;
  text-align    : left;

  &.MuiGrid-root {
    margin: 30px 0;
  }

  ${({theme: {fontSizes: {s, m}, colors: {primary: {white}}}}) => `
    background-color: ${white};
    font-size       : ${s};

    p {
      font-weight: 700;
      font-size  : ${m};
    }
  `};

  ${({theme: {mediaQueries: {sm}}}) => sm} {
    ${({theme: {fontSizes: {xl, m} }}) => `
      font-size: ${m};

      p {
        font-size: ${xl};
      }
    `};
    
    &.MuiGrid-root {
      margin-top: 0;
    }

    & > .MuiGrid-item:first-child {
      padding-right: 14px;
    }

    & > .MuiGrid-item:last-child {
      padding-left: 14px;
    }
  }

  .MuiButton-text {
    margin-left : -24px;
    max-height  : fit-content;
  }
`
