import styled, { css }                      from 'styled-components';
import { format }                           from 'date-fns';
import { Button, Grid }                     from '@mui/material';
import { theme }                            from '@styles/theme';
import { ReactComponent as LocationIcon }   from '../../../../images/Icon-Location-Black.svg';
import { ReactComponent as DistanceIcon }   from '../../../../images/Icon-Metric-Distance-Black.svg';
import { ReactComponent as ElevationIcon }  from '../../../../images/Icon-Metric-Elevation-Black.svg';
import { ReactComponent as AttendanceIcon } from '../../../../images/Icon-Metric-Attendance-Black.svg';
import { ReactComponent as IntensityIcon }  from '../../../../images/Icon-Metric-Power-Intensity-Black.svg';
import { ReactComponent as TimeIcon }       from '../../../../images/Icon-Metric-Duration-Black.svg';

import {
  getDistance,
  getDistanceUnits,
  getElevation,
  getElevationMetrics,
  getTimeFromSeconds
}                                  from '@utils/metrics_utils';
import {
  getDateUTCFromISO,
  getTimeFormat,
}                                  from '@utils/time_utils';
import { Avatar }                  from '@components/Avatar';
import { IntensityTooltip }        from '@components/IntensityTooltip';
import { useGetUserSettingsQuery } from '../../../Profile/queries';
import { EFrequencyType }          from '../../models/ride';
import { useDetails }              from '../../context';
import { InvitationBanner }        from '../InvitationBanner';
import { RideRouteDetails }        from '../RideRouteDetails';
import { UserDetailsSection }      from '../sections/UserDetailsSection';
import { WeatherDetails }          from '../WeatherDetails';

const METRIC_ICON_SIZE = "16px";

export const RideDetailsInvite = () => {
  const { data: settings } = useGetUserSettingsQuery();
  const timeFormat         = getTimeFormat(settings?.timeType);
  const {
    rideDetails,
    routeDetails,
    nearestRideDetails,
  } = useDetails();

  interface IMetricCellProps {
    val      : string | React.SVGProps<SVGSVGElement>;
    title    : string;
    container: boolean;
    item     : boolean;
    xs       : number;
  }

  function MetricCell(props: IMetricCellProps) {
    return (
    <Grid item container xs direction="column" alignItems="center" spacing={0}
      style={{
        height      : '100%',
        borderRight : props.val !== 'Privacy' ? `1px solid ${theme.colors.neutralGrey[500]}` : '0px',
        padding     : '3px 0px 3px 0px',
      }}
    >
      <RideDetailsInvite.MetricValue>{props.val}</RideDetailsInvite.MetricValue>
      <RideDetailsInvite.MetricLabel>{props.title}</RideDetailsInvite.MetricLabel>
   </Grid>
   );
  }

  return (
    <Grid container width="100%" marginBottom="100px">
      <InvitationBanner
        isRideStarted          = {false}
        isPreview              = {false}
        organizerInfo          = {rideDetails?.organizer}
        title                  = {rideDetails?.name}
        when                   = {rideDetails?.date}
        attendeesCount         = {nearestRideDetails?.invitationStatistics?.accepted ?? 0}
      />
      <RideDetailsInvite.Container>
        <RideDetailsInvite.RideName item container>
          <Avatar photoUrl={rideDetails?.organizer?.profilePictureUrl} size={45} />
          <RideDetailsInvite.Name>
            {rideDetails?.name}
          </RideDetailsInvite.Name>
        </RideDetailsInvite.RideName>

        <Grid item container alignItems="center" xs={12}
          style={{
            borderTop    : '1px solid',
            borderBottom : '1px solid',
            borderColor  : theme.colors.neutralGrey[500],
            height       : '60px'
          }}
        >
          <MetricCell container item xs={2} val="Sun" title="Nov 6" />
          <MetricCell container item xs={2} val={format(getDateUTCFromISO(nearestRideDetails?.date ?? rideDetails?.date ?? ''), timeFormat)} title="Start" />
          <MetricCell container item xs={2}
            val={
              <div
                style   = {{ marginTop: -3, cursor: 'pointer' }}
                onClick = {() => (window.open("https://maps.google.com?q="+rideDetails?.startLocation.latitude+","+rideDetails?.startLocation?.longitude ))}
              >
                <LocationIcon width="18px" />
              </div>
            }
            title={rideDetails?.startLocation?.placeName ?? "Location"}
          />
          <MetricCell container item xs={2} val="Gravel" title="Ride" />
          <MetricCell container item xs={2} val="Privacy" title={!rideDetails?.isPublic ? 'Public' : 'Private'} />
        </Grid>
        <Grid item container>
          <RideRouteDetails
            isReccuringRideOverview = {rideDetails?.frequency === EFrequencyType.WEEKLY}
          />
        </Grid>
        <Grid
          item
          container
          alignItems    = "center"
          paddingBottom = "5px"
          spacing       = {1}
          marginLeft    = "0px"
          marginRight   = "5px"
          >
          
          {
            rideDetails?.duration &&
            (<Grid item xs="auto" container alignItems="center">
              <TimeIcon />
              <b>{getTimeFromSeconds(rideDetails?.duration)}</b>
              <RideDetailsInvite.Units>m</RideDetailsInvite.Units>
            </Grid>)
          }
            { // Ride Attendees
              !!nearestRideDetails?.attendeesCount && 
              (<Grid item xs="auto" container alignItems="center" paddingLeft="10px">
                <AttendanceIcon width={METRIC_ICON_SIZE} />
                <b>{nearestRideDetails?.attendeesCount}</b>
              </Grid>)
            }

            { // Route Distance
              routeDetails?.distance && 
              (<Grid item xs="auto" container alignItems="center">
                <DistanceIcon width={METRIC_ICON_SIZE} />

                <b>{getDistance(settings?.measurementSystem, routeDetails?.distance ?? 0)}</b>
                <RideDetailsInvite.Units>{getDistanceUnits(settings?.measurementSystem, routeDetails?.distance ?? 0)}</RideDetailsInvite.Units>
              </Grid>)
            }

            { // Elevation Gain
              routeDetails?.elevationGain && 
              (<Grid item xs="auto" container alignItems="center" paddingRight="10px">
                <ElevationIcon width={METRIC_ICON_SIZE}/>

                <b>{getElevation(settings?.measurementSystem, routeDetails?.elevationGain ?? 0)}</b>
                <RideDetailsInvite.Units>{getElevationMetrics(settings?.measurementSystem)}</RideDetailsInvite.Units>
              </Grid>)
            }

          <Grid item xs="auto" container alignItems="center" paddingRight="10px">
            <IntensityIcon width={METRIC_ICON_SIZE} />

            {rideDetails?.intensity ? (
              <>
                <b>{rideDetails?.intensity}</b>
                <RideDetailsInvite.Units>/5</RideDetailsInvite.Units>
              </>
            ) : '—'}

            <IntensityTooltip />
          </Grid>
        </Grid>
        <div style={{ borderBottom: `1px solid ${theme.colors.neutralGrey[500]}`}} />

        <WeatherDetails horizontal />
        <UserDetailsSection rideDetails={rideDetails} organizerInfo={rideDetails?.organizer}/>
        <RideDetailsInvite.ShareContainer>
          Want to invite a friend?
          <RideDetailsInvite.ShareButton
            size='small'
            variant='outlined'
            onClick={() => {
              if (navigator.share) {
                navigator.share({
                  title: rideDetails?.name,
                  text: rideDetails?.description,
                  url: window.location.href,
                });
              }
            }}
          >
            Send Ride Invite
          </RideDetailsInvite.ShareButton>
        </RideDetailsInvite.ShareContainer>
      </RideDetailsInvite.Container>
    </Grid>
  );
}

export const RideGalleryPhoto = styled.div`
  transition: all 200ms ease-in-out;
  cursor    : pointer;
  overflow  : hidden;
  &:hover {
    border-radius: 4px;
    box-shadow   : ${({theme: {colors: {primary: { rubineLight }}}}) => `0 0 0 3px ${rubineLight}`};
  }
  img {
    width : 64px;
    height: 64px;
  }
`;

RideDetailsInvite.RideType = styled.div`
  ${({theme: {colors: {type: { dark }}}}) => css`
    border       : 2px solid ${ dark };
    border-radius: 4px;
    font-size    : 10px;
    padding      : 0 10px;
    font-weight  : 600;
  `}
`;

RideDetailsInvite.Units = styled.span`
  ${({theme: {colors: {type: { medium }}, fontSizes: { s }}}) => css`
    color       : ${ medium };
    font-size   : ${ s };
    padding-left: 4px;
  `}
`;

RideDetailsInvite.InvitationIcon = styled.span`
  padding-right: 4px;
  display      : flex;
  
  svg {
    width : 20px;
    height: 20px;
  }
`;

RideDetailsInvite.RideName = styled(Grid)`
  font-size   : ${({theme: {fontSizes: { xl }}}) => xl};
  font-weight : bold;
  padding     : 15px;
  display     : flex;
  align-items : center;
`;

RideDetailsInvite.Name = styled.span`
  margin-left : 10px;
`;

RideDetailsInvite.MetricValue = styled.div`
  font-size   : 1em;
  font-weight : bold;
  height      : 25px;
`;

RideDetailsInvite.MetricLabel = styled.div`
  color              : ${({theme: {colors: {type: { medium }}}}) => medium};
  font-size          : ${({theme: {fontSizes: { s }}}) => s};
  font-weight        : medium;
  display            : -webkit-box;
  -webkit-line-clamp : 1;
  -webkit-box-orient : vertical;
  overflow           : hidden;
  word-break         : break-all;
  padding            : 0px 3px 0px 3px;
`;

RideDetailsInvite.Label = styled.div`
  font-size     : 11px;
  color         : ${({theme: {colors: {type: { medium }}}}) => medium};
  letter-spacing: 0.8px;
`;

RideDetailsInvite.LocationButton = styled(Button)`
  margin-left: -24px;
  margin-top : -6px;
`;

RideDetailsInvite.Photo = styled(RideGalleryPhoto)<{src: string}>`
  background-position: 0% 50%, 0px 0px;
  background-size    : cover,  auto;
  background-image   : ${({src}) => `url(${src})`};
  height             : 64px;
  width              : 64px;
`;

RideDetailsInvite.Container = styled.div`
  border        : 1px solid ${({ theme }) => theme.colors.neutralGrey[500]};
  border-radius : 30px;
  width         : 100%;
`;

RideDetailsInvite.ShareContainer = styled.div`
  font-size        : ${({theme: {fontSizes: { s }}}) => s};
  font-weight      : 500;
  padding          : 15px 0px 15px 15px;
  align-items      : center;
  background-color : ${({ theme }) => theme.colors.neutralGrey[200]};
  border-radius    : 0 0 30px 30px;
  border-top       : 1px solid ${({ theme }) => theme.colors.neutralGrey[500]};
`;

RideDetailsInvite.ShareButton = styled(Button)`
  color       : ${({theme: {colors: {primary: { rubineLight }}}}) => rubineLight};
  border      : 1px solid ${({ theme }) => theme.colors.neutralGrey[500]};
  margin-left : 10px;
`;
