import React  from 'react';
import styled from 'styled-components';

interface ILayoutProps {
  children: React.ReactNode
}

export const Layout = ({ children }: ILayoutProps) => (
  <Layout.Wrapper>
    {children}
  </Layout.Wrapper>
);

Layout.Wrapper = styled.div`
  width       : 84%;
  margin      : 20px auto 0;
  padding-top : 22px;

  ${({theme: {mediaQueries: {md}}}) => md} {
    padding-top: 44px;
  }

  ${({ theme: {mediaQueries: {lg}}}) => lg} {
    padding-top: 22px;
  }
`;
