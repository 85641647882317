import { 
  useCallback, 
  useContext, 
  useEffect, 
  useState 
}                                      from 'react';
import styled                          from 'styled-components';
import { 
  Divider, 
  Grid, 
  TextField,
  Button
}                                      from '@mui/material';
import { SettingsContext }             from '../../../../../containers/SettingsContainer';
import { FieldWrapper }                from '../../../../FieldWrapper';
import { TitleBlock }                  from '../../../../TitleBlock';
import { 
  privacyTabs, 
  ZonesEnum, 
  zonesTabs 
}                                      from '../../../../../utils/tabs';
import { ZoneField }                   from '../../../../ZoneField';
import { GeneralSettings }             from '../GeneralSettings';
import { Tabset }                      from '../../../../../../../components/Tabset';
import { POWER_ZONES_COLORS }          from '../../../../../utils/colorZones';
import { formattedZones }              from '../../../../../../../utils/commonFunctions';
import { useCalculatePowerZonesQuery } from '../../../../../queries';
import { SETTINGS_SECTIONS }           from '../../../../../utils/settingsSections';
import { ErrorText }                   from '../../../../../../../components/InputError';

export const PowerZones = () => {
  const { 
    register, 
    setValue, 
    getValues, 
    control, 
    watch,
    formState: {
      errors
    },
    trigger
  }                                 = useContext(SettingsContext);
  const [zonesCount, setZonesCount] = useState<ZonesEnum>(getValues().powerZonesCount);
  const [ftp, setFtp]               = useState<number | null>(getValues().functionalThresholdPower ?? null)
  const { data: powerZones }        = useCalculatePowerZonesQuery(
    {
      functionalThresholdPower : ftp,
      zonesCount
    },
    {
      skip: !(ftp && zonesCount)
    }
  );
  const [minMaxZones, setMinMaxZones] = useState(formattedZones(powerZones));

  useEffect(
    () => {
      const subscription = watch((value, { name }) => {
        if (name === 'powerZonesCount') {
          trigger('functionalThresholdPower').then((isValid) => {
            if (isValid) {
              setZonesCount(value.powerZonesCount as ZonesEnum);
            }
          });
        }
      });
      return () => subscription?.unsubscribe();
    },
    [setValue, trigger, watch]
  );


  useEffect(
    () => {
      setMinMaxZones(formattedZones(powerZones))
    },
    [powerZones]
  );

  const handleAutoCalculate = useCallback(
    () => {
      trigger('functionalThresholdPower').then((isValid) => {
        if (isValid) {
          setFtp(getValues().functionalThresholdPower ?? null);
        }
      });
    },
    [getValues, trigger]
  );

  return (
    <FieldWrapper>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <TitleBlock 
            tabs         = {[privacyTabs[1]]}
            defaultValue = {privacyTabs[1].id}
            label        = {SETTINGS_SECTIONS.powerZones.title}
            description  = {SETTINGS_SECTIONS.powerZones.description}
            tooltip      = {SETTINGS_SECTIONS.powerZones.tooltip}
          />
        </Grid>

        <Grid item md={1} sx={{ display: { xs: 'none', sm: 'block' } }} />
        
        <Grid 
          item
          container 
          sx             = {{ rowGap: { xs: 3, md: 0 } }}
          xs             = {12}
          md             = {7}
          direction      = "column"
          justifyContent = "space-around"
        >
          <Grid item container spacing={2} justifyContent="end" alignItems="center">
            <Grid item xs={12} md={4}>
              {errors.functionalThresholdPower && <PowerZones.Error>{errors.functionalThresholdPower.message ?? ''}</PowerZones.Error>}
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField 
                label   = "FTP"
                variant = "filled"
                type    = "text"
                error={!!errors.functionalThresholdPower}
                fullWidth
                {...register('functionalThresholdPower')}
                inputProps={{
                  maxLength: '3',
                }}
              />
            </Grid>
          </Grid>

          <Grid item container spacing={2} alignItems="center" sx={{ justifyContent: { xs: 'center', sm: 'end' } }}>
            <Grid item xs={12} sm="auto" style={{ paddingTop: 5 }}>
              <GeneralSettings.Container>
                <PowerZones.ZonesTitle># Zones</PowerZones.ZonesTitle>
                <Tabset
                  control = {control}
                  name    = "powerZonesCount"
                  tabs    = {zonesTabs}
                />
              </GeneralSettings.Container>
            </Grid>

            <Grid item xs={12} sm="auto">
              <PowerZones.Button 
                sx      = {{ width: { xs: '100%', sm: 'auto' } }}
                color     = "primary"
                variant   = "outlined"
                onClick   = {handleAutoCalculate}
              >
                Auto Calculate
              </PowerZones.Button>
            </Grid>
          </Grid>

          <Grid item xs sx={{ display: { xs: 'block', sm: 'none' } }}>
            <Divider />
          </Grid>

          <Grid item container spacing={2} justifyContent="start" sx={{ flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
            {POWER_ZONES_COLORS[zonesCount].map((color, index) => (
              <Grid item xs={12} key={color}>
                <ZoneField 
                  fullWidth
                  label     = {`Zone ${index + 1}`} 
                  lineColor = {color} 
                  value     = {minMaxZones.length ? `${minMaxZones[index]?.[0] ?? ''}${minMaxZones[index]?.[1] ? -minMaxZones[index]?.[1] : ''}` : ''}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </FieldWrapper>
  )
};

PowerZones.Error = styled(ErrorText)`
  margin-top : 8px;
  text-align : right;
`;

PowerZones.Button = styled(Button)`
  &.MuiButton-root {
    justify-content: center;
  }
`;

PowerZones.ZonesTitle = styled.h4`
  padding-right: 20px;
`;
