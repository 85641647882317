import {
  useCallback,
  useContext,
  useMemo
}                                  from 'react';
import styled, { css }             from 'styled-components';
import { Button, Grid }            from '@mui/material';
import { useGetUserSettingsQuery } from '@modules/Profile/queries';
import {
  useCreatePlannedRideMutation,
  useRemoveRouteFromRideMutation,
  useUpdatePlannedRideMutation,
}                                  from '@modules/Details/queries';
import { ConnectionsSelector }     from '@modules/CreateModal/components/ConnectionsSelector';
import { RideDetails }             from '@modules/CreateModal/components/RideDetails';
import { RoutesSelector }          from '@modules/CreateModal/components/RoutesSelector';
import {
  FCreateRideSubmit,
  IRideForm,
}                                  from '@modules/CreateModal/models/ride';
import { EManagementType }         from '@modules/Details/models/ride';
import { CTAContext }              from '@modules/CreateModal';
import { useCta }                  from '@utils/hooks/useCta';
import { getDiffObject }           from '@utils/commonFunctions';

export const CreateRideForm = () => {
  const [createPlannedRide, createStatus] = useCreatePlannedRideMutation();
  const [updatePlannedRide, updateStatus] = useUpdatePlannedRideMutation();
  const [removeRouteFromRide]             = useRemoveRouteFromRideMutation();
  const { data: settings }                = useGetUserSettingsQuery();
  const { closeCta }                      = useCta();
  const {
    rideForm,
    rideDetails,
    defaultValues,
  }                                       = useContext(CTAContext);

  const isDirty = useMemo(
    () => {
      if (rideDetails?.id) {
        const diffLength = Object.keys(getDiffObject<IRideForm>(rideForm.watch(), defaultValues, ['startTime', 'startDate'])).length;

        return Boolean(diffLength);
      }

      return true;
    },
    [defaultValues, rideDetails?.id, rideForm]
  );

  const onSubmit = useCallback(
    async (values: IRideForm) => {
      values.management = EManagementType.USER; /** TODO: remove this when ride management changes */

      if (rideDetails?.id) {
        if (!values.routeId && rideDetails?.routeId) {
          await removeRouteFromRide({ id: rideDetails.id })
        }

        const updatedData = getDiffObject<IRideForm>(values, defaultValues, ['startTime', 'startDate']);

        await updatePlannedRide({
          ...updatedData,
          id      : rideDetails.id,
          duration: values.routeId ? null : values.duration,
        });

        closeCta();
      } else {
        const result = FCreateRideSubmit({
          ...values,
          duration: values.routeId ? null : values.duration,
        });

        createPlannedRide(result).unwrap().then(() => {
          rideForm?.reset();
          closeCta(true);
        });
      }
    },
    [closeCta, createPlannedRide, defaultValues, removeRouteFromRide, rideDetails?.id, rideDetails?.routeId, rideForm, updatePlannedRide],
  );

  return (
      <CreateRideForm.Form onSubmit={rideForm?.handleSubmit(onSubmit)}>
        <CreateRideForm.Content>
          <div>
            <CreateRideForm.Header><b>Ride Details</b></CreateRideForm.Header>

            <CreateRideForm.Block>
              <RideDetails />
            </CreateRideForm.Block>
          </div>

          <div>
            <CreateRideForm.Header><b>Favourite Routes</b></CreateRideForm.Header>

            <CreateRideForm.Block>
              <RoutesSelector measurementSystem={settings?.measurementSystem || ''} />
            </CreateRideForm.Block>
          </div>

          <div>
            <CreateRideForm.Header><b>Connections</b></CreateRideForm.Header>

            <CreateRideForm.Block>
              <ConnectionsSelector />
            </CreateRideForm.Block>
          </div>
        </CreateRideForm.Content>

        <Grid container justifyContent="center" p="30px 0 40px 0" width="100%">
          <Button
            type     = "submit"
            variant  = "contained"
            size     = "large"
            disabled = {!isDirty || createStatus.isLoading || updateStatus.isLoading}
          >
            {rideDetails ? 'Save Edits' : 'Go Ride!'}
          </Button>
        </Grid>
      </CreateRideForm.Form>
  );
}

CreateRideForm.Form = styled.form`
  max-height: calc(100% - 20px);
  height    : 100%;
  overflow-y: hidden;
  display   : flex;
  flex-wrap : wrap;
`;

CreateRideForm.Content = styled.div`
  overflow-y: hidden;
  height    : calc(100% - 90px);
  display   : inline-flex;
  margin    : 0 auto;

  & > div {
    margin   : 0 10px;
    width    : 268px;
    min-width: 268px;

    ${({theme: {mediaQueries: { sm }}}) => sm} {
      min-width: 394px;
      width    : 394px;
    }
  }
`;

CreateRideForm.Header = styled.div`
  color     : ${({theme: {colors: {primary: { white }}}}) => white};
  font-size : 22px;
  padding   : 11px 0 18px;
  text-align: center;
`;

CreateRideForm.Block = styled.div`
  background-color: ${({theme: {colors: {primary: { white }}}}) => white};
  border-radius   : 8px;
  height          : calc(100% - 49px);
  overflow-y      : auto;
`;

CreateRideForm.BlockFooter = styled(Grid)`
  ${({theme: {
    colors   : { neutralGrey },
    fontSizes: { s, m }
  }}) => css`
    background-color          : ${ neutralGrey[100] };
    box-shadow                : 0 -1px 0 0 rgb(0 0 0 / 8%);
    border-bottom-left-radius : 8px;
    border-bottom-right-radius: 8px;
    padding                   : 12px 0;
    height                    : 56px;

    .MuiButton-root {
      padding  : 8px 12px;
      font-size: ${ s };
    }

    .MuiButton-endIcon {
      margin: 0;
    }

    svg {
      width : 20px;
      height: 20px;
    }

    ${({theme: {mediaQueries: { sm }}}) => sm} {
      .MuiButton-root {
        font-size: ${ m };
        padding  : 8px 20px;
      }

      svg {
        width : 22px;
        height: 22px;
      }
    }
  `};
`;
