import {
  useCallback,
  useMemo
}                             from 'react';
import { useHistory }         from 'react-router';
import styled, { css }        from 'styled-components';
import DoneIcon               from '@mui/icons-material/Done';
import CloseIcon              from '@mui/icons-material/Close';
import { Button, Stack }      from '@mui/material';
import { scrimBlack }         from '@styles/theme';
import { Avatar }             from '@components/Avatar';
import {
  IConfigCell,
  TableData,
}                             from '@components/TableData';
import {
  EInviteStatus,
  IConnection
}                             from '@modules/Profile/models/Community';
import {
  useAddUserConnectionMutation,
  useGetUsersForInviteQuery,
  useAddUsersConnectionsMutation,
  useDeclineUserInvitationMutation,
}                             from '@modules/Profile/queries';
import { useTable }           from '@utils/hooks/useTable';
import { getProfilePhotoUrl } from '@utils/files_utils';

export const Connections = () => {
  const [addConnection]     = useAddUserConnectionMutation();
  const [addConnections]    = useAddUsersConnectionsMutation();
  const [declineInvitation] = useDeclineUserInvitationMutation();
  const { push }            = useHistory();

  const {
    orderBy,
    order,
    onSort,
    onSelectRow,
    selectedItems,
    pageSize,
    pageNumber,
    setPageNumber,
    searchText,
    debouncedSearchParam,
    setSearchText,
  } = useTable();

  const { data, refetch } = useGetUsersForInviteQuery({
    pageNumber,
    pageSize,
    search        : Number(debouncedSearchParam?.length) >= 2 ? debouncedSearchParam : undefined,
    sortColumn    : orderBy,
    sortDirection : order,
  });

  const {
    content,
    totalPages = 0,
    totalElements = 0,
  } = data ?? {};

  const handleResendInvitation = useCallback(
    (userId: string) => {
      addConnection({ userId });
    },
    [addConnection]
  );

  const handleAddConnection = useCallback(
    (items: string[]) => {
      if (items.length > 0) {
        addConnections({ userIds: items })
          .unwrap()
          .then(refetch);
      }
    },
    [addConnections, refetch]
  );

  const renderRowButtons = useCallback(
    ({ id, connectionId, status }: IConnection) => (
      <>
        {status === EInviteStatus.INCOMING && (
          <Connections.Actions>
            <div onClick={() => declineInvitation({ connectionId: connectionId as string })}>
              <CloseIcon />
            </div>
            <div onClick={() => addConnection({ userId: id, isAccepting: true })}>
              <DoneIcon />
            </div>
          </Connections.Actions>
        )}

        {status === EInviteStatus.OUTGOING && (
          <Connections.Status
            $status = {EInviteStatus.OUTGOING}
            onClick = {() => handleResendInvitation(id)}
          >
            <b>RESEND</b>
          </Connections.Status>
        )}

        {status === EInviteStatus.NONE && (
          <Connections.Status
            $status = {EInviteStatus.NONE}
            onClick = {() => addConnection({ userId: id })}
          >
            <b>CONNECT</b>
          </Connections.Status>
        )}

        {status === EInviteStatus.CONNECTED && (
          <Connections.Status
            $status = {EInviteStatus.CONNECTED}
            $isDisabled
          >
            <b>CONNECTED</b>
          </Connections.Status>
        )}
      </>
    ),
    [addConnection, declineInvitation, handleResendInvitation],
  );

  const configCells: IConfigCell<IConnection>[] = useMemo(
    () => [
      {
        label   : 'Name',
        render  : ({ firstName, lastName, profilePictureUrl }: IConnection) => (
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              photoUrl = {profilePictureUrl && getProfilePhotoUrl(profilePictureUrl)}
              name     = {firstName}
              size     = {40}
            />

            <Connections.Title>{firstName} {lastName}</Connections.Title>
          </Stack>
        ),
        width   : '24%',
        orderBy : 'name',
        isBold  : true
      },
      {
        label   : 'Location',
        key     : 'locationName',
        orderBy : 'location',
      },
      {
        label  : '',
        width: '100px',
        cellFlexAlign: 'center',
        render : renderRowButtons,
      }
    ],
    [renderRowButtons]
  );

  const rowActions = useMemo(
    () => [
      {
        label  : 'View Profile',
        onClick: ({ username }: IConnection) => {
          push(`/user/${username}`);
        }
      },
      {
        label  : 'Add Connection',
        onClick : ({ id }: IConnection) => addConnection({ userId: id }).unwrap().then(() => { refetch() })
      },
    ],
    [addConnection, push, refetch]
  );

  return (
    <Connections.Wrapper>
      <TableData
        data              = {content || []}
        configCells       = {configCells}
        rowActions        = {rowActions}
        orderBy           = {orderBy}
        order             = {order}
        onSort            = {onSort}
        searchText        = {searchText}
        searchPlaceholder = "Name"
        setSearchText     = {setSearchText}
        onSelectRow       = {onSelectRow}
        selectedItems     = {selectedItems}
        setPageNumber     = {setPageNumber}
        totalPages        = {totalPages}
        totalElements     = {totalElements}
        title             = "Riders"
      />

      {selectedItems.length > 0 && (
        <Connections.AddButton
          variant  = "contained"
          onClick  = {() => handleAddConnection(selectedItems)}
        >
          Add Connections
        </Connections.AddButton>
      )}

    </Connections.Wrapper>
  )
}

Connections.AddButton = styled(Button)`
  margin-top: 50px;
`;

Connections.Wrapper = styled.div`
  width     : 100%;
  text-align: center;

  .MuiToolbar-root {
    background-color: #E8E8E7;
  }

  .MuiBox-root {
    margin-right: 5px;
  }
  
  p {
    width: 50%;
  }

  li button {
    background-color: #E8E8E7 !important;
  }

  strong {
    margin-left: 10px;
  }
`;

Connections.Title = styled.div`
  display: flex;

  p {
    margin-left: 15px;
  }
`;

Connections.Actions = styled.div`
  display        : flex;
  justify-content: space-around;
  width          : 100%;
  margin-right   : 20px;

  div {
    display        : flex;
    justify-content: center;
    align-items    : center;
    width          : 30px;
    height         : 30px;
    border-radius  : 100%;
    box-shadow     : 0px 0px 8px 0px rgba(34, 60, 80, 0.41);
    cursor         : pointer;
  }

  div:first-child {
    background-color: ${({ theme }) => theme.colors.neutralGrey[600]};

    &:hover {
      background-color: ${({ theme }) => theme.colors.neutralGrey[700]};
    }
  }
  

  div:nth-child(2) {
    background-color: ${({ theme }) => theme.colors.primary.rubineDark};

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary.rubineLight};
    }
  }

  svg {
    font-size : 20px;
    color     : #FFF;
  }

  svg:hover {
    background-color: transparent;
  }
`;

Connections.Status = styled.div<{ $status?: EInviteStatus; $isDisabled?: boolean; }>`
  --color: ${({ $status, theme: { colors }}) => {
    switch($status) {
      case EInviteStatus.OUTGOING:
        return colors.neutralBlue[300];
      case EInviteStatus.CONNECTED:
        return `${colors.secondary.green}90`;
      default:
        return colors.neutralBlue[700];
    }
  }};
  border-radius: 30px;
  margin-right : 20px;
  color        : var(--color);
  padding      : 8px 18px;
  cursor       : ${({ $isDisabled }) => $isDisabled ? 'auto' : 'pointer'};

  ${({ $isDisabled }) => !$isDisabled && css`
    border    : 2px solid var(--color);
    box-shadow: 0 1px 2px ${scrimBlack(0.04)}, 0px 4px 8px ${scrimBlack(0.04)};

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary.rubineLight};
      border          : ${({ theme }) => `2px solid ${theme.colors.primary.rubineLight}`};
      color           : #FFF;
    }
  `}
`;
