import {
  ForwardedRef,
  forwardRef
} from 'react';
import {
  Controller,
  Control,
  useFormState
} from 'react-hook-form';
import {
  TextInput,
  TTextInputProps
} from '../TextInput';

type TTextFieldProps = TTextInputProps & {
  control: Control<any>;
};

export const TextField = forwardRef(({
  name,
  control,
  ...props
}: TTextFieldProps, ref: ForwardedRef<HTMLInputElement>): JSX.Element => {
  const { errors } = useFormState({ control });

  return (
    <Controller
      name    = {name}
      control = {control}
      render  = {({ field }) => (
        <TextInput
          {...props}
          {...field}
          error = {errors[name]?.message}
          ref   = {ref}
        />
      )}
    />
  )
});
