import React, {
  useContext,
  useState,
  useEffect,
  createContext,
  PropsWithChildren
}                    from 'react';
import {
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
}                     from '@firebase/auth';
import firebase       from 'firebase/compat/app';
import axios          from 'axios';
import { StreamChat } from 'stream-chat';
import { useStore }   from 'react-redux';
import { useHistory } from 'react-router';
import { callApi }    from '@utils/apiCaller';
import { 
  apiOrigin, 
  getStreamKey 
}                     from '@config/api_config';
import { auth }       from '../adapters/firebase';

export const ACCOUNT_TYPE_TABS = [{
  label: 'Personal',
  id: false
}, {
  label: 'Club',
  id: true
}];

type PasswordReset = (email: string) => Promise<any>;

interface ISignupPayload {
  email          : string;
  firstName      : string;
  lastName       : string;
  password       : string;
  username       : string;
  signupCode     : string;
  isClubAccount  : boolean;
  inviteCode?    : string;
  plannedRideId? : string;
  eventDate?     : string;
  birthdate      : string;
}

interface ICompleteSignup {
  email        : string,
  firstName    : string,
  lastName     : string,
  displayName  : string,
  preemHandle  : string,
  signupCode   : string,
  uid          : string,
  isClubAccount: boolean,
  birthdate    : string;
}

type AuthContextValues = {
  currentUser      : firebase.User | null;
  isAuthorized     : boolean;
  setCurrentUser   : React.Dispatch<React.SetStateAction<firebase.User | null>>;
  signupWithApple  : () => Promise<any> /** TODO: fix any */;
  signupWithGoogle : () => Promise<any> /** TODO: fix any */;
  signin           : (email: string, password: string) => Promise<unknown>;
  signout          : () => void;
  passwordReset    : PasswordReset;
  signup           : (data: ISignupPayload) => Promise<unknown>;
  completeSignup   : (input: ICompleteSignup) => Promise<unknown>;
};

export const AuthContext = createContext<AuthContextValues | null>(null);

export function useAuth() {
  return useContext(AuthContext)!;
}

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [loading, setLoading]         = useState(true);
  const { replace }                   = useHistory();
  const { dispatch }                  = useStore();

  function signupWithApple(): Promise<any> /** TODO: fix any */ {
    return new Promise(function (resolve, reject) {
      const provider = new OAuthProvider("apple.com");
      // Once we implement i18n, use it here:
      // provider.setCustomParameters({
      //   // Localize the Apple authentication screen in French.
      //   locale: 'fr'
      // });
      signInWithPopup(auth, provider)
        .then((result) => {
          // The signed-in user info.
          const { user } = result;
          setCurrentUser(user);

          resolve(user);
        })
        .catch((error) => {
          // Handle Errors here.
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // The email of the user's account used.
          // const email = error.email;
          // The credential that was used.
          // const credential = OAuthProvider.credentialFromError(error);
          reject(error);
        });
    });
  }

  function signupWithGoogle(): Promise<any> /** TODO: fix any */ {
    return new Promise(function (resolve, reject) {
      const provider = new GoogleAuthProvider();
      provider.addScope("profile");

      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // const credential = GoogleAuthProvider.credentialFromResult(result);
          // console.log(
          //   "AuthProvider => signupWithGoogle => credential => ",
          //   credential
          // );
          // const token = credential?.accessToken;
          // console.log("AuthProvider => signupWithGoogle => token => ", token);

          const { user } = result;

          resolve(user);
        })
        .catch((error) => {
          // console.error(error);
          // Handle Errors here.
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // The email of the user's account used.
          // const email = error.email;
          // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error);
          reject(error);
        });
    });
  }

  const signin = (email: string, password: string) => {
    let promise = new Promise(function (resolve, reject) {
      signInWithEmailAndPassword(auth, email, password)
        .then(async (ref) => {
          await auth.currentUser?.getIdToken().then(
            (token: string) => {
              setCurrentUser(auth.currentUser);

              axios.defaults.headers['X-Preem-AccessToken'] = token;
            }
          )

          resolve(ref);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  };

  const disconnectChat = async () => {
    const client = StreamChat.getInstance(getStreamKey || '', {
      enableInsights  : true,
      enableWSFallback: true,
    });

    await client.disconnectUser();
  }

  const signout = () => {
    auth.signOut().then(
      () => {
        setCurrentUser(null);

        axios.defaults.headers['X-Preem-AccessToken'] = '';

        dispatch({ type: 'LOG_OUT' });

        replace('/landing#discover');
        window.scrollTo(0, 0);

        disconnectChat();
      }
    );
  };

  const signup = async ({
    email,
    firstName,
    lastName,
    password,
    username,
    signupCode,
    inviteCode,
    plannedRideId,
    eventDate,
    isClubAccount, 
    birthdate
  }: ISignupPayload) => {
    const payload = {
      signupCode,
      firstName,
      lastName,
      password,
      email,
      inviteCode,
      plannedRideId,
      eventDate,
      isClubAccount,
      displayName: `${firstName} ${lastName}`,
      username,
      birthdate
    }

    return await callApi(`${apiOrigin}/api/v1/users?registrationType=email`, 'POST', { ...payload }, { requestWithoutAuth: true });
  }

  const completeSignup = async ({
    email  ,
    firstName,
    lastName,
    displayName,
    preemHandle,
    signupCode,
    uid,
    isClubAccount,
    birthdate
  }: ICompleteSignup) => {
    const payload = {
      signupCode,
      firstName,
      lastName,
      email,
      uid,
      displayName,
      username    : preemHandle,
      isClubAccount,
      birthdate
    }

    return await callApi(`${apiOrigin}/api/v1/users?registrationType=provider`, 'POST', { ...payload }, { requestWithoutAuth: true });
  }

  const passwordReset = (email: string) => {
    let promise = new Promise(function (resolve, reject) {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          resolve(`Password Reset Email sent to ${email}`);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        setCurrentUser(user);

        user.getIdToken().then((token) => {
          axios.defaults.headers['X-Preem-AccessToken'] = token;

          setLoading(false);

          // console.log("AuthContext => JWT => ", token);
          // console.log("AuthContext => image => ", user.photoURL);
        });
      } else {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, [currentUser]);

  axios.interceptors.response.use((response) => response, async error => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true;

      await auth.currentUser?.getIdToken().then(
        (token: string) => {
          setCurrentUser(auth.currentUser);

          axios.defaults.headers['X-Preem-AccessToken'] = token;

          return axios(originalRequest);
        }
      )
    }

    return Promise.reject(error);
  });


  const value = {
    currentUser,
    setCurrentUser,
    signup,
    completeSignup,
    signupWithApple,
    signupWithGoogle,
    signin,
    signout,
    passwordReset,
    isAuthorized: Boolean(currentUser)
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
