import {
  format,
  intervalToDuration,
}                           from 'date-fns';
import {
  DISTANCE_UNITS,
  MEASUREMENT_SYSTEMS,
}                           from '@modules/Profile/utils/options';
import { StatisticsPeriod } from '@components/UserStatisticsTabs/model/statistics';
import {
  metersToFeet,
  metersToMiles,
}                           from './conversion_utils';

export const roundToDecimal = (value: number, decimals?: number) => {
  const decimalNumber = decimals ?? 1;

  return Math.round(value * (10 ** decimalNumber)) / (10 ** decimalNumber);
};

export const roundToPrecision = (value: number, precision = 0): number => {
  const exponent = Math.pow(10, precision);

  return Math.round(value * exponent) / exponent;
}

export const getElevation = (units?: string, value: number = 0): number => {
  let elevation = 0;

  if (units === MEASUREMENT_SYSTEMS.IMPERIAL) {
    elevation = metersToFeet(value);
  }

  if (units === MEASUREMENT_SYSTEMS.METRIC) {
    elevation = +value.toFixed(1);
  }

  return elevation;
}

export const getElevationMetrics = (units?: string): string => {
  return units === MEASUREMENT_SYSTEMS.IMPERIAL ? DISTANCE_UNITS.FT: DISTANCE_UNITS.M;
}

export const getDistance = (units?: string, value = 0): number => {
  let distance = 0;

  if (value && units === MEASUREMENT_SYSTEMS.IMPERIAL) {
    distance = metersToMiles(value);
  } else {
    distance = value >= 1000 ? +Math.round((value / 1000)) : +Math.round(value);
  }

  return distance;
}

export const getDistanceUnits = (units?: string, value?: number): string => {
  if (units === MEASUREMENT_SYSTEMS.IMPERIAL) {
    return DISTANCE_UNITS.MI;
  }

  return value && value < 1000 ? DISTANCE_UNITS.M : DISTANCE_UNITS.KM;
}

export const getTimeFromSeconds = (value?: number): string => {
  if (!value) {
    return '—';
  }
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor(value % 3600 / 60);
  const seconds = Math.floor(value % 3600 % 60);
  if (!hours && !minutes && !seconds) {
    return '—';
  }
  return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
}

export const formatNumberWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getStartDayByPeriod = (period: StatisticsPeriod): string | undefined => {
  const date = new Date();

  switch(period) {
    case StatisticsPeriod.YEAR:
      date.setFullYear(date.getFullYear() - 1);
      return format(date, 'yyyy-MM-dd');
    case StatisticsPeriod.MONTH:
      date.setMonth(date.getMonth() - 1);
      return format(date, 'yyyy-MM-dd');
    case StatisticsPeriod.WEEK:
      date.setDate(date.getDate() - 7);
      return format(date, 'yyyy-MM-dd');
    default:
      return undefined;
  }
}

export const getDurationFormat = (timeSeconds: number) => {
  const duration = intervalToDuration({ start: new Date().setSeconds(0), end: new Date().setSeconds(timeSeconds) });

  const hours    = duration.hours?.toString().length === 1 ? `0${duration.hours}` : duration.hours;
  const minutes  = duration.minutes?.toString().length === 1 ? `0${duration.minutes}` : duration.minutes;
  const seconds  = duration.seconds?.toString().length === 1 ? `0${duration.seconds}` : duration.seconds;

  return `${hours}:${minutes}:${seconds}`;
}
