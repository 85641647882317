import { useCallback, useEffect } from 'react';
import { useHistory }             from 'react-router';
import { getUserStatus }          from '../../../services/user_service';
import { useAuth }                from '../contexts/AuthContext';
import { LoginPage }              from './LoginPage';

const GOOGLE = 'google';

export const Login = () => {
  const history = useHistory();

  const {
    currentUser,
    signupWithApple,
    signupWithGoogle,
    signin
  } = useAuth();

  /**
   * @description Let the user sign in with a third-party provider, then show input form to allow name adjustments and handle selection
   * @param {string} providerName Currently either google or apple, can be expanded
   */
  const thirdPartyLogin = useCallback(
    (providerName: string) => {
      let googlePromise;
      let applePromise;

      if (providerName === GOOGLE) {
        googlePromise = new Promise((resolve, reject) => {
          signupWithGoogle()
            .then((result) => resolve(result))
            .catch((error) => reject(error));
        });

        applePromise = new Promise((_resolve, reject) => {
          reject("rejecting to avoid apple call in google signup");
        });
      } else {
        applePromise = new Promise((resolve, reject) => {
          signupWithApple()
            .then((result) => resolve(result))
            .catch((error) => reject(error));
        });
        googlePromise = new Promise((_resolve, reject) => {
          reject("rejecting to avoid google call in apple signup");
        });
      }

      const promises = [applePromise, googlePromise];

      Promise.any(promises)
        .then((_value: any /** TODO: fix any */) => {
          getUserStatus().then((result: any) => {
            if (result.needsHandle || result.needsLocation) {
              history.push("/signup");
            } else {
              history.push("/");
            }
          });
        })
        .catch(() => {
          // handle apple/google login errors here
        });
    },
    [history, signupWithApple, signupWithGoogle]
  );


  /** user status check to avoid letting logged-in users see the /login page
   * also routes to /signup to let user select handle and/or location
   */
  useEffect(
    () => {
      let useEffectGate = true;

      if (currentUser !== null) {
        getUserStatus().then((result: any) => {
          if (result.needsHandle) {
            useEffectGate === true &&
              history.push('/signup', { needsHandle: true });
          } else if (result.needsLocation) {
            useEffectGate === true &&
              history.push('/signup', { needsLocation: true });
          } else {
            useEffectGate === true && history.push("/");
          }
        });
      }

      return () => {
        useEffectGate = false;
      };
    },
    [currentUser, history]
  );

  return (
    <>
      <LoginPage
        signin          = {signin}
        thirdPartyLogin = {thirdPartyLogin}
      />
    </>
  );
};
