import * as zip from '@zip.js/zip.js';
import {addMinutes, format} from 'date-fns';
import {TIME_TYPES} from '@models/TimeTypes';
import {TTimeType} from '@modules/Profile/models/Settings';
import {roundToPrecision} from '@utils/metrics_utils';

const INCHES_TO_CM                                   = 2.54;
const CM_TO_METERS                                   = 0.01;
const FEET_TO_INCHES                                 = 12;
const ZIP_TYPES                                      = ['application/zip', '.zip'];
const DEFAULT_THUMBNAIL_RESOLUTION: [number, number] = [200, 200];

export const formattedZones = (zones: { [key: string]: number }) => {
  if (!zones) {
    return []
  }

  const zonesArray = Object.values(zones);

  let chunks = [];

  while(zonesArray.length) {
    chunks.push(zonesArray.splice(0, 2));
  }

  return chunks;
}

export const convertCMtoMeters = (cm?: number) => cm ? cm / 100 : 0;
export const convertMetersToCm = (meters?: number) => meters ? Math.round(meters * 100) : 0;

export const convertToMeters = (feet?: string, inches?: string) => {
  if (feet && inches) {
    const result = ((+feet * FEET_TO_INCHES) + +inches) * INCHES_TO_CM * CM_TO_METERS

    return roundToPrecision(result, 4);
  }

  return 0;
};

const getLength = (meters: number) => {
  return (100 * meters) / INCHES_TO_CM + (0 / INCHES_TO_CM);
}

export const convertMetersToInches = (meters?: number | null) => {
  if (meters) {
    const length = getLength(meters);

    const feet = Math.floor(length / FEET_TO_INCHES);

    return (length - FEET_TO_INCHES * feet).toFixed(0);
  }

  return '';
}

export const convertMetersToFeet = (meters?: number | null) => {
  if (meters) {
    const length = getLength(meters);

    return Math.floor(length / 12);
  }

  return '';
}

export const convertPoundsToKgs = (pounds?: number) => pounds ? Number(pounds) / 2.2046 : 0;
export const convertKgsToPounds = (kgs?: number | null) => kgs ? Number(kgs) * 2.2046 : 0;

export const isZip = (file: Blob, fileName: string): boolean => {
  const result = (ZIP_TYPES.indexOf(file?.type) >= 0) ||
    ZIP_TYPES.some(type => fileName.endsWith(type));

  return result;
};

export const filterZip = async (
  unfilteredZipFile : Blob,
): Promise<Blob> => {
  const zipReader = new zip.ZipReader(new zip.BlobReader(unfilteredZipFile));
  const zipWriter = new zip.ZipWriter(new zip.BlobWriter('application/zip'));

  const unfilteredEntries = await zipReader.getEntries();

  const isRideFile = (path: string): boolean => {
    return ["fit", "tcx", "gpx"].some(
      extension =>
        path.endsWith(`.${extension}`)
        || path.endsWith(`.${extension}.gz`)
    );
  }

  const filteredEntries: any[] =
      unfilteredEntries.filter(entry => isRideFile(entry.filename));

  const entryPromises = [];

  for (const entry of filteredEntries) {
    const promise = entry.getData(new zip.BlobWriter()).then((blob: Blob) => zipWriter.add(
      entry.filename,
      new zip.BlobReader(blob)
    ))

    if (promise) {
      entryPromises.push(promise);
    }
  }

  await Promise.all(entryPromises);

  await zipReader.close();

  return await zipWriter.close();
};

export const formatRideDate = (date = new Date()) => format(date, 'yyyy-MM-dd');

export const formatAMPM = (date: Date) => {
  let hours: number            = date.getHours();
  let minutes: number | string = date.getMinutes();
  let ampm: string             = hours >= 12 ? 'PM' : 'AM';
      hours                    = hours % 12;
      hours                    = hours ? hours : 12;
      minutes                  = minutes < 10 ? '0'+minutes : minutes;

  return `${hours}:${minutes} ${ampm}`;
}

export const formatTime = (date: string, timeType?: TTimeType) => {
  const dateFormatted = new Date(date);

  if (timeType === TIME_TYPES.HOURS_12) {
    const formatDate: string = format(addMinutes(dateFormatted, dateFormatted.getTimezoneOffset()), 'yyyy-MM-dd HH:mm:ss');

    return formatAMPM(new Date(formatDate));
  }

  return format(addMinutes(dateFormatted, dateFormatted.getTimezoneOffset()), 'HH:mm');
}

export const appendUrlParams = (
  url    : string,
  params : Record<string | symbol, any>,
): string => Object.entries(params).reduce((url, [key, value], index) => {
  const paramSeparator = index === 0 ? '?' : '&';

  if (value === undefined) {
    return url;
  }

  return `${url}${paramSeparator}${key}=${value}`;
}, url);

export const formatPhoneNumber = (phone: string = '') => {
  const formattedPhone = phone.replace(/[\s()-]/gm, '');

  if (!formattedPhone.includes('+')) {
    return `+${formattedPhone}`;
  }

  return formattedPhone;
};

export const isDateInPast = (date: Date) => date.getTime() <= new Date().setHours(0, 0, 0, 0);

export const getPluralTitle = (singleTitle: string, count: number) => {
  if (count === 0) {
    return singleTitle;
  }

  if (count > 1) {
    return `${singleTitle}s`;
  }

  return singleTitle;
}

export const getDiffObject = <T extends object>(newObject: T, compareObj: T, ignoreFields?: (keyof T)[]): Partial<T> => {
  const newObjectKeys = Object.keys(newObject) as (keyof T)[];

  return newObjectKeys.reduce((resultObj, currentKey) => {
    if (typeof newObject[currentKey] === 'object' && JSON.stringify(newObject[currentKey]) === JSON.stringify(compareObj[currentKey])) {
      return resultObj;
    }

    if (ignoreFields?.includes(currentKey as keyof T) || newObject[currentKey] === compareObj[currentKey]) {
      return resultObj;
    }

    if (Array.isArray(newObject[currentKey]) && JSON.stringify(newObject[currentKey]) === JSON.stringify(compareObj[currentKey])) {
      return resultObj;
    }

    return {
      ...resultObj,
      [currentKey]: newObject[currentKey]
    }
  }, {});
}

export const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  }
  catch(e) {
    return false;
  }
}

export const generateUid = () => Date.now().toString(36) + Math.random().toString(36).substring(2);

export const urlify = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  return text.replace(urlRegex, (url: string) => {
    return '<a href="' + url + '">' + url + '</a>';
  });
}

export const getThumbnailByResolution = (urls?: string[], resolution?: [number, number]) => {
  const [width, height]   = resolution ?? DEFAULT_THUMBNAIL_RESOLUTION;
  const requiredThumbNail = urls?.find((url) => url.includes(`${width}x${height}`));

  return requiredThumbNail ?? urls?.[0];
};
