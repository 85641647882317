import { ReactNode } from 'react';
import styled        from 'styled-components';
import { Button }    from '@mui/material';

interface ISignupButtonProps {
  onHandleClick: () => void;
  icon         : ReactNode;
  title        : string;
}

export const SignupButton = ({
  onHandleClick,
  icon,
  title
}: ISignupButtonProps) => (
  <SignupButton.Wrapper
    size      = "large"
    variant   = "outlined"
    color     = "primary"
    onClick   = {onHandleClick}
    startIcon = {icon}
  >
    <span>{title}</span>
  </SignupButton.Wrapper>
);

SignupButton.Wrapper = styled(Button)`
  padding      : 7px 12px;
  transition   : all 200ms ease-in-out;
  margin-bottom: 12px;

  span:nth-child(2) {
    width: calc(100% - 55px);
  }

  ${({theme: {mediaQueries: {sm}}}) => sm} {
    padding      : 12px 16px;
    margin-bottom: 16px;
  }
`;