
export const toFarenheit = (celsius: number) => {
  return celsius * (9 / 5) + 32;
};
export const toCelsius = (farenheit: number) => {
  return (farenheit - 32) * (5 / 9);
};
export const toMPH = (kph: number) => {
  return Math.round(kph / 1.609344);
};
export const toKPH = (mph: number) => {
  return Math.round(mph * 1.609344);
};
export const metersToFeet = (meters: number): number => {
  return Math.round(meters * 3.28084);
}
export const feetToMeters = (feet: number): number => {
  return Math.round(feet * 0.3048);
};
export const metersToMiles = (meters: number): number => {
  return toMPH(meters/1000);
}
export const milesToMeters = (miles: number): number => {
  return Math.round(miles * 1609.344);
};
export const bytesToKb = (bytes = 0): number => {
  return Math.round(bytes/1000);
}

export const mpsToKmph = (value = 0): number => {
  return Math.round(value * 3.6);
}

export const mpsToMph = (value = 0): number => {
  return Math.round(value * 2.23694);
}

export const kgToPounds = (value = 0): number => {
  return Math.round(value * 2.20462);
}

const compass = ["n", "ne", "e", "se", "s", "sw", "w", "nw"];
/**
 * @description degressToCompass converts a degree reading into a compass direction to generate css classes
 * @param   {number}  degrees  Angle of wind direction
 * @return  {string}           Returns a string to generate a css class
 * @example
 * degreesToCompass(0) => "n"
 * degreesToCompass(90) => "e"
 * degreesToCompass(180) => "s"
 * degreesToCompass(270) => "w"
 * degreesToCompass(360) => "n"
 */
export const degreesToCompass = (degrees: number): string => {
  return compass[Math.round(degrees / 45) % 8];
};
