import { Grid, Button } from '@mui/material';
import styled           from 'styled-components';
import { scrimBlack }   from '../../../../../../styles/theme';

export const EmptyTable = () => (
  <EmptyTable.Wrapper item xs={12} container alignItems="center" justifyContent="center">
    <Grid item xs={12} sm={5} p="7%">
      <Button onClick={() => {}}>
        Import a Route
      </Button>

      <div>
        Import routes from your favorite route editing tool to create and share the best rides in your community or to explore something completely new.
      </div>
    </Grid>
  </EmptyTable.Wrapper>
);

EmptyTable.Wrapper = styled(Grid)`
  &.MuiGrid-root{
    height          : 450px;
    background-color: ${scrimBlack(0.04)};
    font-weight     : 500;
    margin-bottom   : 64px;
  }

  div {
    text-align: center;
  }
`;
