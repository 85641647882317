import {
  ComponentType,
  FC,
  useEffect,
  useMemo
}                             from 'react';
import { getAuth }            from 'firebase/auth';
import { 
  useDispatch,
  useSelector
}                             from 'react-redux';
import { useLocation }        from 'react-router-dom';
import { Redirect }           from 'react-router';
import { 
  currentLocation, 
  setCurrentLocation
}                             from '@store/reducers/location';
import {
  useGetGenLocationByIdQuery,
  useGetGetStreamTokenQuery,
  useGetSelfInformationQuery,
  useGetUserPrimaryLocationQuery
}                             from '@modules/Profile/queries';
import { Loader }             from '@components/Loader';
import {
  IInvitationLinkData,
  RIDES_INVITATION_LINK_PATTERN,
  RIDE_INVITATION_DATA_KEY
}                             from '@modules/Details/models/invitation';
import { useSessionStorage }  from '@utils/hooks/useSessionStorage';
import { useGetStream }       from '@utils/hooks/useGetStream';
import { getUserAvatarUrl }   from '@utils/files_utils';

export const FROM_PATH_KEY = 'from';

export const withAuth = <P extends object>(Component: FC<P>): ComponentType<P> => () => {
  const { currentUser }         = getAuth();
  const { chatClient }          = useGetStream();
  const { data: getStreamData } = useGetGetStreamTokenQuery();
  const {
    data     : self,
    isLoading: isSelfLoading
  }                             = useGetSelfInformationQuery();
  const dispatch                = useDispatch();
  const { setItem }             = useSessionStorage();
  const currentGenLocation      = useSelector(currentLocation);

  useEffect(
    () => {
      const initChat = async () => {
        try {
          await chatClient.connectUser(
            {
              id    : self?.id ?? '',
              name  : self?.displayName ?? '',
              image : getUserAvatarUrl(self?.profilePictureUrl ?? '')
            },
            getStreamData?.token
          );
        } catch (e) {
          console.error(e);
        }
      };

      if (chatClient._user === undefined && self && getStreamData) {
        initChat();
      }
    },
    [chatClient, chatClient._user, getStreamData, self]
  );

  const {
    pathname,
    search
  } = useLocation();

  const isRideInvite = pathname.includes(RIDES_INVITATION_LINK_PATTERN);

  const {
    data     : primaryLocation,
    isLoading: isPrimaryLocationLoading
  } = useGetUserPrimaryLocationQuery(null, { refetchOnMountOrArgChange: true });

  const {
    data     : fetchedGenLocation,
    isLoading: isGenLocationLoading,
  } = useGetGenLocationByIdQuery(
    { locationId: primaryLocation?.genLocationId ?? '' },
    { skip: !primaryLocation }
  );

  useEffect(
    () => {
      if (fetchedGenLocation) {
        dispatch(setCurrentLocation(fetchedGenLocation));
      }
    },
    [dispatch, fetchedGenLocation]
  );

  const isLoading = useMemo(
    () => isSelfLoading || isPrimaryLocationLoading || isGenLocationLoading,
    [isGenLocationLoading, isPrimaryLocationLoading, isSelfLoading]
  );

  if (isLoading) return <Loader />

  if (currentUser && !self) {
    return <Redirect to="/signup" />;
  }

  if (currentUser && self && !isPrimaryLocationLoading && !primaryLocation) {
    return <Redirect to="/signup" />;
  }

  if (!currentUser && !self && isRideInvite) {
    const searchParams = new URLSearchParams(search);

    const invitationData: IInvitationLinkData = {
      inviteCode : searchParams.get('uuid'),
      linkUuid   : searchParams.get('linkUuid'),
    };

    setItem(RIDE_INVITATION_DATA_KEY, invitationData);

    const url = `/shared/ride/${invitationData.linkUuid}/overview`;

    return <Redirect to={url} />;
  }

  if (!currentUser && !self) {
    setItem(FROM_PATH_KEY, { pathname });

    return <Redirect to="/landing" />;
  }

  return <Component {...{} as P} />;
}
