import { PropsWithChildren, useState } from 'react';
import { 
  Button,
  ButtonProps, 
  Dialog, 
  Grid, 
  Box, 
  IconButton,
  Tab,
  Tabs
}                                      from '@mui/material';
import styled                          from 'styled-components';
import { EConnectionStatus }           from '@modules/Profile/models/Community';
import { UserTitle }                   from '../../../../components/UserTitle';
import { scrimBlack }                  from '../../../../styles/theme';
import { ReactComponent as CloseIcon } from '../../../../images/Icon-Close-Lt-Grey.svg';
import { Loader }                      from '../../../../components/Loader';
import { IUserInfo }                   from '../../models/UserInfo';
import { ProfileTab }                  from './components/ProfileTab';

interface IPublicProfileProps {
  isLoading             : boolean;
  setConnectionWithUser?: () => void;
  onClose               : () => void;
  data?                 : IUserInfo;
}

const PUBLIC_PROFILE_TABS: string[] = ['Profile'];

const TabPanel = (props: PropsWithChildren<{index: number, currentTab: string}>) => {
  const { children, index, currentTab } = props;
  
  return (
    <>
      {PUBLIC_PROFILE_TABS[index] === currentTab ? children : null}
    </>
  );
};

export const PublicProfile = ({
  data,
  setConnectionWithUser,
  onClose,
  isLoading
}: IPublicProfileProps) => {
  const [currentTab, setCurrentTab] = useState<string>(PUBLIC_PROFILE_TABS[0]);
  const connectionStatus            = data?.connectionStatus && EConnectionStatus[data?.connectionStatus];

  const ConnectButton = (size: ButtonProps['size']) =>  (
    <>
      {data?.connectionStatus && (
        <PublicProfile.ConnectionStatus status={connectionStatus}>
          <b>{connectionStatus}</b>
        </PublicProfile.ConnectionStatus>
      )}

      {!data?.connectionStatus && setConnectionWithUser && (
        <Grid item>
          <Button 
            variant = "contained"
            size    = {size}
            onClick = {setConnectionWithUser}
          >
            Connect
          </Button>
        </Grid>
      )}
    </>
  );

  return (
    <PublicProfile.Dialog 
      open
      fullScreen
    >
      <PublicProfile.Header container justifyContent="space-between">
        <Grid item xs={10}>
          <UserTitle data={data} showEmptyNationality={false}/>
        </Grid>

        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          {ConnectButton('medium')}
        </Box>

        <Grid item>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </PublicProfile.Header>

      {isLoading ? (
        <Grid container justifyContent="center">
          <Loader />
        </Grid>
      ) : (
        <>
          <PublicProfile.Tabs 
            value         = {currentTab}
            variant       = "scrollable"
            scrollButtons = "auto"
            onChange      = {(_event, newValue) => setCurrentTab(newValue)}
          >
            {PUBLIC_PROFILE_TABS.map((tab, index) => (
              <Tab
                // todo: enable when other tabs are implemented
                disabled = {index > 0}
                key      = {tab}
                label    = {tab}
                value    = {tab}
              />
            ))}
          </PublicProfile.Tabs>

          <TabPanel index={0} currentTab={currentTab}>
            <ProfileTab userInfo = {data} />
          </TabPanel>

          <PublicProfile.Footer container justifyContent="flex-end" sx={{ display: { xs: 'flex', sm: 'none' } }}>
            {ConnectButton('small')}
          </PublicProfile.Footer>
        </>
      )}
    </PublicProfile.Dialog>
  );
}

PublicProfile.Dialog = styled(Dialog)`
  .MuiPaper-root {
    padding: 0;
  }
`;

PublicProfile.Header = styled(Grid)`
  border-bottom   : 1px solid ${scrimBlack(0.08)};
  padding         : 10px 10px 10px 20px;
  background-color: ${({theme: {colors: {primary: {white}}}}) => white};

  ${({theme: {mediaQueries: {sm}}}) => sm} {
    padding: 20px 20px 20px 110px;
  }
`;

PublicProfile.ConnectionStatus = styled.div<{status?: EConnectionStatus}>`
  --color: ${({status, theme: {colors: {neutralBlue, messaging: {green, red}}}}) => {
    switch(status) {
      case EConnectionStatus.PENDING:
        return neutralBlue[300];
      case EConnectionStatus.ACCEPTED:
        return green;
      case EConnectionStatus.DECLINED:
        return red;
      default:
        return '';
    }
  }};
  border-radius: 30px;
  border       : 2px solid var(--color);
  color        : var(--color);
  padding      : 8px 18px;
  box-shadow   : 0px 1px 2px ${scrimBlack(0.04)}, 0px 4px 8px ${scrimBlack(0.04)};
`;

PublicProfile.Tabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    justify-content: unset;
  }

  ${({theme: {mediaQueries: {sm}}}) => sm} {
    .MuiTabs-flexContainer {
      justify-content: center;
    }
  }
`;

PublicProfile.Footer = styled(Grid)`
  border-top      : 1px solid ${scrimBlack(0.08)};
  background-color: ${({theme: {colors: {neutralGrey}}}) => neutralGrey[100]};
  padding         : 12px 16px;
`;
