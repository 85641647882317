import { 
  DISTANCE_UNITS, 
  MEASUREMENT_SYSTEMS
}                                          from '../../../utils/options';
import { ReactComponent as CyclingIcon }   from '../../../../../images/Icon-Sport-Cycling-Rubine.svg';
import { ReactComponent as TimeIcon }      from '../../../../../images/Icon-Metric-Duration-Black.svg';
import { ReactComponent as ElevationIcon } from '../../../../../images/Icon-Metric-Elevation-Black.svg';
import { ReactComponent as DistanceIcon }  from '../../../../../images/Icon-Metric-Distance-Black.svg';
import { ReactComponent as SpeedIcon }     from '../../../../../images/Icon-Metric-Speed-Black.svg';
import { 
  mpsToKmph, 
  mpsToMph 
}                                          from '../../../../../utils/conversion_utils';
import { 
  IStatisticsMetrics, 
  IUserStatistics 
}                                          from '../../../../../components/UserStatisticsTabs/model/statistics';
import { 
  formatNumberWithCommas, 
  getDistance, 
  getDistanceUnits, 
  getElevation, 
  getElevationMetrics, 
  getTimeFromSeconds 
}                                          from '../../../../../utils/metrics_utils';

export const statisticsMetrics: IStatisticsMetrics<IUserStatistics>[] = [{
  icon : <CyclingIcon />,
  label: 'RIDES',
  key  : 'userRidesCount'
}, {
  icon        : <TimeIcon />,
  label       : 'RIDE TIME',
  key         : 'userRidesDuration',
  conversionFn: (_units, value) => getTimeFromSeconds(value)
}, {
  icon        : <ElevationIcon />,
  label       : 'ELEVATION',
  key         : 'userRidesElevation',
  units       : getElevationMetrics,
  conversionFn: (units, value) => {
    const elevation = getElevation(units, value);
    return elevation ? formatNumberWithCommas(elevation) : '—';
  }
}, {
  icon        : <DistanceIcon />,
  label       : 'DISTANCE',
  key         : 'userRidesDistance',
  units       : getDistanceUnits,
  conversionFn: (units, value) => {
    const distance = getDistance(units, value);
    return distance ? distance.toString() : '—';
  }
}, {
  icon        : <SpeedIcon />,
  label       : 'AVG. SPEED',
  key         : 'userRidesAvgSpeed',
  units       : (units) =>  `${units === MEASUREMENT_SYSTEMS.IMPERIAL ? DISTANCE_UNITS.MI : DISTANCE_UNITS.KM}/hr`,
  conversionFn: (units, value = 0) => 
    ((units === MEASUREMENT_SYSTEMS.IMPERIAL ? mpsToMph(value) : mpsToKmph(value)) || '—').toString()
}];