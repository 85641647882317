import {
  useCallback,
  useContext,
  useMemo,
  useState,
  MouseEvent,
}                                          from 'react';
import {
  Grid,
  IconButton,
  TextField,
}                                          from '@mui/material';
import {
  Support,
  Event,
  AccessTime,
  Bolt,
  Watch,
  Place,
  Home,
  Computer,
  PrivacyTip,
  Close,
}                                          from '@mui/icons-material';
import styled                              from 'styled-components';
import { format }                          from 'date-fns';
import { Path }                            from 'react-hook-form';
import { CTAContext }                      from '@modules/CreateModal';
import { TIME_TYPES }                      from '@models/TimeTypes';
import { Dropdown }                        from '@components/Dropdown';
import { CheckboxFormField }               from '@components/CheckboxFormField';
import { LocationModal }                   from '@components/LocationModal';
import { InputError }                      from '@components/InputError';
import { getDurationFormat }               from '@utils/metrics_utils';
import { DatePickerModal }                 from '../DatePickerModal';
import {
  TIME_PICKER_MODE,
  TimePicker
}                                          from '../TimePicker';
import { RideManagementModal }             from '../RideManagementModal';
import {
  FREQUENCY_OPTIONS,
  INTENSITY_OPTIONS,
  LOCATION_TYPE_OPTIONS,
  PRIVACY_OPTIONS,
  RIDE_DROP_OPTIONS,
  RIDE_TYPES_OPTIONS,
}                                          from '../../models/rideDetailsForm';
import { IRideForm }                       from '../../models/ride';
import { ReactComponent as CyclingIcon }   from '../../../../images/Icon-Sport-Cycling-Grey.svg';
import { ReactComponent as FrequencyIcon } from '../../../../images/Icon-Metric-Frequency-Grey.svg';
import { useGetUserSettingsQuery }         from '../../../Profile/queries';
import {
  ELocationType,
  EManagementType,
}                                          from '../../../Details/models/ride';
import { useGetRidesDatesQuery }           from '../../../Details/queries';

const LOCATION_TYPE_ICONS = {
  [ELocationType.OUTDOOR]: Place,
  [ELocationType.INDOOR] : Home,
  [ELocationType.VIRTUAL]: Computer,
};

export const RideDetails = () => {
  const [isDatePickerOpen, setIsDatePickerOpen]         = useState<boolean>(false);
  const [isTimePickerOpen, setIsTimePickerOpen]         = useState<boolean>(false);
  const [isDurationPickerOpen, setIsDurationPickerOpen] = useState<boolean>(false);
  const [isLocationPickerOpen, setIsLocationPickerOpen] = useState<boolean>(false);
  const [rideMgmtIsOpen, setRideMgmtIsOpen]             = useState<boolean>(false);
  const {
    rideForm,
    rideDetails,
    meetUpLocation,
    handleSaveLocation,
    handleClearLocation,
  } = useContext(CTAContext);
  const {
    register,
    setValue,
    control,
    getValues,
    watch,
    formState: { errors }
  } = rideForm;

  const { data: rides }    = useGetRidesDatesQuery();
  const { data: settings } = useGetUserSettingsQuery();
  const startDate          = getValues('startDate') ? format(getValues('startDate'), 'MM.dd.yyyy') : '';
  const timeFormat         = (settings?.timeType === TIME_TYPES.HOURS_12) ? "h':'mm a" : 'HH:mm';
  const startTime          = getValues('startTime') ? format(getValues('startTime'), timeFormat) : '';
  const duration           = getValues('duration') ? getDurationFormat(getValues('duration') ?? 0) : '';
  const ridesDates         = rides?.map(ride => new Date(ride));

  const isTimeModalOpen = isTimePickerOpen || isDurationPickerOpen;

  const getDateFormValue = useCallback(
    (fieldName: Path<IRideForm>) => {
      const value = getValues(fieldName) as Date;
      return value ? new Date(value) : new Date();
    },
    [getValues]
  );

  const handleCloseTimePicker = useCallback(
    () => {
      setIsTimePickerOpen(false);
      setIsDurationPickerOpen(false);
    },
    [],
  );

  const handleSaveTime = useCallback(
    (value: Date) => {
      const currentValue = getDateFormValue('startTime');
      currentValue.setHours(value.getHours());
      currentValue.setMinutes(value.getMinutes());
      setValue('startTime', currentValue);
    },
    [getDateFormValue, setValue],
  );

  const handleSaveDuration = useCallback(
    (value: number) => {
      setValue('duration', value);
    },
    [setValue],
  );

  const handleRemoveLocation = useCallback(
    (event: MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      handleClearLocation();
    },
    [handleClearLocation],
  );

  const meetUpLocationIcon = useMemo(
    () => {
      if (meetUpLocation) {
        return (
          // @ts-ignore
          <IconButton onClick={handleRemoveLocation}>
            <Close />
          </IconButton>
        );
      }

      return <Place />;
    },
    [handleRemoveLocation, meetUpLocation]
  );

  return (
    <>
      <RideDetails.Container p="0 20px 20px" container rowSpacing="20px">
        <Grid item xs={12}>
          <TextField
            fullWidth
            label   = "*Ride Name"
            variant = "filled"
            error   = {!!errors.name}
            {...register('name')}
          />
          {errors.name && <InputError error={errors.name.message ?? ''} />}
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label      = "Meet Up Location"
            variant    = "filled"
            value      = {meetUpLocation?.placeName ?? rideDetails?.startLocation?.placeName ?? ''}
            InputProps = {{ endAdornment: meetUpLocationIcon }}
            onClick    = {() => setIsLocationPickerOpen(true)}
            error      = {!!errors.startLocationId}
          />

          {errors.startTime && <InputError error={errors.startTime.message ?? ''} />}
        </Grid>

        <Grid item container xs={12} columnSpacing="10px" rowSpacing="20px">
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label      = "*Start Date"
              variant    = "filled"
              value      = {startDate}
              InputProps = {{ endAdornment: <Event /> }}
              onClick    = {() => !rideDetails && setIsDatePickerOpen(true)}
              error      = {!!errors.startDate}
              disabled   = {!!rideDetails}
            />
            {errors.startDate && <InputError error={errors.startDate.message ?? ''} />}
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label      = "*Start Time"
              variant    = "filled"
              value      = {startTime}
              InputProps = {{ endAdornment: <AccessTime /> }}
              onClick    = {() => !rideDetails && setIsTimePickerOpen(true)}
              error      = {!!errors.duration}
              disabled   = {!!rideDetails}
            />
            {errors.startTime && <InputError error={errors.startTime.message ?? ''} />}
          </Grid>
        </Grid>

        <Grid item container xs={12} columnSpacing="10px" rowSpacing="20px">
          <Grid item xs={12} sm={6}>
            <Dropdown
              fullWidth
              name          = "frequency"
              label         = "*Frequency"
              control       = {control}
              options       = {FREQUENCY_OPTIONS}
              iconComponent = {FrequencyIcon}
              error         = {!!errors.frequency}
              disabled      = {Boolean(rideDetails)}
            />
            {errors.frequency && <InputError error={errors.frequency.message ?? ''} />}
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label      = "Duration"
              variant    = "filled"
              value      = {duration}
              InputProps = {{ endAdornment: <Watch /> }}
              onClick    = {() => !(watch('routeId')) && setIsDurationPickerOpen(true)}
              disabled   = {Boolean(watch('routeId'))}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} columnSpacing="10px" rowSpacing="20px">
          <Grid item xs={6}>
            <Dropdown
              fullWidth
              iconComponent = {PrivacyTip}
              name          = "isPublic"
              label         = "Ride Privacy"
              control       = {control}
              options       = {PRIVACY_OPTIONS}
              onChange      = {event => setValue('isPublic', JSON.parse(event.target.value as string))}
            />
          </Grid>

          <Grid item xs={6}>
            <Dropdown
              fullWidth
              iconComponent = {LOCATION_TYPE_ICONS[watch('rideLocationType')]}
              name          = "rideLocationType"
              label         = "Location Type"
              control       = {control}
              options       = {LOCATION_TYPE_OPTIONS}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Dropdown
            fullWidth
            iconComponent = {CyclingIcon}
            name          = "rideType"
            label         = {watch('isPublic') ? '*Ride Type' : 'Ride Type'}
            control       = {control}
            options       = {RIDE_TYPES_OPTIONS}
            error         = {!!errors.rideType}
          />
          {errors.rideType && <InputError error={errors.rideType.message ?? ''} />}
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows    = {5}
            label   = {watch('isPublic') ? '*Description' : 'Description'}
            variant = "filled"
            error   = {!!errors.description}
            {...register('description')}
          />
          {errors.description && <InputError error={errors.description.message ?? ''} />}
        </Grid>

        <Grid item container xs={12} columnSpacing="10px" rowSpacing="20px">
          <Grid item xs={12} sm={6}>
            <Dropdown
              fullWidth
              iconComponent = {Support}
              name          = "hasDrops"
              label         = {watch('isPublic') ? '*Ride Drop' : 'Ride Drop'}
              control       = {control}
              options       = {RIDE_DROP_OPTIONS}
              onChange      = {event => setValue('hasDrops', JSON.parse(event.target.value as string))}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Dropdown
              fullWidth
              iconComponent = {Bolt}
              name          = "intensity"
              label         = {watch('isPublic') ? '*Intensity' : 'Intensity'}
              control       = {control}
              options       = {INTENSITY_OPTIONS}
              error         = {!!errors.intensity}
            />
            {errors.intensity && <InputError error={errors.intensity.message ?? ''} />}
          </Grid>
        </Grid>
        {/* Hide ride management until it's ready */}
        {/* <Grid item xs={12}>
          <RideDetails.MgmtField
            fullWidth
            variant    = "filled"
            label      = "*Ride Management"
            value      = {getValues('management')?.toLowerCase() || ''}
            onClick    = {() => setRideMgmtIsOpen(true)}
            InputProps = {{endAdornment: <RideMgmtIcon />}}
            error      = {!!errors.management}
          />
          {errors.management && <InputError error={errors.management.message ?? ''} />}
        </Grid> */}

        <Grid item container xs={12}>
          <Grid item xs={6}>
            <CheckboxFormField
              control = {control}
              name    = "hasStops"
              label   = "Ride Stops"
            />
          </Grid>

          <Grid item xs={6}>
            <CheckboxFormField
              control = {control}
              name    = "canRegroup"
              label   = "Regroups"
            />
          </Grid>
        </Grid>
      </RideDetails.Container>

      {isLocationPickerOpen && (
        <LocationModal
          isOpen
          isRideLocation
          defaultValue       = {meetUpLocation ?? rideDetails?.startLocation}
          onClose            = {() => setIsLocationPickerOpen(false)}
          handleSaveLocation = {handleSaveLocation}
        />
      )}

      {isDatePickerOpen && (
        <DatePickerModal
          isOpen
          defaultValue = {getValues('startDate')}
          onClose      = {() => setIsDatePickerOpen(false)}
          onSave       = {(value: Date) => setValue('startDate', value)}
          selectedDays = {ridesDates}
        />
      )}

      {isTimeModalOpen && (
        <TimePicker
          isOpen
          subtitle        = {isTimePickerOpen ? 'Start Time' : 'Duration'}
          mode            = {isTimePickerOpen ? TIME_PICKER_MODE.TIME : TIME_PICKER_MODE.DURATION}
          minutesStep     = {isTimePickerOpen ? 5 : 15}
          timeType        = {settings?.timeType as TIME_TYPES || TIME_TYPES.HOURS_12}
          defaultTime     = {getValues('startTime')}
          defaultDuration = {getValues('duration')}
          onClose         = {handleCloseTimePicker}
          onSave          = {isTimePickerOpen ? handleSaveTime : handleSaveDuration}
        />
      )}

      <RideManagementModal
        isOpen       = {rideMgmtIsOpen}
        onClose      = {() => setRideMgmtIsOpen(false)}
        defaultValue = {getValues('management')}
        onSave       = {(value: EManagementType) => setValue('management', value)}
      />
    </>
  )
}

RideDetails.Container = styled(Grid)`
  padding-top: 20px;

  position: relative;
  .MuiFilledInput-root path {
    fill: ${({ theme: {colors: {type: { medium }}}}) => medium };
  }
`;

RideDetails.MgmtField = styled(TextField)`
  .MuiFilledInput-input {
    text-transform: capitalize;
  }
`;
