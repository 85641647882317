import {
  useCallback,
  useEffect,
  useState,
}                       from 'react';
import { useForm }      from 'react-hook-form';
import styled           from 'styled-components';
import * as Yup         from 'yup';
import { yupResolver }  from '@hookform/resolvers/yup';
import { Button, Grid } from '@mui/material';
import { Tabset }       from '@components/Tabset';
import { TextField }    from '@components/TextField';
import { 
  IInvitationLinkData, 
}                       from '@modules/Details/models/invitation';
import { 
  ACCOUNT_TYPE_TABS, 
  useAuth 
}                       from '@modules/Auth/contexts/AuthContext';
import { 
  isUserOldEnough,
  isAgeValid
}                       from '@utils/birthdate_utils';

interface ISignupForm {
  signupCode      : string;
  firstName       : string;
  lastName        : string;
  username        : string;
  email           : string;
  password        : string;
  birthdate       : string;
  isClubAccount   : boolean;
  passwordConfirm : string;
  inviteCode?     : string | null;
  plannedRideId?  : string;
  eventDate?      : string;
}

interface ISignupFormError {
  code    : string;
  message : string;
  target  : "birthdate" | "password" | "email" | "firstName" | "lastName" | "signupCode" | "username" | "passwordConfirm" | "isClubAccount" | "inviteCode" | "plannedRideId" | "eventDate";
}

const initialValues: ISignupForm = {
  signupCode      : '',
  firstName       : '',
  lastName        : '',
  username        : '',
  email           : '',
  password        : '',
  passwordConfirm : '',
  birthdate       : '',
  isClubAccount   : false
};
const NAME_REGEX = /[^a-zA-Z,.'\-]/;

const validationSchema = Yup.object({
  signupCode: Yup.string()
    .required("Required")
    .min(6, "Must be exactly 6 characters")
    .max(6, "Must be exactly 6 characters"),
  firstName: Yup.string()
    .trim("Name cannot be empty or contain trailing spaces")
    .min(2, "Must be at least 2 characters")
    .required("Required")
    .test('special-characters-test', 'No numbers or special characters allowed', (name = '') => !NAME_REGEX.test(name)),
  lastName: Yup.string()
    .trim("Name cannot be empty or contain trailing spaces")
    .min(2, "Must be at least 2 characters")
    .required("Required")
    .test('special-characters-test', 'No numbers or special characters allowed', (name = '') => !NAME_REGEX.test(name)),
  username: Yup.string()
    .required("Required")
    .min(6, "Must be at least 6 characters"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Required"),
  passwordConfirm: Yup.string()
    .min(8, "Must be at least 8 characters")
    .oneOf([Yup.ref("password")], "Passwords don't match")
    .required("Required"),
    birthdate: Yup.string()
    .test('age-validation','Sorry! You must be 13 years or older to sign up', (date) => isUserOldEnough(date))
    .test('age-validation','Please select a valid date', (date) => isAgeValid(date))
    .required("Required"),
});

interface SignupFormProps {
  onClickNext        : () => void;
  signupFormVisible  : boolean;
  invitationLinkData?: IInvitationLinkData;
}

/**
 * @description SignupForm is a Signup sub-component that renders the text input form for the signup flow
 * @component
 * @property {Function} onClickNext Method to invoke when the Next button is clicked
 * @example
 * <SignupForm />
 */
export const SignupForm = ({
  onClickNext,
  signupFormVisible,
  invitationLinkData
}: SignupFormProps) => {
  const [error, setError]  = useState<string>('');
  const { signup, signin } = useAuth()!;

  const {
    control,
    handleSubmit,
    getValues,
    setError: setFormError,
    formState: { isDirty, isValid, isSubmitting }
  } = useForm<ISignupForm>({
    defaultValues: {
      ...initialValues,
    },
    resolver     : yupResolver(validationSchema),
    mode         : 'onChange',
  });

  const onSubmit = useCallback(
  ({
    email,
    firstName,
    lastName,
    password,
    username,
    signupCode,
    plannedRideId,
    eventDate,
    isClubAccount,
    birthdate
  }: ISignupForm) => signup({
    email,
    firstName,
    lastName,
    password,
    username,
    signupCode,
    plannedRideId,
    eventDate,
    isClubAccount,
    inviteCode: invitationLinkData?.inviteCode ?? undefined,
    birthdate
  })
      .then(() => {
        signin(email, password).then(onClickNext);
      })
      .catch(e => {
        e.response.data.errors.forEach((error: ISignupFormError) => {
          setFormError(error.target, {
            type: "custom",
            message: error.message,
          });
        });
        setError("Fix the errors above and try again!");
      }),
    [invitationLinkData?.inviteCode, onClickNext, signin, signup, setFormError]
  );

  return (
    <SignupForm.WrapperWithError>
      <SignupForm.Wrapper isVisible={signupFormVisible}>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SignupForm.Head>
              <h1>Sign Up with Email</h1>
            </SignupForm.Head>

            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <h4>Account Type</h4>
              </Grid>

              <Grid item>
                <Tabset 
                  dark
                  tabs         = {ACCOUNT_TYPE_TABS}
                  name         = "isClubAccount"
                  control      = {control}
                  defaultValue = {false}
                />
              </Grid>
            </Grid>

            <TextField
              control            = {control}
              name               = "signupCode"
              placeholder        = "Signup Code"
              showValidationIcon = {getValues('signupCode').length === 6}
              alphanumeric
              dark
            />

            <TextField
              control     = {control}
              name        = "firstName"
              placeholder = "First Name"
              dark
              // onChange={(e) => setIsFirstEmpty(!e.target.value)}
              />

            <TextField
              control     = {control}
              name        = "lastName"
              placeholder = "Last Name"
              dark
              // onChange={(e) => setIsLastEmpty(!e.target.value)}
            />

            <TextField
              control     = {control}
              name        = "username"
              placeholder = "Preem Handle"
              alphanumeric
              dark
            />

            <TextField
              control     = {control}
              name        = "email"
              placeholder = "Email"
              dark
            />

            <TextField
              control     = {control}
              name        = "password"
              inputType   = "password"
              placeholder = "Password"
              dark
            />

            <TextField
              control     = {control}
              name        = "passwordConfirm"
              inputType   = "password"
              placeholder = "Confirm Password"
              dark
            />

            <TextField
              control     = {control}
              name        = "birthdate"
              inputType   = "date"
              placeholder = "Date of Birth"
              dark
            />

            <Button
              fullWidth
              type     = "submit"
              variant  = "contained"
              size     = "large"
              disabled = {isSubmitting || !isDirty || (isDirty && !isValid)}
            >
              Next
            </Button>
          </form>
        </div>
      </SignupForm.Wrapper>
      {error && <SignupForm.ErrorWrapper>{error}</SignupForm.ErrorWrapper>}
    </SignupForm.WrapperWithError>
  );
};
SignupForm.WrapperWithError = styled.div`
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  transition      : opacity 300ms ease-in;
`;

SignupForm.Wrapper = styled.div`
  display         : flex;
  width           : 320px;
  flex-direction  : column;
  align-items     : stretch;
  flex            : 0 0 auto;
  transition      : opacity 300ms ease-in;
  opacity         : ${({ isVisible }: { isVisible: boolean }) => (isVisible ? 1 : 0)};

  @media screen and (max-width: 479px) {
    align-items: center;
  }

  .MuiButton-root {
    justify-content: center;
  }

  h4 {
    color: #FFF;
  }
`;

SignupForm.ErrorWrapper = styled.div`
  color           : ${({ theme }) => theme.colors.primary.white};
  margin-top      : 20px;
  font-size       : 22px;
  line-height     : 124%;
  font-weight     : 700;
`;

SignupForm.Head = styled.div`
  position        : relative;
  display         : flex;
  margin          : 16px 0;
  flex-direction  : column;
  justify-content : center;
  align-self      : stretch;
  text-align      : center;

  @media screen and (min-width: 1920px) {
    margin-bottom: 16px;
  }

  h1 {
    color         : #fff;
    margin-top    : 0px;
    margin-bottom : 8px;
    font-size     : 22px;
    line-height   : 124%;
    font-weight   : 700;
    letter-spacing: -0.4px;
  }
`;
