import {
  useCallback,
  useMemo,
  useState
}                  from 'react';
import { format }  from 'date-fns';
import { useAuth } from '@modules/Auth/contexts/AuthContext';
import {
  INotification,
  ENotificationAction
}                  from '@components/NotificationsPopper/models/notification';
import {
  useGetUserNotificationsQuery,
  useReadAllNotificationsMutation,
  useReadNotificationByIdMutation
}                  from '@modules/Profile/queries';

const MAX_NAME_LENGTH = 114;

export const useNotifications = () => {
  const { currentUser }         = useAuth();
  const isAuthenticated         = currentUser !== null;
  const [pageSize, setPageSize] = useState<number>(20);
  const [types, setTypes]       = useState<ENotificationAction[]>();
  const [readNotificationById]  = useReadNotificationByIdMutation();
  const [readAllNotifications]  = useReadAllNotificationsMutation();

  const {
    data   : notifications,
    refetch: refetchNotifications,
    isLoading
  } = useGetUserNotificationsQuery({
    pageSize,
    pageNumber: 0,
    actionTypes: types
  }, {
    pollingInterval: 10000,
    skip           : !isAuthenticated
  });

  const unreadCount = useMemo(
    () => notifications?.unreadCount ?? 0,
    [notifications]
  );

  // Trims long ride names to ensure start time gets displayed in notification
  const formatRideName = useCallback((name) => (name.length > MAX_NAME_LENGTH ? name.substring(0, MAX_NAME_LENGTH) + '...' : name), [])

  const getMessage = useCallback(
    (notification: INotification) => {
      switch (notification.actionType) {
        case ENotificationAction.rideInvitationReceived: {
          return `${notification.action.organizerName} has sent an invitation to ${notification.action.rideName}`;
        }
        case ENotificationAction.connectionRequest: {
          return `${notification.action.displayName} wants to connect`;
        }
        case ENotificationAction.processingJobComplete: {
          return notification.action.ridesCreatedCount === notification.action.rideFileCount ?
            'Processing has been completed for uploaded files'
            : `Processing has been completed for ${notification.action.ridesCreatedCount}/${notification.action.rideFileCount} of uploaded files`;
        }
        case ENotificationAction.rideInviteResponseReceived: {
          return `${notification.action.inviteeName} is coming to ${notification.action.rideName}`;
        }
        case ENotificationAction.rideEventCancelled: {
          return `${notification.action.rideName} on ${format(new Date(notification.action.rideDate ?? ''), 'yyyy-MM-dd')} has been canceled`;
        }
        case ENotificationAction.operationFailed: {
          return notification.action.message;
        }
        case ENotificationAction.plannedRideEdited: {
          return `${notification.action.organizerName} has made changes to ${notification.action.rideName}`;
        }
        case ENotificationAction.rideEventReminder: {
          return `${formatRideName(notification.action.rideName)} is coming up at ${(notification.action.rideDate ?? '').match(/\d\d:\d\d/)}`;
        }
        case ENotificationAction.connectionAccepted: {
          return `${notification.action.displayName} has accepted your connection request`;
        }
        case ENotificationAction.invitedUserJoinedViaLink: {
          return `${notification.action.displayName} has joined Preem`
        }
        case ENotificationAction.garminFetchHistoricalDataFailed: {
          return notification.action.message;
        }
        default: {
          return '';
        }
      }
    },
    [formatRideName]
  );

  const getPath = useCallback(
    (notification: INotification) => {
      switch (notification.actionType) {
        case ENotificationAction.rideInvitationReceived: {
          return `/ride/${notification.action.plannedRideId}/${((notification.action.rideDate)?.substring(0, 10).concat('/overview')) ?? 'overview'}`;
        }
        case ENotificationAction.connectionRequest: {
          return `/profile/community`;
        }
        case ENotificationAction.processingJobComplete: {
          return '/profile/ride-history';
        }
        case ENotificationAction.rideInviteResponseReceived: {
          return `/ride/${notification.action.plannedRideId}/${((notification.action.rideDate)?.substring(0, 10).concat('/overview')) ?? 'overview'}`;
        }
        case ENotificationAction.rideEventCancelled: {
          return `/`;
        }
        case ENotificationAction.plannedRideEdited: {
          return `/ride/${notification.action.plannedRideId}/${((notification.action.rideDate)?.substring(0, 10).concat('/overview')) ?? 'overview'}`;
        }
        case ENotificationAction.rideEventReminder: {
          return `/ride/${notification.action.plannedRideId}/${((notification.action.rideDate)?.substring(0, 10).concat('/overview')) ?? 'overview'}`;
        }
        case ENotificationAction.connectionAccepted: {
          return `/user/${notification.originatorUser.username}`;
        }
        case ENotificationAction.invitedUserJoinedViaLink: {
          return `/user/${notification.originatorUser.username}`;
        }
        default: {
          return null;
        }
      }
    },
    []
  );

  return {
    getMessage,
    getPath,
    readNotificationById,
    readAllNotifications,
    refetchNotifications,
    unreadCount,
    setPageSize,
    isLoading,
    setTypes,
    notifications: notifications?.content ?? [],
    totalElements: notifications?.totalElements ?? 0,
    totalPages   : notifications?.totalPages ?? 0,
    pageNumber   : notifications?.pageNumber ?? 0
  }
}
