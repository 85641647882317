import {
  useContext,
  useEffect,
  useState
}                            from 'react';
import { Grid, TextField }   from '@mui/material';
import { FieldWrapper }      from '../../../../FieldWrapper';
import { TitleBlock }        from '../../../../TitleBlock';
import {
  FEET_OPTIONS,
  INCHES_OPTIONS,
  MEASUREMENT_SYSTEMS
}                            from '../../../../../utils/options';
import { privacyTabs }       from '../../../../../utils/tabs';
import { SETTINGS_SECTIONS } from '../../../../../utils/settingsSections';
import { SettingsContext }   from '../../../../../containers/SettingsContainer';
import {
  convertCMtoMeters,
  convertMetersToCm,
  convertMetersToFeet,
  convertMetersToInches,
  convertToMeters
}                            from '../../../../../../../utils/commonFunctions';
import { Dropdown }          from '../../../../../../../components/Dropdown';
import { InputError }        from '../../../../../../../components/InputError';

export const Height = () => {
  const {
    setValue,
    getValues,
    watch,
    register,
    control,
    formState: {
      errors
    }
  } = useContext(SettingsContext);
  const [measurementSystem, setMeasurementSystem] = useState<string>(getValues().measurementSystem ?? MEASUREMENT_SYSTEMS.METRIC);

  useEffect(
    () => {
      const height = getValues().height ?? 0;

      setValue('heightCentimeters', convertMetersToCm(height));
      setValue('heightFeet', `${convertMetersToFeet(height)}`);
      setValue('heightInches', `${convertMetersToInches(height)}`);
    },
    [getValues, setValue]
  );

  useEffect(
    () => {
      const subscription = watch((value, { name  }) => {
        if (name === 'measurementSystem') {
          setMeasurementSystem(value?.measurementSystem ?? MEASUREMENT_SYSTEMS.METRIC);

          if (value.measurementSystem === MEASUREMENT_SYSTEMS.METRIC) {
            const meters = convertToMeters(value.heightFeet, value.heightInches)
            const cm     = convertMetersToCm(meters);

            if (cm) {
              setValue('heightCentimeters', cm);
            }
          }

          if (value.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL) {
            const meters = convertCMtoMeters(value.heightCentimeters);
            const feet   = convertMetersToFeet(meters);
            const inches = convertMetersToInches(meters);

            setValue('heightFeet', `${feet}`);
            setValue('heightInches', `${inches}`);
          }
        }
      });
      return () => subscription?.unsubscribe();
    },
    [setValue, watch]
  );

  return (
    <FieldWrapper>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={4}>
          <TitleBlock
            tabs        = {privacyTabs}
            name        = "isHeightPrivate"
            label       = {SETTINGS_SECTIONS.height.title}
            description = {SETTINGS_SECTIONS.height.description}
            tooltip     = {SETTINGS_SECTIONS.height.tooltip}
          />
        </Grid>

        <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }} />

        <Grid item xs={12} sm={6}>
          {measurementSystem === MEASUREMENT_SYSTEMS.METRIC && (
            <Grid item xs={12}>
              <TextField
                label   = "CM"
                variant = "filled"
                type    = "number"
                error   = {!!errors.heightCentimeters}
                fullWidth
                defaultValue = {getValues().height ? convertMetersToInches(getValues().height) : undefined}
                {...register('heightCentimeters')}
              />

              {errors.heightCentimeters && <InputError error={errors.heightCentimeters.message ?? ''} />}
            </Grid>
          )}

          {measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL && (
            <Grid container spacing={2} justifyContent="end">
              <Grid item xs={12} md={6}>
                <Dropdown
                  label        = "Feet"
                  options      = {FEET_OPTIONS}
                  control      = {control}
                  name         = "heightFeet"
                  defaultValue = {getValues().height ? convertMetersToFeet(getValues().height) : undefined}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Dropdown
                  label        = "Inches"
                  options      = {INCHES_OPTIONS}
                  control      = {control}
                  name         = "heightInches"
                  defaultValue = {getValues().height ? convertMetersToInches(getValues().height) : undefined}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FieldWrapper>
  )
}
