import {
  Grid,
  Radio,
  Typography,
}                     from '@mui/material';
import { NearMe }     from '@mui/icons-material';
import styled         from 'styled-components';
import { scrimBlack } from '@styles/theme';

interface INoRouteRow {
  selectedRouteId: string | undefined;
  onSelectRoute  : (value?: string) => void;
}

export const NoRouteRow = ({
  selectedRouteId,
  onSelectRoute
}: INoRouteRow) => (
  <NoRouteRow.Wrapper container columnSpacing="10px">
    <NoRouteRow.IconContainer item xs={2}>
      <NearMe />
    </NoRouteRow.IconContainer>

    <Grid item xs={8} container alignItems="center" columnSpacing="20px">
      <Grid item xs={12}>
        <Typography variant="subtitle1" sx={{ letterSpacing: 0 }}>Skip Route For Now</Typography>
      </Grid>
    </Grid>

    <Grid item xs={2}>
      <Radio
        checked  = {!selectedRouteId}
        onChange = {() => onSelectRoute(undefined)}
      />
    </Grid>
  </NoRouteRow.Wrapper>
);

NoRouteRow.Wrapper = styled(Grid)`
  border-bottom   : 1px solid ${ scrimBlack(0.08) };
  padding         : 12px 16px;
  margin-bottom   : 10px;
  background-color: #fff;
`;

NoRouteRow.IconContainer = styled(Grid)`
  display        : flex;
  justify-content: center;
  align-items    : center;
  
  svg {
    fill: ${({ theme: { colors } }) => colors.type.light}
  }
`;
