import { AnyAction, combineReducers } from 'redux';
import storage                        from 'redux-persist/es/storage';
import { profileApi }                 from '@modules/Profile/queries';
import { homeApi }                    from '@modules/Calendar/queries';
import { detailsApi }                 from '@modules/Details/queries';
import { location }                   from '@store/reducers/location';
import ui                             from './ui';

const appReducer = combineReducers({
  ui,
  location,
  [profileApi.reducerPath]: profileApi.reducer,
  [homeApi.reducerPath]   : homeApi.reducer,
  [detailsApi.reducerPath]: detailsApi.reducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action?.type === 'LOG_OUT') {

    storage.removeItem('persist:location')

    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
