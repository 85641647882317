import styled from "styled-components";

export enum WeatherIconMonoColor {
  Black  = "Black",
  Grey   = "Grey",
  LtGrey = "Lt-Grey",
  NB300  = "NB-300",
  NB400  = "NB-400",
  Rubine = "Rubine",
  White  = "White",
}

export const WeatherIconMono = styled.div<{
  color?      : WeatherIconMonoColor;
  condition   : string;
}>`
  background-position: center;
  background-size    : contain;
  background-repeat  : no-repeat;
  width              : 18px;
  height             : 18px;
  background-image   : ${({ color, condition }) =>
    `url(${
      require(`@/images/icons/weather/${getIconUrl(condition)}-${
        color ? color : WeatherIconMonoColor.Black
      }.svg`)
    })`};

  && {
    margin-right: 0px;
  }
`;

const getIconUrl = (condition: string) => {
  switch (condition) {
    case "light-wind":
    case "windy":
    case "strong-wind":
      return "Icon-Weather-Windy";

    case "clear":
      return "Icon-Weather-Clear";

    case "mostly-clear":
      return "Icon-Weather-Mostly-Clear";

    case "cloudy":
      return "Icon-Weather-Cloudy";

    case "fog":
    case "light-fog":
      return "Icon-Weather-Fog";

    case "freezing-drizzle":
    case "freezing-rain":
    case "heavy-freezing-rain":
    case "light-freezing-rain":
      return "Icon-Weather-Freezing-Rain";

    case "heavy-rain":
      return "Icon-Weather-Rain-Heavy";

    case "heavy-ice-pellets":
    case "ice-pellets":
    case "light-ice-pellets":
      return "Icon-Weather-Ice-Pellets";

    case "mostly-cloudy":
      return "Icon-Weather-Mostly-Cloudy";

    case "partly-cloudy":
      return "Icon-Weather-Partly-Cloudy";

    case "light-rain":
    case "rain":
      return "Icon-Weather-Rain";

    case "drizzle":
      return "Icon-Weather-Rain-Drizzle";

    case "flurries":
    case "heavy-snow":
    case "light-snow":
    case "snow":
      return "Icon-Weather-Snow";

    case "thunderstorm":
    default:
      return "Icon-Weather-Thunderstorm";
  }
};
