import { useMemo }                  from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import styled                       from 'styled-components';
import ShareIcon                    from '@mui/icons-material/Share';
import { useHistory, useParams }    from 'react-router';
import { scrimBlack }               from '@styles/theme';
import { RespondDropdown }          from '@components/RespondDropdown';
import { useDetails }               from '@modules/Details/context';
import { useAuth }                  from '@modules/Auth/contexts/AuthContext';
import { ECtaTab }                  from '@modules/CreateModal/models/CreateModalContent';
import { INVITATION_STATUS }        from '@modules/Details/models/invitation';
import { useCta }                   from '@utils/hooks/useCta';

interface IFooterProps {
  isRideStarted?         : boolean;
  isOrganizer?           : boolean;
  setCancelModalStatus?  : (isOpen: boolean) => void;
  changeAttendanceStatus?: (status: INVITATION_STATUS) => void;
}

export const Footer = ({
  isRideStarted,
  isOrganizer,
  setCancelModalStatus,
  changeAttendanceStatus
}: IFooterProps) => {
  const { currentUser }                     = useAuth();
  const isAuthorized                        = !!currentUser;
  const { linkUuid }                        = useParams<{ linkUuid?: string; }>();
  const { push }                            = useHistory<{ prevState: string }>();
  const { openCta }                         = useCta();
  const { rideDetails, nearestRideDetails } = useDetails();

  const CancelRideButton = useMemo(
    () => (
      isOrganizer && !isRideStarted ? (
        <Button
          color   = "secondary"
          variant = "contained"
          onClick = {() => setCancelModalStatus?.(true)}
        >
          Cancel
        </Button>
      ) : null
    ),
    [isOrganizer, isRideStarted, setCancelModalStatus]
  );

  const EditRideButton = useMemo(
    () => (
      isOrganizer && !isRideStarted ? (
        <Button
          color   = "secondary"
          variant = "contained"
          onClick = {() => openCta({ tab: ECtaTab.EDIT_RIDE, urlParams: { rideId: rideDetails?.id } })}
        >
          Edit
        </Button>
      ) : null
    ),
    [isOrganizer, isRideStarted, openCta, rideDetails?.id]
  );

  const ShareButton = useMemo(
    () => !linkUuid ? (
      <IconButton
        onClick = {() => openCta({ tab: ECtaTab.SHARE_RIDE })}
      >
        <ShareIcon />
      </IconButton>
    ) : null,
    [linkUuid, openCta]
  );

  const RideAttendanceDropdown = useMemo(
    () => {
      if (!isAuthorized) {
        return (
          <RespondDropdown
            label    = "RSVP"
            onChange = {() => push('/signup')}
          />
        )
      }

      return (
        nearestRideDetails?.invitationStatus && !isRideStarted ? (
          <RespondDropdown
            initialResponse = {nearestRideDetails?.invitationStatus}
            label           = "RSVP"
            onChange        = {changeAttendanceStatus}
          />
        ) : null
      )
    },
    [changeAttendanceStatus, isAuthorized, isRideStarted, nearestRideDetails?.invitationStatus, push]
  );

  return (
    <Footer.Wrapper container justifyContent="space-between" sx={{ display: { xs: 'flex', sm: 'none' } }} >
      {CancelRideButton}
      {EditRideButton}
      {ShareButton}
      {RideAttendanceDropdown}
    </Footer.Wrapper>
  )
}

Footer.Wrapper = styled(Grid)`
  position        : fixed;
  bottom          : 0;
  background-color: ${({theme: {colors: { neutralGrey }}}) => neutralGrey[100]};
  padding         : 12px 16px;
  border-top      : 1px solid ${scrimBlack(0.08)};
`;
