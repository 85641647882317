import { ReactNode }          from 'react';
import { RouteDetails }       from '../components/RouteDetails';
import { Attendance }         from '../components/sections/Attendance';
import { Chat }               from '../components/sections/Chat';
import { RideDetailsInvite }  from '../components/RideDetailsInvite';
import { RideDetails }        from '../components/RideDetails';
import { Photos }             from '../components/sections/Photos';

export enum RIGHT_PANEL_CONTENT_TYPE {
  RIDE_DETAILS        = 'ride-details',
  RIDE_DETAILS_INVITE = 'ride-details-invite',
  RIDE_ATTENDANCE     = 'ride-attendance',
  RIDE_PHOTOS         = 'ride-photos',
  CHAT                = 'chat',
  ROUTE_DETAILS       = 'route-details',
  ROUTE_PHOTOS        = 'route-photos',
}

export const RIGHT_PANEL_CONTENT: {[key in RIGHT_PANEL_CONTENT_TYPE]: {
  content         : ReactNode,
  title?          : string,
  previousContent?: RIGHT_PANEL_CONTENT_TYPE
}} = {
  [RIGHT_PANEL_CONTENT_TYPE.RIDE_DETAILS_INVITE]   : {
    content: <RideDetailsInvite />
  },
  [RIGHT_PANEL_CONTENT_TYPE.RIDE_DETAILS]   : {
    content: <RideDetails showPanelBlocks />
  },
  [RIGHT_PANEL_CONTENT_TYPE.ROUTE_DETAILS]  : {
    content: <RouteDetails />,
  },
  [RIGHT_PANEL_CONTENT_TYPE.RIDE_ATTENDANCE]: {
    content        : <Attendance />,
    title          : 'Attendance',
    previousContent: RIGHT_PANEL_CONTENT_TYPE.RIDE_DETAILS
  },
  [RIGHT_PANEL_CONTENT_TYPE.RIDE_PHOTOS]: {
    content        : <Photos />,
    title          : 'Photos',
    previousContent: RIGHT_PANEL_CONTENT_TYPE.RIDE_DETAILS
  },
  [RIGHT_PANEL_CONTENT_TYPE.CHAT]: {
    content        : <Chat />,
    title          : 'Ride Chat',
    previousContent: RIGHT_PANEL_CONTENT_TYPE.RIDE_DETAILS
  },
  [RIGHT_PANEL_CONTENT_TYPE.ROUTE_PHOTOS]: {
    content        : <Photos isRoute />,
    title          : 'Photos',
    previousContent: RIGHT_PANEL_CONTENT_TYPE.ROUTE_DETAILS
  },
}
