import { useCallback, useState }        from 'react';
import { Modal }                        from '@mui/material';
import { useGetSelfInformationQuery }   from '@modules/Profile/queries';
import { useAuth }                      from '@modules/Auth/contexts/AuthContext';
import { useDetails }                   from '@modules/Details/context';
import { 
  useDeleteRidePhotosMutation,
  useDeleteRoutePhotosMutation
 }                                      from '@modules/Details/queries';
import { IRoutePhoto }                  from '@models/RouteModel';
import { InviteFriendForm }             from '@modules/CreateModal/components/InviteFriendForm';
import { IRidePhoto }                   from '@modules/Details/models/ride';
import { ModalContent }                 from '@components/Modal';
import { AddPhotoModal }                from '@components/AddPhotoModal';
import { PhotoPreview }                 from '@components/PhotoPreview';
import { getRidePhotoUrl }              from '@utils/files_utils';

export enum POPUP_TYPES {
  INVITE_FRIEND = 'inviteFriend',
  PHOTO         = 'photo',
  PHOTO_PREVIEW = 'photoPreview'
}

interface IPopupOptions {
  selectedPhotoIndex? : number;
  photos?             : IRidePhoto[] | IRoutePhoto[];
  rideLink?           : string;
  rideId?             : string;
  rideDate?           : string;
  routeId?            : string;
}

export const Popup = () => {
  const [popupType, setPopupType]                 = useState<POPUP_TYPES | null>(null);
  const [options, setOptions]                     = useState<IPopupOptions>();
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(options?.selectedPhotoIndex ?? 0)

  const [deleteRidePhoto] = useDeleteRidePhotosMutation();
  const [deleteRoutePhoto] = useDeleteRoutePhotosMutation();

  const { currentUser }    = useAuth();
  const isAuthorized       = !!currentUser;
  const { isOrganizer }    = useDetails();
  const { data: selfInfo } = useGetSelfInformationQuery(undefined, { skip: !isAuthorized });

  const isPhotoEditable = useCallback(
    (options: IPopupOptions) => (
      (!!options?.routeId) || 
      (options?.photos && 
        options?.selectedPhotoIndex !== undefined &&
        options?.photos[currentPhotoIndex] && 
        selfInfo?.id === options?.photos[currentPhotoIndex].user?.id))
    ,
    [selfInfo, currentPhotoIndex]
  );
  
  const handleDeletePhoto = useCallback(
    (photoUrl: string) =>  {
      // Delete Ride Photo
      if (options?.rideId) {
        deleteRidePhoto({
          fileLinks: [ photoUrl ],
          rideId: options?.rideId,
        });
      }
      // Delete Route Photo
      if (options?.routeId) {
        deleteRoutePhoto({
         fileLinks: [ photoUrl ],
        })
      }
      if (options) {
        options.photos = options?.photos?.filter((photo) => photo.fileLink !== photoUrl) ?? []; 
      }
    }
    ,
    [deleteRidePhoto, deleteRoutePhoto, options]
  );

  Popup.open = (key: POPUP_TYPES, options?: IPopupOptions) => {
    setPopupType(key);
    setOptions(options);
  }

  return (
    <>
      {popupType === POPUP_TYPES.PHOTO && (
        <AddPhotoModal
          isOpen
          onClose  = {() => setPopupType(null)}
          rideId   = {options?.rideId}
          rideDate = {options?.rideDate}
          routeId  = {options?.routeId}
        />
      )}

      {popupType === POPUP_TYPES.PHOTO_PREVIEW && (
        <PhotoPreview
          selectedPhotoIndex       = {(options as IPopupOptions).selectedPhotoIndex ?? 0}
          photos                   = {(options as IPopupOptions).photos ?? []}
          onClose                  = {() => setPopupType(null)}
          urlGeneratorFn           = {getRidePhotoUrl}
          editable                 = {isOrganizer || isPhotoEditable(options as IPopupOptions)}
          onDelete                 = {handleDeletePhoto}
          updateSelectedPhotoIndex = {setCurrentPhotoIndex}
        />
      )}

      {(popupType === POPUP_TYPES.INVITE_FRIEND) &&
        <Modal
          open
          onClose = {() => setPopupType(null)}
        >
          <ModalContent>
            <InviteFriendForm
              rideLink     = {(options as IPopupOptions)?.rideLink ?? ''}
              onPostSubmit = {() => setPopupType(null)}
            />
          </ModalContent>
        </Modal>
      }
    </>
  );
}

Popup.open = (_key: POPUP_TYPES, _options?: IPopupOptions) => {};