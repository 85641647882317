import { useEffect }                 from 'react';
import { useDispatch }               from 'react-redux';
import styled                        from 'styled-components';
import Scrollspy                     from 'react-ui-scrollspy';
import {
  Grid,
}                                    from '@mui/material';
import { useWindowSize }             from '@utils/hooks/useWindowSize';
import { setShowLoginSignupButtons } from '@store/reducers/ui';
import HeroBackgroundUrl             from '@images/landing/About-Photo-Group-Ride.jpg';
import BGPatternWatermark            from '@images/landing/BG-Pattern-Watermark.svg';
import { NavigationBar }             from './components/NavigationBar';
import { PhotoCredit }               from './components/PhotoCredit';
import { Subsection }                from './components/Subsection';
import { Subsections }               from './models';

export const Landing = () => {
  const { isMobile } = useWindowSize();
  const dispatch     = useDispatch();

  const handleNavItemClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const target = window.document.getElementById(
      e.currentTarget.href.split("#")[1]
    );
    target && target.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(
    () => {
      dispatch(setShowLoginSignupButtons(true));
    },
    [dispatch]
  );

  return (
    <Landing.Wrapper flexDirection="column" container>
      <a id="top"></a>
      <Landing.Hero item container>
        {!isMobile && <Landing.HeroImage/>}
        <Landing.Pattern/>
        {!isMobile && <PhotoCredit
          creditLink = "https://www.instagram.com/dyldahl"
          creditText = "@dyldahl"
          top        = "720px"
        />}
        <Landing.HeroCopy
          container
          item
          flexDirection  = "column"
          justifyContent = "center"
        >
          <Landing.HeroLine />
          <Landing.Headline>
            There&apos;s<br />
            Nothing wrong<br />
            with your ride
          </Landing.Headline>
          <Landing.HeroBody>
            But how you plan it, meet up for it, keep track of it, share it,
            decide on it, and dress for it… those parts could be way better. And
            those are all part of the ride.{" "}
            <Landing.Highlighter>
              So maybe there are a few things wrong with your ride after all.
            </Landing.Highlighter>
          </Landing.HeroBody>
        </Landing.HeroCopy>
      </Landing.Hero>
      <NavigationBar onHandleNavItemClick={handleNavItemClick} subsections={Subsections} />
      <Landing.Subsections>
        <Scrollspy scrollThrottle={100}>
          {Subsections.map((subsection) => {
            return subsection.anchor !== "top" && (
              <div key={subsection.anchor} id={subsection.anchor}>
                <Subsection
                  anchor         = {subsection.anchor}
                  key            = {`${subsection.anchor}`}
                  header         = {subsection.header}
                  subheaderLine1 = {subsection.subheaderLine1}
                  subheaderLine2 = {subsection.subheaderLine2}
                  images         = {subsection.images}
                  cta            = {subsection.cta}
                />
              </div>
            );
          })}
        </Scrollspy>
      </Landing.Subsections>
    </Landing.Wrapper>
  );
}

Landing.Wrapper = styled(Grid)`
    padding-top     : 48px;
    background-color: ${({theme})=>theme.colors.neutralGrey[100]} !important;
    ${({theme: {mediaQueries: {xl}}}) => xl} {
      padding-top: 80px;
    }
`;


Landing.Pattern = styled.div`
    width              : 62%;
    display            : flex;
    flex               : 0 20%;
    background-image   : url(${BGPatternWatermark}), radial-gradient(circle farthest-corner at 0% 0%, #ed0d6c, #8f1165);
    background-position: 0% 50%, 0px 0px;
    background-size    : cover, auto;
    position           : absolute;
    right              : 46%;
    z-index            : 50;
    ${({theme: {mediaQueries: {xs}}}) => xs} {
      flex-basis: 100%;
      width     : 100%;
      right     : 0%;
      height    : 420px;
    }
    ${({theme: {mediaQueries: {sm}}}) => sm} {
      width : 70%;
      right : 36%;
      height: 420px;
    }
    ${({theme: {mediaQueries: {md}}}) => md} {
      right : 30%;
      width : 70%;
      height: 720px;
    }
    ${({theme: {mediaQueries: {xl}}}) => xl} {
      right: 40%;
      width : 60%;
      height: 720px;
    }
    ${({theme: {mediaQueries: {xxl}}}) => xxl} {
      height: 1024px;
    }
`;

Landing.HeroImage = styled.div`
    display               : flex;
    flex-basis            : 80%;
    flex-grow             : 1;
    left                  : 30%;
    right                 : 0%;
    border-top-left-radius: 320px;
    position              : absolute;
    z-index               : 100;
    background-image      : linear-gradient(90deg, rgba(0, 0, 0, 0.4), transparent 30%), url(${HeroBackgroundUrl});
    background-position   : 0px 0px, 100% 50%;
    background-size       : auto, cover;
    background-repeat     : repeat, no-repeat;
    ${({theme: {mediaQueries: {xs}}}) => xs} {
      display: none;
    }
    ${({theme: {mediaQueries: {sm}}}) => sm} {
      display               : flex;
      border-top-left-radius: 200px;
      height                : 420px;
    }
    ${({theme: {mediaQueries: {md}}}) => md} {
      display               : flex;
      border-top-left-radius: 360px;
      height                : 720px;
    }
    ${({theme: {mediaQueries: {xl}}}) => xl} {
      display               : flex;
      border-top-left-radius: 360px;
    }
    ${({theme: {mediaQueries: {xxl}}}) => xxl} {
      display               : flex;
      border-top-left-radius: 512px;
      height                : 1024px;
    }
      
`;

Landing.Hero = styled(Grid)`
    align-self    : stretch;
    display       : flex;
    flex-direction: row;
    ${({theme: {mediaQueries: {xs}}}) => xs} {
      padding: 0 20px;
      height : 420px;
    }
    ${({theme: {mediaQueries: {md}}}) => md} {
      padding: 0 20px;
      height : 720px;
    }
    ${({theme: {mediaQueries: {lg}}}) => lg} {
      padding: 0 4%;
    }
    ${({theme: {mediaQueries: {xl}}}) => xl} {
      padding: 0 8%;
    }
    ${({theme: {mediaQueries: {xxl}}}) => xxl} {
      height : 1024px;
      padding: 0 16%;
    }
`;

Landing.Subsections = styled(Grid)`
    ${({theme: {mediaQueries: {xs}}}) => xs} {
        padding: 0 20px;
    }
    ${({theme: {mediaQueries: {md}}}) => md} {
        padding: 0 20px;
    }
    ${({theme: {mediaQueries: {lg}}}) => lg} {
        padding: 0 4%;
    }
    ${({theme: {mediaQueries: {xl}}}) => xl} {
        padding: 0 8%;
    }
    ${({theme: {mediaQueries: {xxl}}}) => xxl} {
        padding: 0 16%;
    }
`;

Landing.HeroLine = styled.div`
    background-color: ${({theme})=>theme.colors.primary.white};
    height          : 8px;
    width           : 132px;
    border-radius   : 4px;
    ${({theme: {mediaQueries: {xs}}}) => xs} {
      height       : 6px;
      margin-bottom: 24px;
    }
    ${({theme: {mediaQueries: {md}}}) => md} {
      margin-bottom: 40px;
    }
    ${({theme: {mediaQueries: {lg}}}) => lg} {
      margin-bottom: 40px;
    }
`;

Landing.HeroCopy = styled(Grid)`
    width  : 40%;
    z-index: 200;
    ${({theme: {mediaQueries: {xs}}}) => xs} {
      width : auto;
      height: 100%;
    }
    ${({theme: {mediaQueries: {sm}}}) => sm} {
      width: 50%;
    }
    ${({theme: {mediaQueries: {md}}}) => md} {
      width: 44%;
    }
    ${({theme: {mediaQueries: {xxl}}}) => xxl} {
      width: 32%;
    }
`;

Landing.HeroBody = styled.div`
    line-height: 182%;
    font-weight: 700;
    color      : ${({theme})=>theme.colors.primary.white};
    ${({theme: {mediaQueries: {xs}}}) => xs} {
      margin-top : 16px;
      font-size  : 14px;
      font-weight: 700px;
    }
    ${({theme: {mediaQueries: {md}}}) => md} {
      margin-top: 28px;
      font-size : 16px;
    }
    ${({theme: {mediaQueries: {lg}}}) => lg} {
      font-size: 20px;
    }
`;

Landing.Highlighter = styled.i`
    color: ${({theme})=>theme.colors.primary.chartreuse}
`;

Landing.Headline = styled.div`
    line-height   : 100%;
    color         : ${({theme})=>theme.colors.primary.white};
    font-weight   : 900;
    text-transform: uppercase;
    ${({theme: {mediaQueries: {xs}}}) => xs} {
      font-size: 34px;
    }
    ${({theme: {mediaQueries: {md}}}) => md} {
      font-size: 48px;
    }
    ${({theme: {mediaQueries: {lg}}}) => lg} {
      font-size  : 60px;
      line-height: 100%;
    }
`;