import { 
  useEffect, 
  useLayoutEffect, 
  useState 
}                                    from 'react';
import { useSelector, useDispatch }  from 'react-redux';
import { isMobile }                  from 'react-device-detect';
import styled                        from 'styled-components';
import { Link, useHistory }          from 'react-router-dom';
import { showFeed }                  from '@config/api_config';
import { useGetStream }              from '@utils/hooks/useGetStream';
import { HeaderRight }               from './components/HeaderRight';
import { setSmaller, smaller }       from '../../store/reducers/ui';
import { useAuth }                   from '../../modules/Auth/contexts/AuthContext';
import { Logo }                      from './components/Logo';

enum ELink {
  Feed     = '/feed',
  Calendar = '/'
}

export const Header = () => {
  const { chatClient }                   = useGetStream();
  const { location: { pathname } }       = useHistory();
  const { currentUser }                  = useAuth();
  const dispatch                         = useDispatch();
  const [distance, setDistance]          = useState(0);
  const [messageCount, setMessagesCount] = useState(0);
  let   isSmaller                        = useSelector(smaller);

  const resizeHeaderOnScroll = () => {
    const scrollY = window.scrollY;
    dispatch(setSmaller(distance < scrollY));
    setDistance(scrollY);
  };

  useLayoutEffect(() => {
    if (!isMobile) {
      window.addEventListener('scroll', resizeHeaderOnScroll);
    }
    return () => window.removeEventListener('scroll', resizeHeaderOnScroll);
  });

  useEffect(
    () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      chatClient.on('message.new', event => {
        setMessagesCount(event.total_unread_count as number);
      });

      chatClient.on('notification.message_new', event => {
        setMessagesCount(event.total_unread_count as number);
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
      chatClient.on('notification.mark_read', event => {
        setMessagesCount(event.total_unread_count as number);
      });

      setMessagesCount(chatClient.user?.total_unread_count as number ?? 0);
    },
    [chatClient]
  );

  return (
    <Header.Wrapper
      id        = "header"
      isSmaller = {isSmaller}
      isMobile  = {isMobile}
    >
      <Header.LogoWrapper>
        <Logo path="/" />

        {currentUser && !isMobile && <Header.BetaText>BETA</Header.BetaText>}

        {!isMobile && (
          <Header.NavigationWrapper>
            {currentUser && showFeed === 'true' && <Header.NavLink to={ELink.Feed} $isActive={pathname === ELink.Feed}>Feed</Header.NavLink>}
            {currentUser && showFeed === 'true' &&<Header.NavLink to={ELink.Calendar} $isActive={pathname === ELink.Calendar}>Calendar</Header.NavLink>}
          </Header.NavigationWrapper>
        )}
      </Header.LogoWrapper>

      <HeaderRight
        isSmaller     = {isSmaller}
        messagesCount = {messageCount}
        currentUser   = {currentUser}
      />
    </Header.Wrapper>
  );
};

Header.Wrapper = styled.header<{isSmaller: boolean, isMobile: boolean}>`
  position        : absolute;
  left            : 0%;
  top             : 0%;
  right           : 0%;
  bottom          : auto;
  z-index         : 1000;
  display         : flex;
  height          : 64px;
  justify-content : space-between;
  align-items     : center;
  flex            : 0 0 auto;
  background-color: ${({theme: {colors: {primary: {white}}}}) => white};
  box-shadow      : 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  transition      : all 100ms ease-in;
  padding         : 0 6%;

  ${({theme: {mediaQueries: {xs}}}) => xs} {
    height  : 48px;
    position: absolute;
    padding : 0 20px;
  }

  ${({theme: {mediaQueries: {md}}}) => md} {
    height     : 64px;
    padding    : 0 20px;
    position   : fixed;
    align-items: flex-start;

    ${({isSmaller}) => isSmaller && `
      transform: translateY(-100%);
      position : absolute;
    `}
  }

  ${({theme: {mediaQueries: {lg}}}) => lg} {
    padding: 0 4%;
    height : 64px;
    ${({isSmaller}) => isSmaller && `
      position : fixed;
      transform: unset;
      height   : 48px;
    `}
  }

  ${({theme: {mediaQueries: {xl}}}) => xl} {
    padding: 0 8%;
    height : 80px;
    ${({isSmaller}) => isSmaller && `
      height: 48px;
    `}
  }

  ${({theme: {mediaQueries: {xxl}}}) => xxl} {
    padding: 0 16%;
  }

  .header-button {
    width : 36px;
    height: 36px;

    ${({theme: {mediaQueries: {xl}}}) => xl} {
      ${({isSmaller}) => !isSmaller && `
        width: 40px;
        height: 40px;
      `}
    }
  }
`;

Header.LogoWrapper = styled.div`
  position        : relative;
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  height          : 100%;
`;

Header.BetaText = styled.p`
  color      : ${({ theme }) => theme.colors.neutralGrey[600]};
  font-weight: 600;
  font-size  : 15px;
  margin-left: 16px;
`;

Header.NavigationWrapper = styled.div`
  display        : flex;
  justify-content: space-between;
  margin-left    : 32px;
  width          : 130px;
`;

Header.NavLink = styled(Link)<{ $isActive: boolean }>`
  color          : ${({ theme, $isActive }) => $isActive ? theme.colors.darkBlue : theme.colors.neutralGrey[600]};
  font-weight    : 600;
  font-size      : 16px;
  text-decoration: none;
  cursor         : pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.darkBlue};
  }
`;