import styled          from 'styled-components';
import { IConnection } from '@modules/Profile/models/Community';
import { Avatar }      from '@components/Avatar';

interface IUserOptionProps extends IConnection {
  onClick: () => void;
}

export const UserOption = ({
  username,
  profilePictureUrl,
  firstName,
  lastName,
  onClick,
}: IUserOptionProps) => (
  <UserOption.Item onClick={onClick}>
    <Avatar
      size     = {32}
      photoUrl = {profilePictureUrl}
      name     = {`${firstName} ${lastName}`}
    />

    <div>
      <h4>
        @{username}
      </h4>

      <p>
        {firstName} {lastName}
      </p>
    </div>
  </UserOption.Item>
);

UserOption.Item = styled.div`
  padding    : 10px;
  display    : flex;
  gap        : 10px;
  align-items: center;
  cursor     : pointer;
  
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.neutralGrey[300]}; 
  }
  
  h4, p {
    margin   : 0;
    font-size: ${({ theme: { fontSizes } }) => fontSizes.s};
  }
`;
