import { createContext, useContext }  from 'react';
import { IRouteModel }                from '@models/RouteModel';
import { INearestRideDetails, IRide } from '../models/ride';

export interface IDetailsContext {
  rideDate          : string
  rideId            : string;
  isPreview         : boolean;
  rideDetails?      : IRide;
  routeDetails      : IRouteModel;
  nearestRideDetails: INearestRideDetails;
  isOrganizer       : boolean;
}

export const DetailsContext = createContext<IDetailsContext>({} as IDetailsContext);
export const useDetails     = () => useContext(DetailsContext);
