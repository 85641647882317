import { 
  Channel, 
  Chat as ChatProvider, 
  MessageInput, 
  MessageList,
  Window
}                       from 'stream-chat-react';
import styled           from 'styled-components';
import { Message }      from '@components/Chat/components/Message';
import { useDetails }   from '@modules/Details/context';
import { useGetStream } from '@utils/hooks/useGetStream';

export const Chat = () => {
  const { rideId, nearestRideDetails } = useDetails();
  const { chatClient }                 = useGetStream();

  const channel = chatClient?.channel('messaging', `${rideId}_${nearestRideDetails?.date?.replaceAll(':', '-')}`);

  return (
    <Chat.Wrapper>
      {chatClient && (
        <ChatProvider client={chatClient} theme='messaging light'>
          <Channel channel={channel}>
            <Window>
              <MessageList Message={Message} />
              <MessageInput disableMentions />
            </Window>
          </Channel>
        </ChatProvider>
      )}
    </Chat.Wrapper>
  );
};

Chat.Wrapper = styled.div`
  .str-chat str-chat-channel messaging light {
    overflow: scroll;
  }

  .str-chat {
    height: 100%;
  }

  .str-chat__list {
    max-height : calc(100vh - 284px) !important;
    height     : calc(100vh - 284px) !important;
    min-height : calc(100vh - 284px) !important;
  }

  .messaging.str-chat .str-chat__list {
    box-shadow: 0px 0px 2px 0px rgb(34 60 80 / 39%) inset !important;
  }

  .str-chat__input-flat {
    bottom   : 0px;
    width    : 100%;
  }
`;
