import { useCallback }                     from 'react';
import styled                              from 'styled-components';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Control, Controller, Path }       from 'react-hook-form';

export type TabsetItem<T, S> = {
  id    : T;
  label : S;
};

export interface ITabsetProps<T, S> {
  tabs          : TabsetItem<T, S>[];
  control       : Control<any,  object>;
  dark?         : boolean;
  name?         : Path<any>;
  defaultValue? : any;
}

export const Tabset = <T, S>({
  tabs,
  control,
  name,
  defaultValue,
  dark,
}: ITabsetProps<T, S>) => {
  const handleAlignment = useCallback(
    (onChange, newAlignment) => {
      if (newAlignment !== null) {
        onChange(newAlignment);
      }
    },
    []
  );

  return (
    <Controller
      name         = {name || ''}
      control      = {control}
      defaultValue = {defaultValue}
      render       = {({ field }) => (
        <Tabset.Wrapper
          {...field}
          exclusive
          onChange = {(_e, value) => handleAlignment(field.onChange, value)}
          $dark    = {!!dark}
        >
          {tabs.length === 1 ? (
            <Tabset.Pill
              disabled
              value = {tabs[0].id}
              $dark = {!!dark}
            >
              <a>{tabs[0].label}</a>
            </Tabset.Pill>
          ) : (
            tabs.map(tab => (
              <Tabset.Pill
                key      = {`${tab.id}`}
                selected = {field.value === tab.id}
                value    = {tab.id}
                $dark    = {!!dark}
              >
                <a>{tab.label}</a>
              </Tabset.Pill>
            ))
          )}
        </Tabset.Wrapper>
      )}
    />
  )
}

Tabset.Wrapper = styled(ToggleButtonGroup)<{ $dark?: boolean}>`
  && {
    height           : 32px;
    border-radius    : 16px;
    background-color : ${({ $dark }) => $dark ? 'rgba(0,0,0,0.16)' : 'rgba(0, 0, 0, 0.08)'};
  }
`;

Tabset.Pill = styled(ToggleButton)<{ $dark?: boolean }>`
  && {
    display        : flex;
    height         : 32px;
    padding-right  : 16px;
    padding-left   : 16px;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    align-self     : center;
    border-radius  : 16px !important;
    border-width   : 0px !important;
    text-transform : none;
    font-size      : 13px;
    font-weight    : 700;
    color          : ${({ $dark, theme: { colors } }) => $dark ? colors.type.white : colors.type.medium};
    letter-spacing : -0.2px;
  }

  &&:hover {
    background-color : transparent;
  }

  ${({ selected, disabled, theme }) => !selected && !disabled && `
    &&:hover {
      a {
        color: ${theme.colors.primary.rubineLight};
      }
    }
  `}

  ${({ selected, disabled, theme }) => selected && !disabled && `
    background-color : #FFF !important;
    box-shadow       : 0 4px 8px 0 rgb(0 0 0 / 8%), 0 1px 4px 0 rgb(0 0 0 / 8%);

    a {
      color: ${theme.colors.neutralBlue[300]}
    }
  `}

  ${({ disabled, theme }) => disabled && `
    background-color : ${theme.colors.neutralGrey[600]} !important;

    a {
      color: ${theme.colors.primary.white};
    }
  `}
`;
