import { PropsWithChildren }            from 'react';
import styled, { css }                  from 'styled-components';
import { Button, Grid, Modal as Popup } from '@mui/material';
import { scrimBlack }                   from '../../styles/theme';

interface IModalProps {
  isOpen              : boolean;
  width?              : string;
  maxWidth?           : string;
  itemName            : string;
  submitBtnLabel      : string;
  title?              : string;
  submitBtnIsDisabled?: boolean;
  onClose?            : () => void;
  onSave              : () => void;
}

export const Modal = ({
  children,
  isOpen,
  width = '550px',
  maxWidth = '95vw',
  itemName,
  title,
  onClose,
  onSave,
  submitBtnLabel,
  submitBtnIsDisabled
}: PropsWithChildren<IModalProps>) => (
  <Popup
    open    = {isOpen}
    onClose = {onClose}
    disableAutoFocus
    disableRestoreFocus
  >
    <Modal.Content width={width} maxWidth={maxWidth}>
      {title && (
        <Modal.Header>
          <span>{itemName}</span>
          <div><b>{title}</b></div>
        </Modal.Header>
      )}

      {children}

      <Modal.Footer container justifyContent="space-between">
        <Button
          color   = "secondary"
          variant = "contained"
          onClick = {onClose}
          size    = "small"
        >
          Cancel
        </Button>

        <Button
          variant  = "contained"
          onClick  = {onSave}
          size     = "small"
          disabled = {submitBtnIsDisabled}
        >
          {submitBtnLabel}
        </Button>
      </Modal.Footer>
    </Modal.Content>
  </Popup>
);

export const ModalContent = styled.div`
  position        : absolute;
  max-height      : 90%;
  max-width       : 90%;
  transform       : translate(-50%, -50%);
  top             : 50%;
  left            : 50%;
  border-radius   : 8px;
`;

Modal.Content = styled(ModalContent)<{ width: string, maxWidth: string }>`
  ${({ theme: { colors: { primary: { white }} }, width, maxWidth }) => css`
    background-color: ${white};
    width           : ${width};
    max-width       : ${maxWidth};
  `}

  ${({theme: {mediaQueries: {xs}}}) => xs } {
    font-size   : ${({ theme: {fontSizes: {xl}}}) => xl};
    line-height : 28px;
  }
`;

Modal.Header = styled(Grid)`
  ${({theme: {colors: {neutralGrey, type: {medium}, primary: {white}}}}) => css`
    background-color       : ${neutralGrey[800]};
    padding                : 10px 24px 20px;
    border-top-right-radius: 8px;
    border-top-left-radius : 8px;

    span {
      font-size     : 11px;
      color         : ${medium};
      text-transform: uppercase;
      line-height   : 11px;
    }

    div {
      color      : ${white};
      font-size  : 22px;
      line-height: 22px;
    }
  `}
`;

Modal.Footer = styled(Grid)`
  background-color          : ${({theme: {colors: {neutralGrey}}}) => neutralGrey[100]};
  box-shadow                : 0 -1px 0 0 ${scrimBlack(0.08)};
  padding                   : 12px 16px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius : 8px;
`;
