import { ReactElement }                    from 'react';
import { Route, Switch, useLocation }      from 'react-router-dom';
import { Header }                          from '@modules/Profile/components/Header';
import { SettingsContainer }               from '@modules/Profile/containers/SettingsContainer';
import { ProfileContainer }                from '@modules/Profile/containers/ProfileContainer';
import { CommunityContainer }              from '@modules/Profile/containers/CommunityContainer';
import { RideHistoryContainer }            from '@modules/Profile/containers/RideHistoryContainer';
import { useGetUserSettingsQuery }         from '@modules/Profile/queries';
import { RoutesContainer }                 from '@modules/Profile/containers/RoutesContainer';
import { AppsContainer }                   from '@modules/Profile/containers/AppsContainer';
import { Footer }                          from '@components/Footer';
import { withAuth }                        from '@utils/HOCs/withAuth';
import { ReactComponent as ProfileIcon }   from '@images/Icon-Profile-Circle-Grey.svg';
import { ReactComponent as SettingsIcon }  from '@images/Icon-Settings-Grey.svg';
import { ReactComponent as CommunityIcon } from '@images/Icon-Create-Circle-Grey.svg';
import { ReactComponent as AppsIcon }      from '@images/Icon-Social-Link-Grey.svg';
import { ReactComponent as RoutesIcon }    from '@images/Icon-Metric-Routes-Grey.svg';
import { ReactComponent as HistoryIcon }   from '@images/Icon-Metric-Ride-History-Grey.svg';
import { ITab }                            from '@models/tab';

interface IProfileTab extends ITab {
  content?: ReactElement;
}

export const tabsData: IProfileTab[] = [
  {
    label   : 'Profile',
    value   : '/profile',
    icon    : <ProfileIcon />,
    content : <ProfileContainer />,
  },
  {
    label   : 'Community',
    value   : '/profile/community',
    icon    : <CommunityIcon />,
    content : <CommunityContainer />
  },
  {
    label   : 'Ride History',
    value   : '/profile/ride-history',
    icon    : <HistoryIcon />,
    content : <RideHistoryContainer />,
  },
  {
    label   : 'My Routes',
    value   : '/profile/my-routes',
    icon    : <RoutesIcon />,
    content : <RoutesContainer />
  },
  {
    label   : 'Apps',
    value   : '/profile/apps',
    icon    : <AppsIcon />,
    content : <AppsContainer />,
  },
  {
    label   : 'Settings',
    value   : '/profile/settings',
    icon    : <SettingsIcon />,
    content : <SettingsContainer />
  },
];

const ProfileBase = () => {
  const { pathname }       = useLocation();
  const { data: settings } = useGetUserSettingsQuery();

  return (
    <>
      <Header 
        settings = {settings}
        pathname = {pathname }
      />
      
      <Switch>
        {tabsData.map(tab => (
          <Route
            key  = {tab.value}
            path = {tab.value}
            exact
          >
            {tab.content}
          </Route>
        ))}
        
        <Footer />
      </Switch>
    </>
  )
}

export const Profile = withAuth(ProfileBase);
