import { useCallback, useEffect }   from 'react';
import { Button, Container }        from '@mui/material';
import LockIcon                     from '@mui/icons-material/Lock';
import styled                       from 'styled-components';
import { isMobile }                 from 'react-device-detect';
import {
  useHistory,
  useParams
}                                   from 'react-router-dom';
import { useAuth }                  from '@modules/Auth/contexts/AuthContext';
import { RIDE_INVITATION_DATA_KEY } from '@modules/Details/models/invitation';
import { useSessionStorage }        from '@utils/hooks/useSessionStorage';

enum EButtonLink {
  Signup = '/signup',
  Login  = '/login',
  Home   = '/'
}

export const PrivateRideErrorPage = () => {
  const history              = useHistory();
  const { currentUser }      = useAuth();
  const { setItem }          = useSessionStorage();
  const { rideId, rideDate } = useParams<{ rideId: string; rideDate: string}>();
  const isAuthorized         = !!currentUser;

  const handleButtonClick = useCallback(
    (link: EButtonLink) => {
      if (!isAuthorized) {
        setItem(RIDE_INVITATION_DATA_KEY, { plannedRideId: rideId, eventDate: rideDate });
      }
      history.push(link);
    },
    [history, isAuthorized, rideDate, rideId, setItem]
  );

  // This makes the login and signup button in the header redirect to the ride after login
  useEffect(() => {
      setItem(RIDE_INVITATION_DATA_KEY, { plannedRideId: rideId, eventDate: rideDate });
    },
    [rideId, rideDate, setItem]
  );

  return (
    <PrivateRideErrorPage.Wrapper>
      <Container maxWidth="lg">
        <LockIcon />

        <h1>Sorry, you are attempting to view a ride.</h1>

        <div>
          Either log in or contact the ride organizer for a share link
        </div>

        {isAuthorized ? (
          <PrivateRideErrorPage.ButtonWrapper>
            <Button variant='contained' onClick={() => handleButtonClick(EButtonLink.Home)}>
              Home
            </Button>
          </PrivateRideErrorPage.ButtonWrapper>
        ) : (
          <PrivateRideErrorPage.ButtonWrapper>
            <Button variant='contained' onClick={() => handleButtonClick(EButtonLink.Signup)}>
              Sign Up
            </Button>

            <Button variant='contained' onClick={() => handleButtonClick(EButtonLink.Login)}>
              Log In
            </Button>
          </PrivateRideErrorPage.ButtonWrapper>
        )}
      </Container>
    </PrivateRideErrorPage.Wrapper>
  );
};

PrivateRideErrorPage.Wrapper = styled.div`
  margin-top : ${isMobile ? '10px' : '100px'};
  text-align : center;
  height     : 1200px;

  svg {
    font-size : 120px;
    color     : ${({ theme }) => theme.colors.neutralGrey[500]}; 
  }

  button:first-child {
    margin-right: 20px;
  }
`;

PrivateRideErrorPage.ButtonWrapper = styled.div`
  margin-top     : 30px;
  display        : flex;
  justify-content: center;
`;
