import { useMemo }                 from 'react';
import styled                      from 'styled-components';
import {
  ReactComponent as TemperatureIcon
}                                  from '@/images/Icon-Weather-Temperature-Black.svg';
import {
  ReactComponent as WindIcon
}                                  from '@/images/Icon-Weather-Windy-Black.svg';
import { useTable }                from '@utils/hooks/useTable';
import {
  degreesToCompass,
  toMPH
}                                  from '@utils/conversion_utils';
import { roundToDecimal }          from '@utils/metrics_utils';
import {
  getWeatherConditionClassName,
  getFormatDuration
}                                  from '@utils/string_utils';
import { WindDirection }           from '@components/WindDirection';
import {
  IConfigCell,
  TableData,
}                                  from '@components/TableData';
import {
  WeatherIconMono,
  WeatherIconMonoColor,
}                                  from '@components/WeatherIconMono';
import { EWeatherCode }            from '@models/rideWeather';
import {
  IRideHistoryWeather,
  IRideHistory,
}                                  from '@modules/Details/models/rideHistory';
import { HISTORY_STATISTICS }      from '@modules/Details/models/statistics';
import { RecurringStatistics }     from '@modules/Details/components/RecurringStatistics';
import {
  useGetRideHistoryStatisticsQuery,
  useGetRideHistoryQuery,
}                                  from '@modules/Details/queries';
import { useDetails }              from '@modules/Details/context';
import { useGetUserSettingsQuery } from '@modules/Profile/queries';

const renderArrangeValues = (min: number, max: number) => {
  const minValue = min.toFixed();
  const maxValue = max.toFixed();

  return minValue === maxValue ? maxValue : `${minValue}-${maxValue}`;
}

const WeatherCell = ({ weather }: { weather: IRideHistoryWeather }) => (
  <WeatherCell.Wrapper>
    <div>
      <WeatherIconMono condition={getWeatherConditionClassName(weather.weatherCode)} color={WeatherIconMonoColor.Black} />
      <p>{EWeatherCode[weather.weatherCode]}</p>
    </div>

    <div>
      <TemperatureIcon />
      <p>{renderArrangeValues(weather.temperatureMin, weather.temperatureMax)}°<span>c</span></p>
    </div>

    <div>
      <WindIcon />
      <p>{renderArrangeValues(weather.windSpeedMin, weather.windSpeedMax)}<span>km/hr</span></p>
      <WeatherCell.WindDirection direction={degreesToCompass(weather.windDirection)}/>
    </div>
  </WeatherCell.Wrapper>
);

export const RideHistory = () => {
  const { rideId }           = useDetails();
  const { data: settings }   = useGetUserSettingsQuery();
  const { data: statistics } = useGetRideHistoryStatisticsQuery({ rideId });
  const {
    orderBy,
    order,
    onSort,
    pageSize,
    pageNumber,
    setPageNumber,
  } = useTable();

  const { data: history }    = useGetRideHistoryQuery({
    rideId,
    pageNumber,
    pageSize,
    sortColumn    : orderBy,
    sortDirection : order
  });


  const configCells = useMemo<IConfigCell<IRideHistory>[]>(
    () => [{
      label    : 'Date',
      render   : (ride) => {
        const date = new Date(ride.date);

        const year  = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day   = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`
      },
      orderBy       : 'date',
      isBold        : true,
      width         : '10%',
      headAlign     : 'center',
      cellFlexAlign : 'center'
    },
    {
      label         : '# Riders',
      orderBy       : 'riders',
      key           : 'attendeesCount',
      width         : '10%',
      headAlign     : 'center',
      cellFlexAlign : 'center'
    },
    {
      label         : 'Avg Speed',
      orderBy       : 'speed',
      render        : ({ averageSpeed }) => {
        const speed = averageSpeed
          ? roundToDecimal(settings?.measurementSystem === 'METRIC' ? averageSpeed : toMPH(averageSpeed))
          : '—';
        const metricUnit = averageSpeed
          ? settings?.measurementSystem === 'METRIC' ? 'km/h' : 'mph'
          : '—';

        return (
          <RideHistory.SpeedCell>
            <span>{speed} {metricUnit}</span>
          </RideHistory.SpeedCell>
        );
      },
      width         : '10%',
      headAlign     : 'center',
      cellFlexAlign : 'center'
    },
    {
      label         : 'Time',
      width         : '10%',
      render        : (ride) => ride.averageTime ? getFormatDuration(ride.averageTime) : '—',
      headAlign     : 'center',
      cellFlexAlign : 'center'
  }],
    [settings?.measurementSystem],
  );

  return (
    <RideHistory.Wrapper>
      <RecurringStatistics
        withGallery       = {false}
        statisticsMetrics = {HISTORY_STATISTICS}
        data              = {statistics}
      />

      <RideHistory.Container>
        <TableData
          configCells   = {configCells}
          data          = {history?.content || []}
          order         = {order}
          orderBy       = {orderBy}
          onSort        = {onSort}
          setPageNumber = {setPageNumber}
          totalPages    = {history?.totalPages ?? 0}
          title         = "Rides"
          totalElements = {history?.totalElements ?? 0}
        />
      </RideHistory.Container>
    </RideHistory.Wrapper>
  );
}

RideHistory.Wrapper = styled.div`
  width: 100%;

  .MuiGrid-container:not(.MuiGrid-item) {
    justify-content: center;
  }
`;

RideHistory.Container = styled.div`
  padding-top   : 32px;
  padding-right : 8%;
  padding-left  : 8%;
`;

WeatherCell.Wrapper = styled.div`
  display         : flex;
  justify-content : space-between;

  svg {
    width  : 20px;
    height : 20px;
  }

  div {
    display      : flex;
    margin-right : 20px;
  }

  p {
    margin      : 3px 0px 0px 8px;
    font-weight : 500;
    font-size   : 15px;
    line-height : 14px;
  }

  span {
    margin-left : 2px;
    font-size   : 13px;
    line-height : 12px;
    color       : ${({ theme }) => theme.colors.type.medium};
  }
`;

WeatherCell.WindDirection = styled(WindDirection).attrs({
  as: 'span'
})`
  && {
    width       : 24px;
    margin-left : 6px;
  }
`;

RideHistory.SpeedCell = styled.div`
  span {
    margin-left : 2px;
    font-size   : 13px;
    line-height : 12px;
    color       : ${({ theme }) => theme.colors.type.medium};
  }
`;
