import styled            from 'styled-components';
import { Grid }          from '@mui/material';
import BlueLogo          from '@images/Logo-Preem-Blue-100.svg';
import WhiteLogo         from '@images/Logo-Preem-White.svg';
import SkyBlueLogo       from '@images/Logo-Preem-Sky-Blue.svg';
import { useWindowSize } from '@utils/hooks/useWindowSize';

export const Footer = () => {
  const { isMobile } = useWindowSize();

  return (
    <Footer.Container id="footer">
      <Footer.PreemLabel href="#" />
      <Footer.LinksContainer >
        <Footer.CopyrightContainer>
          &copy;{new Date().getFullYear()} PREEM. All rights reserved
        </Footer.CopyrightContainer>
        {!isMobile && <FooterBullet> &bull; </FooterBullet> }
        <FooterLink href="/privacy">
          Privacy
        </FooterLink>
        <FooterBullet> &bull; </FooterBullet>
        <FooterLink className="cky-banner-element">
          cookies
        </FooterLink>
      </Footer.LinksContainer>
    </Footer.Container>
  );
};


Footer.Container = styled.footer`
  position        : relative;
  left            : 0;
  top             : auto;
  right           : 0;
  bottom          : 0;
  z-index         : 30;
  display         : flex;
  flex-direction  : column;
  justify-content : flex-start;
  align-items     : stretch;
  flex            : 0 0 auto;
  margin-top      : 20px;
  background-color: ${({theme: {colors: {darkBlue}}}) => darkBlue};
  padding         : 32px 6% 40px;
  font-size       : 10px;
  line-height     : 140%;
  font-weight     : 600;
  letter-spacing  : 1px;
  text-transform  : uppercase;

  &, a {
    color: ${({theme: {colors: {secondary: {blue}}}}) => blue};
  }

  ${({theme: {mediaQueries: {xs}}}) => xs} {
    padding: 16px 20px 20px 20px;
  }

  ${({theme: {mediaQueries: {sm}}}) => sm} {
    padding: 32px 20px 40px;
  }

  ${({theme: {mediaQueries: {md}}}) => md} {
    padding: 16px 20px 20px;
  }

  ${({theme: {mediaQueries: {lg}}}) => lg} {
    padding: 32px 4% 40px;
  }

  ${({theme: {mediaQueries: {xl}}}) => xl} {
    padding: 32px 8% 40px;
  }
`;

Footer.CopyrightContainer = styled.div`
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    display   : block;
    width     : 100%;
    font-size : 10px;
    margin-top: 12px;
    text-align: center;
  }
  ${({theme: {mediaQueries: {sm}}}) => sm} {
    display   : inline-block;
    width     : auto;
    font-size : 12px;
    margin-top: 0px;
    text-align: left;
  }
`;

Footer.PreemLabel = styled.a`
  display            : block;
  width              : 110px;
  height             : 32px;
  margin-right       : 16px;
  align-items        : center;
  flex               : 0 0 auto;
  background-image   : url(${BlueLogo});
  background-position: 0% 50%;
  background-size    : auto 28px;
  background-repeat  : no-repeat;
  transition         : all 200ms ease-in-out;

  &:hover {
    background-image: url(${WhiteLogo});
  }
  &:active {
    background-image: url(${SkyBlueLogo});
  }

  ${({theme: {mediaQueries: {xs}}}) => xs} {
    align-self     : center;
    background-size: 100% 24px;
    margin-right   : 0px;
  }

  ${({theme: {mediaQueries: {sm}}}) => sm} {
    align-self: stretch;
  }

  ${({theme: {mediaQueries: {md}}}) => md} {
    margin-top     : 4px;
    background-size: auto 24px;
  }

  ${({theme: {mediaQueries: {lg}}}) => lg} {
    width          : 125px;
    background-size: auto 32px;
  }
`;

export const FooterLink = styled.a`
  padding-top    : 12px;
  padding-bottom : 12px;
  transition     : color 200ms ease-in-out;
  line-height    : 128%;
  text-decoration: none;
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    font-size  : 10px;
    font-weight: 500;
  }
  ${({theme: {mediaQueries: {sm}}}) => sm} {
    font-size  : 12px;
    font-weight: 700;
  }
  &:hover {
    cursor: pointer;
    color : ${({theme: {colors: {primary: {white}}}}) => white};
  }

  &:active {
    color: ${({theme: {colors: {secondary: {skyBlue}}}}) => skyBlue};
  }
`;

Footer.LinksContainer = styled.div`
  display  : flex;
  flex-wrap: wrap;
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    justify-content: center;
    align-items    : center;
    align-content  : center;
  }
  ${({theme: {mediaQueries: {sm}}}) => sm} {
    padding-top    : 16px;
    justify-content: flex-start;
  }
  
`;

export const FooterBullet = styled(Grid)`
  padding: 0 8px;
`;
