import { Grid }      from '@mui/material';
import styled        from 'styled-components';
import { CardTypes } from '@modules/Calendar/models';

interface ICardHeaderCalendarProps {
  cardType : CardTypes;
  startTime: string;
  endTime  : string;
}

export const CardHeader = ({
  cardType,
  startTime,
  endTime
}: ICardHeaderCalendarProps) => (
    <CardHeader.Container
      alignItems     = "center"
      $cardType      = {cardType}
      className      = "container-wrapper"
      container
      justifyContent = "center"
    >
      <Grid container justifyContent="space-between" alignItems="center">
        {cardType}
        <div>{startTime}{endTime ? `-${endTime}` : ''}</div>
      </Grid>
    </CardHeader.Container>
  );

CardHeader.Container = styled(Grid)<{ $cardType: CardTypes }>`
  font-weight     : 500;
  font-size       : 12px;
  letter-spacing  : 0.8px;
  text-transform  : uppercase;
  color           : ${({theme: {colors: {primary: { white }}}}) => white};
  height          : 48px;
  padding         : 0 16px;
  
  &.MuiGrid-root.MuiGrid-container {
    background: ${({ theme, $cardType }) => {
      switch ($cardType) {
        case CardTypes.GroupRide:
          return theme.colors.gradientVertical.blue;
        case CardTypes.Invitation:
          return theme.colors.gradientVertical.rubine;
        case CardTypes.RidePlan:
        default:
          return theme.colors.type.dark;
      }
    }} 
  }

  span:nth-child(2) {
    color: ${({theme: {colors: {type: { light }}}}) => light};
  }
`;
