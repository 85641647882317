import { 
  Dialog, 
  Button, 
  useMediaQuery, 
  useTheme, 
}                                     from '@mui/material'
import styled                         from 'styled-components';
import { 
  ReactComponent as BackIcon 
}                                     from '@images/Icon-Chevron-L-Rubine.svg';
import { 
  IConfigCell, 
  TableData 
}                                     from '@components/TableData';
import { 
  EConnectionStatus, 
  IConnection 
}                                     from '@modules/Profile/models/Community';
import { useTable }                   from '@utils/hooks/useTable';
import { scrimBlack }                 from '@styles/theme';
import { useGetUserInvitationsQuery } from '@modules/Profile/queries';

interface IExpandedInvitationsTable {
  onClose    : () => void;
  configCells: IConfigCell<IConnection>[];
  title      : string;
}

export const ExpandedInvitationsTable = ({ 
  onClose,
  configCells,
  title
}: IExpandedInvitationsTable) => {
  const theme       = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const {
    orderBy,
    order,
    onSort,
    setPageNumber,
    pageNumber
  }                  = useTable();

  const { 
    data, 
    isLoading 
  } = useGetUserInvitationsQuery({
    pageNumber,
    pageSize     : 10,
    sortColumn   : orderBy,
    sortDirection: order
  }, { pollingInterval: 10000 });

  return (
    <Dialog
      open
      fullWidth
      onClose            = {onClose}
      maxWidth           = 'lg'
      transitionDuration = {{ exit: 0 }}
    >
      <ExpandedInvitationsTable.BackBtn
        startIcon = {<BackIcon />}
        onClick   = {onClose}
      >
        {isBigScreen && 'Back'}
      </ExpandedInvitationsTable.BackBtn>

      <ExpandedInvitationsTable.Wrapper>
        <TableData
          configCells       = {configCells}
          data              = {data?.content ?? []}
          isLoading         = {isLoading}
          setPageNumber     = {setPageNumber}
          order             = {order}
          orderBy           = {orderBy}
          onSort            = {onSort}
          title             = {title}
          totalPages        = {data?.totalPages ?? 0}
          totalElements     = {data?.totalElements ?? 0}
        />
      </ExpandedInvitationsTable.Wrapper>
    </Dialog>
  )
}

ExpandedInvitationsTable.Wrapper = styled.div`
  height    : 100%;
  background: #FFF;
  padding   : 20px 40px 40px 40px;
`;


ExpandedInvitationsTable.BackBtn = styled(Button)`
  width: max-content;

  &.MuiButton-root {
    top       : 20px;
    color     : ${({ theme }) => theme.colors.primary.rubineLight};
    filter    : unset;

    ${({ theme: {mediaQueries: { xl }}}) => xl } {
      top: 20px;
      left: 0px;
    }
  }

  &:hover {
    opacity: 0.8;
  }
`;

ExpandedInvitationsTable.Status = styled.div<{ status?: EConnectionStatus }>`
  --color: ${({status, theme: { colors: { neutralBlue } }}) => {
    switch(status) {
      case EConnectionStatus.PENDING:
        return neutralBlue[300];
      default:
        return '';
    }
  }};
  border-radius: 30px;
  margin-right : 20px;
  border       : 2px solid var(--color);
  color        : var(--color);
  padding      : 8px 18px;
  box-shadow   : 0px 1px 2px ${scrimBlack(0.04)}, 0px 4px 8px ${scrimBlack(0.04)};
  cursor       : pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.rubineLight};
    border          : ${({ theme }) => `2px solid ${theme.colors.primary.rubineLight}`};
    color           : #FFF; 
  }
`;