import styled           from 'styled-components';
import { IGenLocation } from '../../../../../Profile/models/genLocation';

interface IContentHeroLocationSelectorDropdownProps {
  clickHandler        : (genLocation: IGenLocation) => void;
  locationOptions     : IGenLocation[];
  contentHeroLocation?: IGenLocation;
}

export const ContentHeroLocationSelectorDropdown = ({
  clickHandler,
  contentHeroLocation,
  locationOptions,
}: IContentHeroLocationSelectorDropdownProps) => {
  return (
    <LocationOptionsWrapper>
      {locationOptions &&
        locationOptions.map((locationOption: IGenLocation, index: number) => (
          <LocationLink
            key      = {`locationOption-${index}`}
            selected = {contentHeroLocation?.id === locationOption.id}
            onClick  = {() => { clickHandler(locationOption); }}
          >
            <HighlightBorder />
            
            <div>{locationOption.name}</div>
          </LocationLink>
        ))}
    </LocationOptionsWrapper>
  );
};

const LocationOptionsWrapper = styled.div`
  padding-top   : 8px;
  padding-bottom: 12px;
`;

export const HighlightBorder = styled.div`
  display                   : block;
  transition                : all 200ms ease-in-out;
  width                     : 3px;
  height                    : 100%;
  border-bottom-right-radius: 4px;
  border-top-right-radius   : 4px;
  background-color          : transparent;
  margin-right              : 16px;
  align-self                : flex-start;
`;

interface ILocationLinkProps {
  selected?: boolean;
}

export const LocationLink = styled.div<ILocationLinkProps>`
  align-items    : center;
  align-self     : stretch;
  color          : ${({ theme, selected }) =>
    selected ? theme.colors.neutralBlue[300] : theme.colors.type.medium};
  display        : flex;
  font-size      : 13px;
  font-weight    : 700;
  height         : 32px;
  letter-spacing : -0.25px;
  line-height    : 100%;
  margin-bottom  : 4px;
  margin-top     : 4px;
  padding-right  : 40px;
  text-decoration: none;
  transition     : all 200ms ease-in-out;
  white-space    : nowrap;
  &:active {
    filter: brightness(72%);
  }
  ${({ selected, theme }) =>
    selected &&`
    ${HighlightBorder} {
      background-color: ${theme.colors.neutralBlue[300]}
    }
  `}
  &:hover {
    cursor: pointer;
    color : ${({ theme, selected }) =>
      !selected && theme.colors.primary.rubineLight}
  }
  &:hover ${HighlightBorder} {
    background-color: ${({ theme, selected }) =>
      selected
        ? theme.colors.neutralBlue[300]
        : theme.colors.primary.rubineLight}
`;
