import styled from "styled-components";

export const Privacy = () =>{
    return (
      <Privacy.Wrapper>
        <Privacy.Section>
          <i>Effective July 21, 2021</i>
        </Privacy.Section>

        <Privacy.Header>Privacy Policy</Privacy.Header>

        <Privacy.Section>
          Founded by passionate cyclists, Preem is the first vertical social
          network for riders powered by a smart social+ app that gives you
          personalized route, ride, and connection recommendations.
        </Privacy.Section>

        <Privacy.Section>
          We aggregate the data provided by our members and apply proprietary
          models to enable you to discover, connect, and ride with friends
          wherever you go, indoors and out, bringing the vibrant cycling
          community to life online.
        </Privacy.Section>

        <Privacy.Section>
          We care deeply about your privacy and have prepared this Privacy
          Policy (“Privacy Policy”) to explain how we collect, use, and share
          information we obtain through your use of the websites, applications,
          social media accounts, and other services (“Services”) that are
          operated or controlled by Preem (collectively, “we,” “us” or “our”).
        </Privacy.Section>

        <Privacy.Section>
          We may change this Privacy Policy from time to time. If we make
          changes, we will notify you by revising the date at the top of the
          policy and, in some cases, we may provide you with additional notice
          (such as adding a statement to our homepage or sending you a
          notification). We encourage you to review the Privacy Policy whenever
          you access the Services or otherwise interact with us to stay informed
          about our information practices and the choices available to you.
        </Privacy.Section>

        <Privacy.Section>
          This Privacy Policy does not apply to third party products or services
          or the practices of companies that we do not own or control, including
          other companies you might interact with on or through the Services.
        </Privacy.Section>

        <Privacy.Header>Information Preem Collects</Privacy.Header>

        <Privacy.Header>Information You Choose to Provide Us</Privacy.Header>
        
        <Privacy.Section>
          When you use our services, we collect information that you choose to
          share with us including:
        </Privacy.Section>
        
        <Privacy.Section>
          <ul>
            <li>
              Information you provide when you create an account, including name
              and email address
            </li>
            <li>
              Your preferences and settings, such as your language and unit
              settings
            </li>
            <li>
              Data you may provide when communicating with us such as requests
              and feedback or any other data that we may receive in
              communications via email or social media.
            </li>
          </ul>
        </Privacy.Section>
        
        <Privacy.Header>
          Information Automatically Collected When You Use Our Services
        </Privacy.Header>
        
        <Privacy.Section>
          When you access or use our Services, we automatically collect
          information about you, including:
        </Privacy.Section>
        
        <Privacy.Section>
          <ul>
            <li>
              Log Information: We collect log information about your use of the
              Services, including the type of browser you use, app version,
              access times, pages viewed, your IP address and the page you
              visited before navigating to our Services.
            </li>
            <li>
              Device Information: We collect information about the computer or
              mobile device you use to access our Services, including the
              hardware model, operating system and version, unique device
              identifiers, and mobile network information.
            </li>
            <li>
              Information Collected by Cookies and Other Tracking Technologies:
              Preem and its partners may use cookies, clear GIFs (also known as
              web beacons, web bugs or pixel tags), and other tracking
              technologies to collect information about you and your interaction
              with our Services (“Cookie Data”). For more information about the
              types of cookies we use and our reasons for using them, as well as
              your choices concerning cookies, please visit our Cookie Policy.
            </li>
          </ul>
        </Privacy.Section>
        
        <Privacy.Header>How and Why We Use this Information</Privacy.Header>
        
        <Privacy.Section>
          We use the information we collect to provide, maintain, and improve
          our Services. We also use the information we collect to:
        </Privacy.Section>
        
        <Privacy.Section>
          <ul>
            <li>
              Communicate with you for support inquiries or sharing information
              about our Services
            </li>
            <li>Monitor and analyze trends and usage</li>
            <li>Enforce our Terms of Service and policies</li>
            <li>
              Personalize and improve the Service and provide advertisements,
              content, or features that match user profiles or interests
            </li>
            <li>
              Facilitate contests, sweepstakes, and promotions and process and
              deliver entries and rewards
            </li>
            <li>
              Carry out any other purpose described to you at the time the
              information was collected
            </li>
          </ul>
        </Privacy.Section>
        
        <Privacy.Header>How and Why We Share Your Data</Privacy.Header>
        
        <Privacy.Section>
          Preem does not sell, rent, or lease your information, nor will we
          share your information outside of Preem and the Preem community except
          in the following circumstances:
        </Privacy.Section>
        
        <Privacy.Section>
          <ul>
            <li>
              With vendors, consultants, and other service providers who need
              access to such information to carry out work on our behalf, such
              as for payment processing, providing customer service, sending
              marketing communications, fulfilling orders and delivering
              packages, conducting research and analysis, providing cloud
              computing infrastructure and other technology that helps us offer
              our Services and carry out our business, and other business
              functions
            </li>
            <li>
              In response to a request for information if we believe disclosure
              is in accordance with, or required by, any applicable law or legal
              process, including lawful requests by public authorities to meet
              national security or law enforcement requirements
            </li>
            <li>
              If we believe your actions are inconsistent with our terms of
              service or policies, or to protect the rights, property, and
              safety of Preem or others.
            </li>
            <li>
              In connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of
              our business by another company
            </li>
          </ul>
        </Privacy.Section>

        <Privacy.Section>
          We may also share aggregated information, which cannot reasonably be
          used to identify you
        </Privacy.Section>
        
        <Privacy.Header>Retention of Data</Privacy.Header>
        
        <Privacy.Section>
          We store the information we collect about you for as long as is
          necessary for the purpose(s) for which we originally collected it. We
          may retain certain information for legitimate business purposes or as
          required by law.
        </Privacy.Section>
        
        <Privacy.Section>
          Data about your use of the Services will be retained until you delete
          your account with us. We will delete Account Registration Information,
          Financial Information and Device Information within 30 days of your
          deletion request.
        </Privacy.Section>
        
        <Privacy.Header>How We Protect Your Data</Privacy.Header>
        
        <Privacy.Section>
          Preem takes the security of your personal information very seriously.
          We take several measures to safeguard the collection, transmission and
          storage of the data we collect. We employ reasonable protections for
          your information that are appropriate to its sensitivity. The Services
          use industry standard Secure Sockets Layer (SSL) technology to allow
          for the encryption of personal information and credit card numbers.
          Preem engages providers that are industry leaders in online security,
          including Services verification, to strengthen the security of our
          Services. The Services are registered with site identification
          authorities so that your browser can confirm Preem’s identity before
          any personal information is sent.
        </Privacy.Section>
        
        <Privacy.Header>
          Control Over Your Data - Individuals in the European Union
        </Privacy.Header>
        
        <Privacy.Section>
          If you are an individual in the European Union, you have certain
          rights with respect to the access, correction, restriction, and
          erasure of your personal information stored on our platform at any
          time. You can exercise any of these rights at any time by contacting
          us at privacy@gopreem.com. Your rights include the following:
        </Privacy.Section>
        
        <Privacy.Section>
          <ul>
            <li>
              <i>Accessing your data</i>. Upon request, we shall provide any
              information relating to your data and our processing of your data
              in a concise, transparent, intelligible, and easily accessible
              form using clear and plain language. The information shall be
              provided in writing or by other means, including, where
              appropriate, by electronic means within 30 days of a written
              request.
            </li>
            <li>
              <i>Correcting your data</i>. You have the right to ask us to
              rectify any inaccurate or incomplete personal data on our
              platform. If we have given your personal data to any third
              parties, we will notify those third parties that the Company has
              received a request to rectify your personal data, unless doing so
              proves impossible or involves disproportionate effort. Those third
              parties should also rectify the personal data they hold - however,
              we are not in a position to audit those third parties to ensure
              that the rectification has occurred.
            </li>
            <li>
              <i>Erasing your data</i>. You can ask us to erase your personal
              data stored on our platform. If we receive a request to erase your
              data, we will ask you if you want your personal data to be removed
              entirely or if you want to be kept on a list of individuals who do
              not want to be contacted in the future (for a specified period or
              otherwise). We cannot keep a record of individuals whose data we
              have erased so you may be contacted again by us, should we come
              into possession of your personal data at a later date.
            </li>
            <li>
              If we have given your personal data to any third parties, we will
              tell those third parties that the Company has received a request
              to erase your personal data, unless this proves impossible or
              involves a disproportionate effort. Those third parties should
              also rectify the personal data they hold - however, the Company
              will not be in a position to audit those third parties to ensure
              that the rectification has occurred.
            </li>
          </ul>
        </Privacy.Section>
        
        <Privacy.Section>
          <i>Withdrawing your consent</i>. Where we are relying on consent to
          process your personal data (for example consent to receive marketing)
          you have the right to withdraw your consent at any time. However, this
          will not affect the lawfulness of any processing carried out before
          you withdraw your consent. If you withdraw your consent, we may not be
          able to provide certain Services or services to you.
        </Privacy.Section>
        
        <Privacy.Header>
          Control Over Your Data - Individuals Outside the European Union
        </Privacy.Header>
        
        <Privacy.Section>
          <i>Accessing Account Information</i>. We will provide you with the
          means to ensure that personally identifiable information in your web
          account file is correct and current. You may review this information
          by contacting us by sending an email to privacy@gopreem.com.
        </Privacy.Section>

        <Privacy.Section>
          <i>California Online Privacy Protection Act</i>. As required by the
          California Online Privacy Protection Act (“California Act”) and the
          California Business and Professions Code, this Privacy Statement
          identifies the categories of personally identifiable information (as
          that term is defined above and in the California Act) that we collect
          through our Site about individual consumers who use or visit our Site
          and the categories of third-party persons or entities with whom such
          personally identifiable information may be shared. See more about the
          California Act at http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf.
        </Privacy.Section>

        <Privacy.Header>Where We Store and Transfer Your Data</Privacy.Header>

        <Privacy.Section>
          We control this site from our offices in the United States. We may
          store and use information in the United States and other
          jurisdictions; any personal data provided to the us will be
          transmitted to or within those jurisdictions. We also may transfer
          information and personal data to other jurisdictions to facilitate our
          third-party processors’ access to and/or processing of information
          and/or personal data.
        </Privacy.Section>

        <Privacy.Section>
          <i>Individuals in the EU</i>. Whenever we transfer your personal data
          outside the European Economic Area (“EEA”), we ensure a similar degree
          of protection is afforded to it as in the EEA by using specific
          contractual clauses approved by the European Commission which give
          personal data the same protection it has in Europe.
        </Privacy.Section>

        <Privacy.Header>Contact Us</Privacy.Header>

        <Privacy.Section>
          If you have any questions about this Privacy Policy or privacy-related
          matters, please contact us at privacy@gopreem.com.
        </Privacy.Section>

        <Privacy.Section>You can also contact us here:</Privacy.Section>

        <Privacy.Section>
          <b>PREEM, Inc.</b> <br />
          1550 Wewatta, 2 <br />
          Denver, CO 80202 <br />
          Attn: Legal <br />
        </Privacy.Section>
      </Privacy.Wrapper>
    );
}

Privacy.Wrapper = styled.div`
    ${({theme: {mediaQueries: {xs}}}) => xs} {
        padding    : 0 20px;
        padding-top: 70px;
    }

    ${({theme: {mediaQueries: {md}}}) => md} {
        padding    : 0 20px;
        padding-top: 80px;
    }

    ${({theme: {mediaQueries: {lg}}}) => lg} {
        padding    : 0 4%;
        padding-top: 80px;
    }

    ${({theme: {mediaQueries: {xl}}}) => xl} {
        padding: 0 8%;
        padding-top: 100px;
    }

    ${({theme: {mediaQueries: {xxl}}}) => xxl} {
        padding: 0 16%;
        padding-top: 100px;
    }
`

Privacy.Section = styled.div`
    padding-bottom: 1.5em;
`;

Privacy.Header = styled.div`
    padding-bottom: 1.5em;
    font-weight   : bold;
`;

