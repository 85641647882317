import { PropsWithChildren } from 'react';
import styled                from 'styled-components';

interface IHeaderButtonProps {
  buttonClasses?     : string;
  icon?              : string;
  notificationsCount?: number;
  onHandleClick?     : (event: React.MouseEvent<HTMLElement>) => void;
  onHandleMouseOver? : () => void;
}

export const HeaderButton = ({
  onHandleClick,
  onHandleMouseOver,
  icon,
  children,
  notificationsCount = 0
}: PropsWithChildren<IHeaderButtonProps>) => {
  return (
    <HeaderButton.Wrapper
      onMouseOver = {onHandleMouseOver}
      onClick     = {onHandleClick}
    >
      {notificationsCount > 0 && <HeaderButton.NotificationsCount>{notificationsCount}</HeaderButton.NotificationsCount>}
      {icon && (
        <img
          src = {icon}
          alt = ""
        />
      )}
      {children}
    </HeaderButton.Wrapper>
  );
};

HeaderButton.Wrapper = styled.div`
  position         : relative;
  background-color : #EBEAE9;
  border-radius    : 100%;
  width            : 40px;
  min-width        : 40px;
  height           : 40px;
  display          : flex;
  align-items      : center;
  justify-content  : center;
`;

HeaderButton.NotificationsCount = styled.div`
  position        : absolute;
  width           : 17px;
  height          : 17px;
  font-size       : 9px;
  background-color: ${({ theme }) => theme.colors.primary.rubineLight};
  color           : #FFF;
  border-radius   : 100%;
  top             : -7px;
  padding         : 1px;
  right           : -7px;
  display         : flex;
  align-items     : center;
  justify-content : center;
`;