// import { RideTypes } from "../components/RideTypeBadge";
import { UnitType } from "./RouteCardModel";

export interface CalendarCardModel {
  dayOfWeekBegin: string;
  dayOfWeekEnd? : string;
  dateBegin     : string;
  dateEnd?      : string;
  timeBegin?    : string;
  timeEnd?      : string;

  locations       : Location[];
  weather?        : Weather;
  invitationCount?: number;
  groupRideCount? : number;

  organizerPreemHandle?  : string;
  organizerProfilePicUrl?: string;
  organizerName?         : string;

  rideName?: string;
  // rideType?: RideTypes;
  commentCount? : number;
  shareCount?   : number;
  distance?     : number;
  distanceUnit? : UnitType;
  elevation?    : number;
  elevationUnit?: UnitType;
  intensity?    : number;
}

export interface Location {
  city     : string;
  stateProv: string;
  country  : string;
  selected : boolean;
}

export interface Weather {
  condition       : WeatherCondition;
  precipitation   : number;
  minTemperature? : number;
  maxTemperature? : number;
  temperature?    : number;
  temperatureUnits: TemperatureUnitType;
  minWindSpeed    : number;
  maxWindSpeed    : number;
  windSpeedUnits  : WindSpeedUnitType;
  windDirection   : number;
}

export enum TemperatureUnitType {
  f = "FAHRENHEIT",
  c = "CELSIUS",
}
export enum WindSpeedUnitType {
  mph = "mi/hr",
  kph = "km/hr",
}

export enum WindDirection {
  n = "N",
  e = "E",
  s = "S",
  w = "W",
  ne = "NE",
  nw = "NW",
  se = "SE",
  sw = "SW",
}

/** TODO: once this is coming from the API, we might need the tomorrow.io weather codes as listed in the commented code below */
// export enum WeatherCondition {
//   air_quality_acceptable = "Air Quality Acceptable",
//   air_quality_good = "Air Quality Good",
//   air_quality_poor = "Air Quality Poor",
//   clear = "Clear",
//   cloudy = "Cloudy",
//   drizzle = "Drizzle",
//   flurries = "Flurries",
//   fog = "Fog",
//   freezing_drizzle = "Freezing Drizzle",
//   freezing_rain = "Freezing Rain",
//   heavy_freezing_rain = "Heavy Freezing Rain",
//   heavy_ice_pellets = "Heavy Ice Pellets",
//   heavy_rain = "Heavy Rain",
//   heavy_snow = "Heavy Snow",
//   ice_pellets = "Ice Pellets",
//   light_fog = "Light Fog",
//   light_freezing_rain = "Light Freezing Rain",
//   light_ice_pellets = "Light Ice Pellets",
//   light_rain = "Light Rain",
//   light_snow = "Light Snow",
//   mostly_clear = "Mostly Clear",
//   mostly_cloudy = "Mostly Cloudy",
//   partly_cloudy = "Partly Cloudy",
//   rain = "Rain",
//   rain_drizzle = "Rain Drizzle",
//   rain_heavy = "Rain Heavy",
//   snow = "Snow",
//   sun_rise = "Sun Rise",
//   sun_set = "Sun Set",
//   thunderstorm = "Thunderstorm",
//   windy = "Windy",
// }
export enum WeatherCondition {
  // air_quality_acceptable = "Air Quality Acceptable",
  // air_quality_good = "Air Quality Good",
  // air_quality_poor = "Air Quality Poor",
  clear               = 1000,
  cloudy              = 1001,
  drizzle             = 4000,
  flurries            = 5001,
  fog                 = 2000,
  freezing_drizzle    = 6000,
  freezing_rain       = 6001,
  heavy_freezing_rain = 6201,
  heavy_ice_pellets   = 7101,
  heavy_rain          = 4201,
  heavy_snow          = 5101,
  ice_pellets         = 7000,
  light_fog           = 2100,
  light_freezing_rain = 6200,
  light_ice_pellets   = 7102,
  light_rain          = 4200,
  light_snow          = 5100,
  light_wind          = 3000,
  mostly_clear        = 1100,
  mostly_cloudy       = 1102,
  partly_cloudy       = 1101,
  rain                = 4001,
  snow                = 5000,
  thunderstorm        = 8000,
  unknown             = 0,
  windy               = 3001,
  // sun_rise = ,
  // sun_set = ,
}
