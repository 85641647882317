import {
  useCallback,
  useContext,
  useState,
}                                        from 'react';
import {
  Button,
  Grid,
  LinearProgress,
  Box,
}                                        from '@mui/material';
import styled, { css }                   from 'styled-components';
import { scrimBlack }                    from '@styles/theme';
import { FilesUploader }                 from '@components/FilesUploader';
import { Notification }                  from '@components/Notification';
import { callApi }                       from '@utils/apiCaller';
import { NoRouteRow }                    from '@modules/CreateModal/components/NoRouteRow';
import { useCta }                        from '@utils/hooks/useCta';
import { getUploadingProgress }          from '@utils/files_utils';
import { IRouteModel }                   from '@models/RouteModel';
import { CreateRideForm }                from '../CreateRideForm';
import {
  RideFormSelectorTable,
  RideEmptySelectorLabel,
}                                        from '../ConnectionsSelector';
import { ECtaTab }                       from '../../models/CreateModalContent';
import { ReactComponent as ChevronIcon } from '../../../../images/Icon-Chevron-R-Rubine.svg';
import {
  isRouteFileValid,
  ROUTES_UPLOAD_URL
}                                        from '../../../Profile/pages/Routes/components/RoutesUploader';
import { RideRouteRow }                  from '../RideRouteRow';
import { CTAContext }                    from '../..';

interface IRoutesSelector {
  measurementSystem: string;
}

export const RoutesSelector = ({ measurementSystem }: IRoutesSelector) => {
  const {
    selectedRoute,
    setSelectedRoute,
    routes,
    refetchRoutes,
  }                                               = useContext(CTAContext);
  const [uploadingProgress, setUploadingProgress] = useState<number>(0);
  const { openCta }                               = useCta();

  const importingIsInProgress = uploadingProgress > 0 && uploadingProgress < 100;

  const uploadRoute = useCallback(
    async (event) => {
      const file = event.target.files[0];

      if (file) {
        const fileName          = file.name;
        const validationMessage = isRouteFileValid(fileName);

        if (validationMessage) {
          Notification.enqueueSnackbar(validationMessage, 'error');
        } else {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('name', fileName);

          const { data } = await callApi<IRouteModel, FormData>(ROUTES_UPLOAD_URL, 'POST', formData, {
            onUploadProgress: (progressEvent) => {
              const percent = getUploadingProgress(progressEvent);
              setUploadingProgress(percent);
            }
          });

          refetchRoutes?.();
          setSelectedRoute(data);
        }
      }
    },
    [refetchRoutes, setSelectedRoute]
  );

  const handleSelectRoute = useCallback(
    (routeId: string | undefined) => {
      const selectedRoute = routes?.find(({ id }) => id === routeId);

      setSelectedRoute(selectedRoute);
    },
    [routes, setSelectedRoute],
  );

  const TableContent = (
    <>
      <RideFormSelectorTable item>
        <NoRouteRow
          selectedRouteId = {selectedRoute?.id}
          onSelectRoute   = {handleSelectRoute}
        />

        <Box sx={{ backgroundColor: 'white', height: 'calc(100% - 77px)' }}>
          {routes?.map(route => (
            <RideRouteRow
              key               = {route.id}
              route             = {route}
              measurementSystem = {measurementSystem}
              selectedRouteId   = {selectedRoute?.id}
              onSelectRoute     = {handleSelectRoute}
            />
          ))}

          {!routes?.length && !selectedRoute && (
            <RideEmptySelectorLabel>
              <b>No Routes to Display</b>
            </RideEmptySelectorLabel>
          )}
        </Box>
      </RideFormSelectorTable>

      <CreateRideForm.BlockFooter
        item
        container
        justifyContent = {routes?.length ? 'space-between' : 'center'}
        flexWrap       = "nowrap"
      >
        <Grid item>
          <Button
            size    = "small"
            endIcon = {<ChevronIcon />}
            onClick = {() => openCta({ tab: ECtaTab.MY_ROUTES })}
          >
            See All Routes
          </Button>
        </Grid>

        <Grid item>
          <label htmlFor="btn-upload">
            <FilesUploader.Input
              id       = "btn-upload"
              name     = "btn-upload"
              type     = "file"
              accept   = ".tcx, .gpx"
              disabled = {importingIsInProgress}
              onChange = {uploadRoute}
              multiple
            />

            <Button component="span" endIcon={<ChevronIcon />} size="small">
              Import a Route
            </Button>

            {importingIsInProgress && (
              <Grid item xs={12} position="relative" margin="0 14px">
                <LinearProgress value={60} variant="determinate"/>
              </Grid>
            )}
          </label>
        </Grid>
      </CreateRideForm.BlockFooter>
    </>
  );

  return (
    <RoutesSelector.Content item container direction="column" justifyContent="space-between">
      {TableContent}
    </RoutesSelector.Content>
  )
}

RoutesSelector.DropdownBtn = styled(Button)`
  ${({theme: {colors: {primary: { white }}}}) => css`
    color     : ${ white } !important;
    font-size : 22px;
    padding   : 6px 10px 12px 20px;

    &[aria-expanded='true'] {
      background-color: ${scrimBlack(0.16)};
      box-shadow      : 0 4px 16px 0 rgb(0 0 0 / 16%);

      .MuiButton-endIcon {
        transform: rotate(180deg);
      }
    }
  `};
`;

RoutesSelector.Content = styled(Grid)`
  background-color: #EBEBEB;
  height          : 100%;
  border-radius   : 8px;
  overflow        : hidden;

  .MuiLinearProgress-root {
    position: absolute;
    width   : 100%;
  }
`;
