import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
}                         from 'redux-persist'
import { configureStore } from '@reduxjs/toolkit';
import { profileApi }     from '@modules/Profile/queries';
import { homeApi }        from '@modules/Calendar/queries';
import { detailsApi }     from '@modules/Details/queries';
import reducer            from './reducers';

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(
      profileApi.middleware,
      homeApi.middleware,
      detailsApi.middleware
    ),
});

const persistor = persistStore(store);

export { persistor, store };
