import styled             from 'styled-components';
import { EAirQualityNum } from '../../models/rideWeather';

const getAirQualityIconName = (quality: EAirQualityNum) => {
  switch (quality) {
    case EAirQualityNum.Good:
      return 'Icon-Weather-Air-Quality-Good-Black.svg';
    case EAirQualityNum.Moderate:
      return 'Icon-Weather-Air-Quality-Acceptable-Black.svg';
    case EAirQualityNum['Unhealthy for Sensitive Groups']:
    case EAirQualityNum.Unhealthy:
    case EAirQualityNum['Very Unhealthy']:
    case EAirQualityNum.Hazardous:
      return 'Icon-Weather-Air-Quality-Poor-Black.svg';
    default:
      return "Icon-Weather-Air-Quality-Acceptable-Black.svg";
  }
}

export const AirQualityIcon = styled.div<{
  quality : EAirQualityNum;
  width?  : number | string;
  height? : number | string;
}>`
  align-items        : center;
  background-position: 0% 50%;
  background-repeat  : no-repeat;
  background-size    : cover;
  display            : flex;
  height             : ${({ height, width }) => height ?? width ?? '24px'};
  flex               : 0 0 auto;
  width              : ${({ width }) => width ?? '24px'};
  margin-right       : 5px;
  background-image   : ${({ quality }) =>
    `url(${
      require(`@images/${getAirQualityIconName(quality)}`)
    })`};
`;
