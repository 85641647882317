import { IGenLocation }      from '@modules/Profile/models/genLocation';
import {
  EFrequencyType,
  ELocationType,
  ERideTypes,
}                            from '@modules/Details/models/ride';
import { INVITATION_STATUS } from '@modules/Details/models/invitation';

export type WeatherData = {
  forecastDate          : string;
  geohash               : string;
  id                    : string;
  maxTempCelsius        : number;
  minTempCelsius        : number;
  precipitationPercent  : number;
  realFeelMaxTempCelsius: number;
  realFeelMinTempCelsius: number;
  weatherCode           : number;
  windDirection         : number;
  windSpeedMaxMS        : number;
  windSpeedMinMS        : number;
};

export enum CardTypes {
  RidePlan   = 'Ride Plan',
  Invitation = 'Invitation',
  GroupRide  = 'Group Ride',
}

export interface IRideInvitations {
  [key:string]: IRideInvitation[];
}

export interface IAttendees {
  count       : number;
  friendsCount: number;
  topFriends  : { displayName: string; profilePictureUrl: string | null; }[];
}

export interface IRideInvitation {
  plannedRideId    : string;
  invitationStatus : INVITATION_STATUS;
  attendees        : IAttendees;
  organizer        : Organizer;
  ride             : Ride;
}

export interface Organizer {
  id               : string;
  firstName        : string;
  lastName         : string;
  profilePictureUrl: string;
  username         : string;
}

export interface Ride {
  routeType          : any;
  startDate          : string;
  endDate            : string;
  name               : string;
  type               : ERideTypes;
  distance           : number;
  elevationGain      : number;
  intensity          : number;
  timezoneOffset     : number;
  rideLocationType   : ELocationType;
  frequency          : EFrequencyType;
  genLocation        : IGenLocation,
  startLocation?     : RideLocation,
  duration?          : number,
  routeId?           : string,
  routeThumbnailPaths: string[];
}

export type RideLocation = {
  address       : string | null;
  rideId        : string;
  placeName     : string | null;
  longitude     : number;
  latitude      : number;
  thumbnailPaths: string[];
}

export enum WeatherQuality {
  GOOD   = "good",
  BAD    = "bad",
  OK     = "ok",
  NODATA = "nodata"
}

export type UserByIDResponse = {
  id               : string;
  username         : string;
  displayName      : string;
  firstName        : string;
  lastName         : string;
  email            : string;
  profilePictureUrl: string;
}
