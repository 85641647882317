import { TIME_TYPES }          from '@models/TimeTypes';
import { DropdownOptionModel } from '@components/Dropdown';

export interface ITimePickerForm {
  hours     : string;
  minutes   : string;
  dayPeriod?: DAY_PERIOD;
}

export enum DAY_PERIOD {
  AM = 'am',
  PM = 'pm'
}

export const getHoursOptions = (timeType: TIME_TYPES) => {
  const is12HoursType = timeType === TIME_TYPES.HOURS_12;
  const hoursAmount   = is12HoursType ? 12 : 24;

  return Array.from(Array(hoursAmount)).reduce(
    (arr, _value, index) => {
      const hourValue = index.toString();

      const hourOption: DropdownOptionModel = {
        name : is12HoursType && index === 0 ? '12' : hourValue,
        value: hourValue
      };

      arr.push(hourOption);
      return arr;
    },
    []
  );
}

export const getMinOptions = (step: 5 | 15): DropdownOptionModel[] => {
  const totalOptionsLength = 60 / step;

  return Array.from({ length: totalOptionsLength }, (_item, index) => {
    const minuteValue = ('0' + Math.ceil(index * step)).slice(-2).toString();

    return { name: minuteValue, value: minuteValue };
  });
};
