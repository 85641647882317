import styled   from "styled-components";
import { Grid } from "@mui/material";
import { ERideTypes } from "../../modules/Details/models/ride";
import { useEffect, useState } from "react";

export const RideTypeBadge = ({ rideType }: IRideTypeBadge) => {
  const [badgeLabel, setBadgeLabel] = useState<string>("");
  useEffect(() => {
    switch (rideType) {
      case ERideTypes.ROAD:
        setBadgeLabel("RD");
        break;
      case ERideTypes.GRAVEL:
        setBadgeLabel("GRVL");
        break;
      case ERideTypes.MTB:
        setBadgeLabel("MTB");
        break;
      case ERideTypes.CROSS:
        setBadgeLabel("CX");
        break;
      case ERideTypes.TRACK:
        setBadgeLabel("TRK");
        break;
      case ERideTypes.BIKE_PACKING:
        setBadgeLabel("BP");
        break;
      case ERideTypes.RECUMBENT:
        setBadgeLabel("REC");
        break;
      case ERideTypes.ADAPTIVE:
        setBadgeLabel("ADP");
        break;
      case ERideTypes.TOURING:
        setBadgeLabel("TRNG");
        break;
      case ERideTypes.TRIATHLON:
        setBadgeLabel("TLON");
        break;
      case ERideTypes.URBAN:
        setBadgeLabel("URB");
        break;
      case ERideTypes.OTHER:
        setBadgeLabel("OTHR");
        break;
    }
  }, [rideType]);
  return (
  <RideTypeBadge.Wrapper container alignContent={"center"}>
    <RideTypeBadge.Label item>{badgeLabel}</RideTypeBadge.Label>
  </RideTypeBadge.Wrapper>
)};

interface IRideTypeBadge {
  rideType: ERideTypes;
}

RideTypeBadge.Wrapper = styled(Grid)`
  border-radius: 4px;
  border       : 1px solid ${({ theme }) => theme.colors.primary.white};
  height       : 16px;
  padding      : 0 3px 0 5px;
`;

RideTypeBadge.Label = styled(Grid)`
  color         : ${({ theme }) => theme.colors.primary.white};
  font-size     : 9px;
  font-weight   : 600;
  letter-spacing: 1px;
  line-height   : 10px;
  text-transform: uppercase;
`;
