import { format }                 from 'date-fns';
import styled                     from 'styled-components';
import { IRideInvitation }        from '@modules/Calendar/models';
import { IGenLocation }           from '@modules/Profile/models/genLocation';
import { ECtaTab }                from '@modules/CreateModal/models/CreateModalContent';
import { useCta }                 from '@utils/hooks/useCta';
import { useGetPublicRidesQuery } from '@modules/Details/queries';
import { CreateButton }           from '@components/CreateButton';
import { TabsetContent }          from '../../../TabsetContent';
import { TabType }                from '../../../TabsetDay';

interface IContentProps {
  date           : Date;
  invitationCards: IRideInvitation[];
  rideCards      : IRideInvitation[];
  selectedTab    : TabType;
  isMobile?      : boolean;
  activeLocation?: IGenLocation;
}

export const Content = ({
  date,
  invitationCards,
  rideCards,
  selectedTab,
  activeLocation,
  isMobile = false
}: IContentProps) => {
  const { openCta }              = useCta();
  const { data: groupRideCards } = useGetPublicRidesQuery({
    from         : format(date, "yyyy-MM-dd"),
    to           : format(date, "yyyy-MM-dd"),
    genLocationId: activeLocation?.id ?? ''
  }, { skip: !activeLocation });

  return (
    <Content.Wrapper $isMobile={isMobile}>
      <Content.InnerWrapper>
        <Content.CreateButtonWrapper>
          <CreateButton
            label         = "Create a Ride"
            onHandleClick = {() => openCta({ tab: ECtaTab.CREATE_RIDE, urlParams: { date: date.toISOString() } })}
          />
        </Content.CreateButtonWrapper>

        <TabsetContent
          selectedTab     = {selectedTab}
          date            = {format(date, "yyyy-MM-dd")}
          invitationCards = {invitationCards}
          rideCards       = {rideCards}
          groupRideCards  = {groupRideCards}
        />
      </Content.InnerWrapper>
    </Content.Wrapper>
  );
};

Content.Wrapper = styled.div<{ $isMobile: boolean }>`
  display         : flex;
  flex-direction  : column;
  padding         : 32px 32px 0px 32px;
  width           : ${({ $isMobile }) => $isMobile ? '100%' : '350px'};
  background-color: #F2F2F1;
`;

Content.InnerWrapper = styled.div`
  display        : flex;
  flex-direction : column;
  justify-content: start;
  z-index        : 300;
  padding        : 0 32px;
  min-height     : 600px;
`;

Content.CreateButtonWrapper = styled.div`
  display        : flex;
  flex-direction : row;
  justify-content: center;
  padding-bottom : 32px;
`;
