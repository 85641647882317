import {
  useState,
  useCallback,
  ClipboardEvent
}                           from 'react';
import { OGAPIResponse }    from 'getstream';
import { useStreamContext } from 'react-activity-feed';
import { isValidUrl }       from '@utils/commonFunctions';

export const useOg = () => {
  const appCtx                            = useStreamContext();
  const client                            = appCtx.client;
  const [isLoadingOg, setLoadingOgStatus] = useState(false);
  const [ogData, setOgData]               = useState<OGAPIResponse>();

  const onPaste = useCallback(
    async (event: ClipboardEvent<HTMLDivElement>) => {
      const value = event.clipboardData.getData('text');

      const isUrl = isValidUrl(value);

      if (isUrl) {
        setLoadingOgStatus(true);

        try {
          const response = await client?.og(value);

          setOgData(response);
          setLoadingOgStatus(false);
        } catch {
          setLoadingOgStatus(false);
        }
      }
    },
    [client]
  );

  return {
    isLoadingOg,
    onPaste,
    ogData,
    setOgData,
  }
}
