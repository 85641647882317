 import { 
  useCallback, 
  useMemo, 
  useState 
}                                   from 'react';
import { useHistory }               from 'react-router';
import styled                       from 'styled-components';
import { Link }                     from '@mui/material';
import DoneIcon                     from '@mui/icons-material/Done';
import CloseIcon                    from '@mui/icons-material/Close';
import { 
  EConnectionStatus, 
  IConnection 
}                                   from '@modules/Profile/models/Community';
import { 
  useAddUserConnectionMutation, 
  useDeclineUserInvitationMutation, 
  useGetSelfInformationQuery, 
  useGetUserInvitationsQuery, 
}                                   from '@modules/Profile/queries';
import { PreviewTable }             from '@components/PreviewTable';
import { IConfigCell }              from '@components/TableData';
import { Avatar }                   from '@components/Avatar';
import { getProfilePhotoUrl }       from '@utils/files_utils';
import { scrimBlack }               from '@styles/theme';
import { getPluralTitle }           from '@utils/commonFunctions';
import { useTable }                 from '@utils/hooks/useTable';
import { ExpandedInvitationsTable } from './ExpandedInvitationsTable';

const Title = styled.div`
  display    : flex;
  align-items: center;

  a {
    margin-left: 15px;
  }
`;

export const InvitationsTable = () => {
  const { push }                      = useHistory();
  const [isModalOpen, setModalStatus] = useState<boolean>(false);
  const { data: self }                = useGetSelfInformationQuery();
  const [addUserConnection]           = useAddUserConnectionMutation();
  const [declineInvitation]           = useDeclineUserInvitationMutation();
  
  const {
    orderBy,
    order,
    onSort,
    pageNumber
  }                  = useTable();

  const { 
    data, 
    isLoading 
  } = useGetUserInvitationsQuery({
    pageNumber,
    pageSize     : 5,
    sortColumn   : orderBy,
    sortDirection: order
  }, { pollingInterval: 10000 });

  const handleResendInvitation = useCallback(
    (userId: string) => {
      addUserConnection({ userId });
    },
    [addUserConnection]
  );

  const configCells = useMemo<IConfigCell<IConnection>[]>(
    () => [
      {
        label  : 'Name',
        orderBy: 'name',
        render  : ({ firstName, lastName, profilePictureUrl, username }) => (
          <Title>
            <Avatar
              photoUrl = {profilePictureUrl && getProfilePhotoUrl(profilePictureUrl)}
              name     = {firstName}
              size     = {40}
            />
    
            <Link onClick = {() => push(`/user/${username}`)}>
              {firstName} {lastName}
            </Link>
          </Title>
        ),
        width: '300px',
        isBold : true
      },
      {
        label  : 'Location',
        key    : 'locationName',
        orderBy: 'location',
      },
      {
        label  : '',
        width: '100px',
        render : ({ inviterId, id, connectionId }) => (
          <>
            {connectionId && inviterId && inviterId !== self?.id && (
              <InvitationsTable.Actions>
                <div onClick={() => declineInvitation({ connectionId })}>
                  <CloseIcon />
                </div>
                <div onClick={() => addUserConnection({ userId: id, isAccepting: true })}>
                  <DoneIcon />
                </div>
              </InvitationsTable.Actions>
            )}

            {connectionId && inviterId && inviterId === self?.id && (
              <InvitationsTable.Status 
                status  = {EConnectionStatus.PENDING}
                onClick = {() => handleResendInvitation(id)}
              >
                <b>RESEND</b>
              </InvitationsTable.Status>
            )}
          </>
        )
      }
    ],
    [addUserConnection, declineInvitation, handleResendInvitation, push, self?.id]
  );

  return (
    <>
      {isModalOpen  && (
        <ExpandedInvitationsTable 
          onClose     = {() => setModalStatus(false)}
          configCells = {configCells}
          title       = {getPluralTitle('Invitation', data?.totalElements ?? 0)}
        />
      )}

      <PreviewTable 
        onExpand    = {() => setModalStatus(true)}
        title       = {getPluralTitle('Invitation', data?.totalElements ?? 0)}
        totalCount  = {data?.totalElements ?? 0}
        configCells = {configCells}
        data        = {data?.content ?? []}
        isLoading   = {isLoading}
        onSort      = {onSort}
        order       = {order}
        orderBy     = {orderBy}
      />
    </>
  );
}

InvitationsTable.Actions = styled.div`
  display        : flex;
  justify-content: space-around;
  width          : 100%;
  margin-right   : 20px;

  div {
    display        : flex;
    justify-content: center;
    align-items    : center;
    width          : 30px;
    height         : 30px;
    border-radius  : 100%;
    box-shadow     : 0px 0px 8px 0px rgba(34, 60, 80, 0.41);
    cursor         : pointer;
  }

  div:first-child {
    background-color: ${({ theme }) => theme.colors.neutralGrey[600]};

    &:hover {
      background-color: ${({ theme }) => theme.colors.neutralGrey[700]};
    }
  }
  

  div:nth-child(2) {
    background-color: ${({ theme }) => theme.colors.primary.rubineDark};

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary.rubineLight};
    }
  }

  svg {
    font-size : 20px;
    color     : #FFF;
  }

  svg:hover {
    background-color: transparent;
  }
`;

InvitationsTable.Status = styled.div<{ status?: EConnectionStatus }>`
  --color: ${({status, theme: { colors: { neutralBlue } }}) => {
    switch(status) {
      case EConnectionStatus.PENDING:
        return neutralBlue[300];
      default:
        return '';
    }
  }};
  border-radius: 30px;
  margin-right : 20px;
  border       : 2px solid var(--color);
  color        : var(--color);
  padding      : 8px 18px;
  box-shadow   : 0px 1px 2px ${scrimBlack(0.04)}, 0px 4px 8px ${scrimBlack(0.04)};
  cursor       : pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.rubineLight};
    border          : ${({ theme }) => `2px solid ${theme.colors.primary.rubineLight}`};
    color           : #FFF; 
  }
`;