import { Link }           from 'react-router-dom';
import styled             from 'styled-components';
import RubineLogoIcon     from '@images/Logo-Preem-Rubine-Gradient.svg';
import DarkRubineLogoIcon from '@images/Logo-Preem-Rubine-Dark.svg';

interface ILogoProps {
  path: string;
}

export const Logo = ({ path }: ILogoProps) => <Logo.Icon to={path} />;

Logo.Icon = styled(Link)`
  display            : flex;
  height             : 100%;
  align-items        : center;
  align-self         : stretch;
  flex               : 0 0 auto;
  background-image   : url(${RubineLogoIcon});
  background-repeat  : no-repeat;
  transform-origin   : 0 50%;
  transition         : all 200ms ease-in-out;
  width              : 95px;
  padding-bottom     : 4px;
  background-position: 0 40%;
  background-size    : auto 24px;

  &:hover {
    background-image: url(${DarkRubineLogoIcon});
    filter          : brightness(95%);
  }

  &:active {
    background-image: url(${DarkRubineLogoIcon});
    filter          : brightness(80%);
  }

  ${({ theme: { mediaQueries: { md } } }) => md} {
    width          : 110px;
    background-size: auto 28px;
  }

  ${({ theme: { mediaQueries: { xl } } }) => xl} {
    width          : 125px;
    background-size: auto 32px;
  }
`;
