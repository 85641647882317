import { Dispatch, SetStateAction } from "react";

// Set to true to enable show/hide animation announcements in the console
const DEBUG_ANIMATIONS = false;

type visibilityController = {
  label: string;
  set: Dispatch<SetStateAction<boolean>>;
};

/**
 * @description Show a container, then fade its component in
 * @function
 * @example
 * showComponentsAndContainers(
 *    [{ label: "SignupForm", set: setSignupFormVisible }],
 *    [{ label: "SignupFormContainer", set: setSignupFormContainerVisible }],
 * );
 * @param {visibilityController[]} componentVisibilityControllers Array of items to fade in
 * @param {visibilityController[]} containerVisibilityControllers Array of items to show
 */
export function showComponentsAndContainers(
  componentVisibilityControllers: visibilityController[],
  containerVisibilityControllers: visibilityController[]
): void {
  containerVisibilityControllers.forEach(
    (containerVisibilityController: visibilityController) => {
      DEBUG_ANIMATIONS &&
        console.log(`show container ${containerVisibilityController.label}`);
      containerVisibilityController.set(true);
    }
  );

  setTimeout(() => {
    componentVisibilityControllers.forEach((componentVisibilityController) => {
      DEBUG_ANIMATIONS &&
        console.log(`show component ${componentVisibilityController.label}`);
      componentVisibilityController.set(true);
    });
  }, 300);
}

/**
 * @description Fade a component out, then hide its container
 * @function
 * @example
 * hideComponentsAndContainers(
 *    [{ label: "SignupForm", set: setSignupFormVisible }],
 *    [{ label: "SignupFormContainer", set: setSignupFormContainerVisible }],
 * );
 * @param {visibilityController[]} componentVisibilityControllers Array of items to fade out
 * @param {visibilityController[]} containerVisibilityControllers Array of items to hide
 */
export function hideComponentsAndContainers(
  componentVisibilityControllers: visibilityController[],
  containerVisibilityControllers: visibilityController[]
): void {
  componentVisibilityControllers.forEach(
    (componentVisibilityController: visibilityController) => {
      DEBUG_ANIMATIONS &&
        console.log(`hide component ${componentVisibilityController.label}`);
      componentVisibilityController.set(false);
    }
  );

  setTimeout(() => {
    containerVisibilityControllers.forEach((containerVisibilityController) => {
      DEBUG_ANIMATIONS &&
        console.log(`hide container ${containerVisibilityController.label}`);
      containerVisibilityController.set(false);
    });
  }, 300);
}
