import { useCallback, useMemo, useState } from 'react';
import styled                             from 'styled-components';
import { useParams }                      from 'react-router';
import { Button, Grid }                   from '@mui/material';
import { useAuth }                        from '@modules/Auth/contexts/AuthContext';
import { useGetSelfInformationQuery }     from '@modules/Profile/queries';
import { IRidePhoto }                     from '@modules/Details/models/ride';
import { Popup, POPUP_TYPES }             from '@modules/Details/components/Popup';
import { useDetails }                     from '@modules/Details/context';
import { 
  useDeleteRidePhotosMutation,
  useDeleteRoutePhotosMutation,
  useGetRidePhotosQuery, 
  useGetRoutePhotosQuery
}                                         from '@modules/Details/queries';
import { INVITATION_STATUS }              from '@modules/Details/models/invitation';
import { IRoutePhoto }                    from '@models/RouteModel';
import { getRidePhotoUrl }                from '@utils/files_utils';

interface IPhotosProps {
  isRoute?: boolean;
}

export const Photos = ({ isRoute = false }: IPhotosProps) => {
  const [fileLinks, setFileLinks]         = useState<string[]>([])

  const { routeId, linkUuid }             = useParams<{ routeId: string, linkUuid?: string }>();
  const { rideId, rideDate, isOrganizer, nearestRideDetails } = useDetails();
  const {currentUser}                     = useAuth();
  const isAuthorized                      = !!currentUser;
  const { data: selfInfo }                = useGetSelfInformationQuery(undefined, { skip: !isAuthorized });
  const [deleteRidePhoto]                 = useDeleteRidePhotosMutation();
  const [deleteRoutePhoto]                = useDeleteRoutePhotosMutation()

  const { data: ridePhotos } = useGetRidePhotosQuery({ 
    rideId, 
    rideDate,
    pageNumber: 0,
    pageSize  : 50 // TODO: Will update when pagination will be implemented
  }, {
    skip: isRoute || Boolean(linkUuid)
  });

  const { data: routePhotos } = useGetRoutePhotosQuery({
    routeId,
    pageNumber: 0,
    pageSize  : 20  // TODO: Will update when pagination will be implemented (Large page size causes this to break and return undefined)
  }, {
    skip: !isRoute
  });

  const isUsersPhoto = useCallback(
    (photoUserId: string) => ((photoUserId === selfInfo?.id) || isRoute)
    ,
    [selfInfo, isRoute]
  )

  const hideAddButton = useCallback(
    () => isRoute ? false : nearestRideDetails?.invitationStatus !== INVITATION_STATUS.ACCEPTED
    ,
    [nearestRideDetails?.invitationStatus, isRoute]
  );
  
  const addOrRemovePhotoLink = useCallback((fileLink: string) => 
    setFileLinks(fileLinks.includes(fileLink) ? fileLinks.filter((link) => link !== fileLink) : [...fileLinks, fileLink])
    ,
    [fileLinks]
  );

  const handleDeletePhotos = useCallback(
    () => {
      isRoute ? deleteRoutePhoto({ fileLinks }) : deleteRidePhoto({fileLinks, rideId});
      setFileLinks([]);
    }
    ,
    [deleteRidePhoto, deleteRoutePhoto, fileLinks, isRoute, rideId]
  );

  const photos = useMemo(
    () => isRoute ? routePhotos?.content as IRoutePhoto[] : ridePhotos?.content as IRidePhoto[] ?? [],
    [ridePhotos?.content, routePhotos?.content, isRoute]
  );

  const getRouteOrRideId = (isRoute?: boolean) => isRoute ? { routeId } : { rideId };

  return (
    <Photos.Container>
      <Photos.Content>
        <Grid container flexWrap="wrap" spacing={2} justifyContent="flex-start" sx={{ height: '50%' }}>
          {photos.map((photo, index) => (
            <Grid item xs={4} md={3} key={photo?.id}>
              {
                (isRoute || isOrganizer || (photo?.user?.id && isUsersPhoto(photo?.user?.id))) &&  
                <Photos.Checkbox 
                  type     = {'checkbox'}
                  onChange = {() => addOrRemovePhotoLink(photo.fileLink)}
                  checked  = {fileLinks.includes(photo.fileLink)}
                />
              }
              <Photos.Photo 
                onClick = {() => Popup.open(POPUP_TYPES.PHOTO_PREVIEW, { 
                  selectedPhotoIndex: index,
                  photos,
                  ...getRouteOrRideId(isRoute)
                  })
                }
                image   = {getRidePhotoUrl(photo.fileLink)}
              />
            </Grid>
          ))}
        </Grid>
      </Photos.Content>

      <Photos.Footer>
        <Button
          size        = "small"
          variant     = "contained"
          onClick     = {() => Popup.open(POPUP_TYPES.PHOTO, { rideId, rideDate, routeId })}
          disabled    = {hideAddButton()}
        >
          Add a Photo
        </Button>
        {
          <Photos.Delete
            size     = "small"
            variant  = "outlined"
            color    = "primary"
            onClick  = {handleDeletePhotos}
            disabled = {fileLinks.length < 1}
            >
            {fileLinks.length <= 1 ? "Delete Photo" : "Delete Photos"}
         </Photos.Delete>
        }
      </Photos.Footer>
    </Photos.Container>
  );
}

Photos.Checkbox = styled.input`
  cursor  : pointer;
  position: relative;
  top     : 20px;
  left    : 0px;
`;

Photos.Delete = styled(Button)`
  margin-left: 5px;
`

Photos.Container = styled.div`
  position   : relative;
  height     : calc(100% - 96px);
  margin-left: 0;
  width      : 100%;
  box-shadow : inset 0 4px 8px -4px rgb(0 0 0 / 7%);

  ${({ theme: {mediaQueries: { sm }}}) => sm } {
    height     : calc(100% - 60px);
    width      : calc(100% + 95px);
    margin-left: -40px;
  }
`;

Photos.Footer = styled.div`
  display        : flex;
  justify-content: center;
  padding        : 12px 0;
  box-shadow     : 0 -1px 0 0 rgb(0 0 0 / 8%);
`;

Photos.Content = styled.div`
  display  : flex;
  flex-wrap: wrap;
  padding  : 20px 0;
  overflow : scroll;
  height   : 85%;

  ${({ theme: {mediaQueries: { sm }}}) => sm } {
    padding: 40px;
  }
`;

Photos.Photo = styled.div<{ image: string }>`
  transition         : all 200ms ease-in-out;
  cursor             : pointer;
  overflow           : hidden;
  height             : 200px;
  background-image   : ${({ image }) => `url(${image})`};
  background-size    : cover;
  background-repeat  : no-repeat;
  background-position: center;

  &:hover {
    border-radius: 4px;
    box-shadow   : ${({theme: {colors: {primary: { rubineLight }}}}) => `0 0 0 3px ${rubineLight}`};
  }
  &.selected {
    border-radius: 4px;
    box-shadow   : ${({theme: {colors: {primary: { rubineLight }}}}) => `0 0 0 3px ${rubineLight}`};
  }
`;