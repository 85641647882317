import { Button, IconButton, Modal }      from '@mui/material';
import styled                             from 'styled-components';
import { 
  useCallback,
  useEffect,
  useState
}                                         from 'react';
import { ModalContent }                   from '../Modal';
import { ReactComponent as CloseIcon }    from '../../images/Icon-Close-Lt-Grey.svg';
import { ReactComponent as NextIcon }     from '../../images/Icon-Chevron-R-White.svg';
import { ReactComponent as PreviousIcon } from '../../images/Icon-Chevron-L-White.svg';

interface IBasePhoto {
  fileLink: string;
}

interface IPhotoPreviewProps {
  photos                   : IBasePhoto[];
  selectedPhotoIndex       : number;
  onClose                  : () => void;
  editable?                : boolean;
  onDelete?                : (photoPath: string) => void;
  urlGeneratorFn?          : (photoUrl: string) => string;
  updateSelectedPhotoIndex?: (index: number) => void;
}

export const PhotoPreview = ({
  photos,
  selectedPhotoIndex,
  onClose,
  urlGeneratorFn,
  editable,
  onDelete,
  updateSelectedPhotoIndex,
}: IPhotoPreviewProps) => {
  const [selectedPhoto, setSelectedPhoto] = useState<IBasePhoto>(photos[selectedPhotoIndex]);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number>(selectedPhotoIndex);

  useEffect(() => {
      if (updateSelectedPhotoIndex) {
        updateSelectedPhotoIndex(currentPhotoIndex)
      }
    },
    [currentPhotoIndex, updateSelectedPhotoIndex]
  )

  const selectNextPhoto = useCallback(
    () => {
      const selectedPhotoIndex = photos.findIndex(photo => photo === selectedPhoto);
      const nextPhotoIndex = selectedPhotoIndex === (photos.length - 1) ? 0 : selectedPhotoIndex + 1;
      setSelectedPhoto(photos[nextPhotoIndex]);
      setCurrentPhotoIndex(nextPhotoIndex);
    },
    [selectedPhoto, photos]
  );

  const selectPrevPhoto = useCallback(
    () => {
      const selectedPhotoIndex = photos.findIndex(photo => photo === selectedPhoto);
      const prevPhotoIndex = !selectedPhotoIndex ? (photos.length - 1) : selectedPhotoIndex - 1;
      setSelectedPhoto(photos[prevPhotoIndex]);
      setCurrentPhotoIndex(prevPhotoIndex);
    },
    [selectedPhoto, photos]
  );

  const handleDeletePhoto = useCallback(
    () => {
      if (onDelete) {
        onDelete(selectedPhoto?.fileLink ?? photos[selectedPhotoIndex].fileLink)

        photos.length > 1 ? selectNextPhoto() : onClose();
      }
    },
    [selectedPhoto, photos, onClose, onDelete, selectNextPhoto, selectedPhotoIndex]
  )

  useEffect(() => {
    if (!selectedPhoto?.fileLink) {
      const selectedPhotoIndex = photos.findIndex(photo => photo === selectedPhoto);
      const prevPhotoIndex = !selectedPhotoIndex ? (photos.length - 1) : selectedPhotoIndex - 1;
      const nextPhotoIndex = selectedPhotoIndex === (photos.length - 1) ? 0 : selectedPhotoIndex + 1;
      if (photos[prevPhotoIndex]?.fileLink) {
        return selectPrevPhoto()
      }
      if (photos[nextPhotoIndex]?.fileLink) {
        return selectNextPhoto();
      }
      return onClose();
    }
  }, [selectedPhoto, photos, onClose, selectNextPhoto, selectPrevPhoto])

  return (
    <Modal
      open
      onClose = {onClose}
    >
      <ModalContent>
        {(photos.length > 1) && (
          <PhotoPreview.PreviousBtn onClick={selectPrevPhoto}>
            <PreviousIcon />
          </PhotoPreview.PreviousBtn>
        )}

        <PhotoPreview.Photo src={urlGeneratorFn ? urlGeneratorFn(selectedPhoto?.fileLink ?? '') : selectedPhoto?.fileLink ?? ''} alt="Ride" />

        <PhotoPreview.Btn onClick={onClose}>
          <CloseIcon />
        </PhotoPreview.Btn>

        {(photos.length > 1) && (
          <PhotoPreview.NextBtn onClick={selectNextPhoto}>
            <NextIcon />
          </PhotoPreview.NextBtn>
        )}

        {editable && (
          <PhotoPreview.Delete
            variant = "outlined"
            color   = "primary"
            onClick = {handleDeletePhoto}
          >
            Delete Photo
          </PhotoPreview.Delete>
        )}
      </ModalContent>
    </Modal>
  )
};

PhotoPreview.Btn = styled(IconButton)`
  position: absolute;
`;

PhotoPreview.NextBtn = styled(PhotoPreview.Btn)`
  top: 50%;
`;

PhotoPreview.PreviousBtn = styled(PhotoPreview.NextBtn)`
  right: 100%;
`;

PhotoPreview.Photo = styled.img`
  max-height: 90vh;
  max-width : 90vw;
`;

PhotoPreview.Delete = styled(Button)`
  display : flex;
  position: absolute;
  bottom  : 5%;
  left    : calc(50% - 71px)
`;
