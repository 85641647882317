import { useContext }        from 'react';
import { Grid, TextField }   from '@mui/material';
import { SettingsContext }   from '../../../../../containers/SettingsContainer';
import { FieldWrapper }      from '../../../../FieldWrapper';
import { TitleBlock }        from '../../../../TitleBlock';
import { privacyTabs }       from '../../../../../utils/tabs';
import { InputError }        from '../../../../../../../components/InputError';
import { SETTINGS_SECTIONS } from '../../../../../utils/settingsSections';

export const Name = () => {
  const { register, formState: { errors } } = useContext(SettingsContext);

  return (
    <FieldWrapper>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={4}>
          <TitleBlock 
            tabs         = {[privacyTabs[0]]}
            defaultValue = {privacyTabs[0].id}
            label        = {SETTINGS_SECTIONS.name.title}
            description  = {SETTINGS_SECTIONS.name.description}
            tooltip      = {SETTINGS_SECTIONS.name.tooltip}
          />
        </Grid>

        <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }}/>

        <Grid item xs={0} md={6}>
          <Grid container spacing={2} justifyContent="end">
            <Grid item xs={12} sm={6}>
              <TextField 
                label   = "First Name"
                variant = "filled"
                error   = {!!errors?.user?.firstName}
                fullWidth 
                {...register('user.firstName')}
              />
              {errors.user?.firstName && <InputError error={errors.user.firstName.message ?? ''} />}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                label   = "Last Name"
                variant = "filled"
                error   = {!!errors?.user?.lastName}
                fullWidth 
                {...register('user.lastName')}
              />
              {errors.user?.lastName && <InputError error={errors.user.lastName.message ?? ''} />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FieldWrapper>
  );
}
