import styled                              from 'styled-components';
import {
  Grid,
  Link,
  Radio,
}                                          from '@mui/material';
import { IRouteTableRow, IRouteModel }     from '@models/RouteModel';
import { scrimBlack }                      from '@styles/theme';
import { preemFilesHost }                  from '@config/api_config';
import { Avatar }                          from '@components/Avatar';
import {getDistance, getDistanceUnits, getElevation} from '@utils/metrics_utils';
import { ReactComponent as DistanceIcon }  from '@images/Icon-Metric-Distance-Black.svg';
import { ReactComponent as ElevationIcon } from '@images/Icon-Metric-Elevation-Black.svg';
import {
  DISTANCE_UNITS,
  MEASUREMENT_SYSTEMS,
}                                          from '@modules/Profile/utils/options';
import { DEFAULT_ROUTE_THUMBNAIL_PATH }    from '@components/RouteDetails';

interface IRideRouteRow {
  route            : Partial<IRouteTableRow> & Partial<IRouteModel>;
  measurementSystem: string;
  selectedRouteId? : string;
  onSelectRoute    : (value: string | undefined) => void;
}

export const RideRouteRow = ({
  route,
  measurementSystem,
  selectedRouteId,
  onSelectRoute
}: IRideRouteRow) => (
  <RideRouteRow.Wrapper container columnSpacing="10px">
    <Grid item xs={2}>
      <Avatar
        photoUrl = {`${preemFilesHost}${route.routeThumbnailPaths?.[0] ?? DEFAULT_ROUTE_THUMBNAIL_PATH}`}
        size     = {40}
      />
    </Grid>

    <Grid item xs={8} container alignItems="center" columnSpacing="20px">
      <Grid item xs={12}>
        {/* TODO: add ref attribute to Route Details */}
        <Link>
          <RideRouteRow.Name>{route.name}</RideRouteRow.Name>
        </Link>
      </Grid>

      <RideRouteRow.RouteMetrics item container xs="auto" alignItems="center">
        <DistanceIcon />
        <b>
          {`
            ${getDistance(measurementSystem, route.distance)} 
            ${getDistanceUnits(measurementSystem, route.distance)}
          `}
        </b>
      </RideRouteRow.RouteMetrics>

      <RideRouteRow.RouteMetrics item container xs="auto" alignItems="center">
        <ElevationIcon />

        <b>
          {`
            ${getElevation(measurementSystem, route.elevation || route.elevationGain)} 
            ${measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL ? DISTANCE_UNITS.FT : DISTANCE_UNITS.M}
          `}
        </b>
      </RideRouteRow.RouteMetrics>
    </Grid>

    <Grid item xs={2}>
      <Radio
        checked  = {route.id === selectedRouteId}
        value    = {route.id}
        onChange = {event => onSelectRoute(event.target.value)}
      />
    </Grid>
  </RideRouteRow.Wrapper>
);

RideRouteRow.Wrapper = styled(Grid)`
  border-bottom: 1px solid ${ scrimBlack(0.08) };
  padding      : 12px 16px;
`;

RideRouteRow.Name = styled.div`
  overflow     : hidden;
  text-overflow: ellipsis;
`;

RideRouteRow.RouteMetrics = styled(Grid)`
  font-size: ${({theme: {fontSizes: { s }}}) => s};
  
  svg {
    width : 18px;
    height: 18px;
  }
`;
