import { useHistory }    from 'react-router';
import styled            from 'styled-components';
import { 
  Button, 
  Grid, 
}                        from '@mui/material';

import { scrimBlack }    from '@styles/theme';
import { useWindowSize } from '@utils/hooks/useWindowSize';

export interface ISubsection {
  anchor        : string;
  header        : string;
  subheaderLine1: string;
  subheaderLine2: string;
  images?       : ISubsectionImage[];
  cta?          : ISubsectionCTA;
}

interface ISubsectionImage {
  url  : string;
  label: string;
}

interface ISubsectionCTA {
  label: string;
  link : string;
}

export const Subsection = (subsection: ISubsection) => {
  const { isMobile } = useWindowSize();
  const { push }     = useHistory();

  return (
    <Subsection.Wrapper
      container
      item
      alignContent   = "center"
      flexDirection  = "column"
      justifyContent = "center"
    >
      <Subsection.Header item>{subsection.header}</Subsection.Header>
      {isMobile ? (
        <Subsection.SubheaderWrapper>
          <Subsection.Subheader item>
            {subsection.subheaderLine1} {subsection.subheaderLine2}
          </Subsection.Subheader>
        </Subsection.SubheaderWrapper>
      ) : (
        <>
          <Subsection.Subheader item>
            {subsection.subheaderLine1}
          </Subsection.Subheader>
          <Subsection.Subheader item>
            {subsection.subheaderLine2}
          </Subsection.Subheader>
        </>
      )}
      {subsection.images && (
        <Subsection.ImageRow item container flexDirection={"row"}>
          {subsection.images.map((subsectionImage, index) => (
            <Subsection.ImageContainer
              item
              container
              xs             = {12}
              sm             = {6}
              md             = {3}
              key            = {`${subsectionImage.label}${index}`}
              alignItems     = "center"
              flexDirection  = "column"
              justifyContent = "center"
            >
              <Subsection.ImageWrapper
                container
                item
                alignItems    = "center"
                flexDirection = "column"
              >
                <Subsection.Image src={subsectionImage.url} />
                <Subsection.ImageLabel item>
                  {subsectionImage.label}
                </Subsection.ImageLabel>
              </Subsection.ImageWrapper>
            </Subsection.ImageContainer>
          ))}
        </Subsection.ImageRow>
      )}
      {subsection.cta && (
        <Subsection.CTARow item container xs="auto" justifyContent="center">
          <Button
            size    = "large"
            variant = "contained"
            onClick = {() => {
              subsection && subsection.cta && push(subsection.cta.link);
              if (subsection?.cta?.link.includes('discover')) {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }
            }}
          >
            {subsection.cta.label}
          </Button>
        </Subsection.CTARow>
      )}
      {!subsection.cta && <Subsection.HRule item />}
    </Subsection.Wrapper>
  );
};

Subsection.Wrapper = styled(Grid)`
`;

Subsection.CTARow = styled(Grid)`
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    margin-bottom: 100px;
  }
  ${({theme: {mediaQueries: {sm}}}) => sm} {
    margin-bottom: 300px;
  }
`;

Subsection.HRule = styled(Grid)`
  margin          : 24px 0;
  height          : 1px;
  background-color: ${scrimBlack(0.16)};
`;

Subsection.Header = styled(Grid)`
  color         : ${({ theme }) => theme.colors.neutralBlue[300]};
  text-transform: uppercase;
  font-weight   : 900;
  text-align    : center;
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    font-size    : 28px;
    margin-top   : 40px;
    margin-bottom: 0px;
  }
  ${({theme: {mediaQueries: {sm}}}) => sm} {
    margin-top   : 60px;
    margin-bottom: 12px;
    font-size    : 40px;
  }
`;

Subsection.Subheader = styled(Grid)`
  text-align : center;
  font-weight: 400;
  color      : ${({ theme }) => theme.colors.type.medium};
  &:nth-of-type(3) {
    margin-bottom: 64px;
  }
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    font-size  : 15px;
    line-height: 156%;
  }
  ${({theme: {mediaQueries: {sm}}}) => sm} {
    line-height: 28px;
    font-size  : 18px;
  }
`;

Subsection.SubheaderWrapper = styled(Grid)`
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    margin-bottom: 46px;
  }
  ${({theme: {mediaQueries: {sm}}}) => sm} {
    margin-bottom: 0px;
  }
`;

Subsection.ImageRow = styled(Grid)`
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    margin-bottom: 12px;
  }
  ${({theme: {mediaQueries: {md}}}) => md} {
    margin-bottom: 70px;
  }
`;

Subsection.ImageWrapper = styled(Grid)`
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    margin-bottom: 24px;
  }
  ${({theme: {mediaQueries: {sm}}}) => sm} {
    margin-bottom: 0px;
  }
`;

Subsection.Image = styled.img`
  max-height: 100%; /** Override CookieYes image sizing */
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    width        : 152px;
    height       : 152px;
    margin-bottom: 14px;
  }
  ${({theme: {mediaQueries: {sm}}}) => sm} {
    height       : 200px;
    width        : 200px;
    margin-bottom: 24px;
  }
  ${({theme: {mediaQueries: {xl}}}) => xl} {
    height: 267px;
    width : 267px;
  }
  ${({theme: {mediaQueries: {xxl}}}) => xxl} {
    height: 300px;
    width : 300px;
  }
  `;

Subsection.ImageLabel = styled(Grid)`
  color         : ${({ theme }) => theme.colors.type.medium};
  text-transform: uppercase;
  text-align    : center;
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    font-size     : 10px;
    letter-spacing: 0.8px;
    font-weight   : 600;
  }
  ${({theme: {mediaQueries: {sm}}}) => sm} {
    font-size  : 12px;
    font-weight: 700;
  }
`;

Subsection.ImageContainer = styled(Grid)`
  margin-bottom: 12px;
`;
