import {
  useCallback,
  useEffect,
  useMemo,
  useState
}                                            from 'react';
import { useSelector }                       from 'react-redux';
import { format}                             from 'date-fns';
import styled                                from 'styled-components';

import { currentLocation }                   from '../../../../../../../../store/reducers/location';
import { INVITATION_STATUS }                 from '../../../../../../../Details/models/invitation';
import { MEASUREMENT_SYSTEMS }               from '../../../../../../../Profile/utils/options';
import { scrimBlack }                        from '../../../../../../../../styles/theme';
import { toFarenheit, toMPH }                from '../../../../../../../../utils/conversion_utils';
import {
  useGetUserGenLocationsQuery,
  useGetUserSettingsQuery
}                                            from '../../../../../../../Profile/queries';
import { useGetWeatherByGenLocationIdQuery } from '../../../../../../queries';
import {
  TemperatureUnitType,
  Weather,
  WindSpeedUnitType,
}                                            from '../../../../../../../../models/CalendarCardModel';
import { LocationDropdown }                  from '../../../LocationDropdown';
import { TabsetDay, TabType }                from '../../../TabsetDay';
import { WeatherForecast }                   from '../WeatherForecast';
import { IGenLocation }                      from '../../../../../../../Profile/models/genLocation';
import {
  IRideInvitation,
  WeatherData
}                                            from '../../../../../../models';
import { useGetPublicRidesQuery }            from '../../../../../../../Details/queries';

interface IHeaderProps {
  date             : Date;
  invitationCards  : IRideInvitation[];
  onSelectTab      : (tab: TabType) => void;
  rideCards        : IRideInvitation[];
  selectedTab      : TabType | null;
  isMobile         : boolean;
  dayIndex         : number;
  setActiveLocation: (genLocation: IGenLocation) => void;
  activeLocation?  : IGenLocation;
}

export const Header = ({
  date,
  invitationCards,
  rideCards,
  onSelectTab,
  selectedTab,
  isMobile,
  setActiveLocation,
  activeLocation,
  dayIndex
}: IHeaderProps) => {
  const stateLocation: IGenLocation                             = useSelector(currentLocation);
  const [previousDate, setPreviousDate]                         = useState<Date | undefined>();
  const [currentWeather, setCurrentWeather]                     = useState<Weather | undefined>(undefined);
  const [pendingInvitations, setPendingInvitations]             = useState<boolean>(false);
  const [locations, setLocations]                               = useState<IGenLocation[]>([]);
  const [isStateLocationUpdated, setStateLocationUpdatedStatus] = useState<boolean>(false);
  const { data: settings }                                      = useGetUserSettingsQuery();
  const { data: userGenLocations }                              = useGetUserGenLocationsQuery();
  const { data: weather }                                       = useGetWeatherByGenLocationIdQuery({ genLocationId: activeLocation?.id ?? '' }, { skip: !activeLocation?.id });
  const { data: groupRideCards }                                = useGetPublicRidesQuery({ from: format(date, 'yyyy-MM-dd'), to: format(date, 'yyyy-MM-dd'), genLocationId: activeLocation?.id ?? '' }, { skip: !activeLocation });
  const measurementSystem                                       = settings?.measurementSystem || MEASUREMENT_SYSTEMS.IMPERIAL;
  const temperatureUnits                                        = settings?.temperatureUnits || TemperatureUnitType.f;

  const ridesGenLocations = useMemo(
    () => rideCards
      .filter(card => card.invitationStatus === INVITATION_STATUS.ACCEPTED)
      .map(card => ({ ...card.ride.genLocation, name: `Ride in ${card.ride?.genLocation?.name ?? ''}` })
    ),
    [rideCards]
  );

  useEffect(
    () => {
      setStateLocationUpdatedStatus(true);
    },
    [ridesGenLocations, stateLocation]
  );

  useEffect(
    () => {
      if (userGenLocations && ridesGenLocations && stateLocation && isStateLocationUpdated) {
        const filteredRidesGenLocations   = ridesGenLocations.filter(({ id }) => id);
        const ridesAndUserGenLocations    = [...filteredRidesGenLocations, ...(userGenLocations ?? []), stateLocation];
        const uniqueLocationIds: string[] = [];
        const uniqueLocations             = ridesAndUserGenLocations.filter(location => {
          if (uniqueLocationIds.includes(location.id)) {
            return false;
          } else {
            uniqueLocationIds.push(location.id);

            return true;
          }
        });

        setLocations(uniqueLocations);

        setActiveLocation(filteredRidesGenLocations.length ? filteredRidesGenLocations[0] : stateLocation);
        setStateLocationUpdatedStatus(false);
      }
    },
    [isStateLocationUpdated, ridesGenLocations, stateLocation, setActiveLocation, userGenLocations]
  );

  /** Set location to match content hero when date changes */
  useEffect(
    () => {
      const shortDate     = format(date, 'yyyy-MM-dd');
      const shortPrevDate = previousDate ? format(previousDate, 'yyyy-MM-dd') : undefined;

      if (shortPrevDate) {
        if (shortDate !== shortPrevDate) {
          setActiveLocation(stateLocation);
          setPreviousDate(date);
        }
      } else {
        setPreviousDate(date);
      }
    },
    [date, previousDate] // eslint-disable-line
  );

  const processWeatherDataPoint = useCallback(
    (weatherDataPoint: WeatherData): Weather => {
      const isFarenheit = temperatureUnits  === TemperatureUnitType.f;
      const isMetric    = measurementSystem === MEASUREMENT_SYSTEMS.METRIC;
      /** TODO: Post-MVP, need to add preference for showing real feel temps or not */
      return {
        condition       : weatherDataPoint.weatherCode,
        precipitation   : weatherDataPoint.precipitationPercent,
        minTemperature  : isFarenheit
            ? toFarenheit(weatherDataPoint.minTempCelsius)
            : weatherDataPoint.minTempCelsius,
        maxTemperature  : isFarenheit
            ? toFarenheit(weatherDataPoint.maxTempCelsius)
            : weatherDataPoint.maxTempCelsius,
        temperatureUnits: isFarenheit
            ? TemperatureUnitType.f
            : TemperatureUnitType.c,
        minWindSpeed    : isMetric
            ? Math.round(weatherDataPoint.windSpeedMinMS)
            : toMPH(weatherDataPoint.windSpeedMinMS),
        maxWindSpeed    : isMetric
            ? Math.round(weatherDataPoint.windSpeedMaxMS)
            : toMPH(weatherDataPoint.windSpeedMaxMS),
        windSpeedUnits  : isMetric ? WindSpeedUnitType.kph : WindSpeedUnitType.mph,
        windDirection   : weatherDataPoint.windDirection,
      }
    },
    [measurementSystem, temperatureUnits]
  );

  const getWeatherForActiveLocation = useCallback(
    () => {
      const checkDate        = format(date, "yyyy-MM-dd");
      const weatherDataPoint = weather?.find((item: WeatherData) => item.forecastDate === checkDate);

      if (weatherDataPoint) {
        setCurrentWeather(processWeatherDataPoint(weatherDataPoint));
      } else {
        setCurrentWeather(undefined);
      }
    },
    [date, processWeatherDataPoint, weather]
  );

  /** Update column weather any time the date/location/unit prefs change */
  useEffect(
    () => {
      getWeatherForActiveLocation();
    },
    [activeLocation, date, measurementSystem, temperatureUnits, location, weather] // eslint-disable-line
  );

  const handleLocationChange = useCallback(
    (selectedLocation: IGenLocation) => {
      if (!isMobile) {
        onSelectTab(TabType.MY_RIDES);
      }

      setActiveLocation(selectedLocation);
      setLocations(prevLocations => {
        const isLocationExist = prevLocations.find(location => location.id === selectedLocation.id);

        return isLocationExist ? prevLocations : [...prevLocations, selectedLocation];
      });
    },
    [] // eslint-disable-line
  );

  useEffect(
    () => {
      const pendingInvitations = invitationCards.some(invitationCard => invitationCard.invitationStatus === INVITATION_STATUS.PENDING);

      setPendingInvitations(pendingInvitations);
    },
    [invitationCards]
  );

  return (
    <Header.Wrapper $isMobile={isMobile} $dayIndex={dayIndex}>
      <Header.InnerWrapper>
        <Header.Date isMobile={isMobile}>
          {format(date, "EEEE d")}
        </Header.Date>

        <LocationDropdown
          locations        = {locations}
          selectedLocation = {activeLocation}
          onLocationChange = {handleLocationChange}
        />

        {isMobile ? (
          <Header.MobileWrapper>
            <WeatherForecast forecast={currentWeather} isMobile={isMobile} date={date} />

            <TabsetDay
              myRideCount        = {rideCards.length || 0}
              inviteCount        = {invitationCards.length || 0}
              groupRideCount     = {groupRideCards?.length ?? 0}
              pendingInvitations = {pendingInvitations}
              onTabClick         = {onSelectTab}
              selectedTab        = {selectedTab}
              isMobile           = {isMobile}
            />
          </Header.MobileWrapper>
        ) : (
          <>
            <WeatherForecast forecast={currentWeather} isMobile={isMobile} date={date} />

            <TabsetDay
              myRideCount        = {rideCards.length || 0}
              inviteCount        = {invitationCards.length || 0}
              groupRideCount     = {groupRideCards?.length ?? 0}
              pendingInvitations = {pendingInvitations}
              onTabClick         = {onSelectTab}
              selectedTab        = {selectedTab}
              isMobile           = {isMobile}
            />
          </>
        )}
      </Header.InnerWrapper>
    </Header.Wrapper>
  );
};

Header.Wrapper = styled.div<{ $isMobile: boolean, $dayIndex: number }>`
  display       : flex;
  flex-direction: column;

  ${({ $isMobile}) => $isMobile && `border-bottom: 2px solid ${scrimBlack(0.08)}`};
  ${({ $isMobile, $dayIndex}) => $isMobile && $dayIndex === 6 && 'border-bottom: none'};

  ${({ $isMobile }) => !$isMobile && `
    padding: 32px 32px 0 32px;
    min-width: 350px;
  `}

  ${({ $isMobile, $dayIndex}) => !$isMobile && $dayIndex === 0 && `
    padding-left: 0;
  `};
`;

Header.MobileWrapper = styled.div`
  display        : flex;
  justify-content: center;
  gap            : 20px;
  padding-bottom : 20px;
`;

Header.InnerWrapper = styled.div`
  display        : flex;
  flex-direction : column;
  justify-content: start;
`;

Header.Date = styled.h4<{ isMobile: boolean}>`
  margin-bottom : 8px;
  font-size     : ${({ isMobile }) => isMobile ? '16px' : '22px'};
  line-height   : 124%;
  font-weight   : 700;
  letter-spacing: -0.4px;
  display       : flex;
  width         : 100%;
  flex-direction: column;
  flex-wrap     : wrap;
  align-items   : center;
  align-content : center;
  align-self    : stretch;
  flex          : 0 0 auto;

  @media screen and (max-width: 479px) {
    flex: 0 auto;
  }
`;
