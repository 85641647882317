import { IUserBaseInfo } from '@modules/Profile/models/UserInfo';

export enum ERouteImportSource {
  RouteFile     = 'ROUTE_FILE',
  RideWithGps   = 'RIDE_WITH_GPS',
  RideGenerated = 'RIDE_FILE'
}

export interface IRouteTableRow {
  id                  : string;
  date                : string;
  creationDate        : string;
  name                : string;
  locationName        : string;
  distance            : number;
  elevation           : number;
  aggWeight           : number
  routeThumbnailPaths : string[];
  isFavorite?         : boolean;
  importSource: ERouteImportSource
}

export interface IRouteTableResponse {
  content       : IRouteTableRow[];
  totalElements : number;
  totalPages    : number;
  pageNumber    : number;
  pageSize      : number;
}

export interface IRouteParams {
  name?                    : string;
  dateFrom?                : string;
  dateTo?                  : string;
  distanceMin?             : number;
  distanceMax?             : number;
  elevationMin?            : number;
  elevationMax?            : number;
  durationMin?             : number;
  durationMax?             : number;
  aggWeightGreaterEqualOne?: boolean;
  genLocationId?           : string;
  importSource?            : ERouteImportSource;
  favoriteOnly?            : boolean;
}

export interface IRoutePhoto {
  dateCreated: string;
  fileLink   : string;
  id         : string;
  user?      : IUserBaseInfo;
}

export interface IRouteModel {
  id                 : string;
  creationDate       : string;
  distance           : number;
  elevationGain      : number;
  elevationLoss      : number;
  maxLatitude        : number;
  maxLongitude       : number;
  minLatitude        : number;
  minLongitude       : number;
  name               : string;
  routeThumbnailPaths: string[];
  owner              : IUserBaseInfo;
  genLocation        : {
    name: string;
  }
}
