import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
}                                   from 'react';
import axios                        from 'axios';
import { throttle }                 from 'lodash';
import styled, { css }              from 'styled-components';
import mapboxgl, { Map }            from 'mapbox-gl';
import MapboxGeocoder, { Result }   from '@mapbox/mapbox-gl-geocoder';
import { Grid }                     from '@mui/material';
import {
  scrimBlack,
  scrimWhite
}                                   from '@styles/theme';
import { IUserLocation }            from '@modules/Profile/models/UserInfo';
import { useGetUserLocationsQuery } from '@modules/Profile/queries';
import IconAddRubine                from '@images/Icon-Add-Rubine.svg';
import IconRemoveRubine             from '@images/Icon-Remove-Rubine.svg';
import IconLocationRubine           from '@images/Icon-Location-Rubine.svg';
import MarkerIcon                   from '../../images/Map-Preem-Marker.svg';
import { Modal }                    from '../Modal';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';

export interface ISaveLocationData {
  lng       : number;
  lat       : number;
  cityName  : string;
  stateName : string;
  timezone? : string;
  address?  : string;
  placeName?: string;
}

export interface ILocationModalProps {
  isOpen            : boolean;
  onClose           : () => void;
  handleSaveLocation: (data: ISaveLocationData) => void;
  allowMap?         : boolean;
  itemName?         : string;
  defaultValue?     : IUserLocation;
  isRideLocation?   : boolean;
}

interface IMapboxProperties {
  address: string;
}

interface IMapboxFeature {
  address    : string;
  center     : number[];
  context    : IMapboxContext[];
  place_name : string;
  place_type : string[];
  text       : string;
  properties?: IMapboxProperties;
}

interface IMapboxContext {
  id         : string;
  text       : string;
  short_code?: string;
}

export const DEFAULT_LOCATION = { latitude: 40.0445, longitude: -105.25624, cityName: 'Boulder', stateName: 'Colordado', placeName: '' };

export const LocationModal = ({
  isOpen,
  onClose,
  handleSaveLocation,
  allowMap     = true,
  itemName     = 'Location',
  defaultValue,
  isRideLocation,
}: ILocationModalProps) => {
   const { data: locations }                             = useGetUserLocationsQuery();
   const [isExistingLocation, setExistingLocationStatus] = useState<boolean>(false);
   const mapContainerRef                                 = useRef<HTMLElement | null>(null);
   const [activeFeature, setActiveFeature]               = useState<IMapboxFeature>();
   const [lat, setLat]                                   = useState(defaultValue?.latitude ?? DEFAULT_LOCATION.latitude);
   const [lng, setLng]                                   = useState(defaultValue?.longitude ?? DEFAULT_LOCATION.longitude);
   const [placeName, setPlaceName]                       = useState<string>(defaultValue?.placeName ?? DEFAULT_LOCATION.placeName);
   const [cityName, setCityName]                         = useState<string>('');
   const [stateName, setStateName]                       = useState<string>('');
   const [address, setAddress]                           = useState<string>();
   const [lastLat, setLastLat]                           = useState(0);
   const [lastLng, setLastLng]                           = useState(0);
   const [zoom, setZoom]                                 = useState(10);
   const [allowSubmit, setAllowSubmit]                   = useState(false);
   const [isSetRef, setRefStatus]                        = useState(false);
   const [marker, setMarker]                             = useState<mapboxgl.Marker | null>(null);
   const [tmzOffset, setTmzOffset]                       = useState<string>('');
   const map                                             = useRef<Map | null>(null);
         mapboxgl.accessToken                            = process.env.REACT_APP_PREEM_MAPBOX_ACCESS_TOKEN || '';

  const createMarker = useMemo(
    () => (lng: number, lat: number) => {
      setMarker(null);
      marker?.remove();
      document.querySelector('.mapboxgl-marker')?.remove();

      const markerEl     = document.createElement('img');
            markerEl.src = MarkerIcon;

      const newMarker = map.current && new mapboxgl.Marker(markerEl).setLngLat({lat, lng}).addTo(map.current);
      setMarker(newMarker);
    },
    [marker]
  );
  
  function formatPlaceName(feature: any): string {
    const placeNames = feature.place_name
      .replace(/\d{5}/, '')
      .split(', ')
      .filter((value: string) => value);

    const newLabel = placeNames.slice(0, placeNames.length - 1).join(', ');

    if (feature?.id?.includes('place')) {
      setCityName(feature.text);
    } else if (feature?.context) {
      const placeContext = feature.context.find((context: any) => context.id.includes('place'));

      if (placeContext) {
        setCityName(placeContext?.text);
      }
    }

    if (feature?.id?.includes('address')) {
      setAddress(`${feature.address} ${feature.text}`);
    } else if (feature?.context) {
      const addressContext = feature.context.find((context: any) => context.id.includes('address'));

      if (addressContext) {
        setAddress(`${addressContext.address} ${addressContext?.text}`);
      }
    }

    if (feature?.context) {
      const activeContext = feature.context.find((context: any) => context && context.short_code);

      if (activeContext) {
        const stateName = activeContext.text;
        setStateName(stateName);
      }
    }

    return newLabel;
  }

  // throttle the reverse geocoding API call
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lookupPlace = useCallback(
    throttle(
      (lng, lat) => {
        const params = {
          types: isRideLocation ? 'address,poi' : 'place,district,region',
        };

        axios
          .get(
            `https://api.mapbox.com/v4/examples.4ze9z6tv/tilequery/${lng},${lat}.json?access_token=${process.env.REACT_APP_PREEM_MAPBOX_ACCESS_TOKEN}`
          )
          .then(result => setTmzOffset(result?.data?.features[0]?.properties?.TZID));

        axios
          .get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${process.env.REACT_APP_PREEM_MAPBOX_ACCESS_TOKEN}`,
            { params }
            /** save for genlocationid `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${process.env.REACT_APP_PREEM_MAPBOX_ACCESS_TOKEN}&types=poi,address` */
          )
          .then((res) => {
            setActiveFeature(res.data.features[0]);
            if (res?.data?.features[0]?.place_name) {
              const placeNameFormatted: string = formatPlaceName(
                res.data.features[0]
              );
              const fixedLng = Number(lng.toFixed(5));
              const fixedLat = Number(lat.toFixed(4));

              setPlaceName(placeNameFormatted);
              setLng(fixedLng);
              setLat(fixedLat);

              createMarker(fixedLng, fixedLat);

              const geoInput: HTMLInputElement | null = document.querySelector(
                '.mapboxgl-ctrl-geocoder--input'
              );
              // update geocoder input
              if (geoInput) {
                geoInput.value = placeNameFormatted;
              }
            }
          });

          setExistingLocationStatus(false);
      },
      1000,
      { trailing: true }
    ),
    []
  );

  const getLocation = useCallback(
    () => {
      if (defaultValue) {
        setLastLat(defaultValue.latitude);
        setLastLng(defaultValue.longitude);
        lookupPlace(defaultValue.longitude, defaultValue.latitude);

        setAllowSubmit(true);
      } else {
        navigator.geolocation.getCurrentPosition(position => {
          const geoLat = Number(position.coords.latitude.toFixed(4));
          const geoLng = Number(position.coords.longitude.toFixed(5));
          setLat(geoLat);
          setLastLat(geoLat);
          setLng(geoLng);
          setLastLng(geoLng);
          lookupPlace(geoLng, geoLat);

          if (map.current) {
            map.current.setCenter([geoLng, geoLat]);
          }
        },
        error => {
          if (error.code === error.PERMISSION_DENIED) {
            setLastLat(lat);
            setLastLng(lng);
            lookupPlace(lng, lat);
          }
          setAllowSubmit(true);
        });
      }
    },
    [defaultValue, lat, lng, lookupPlace]
  );

  // create map and setup map events
  useEffect(
    () => {
      if (map.current) return;
      if (isSetRef && mapContainerRef.current) {
        map.current = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: 'mapbox://styles/mapbox/outdoors-v11',
          center: [lng, lat],
          zoom: zoom,
          attributionControl: false,
        });
        map.current.addControl(new mapboxgl.AttributionControl(), 'top-left');
      }
    },
    [isSetRef, lat, lng, zoom]
  );

  function blockSubmit() {
    setAllowSubmit(false);
  }

  function handleMoveEnd(e: Event) {
    // geolocate button triggers a fake moveend event, so we need to ignore it
    if ('geolocateSource' in e) {
      return;
    }
    if (map.current) {
      const { lat: centerLat, lng: centerLng } = map.current.getCenter();
      setLng(Number(centerLng.toFixed(5)));
      setLastLng(Number(centerLng.toFixed(5)));
      setLat(Number(centerLat.toFixed(4)));
      setLastLat(Number(centerLat.toFixed(4)));
      setZoom(Number(map.current.getZoom().toFixed(0)));
      setTimeout(() => {
        lookupPlace(centerLng, centerLat);
      }, 200);
      setTimeout(() => {
        setAllowSubmit(true);
      }, 400);
    }
  }

  useEffect(() => {
    if (!map.current) return;
    if (allowMap) {
      map.current
        .on('mousedown', blockSubmit)
        .on('touchstart', blockSubmit)
        .on('movestart', blockSubmit)
        .on('zoomstart', blockSubmit)
        .on('wheel', blockSubmit)
        .on('moveend', handleMoveEnd);
    } else {
      map.current
        .off('mousedown', blockSubmit)
        .off('touchstart', blockSubmit)
        .off('movestart', blockSubmit)
        .off('zoomstart', blockSubmit)
        .off('wheel', blockSubmit)
        .off('moveend', handleMoveEnd);
    }
    return () => {
      if (!map.current) return;
      map.current
        .off('mousedown', blockSubmit)
        .off('touchstart', blockSubmit)
        .off('movestart', blockSubmit)
        .off('zoomstart', blockSubmit)
        .off('wheel', blockSubmit)
        .off('moveend', handleMoveEnd);
    };
  });

  // add geolocate button and event handling
  useEffect(() => {
    if (!map.current) return;
    const geolocateControl = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: false,
      showUserHeading: false,
      showUserLocation: false,
    });

    function handleGeolocate(e: GeolocationPosition | object | undefined) {
      setAllowSubmit(false);
      if (e && 'coords' in e && map.current) {
        map.current.flyTo({
          center: [e.coords.longitude, e.coords.latitude],
          zoom: zoom,
        });
      }
    }
    geolocateControl.on('geolocate', handleGeolocate);
    map.current.addControl(geolocateControl);
    map.current.addControl(
      new mapboxgl.NavigationControl({ showCompass: false }),
      'bottom-right'
    );

    return () => {
      geolocateControl.off('geolocate', handleGeolocate);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSetRef]);

  // add geocoder and geocoder event handling
  useEffect(
    () => {
      if (!map.current) return;
      const geocoder = new MapboxGeocoder({
        flyTo: {
          // ms, higher number makes the animation go slower
          duration: 2000,
          zoom: zoom,
        },
        getItemValue: function (item) {
          return formatPlaceName(item);
        },
        types      : isRideLocation ? 'place,district,region,poi,address' : 'place,district,region',
        placeholder: itemName,
        accessToken: mapboxgl.accessToken,
        mapboxgl   : map.current,
        render     : function (item: Result) {
          return `<div class='geocoder-dropdown-item'>${formatPlaceName(
            item
          )}</div>`;
        },
      });

      function handleGeocoderLoading() {
        setAllowSubmit(false);
      }
      function handleGeocoderClear() {
        setAllowSubmit(false);
      }
      function handleGeocoderResult() {
        setLastLng(lng);
        setLastLat(lat);
      }
      geocoder
        .on('loading', handleGeocoderLoading)
        .on('clear', handleGeocoderClear)
        .on('result', handleGeocoderResult);
      const geocoderTarget: Element | null =
        document.querySelector('.geocoder-target');

      if (geocoderTarget !== null && !geocoderTarget.hasChildNodes()) {
        geocoderTarget.appendChild(geocoder.onAdd(map.current));
      }
      return () => {
        geocoder.off('loading', handleGeocoderLoading);
        geocoder.off('clear', handleGeocoderClear);
        geocoder.off('result', handleGeocoderResult);
      };
    },
    [isSetRef] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // the geocoder uses the Enter key to clear the input, so we're redefining that behavior
  useEffect(() => {
    const inputElement: HTMLInputElement | null = document.querySelector(
      '.mapboxgl-ctrl-geocoder--input'
    );

    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();

        if (lastLat !== lat && lastLng !== lng) {
          setLastLat(lat);
          setLastLng(lng);
          lookupPlace(lng, lat);
        }
      }
    };

    inputElement?.addEventListener('keydown', handleKeyPress);

    return () => {
      inputElement?.removeEventListener('keydown', handleKeyPress);
    };
  }, [lng, lat, lastLat, lastLng, lookupPlace]);

  // get initial location
  useEffect(
    () => {
      getLocation();
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setRef = useCallback((ref: HTMLDivElement | null) => {
    setRefStatus(true);

    mapContainerRef.current = ref;
  }, []);

  const handleSave = useCallback(() => {
    if (allowSubmit && activeFeature) {
      const isLocationExisting = locations?.some(location => location.placeName === `${cityName}, ${stateName}`);

      const existingLocationStatus = Boolean(isLocationExisting && !isRideLocation);

      setExistingLocationStatus(existingLocationStatus);

      if (!existingLocationStatus) {
        const address: string = activeFeature.place_name.replace(', United States', '');

        if (activeFeature.place_type[0] === 'poi') {
          const addressArray = address.split(',')

          addressArray.shift();

          const trimmedAddress = addressArray.join(',').trim();

          const placeName = formatPlaceName(activeFeature);

          handleSaveLocation({
            lat,
            lng,
            stateName,
            cityName,
            placeName,
            address  : trimmedAddress,
            timezone : tmzOffset,
          });
        } else {
          handleSaveLocation({
            lat,
            lng,
            address,
            stateName,
            cityName,
            placeName,
            timezone: tmzOffset,
          });
        }

        onClose();
      }
    }
  }, [
    allowSubmit,
    activeFeature,
    locations,
    isRideLocation,
    cityName,
    stateName,
    onClose,
    handleSaveLocation,
    lat,
    lng,
    tmzOffset,
    placeName
  ]);

  return (
    <Modal
      isOpen              = {isOpen}
      width               = '600px'
      itemName            = {itemName}
      submitBtnLabel      = {`Add ${itemName}`}
      submitBtnIsDisabled = {!allowSubmit}
      title               = {placeName ?? address ?? 'Select an address'}
      onClose             = {onClose}
      onSave              = {handleSave}
    >
      <LocationModal.Location container>
        <div ref={setRef} className="map-container" />
        <div className="geocoder-target" />
      </LocationModal.Location>

      {isExistingLocation && <LocationModal.Error>You have already added this location</LocationModal.Error>}
    </Modal>
  );
};

LocationModal.Location = styled(Grid)`&& {
  padding: 24px 24px 36px;

  .map-container {
    height       : 340px;
    max-height   : 38vh;
    width        : 100%;
    border-radius: 8px;
    margin-bottom: 16px;
  }

  .geocoder-target {
    width: 100%;
  }

  ${({ theme: { colors } }) => css`
     .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
      border          : none !important;
      background-color: ${scrimBlack(0.16)};
      outline         : 0 !important;
      min-width       : 100%;
      margin-bottom   : 2em;

      .mapboxgl-ctrl-geocoder--icon {
        top: 13px;
        &.mapboxgl-ctrl-geocoder--icon-search,
        &.mapboxgl-ctrl-geocoder--icon-close {
          fill: ${scrimWhite(0.64)};
        }
      }
       
      .mapboxgl-ctrl-geocoder--input {
        border-radius   : 4px;
        border          : none !important;
        outline         : 0 !important;
        box-shadow      : none;
        color           : ${colors.primary.black};
        background-color: transparent;
        font-family     : 'Inter', sans-serif;
        font-size       : 14px;
        line-height     : 14px;
        font-weight     : 700;
        height          : 48px;
        padding-bottom  : 5px;
        padding-left    : 36px;
        padding-top     : 5px;
        transition      : all 0.3s ease-in-out;
        &:focus {
          box-shadow: 0 0 0px 2px ${colors.primary.black};
        }
      }

      .mapboxgl-ctrl-geocoder--pin-right {
        & > * {
          top: 12px;
        }
        & > button {
          background-color: transparent !important;
        }
      }

      .suggestions-wrapper {
        .suggestions {
          background: ${scrimBlack(0.74)} !important;
          li {
            &.active {
              a {
                .geocoder-dropdown-item {
                  color: ${scrimBlack(0.64)} !important;
                }
              }
            }
            a {
              color: ${scrimWhite(0.64)} !important;
              &:hover {
                .geocoder-dropdown-item {
                  color: ${scrimBlack(0.64)} !important;
                }
              }
            }
          }
        }
      }
    }

    .mapbox-ctrl,
    .mapboxgl-ctrl-group {
      display       : flex;
      flex-direction: row-reverse;
      background    : transparent;
      &:not(:empty) {
        box-shadow: none;
      }
      button {
        border-radius   : 100%;
        background-color: ${colors.primary.white};
        height          : 32px;
        width           : 32px;
        
        &:focus:last-child,
        &:focus:first-child {
          border-radius: 100%;
        }
        &:not(:disabled):hover,
        &:hover {
          transform : scale(1.1);
        }
      }
    }

    .mapboxgl-ctrl-zoom-in {
      transition: all 200ms ease-in;

      .mapboxgl-ctrl-icon {
        background-image: url(${IconAddRubine});
      }
    }

    .mapboxgl-ctrl-zoom-out {
      margin-right: 0.5em;
      transition  : all 200ms ease-in;
      
      .mapboxgl-ctrl-icon {
        background-image: url(${IconRemoveRubine});
      }
    }

    .mapboxgl-ctrl-geolocate {
      transition: all 200ms ease-in;
      
      .mapboxgl-ctrl-icon {
        background-image: url(${IconLocationRubine});
      }
    }
  `}
  
  ${({theme: {colors: { primary: { black}, type: { dark, medium}}}}) => css`
    .mapboxgl-ctrl-geocoder--input, .mapboxgl-ctrl-geocoder--input:focus {
      color           : ${black};
      background-color: transparent;
    }

    .mapboxgl-ctrl-geocoder--input::placeholder {
      color: ${medium};
    }

    .mapboxgl-ctrl-geocoder--input:focus {
      box-shadow: 0 0 0 2px ${dark};
    }

    path {
      fill: ${black};
    }
  `};

  .mapboxgl-ctrl-bottom-right {
    bottom: 10px;
  } 
}`;

LocationModal.Error = styled.p`
  text-align : center;
  font-size  : 14px;
  margin-top : -25px;
  color      : ${({ theme }) => theme.colors.messaging.red}
`;
