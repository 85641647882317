import {
  useEffect,
  useState
}                     from 'react';
import styled         from 'styled-components';
import { scrimBlack } from '@styles/theme';
import { useMapbox }  from '@utils/hooks/useMapbox';

interface ILocationProps {
  setSelectedLocation: (location: MapboxGeocoder.Result | null) => void;
  selectedLocation   : MapboxGeocoder.Result | null;
}

export const Location = ({
  setSelectedLocation,
  selectedLocation
}: ILocationProps) => {
  const [isRendered, setRenderedStatus] = useState(false);
  const { geocoder }                    = useMapbox({ getItemValue: result => {
    setSelectedLocation(result);

    return result.text;
  } });


  useEffect(
    () => {
      if (isRendered) {
        geocoder.addTo('#geocoder-container');

        geocoder.setInput(selectedLocation?.text ?? '');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRendered]
  );

  geocoder.on('clear', () => {
    setSelectedLocation(null);
  });

  return (
    <Location.SearchContainer
      id  = "geocoder-container"
      ref = {() => setRenderedStatus(true)}
    />
  )
}

Location.SearchContainer = styled.div`
  position              : relative;
  border-radius         : 4px;
  font-family           : "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight           : 400;
  font-size             : 1rem;
  line-height           : 1.4375em;
  -webkit-letter-spacing: 0.00938em;
  -moz-letter-spacing   : 0.00938em;
  -ms-letter-spacing    : 0.00938em;
  letter-spacing        : 0.00938em;
  color                 : rgba(0,0,0,0.87);
  box-sizing            : border-box;
  cursor                : text;
  display               : -webkit-inline-flex;
  display               : -ms-inline-flexbox;
  display               : inline-flex;
  -webkit-align-items   : center;
  -webkit-box-align     : center;
  -ms-flex-align        : center;
  align-items           : center;
  padding               : 4px 0 5px;
  width                 : 100%;
  background            : #E5E5E4;
  border                : none;

  &:hover {
    border: 1px solid black;
  }

  && input {
    line-height  : 20px;
    font-size    : 14px;
    font-weight  : bold;
    padding-right: 5px;
    background   : #E5E5E4;
    width        : 100%;
    outline      : none;
  }

  && .mapboxgl-ctrl-geocoder {
    width           : 100%;
    box-shadow      : none;
    border          : none !important;
    background-color: ${scrimBlack(0.16)};
    border-color    : transparent !important;
    outline         : 0 !important;
    min-width       : 100%;

    .mapboxgl-ctrl-geocoder--button {
      background-color: transparent;
    }

    .mapboxgl-ctrl-geocoder--button:hover {
      background-color: transparent;
    }
  }
`;
