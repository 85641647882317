import { useEffect, useState }           from 'react';
import styled                            from 'styled-components';
import { useHistory }                    from 'react-router';
import { Button, Grid }                  from '@mui/material';
import { format }                        from 'date-fns';
import { scrimBlack }                    from '@styles/theme';
import { ProfileStatistics }             from '@modules/Profile/pages/Profile/components/ProfileStatistics';
import { UserBio }                       from '@modules/Profile/pages/Profile/components/UserBio';
import { IUserInfo }                     from '@modules/Profile/models/UserInfo';
import { Layout }                        from '@modules/Profile/components/Layout';
import {
  useGetUserStatisticsQuery,
  useGetUserSettingsQuery,
}                                        from '@modules/Profile/queries';
import { PageHeader }                    from '@modules/Profile/components/PageHeader';
import { PageDescription }               from '@modules/Profile/components/PageDescription';
import { UserTitleStatistics }           from '@components/UserTitleStatistics';
import { UserStatisticsTabs }            from '@components/UserStatisticsTabs';
import { Loader }                        from '@components/Loader';
import { StatisticsPeriod }              from '@components/UserStatisticsTabs/model/statistics';
import { getStartDayByPeriod }           from '@utils/metrics_utils';
import { ReactComponent as ChevronIcon } from '@images/Icon-Chevron-R-Rubine.svg';

interface IProfilePageProps {
  userInfo?: IUserInfo;
  isLoading: boolean;
}

export const ProfilePage = ({
  userInfo,
  isLoading,
}: IProfilePageProps) => {
  const { push }                                = useHistory();
  const [statisticsPeriod, setStatisticsPeriod] = useState<StatisticsPeriod>();
  const [statisticDates, setStatisticDates]     = useState<{ toDate: string; fromDate: string; }>();
  const { data: settings }                      = useGetUserSettingsQuery();
  const { data: statistics, refetch }           = useGetUserStatisticsQuery({ from: statisticDates?.fromDate, to: statisticDates?.toDate });
  const userBio                                 = userInfo?.userBio;

  useEffect(
    () => {
      if (statisticsPeriod && statisticsPeriod !== StatisticsPeriod.ALL) {
        setStatisticDates({
          fromDate: getStartDayByPeriod(statisticsPeriod) ?? '',
          toDate  : format(new Date(), 'yyyy-MM-dd'),
        });
      }

      if (statisticsPeriod === StatisticsPeriod.ALL) {
        setStatisticDates(undefined);
      }
    },
    [refetch, statisticsPeriod]
  );

  useEffect(
    () => {
      refetch();
    },
    [refetch, statisticDates]
  );

  if (isLoading) {
    return <div><Loader /></div>;
  }

  return (
    <>
      <ProfilePage.Container
        container
        justifyItems  = "flex-start"
        columnSpacing = "80px"
      >
        <Grid
          item
          xs = {12}
          sx = {{ paddingBottom: {xs: userBio ? 0 : '30px', sm: userBio ? 0 : '10px'}}}
        >
          <PageHeader>Profile</PageHeader>

          {!userBio && (
            <PageDescription>
              Tell the Preem community who you are as a cyclist to unlock the
              best experiences and the right people to share them with.
            </PageDescription>
          )}
        </Grid>

        <Grid item container xs={12} md={3} justifyContent="flex-start">
          {settings?.user?.username && (
            <ProfilePage.UserLink
              endIcon = {<ChevronIcon />}
              onClick = {() => push(`user/${settings?.user.username}`)}
            >
              <span>{`gopreem.com/user/${settings?.user.username}`}</span>
            </ProfilePage.UserLink>
          )}

          <UserBio value={userBio} />
        </Grid>

        <Grid item container alignItems="flex-start" xs={12} md={9}>
          <UserTitleStatistics
            data               = {userInfo}
            onLocationClick    = {() => push('profile/settings?position=location')}
            onConnectionsClick = {() => push('profile/community')}
          />
          {/* <div>Rouleur</div> */}
        </Grid>

        <Grid item container xs={12} justifyContent="center">
          <UserStatisticsTabs onPeriodChanged={setStatisticsPeriod} />
        </Grid>
      </ProfilePage.Container>

      <Layout>
        <ProfileStatistics
          data  = {statistics}
          units = {settings?.measurementSystem}
        />
      </Layout>
    </>
  );
};

ProfilePage.Container = styled(Grid)`
  background-color: ${({theme: {colors: { neutralGrey }}}) => neutralGrey[100]};
  padding         : 20px 8% 0;
  box-shadow      : 0px 1px 2px ${scrimBlack(0.04)}, 0px 4px 8px ${scrimBlack(0.04)};

  ${({theme: { mediaQueries: { sm }}}) => sm} {
    padding: 44px 8% 0;
  }

  .MuiFormControl-root .MuiFilledInput-root {
    box-shadow  : none;
    padding-left: 0;
  }
`;

ProfilePage.UserLink = styled(Button)`
  width: 100%;

  span {
    overflow     : hidden;
    text-overflow: ellipsis;
  }

  &.MuiButton-root {
    padding: 8px 0;

    .MuiButton-endIcon {
      margin-left: 0;
    }

    ${({theme: {mediaQueries: { sm }}}) => sm} {
      padding: 32px 0 20px;
    }
  }
`;

ProfilePage.UserInfo = styled(Grid)`
  border-bottom: 1px solid ${scrimBlack(0.08)};
  padding      : 20px 0;
`;
