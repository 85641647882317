import { useEffect }                from 'react';
import { useSnackbar, VariantType } from 'notistack';
import { Collapse }                 from '@mui/material';

export const Notification: any = (): null => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(
    (): void => {
      Notification.enqueueSnackbar = (message: string, variant: VariantType): void => {
        enqueueSnackbar(
          message, 
          { 
            variant,
            anchorOrigin: {
              vertical   : 'bottom',
              horizontal : 'left',
            },
          TransitionComponent: Collapse,
          }
        );
      };
    },
    [enqueueSnackbar],
  );

  useEffect(
    (): void => {
      Notification.closeSnackbar = (): void => {
        closeSnackbar();
      };
    },
    [closeSnackbar],
  );

  return null;
};
