import styled                  from 'styled-components';
import { Grid }                from '@mui/material';
import { scrimBlack }          from '../../../../styles/theme';
import {
  IConfigCell,
  IRowAction,
  ITableAction,
  ITemporaryItem,
  TableData
}                              from '../../../../components/TableData';
import { Layout }              from '../../components/Layout';
import { PageHeader }          from '../../components/PageHeader';
import { PageDescription }     from '../../components/PageDescription';
import { RideHistoryUploader } from './components/RideHistoryUploader';
import { IRideHistoryItem }    from '../../models/RideHistory';
import { TOrderSortType }      from '../../../../models/types';
import { EmptyTable }          from './components/EmptyTable';
import {
  IRideFilters,
  RideFiltersModal
}                              from './components/RideFiltersModal';
import { TMeasurementSystem }  from '../../models/Settings';

interface IRideHistoryPageProps {
  data              : IRideHistoryItem[];
  temporaryItems    : ITemporaryItem[];
  // rideHistoryErrors : IError[];
  configCells       : IConfigCell<IRideHistoryItem>[];
  isLoading         : boolean;
  rowActions        : IRowAction<IRideHistoryItem>[];
  orderBy           : string | undefined;
  order             : TOrderSortType | undefined;
  onSort            : (orderBy: string, order: TOrderSortType) => void;
  onSelectRow       : (id: string) => void;
  selectedItems     : string[];
  onSelectAll       : (items: IRideHistoryItem[]) => void;
  totalPages        : number;
  totalElements     : number;
  setPageNumber     : (pageNumber: number) => void;
  tableActions      : ITableAction[];
  refetch           : () => void;
  measurementSystem : TMeasurementSystem;
  searchText        : string;
  setSearchText     : (searchText: string) => void;
  areFiltersOpen    : boolean;
  setFiltersOpen    : (value: boolean) => void;
  onSaveFilters     : (values: IRideFilters) => void;
}

export const RideHistoryPage = ({
  data,
  temporaryItems,
  configCells,
  isLoading,
  rowActions,
  orderBy,
  order,
  onSort,
  onSelectRow,
  selectedItems,
  onSelectAll,
  totalPages,
  totalElements,
  setPageNumber,
  tableActions,
  refetch,
  measurementSystem,
  searchText,
  setSearchText,
  areFiltersOpen,
  setFiltersOpen,
  onSaveFilters,
}: IRideHistoryPageProps) => (
  <Layout>
    <Grid container justifyContent="space-between">
      <Grid item xs={12}>
        <PageHeader>Ride History</PageHeader>
      </Grid>

      <RideHistoryPage.Header item container xs={12} sm={5}>
        <PageDescription>
          Capturing past rides will allow Preem to automatically build a curated list of your best routes so you can relive them virtually and in real life.
        </PageDescription>
      </RideHistoryPage.Header>

      <RideHistoryUploader refetch={refetch} />
    </Grid>

    <Grid container>
      <TableData
        configCells         = {configCells}
        data                = {data}
        temporaryItems      = {temporaryItems}
        isLoading           = {isLoading}
        rowActions          = {rowActions}
        tableActions        = {tableActions}
        onSelectRow         = {onSelectRow}
        onSelectAll         = {onSelectAll}
        selectedItems       = {selectedItems}
        order               = {order}
        orderBy             = {orderBy}
        onSort              = {onSort}
        searchText          = {searchText}
        setSearchText       = {setSearchText}
        searchPlaceholder   = "Ride Title"
        title               = "Activities"
        emptyTable          = {() => <EmptyTable />}
        totalPages          = {totalPages}
        totalElements       = {totalElements}
        setPageNumber       = {setPageNumber}
        actionButtonLabel   = "Filter Results"
        onActionButtonClick = {() => setFiltersOpen(true)}
      />
    </Grid>

    <RideFiltersModal
      isOpen            = {areFiltersOpen}
      measurementSystem = {measurementSystem}
      onClose           = {() => setFiltersOpen(false)}
      onSubmit          = {onSaveFilters}
    />
  </Layout>
);

RideHistoryPage.Header = styled(Grid)`
  padding-right: 60px;
`;

RideHistoryPage.ActivitiesLabel = styled.div`
  font-size     : 15px;
  padding-bottom: 20px;

  span {
    font-size: ${({theme: {fontSizes: {l}}}) => l};
  }

  ${({theme: {mediaQueries: {sm}}}) => sm} {
    font-size: ${({theme: {fontSizes: {xl}}}) => xl};

    span {
      font-size: 22px;
    }
  }
`;

RideHistoryPage.EmptyContainer = styled(Grid)`
  height      : 500px;
  align-items : center;
  
  &.MuiGrid-root{
    background-color: ${scrimBlack(0.04)};
    font-weight     : 500;
    margin-bottom   : 64px;
  }
`;

