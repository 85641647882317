import {
  useCallback,
  useState
}                            from 'react';
import styled                from 'styled-components';
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
}                            from '@mui/material';
import CloseIcon             from '@mui/icons-material/Close';
import { LocationModal }     from '@components/LocationModal';
import { FieldWrapper }      from '../../../../FieldWrapper';
import { TitleBlock }        from '../../../../TitleBlock';
import { privacyTabs }       from '../../../../../utils/tabs';
import { SETTINGS_SECTIONS } from '../../../../../utils/settingsSections';
import {
  useDeleteUserLocationMutation,
  useGetUserLocationsQuery,
  useUpdateUserLocationMutation,
  useCreateUserLocationMutation
}                            from '../../../../../queries';

export const Locations = () => {
  const [deleteLocation]              = useDeleteUserLocationMutation();
  const { data: locations }           = useGetUserLocationsQuery();
  const [selectLocation]              = useUpdateUserLocationMutation();
  const [createLocation]              = useCreateUserLocationMutation();
  const [isOpenModal, setModalStatus] = useState(false);

  const handleSaveLocation = useCallback(
    (data: { lng: number, lat: number, cityName: string, stateName: string }) => {
      createLocation(data);
    },
    [createLocation]
  );

  return (
    <FieldWrapper>
      <LocationModal
        key                = {`${new Date()}`}
        isOpen             = {isOpenModal}
        onClose            = {() => setModalStatus(false)}
        handleSaveLocation = {handleSaveLocation}
      />
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={4}>
          <TitleBlock
            tabs         = {[privacyTabs[1]]}
            defaultValue = {privacyTabs[1].id}
            label        = {SETTINGS_SECTIONS.locations.title}
            description  = {SETTINGS_SECTIONS.locations.description}
            tooltip      = {SETTINGS_SECTIONS.locations.tooltip}
          />
        </Grid>

        <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }} />

        <Grid item xs={12} md={6}>
          <Grid container spacing={2} justifyContent="start">
            <Grid item xs={12}>
              {locations && (
                <RadioGroup defaultValue={locations.find(location => location.isPrimary)?.id} aria-label="gender" style={{ marginTop: '20px'}}>
                {(locations ?? []).map(location => (
                  <Locations.Location key={location.id}>
                    <FormControlLabel
                      value    = {location.id}
                      color    = "primary"
                      control  = {<Radio />}
                      label    = {location.placeName ?? ''}
                      onChange = {() => selectLocation({ ...location, isPrimary: true })}
                    />

                    {!location.isPrimary && (
                      <IconButton onClick={() => deleteLocation(location.id)}>
                        <Locations.RemoveIcon />
                      </IconButton>
                    )}
                  </Locations.Location>
                ))}
                </RadioGroup>
              )}

              <Button
                variant = "contained"
                size    = "large"
                style   = {{ margin: '20px 0 40px 0' }}
                onClick = {() => setModalStatus(true)}
              >
                Add a location
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FieldWrapper>
  );
}

Locations.Location = styled.div`
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  height          : 70px;
  border-top      : 1px solid ${({ theme }) => theme.colors.neutralGrey[450]};

  label {
    margin-bottom: 0;
  }

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutralGrey[450]}
  }
`;

Locations.RemoveIcon = styled(CloseIcon)`
  cursor : pointer;
  color  : ${({ theme }) => theme.colors.neutralGrey[600]}
`;
