import { useCallback, useMemo, useState } from 'react';
import styled                             from 'styled-components';
import { Button, Grid, LinearProgress }   from '@mui/material';
import { ReactComponent as AlertIcon }    from '../../images/Icon-Circle-Alert-M-Red.svg';
import { ReactComponent as SuccessIcon }  from '../../images/Icon-Circle-Success-M-Green.svg';
import { Notification }                   from '../../components/Notification';
import { bytesToKb }                      from '../../utils/conversion_utils';

interface IFilesUploader {
  onUpload            : (file: File) => void;
  progress            : number;
  isError?            : boolean;
  validateExtensionFn?: (fileName: string) => string;
  cancelFn?           : () => void;
  multiple?           : boolean;
  accept?             : string;
}

export const FilesUploader = ({
  progress,
  isError,
  onUpload,
  validateExtensionFn,
  cancelFn,
  accept   = '',
  multiple = false
}: IFilesUploader) => {
  const [selectedFile, setSelectedFile] = useState<File>();

  const selectFile = useCallback(
    async (event) => {
      const file              = event.target.files[0];
      const validationMessage = validateExtensionFn && validateExtensionFn(file.name);

      if (validationMessage) {
        Notification.enqueueSnackbar(validationMessage, 'error');
      } else {
        setSelectedFile(file);
      }
    },
    [validateExtensionFn]
  );

  const getLoadedSize = useMemo(
    () => () => progress * (bytesToKb(selectedFile?.size)) / 100,
    [progress, selectedFile?.size]
  );

  const cancelUploading = useCallback(
    () => {
      setSelectedFile(undefined);
      cancelFn && cancelFn();
    },
    [cancelFn]
  );

  const ErrorBlock = (
    <Grid container rowSpacing="10px">
      <Grid item container justifyContent="space-between">
        {selectedFile?.name ?? ''}

        <b>0 Kb</b>
      </Grid>

      <Grid item xs={12}>
        <FilesUploader.Status error>
          <AlertIcon />

          File Upload Error
        </FilesUploader.Status>
      </Grid>

      <Grid item container columnSpacing="10px">
        <Grid item>
          <Button color="secondary" variant="contained" onClick={() => setSelectedFile(undefined)}>
            Cancel
          </Button>
        </Grid>

        <Grid item>
          <Button variant="contained" onClick={() => selectedFile && onUpload(selectedFile)}>
            Retry
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  const SuccessBlock = (
    <Grid container rowSpacing="10px">
      <Grid item container justifyContent="space-between">
        {selectedFile?.name ?? ''}

        <b>{bytesToKb(selectedFile?.size)} Kb</b>
      </Grid>

      <Grid item xs={12}>
        <FilesUploader.Status>
          <SuccessIcon />

          File Upload Complete
        </FilesUploader.Status>
      </Grid>
    </Grid>
  );

  const BrowseBlock = (
    <>
      <label htmlFor="btn-upload">
        <FilesUploader.Input
          id       = "btn-upload"
          name     = "btn-upload"
          type     = "file"
          accept   = {accept}
          multiple = {multiple}
          onChange = {selectFile}
        />

        <Button variant="contained" component="span">
          Browse
        </Button>
      </label>

      <FilesUploader.NoFilesLabel>No File Selected</FilesUploader.NoFilesLabel>
    </>
  );

  const ProgressBlock = (
    <Grid container rowSpacing="10px">
      <Grid item xs={12}>
        <LinearProgress value={progress} variant="determinate" />
      </Grid>

      <Grid item container justifyContent="space-between">
        {selectedFile?.name ?? ''}

        <b>{getLoadedSize()} / {bytesToKb(selectedFile?.size)} Kb</b>
      </Grid>

      {cancelFn && <Grid item>
        <Button color="secondary" variant="contained" onClick={cancelUploading}>Cancel</Button>
      </Grid>}
    </Grid>
  );

  const UploaderBlock = (
    <Grid container rowSpacing="10px">
      <FilesUploader.FileName item>
        <b>{selectedFile?.name ?? ''}</b>
      </FilesUploader.FileName>

      <Grid item container columnSpacing="20px">
        <Grid item>
          <Button
            color   = "secondary"
            variant = "contained"
            onClick = {() => setSelectedFile(undefined)}
          >
            Clear
          </Button>
        </Grid>

        <Grid item>
          <Button variant="contained" onClick={() => selectedFile && onUpload(selectedFile)}>
            Upload
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderContent = () => {
    if (selectedFile) {
      if (progress > 0 && progress < 100) {
        return ProgressBlock;
      } else if (isError) {
        return ErrorBlock;
      } else if (progress === 100) {
        setTimeout(() => setSelectedFile(undefined), 1000);

        return SuccessBlock;
      } else {
        return UploaderBlock;
      }
    }
    return BrowseBlock;
  };

  return (
    <>
      {renderContent()}
    </>
  );
}

FilesUploader.Input = styled.input`
  display: none;
`;

FilesUploader.NoFilesLabel = styled.span`
  font-weight : 500;
  color       : ${({theme: {colors: {type: {medium}}}}) => medium};
  padding-left: 20px;
`;

FilesUploader.Status = styled.div<{error?: boolean}>`
  color      : ${({theme: {colors: {messaging: {green, red}}}, error}) => error ? red : green};
  display    : flex;
  align-items: center;
  font-weight: 700;

  svg {
    margin-right: 6px;
  }
`;

FilesUploader.FileName = styled(Grid)`
  overflow     : hidden;
  white-space  : nowrap;
  text-overflow: ellipsis;
`;
