import { useEffect }       from 'react';
import { format }          from 'date-fns';
import { Popper }          from '@mui/material';
import { ArrowBackIos }    from '@mui/icons-material';
import styled              from 'styled-components';
import { IRideInvitation } from '../../../../../../models';
import { TabType }         from '../../../TabsetDay';
import { IGenLocation }    from '../../../../../../../Profile/models/genLocation';
import { Header }          from '../Header';
import { Content }         from '../Content';

interface IMobileDayViewer {
  isOpen           : boolean;
  onClose          : () => void;
  dayIndex         : number;
  date             : Date;
  selectedTab      : TabType;
  rideCards        : IRideInvitation[];
  invitationCards  : IRideInvitation[];
  handleSelectTab  : (tab: TabType) => void;
  setActiveLocation: (genLocation: IGenLocation) => void;
  activeLocation?  : IGenLocation;
}

export const MobileDayViewer = ({
  isOpen,
  onClose,
  date,
  selectedTab,
  rideCards,
  invitationCards,
  dayIndex,
  handleSelectTab,
  setActiveLocation,
  activeLocation
}: IMobileDayViewer) => {
  useEffect(
    () => {
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = 'auto';
      };
    },
    [isOpen],
  );

  return (
    <MobileDayViewer.Wrapper open={isOpen}>
      <MobileDayViewer.Header>
        <MobileDayViewer.DateContainer>
          <MobileDayViewer.Close onClick={onClose}>
            <ArrowBackIos fontSize="small" /> Back
          </MobileDayViewer.Close>

          <MobileDayViewer.Date>
            {format(date, "EEEE d")}
          </MobileDayViewer.Date>
        </MobileDayViewer.DateContainer>

        <Header
          date              = {date}
          invitationCards   = {invitationCards}
          rideCards         = {rideCards}
          selectedTab       = {selectedTab}
          onSelectTab       = {handleSelectTab}
          activeLocation    = {activeLocation}
          setActiveLocation = {setActiveLocation}
          dayIndex          = {dayIndex}
          isMobile          = {false}
        />

      </MobileDayViewer.Header>
        <Content
          date            = {date}
          invitationCards = {invitationCards}
          rideCards       = {rideCards}
          selectedTab     = {selectedTab}
          activeLocation  = {activeLocation}
          isMobile
        />
      <MobileDayViewer.Content>
      </MobileDayViewer.Content>
    </MobileDayViewer.Wrapper>
  )
};

MobileDayViewer.Wrapper = styled(Popper)`
  position   : fixed;
  bottom     : 0;
  min-height : 100vh;
  width      : 100vw;
  z-index    : 1000;
  overflow   : auto;

  ${Header.Wrapper} {
    padding: 10px 0 0 0;

    h4 {
      display: none;
    }
  }

  ${Content.Wrapper} {
    padding: 32px 0 0 0;
  }
`;

MobileDayViewer.Header = styled.div`
  position        : relative;
  background-color: ${({ theme: { colors } }) => colors.neutralGrey[100]};
  box-shadow      : 0 2px 8px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
`;

MobileDayViewer.Content = styled.div`
  background-color: ${({ theme: { colors } }) => colors.neutralGrey[200]};
`;

MobileDayViewer.DateContainer = styled.div`
  display         : flex;
  justify-content : center;
  gap             : 10px;
  padding-top     : 20px;
  background-color: ${({ theme: { colors } }) => colors.neutralGrey[100]};
`;

MobileDayViewer.Close = styled.a`
  align-items               : center;
  display                   : flex;
  justify-content           : center;
  color                     : ${({ theme }) => theme.colors.primary.rubineLight};
  font-size                 : 14px;
  font-style                : normal;
  font-weight               : 700;
  line-height               : 14px;
  border-bottom-left-radius : 8px;
  border-bottom-right-radius: 8px;

  &:hover {
    color : ${({ theme }) => theme.colors.primary.rubineDark};
    cursor: pointer;
  }
`;

MobileDayViewer.Date = styled.h3`
  width : fit-content;
  margin: 0;
`;
