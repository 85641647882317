import {
  useCallback,
  useMemo,
  useState,
  MouseEvent,
  ChangeEvent,
  useEffect
}                                  from 'react';
import styled                      from 'styled-components';
import { useHistory }              from 'react-router';
import {
  ImageUploadButton,
  ImageUpload,
}                                  from 'react-file-utils';
import {
  Button,
  ButtonGroup,
  Dialog,
  IconButton,
  Tooltip,
}                                  from '@mui/material';
import {
  AlternateEmail,
  ArrowBackIosNew,
  Close,
  Photo,
  Place
}                                  from '@mui/icons-material';
import {
  EPostType,
  IPostImage,
  IPost
}                                  from '@models/Feed/post';
import { useOg }                   from '@utils/hooks/useOg';
import { useImageUpload }          from '@utils/hooks/useImageUpload';
import { useAuth }                 from '@modules/Auth/contexts/AuthContext';
import {
  useGetUserConnectionsQuery,
  useGetUserSettingsQuery
}                                  from '@modules/Profile/queries';
import { IConnection }             from '@modules/Profile/models/Community';
import { getUserAvatarUrl }        from '@utils/files_utils';
import { useGetStream }            from '@utils/hooks/useGetStream';
import { Main }                    from './components/Main';
import { Location }                from './components/Location';
import { Mentions }                from './components/Mentions';

interface IAddPostModal {
  postType  : EPostType | null;
  postToEdit: IPost | null;
  refresh?  : () => Promise<void>;
  onClose   : (reason?: string) => void;
}

enum EContentType {
  Main,
  Location,
  Tag
}

const getTitle = (content: EContentType) => {
  if (content === EContentType.Location) {
    return 'Select Location';
  }

  if (content === EContentType.Tag) {
    return 'Tag users';
  }

  return 'New Post';
};

export const PostModal = ({
  postType,
  onClose,
  refresh,
  postToEdit,
}: IAddPostModal) => {
  const { push }                                        = useHistory();
  const { onPaste, isLoadingOg, ogData, setOgData }     = useOg();
  const { images, setImages, uploadImage, removeImage } = useImageUpload();
  const { currentUser }                                 = useAuth();
  const { data: settings }                              = useGetUserSettingsQuery();
  const { data: connections }                           = useGetUserConnectionsQuery({ pageSize: 50 });
  const { handleCreatePost, handleUpdatePost }          = useGetStream();
  const photoUrl                                        = getUserAvatarUrl(settings?.profilePictureUrl, currentUser?.photoURL || '');
  const initialText                                     = postToEdit ? postToEdit.text.replaceAll('<br>', '') : '';
  const [text, setText]                                 = useState(initialText);
  const [contentType, setContentType]                   = useState<EContentType>(EContentType.Main);
  const [mentions, setMentions]                         = useState<IConnection[]>(postToEdit?.mentions ?? []);
  const [selectedLocation, setSelectedLocation]         = useState<MapboxGeocoder.Result | null>(postToEdit?.location as MapboxGeocoder.Result ?? null);
  const [isLoading, setLoading]                         = useState<boolean>(false);

  const showImagePreviewer = postType === EPostType.Photo || !!images.length;
  const isButtonDisabled   = (text.length === 0 && images.length === 0) || isLoading;

  useEffect(
    () => {
      if (postToEdit?.attachments.og) {
        setOgData(postToEdit.attachments.og);
      }

      if (postToEdit?.attachments.images?.length) {
        const postImages = postToEdit.attachments.images.map(url => ({
          id: url,
          url,
          state: 'uploaded',
          file: {
            name: url
          }
        })) as IPostImage[];

        setImages(postImages);
      }
    },
    [postToEdit, setImages, setOgData]
  );

  const handleFiles = useCallback(
    (files: File[]) => files.forEach(uploadImage),
    [uploadImage],
  );

  const handleClickMention = useCallback(
    (user: IConnection) => push(`/user/${user.username}`),
    [push],
  );

  const handleRemoveMention = useCallback(
    (id: string) => {
      setMentions((prevState) => prevState.filter((item) => item.id !== id));
    },
    []
  );

  const handleSavePost = useCallback(
    () => {
      setLoading(true);

      if (postToEdit) {
        handleUpdatePost({
          activity: postToEdit,
          location: selectedLocation,
          og: ogData,
          mentions,
          images,
          text,
        })?.then(() => {
          if (refresh) {
            refresh().then(() => {
              setLoading(false);
              onClose();
            })
          } else {
            setLoading(false);
            onClose();
          }
        });
      } else {
        handleCreatePost({
          location: selectedLocation,
          og      : ogData,
          mentions,
          images,
          text: text.replace(/\n/g, '<br>\n'),
        })?.then(() => {
          setLoading(false);
          onClose();
        });
      }
    },
    [
      handleCreatePost,
      handleUpdatePost,
      images,
      mentions,
      ogData,
      onClose,
      postToEdit,
      refresh,
      selectedLocation,
      text
    ],
  );

  const imageUploads = useMemo<ImageUpload[]>(
    () => images.map(({ id, url, file }) => ({
      id,
      url,
      state: 'finished',
      file
    })),
    [images],
  );

  const handleRemovePreviewLink = useCallback(
    (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      event.stopPropagation();
      setOgData(undefined);
    },
    [setOgData]
  );

  const handleInput = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setText(event.target.value);
    },
    []
  );

  return (
    <Dialog
      open
      onClose  = {(_event, reason) => onClose(reason)}
      fullWidth
      disableAutoFocus
      disableRestoreFocus
      disableEscapeKeyDown
    >
      <PostModal.Header>
        {contentType !== EContentType.Main && (
          <PostModal.BackButton onClick={() => setContentType(EContentType.Main)}>
            <ArrowBackIosNew />
            <p>Back</p>
          </PostModal.BackButton>
        )}

        <p>{getTitle(contentType)}</p>

        {contentType === EContentType.Main && (
          <PostModal.CloseButton
            onClick={() => onClose('escapeKeyDown')}
          >
            <Close />
          </PostModal.CloseButton>
        )}
      </PostModal.Header>

      <PostModal.Content>
        {contentType === EContentType.Main && (
          <Main
            onPaste                 = {onPaste}
            isLoadingOg             = {isLoadingOg}
            handleRemovePreviewLink = {handleRemovePreviewLink}
            photoUrl                = {photoUrl}
            handleInput             = {handleInput}
            text                    = {text}
            displayName             = {`${settings?.user.firstName} ${settings?.user.lastName}`}
            selectedLocation        = {selectedLocation}
            ogData                  = {ogData}
            showImagePreviewer      = {showImagePreviewer}
            imageUploads            = {imageUploads}
            mentions                = {mentions}
            handleClickMention      = {handleClickMention}
            handleRemoveImage       = {removeImage}
            handleRemoveMention     = {handleRemoveMention}
            handleFiles             = {handleFiles}
          />
        )}

        {contentType === EContentType.Location && (
          <Location setSelectedLocation={setSelectedLocation} selectedLocation={selectedLocation} />
        )}

        {contentType === EContentType.Tag && (
          <Mentions
            selectedUsers    = {mentions}
            setSelectedUsers = {setMentions}
            connections      = {connections?.content ?? []}
          />
        )}
      </PostModal.Content>

      <PostModal.Footer>
        <ButtonGroup>
          <Tooltip title="Photos" placement="top" arrow>
            <PostModal.IconButton $isActive={true}>
              <ImageUploadButton
                handleFiles = {handleFiles}
                multiple
              >
                <Photo />
              </ImageUploadButton>
            </PostModal.IconButton>
          </Tooltip>

          <Tooltip title="Location" placement="top" arrow>
            <PostModal.IconButton
              onClick   = {() => setContentType(EContentType.Location)}
              $isActive = {contentType === EContentType.Location || Boolean(selectedLocation)}
            >
              <Place />
            </PostModal.IconButton>
          </Tooltip>

          <Tooltip title="Mentions" placement="top" arrow>
            <PostModal.IconButton
              onClick   = {() => setContentType(EContentType.Tag)}
              $isActive = {contentType === EContentType.Tag}
            >
              <AlternateEmail />
            </PostModal.IconButton>
          </Tooltip>
        </ButtonGroup>

        {contentType === EContentType.Main && (
          <Button
            variant  = "contained"
            color    = "primary"
            disabled = {isButtonDisabled}
            onClick  = {handleSavePost}
          >
            Save
          </Button>
        )}

        {contentType !== EContentType.Main && (
          <Button
            variant  = "outlined"
            color    = "primary"
            onClick  = {() => setContentType(EContentType.Main)}
          >
            Select
          </Button>
        )}
      </PostModal.Footer>
    </Dialog>
  )
}

PostModal.Header = styled.div`
  position       : relative;
  display        : flex;
  justify-content: center;
  align-items    : center;
  border-bottom  : ${({ theme }) => `1px solid ${theme.colors.neutralGrey[400]}`};
  height         : 60px;

  p {
    font-weight : 700;
  }
`;

PostModal.Content = styled.div`
  padding   : 10px 20px 20px 20px;
  box-shadow: inset 1px 4px 13px 0px rgb(38 37 37 / 12%);
  min-height: 250px;
  overflow  : auto;
`;

PostModal.BackButton = styled.div`
  position   : absolute;
  display    : flex;
  align-items: center;
  gap        : 5px;
  left       : 20px;
  color      : ${({ theme }) => theme.colors.primary.rubineLight};
  cursor     : pointer;
`;

PostModal.CloseButton = styled(IconButton)`
  position: absolute;
  right   : 20px;
`;

PostModal.Footer = styled.div`
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  height          : 70px;
  padding         : 0 20px;
  border-top      : ${({ theme }) => `1px solid ${theme.colors.neutralGrey[400]}`};

  .rfu-image-upload-button {
    label {
      display: block;
      width  : 24px;
      height : 24px;

      svg {
        fill: ${({ theme }) => theme.colors.neutralGrey[600]};
      }

      svg:hover {
        fill: ${({ theme }) => theme.colors.primary.rubineLight};
      }
    }
  }

  button {
    height: 40px;
  }

  svg {
    cursor: pointer;
    color : ${({ theme }) => theme.colors.neutralGrey[600]};
  }

  svg:hover {
    color: ${({ theme }) => theme.colors.primary.rubineLight};
  }
`;

PostModal.IconButton = styled(IconButton)<{ $isActive?: boolean }>`
  ${({ $isActive, theme }) => $isActive && `
    svg {
      color: ${theme.colors.primary.rubineLight};
    }

    .rfu-image-upload-button {
      color: ${theme.colors.primary.rubineLight}
    }
  `}
`;
