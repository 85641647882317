import {
  useRef,
  useEffect,
  useState,
  useCallback,
}                                        from 'react';
import axios                             from 'axios';
import { throttle }                      from 'lodash';
import { useDispatch }                   from 'react-redux';
import styled, { css }                   from 'styled-components';
import mapboxgl, { Map }                 from 'mapbox-gl';
import MapboxGeocoder                    from '@mapbox/mapbox-gl-geocoder';
import { Button }                        from '@mui/material';
import { getGenLocationById }            from '@modules/Calendar';
import { IGenLocation }                  from '@modules/Profile/models/genLocation';
import { ILocation }                     from '@modules/Profile/models/Settings';
import { useCreateUserLocationMutation } from '@modules/Profile/queries';
import { setCurrentLocation }            from '@store/reducers/location';
import { scrimBlack, scrimWhite }        from '@styles/theme';
import { DEFAULT_LOCATION }              from '@components/LocationModal';
import MarkerIcon                        from '@images/Map-Preem-Marker.svg';
import IconAddRubine                     from '@images/Icon-Add-Rubine.svg'
import IconRemoveRubine                  from '@images/Icon-Remove-Rubine.svg'
import IconLocationRubine                from '@images/Icon-Location-Rubine.svg';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';

interface ISignupLocationProps {
  allowMap      : boolean;
  onSignupClick : () => void;
}

interface ILocationState {
  lat      : number;
  lng      : number;
  cityName : string;
  stateName: string;
  isPrimary: boolean;
}

export const SignupLocation = ({
  allowMap,
  onSignupClick,
}: ISignupLocationProps) => {
  const dispatch                      = useDispatch();
  const mapContainerRef               = useRef(null);
  const [location, setLocation]       = useState<ILocationState>({
    lat      : DEFAULT_LOCATION.latitude,
    lng      : DEFAULT_LOCATION.longitude,
    cityName : '',
    stateName: '',
    isPrimary: true
  });
  const [prevLocation, setPrevLocation] = useState<{ lat: number, lng: number}>({ lat: 0, lng: 0 });
  const [marker, setMarker]             = useState<mapboxgl.Marker | null>(null);
  const [zoom, setZoom]                 = useState(10);
  const [allowSubmit, setAllowSubmit]   = useState(false);
  const [createUserLocation]            = useCreateUserLocationMutation();
  const map                             = useRef<Map | null>(null);
        mapboxgl.accessToken            = process.env.REACT_APP_PREEM_MAPBOX_ACCESS_TOKEN || "";

  const disableClickNext = useCallback(
    () => {
      setAllowSubmit(false);
    },
    []
  );

  const updateUserLocation = useCallback(
    (newLocation: ILocation) => {
      getGenLocationById(newLocation.genLocationId)
      .then((genLocation: IGenLocation) => dispatch(setCurrentLocation(genLocation)))
    },
    [
      dispatch,
    ],
  );

  const onSubmit = useCallback(
    () => {
      disableClickNext();
      createUserLocation(location).then((res: any) => updateUserLocation(res?.data)).then(onSignupClick)
    },
    [createUserLocation, location, onSignupClick, disableClickNext, updateUserLocation]
  );

  const createMarker = useCallback(
    (lng: number, lat: number) => {
      setMarker(null);

      marker?.remove();
      document.querySelector('.mapboxgl-marker')?.remove();

      const markerEl     = document.createElement('img');
            markerEl.src = MarkerIcon;

      const newMarker = map.current && new mapboxgl.Marker(markerEl).setLngLat({ lat, lng }).addTo(map.current);
      setMarker(newMarker);
    },
    [marker]
  );

  // throttle the reverse geocoding API call
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lookupPlace = useCallback(
    throttle(
      (lng, lat) => {
        axios
          .get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${process.env.REACT_APP_PREEM_MAPBOX_ACCESS_TOKEN}&types=place,district,region`
          )
          .then((res) => {
            if (
              res.data &&
              res.data.features[0]
            ) {
              const placeNameFormatted: string = formatPlaceName(
                res.data.features[0]
              );
              const fixedLng = lng.toFixed(5);
              const fixedLat = lat.toFixed(4);

              setLocation(prevLocation => ({
                ...prevLocation,
                lng: fixedLng,
                lat: fixedLat,
                placeName: placeNameFormatted
              }));

              createMarker(fixedLng, fixedLat);

              const geoInput: HTMLInputElement | null = document.querySelector('.mapboxgl-ctrl-geocoder--input');

              if (geoInput) {
                geoInput.value = placeNameFormatted;
              }

              setAllowSubmit(true);
            }
          });
      },
      1000,
      { trailing: true }
    ),
    []
  );

  const getLocation = useCallback(
    () => {
      navigator.geolocation.getCurrentPosition(position => {
        const geoLat = Number(position.coords.latitude.toFixed(4));
        const geoLng = Number(position.coords.longitude.toFixed(5));

        setLocation(prevLocation => ({
          ...prevLocation,
          lat: geoLat,
          lng: geoLng
        }));

        lookupPlace(geoLng, geoLat);

        if (map.current) {
          map.current.setCenter([geoLng, geoLat]);
        }
      },
      error => {
        if (error.code === error.PERMISSION_DENIED) {
          lookupPlace(location.lng, location.lat);
        }
      });
    },
    [location, setLocation, lookupPlace]
  );

  const formatPlaceName = useCallback(
    (feature: MapboxGeocoder.Result) => {
      const cityName  = feature?.place_name?.replace(/\d{5}, United States/, '')?.split(',')[0] ?? '';
      const stateName = feature?.context?.find((context: any) => context && context.short_code)?.text ?? '';

      setLocation(prevLocation => ({
        ...prevLocation,
        cityName,
        stateName
      }));

      return stateName.length ? `${cityName}, ${stateName}` : cityName;
    },
    []
  );

  // create map and setup map events
  useEffect(
    () => {
      if (map.current) return;

      if (allowMap) {
        map.current = new mapboxgl.Map({
          container          : mapContainerRef.current ?? '',
          style              : 'mapbox://styles/mapbox/outdoors-v11',
          center             : [location.lng, location.lat],
          zoom               : zoom,
          attributionControl : false,
        });

        map.current.addControl(new mapboxgl.AttributionControl(), 'bottom-left');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allowMap]
  );

  const handleMoveEnd = useCallback(
    (e: Event) => {
      // geolocate button triggers a fake moveend event, so we need to ignore it
      if ('geolocateSource' in e) {
        return;
      }

      if (map.current) {
        const { lat: centerLat, lng: centerLng } = map.current.getCenter();

        setPrevLocation({
          lng: Number(centerLng.toFixed(5)),
          lat: Number(centerLat.toFixed(4))
        });

        setLocation(prevLocation => ({
          ...prevLocation,
          lng: Number(centerLng.toFixed(5)),
          lat: Number(centerLat.toFixed(4))
        }));

        setZoom(Number(map.current.getZoom().toFixed(0)));
        createMarker(centerLng, centerLat);

        setTimeout(() => {
          lookupPlace(centerLng, centerLat);
        }, 200);

        setTimeout(() => {
          setAllowSubmit(true);
        }, 400);
      }
    },
    [createMarker, lookupPlace]
  );

  useEffect(
    () => {
      if (!map.current) return;

      if (allowMap) {
        map.current
          .on('mousedown', disableClickNext)
          .on('touchstart', disableClickNext)
          .on('movestart', disableClickNext)
          .on('zoomstart', disableClickNext)
          .on('wheel', disableClickNext)
          .on('moveend', handleMoveEnd);
      } else {
        map.current
          .off('mousedown', disableClickNext)
          .off('touchstart', disableClickNext)
          .off('movestart', disableClickNext)
          .off('zoomstart', disableClickNext)
          .off('wheel', disableClickNext)
          .off('moveend', handleMoveEnd);
      }

      return () => {
        if (!map.current) return;
        map.current
          .off('mousedown', disableClickNext)
          .off('touchstart', disableClickNext)
          .off('movestart', disableClickNext)
          .off('zoomstart', disableClickNext)
          .off('wheel', disableClickNext)
          .off('moveend', handleMoveEnd);
      };
    }
  );

  const handleGeolocate = useCallback(
    (e: GeolocationPosition | object | undefined) => {
      disableClickNext();

      if (e && 'coords' in e && map.current) {
        map.current.flyTo({
          center: [e.coords.longitude, e.coords.latitude],
          zoom: zoom,
        });
      }
    },
    [disableClickNext, zoom]
  );

  // add geolocate button and event handling
  useEffect(() => {
    if (!map.current) return;

    const geolocateControl = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation : false,
      showUserHeading   : false,
      showUserLocation  : false,
    });

    geolocateControl.on('geolocate', handleGeolocate);
    map.current.addControl(geolocateControl);
    map.current.addControl(
      new mapboxgl.NavigationControl({ showCompass: false }),
      'bottom-right'
    );

    return () => {
      geolocateControl.off('geolocate', handleGeolocate);
    };
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  []
);

  // add geocoder and geocoder event handling
  useEffect(
    () => {
      if (!map.current) return;

      const geocoder = new MapboxGeocoder({
        flyTo: {
          // ms, higher number makes the animation go slower
          duration: 2000,
          zoom    : zoom,
        },
        getItemValue: item => formatPlaceName(item),
        types       : 'place,district,region',
        placeholder : 'Location',
        marker      : false,
        accessToken : mapboxgl.accessToken,
        render      : item => `<div class='geocoder-dropdown-item'>${formatPlaceName(item)}</div>`
      });

      geocoder
        .on('loading', disableClickNext)
        .on('clear', disableClickNext)
        .on('result', () => {
          setPrevLocation({
            lng: location.lng,
            lat: location.lat
          });
        });

      const geocoderTarget: Element | null =
        document.querySelector('.geocoder-target');
      if (geocoderTarget !== null && !geocoderTarget.hasChildNodes()) {
        geocoderTarget.appendChild(geocoder.onAdd(map.current));
      }
      return () => {
        geocoder.off('loading', disableClickNext);
        geocoder.off('clear', disableClickNext);
        geocoder.off('result', () => {
          setPrevLocation({
            lng: location.lng,
            lat: location.lat
          });
        });
      };
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // the geocoder uses the Enter key to clear the input, so we're redefining that behavior
  useEffect(
    () => {
      const inputElement: HTMLInputElement | null = document.querySelector('.mapboxgl-ctrl-geocoder--input');

      const handleKeyPress = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          e.preventDefault();

          if (prevLocation.lat !== location.lat && prevLocation.lng !== location.lng) {
            setPrevLocation({
              lng: location.lng,
              lat: location.lat
            });

            lookupPlace(location.lng, location.lat);
            createMarker(location.lng, location.lat);
          } else {
            onSubmit();
          }
        }
      };

      inputElement?.addEventListener('keydown', handleKeyPress);

      return () => inputElement?.removeEventListener('keydown', handleKeyPress);
    },
    [createMarker, location, lookupPlace, onSubmit, prevLocation.lat, prevLocation.lng]
  );

  // get initial location
  useEffect(
    () => {
      getLocation();
      createMarker(location.lng, location.lat);
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <SignupLocation.Wrapper>
      <SignupLocation.Head>
        <h1>Choose your location</h1>
        <p>
          Preem wants to provide you with relevant rides before your first pedal
          stroke.
        </p>
      </SignupLocation.Head>

      <SignupLocation.MapContainer ref={mapContainerRef} />

      <div className="geocoder-target" />

      <SignupLocation.SubmitBtn
        fullWidth
        onClick  = {onSubmit}
        variant  = "contained"
        size     = "large"
        disabled = {!allowSubmit}
      >
        Next
      </SignupLocation.SubmitBtn>
    </SignupLocation.Wrapper>
  );
};

SignupLocation.Wrapper = styled.div` && {
  ${({ theme: { colors } }) => css`
    .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
      border          : none !important;
      background-color: ${scrimBlack(0.16)};
      outline         : 0 !important;
      min-width       : 100%;
      margin-bottom   : 2em;

      .mapboxgl-ctrl-geocoder--icon {
        top: 13px;
        &.mapboxgl-ctrl-geocoder--icon-search,
        &.mapboxgl-ctrl-geocoder--icon-close {
          fill: ${scrimWhite(0.64)};
        }
      }
       
      .mapboxgl-ctrl-geocoder--input {
        background-color: ${scrimBlack(0.04)};
        border-radius   : 4px;
        border          : none !important;
        outline         : 0 !important;
        box-shadow      : none;
        color           : ${scrimWhite(0.64)};
        font-family     : "Inter", sans-serif;
        font-size       : 14px;
        line-height     : 14px;
        font-weight     : 700;
        height          : 48px;
        padding-bottom  : 5px;
        padding-left    : 36px;
        padding-top     : 5px;
        transition      : all 0.3s ease-in-out;
        &::placeholder {
          color: ${scrimWhite(0.64)};
        }
        &:focus {
          box-shadow: 0 0 0px 2px ${colors.primary.white};
          color     : ${colors.primary.white};
        }
      }

      .mapboxgl-ctrl-geocoder--pin-right {
        & > * {
          top: 12px;
        }
        & > button {
          background-color: transparent !important;
        }
      }

      .suggestions-wrapper {
        .suggestions {
          background: ${scrimBlack(0.74)} !important;
          li {
            &.active {
              a {
                .geocoder-dropdown-item {
                  color: ${scrimBlack(0.64)} !important;
                }
              }
            }
            a {
              color: ${scrimWhite(0.64)} !important;
              &:hover {
                .geocoder-dropdown-item {
                  color: ${scrimBlack(0.64)} !important;
                }
              }
            }
          }
        }
      }
    }

    .mapbox-ctrl,
    .mapboxgl-ctrl-group {
      display       : flex;
      flex-direction: row-reverse;
      background    : transparent;
      &:not(:empty) {
        box-shadow: none;
      }
      button {
        border-radius   : 100%;
        background-color: ${colors.primary.white};
        height          : 32px;
        width           : 32px;
        
        &:focus:last-child,
        &:focus:first-child {
          border-radius: 100%;
        }
        &:not(:disabled):hover,
        &:hover {
          transform : scale(1.1);
        }
      }
    }

    .mapboxgl-ctrl-zoom-in {
      transition: all 200ms ease-in;

      .mapboxgl-ctrl-icon {
        background-image: url(${IconAddRubine});
      }
    }

    .mapboxgl-ctrl-zoom-out {
      margin-right: 0.5em;
      transition  : all 200ms ease-in;
      
      .mapboxgl-ctrl-icon {
        background-image: url(${IconRemoveRubine});
      }
    }

    .mapboxgl-ctrl-geolocate {
      transition: all 200ms ease-in;
      
      .mapboxgl-ctrl-icon {
        background-image: url(${IconLocationRubine});
      }
    }
  `}
}`;

SignupLocation.Head = styled.div`
  position        : relative;
  display         : flex;
  margin          : 16px 0;
  flex-direction  : column;
  justify-content : center;
  align-self      : stretch;
  text-align      : center;

  @media screen and (min-width: 1920px) {
    margin-bottom: 16px;
  }

  h1 {
    color      : #FFF;
    margin-top : 0;
  }

  p {
    margin-bottom : 8px;
    font-size     : 16px;
    font-weight   : 400;
    color         : #FFF;
  }
`;

SignupLocation.MapContainer = styled.div`
  height        : 300px;
  width         : 400px;
  margin-bottom : 2em;
  border-radius : 8px;
`;

SignupLocation.SubmitBtn = styled(Button)`
  justify-content: center;
`;
