import styled                     from 'styled-components';
import ArrowForwardIosIcon        from '@mui/icons-material/ArrowForwardIos';
import { IConfigCell, TableData } from '@components/TableData';
import { TOrderSortType }         from '@models/types';

const PREVIEW_ELEMENTS = 5;

interface IPreviewTable<T> {
  title      : string;
  totalCount : number;
  configCells: IConfigCell<T>[];
  data       : T[];
  isLoading  : boolean;
  onExpand   : () => void;
  onSort     : (orderBy: string, order: TOrderSortType) => void;
  order      ?: TOrderSortType;
  orderBy    ?: string;
}

export const PreviewTable = <T extends { id: string }>({
  title,
  totalCount,
  configCells,
  data,
  isLoading,
  onExpand,
  onSort,
  order,
  orderBy
}: IPreviewTable<T>) => (
  <>
    {totalCount > PREVIEW_ELEMENTS && (
      <PreviewTable.Title onClick={() => onExpand()}>
        <span>{totalCount}</span>
        <strong>{title}</strong>

        <ArrowForwardIosIcon fontSize="small" />
      </PreviewTable.Title>
    )}
    <TableData
      configCells   = {[{ label: '', width: '5px' }, ...configCells]}
      data          = {data}
      title         = {totalCount > PREVIEW_ELEMENTS ? undefined : title}
      totalElements = {totalCount}
      isLoading     = {isLoading}
      onSort        = {onSort}
      order         = {order}
      orderBy       = {orderBy}
    />
  </>
);

PreviewTable.Title = styled.p`
  text-align    : left;
  margin        : 20px 0 8px;
  font-size     : 18px;
  line-height   : 156%;
  position      : relative;
  color         : ${({ theme }) => theme.colors.primary.rubineLight};
  font-weight   : 700;
  font-size     : 22px;
  line-height   : 124%;
  letter-spacing: -0.4px;
  width         : max-content;
  cursor        : pointer;

  span {
    margin-right: 10px;
  }

  strong {
    margin-right  : 10px;
    margin-bottom : 8px;
  }

  svg {
    font-weight: bold;
    position   : absolute;
    top        : 4px;
  }

  &:hover {
    opacity: 0.8;
  }
`;
