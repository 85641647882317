import { Grid }                     from "@mui/material";
import styled                       from "styled-components";
import { FooterBullet, FooterLink } from "../Footer";

export const LoginSignupFooter = () => (
  <LoginSignupFooter.Container 
    container 
    sx         = {{ 
      flexDirection : {xs: 'column', md: 'row'},
      justifyContent: {xs: 'center', md: 'space-between'}
    }}
    alignItems = "center"
  >

    <Grid item xs="auto">&copy;{ new Date().getFullYear() } PREEM. All rights reserved</Grid>

    <Grid item container alignItems="center" xs="auto">
      <FooterLink>
        Terms &amp; Conditions
      </FooterLink>

      <FooterBullet>•</FooterBullet>

      <FooterLink>
        Privacy
      </FooterLink>

      <FooterBullet>•</FooterBullet>
      
      <FooterLink>
        cookies
      </FooterLink>
    </Grid>
  </LoginSignupFooter.Container>
);

LoginSignupFooter.Container = styled(Grid)`
  position       : fixed;
  bottom         : -10px;
  z-index        : 500;
  padding        : 0 6% 40px;
  color          : rgba(255, 255, 255, 0.48);
  font-size      : 11px;
  font-weight    : 500;
  letter-spacing : 0.8px;
  text-transform : uppercase;

  a {
    cursor     : pointer;
    font-weight: 500;
  }

  ${({theme: {mediaQueries: {md}}}) => md} {
    bottom : 0;
    padding: 0 20px 20px;
  }
`;