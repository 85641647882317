import {
  useCallback,
  useState,
  useMemo,
  useEffect,
  Suspense,
}                                 from 'react';
import {
  Link,
  useLocation,
  useParams,
}                               from 'react-router-dom';
import styled, { css }          from 'styled-components';
import { Grid, Tab, Tabs }      from '@mui/material';
import { scrimBlack }           from '@styles/theme';
import { RideDetailsContainer } from '../../containers/RideDetails';
import {
  RIGHT_PANEL_CONTENT,
  RIGHT_PANEL_CONTENT_TYPE
}                               from '../../models/rightPanelContent';
import { RightBlockHeader }     from '../RightBlockHeader';
import { Popup }                from '../Popup';
import { RideHistory }          from '../RideHistory';
import {
  RIDE_TABS,
  ERideTab
}                               from '../../models/rideTabs';
import RideRouteDetails         from '../RideRouteDetails';
import { WeatherDetails }       from '../WeatherDetails';

interface IContentProps {
  rightPanelContent: RIGHT_PANEL_CONTENT_TYPE;
  isRecurringRide  : boolean;
}

export const Content = ({
  rightPanelContent,
  isRecurringRide,
}: IContentProps) => {
  const previousContent             = RIGHT_PANEL_CONTENT[rightPanelContent].previousContent;
  const rightPanelHeader            = RIGHT_PANEL_CONTENT[rightPanelContent].title;
  const { pathname }                = useLocation();
  const [currentTab, setCurrentTab] = useState<ERideTab>(RIDE_TABS.find(tab => pathname.includes(tab.value))?.value || ERideTab.NEXT_RIDE);
  const { linkUuid }                = useParams<{ rideId?: string, routeId?: string, tab: string; linkUuid?: string; }>();

  const handleChange = useCallback(
    (_event, newValue: ERideTab) => {
      setCurrentTab(newValue);
    },
    []
  );

  const RouteDetails = useMemo(
    () => (
    <Grid item xs={12} sm={5} overflow="auto">
      <Suspense fallback>
        <RideRouteDetails />
      </Suspense>

      {isRecurringRide ? (currentTab === ERideTab.NEXT_RIDE ? <WeatherDetails /> : null) : <WeatherDetails />}
    </Grid>
    ),
    [currentTab, isRecurringRide]
  );

  const FullPageView = useMemo(
    () => (
      <Content.DetailsContainer>
        {rightPanelHeader &&
          <RightBlockHeader
            title       = {rightPanelHeader}
            onBackClick = {() => previousContent && RideDetailsContainer.changeRightContent(previousContent)}
          />
        }

        {RIGHT_PANEL_CONTENT[rightPanelContent].content}
      </Content.DetailsContainer>
    ),
    [previousContent, rightPanelContent, rightPanelHeader]
  );

  const SingleRideDetails = useMemo(
    () => (
      <Content.RightPanel item xs={12} sm={7} $withPadding>
        {rightPanelHeader &&
          <RightBlockHeader
            title       = {rightPanelHeader}
            onBackClick = {() => previousContent && RideDetailsContainer.changeRightContent(previousContent)}
          />
        }

        {RIGHT_PANEL_CONTENT[rightPanelContent].content}
      </Content.RightPanel>
    ),
    [previousContent, rightPanelContent, rightPanelHeader]
  );

  useEffect(
    () => {
      if (linkUuid) {
        RideDetailsContainer.changeRightContent(RIGHT_PANEL_CONTENT_TYPE.RIDE_DETAILS_INVITE);
        setCurrentTab(ERideTab.RIDE_INVITE);
      }
    },
    [currentTab, linkUuid]
  );

  return (
    <>
      {isRecurringRide ? (
        <Tabs
          centered
          value    = {currentTab}
          onChange = {handleChange}
        >
          {RIDE_TABS.map(tab => (
            <Tab
              key       = {tab.label}
              label     = {tab.label}
              value     = {tab.value}
              to        = {tab.value}
              component = {Link}
            />
          ))}
        </Tabs>
      ) : (
        currentTab !== ERideTab.RIDE_INVITE && (
        <Content.Container
          container
          hidden         = {!!previousContent}
          $recurringRide = {isRecurringRide}
        >
          {RouteDetails}
          {SingleRideDetails}
        </Content.Container>
      )) || (
        <Content.Container
          container
          hidden         = {!!previousContent}
          $recurringRide = {isRecurringRide}
        >
          {FullPageView}
        </Content.Container>
        )
      }

      {isRecurringRide && (
        <Content.Container
          container
          hidden         = {!!previousContent}
          $recurringRide = {isRecurringRide}
        >
          <>
            {(currentTab !== ERideTab.RIDE_HISTORY) && RouteDetails}

            {currentTab === ERideTab.NEXT_RIDE && SingleRideDetails}

            {currentTab === ERideTab.RIDE_HISTORY && <RideHistory />}
          </>
        </Content.Container>
      )}

      <Popup />
    </>
  );
}

Content.Container = styled(Grid)<{ hidden: boolean, $recurringRide: boolean }>`
  ${({ hidden, $recurringRide }) => css`
    height     : ${hidden && 'calc(100% - 92px)'};
    overflow-y : auto;
    width      : 100%

    ${({theme: {mediaQueries: { sm }}}) => sm} {
      height     : ${$recurringRide ? 'calc(100% - 128px)' : 'calc(100% - 92px)'};
      overflow-y : unset;
    }
  `};
`;

Content.RightPanel = styled(Grid)<{ $withPadding?: boolean}>`
  ${({ theme: {colors: { neutralGrey, primary }}, $withPadding}) => css`
    height    : 100vh;
    padding   : ${$withPadding && '20px'};
    overflow-y: auto;
    overflow-x: hidden;
    border-top: ${neutralGrey[400]};

    ${({theme: {mediaQueries: { sm }}}) => sm} {
      background: ${primary.white};
      padding   : ${$withPadding && '32px 55px 24px 40px'};
      box-shadow: inset -1px 9px 10px 1px ${ scrimBlack(0.04) };
      border    : none;
    }
  `}
`;

Content.DetailsContainer = styled.div`
  margin          : 0px 10px 0px 10px;
  padding         : 0px 5px 0px 5px;
  width           : 100%;
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
`;
