import { createApi }               from '@reduxjs/toolkit/query/react';
import {
  ILocation,
  ILocationSubmit,
  ISettingsForm
}                                  from '../models/Settings';
import {
  IUserInfo,
  IUserProfilePhoto
}                                  from '../models/UserInfo';
import { GarminConnectionRequest } from '../pages/Apps/models/garminConnection';
import { IConnectionResponse }     from '../models/Community';
import {
  IErrorsResponse,
  IRideHistoryParams,
  IRideHistoryResponse
}                                  from '../models/RideHistory';
import { INonMemberInvitation }    from '../../CreateModal/models/NonMemberInvite';
import { axiosBaseQuery }          from '@utils/baseQuery';
import { appendUrlParams }         from '@utils/commonFunctions';
import { ENotificationAction }     from '@components/NotificationsPopper/models/notification';
import { IUserStatistics }         from '@components/UserStatisticsTabs/model/statistics';
import {
  INotificationResponse,
  INotification
}                                  from '@components/NotificationsPopper/models/notification';
import { INVITATION_STATUS }       from '@modules/Details/models/invitation';
import { IProcessingJob }          from '@modules/Profile/models/job';
import {
  IResponseWithPagination,
  TPagination
}                                  from '@models/types';
import {
  IRouteParams,
  IRouteTableResponse,
}                                  from '@models/RouteModel';
import {IRecommendation }          from '@models/Feed/recommendations';
import { IUserBaseInfo }           from '../models/UserInfo';
import { IGenLocation }            from '../models/genLocation';

export const USER_PROFILE_URL = 'user-profile';
export const USER_ROUTES_URL  = 'routes';
const USER_RIDE_HISTORY_URL   = 'rides';
const USER_SETTINGS_URL       = 'user-settings';
const USER_LOCATIONS_URL      = 'locations';
const CONNECTIONS_URL         = 'connections';
const USERS_URL               = 'users';
const PASSWORD                = 'password';
const GENLOCATIONS            = 'genlocations';
const NOTIFICATIONS           = 'notifications';

const SELF_URL                = `${USERS_URL}/self`;
const RESET_PASSWORD          = `${USERS_URL}/${PASSWORD}/reset`;
const UPDATE_FORGOT_PASSWORD  = `${USERS_URL}/${PASSWORD}`;
const VERIFY_RESET_CODE       = `${USERS_URL}/${PASSWORD}/reset-code/verify`;

const USER_STATISTICS_URL      = `${USER_PROFILE_URL}/statistics`;
const USER_INFO_URL            = `${USER_PROFILE_URL}/short-info`;
const UPDATE_PROFILE_PICTURE   = `${USER_SETTINGS_URL}/profile-picture`;
const UPDATE_PROFILE_PHOTOS    = `${USER_SETTINGS_URL}/public-photos`;
const USER_PHONE_SETTINGS      = `${USER_SETTINGS_URL}/phone-numbers`;
const VERIFY_USER_PHONE        = `${USER_PHONE_SETTINGS}/codes`;
const USER_PROFILE_CONNECTIONS = `${USER_PROFILE_URL}/${CONNECTIONS_URL}`;
const SEND_INVITATION          = `${CONNECTIONS_URL}/invitations/bulk`;
const SEND_CONNECTIONS_BULK    = `${CONNECTIONS_URL}/bulk`;
const USER_RIDE_HISTORY_ERRORS = `${USER_RIDE_HISTORY_URL}/errors`;
const RIDE_UPLOAD              = `${USER_RIDE_HISTORY_URL}/upload`;
const JOBS_INFO_URL            = `${USER_RIDE_HISTORY_URL}/jobs`;

const PUBLIC_PROFILE                  = `${USER_PROFILE_URL}/:username`;
const PUBLIC_PROFILE_STATISTICS       = `${PUBLIC_PROFILE}/statistics`;
const PUBLIC_PROFILE_SHORT_INFO       = `${PUBLIC_PROFILE}/short-info`;
const PUBLIC_PROFILE_SHORT_INFO_BY_ID = `${USERS_URL}/:userId`
const CONNECTIONS                     = 'connections';
const INVITATIONS                     = 'invitations';
const CONNECTION_INVITATIONS          = `${CONNECTIONS}/${INVITATIONS}`
const DECLINE_INVITATION              = `${CONNECTION_INVITATIONS}/:connectionId`;

const GARMIN_URL            = 'garmin';
const GARMIN_CONNECTION_URL = `${GARMIN_URL}/temporary`;
const CONNECT_GARMIN_URL    = `${GARMIN_URL}/connection`;

const WAHOO             = 'wahoo';
const WAHOO_ACCESS_URL  = `${WAHOO}/connection`;
const WAHOO_AUTH_URL    = `${WAHOO}/authorization_url`;
const CONNECT_WAHOO_URL = `${WAHOO}/connect`;

const RIDE_GPS            = 'ridewithgps';
const RIDE_GPS_AUTH_URL   = `${RIDE_GPS}/authorization_url`;
const RIDE_GPS_STATUS     = `${RIDE_GPS}/connection`;
const RIDE_GPS_CONNECT    = `${RIDE_GPS}/connect`;
const RIDE_GPS_DISCONNECT = `${RIDE_GPS}/connection`;
const RIDE_GPS_IMPORT     = `${RIDE_GPS}/routes/import`;

const USER_CONNECTED_GEN_LOCATIONS        = `${GENLOCATIONS}/plannedrides/${USERS_URL}/current`;
const USER_ROUTES_CONNECTED_GEN_LOCATIONS = `${GENLOCATIONS}/${USER_ROUTES_URL}/${USERS_URL}/current`;
const MY_GENLOCATIONS                     = `${GENLOCATIONS}/${USERS_URL}/current`;

const RECOMMENDATIONS             = 'recommendations';
const CONNECTIONS_RECOMMENDATIONS = `${CONNECTIONS}/${RECOMMENDATIONS}`;

export const profileApi = createApi({
  baseQuery   : axiosBaseQuery,
  reducerPath : 'profileApi',
  tagTypes    : [
    'Settings',
    'Locations',
    'Statistics',
    'UserInfo',
    'Connections',
    'RideHistory',
    'UsersForInvite',
    'RideHistoryErrors',
    'GarminAccess',
    'GarminConnection',
    'WahooAccess',
    'WahooAuthUrl',
    'RideWithGpsAuthUrl',
    'RideWithGpsStatus',
    'PublicProfileStatistics',
    'PublicProfileShortInfo',
    'Routes',
    'Self',
    'UserConnectedGenLocations',
    'UserRoutesConnectedGenLocations',
    'UserGenLocations',
    'ProfilePhotos',
    'PrimaryLocation',
    'Invitations',
    'Notifications',
    'Recommendations',
    'GetStreamToken',
    'ProcessingJobs',
  ],
  endpoints   : (builder) => ({
    // GET User General Settings
    getUserSettings    : builder.query<ISettingsForm, void>({
      query            : () => ({ url: USER_SETTINGS_URL }),
      providesTags     : ['Settings'],
    }),
    // GET User Locations
    getUserLocations   : builder.query<ILocation[], void | { isMasked?: boolean; }>({
      query            : (params) => ({ url: params?.isMasked ? `${USER_LOCATIONS_URL}/masked` : USER_LOCATIONS_URL }),
      providesTags     : ['Locations'],
      transformResponse: ({ locations }, _meta, params) => locations
        .sort((a: ILocation, b: ILocation) => (a.placeName > b.placeName ? 1 : -1))
        .filter((location: ILocation) => params?.isMasked ? location?.isMasked : !location?.isMasked),
    }),
    // GET Calculated Heart Rate Zone values
    calculateHeartRateZones : builder.query({
      query : (maxHeartRate: number | null) => ({
        url: `${USER_SETTINGS_URL}/heart-rate-zones/calculated?maxHeartRate=${maxHeartRate}`
      })
    }),
    // GET Calculated Power Zone values
    calculatePowerZones     : builder.query({
      query: ({ functionalThresholdPower, zonesCount }) => ({
        url: `${USER_SETTINGS_URL}/power-zones/calculated?functionalThresholdPower=${functionalThresholdPower}&zonesCount=${zonesCount}`,
      })
    }),

    // CREATE User Location
    createUserLocation: builder.mutation<ILocation, ILocationSubmit>({
      query  : ({
        lat,
        lng,
        cityName,
        stateName,
        placeName,
        isPrimary = false,
        address = '',
        isForPlannedRide = false,
        isMasked = false,
      }) => ({
        url    : USER_LOCATIONS_URL,
        method : 'POST',
        body   : {
          isPrimary,
          cityName,
          stateName,
          address,
          isMasked,
          isForPlannedRide,
          placeName: placeName ?? `${cityName}, ${stateName}`,
          latitude : lat,
          longitude: lng,
        }
      }),
      invalidatesTags: ['Locations', 'UserGenLocations']
    }),

    // CREATE User Phone
    updateUserPhone: builder.mutation<void, { phoneNumber: string; }>({
      query: (body) => ({
        url   : USER_PHONE_SETTINGS,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Settings'],
    }),

    // Verify User Phone
    verifyUserPhone: builder.mutation<void, { code: string; }>({
      query: (body) => ({
        url : VERIFY_USER_PHONE,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Settings'],
    }),

    // UPDATE User General Settings
    updateUserSettings: builder.mutation<void, Partial<ISettingsForm>>({
      query: (body) => ({
        url   : USER_SETTINGS_URL,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['Settings', 'UserInfo', 'PublicProfileShortInfo']
    }),
    // UPDATE User Location
    updateUserLocation: builder.mutation({
      query    : (body) => ({
        url    : `${USER_LOCATIONS_URL}/${body.id}`,
        method : 'PUT',
        body
      }),
      invalidatesTags: ['Locations', 'UserGenLocations']
    }),

    // DELETE User Location
    deleteUserLocation: builder.mutation<void, string>({
      query    : (id) => ({
        url           : `${USER_LOCATIONS_URL}/${id}`,
        successMessage: 'Location has been removed',
        method        : 'DELETE',
      }),
      invalidatesTags: ['Locations', 'UserGenLocations']
    }),

    // GET User Statistics
    getUserStatistics: builder.query<IUserStatistics, { from?: string, to?: string }>({
      query       : ({ from, to }) => ({ url: appendUrlParams(USER_STATISTICS_URL, { from, to }) }),
      providesTags: ['Statistics']
    }),

    // GET User Profile Info
    getUserProfileInfo: builder.query<IUserInfo, void>({
      query       : () => ({ url: USER_INFO_URL }),
      providesTags: ['UserInfo']
    }),

    // GET user access to Garmin
    getUserGarminAccess: builder.query<{ connected: boolean }, void>({
      query       : () => ({ url: CONNECT_GARMIN_URL }),
      providesTags: ['GarminAccess']
    }),

    // GET user Garmin connection
    getGarminConnection: builder.query<string, void>({
      query       : () => ({ url: GARMIN_CONNECTION_URL }),
      providesTags: ['GarminConnection']
    }),

    // Connect Garmin
    connectToGarmin: builder.mutation({
      query: (body: GarminConnectionRequest) => ({
        url   : CONNECT_GARMIN_URL,
        method: 'POST',
        body
      }),
      invalidatesTags: ['GarminAccess']
    }),

    // GET user access to Wahoo
    getUserWahooAccess: builder.query<{ connected: boolean }, void>({
      query       : () => ({ url: WAHOO_ACCESS_URL }),
      providesTags: ['WahooAccess']
    }),

    // GET Wahoo auth url
    getWahooAuthUrl: builder.query<string, void>({
      query       : () => ({ url: WAHOO_AUTH_URL }),
      providesTags: ['WahooAuthUrl']
    }),

    connectToWahoo: builder.mutation({
      query: (body: {code: string}) => ({
        url   : CONNECT_WAHOO_URL,
        method: 'POST',
        body
      }),
      invalidatesTags: ['WahooAccess']
    }),

    // GET Ride with GPS auth url
    getRideWithGpsAuthUrl: builder.query<string, void>({
      query       : () => ({ url: RIDE_GPS_AUTH_URL }),
      providesTags: ['RideWithGpsAuthUrl']
    }),

    // GET Check Ride with GPS connection
    getRideWithGpsStatus: builder.query<{ connected: boolean }, void>({
      query : () => ({ url: RIDE_GPS_STATUS }),
      providesTags: ['RideWithGpsStatus']
    }),

    // POST Connect Ride with GPS
    connectToRideWithGps: builder.mutation<void, { code: string }>({
      query: (body) => ({
        url   : RIDE_GPS_CONNECT,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['RideWithGpsStatus']
    }),

    // DELETE Disconnect Ride with GPS
    disconnectFromRideWithGps: builder.mutation<void, void>({
      query: () => ({
        url   : RIDE_GPS_DISCONNECT,
        method: 'DELETE',
      }),
      invalidatesTags: ['RideWithGpsStatus'],
    }),

    // POST Import routes from Rides with GPS
    importRoutesFromRideGps: builder.mutation<void, void>({
      query: () => ({
        url           : RIDE_GPS_IMPORT,
        method        : 'POST',
        successMessage: 'Routes have been imported from Ride with GPS',
      }),
      invalidatesTags: ['Routes'],
    }),

    // UPDATE User Avatar
    updateUserAvatar: builder.mutation({
      query: (body) => ({
        url   : UPDATE_PROFILE_PICTURE,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Settings']
    }),

     // DELETE User Avatar
     deleteUserAvatar: builder.mutation({
      query: (body) => ({
        url   : UPDATE_PROFILE_PHOTOS,
        method: 'DELETE',
        body
      }),
      invalidatesTags: ['Settings']
    }),

    // GET User Connections
    getUserConnections: builder.query<IConnectionResponse, TPagination>({
      query: ({
        pageNumber = 0,
        pageSize = 50,
        search,
        sortColumn    = 'name',
        sortDirection = 'asc'
      }) => ({
        url: appendUrlParams(USER_PROFILE_CONNECTIONS, {
          pageNumber,
          pageSize,
          sortColumn,
          sortDirection,
          search,
        })
      }),
      providesTags      : ['Connections'],
      keepUnusedDataFor : 0,
    }),

    // DELETE User Connection
    deleteUserConnection: builder.mutation({
      query : (userId: string) => ({
        url           : `${CONNECTIONS_URL}/${userId}`,
        successMessage: 'Connection has been removed',
        method        : 'DELETE',
      }),
      invalidatesTags: ['Connections']
    }),

    //CREATE invitation for non-member
    createNonMemberInvitation: builder.mutation({
      query: (body: INonMemberInvitation) => ({
        url           : SEND_INVITATION,
        method        : 'POST',
        successMessage: body.emails.length > 1 ? 'Invitations have been sent' : 'Invitation has been sent',
        body
      })
    }),

    // DELETE User Bulk Connections
    deleteUserConnections: builder.mutation({
      query : (ids: string[]) => ({
        url    : `${CONNECTIONS_URL}`,
        body   : {
          userIds: ids
        },
        method        : 'DELETE',
        successMessage: 'Connections have been removed'
      }),
      invalidatesTags: ['Connections']
    }),

    // GET User Ride History
    getUserRideHistory: builder.query<IRideHistoryResponse, IRideHistoryParams & TPagination>({
      query : ({
         pageNumber,
         pageSize,
         sortColumn    = '',
         sortDirection = '',
         ...params
       }) => ({
        // url: `${USER_RIDE_HISTORY_URL}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`
        url: appendUrlParams(USER_RIDE_HISTORY_URL, { pageNumber, pageSize, sortColumn, sortDirection, ...params }),
      }),
      providesTags: ['RideHistory']
    }),

    // GET Ride History Errors
    getUserRideHistoryErrors: builder.query<IErrorsResponse, void>({
      query : () => ({ url: USER_RIDE_HISTORY_ERRORS }),
      providesTags: ['RideHistoryErrors']
    }),


    // DELETE User Ride History
    deleteUserRideHistory: builder.mutation({
      query : (rideId: string) => ({
        url           : `${USER_RIDE_HISTORY_URL}/${rideId}`,
        successMessage: 'Ride has been removed',
        method        : 'DELETE',
      }),
      invalidatesTags: ['RideHistory']
    }),

    // DELETE User Bulk Ride Histories
    deleteUserRideHistories: builder.mutation({
      query : (ids: string[]) => ({
        url    : `${USER_RIDE_HISTORY_URL}`,
        body   : {
          ids
        },
        successMessage: 'Rides have been removed',
        method        : 'DELETE',
      }),
      invalidatesTags: ['RideHistory']
    }),

    // GET Users For Invite
    getUsersForInvite  : builder.query<IConnectionResponse, TPagination>({
      query : ({
         sortColumn    = '',
         sortDirection = '',
         ...params
       }) => ({ url: `${appendUrlParams(CONNECTION_INVITATIONS, {
        sortDirection,
        sortColumn,
        ...params
      })}&includeForInvite`}),
      providesTags     : ['UsersForInvite'],
      keepUnusedDataFor: 0,
    }),

    // POST Add User Connection
    addUserConnection: builder.mutation<void, { userId: string, isAccepting?: boolean }>({
      query: ({
        userId,
        isAccepting = false
      }) => ({
        url           : CONNECTIONS_URL,
        method        : 'POST',
        successMessage: isAccepting ? 'Connection has been accepted': 'Request has been sent',
        body          : { userId }
      }),
      invalidatesTags: ['Invitations', 'Connections', 'UsersForInvite', 'PublicProfileShortInfo', 'Recommendations']
    }),

    // GET User Routes
    getUserRoutes: builder.query<IRouteTableResponse, IRouteParams & TPagination>({
      query : ({
        favoriteOnly,
        sortColumn    = '',
        sortDirection = '',
        ...params
      }) => ({
        url: appendUrlParams(USER_ROUTES_URL, {
          sortDirection,
          sortColumn,
          favoriteOnly: favoriteOnly ? favoriteOnly : undefined,
          ...params
        })
      }),
      providesTags: ['Routes']
    }),

    //POST send bulk invitations
    addUsersConnections: builder.mutation<void, { userIds: string[] }>({
      query: (body) => ({
        url: SEND_CONNECTIONS_BULK,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Invitations', 'Connections', 'UsersForInvite', 'PublicProfileShortInfo', 'Recommendations']
    }),

    // GET Public Profile Statistics
    getPublicProfileStatistics: builder.query<IUserStatistics, {username: string, from?: string, to?: string }>({
      query       : ({ from, to, username }) => {
        const formattedUrl = PUBLIC_PROFILE_STATISTICS.replace(':username', username);

        return { url: appendUrlParams(formattedUrl, { from, to }) };
      },
      providesTags: ['PublicProfileStatistics']
    }),

    // GET Public Profile Short Info
    getPublicProfileShortInfo: builder.query<IUserInfo, { username: string }>({
      query       : ({ username }) => ({ url: PUBLIC_PROFILE_SHORT_INFO.replace(':username', username) }),
      providesTags: ['PublicProfileShortInfo']
    }),

    // POST User Connection
    setConnectionWithUser: builder.mutation({
      query : (body: {userId: string}) => ({
        url   : CONNECTIONS,
        method: 'POST',
        body
      }),
      invalidatesTags: ['PublicProfileShortInfo']
    }),

    // DELETE User Route
    deleteUserRoute: builder.mutation({
      query    : (routeId: string) => ({
        url           : `${USER_ROUTES_URL}/${routeId}`,
        method        : 'DELETE',
        successMessage: 'Route has been removed'
      }),
      invalidatesTags: ['Routes']
    }),

    // DELETE User Bulk Routes
    deleteUserRoutes: builder.mutation({
      query : (ids: string[]) => ({
        url    : `${USER_ROUTES_URL}`,
        body   : {
          ids
        },
        successMessage: 'Routes have been removed',
        method        : 'DELETE',
      }),
      invalidatesTags: ['Routes']
    }),

    // GET Self Info
    getSelfInformation: builder.query<IUserBaseInfo, void>({
      query       : () => ({ url: SELF_URL }),
      providesTags: ['Self']
    }),

    // GET User's Gen Locations with accepted Rides
    getUserConnectedGenLocations: builder.query<IGenLocation[], void>({
      query       : () => ({ url: USER_CONNECTED_GEN_LOCATIONS }),
      providesTags: ['UserConnectedGenLocations']
    }),

    // GET User's Gen Locations attached to Routes
    getUserGenLocationsAttachedToRoutes: builder.query<IGenLocation[], void>({
      query: () => ({
        url: USER_ROUTES_CONNECTED_GEN_LOCATIONS
      }),
      providesTags: ['UserRoutesConnectedGenLocations'],
    }),

    // GET User's Gen Locations
    getUserGenLocations: builder.query<IGenLocation[], void>({
      query       : () => ({ url: MY_GENLOCATIONS }),
      providesTags: ['UserGenLocations']
    }),

    // PATCH User's Route
    updateUserRoute: builder.mutation({
      query: ({ id, name, isFavorite }: { id: string, name?: string, isFavorite?: boolean }) => ({
        url: `${USER_ROUTES_URL}/${id}`,
        body: {
          name,
          isFavorite
        },
        successMessage: 'Route has been updated',
        method        : 'PATCH'
      }),
      invalidatesTags: ['Routes']
    }),

    // UPDATE User Profile Photos
    updateUserProfilePhotos: builder.mutation({
      query: (body) => ({
        url   : UPDATE_PROFILE_PHOTOS,
        method: 'POST',
        body
      }),
      invalidatesTags: ['ProfilePhotos']
    }),

    // DELETE User Profile Photos
    deleteUserProfilePhoto: builder.mutation({
    query: (body) => ({
      url   : UPDATE_PROFILE_PHOTOS,
      method: 'DELETE',
      body
    }),
    invalidatesTags: ['ProfilePhotos']
  }),

    // GET User Profile Photos by ID
    getUserProfilePhotos: builder.query<IUserProfilePhoto[], { userId: string }>({
      query        : ({ userId }) => ({ url: `${UPDATE_PROFILE_PHOTOS}/${userId}` }),
      providesTags : ['ProfilePhotos']
    }),

    // GET User Primary Location
    getUserPrimaryLocation: builder.query<ILocation, null>({
      query       : () => ({ url: `${USER_LOCATIONS_URL}/primary`}),
      providesTags: ['PrimaryLocation']
    }),

    // GET GenLocation by ID
    getGenLocationById: builder.query<IGenLocation, { locationId: string}>({
      query: ({ locationId }) => ({ url: `${GENLOCATIONS}/${locationId}` })
    }),

    // POST Reset Password
    sendForgotEmail: builder.mutation({
      query: ({ email }: { email: string }) => ({
        url   : RESET_PASSWORD,
        method: 'POST',
        body  : { email }
      })
    }),

    // PUT Update Password
    updatePassword: builder.mutation({
      query: ({ oobCode, newPassword }: { oobCode?: string, newPassword: string }) => ({
        url    : UPDATE_FORGOT_PASSWORD,
        method : 'PUT',
        body   : {
          oobCode,
          newPassword
        }
      })
    }),

    // POST Verify Reset Code
    verifyResetCode: builder.mutation({
      query: ({ oobCode }: { oobCode: string }) => ({
        url    : VERIFY_RESET_CODE,
        method : 'POST',
        body   : { oobCode }
      })
    }),

    // DELETE Current User
    deleteCurrentUser: builder.mutation<Promise<unknown>, void>({
      query: () => ({
        url   : USERS_URL,
        method: 'DELETE',
      })
    }),

    // DELETE Garmin Connection
    deleteGarminConnection: builder.mutation<Promise<unknown>, void>({
      query: () => ({
        url   : CONNECT_GARMIN_URL,
        method: 'DELETE'
      })
    }),

    // DELETE Garmin Connection
    deleteWahooConnection: builder.mutation<Promise<unknown>, void>({
      query: () => ({
        url   : WAHOO_ACCESS_URL,
        method: 'DELETE'
      })
    }),

    // GET User Invitations
    getUserInvitations: builder.query<IConnectionResponse, TPagination>({
      query : ({
         sortColumn    = 'name',
         sortDirection = 'asc',
         ...params
       }) => ({ url: appendUrlParams(CONNECTION_INVITATIONS, {
        sortDirection,
        sortColumn,
        ...params
      })}),
      providesTags      : ['Invitations'],
      keepUnusedDataFor : 0,
    }),

    // POST Decline User Invitation
    declineUserInvitation: builder.mutation<void, { connectionId: string }>({
      query: ({ connectionId }) => ({
        url   : DECLINE_INVITATION.replace(':connectionId', connectionId),
        method: 'PATCH',
        body   : {
          status: INVITATION_STATUS.DECLINED
        }
      }),
      invalidatesTags: ['Invitations', 'UsersForInvite']
    }),

    // GET Notifications
    getUserNotifications: builder.query<IResponseWithPagination<INotification[]> & { unreadCount: number }, TPagination & { includeRead?: boolean, includeUnread?: boolean, actionTypes?: ENotificationAction[] }>({
      query             : ({
        pageSize,
        pageNumber,
        includeRead,
        includeUnread,
        actionTypes
      }) => ({
        url: appendUrlParams(NOTIFICATIONS, {
          pageSize,
          pageNumber,
          includeRead,
          includeUnread,
          actionTypes
        })
      }),
      transformResponse : ({ content, ...rest }) => ({
        ...rest,
        content: content.map((notification: INotificationResponse) => ({ ...notification, action: JSON.parse(notification.action) })),
      }),
      providesTags     : ['Notifications'],
      keepUnusedDataFor: 0
    }),

    // POST read notification
    readNotificationById: builder.mutation({
      query: (id: string) => ({
        url    : `${NOTIFICATIONS}/read/${id}`,
        method : 'POST'
      }),
      invalidatesTags: ['Notifications']
    }),

    // POST read all notifications
    readAllNotifications: builder.mutation({
      query: () => ({
        url    : `${NOTIFICATIONS}/read`,
        method : 'POST'
      }),
      invalidatesTags: ['Notifications']
    }),

    // GET User Recommendations
    getRecommendations: builder.query<IRecommendation[], TPagination>({
      query: ({
        pageNumber,
        pageSize
      }) => ({
        url: appendUrlParams(CONNECTIONS_RECOMMENDATIONS, {
          pageNumber,
          pageSize,
        })
      }),
      providesTags      : ['Recommendations'],
      keepUnusedDataFor : 0,
    }),

    // GET GetStream token
    getGetStreamToken: builder.query<{ token: string }, void>({
      query: () => ({ url: 'get-stream/token' }),
      providesTags: ['GetStreamToken'],
    }),

    // POST Upload Ride to Ride History
    uploadRideHistory: builder.mutation<number, FormData>({
      query: (data: FormData) => ({
        url    : RIDE_UPLOAD,
        body   : data,
        method : 'POST',
        // headers: { 'Content-Type': 'multipart/form-data' },
      }),
      invalidatesTags: ['RideHistory', 'ProcessingJobs'],
    }),

    // GET All Jobs
    getUserJobs: builder.query<IProcessingJob[], void>({
      query: () => ({ url: JOBS_INFO_URL }),
      providesTags: ['ProcessingJobs']
    }),

    // GET Job Status
    getJobStatus: builder.query<IProcessingJob, number>({
      query: (id: number) => ({
        url: `${JOBS_INFO_URL}/${id}`
      }),
    }),

    // GET User Info by ID
    getUserInfoById: builder.query<IUserInfo, { userId: string }>({
      query: ({ userId }) => ({
        url: PUBLIC_PROFILE_SHORT_INFO_BY_ID.replace(':userId', userId),
      })
    })
  }),
});

export const {
  useGetUserSettingsQuery,
  useGetUserLocationsQuery,
  useCalculateHeartRateZonesQuery,
  useCalculatePowerZonesQuery,
  useGetUserStatisticsQuery,
  useGetUserProfileInfoQuery,
  useGetUserGarminAccessQuery,
  useGetGarminConnectionQuery,
  useGetUserWahooAccessQuery,
  useGetWahooAuthUrlQuery,
  useGetRideWithGpsAuthUrlQuery,
  useGetRideWithGpsStatusQuery,
  useConnectToRideWithGpsMutation,
  useDisconnectFromRideWithGpsMutation,
  useImportRoutesFromRideGpsMutation,
  useGetUserConnectionsQuery,
  useGetUserRideHistoryQuery,
  useGetUsersForInviteQuery,
  useGetUserRideHistoryErrorsQuery,
  useGetUserRoutesQuery,
  useGetPublicProfileStatisticsQuery,
  useGetPublicProfileShortInfoQuery,
  useGetSelfInformationQuery,
  useGetUserConnectedGenLocationsQuery,
  useGetUserGenLocationsQuery,
  useGetUserGenLocationsAttachedToRoutesQuery,
  useGetUserProfilePhotosQuery,
  useGetUserPrimaryLocationQuery,
  useGetGenLocationByIdQuery,
  useGetUserInvitationsQuery,
  useGetUserNotificationsQuery,
  useGetRecommendationsQuery,
  useGetGetStreamTokenQuery,
  useGetUserInfoByIdQuery,

  useCreateUserLocationMutation,
  useCreateNonMemberInvitationMutation,
  useAddUserConnectionMutation,
  useAddUsersConnectionsMutation,
  useSetConnectionWithUserMutation,
  useDeclineUserInvitationMutation,
  useReadNotificationByIdMutation,
  useReadAllNotificationsMutation,

  useUpdateUserSettingsMutation,
  useUpdateUserLocationMutation,
  useUpdateUserPhoneMutation,
  useVerifyUserPhoneMutation,
  useUpdateUserAvatarMutation,
  useUpdateUserProfilePhotosMutation,

  useDeleteUserLocationMutation,
  useDeleteUserConnectionMutation,
  useUploadRideHistoryMutation,
  useGetJobStatusQuery,
  useGetUserJobsQuery,
  useDeleteUserRideHistoryMutation,
  useDeleteUserRideHistoriesMutation,
  useDeleteUserRouteMutation,
  useDeleteUserRoutesMutation,
  useDeleteUserConnectionsMutation,
  useDeleteUserAvatarMutation,
  useDeleteUserProfilePhotoMutation,

  useUpdateUserRouteMutation,
  useSendForgotEmailMutation,
  useUpdatePasswordMutation,
  useVerifyResetCodeMutation,
  useDeleteCurrentUserMutation,

  useConnectToGarminMutation,
  useConnectToWahooMutation,
  useDeleteGarminConnectionMutation,
  useDeleteWahooConnectionMutation,
} = profileApi;
