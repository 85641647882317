import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
}                                      from 'react';
import { useSelector }                 from 'react-redux';
import { useHistory }                  from 'react-router-dom';
import { UseFormReturn, useForm }      from 'react-hook-form';
import styled, { css, keyframes }      from 'styled-components';
import {
  Button,
  Dialog,
  useMediaQuery,
  useTheme,
}                                      from '@mui/material';
import { yupResolver }                 from '@hookform/resolvers/yup';
import {
  IRouteModel,
  IRouteTableRow
}                                      from '@models/RouteModel';
import { smaller }                     from '@store/reducers/ui';
import { useQueryParams }              from '@utils/hooks/useQueryParams';
import { ISaveLocationData }           from '@components/LocationModal';
import { Notification }                from '@components/Notification';
import { ILocation }                   from '@modules/Profile/models/Settings';
import { useCta }                      from '@utils/hooks/useCta';
import {
  useGetRideDetailsQuery,
  useGetRouteDetailsQuery
}                                      from '@modules/Details/queries';
import { IUserLocation }               from '@modules/Profile/models/UserInfo';
import LogoBg                          from '../../images/Pattern-L-A16-16-9.svg';
import { ReactComponent as AddIcon }   from '../../images/Icon-Add-White.svg';
import { ReactComponent as BackIcon }  from '../../images/Icon-Chevron-L-White.svg';
import {
  ECtaTab,
}                                      from './models/CreateModalContent';
import {
  FRideForm,
  IRideForm,
  rideValidationSchema
}                                      from './models/ride';
import { IRide }                       from '../Details/models/ride';
import {
  useCreateUserLocationMutation,
  useGetUserGenLocationsQuery,
  useGetUserRoutesQuery
}                                      from '../Profile/queries';
import { CTA_CONTENT }                 from '@modules/CreateModal/container';

interface ICreateModalProps {
  tab: ECtaTab;
}

export type TRoute = Partial<IRouteTableRow> & Partial<IRouteModel>;

export type ModalContextProps = {
  rideForm              : UseFormReturn<IRideForm>;
  setSelectedRoute      : (route: TRoute | undefined) => void;
  selectedConnections   : string[];
  setSelectedConnections: (ids: string[]) => void;
  handleSaveLocation    : (data: ISaveLocationData) => void;
  handleClearLocation   : () => void;
  previousContent?      : ECtaTab;
  defaultValues         : IRideForm;
  meetUpLocation?       : IUserLocation;
  selectedRoute?        : TRoute;
  options?              : any;
  rideId?               : string;
  rideDetails?          : IRide;
  routes?               : TRoute[];
  refetchRoutes?        : () => void;
};

export const CTAContext = createContext<ModalContextProps>({} as ModalContextProps);

export const CTA = ({ tab }: ICreateModalProps) => {
  const history                                       = useHistory();
  const [modalContext, setModalContext]               = useState<ModalContextProps>({} as ModalContextProps);
  const { closeCta }                                  = useCta();
  const { date, rideId }                              = useQueryParams<{ date?: string; rideId?: string; }>();
  const [btnTransform, setBtnTransform]               = useState<boolean>(true);
  const isSmaller                                     = useSelector(smaller);
  const theme                                         = useTheme();
  const isBigScreen                                   = useMediaQuery(theme.breakpoints.up('sm'));
  const [selectedRoute, setSelectedRoute]             = useState<TRoute | undefined>();
  const [selectedConnections, setSelectedConnections] = useState<string[]>(modalContext?.selectedConnections ?? []);
  const [meetUpLocation, setMeetUpLocation]           = useState<ILocation | undefined>();
  const routeBuffer                                   = useRef<TRoute | null>(null);
  const { refetch: refetchUserGenLocations }          = useGetUserGenLocationsQuery();
  const [createLocation]                              = useCreateUserLocationMutation();
  const {
    data : rideDetails,
    error: rideDetailsError,
  } = useGetRideDetailsQuery({ id: rideId ?? '' }, { skip: !rideId });

  const { data: routeDetails } = useGetRouteDetailsQuery({ id: rideDetails?.routeId as string }, { skip: !rideDetails?.routeId });

  const content = useMemo(
    () => CTA_CONTENT[tab],
    [tab]
  );

  const {
    data,
    refetch: refetchRoutes,
  } = useGetUserRoutesQuery({
    favoriteOnly : true,
    pageNumber   : 0,
    pageSize     : 50,
    sortDirection: 'desc',
    sortColumn   : 'date',
  });

  const handleCloseBtnClick = useCallback(
    () => {
      setBtnTransform(false);
      setModalContext({} as ModalContextProps);
      closeCta();
    },
    [closeCta, setModalContext]
  );

  const defaultValues = useMemo<IRideForm>(
    () =>  FRideForm({ date, ...rideDetails }),
    [date, rideDetails]
  );

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    reset,
    formState,
    trigger,
    ...rest
  } = useForm<IRideForm>({
    mode     : 'onChange',
    resolver : yupResolver(rideValidationSchema),
    defaultValues
  });

  const handleSelectRoute = useCallback(
    (route: TRoute | undefined) => {
      if (selectedRoute && !selectedRoute.isFavorite) {
        routeBuffer.current = selectedRoute;
      }

      setSelectedRoute(route);

      setValue('duration', undefined);
      setValue('routeId', route?.id);
    },
    [
      selectedRoute,
      setValue
    ],
  );

  const routes = useMemo<TRoute[]>(
    () => {
      const initialRoutes: TRoute[] = [...(data?.content ?? [])];

      if (routeBuffer.current) {
        const isRouteInList = !!initialRoutes.find(({ id }) => id === routeBuffer.current?.id);

        if (!isRouteInList && routeBuffer.current?.id !== selectedRoute?.id) {
          initialRoutes.unshift(routeBuffer.current);
        }
      }

      const isSelectedInList = !!initialRoutes.find(({ id }) => id === selectedRoute?.id);

      if (selectedRoute && !isSelectedInList) {
        initialRoutes.unshift(selectedRoute);
      }

      return initialRoutes;
    },
    [data?.content, selectedRoute],
  );

  useEffect(refetchUserGenLocations, [refetchUserGenLocations]);

  useEffect(
    () => {
      if (rideDetails) {
        if (routeDetails) {
          setSelectedRoute(routeDetails);
        }

        if (rideDetails.inviteeIds?.length) {
          setSelectedConnections(rideDetails.inviteeIds);
        }

        const values = FRideForm(rideDetails);

        reset(values);
      }
    },
    [reset, rideDetails, routeDetails],
  );

  useEffect(
    () => {
      if (rideDetailsError) {
        const errorMessage = (rideDetailsError as any)?.data?.message

        Notification.enqueueSnackbar(errorMessage, 'error');

        closeCta();
      }
    },
    [closeCta, rideDetailsError],
  );

  const handleSaveMeetUpLocation = useCallback(
    (data: ISaveLocationData) => {
      createLocation({ ...data, isForPlannedRide: true })
        .unwrap()
        .then((data) => {
          setMeetUpLocation(data);

          setValue('startLocationId', data?.id ?? undefined, { shouldDirty: true });
        });
    },
    [createLocation, setValue]
  );

  const handleClearMeetUpLocation = useCallback(
    () => {
      setValue('startLocationId', undefined);
      setMeetUpLocation(undefined);
    },
    [setValue],
  );

  return (
    <CTAContext.Provider
      value = {{
        selectedConnections,
        setSelectedConnections,
        meetUpLocation,
        defaultValues,
        routes,
        refetchRoutes,
        selectedRoute,
        setSelectedRoute   : handleSelectRoute,
        handleSaveLocation : handleSaveMeetUpLocation,
        handleClearLocation: handleClearMeetUpLocation,
        rideDetails        : rideDetails,
        previousContent    : ECtaTab.CTAS,
        rideForm           : {
          register,
          handleSubmit,
          setValue,
          getValues,
          watch,
          control,
          reset,
          formState,
          trigger,
          ...rest
        }
      }}
    >
      <CTA.Dialog
        fullScreen
        transitionDuration = {{ exit: 0 }}
        open
        $headerIsSmall     = {isSmaller}
      >
        <CTA.CloseButton
          endIcon         = {<AddIcon />}
          variant         = "contained"
          $closeAnimation = {!btnTransform}
          onClick         = {handleCloseBtnClick}
        />

        {content?.title && (
          <>
            {content?.backIcon && (
              <CTA.BackBtn
                startIcon = {<BackIcon />}
                onClick   = {() => history.goBack()}
              >
                {isBigScreen && 'Back'}
              </CTA.BackBtn>
            )}

            <CTA.Header>
              {content?.title}
            </CTA.Header>
          </>
        )}

        {content.component}
      </CTA.Dialog>
    </CTAContext.Provider>
  );
};

CTA.Dialog = styled(Dialog)<{ $headerIsSmall: boolean }>`
  .MuiPaper-root {
    background-image: ${({ theme: {colors: {primary: { rubineLight, purple }}}}) => `
      url(${LogoBg}), radial-gradient(circle farthest-corner at 0% 0%, ${ rubineLight }, ${ purple })
    `};
    background-position: 0% 50%, 0px 0px;
    background-size    : cover,  auto;
    padding            : 10px 20px 0;

    ${({ theme: {mediaQueries: { sm }}}) => sm } {
      padding: 4px 37px 20px;
    }

    ${({ theme: {mediaQueries: { md }}}) => md } {
      padding: 12px calc(4% + 17px) 20px;

      ${({ $headerIsSmall }) => $headerIsSmall && `
        padding-top: 4px;
      `}
    }

    ${({ theme: { mediaQueries: { lg } }}) => lg } {
      padding: 12px calc(8%) 20px;

      ${({ $headerIsSmall }) => $headerIsSmall && `
        padding-top: 4px;
      `}
    }
  }

  .MuiPaper-root > .MuiButton-root {
    align-self  : end;
    padding     : 8px;
    min-width   : unset;
    margin-top  : 22px;
    margin-left : -5px;
  }

  .MuiPaper-root > .MuiButton-root > .MuiButton-endIcon {
    margin: 0;
  }
`;

export const createButtonAnimation = keyframes`
  0% { transform: rotate(0) }
  100% { transform: rotate(45deg) }
`;

export const closeButtonAnimation = keyframes`
  0% { transform: rotate(45deg) }
  100% { transform: rotate(0) }
`;

CTA.CloseButton = styled(Button)<{ $closeAnimation: boolean }>`
  &.MuiButton-root:hover {
    transform: rotate(45deg);
  }
  transform: rotate(45deg);
  animation: ${ createButtonAnimation } 0.3s;

  ${({ $closeAnimation }) => $closeAnimation && css`
    animation: ${ closeButtonAnimation } 0.3s;
  `};
`;

CTA.Header = styled.h1`
  margin-bottom: 10px;
  color        : ${({ theme }) => theme.colors.primary.chartreuse};
  line-height  : 104%;
  font-weight  : 900;
  align-self   : center;
  font-size    : 28px;

  ${({ theme: {mediaQueries: { xl }}}) => xl } {
    font-size: 40px;
    margin   : 80px 0 30px;
  }
`;

CTA.BackBtn = styled(Button)`
  &.MuiButton-root {
    position: absolute;
    left    : 8%;
    top     : 59px;
    opacity : 64%;
    color   : white;
    filter  : unset;

    ${({ theme: {mediaQueries: { xl }}}) => xl } {
      top: 137px;
    }
  }

  &:hover {
    opacity: 1;
  }
`;
