import { ReactElement }                     from 'react';
import { ITab }                             from '@models/tab';
import { IUserInfo }                        from '@models/UserInfo';
import { ReactComponent as AttendanceIcon } from '../../../images/Icon-Metric-Attendance-Black.svg';
import { ReactComponent as GoingIcon }      from '../../../images/Icon-RSVP-Going.svg';
import { ReactComponent as NotGoingIcon }   from '../../../images/Icon-RSVP-Not-Going.svg';
import { ReactComponent as NoResponseIcon } from '../../../images/Icon-RSVP-No-Response.svg';
import { ReactComponent as MaybeIcon }      from '../../../images/Icon-RSVP-Maybe.svg';

export const RIDE_INVITATION_DATA_KEY      = 'ride-invitation';
export const RIDES_INVITATION_LINK_PATTERN = '/rides';

export interface IInvitationLinkData {
  plannedRideId?: string | null;
  eventDate?    : string | null;
  inviteCode?   : string | null;
  linkUuid?     : string | null;
}

export enum INVITATION_STATUS {
  PENDING  = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  MAYBE    = 'MAYBE'
}

export const INVITATION_ICONS: {[key in INVITATION_STATUS]: ReactElement} = {
  [INVITATION_STATUS.ACCEPTED]: <GoingIcon />,
  [INVITATION_STATUS.MAYBE]   : <MaybeIcon />,
  [INVITATION_STATUS.DECLINED]: <NotGoingIcon />,
  [INVITATION_STATUS.PENDING] : <NoResponseIcon />
}

export interface IInvitation extends IUserInfo {
  status: INVITATION_STATUS;
}

export const ATTENDANCE_TABS: ITab<INVITATION_STATUS | null>[] = [{
  value: null,
  icon : <AttendanceIcon />
}, {
  value: INVITATION_STATUS.MAYBE,
  icon : INVITATION_ICONS.MAYBE
}, {
  value: INVITATION_STATUS.ACCEPTED,
  icon : INVITATION_ICONS.ACCEPTED
}, {
  value: INVITATION_STATUS.DECLINED,
  icon : INVITATION_ICONS.DECLINED
}, {
  value: INVITATION_STATUS.PENDING,
  icon : INVITATION_ICONS.PENDING
}];
