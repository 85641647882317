import {
  useState,
  useCallback,
  MouseEvent
}                       from 'react';
import ReactPlayer      from 'react-player';
import styled           from 'styled-components';
import { Gallery }      from 'react-activity-feed';
import { EnrichedUser } from 'getstream';
import {
  IPost,
  TActivityUserType,
}                       from '@models/Feed/post';
import { urlify }       from '@utils/commonFunctions';
import { Header }       from '../Header';
import { Content }      from '../Content';
import { LinkPreview }  from '../../../LinkPreview';
import 'react-activity-feed/dist/index.css';

interface IRepostProps {
  activity: IPost;
}

const MAX_WORDS_COUNT = 100;

export const Repost = ({
  activity
}: IRepostProps) => {
  const {
    text,
    attachments,
  } = activity;

  const needShortenText = (text ?? '').length > MAX_WORDS_COUNT;
  const shortenedText   = needShortenText ? `${(text ?? '').slice(0, MAX_WORDS_COUNT)}…` : activity.text;

  const [showReadMore, setShowReadMore] = useState<boolean>(true);

  const handleReadMore = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      setShowReadMore((prevValue) => !prevValue);
    },
    [],
  );

  const userData = (activity.actor as EnrichedUser).data as unknown as TActivityUserType | void;

  return (
    <Repost.Wrapper>
      <Header
        id                = {activity.id}
        profilePictureUrl = {userData?.profilePictureUrl}
        username          = {userData?.username ?? 'NoUsername'}
        displayName       = {userData?.displayName ?? 'Post Author'}
        time              = {activity.time}
        location          = {activity.location as MapboxGeocoder.Result | null}
        isOwnPost         = {false}
        onEditPost        = {() => {}}
      />

      <Content.Wrapper>
        <Content.TextContainer>
          {attachments?.og?.videos && <Content.VideoSourceText>{attachments?.og.site_name}</Content.VideoSourceText>}

          {text && <Content.Text dangerouslySetInnerHTML={{ __html: urlify(showReadMore ? shortenedText : (text ?? '')) }} />}

          {needShortenText && (
            <Content.ReadMore href="#" onClick={handleReadMore}>
              {showReadMore ? 'Read More' : 'Read Less'}
            </Content.ReadMore>
          )}
        </Content.TextContainer>

        {activity.attachments?.og?.videos && (
          <ReactPlayer
            light
            playIcon = {<Content.PlayIconWrapper><Content.PlayIcon /></Content.PlayIconWrapper>}
            url      = {activity.attachments?.og?.url}
            width    = "100%"
            height   = "450px"
            controls
          />
        )}

        {attachments?.og && !attachments.og.videos && <LinkPreview data={attachments.og} />}

        {/* {!!mentions?.length && (
          <Content.Tags>
            {mentions?.map(({ id, username }) => (
              <Content.UserTag
                key     = {id}
                label   = {`@${username}`}
                onClick = {() => history.push(`/user/${username}`)}
              />
            ))}
          </Content.Tags>
        )}

        )} */}

        {!!attachments?.images?.length && (
          <div className="raf-activity__content">
            <Gallery images={attachments.images} />
          </div>
        )}
      </Content.Wrapper>
    </Repost.Wrapper>
  );
};

Repost.Wrapper = styled.div`
  margin                 : 0px 20px;
  border-top-left-radius : 8px;
  border-top-right-radius: 8px;
  box-shadow             : 0px 0px 10px 0px #a7a7a7;

  div:first-child {
    padding-top: 0px;
  }

  div:nth-child(2) {
    padding-bottom: 0px;
  }

  p {
    margin-top: 20px;
  }
`;
