import { Grid, Button } from '@mui/material';
import { useHistory }   from 'react-router';
import styled           from 'styled-components';
import { scrimBlack }   from '../../../../../../styles/theme';

export const EmptyTable = () => {
  const history = useHistory();

  return (
    <EmptyTable.Wrapper item xs={12} container alignItems="center">
      <Grid item xs={12} sm={6} p="7%">
        <Button onClick={() => history.push('apps')}>
          Connect a Cycling App
        </Button>

        <div>
          Sync activities from your phone, wearable, or bike computer to keep Preem up-to-date on your latest rides and favorite routes.
        </div>
      </Grid>

      <Grid item xs={12} sm={6} p="7%">
        <Button onClick={() => history.push('apps')}>
          Import Ride Data
        </Button>
        
        <div>
          Import your .fit file history to allow Preem to identify all of your favorite routes, making it easier than ever to plan rides with your friends.
        </div>
      </Grid>
    </EmptyTable.Wrapper>
  )
}

EmptyTable.Wrapper = styled(Grid)`
  &.MuiGrid-root{
    height          : 450px;
    background-color: ${scrimBlack(0.04)};
    font-weight     : 500;
    margin-bottom   : 64px;
  }
`;