import { useContext }        from 'react';
import styled                from 'styled-components';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import ArrowForwardIosIcon   from '@mui/icons-material/ArrowForwardIos';


interface FloatingArrowsProps {
  height: number,
}

export const FloatingArrows = ({ 
  height,
}: FloatingArrowsProps) => {
  const { 
    isFirstItemVisible,
    isLastItemVisible,
    scrollPrev,
    scrollNext
  } = useContext(VisibilityContext);

  return (
    <FloatingArrows.ArrowContainer height={height}>
      <FloatingArrows.OuterCircleLeft visible={!isFirstItemVisible} onClick={() => scrollPrev()}>
        <FloatingArrows.LeftArrow />
      </FloatingArrows.OuterCircleLeft>
      <FloatingArrows.OuterCircleRight visible={!isLastItemVisible} onClick={() => scrollNext()}>
        <FloatingArrows.RightArrow />
      </FloatingArrows.OuterCircleRight>
    </FloatingArrows.ArrowContainer>
  )
};

FloatingArrows.OuterCircleLeft = styled.div<{ visible: boolean }>`
  background-color: #000000;
  border-radius   : 50%;
  height          : 5em;
  width           : 5em;
  display         : flex;
  justify-content : center;
  align-items     : center;
  font-size       : 10px;
  cursor          : pointer;
  position        : sticky;
  visibility      : ${({ visible }) => visible ? 'visible' : 'hidden'};
  pointer-events  : auto;
`;

FloatingArrows.OuterCircleRight = styled.div<{ visible: boolean }>`
  background-color: #000000;
  border-radius   : 50%;
  height          : 5em;
  width           : 5em;
  display         : flex;
  justify-content : center;
  align-items     : center;
  font-size       : 10px;
  cursor          : pointer;
  position        : sticky;
  left            : 100%;
  visibility      : ${({ visible }) => visible ? 'visible' : 'hidden'};
  pointer-events  : auto;
`;
  
FloatingArrows.RightArrow = styled(ArrowForwardIosIcon)`
  color           : #ffffff;
`;

FloatingArrows.LeftArrow = styled(ArrowForwardIosIcon)`
  color           : #ffffff;
  transform: rotate(180deg);
`;
  
FloatingArrows.ArrowContainer = styled.div<{ height: number }>`
  position      : fixed;
  height        : ${({ height }) => height + 'px'};
  width         : 100%;
  top           : 21.5rem;
  left          : 0px;
  display       : flex;
  align-items   : flex-start;
  padding-top   : 15rem;
  z-index       : 500;
  pointer-events: none;
`;