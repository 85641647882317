import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
}                                      from 'react';
import { useParams }                   from 'react-router';
import mapboxgl, { LineLayer, Map }    from 'mapbox-gl';
import { isAfter }                     from 'date-fns';
import styled, { css }                 from 'styled-components';
import { Link }                        from '@mui/material';
import * as turf                       from '@turf/turf';
import { getDateUTCFromISO }           from '@utils/time_utils';
import { useGetRouteTrackpointsQuery } from '@modules/Details/queries';
import { DEFAULT_LOCATION }            from '@components/LocationModal';
import { Loader }                      from '@components/Loader';
import { ECtaTab }                     from '@modules/CreateModal/models/CreateModalContent';
import { useCta }                      from '@utils/hooks/useCta';
import { useGetSelfInformationQuery }  from '@modules/Profile/queries';
import { RouteChart }                  from '../RouteChart';
import { useDetails }                  from '../../context';
import StartMarker                     from '../../../../images/mapMarkers/StartMarker.svg';
import FinishMarker                    from '../../../../images/mapMarkers/FinishMarker.svg';
import StartFinishMarker               from '../../../../images/mapMarkers/StartFinishMarker.svg';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';

interface IRouteMapDetails {
  minElevation          : number;
  maxElevation          : number;
  distance              : number;
  elevationsByPoints    : number[];
  distancesBetweenPoints: number[];
  linePoints            : number[];
  colorPoints           : string[];
  lineColorPoints       : (string | number)[];
}

// @ts-ignore
// const ELEVATION_COLORS = ['#489F64', '#F5BD41', '#EE7A33', '#C64126'];
const TEMP_ELEVATION_COLOR = "#36454f";

interface IRideRouteDetailsProps {
  routeId?                 : string;
  isReccuringRideOverview? : boolean;
}

const getColor = (
  elevation    : number,
  prevElevation: number,
  distance     : number
) => {
  if (elevation <= prevElevation) {
    // return ELEVATION_COLORS[0];
    return TEMP_ELEVATION_COLOR;
  }

  const rise = elevation - prevElevation;
  const grade = rise / distance;

  if (!grade) {
    // return ELEVATION_COLORS[0];
    return TEMP_ELEVATION_COLOR;
  }

  if (grade < 1) {
    // return ELEVATION_COLORS[1];
    return TEMP_ELEVATION_COLOR;
  } else if (grade === 1) {
    // return ELEVATION_COLORS[2];
    return TEMP_ELEVATION_COLOR;
  } else if (grade > 1) {
    // return ELEVATION_COLORS[3];
    return TEMP_ELEVATION_COLOR;
  }

  // return ELEVATION_COLORS[0];
  return TEMP_ELEVATION_COLOR;
};

export const RideRouteDetails = ({
  routeId,
  isReccuringRideOverview = false,
}: IRideRouteDetailsProps) => {
  const { data: user }                        = useGetSelfInformationQuery();
  const { rideDetails }                       = useDetails();
  const { openCta }                           = useCta();
  const { linkUuid }                          = useParams<{ rideId: string; rideDate: string; linkUuid?: string; }>();
  const [isSetRef, setRefStatus]              = useState(false);
  const [routeMapDetails, setRouteMapDetails] = useState<IRouteMapDetails | null>(null);
  const mapContainerRef                       = useRef<HTMLDivElement | null>(null);
  const map                                   = useRef<Map | null>(null);
  mapboxgl.accessToken                        = process.env.REACT_APP_PREEM_MAPBOX_ACCESS_TOKEN || '';

  const isRouteDetails          = !!routeId;
  const isUserRideOwner         = rideDetails?.organizer && user?.id === rideDetails.organizer.id;

  const {
    data    : routeTrackpoints,
    isError : isTrackpointsError,
  } = useGetRouteTrackpointsQuery({
      id        : linkUuid ? linkUuid : (routeId ?? rideDetails?.routeId ?? ''),
      isPrivate : isRouteDetails || isUserRideOwner,
      isShared  : Boolean(linkUuid)
    },
    { skip: !routeId && !rideDetails?.routeId && !!user }
  );

  const hasRoute                = !!rideDetails?.routeId;
  const showMapAndStartLocation = hasRoute && !!rideDetails?.startLocation?.placeName;
  const showStartLocation       = !hasRoute && !!rideDetails?.startLocation?.longitude && !!rideDetails?.startLocation?.latitude;
  const showNoMapLabel          = (!hasRoute && !showStartLocation && !isRouteDetails) || isTrackpointsError;
  const showChart               = hasRoute && !isTrackpointsError;
  const isRideStarted           = !isAfter(getDateUTCFromISO(rideDetails?.date ?? ''), new Date());
  const isEditLinkDisabled      = isRideStarted || !isUserRideOwner;

  const coordinates = useMemo(
    () => routeTrackpoints?.coordinates ?? [],
    [routeTrackpoints]
  );

  RideRouteDetails.moveMapToPosition = (
    lng = DEFAULT_LOCATION.longitude,
    lat = DEFAULT_LOCATION.latitude
  ) => {
    map.current?.setCenter({ lng, lat });
  };

  const setRef = useCallback((ref: HTMLDivElement | null) => {
    setRefStatus(true);

    mapContainerRef.current = ref;
  }, []);

  const createMarker = useCallback((icon: string, lng = 0, lat = 0) => {
    const markerEl = document.createElement("img");
    markerEl.src = icon;

    map.current &&
      new mapboxgl.Marker(markerEl).setLngLat({ lat, lng }).addTo(map.current);
  }, []);

  const drawRoute = useCallback(
    () => {
      if (coordinates.length && map.current?.isStyleLoaded() && !map.current.getLayer("routeBg")) {
        const pointsStep = 1 / (coordinates.length - 1);

        const geojson: GeoJSON.Feature<GeoJSON.Geometry> = {
          type       : "Feature",
          properties : {},
          geometry   : (routeTrackpoints || {}) as GeoJSON.Geometry,
        };

        const routeMapDetails: IRouteMapDetails = coordinates.reduce(
          (acc: IRouteMapDetails, cur: number[], index: number) => {
            const currentElevation = cur[2];
            const pointRange = index * pointsStep;

            const from = turf.point(cur);
            const options: { units: turf.Units } = { units: "meters" };
            const to = turf.point(coordinates?.[++index] ?? cur);
            const distance = turf.distance(from, to, options);

            if (acc.minElevation && acc.maxElevation) {
              acc.minElevation =
                currentElevation < acc.minElevation
                  ? currentElevation
                  : acc.minElevation;
              acc.maxElevation =
                currentElevation > acc.maxElevation
                  ? currentElevation
                  : acc.maxElevation;
            } else {
              acc.minElevation = currentElevation;
              acc.maxElevation = currentElevation;
            }

            if (acc.distance) {
              acc.distance = acc.distance + distance;
            } else {
              acc.distance = distance;
            }

            if (acc.elevationsByPoints) {
              acc.elevationsByPoints = [
                ...acc.elevationsByPoints,
                currentElevation,
              ];
            } else {
              acc.elevationsByPoints = [currentElevation];
            }

            if (acc.distancesBetweenPoints) {
              acc.distancesBetweenPoints = [
                ...acc.distancesBetweenPoints,
                distance,
              ];
            } else {
              acc.distancesBetweenPoints = [distance];
            }

            if (acc.linePoints && acc.colorPoints && acc.lineColorPoints) {
              const previousElvation = acc.elevationsByPoints[--index - 1];
              const color = getColor(
                currentElevation,
                previousElvation,
                distance
              );

              acc.linePoints = [...acc.linePoints, pointRange];
              acc.colorPoints = [...acc.colorPoints, color];
              acc.lineColorPoints = [...acc.lineColorPoints, pointRange, color];
            } else {
              acc.linePoints = [pointRange];
              // acc.colorPoints = [ELEVATION_COLORS[0]];
              // acc.lineColorPoints = [pointRange, ELEVATION_COLORS[0]];

              acc.colorPoints = [TEMP_ELEVATION_COLOR];
              acc.lineColorPoints = [pointRange, TEMP_ELEVATION_COLOR];
            }

            return acc;
          },
          {} as IRouteMapDetails
        );

        setRouteMapDetails(routeMapDetails);

        const lineOptions: LineLayer = {
          type  : "line",
          id    : "route",
          source: {
            type       : "geojson",
            data       : geojson,
            lineMetrics: true,
          },
          layout: {
            "line-cap" : "round",
            "line-join": "round",
          },
        };

        map?.current?.addLayer({
          ...lineOptions,
          id   : "routeBg",
          paint: {
            "line-color": "white",
            "line-width": 12,
          },
        });

        map?.current?.addLayer({
          ...lineOptions,
          id: "route",
          paint: {
            "line-width"   : 6,
            "line-gradient": [
              "interpolate",
              ["linear"],
              ["line-progress"],
              ...routeMapDetails.lineColorPoints,
            ],
          },
        });

        map?.current?.addControl(
          new mapboxgl.NavigationControl({ showCompass: false }),
          "bottom-right"
        );

        if (routeTrackpoints && map.current) {
          const startPoint = coordinates[0];
          const endPoint = coordinates[coordinates.length - 1];

          if (startPoint[0] === endPoint[0] && startPoint[1] === endPoint[1]) {
            createMarker(StartFinishMarker, startPoint[0], startPoint[1]);
          } else {
            createMarker(StartMarker, startPoint[0], startPoint[1]);
            createMarker(FinishMarker, endPoint[0], endPoint[1]);
          }

          RideRouteDetails.moveMapToPosition(startPoint[0], startPoint[1]);
        }
      }
    },
    [coordinates, createMarker, routeTrackpoints]
  );

  const drawStartLocation = useCallback(
    () => {
      const { longitude, latitude } = rideDetails?.startLocation ?? {};

      createMarker(StartMarker, longitude, latitude);

      RideRouteDetails.moveMapToPosition(longitude, latitude);
    },
    [createMarker, rideDetails?.startLocation],
  );

  const createMap = useCallback(
    (container: string | HTMLElement) => {
      map.current = new mapboxgl.Map({
        container,
        style: 'mapbox://styles/mapbox/outdoors-v11',
        zoom: 10,
        attributionControl: false,
      }).on('load', () => {
        if (showStartLocation) {
          drawStartLocation();
          drawRoute();
        }
        if (showMapAndStartLocation) {
          drawStartLocation();
        }
        if (hasRoute || isRouteDetails) {
          drawRoute();
        }
      });
      map.current.addControl(new mapboxgl.AttributionControl(), 'top-left');
      map.current.addControl(new mapboxgl.FullscreenControl());
    },
    [
      drawRoute,
      drawStartLocation,
      isRouteDetails,
      hasRoute,
      showMapAndStartLocation,
      showStartLocation
    ]
  );

  useEffect(() => {
    map?.current?.remove();

    if (routeTrackpoints && isSetRef && mapContainerRef.current) {
      const startPoint = coordinates[0];

      createMap(mapContainerRef.current);

      RideRouteDetails.moveMapToPosition(startPoint[0], startPoint[1]);
    }

    if (showStartLocation && isSetRef && mapContainerRef.current) {
      createMap(mapContainerRef.current);
    }
  }, [
    coordinates,
    createMap,
    isReccuringRideOverview,
    isSetRef,
    routeTrackpoints,
    showStartLocation
  ]);

  useEffect(() => {
    if (map.current) return;

    if (isSetRef && mapContainerRef.current) {
      createMap(mapContainerRef.current);
    }
  }, [isSetRef, createMap]);

  const chartLayout = useMemo(
    () => routeMapDetails ? (
      <RouteChart
        colorPoints             = {routeMapDetails.colorPoints}
        linePoints              = {routeMapDetails.linePoints}
        elevations              = {routeMapDetails.elevationsByPoints}
        distances               = {routeMapDetails.distancesBetweenPoints}
        minElevation            = {routeMapDetails.minElevation}
        maxElevation            = {routeMapDetails.maxElevation}
        distance                = {routeMapDetails.distance}
        isReccuringRideOverview = {isReccuringRideOverview}
      />
    ) : null,
    [isReccuringRideOverview, routeMapDetails],
  );

  const mapLayout = useMemo(
    () => showNoMapLabel ? (
      <RideRouteDetails.SelectLocationWrapper>
        <RideRouteDetails.SelectLocationLabel
          $disabled = {isEditLinkDisabled || isTrackpointsError}
          onClick   = {() => openCta({ tab: ECtaTab.EDIT_RIDE, urlParams: { rideId: rideDetails?.id } })}
        >
          {
            isTrackpointsError
            ? 'No route data available'
            : isEditLinkDisabled
              ? 'No location or route selected'
              : 'Add route or meet-up location for detailed map'
          }
        </RideRouteDetails.SelectLocationLabel>
      </RideRouteDetails.SelectLocationWrapper>
    ) : (
      <RideRouteDetails.MapContainer
        ref                      = {setRef}
        $hasRoute                = {hasRoute}
        $isReccuringRideOverview = {isReccuringRideOverview}
      />
    ),
    [
      showNoMapLabel,
      isEditLinkDisabled,
      isTrackpointsError,
      setRef,
      hasRoute,
      isReccuringRideOverview,
      openCta,
      rideDetails?.id
    ],
  );

  return (
    <>
      {mapLayout}

      {showChart && chartLayout}
    </>
  );
};

RideRouteDetails.moveMapToPosition = (_lng?: number, _lat?: number) => {};

RideRouteDetails.MapContainer = styled.div<{
  $isReccuringRideOverview: boolean;
  $hasRoute               : boolean;
}>`
  width     : 100%;
  height    : ${({ $isReccuringRideOverview }) => $isReccuringRideOverview ? '69%' : '30%'};
  min-height: 200px;
  
  .mapboxgl-canvas {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutralGrey[500]};
    height: 100%;
    position: static;
  }
`;

RideRouteDetails.SelectLocationWrapper = styled.div`
  width          : 100%;
  height         : 30%;
  min-height     : 200px;
  display        : flex;
  justify-content: center;
  align-items    : center;
`;

RideRouteDetails.SelectLocationLabel = styled(Link)<{ $disabled: boolean; }>`
  font-size: 16px;

  ${({ $disabled, theme: { colors } }) => $disabled && css`
    pointer-events: none;
    color         : ${colors.type.medium};
  `};
`;

export default RideRouteDetails;
