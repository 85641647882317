import {
  useCallback,
  useMemo,
}                                  from 'react';
import styled                      from 'styled-components';
import { format }                  from 'date-fns';
import { Slider }                  from '@mui/material';
import {
  getTimeFormat
}                                  from '@utils/time_utils';
import {
  ITimeline,
  IWeatherDetails
}                                  from '@models/rideWeather';
import { useGetUserSettingsQuery } from '@modules/Profile/queries';

const MAX_SLIDER_VALUE = 100;

const getFormattedDate = (time: string) => {
  const formattedHours   = time.split('T')[1].split('-')[0].split(':')[0];
  const formattedMinutes = time.split('T')[1].split('-')[0].split(':')[1];

  const date = new Date();
  date.setHours(Number(formattedHours))
  date.setMinutes(Number(formattedMinutes))

  return date;
}

interface IWeatherTimelineSlider {
  cardSize         : boolean;
  timelines        : ITimeline[];
  setCurrentWeather: (weather: IWeatherDetails) => void;
  hasRoute         : boolean;
  startTime        : string;
  endTime          : string;
}

export const WeatherTimelineSlider = ({
  cardSize,
  timelines,
  setCurrentWeather,
  startTime,
  endTime,
  hasRoute
}: IWeatherTimelineSlider) => {
  const { data: settings } = useGetUserSettingsQuery();

  const weatherTimelines = useMemo<IWeatherDetails[]>(
    () => {
      if (timelines.length) {
        let weatherTimelines: IWeatherDetails[] = [];

        if (!hasRoute) {
          const lines = timelines[0].intervals.map(timeline => timeline.values);

          weatherTimelines = lines;
        } else {
          const lastIndex = timelines.length - 1;

          const lines = timelines.map((timeline, index) => {
            return index !== lastIndex ? timeline.intervals[0].values : [timeline.intervals[0].values, timeline.intervals[1].values];
          });

          weatherTimelines = lines.flat();
        }

        return weatherTimelines
          .reduce((acc, item, index) => {
          if (index !== 0) {
            const prevItem = acc[index - 1];
            const currentItem: IWeatherDetails = { ...item };

            const weatherKeys = Object.keys(prevItem) as (keyof IWeatherDetails)[];

            weatherKeys.forEach((key) => {
              if (!currentItem[key]) {
                // @ts-ignore
                currentItem[key] = prevItem[key];
              }
            });

            if (currentItem.weatherCode === 1000) {
              currentItem.weatherCode = prevItem.weatherCode;
            }

            return [...acc, currentItem];
          }

          return [...acc, item];
        }, [] as IWeatherDetails[]);
      }

      return [];
    },
    [hasRoute, timelines]
  );

  const sliderStep = useMemo(
    () => MAX_SLIDER_VALUE / (hasRoute ? weatherTimelines.length : weatherTimelines.length - 1),
    [hasRoute, weatherTimelines.length]
  );

  const handleOnSelectTime = useCallback(
    (event: Event, value: number) => {
      event.stopPropagation();

      const index = +((value / sliderStep)).toFixed();

      if (weatherTimelines && weatherTimelines[index]) {
        const weatherPoint = weatherTimelines[index];

        if (weatherPoint) {
          setCurrentWeather(weatherPoint);
        }
      }
    },
    [sliderStep, setCurrentWeather, weatherTimelines]
  );

  const valueLabelFormat = useCallback(
    (value: number): string => {
      if (timelines.length) {
        const index = value === 0 ? 0 : +((value / sliderStep)).toFixed();

        if (!hasRoute) {
          if (index === 0) {
            return startTime;
          }

          if (index === weatherTimelines.length - 1) {
            return endTime;
          }

          const time = timelines[0].intervals[index].startTime;

          return format(getFormattedDate(time), getTimeFormat(settings?.timeType));
        }

        const lastIndex = timelines.length + 1;  // add extra point to timeline for duplicated last value - /** TODO: remove this if backend returns additional points */

        if (index === 0) {
          return startTime;
        }

        if (index === lastIndex) {
          return endTime;
        }

        const time = index === timelines.length ? timelines[index - 1].endTime : timelines[index].startTime;

        return format(getFormattedDate(time), getTimeFormat(settings?.timeType));
      }

      return '';
    },
    [timelines, sliderStep, hasRoute, settings?.timeType, weatherTimelines.length, startTime, endTime]
  );

  return (
    <WeatherTimelineSlider.Wrapper $cardSize={cardSize}>
      <WeatherTimelineSlider.Time  $cardSize={cardSize}>
        {startTime && <WeatherTimelineSlider.TimeLabel>{startTime}</WeatherTimelineSlider.TimeLabel>}
        {endTime && <WeatherTimelineSlider.TimeLabel>{endTime}</WeatherTimelineSlider.TimeLabel>}
      </WeatherTimelineSlider.Time>

      <Slider
        defaultValue      = {0}
        onChange          = {(event, value) => handleOnSelectTime(event, value as number)}
        step              = {sliderStep}
        valueLabelFormat  = {valueLabelFormat}
        size              = "medium"
        valueLabelDisplay = "auto"
      />
    </WeatherTimelineSlider.Wrapper>
  )
};

WeatherTimelineSlider.Wrapper = styled.div<{ $cardSize: boolean }>`
  .MuiSlider-root {
    color : #c7c5c5;
    height: ${({ $cardSize }) => ($cardSize ? "20px" : "35px")};
  }

  .MuiSlider-thumb {
    color     : ${({ theme }) => theme.colors.primary.rubineLight};
    border    : 3px solid ${({ theme }) => theme.colors.primary.white};;
    height    : ${({ $cardSize }) => ($cardSize ? "30px" : "44px")};
    width     : ${({ $cardSize }) => ($cardSize ? "30px" : "44px")};
    box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.36);
  }

  .MuiSlider-track {
    background-color: ${({ theme }) => theme.colors.neutralGrey[500]};
  }
`;

WeatherTimelineSlider.Time = styled.div<{ $cardSize: boolean }>`
  display        : flex;
  justify-content: space-between;
  margin-top     : ${({ $cardSize }) => ($cardSize ? "18px" : "40px")};
  font-size      : 10px;
  font-weight    : 500;
  line-height    : 100%;
  letter-spacing : 0px;
  text-transform : uppercase;
  padding        : 0 10px 0 5px;
`;

WeatherTimelineSlider.TimeLabel = styled.div`
  color      : ${({theme})=>theme.colors.type.dark};
  font-weight: 500;
`
