import styled                            from "styled-components";
import { ReactNode }                     from "react";
import { Button }                        from "@mui/material";
import { ReactComponent as ChevronIcon } from "../../images/Icon-Chevron-R-Rubine.svg";

interface ICreateButtonProps {
  buttonClasses?: string;
  label         : string;
  onHandleClick : (text: string) => void;
  icon?         : ReactNode;
}

export const CreateButton = ({ 
  label, 
  icon,
  onHandleClick
}: ICreateButtonProps) => (
  <CreateButton.Container 
    variant   = "outlined"
    endIcon   = {<ChevronIcon />}
    startIcon = {icon}
    onClick   = {() => onHandleClick('ride')}
  >
    {label}
  </CreateButton.Container>
);

CreateButton.Container = styled(Button)`
  &.MuiButton-root {
    box-sizing    : border-box;
    transition    : all 200ms ease-in-out;
    border-radius : 8px;
    font-size     : ${({ theme: { fontSizes: { l }}}) => l};
    padding       : 14px 25px;
    letter-spacing: normal;
    flex-grow     : 1;

    ${({ theme: { mediaQueries: { md }}}) => md} {
      font-size: 14px;
      padding  : 7px 6px 7px 12px;
    }
  };
`;