import { useState, useCallback } from 'react';
import { Link }                  from 'react-router-dom';
import styled                    from 'styled-components';
import {
  useFeedContext,
  useTranslationContext
}                                from 'react-activity-feed';
import { humanizeTimestamp }     from 'react-activity-feed/dist/utils';
import { Menu, MenuItem }        from '@mui/material';
import MoreHorizIcon             from '@mui/icons-material/MoreHoriz';
import { Avatar }                from '@components/Avatar';
import { getUserAvatarUrl }      from '@utils/files_utils';
import { ConfirmationModal }     from '@components/ConfirmationModal';

interface IHeaderProps {
  id                : string;
  time              : string;
  username          : string;
  displayName       : string;
  location          : MapboxGeocoder.Result | null;
  isOwnPost         : boolean;
  onEditPost        : () => void;
  profilePictureUrl?: string | null;
}

export const Header = ({
  profilePictureUrl,
  username,
  displayName,
  time,
  location,
  id,
  isOwnPost,
  onEditPost,
}: IHeaderProps) => {
  const feed                                        = useFeedContext();
  const { tDateTimeParser }                         = useTranslationContext();
  const [headerAnchorEl, setHeaderAnchorEl]         = useState<null | HTMLElement>(null);
  const [isConfirmModalOpen, setConfirmModalStatus] = useState(false);

  const handleClickPostActions = useCallback(
    (event: any) => {
      setHeaderAnchorEl(event.currentTarget);
    },
    []
  );

  const handleRemovePost = useCallback(
    () => {
      setConfirmModalStatus(true);
      setHeaderAnchorEl(null);
    },
    []
  );

  const removePost = useCallback(
    () => {
      feed.onRemoveActivity(id).then(() => setConfirmModalStatus(false))
    },
    [feed, id]
  );

  return (
    <Header.Wrapper>
      <Header.AuthorContainer>
        <Avatar
          name     = {displayName}
          size     = {36}
          photoUrl = {getUserAvatarUrl(profilePictureUrl)}
        />

        <Header.AuthorInfo>
          <Header.AuthorWrapper>
            <Header.AuthorName to={`/user/${username}`}>
              {displayName}
            </Header.AuthorName>

            {location && (
              <Header.Location>
                <span>in</span> {location.text}
              </Header.Location>
            )}
          </Header.AuthorWrapper>

          <Header.Date>
            {humanizeTimestamp(time, tDateTimeParser)}
          </Header.Date>
        </Header.AuthorInfo>
      </Header.AuthorContainer>

      {isOwnPost && (
        <>
          <MoreHorizIcon onClick={handleClickPostActions} />

          <Menu
            id            = {`post-action-${id}`}
            anchorEl      = {headerAnchorEl}
            open          = {Boolean(headerAnchorEl)}
            onClose       = {() => setHeaderAnchorEl(null)}
            MenuListProps = {{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick = {onEditPost}>
              Edit
            </MenuItem>

            <MenuItem onClick = {handleRemovePost}>
              Delete
            </MenuItem>
          </Menu>
        </>
      )}

      {isConfirmModalOpen && (
        <ConfirmationModal
          onSubmit         = {removePost}
          onCancel         = {() => setConfirmModalStatus(false)}
          title            = "Are you sure you want to delete this post?"
          submitButtonText = 'Delete'
          cancelButtonText = 'Cancel'
        />
      )}
    </Header.Wrapper>
  );
}

Header.Wrapper = styled.div`
  display                : flex;
  justify-content        : space-between;
  margin-top             : 30px;
  padding                : 0px 15px 0px 15px;
  background-color       : #FFF;
  border-top-left-radius : 8px;
  border-top-right-radius: 8px;

  svg {
    cursor    : pointer;
    margin-top: 15px;
  }
`;

Header.AuthorContainer = styled.div`
  display   : flex;
  gap       : 10px;
  margin-top: 15px;
`;

Header.AuthorName = styled(Link)`
  color          : ${({ theme: { colors } }) => colors.type.dark};
  margin         : 0;
  font-weight    : 600;
  text-decoration: none;
  transition     : ease .2s;  

  &:hover {
    color: ${({ theme: { colors } }) => colors.primary.rubineLight};
  }
`;

Header.AuthorWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

Header.Location = styled.div`
  color      : ${({ theme: { colors } }) => colors.type.dark};
  margin     : 0;
  font-weight: 600;

  span {
    font-weight: initial;
  }
`;

Header.Date = styled.time`
  font-size: ${({ theme: { fontSizes } }) => fontSizes.s};
`;

Header.AuthorInfo = styled.div`
  display        : flex;
  flex-direction : column;
  justify-content: center;
  gap            : 3px;
`;
