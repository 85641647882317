import styled                           from 'styled-components';
import { SignupButton }                 from '../../../../../components/SignupButton';
import { ReactComponent as EmailIcon }  from '../../../../../images/Icon-Sign-Up-Mail.svg';
import { ReactComponent as GoogleIcon } from '../../../../../images/Icon-Sign-Up-Google.svg';
import { ReactComponent as AppleIcon }  from '../../../../../images/Icon-Sign-Up-Apple.svg';

interface ISignupCTAsProps {
  handleAppleClick     : () => void;
  handleEmailClick     : () => void;
  handleGoogleClick    : () => void;
  handleFacebookClick? : () => void;
  ctasContainerVisible : boolean;
  ctasVisible          : boolean;
}

export const SignupCTAs = ({
  handleAppleClick,
  handleEmailClick,
  ctasContainerVisible,
  // handleFacebookClick,
  handleGoogleClick,
  ctasVisible
}: ISignupCTAsProps) => (
  <SignupCTAs.Wrapper isVisible={ctasContainerVisible}>
    <SignupCTAs.Container isVisible={ctasVisible}>
      <SignupButton onHandleClick={handleEmailClick} title="Sign up with Email" icon={<EmailIcon />} />

      <SignupButton onHandleClick={handleGoogleClick} title="Sign up with Google" icon={<GoogleIcon />} />

      <SignupButton onHandleClick={handleAppleClick} title="Sign up with Apple" icon={<AppleIcon />} />
    </SignupCTAs.Container>
  </SignupCTAs.Wrapper>
);

SignupCTAs.Wrapper = styled.div`
  position       : absolute;
  left           : 0%;
  top            : 60%;
  right          : 0%;
  bottom         : 0%;
  z-index        : 500;
  display        : ${({ isVisible }: { isVisible: boolean }) => !isVisible ? 'none' : 'flex'};
  margin-bottom  : 72px;
  flex-direction : column;
  align-items    : center;
  transition     : opacity 300ms ease-in;

  @media screen and (min-width: 1440px) {
    margin-bottom: 80px;
  }
  @media screen and (min-width: 1920px) {
    margin-bottom: 112px;
  }
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }

  @media screen and (max-width: 767px) {
    position : relative;
    top      : 24px;
  }


  @media screen and (max-width: 479px) {
    position      : absolute;
    left          : 0%;
    top           : 100px;
    right         : 0%;
    margin-bottom : 88px;
  }
`;

SignupCTAs.Container = styled.div`
  display        : flex;
  width          : 320px;
  flex-direction : column;
  opacity        : 0;
  transition     : opacity 300ms ease-in;
  opacity        : ${({ isVisible } : { isVisible: boolean }) => isVisible ? 1 : 0};

  @media screen and (max-width: 767px) {
    width: 280px;
  }

  @media screen and (max-width: 479px) {
    width: 260px;
  }
`;
