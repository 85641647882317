import { format }             from 'date-fns';
import { IUserInfo }          from '@models/UserInfo';
import { IStatisticsMetrics } from '@components/UserStatisticsTabs/model/statistics';
import { roundToDecimal }     from '@utils/metrics_utils';
import { getFormatDuration }  from '@utils/string_utils';
import { toMPH }              from '@utils/conversion_utils';
import { TMeasurementSystem } from '@modules/Profile/models/Settings';

export interface IRecurringRideStatistics {
  date                 : string;
  ridesToDate          : number;
  avgAttendance        : number;
  userAttendance       : number;
  connectionsAttendance: number;
}

export interface IReccuringRideHistoryStatistics {
  ridesToDate?   : number;
  avgAttendance? : number;
  avgSpeed?      : number;
  avgTime?       : number;
}

export interface IAttendingRider extends IUserInfo {
  ridesCount: number;
}

export interface IRideStatistics<T> extends Omit<IStatisticsMetrics<T>, 'icon' | 'conversionFn'> {
  conversionFn?: (value: T[keyof T], measurementSystem: TMeasurementSystem) => string;
}

export const RIDE_STATISTICS: IRideStatistics<IRecurringRideStatistics>[] = [{
  key         : 'date',
  label       : 'DATE CREATED',
  conversionFn: (value) => format(value ? new Date(value) : new Date(), 'yyyy')
}, {
  key  : 'ridesToDate',
  label: 'RIDES TO DATE'
}, {
  key  : 'avgAttendance',
  label: 'AVG. ATTENDANCE'
}, {
  key  : 'userAttendance',
  label: 'MY ATTENDANCE'
}, {
  key  : 'connectionsAttendance',
  label: 'CONNECTIONS ATTENDANCE'
}];

// todo: add type instead of any
export const HISTORY_STATISTICS: IRideStatistics<any>[] = [{
  key  : 'ridesToDate',
  label: 'RIDES TO DATE'
}, {
  key  : 'avgAttendance',
  label: 'AVG. ATTENDANCE'
}, {
  key         : 'avgSpeed',
  label       : 'AVG. SPEED',
  conversionFn: (value, measurementSystem) => String(roundToDecimal(measurementSystem === 'IMPERIAL' ? toMPH(value) : value))
}, {
  key  : 'avgTime',
  label: 'AVG. TIME',
  conversionFn: (value) => value ? getFormatDuration(value) : '—'
}];
