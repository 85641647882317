import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
}                     from '@mui/material';
import {
  useCallback,
  useEffect,
  useMemo,
  useState
}                     from 'react';
import { useForm }    from 'react-hook-form';
import styled         from 'styled-components';
import { TIME_TYPES } from '@models/TimeTypes';
import { Dropdown }   from '@components/Dropdown';
import { Modal }      from '@components/Modal';
import {
  hoursToSecs,
  minutesToSecs,
  secsToHoursAndMinutes
}                     from '@utils/time_utils';
import {
  DAY_PERIOD,
  getHoursOptions,
  getMinOptions,
  ITimePickerForm,
}                     from '../../models/timePicker';

export enum TIME_PICKER_MODE {
  TIME,
  DURATION,
}

interface ITimePickerProps {
  isOpen          : boolean;
  subtitle        : string;
  timeType        : TIME_TYPES;
  onClose         : () => void;
  onSave          : (time: any) => void;
  defaultTime?    : Date;
  defaultDuration?: number | null;
  minutesStep?    : 5 | 15;
  mode?           : TIME_PICKER_MODE;
}

export const TimePicker = ({
  isOpen,
  subtitle,
  defaultTime     = new Date(),
  defaultDuration = 0,
  minutesStep     = 5,
  mode,
  timeType,
  onClose,
  onSave,
}: ITimePickerProps) => {
  const isDurationMode = mode === TIME_PICKER_MODE.DURATION;

  const {
    hours  : hoursDuration,
    minutes: minutesDuration,
  } = secsToHoursAndMinutes(defaultDuration ?? 0);

  const minutedDurationFormatted = ('0' + Math.ceil((minutesDuration / 5)) * 5).slice(-2).toString();

  const hoursTime   = defaultTime.getHours();
  const minutesTime = ('0' + Math.ceil((defaultTime.getMinutes() / 5)) * 5).slice(-2).toString();

  const hours   = isDurationMode ? hoursDuration : hoursTime;
  const minutes = isDurationMode ? minutedDurationFormatted : minutesTime;

  const isHalfDayFormat = timeType === TIME_TYPES.HOURS_12;
  const dayPeriod       = (isHalfDayFormat && hoursTime / 12 >= 1) ? DAY_PERIOD.PM : DAY_PERIOD.AM;

  const {
    setValue,
    getValues,
    control,
    watch
  } = useForm<ITimePickerForm>({
    defaultValues: {
      hours  : isDurationMode ? hours.toString() : ((hours === 12) ? 0 : (hours % 12)).toString(),
      minutes: ((String(minutes) === '60') ? '00' : String(minutes)),
      dayPeriod
    }
  });

  const createTitle = useMemo(
    () => () => {
      const { hours, minutes, dayPeriod } = getValues();

      if (hours && minutes) {
        const hoursTitle = isHalfDayFormat && hours === '0' ? subtitle === 'Duration' ? '0' : '12' : hours;

        return isDurationMode ? `${ hoursTitle }:${ minutes }` : `${ hoursTitle }:${ minutes } ${ dayPeriod || '' }`;
      }

      return '';
    },
    [getValues, isDurationMode, isHalfDayFormat, subtitle]
  );
  const [title, setTitle] = useState<string>(createTitle());

  const handleSave = useCallback(
    () => {
      const hours    = getValues('hours');
      const isoHours = (getValues('dayPeriod') === DAY_PERIOD.PM) ? (parseInt(hours) - 12): parseInt(hours);
      const minutes  = getValues('minutes');

      if (isDurationMode) {
        const duration = hoursToSecs(Number(hours)) + minutesToSecs(Number(minutes));

        onSave(duration);
      } else {
        const startDate = new Date();

        startDate.setHours(isoHours);
        startDate.setMinutes(parseInt(minutes));

        onSave(startDate);
      }

      onClose();
    },
    [getValues, isDurationMode, onClose, onSave]
  );

  useEffect(
    () => {
      const subscription = watch(
        () => setTitle(createTitle())
      );

      return () => subscription.unsubscribe();
    },
    [watch, createTitle]
  );

  return (
    <Modal
      isOpen              = {isOpen}
      width               = "400px"
      itemName            = {subtitle}
      submitBtnLabel      = "Select Time"
      submitBtnIsDisabled = {!getValues('hours') || !getValues('minutes')}
      title               = {title}
      onClose             = {onClose}
      onSave              = {handleSave}
    >
      <form>
        <TimePicker.Content container justifyContent="center">
          <Dropdown
            label   = "Hr"
            options = {getHoursOptions(isDurationMode ? TIME_TYPES.HOURS_24 : timeType as TIME_TYPES)}
            control = {control}
            name    = "hours"
          />

          <TimePicker.Colon>
            <b>:</b>
          </TimePicker.Colon>

          <Dropdown
            label   = "Min"
            options = {getMinOptions(minutesStep)}
            control = {control}
            name    = "minutes"
          />

          { mode === TIME_PICKER_MODE.TIME && timeType === TIME_TYPES.HOURS_12 && (
            <TimePicker.RadioGroup
              value    = {getValues('dayPeriod')}
              onChange = {(_event: React.ChangeEvent<HTMLInputElement>, value: string) =>
                setValue('dayPeriod', value as DAY_PERIOD)}
            >
              <FormControlLabel
                value   = {DAY_PERIOD.AM}
                label   = "AM"
                control = {<Radio />}
              />

              <FormControlLabel
                value   = {DAY_PERIOD.PM}
                label   = "PM"
                control = {<Radio />}
              />
            </TimePicker.RadioGroup>
          )}
        </TimePicker.Content>
      </form>
    </Modal>
  );
}

TimePicker.Content = styled(Grid)`
  padding    : 24px;
  display    : flex;
  align-items: center;

  .MuiFormControl-root {
    width: 88px;
  }
`;

TimePicker.Colon = styled.div`
  font-size: 28px;
  padding  : 0 10px;

  ${({theme: {mediaQueries: { sm }}}) => sm} {
    padding: 0 20px;
  };
`;

TimePicker.RadioGroup = styled(RadioGroup)`
  padding-left: 10px;

  ${({theme: {mediaQueries: { sm }}}) => sm} {
    padding-left: 30px;
  };
`;
