import {
  useEffect,
  useState,
  useCallback,
}                                          from 'react';
import styled                              from 'styled-components';
import {
  format,
  isAfter,
}                                          from 'date-fns';
import {
  getDateUTCFromISO,
  getTimeFormat,
  cutDateFromISO,
}                                          from '@utils/time_utils';
import { useCta }                          from '@utils/hooks/useCta';
import { INVITATION_STATUS }               from '@modules/Details/models/invitation';
import { MEASUREMENT_SYSTEMS }             from '@modules/Profile/utils/options';
import {
  useGetSelfInformationQuery,
  useGetUserSettingsQuery
}                                          from '@modules/Profile/queries';
import {
  useGetRideEventWeatherQuery,
  usePutRideInvitationResponseMutation
}                                          from '@modules/Details/queries';
import { ECtaTab }                         from '@modules/CreateModal/models/CreateModalContent';
import {
  CardTypes,
  IRideInvitation,
}                                          from '@modules/Calendar/models';
import { RideBlock }                       from '@modules/Calendar/components/WeekViewer/components/Rides/DayCard/components/RideBlock';
import { WeatherBlock }                    from '@modules/Calendar/components/WeekViewer/components/Rides/DayCard/components/WeatherBlock';
import { ControlsBlock }                   from '@modules/Calendar/components/WeekViewer/components/Rides/DayCard/components/ControlsBlock';
import {
  IWeatherDetails
}                                          from '@models/rideWeather';
import { TemperatureUnitType }             from '@models/CalendarCardModel';
import { CardHeader }                      from './components/Header';

interface ICardOpenProps {
  cardType       : CardTypes;
  invitation     : IRideInvitation;
  date           : string;
  onDetailsClick?: () => void;
}

export const DayCard = ({
  cardType,
  invitation,
  onDetailsClick,
  date
}: ICardOpenProps) => {
  const { data: user }                      = useGetSelfInformationQuery();
  const { data: settings }                  = useGetUserSettingsQuery();
  const [updateInvitation]                  = usePutRideInvitationResponseMutation();
  const [currentWeather, setCurrentWeather] = useState<IWeatherDetails | null>(null);
  const [startTime, setStartTime]           = useState<string>('');
  const [endTime, setEndTime]               = useState<string>('');
  const { openCta }                         = useCta();
  const isRideStarted                       = !isAfter(getDateUTCFromISO(invitation.ride.startDate), new Date());

  const {
    data: routeWeather,
  } = useGetRideEventWeatherQuery({
    id      : invitation.plannedRideId,
    rideDate: cutDateFromISO(invitation.ride.startDate ?? ''),
  }, { skip:  isRideStarted || !invitation.plannedRideId });

  const changeAttendanceStatus = useCallback(
    (status: INVITATION_STATUS) => {
      updateInvitation({ rideId: invitation.plannedRideId, status, date });
    },
    [date, invitation.plannedRideId, updateInvitation]
  );

  const handleEditRide = useCallback(
    () => openCta({ tab: ECtaTab.EDIT_RIDE, urlParams: { rideId: invitation.plannedRideId } }),
    [invitation.plannedRideId, openCta],
  );

  useEffect(
    () => {
      if (routeWeather?.length) {
        setCurrentWeather(routeWeather[0].intervals[0].values);
      }
    },
    [routeWeather]
  );

  useEffect(
    () => {
      setStartTime(format(getDateUTCFromISO(invitation.ride.startDate), getTimeFormat(settings?.timeType)));

      if (invitation.ride.startDate !== invitation.ride.endDate) {
        setEndTime(format(getDateUTCFromISO(invitation.ride.endDate), getTimeFormat(settings?.timeType)));
      }
    },
    [invitation, isRideStarted, settings]
  );

  return (
    <DayCard.Wrapper>
      <CardHeader cardType={cardType} startTime={startTime} endTime={endTime} />

      <RideBlock
        invitation        = {invitation}
        measurementSystem = {settings?.measurementSystem ?? MEASUREMENT_SYSTEMS.IMPERIAL}
      />

      <WeatherBlock
        invitation         = {invitation}
        currentWeather     = {currentWeather}
        routeWeather       = {routeWeather}
        measurementSystem  = {settings?.measurementSystem ?? MEASUREMENT_SYSTEMS.IMPERIAL}
        temperatureUnits   = {settings?.temperatureUnits ?? TemperatureUnitType.f}
        startTime          = {startTime}
        endTime            = {endTime}
        isRideStarted      = {isRideStarted}
        isEditLinkDisabled = {isRideStarted || user?.id !== invitation.organizer.id}
        setCurrentWeather  = {setCurrentWeather}
        onOpenEdit         = {handleEditRide}
      />

      <ControlsBlock
        isRideStarted            = {isRideStarted}
        cardType                 = {cardType}
        invitationStatus         = {invitation.invitationStatus ?? INVITATION_STATUS.PENDING}
        onDetailsClick           = {onDetailsClick}
        onChangeAttendanceStatus = {changeAttendanceStatus}
      />
    </DayCard.Wrapper>
    );
  };

DayCard.Wrapper = styled.div`
  align-self   : center;
  width        : 320px;
  border-radius: 8px;
  box-shadow   : 0 2px 8px 0 rgb(0 0 0 / 4%), 0 4px 16px 0 rgb(0 0 0 / 8%);
  margin       : 1.03% 1.03% 25px 1.03%;
  transition   : all 300ms ease-in-out;
  overflow     : hidden;
`;
