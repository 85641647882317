import { Grid }            from '@mui/material';
import { IApp }            from '@modules/Profile/containers/AppsContainer';
import { Layout }          from '@modules/Profile/components/Layout';
import { PageHeader }      from '@modules/Profile/components/PageHeader';
import { PageDescription } from '@modules/Profile/components/PageDescription';
import { AppConnector }    from '@modules/Profile/pages/Apps/components/AppConnector';

interface IAppsPageProps {
  apps: IApp[];
}

export const AppsPage = ({
 apps,
}: IAppsPageProps) => (
  <Layout>
    <Grid container>
      <Grid item xs={12}>
        <PageHeader>Apps</PageHeader>
      </Grid>

      <Grid item xs={12}>
        <PageDescription>
          Connect your apps below to allow Preem to Import ride history and sync New rides.
        </PageDescription>
      </Grid>

      <Grid item xs={12} marginTop={4}>
        {apps.map((app, index) => (
          <AppConnector
            {...app}
            key              = {app.title}
            showBottomBorder = {index !== apps.length - 1}
          />
        ))}
      </Grid>
    </Grid>
  </Layout>
);
