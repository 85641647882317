import styled, { css }                     from 'styled-components';
import { useCallback }                     from 'react';
import { useParams }                       from 'react-router';
import { Button, Grid }                    from '@mui/material';
import TimerIcon                           from '@mui/icons-material/Timer';
import LocationOnIcon                      from '@mui/icons-material/LocationOn';
import { ReactComponent as DistanceIcon }  from '@images/Icon-Metric-Distance-Black.svg';
import { ReactComponent as ElevationIcon } from '@images/Icon-Metric-Elevation-Black.svg';
import {
  getDistance,
  getDistanceUnits,
  getElevation,
  getElevationMetrics
}                                          from '@utils/metrics_utils';
import { getRidePhotoUrl }                 from '@utils/files_utils';
import { getRouteDurationByDistance }      from '@utils/time_utils';
import { useGetUserSettingsQuery }         from '../../../Profile/queries';
import { RIGHT_PANEL_CONTENT_TYPE }        from '../../models/rightPanelContent';
import { RightPanelBlock }                 from '../RightPanelBlock';
import { Popup, POPUP_TYPES }              from '../Popup';
import { RideRouteDetails }                from '../RideRouteDetails';
import {
  useGetRouteDetailsQuery,
  useGetRouteTrackpointsQuery,
  useGetRoutePhotosQuery
}                                          from '../../queries';

export const RouteDetails = () => {
  const { routeId }                = useParams<{ routeId: string }>();
  const { data: settings }         = useGetUserSettingsQuery();
  const { data: routeDetails }     = useGetRouteDetailsQuery({ id: routeId }, { skip: !routeId });
  const {
    data: routeTrackpoints,
  } = useGetRouteTrackpointsQuery({
      id        : routeId,
      isPrivate : !!routeId
    },
    { skip: !routeId }
  );

  const changeMapPosition = useCallback(
    () => {
      RideRouteDetails.moveMapToPosition(routeTrackpoints?.coordinates[0][0], routeTrackpoints?.coordinates[0][1]);
    },
    [routeTrackpoints]
  );

  const { data: routePhotos } = useGetRoutePhotosQuery({
    routeId,
    pageNumber: 0,
    pageSize  : 20
  });

  return (
    <>
      {routeDetails && routeTrackpoints && (
        <Grid container>
          <Grid item container alignItems="center" columnSpacing="24px" paddingBottom="10px">
            <Grid item xs="auto" container alignItems="center">
              <TimerIcon />

              <b style={{ marginLeft: '7px' }}>{getRouteDurationByDistance(routeDetails.distance)}</b>
            </Grid>

            <Grid item xs="auto" container alignItems="center">
              <DistanceIcon />

              <b>{getDistance(settings?.measurementSystem, routeDetails.distance)}</b>
              <RouteDetails.Units>{getDistanceUnits(settings?.measurementSystem)}</RouteDetails.Units>
            </Grid>

            <Grid item xs="auto" container alignItems="center">
              <ElevationIcon />

              <b>{getElevation(settings?.measurementSystem, routeDetails.elevationGain)}</b>
              <RouteDetails.Units>{getElevationMetrics(settings?.measurementSystem)}</RouteDetails.Units>
            </Grid>
          </Grid>

          {(window.location.host.includes("testing") || window.location.host.includes("staging") || window.location.host.includes("local")) && (
            <Grid item paddingBottom="24px">
              <RouteDetails.Label>START LOCATION</RouteDetails.Label>

              <RouteDetails.LocationButton
                startIcon = {<LocationOnIcon />}
                onClick   = {changeMapPosition}
              >
                {routeDetails.genLocation.name ?? `${routeTrackpoints.coordinates[0][0]}, ${routeTrackpoints.coordinates[0][1]}`}
              </RouteDetails.LocationButton>
            </Grid>
          )}

          <>
            <RightPanelBlock
              title         = {`Photos (${routePhotos?.totalElements ?? 0})`}
              content       = {RIGHT_PANEL_CONTENT_TYPE.ROUTE_PHOTOS}
              onAddBtnClick = {() => Popup.open(POPUP_TYPES.PHOTO, { routeId })} // todo: pass photos through props
              routeId       = {routeId}
            >
              <Grid item container columnSpacing="10px" rowSpacing="10px">
                {routePhotos?.content?.map((photo, index) => (
                  <Grid key={photo.id} item>
                    <RouteDetails.Photo
                      onClick = {() => Popup.open(POPUP_TYPES.PHOTO_PREVIEW, { selectedPhotoIndex: index, photos: routePhotos.content, routeId})}
                      src     = {getRidePhotoUrl(photo.fileLink)}
                    />
                  </Grid>
                ))}
              </Grid>
            </RightPanelBlock>

            {/* <RightPanelBlock
              hideAddButton
              title   = 'Comments'
              content = {RIGHT_PANEL_CONTENT_TYPE.CHAT}
            /> */}
          </>
        </Grid>
      )}
    </>
  );
}

export const RideGalleryPhoto = styled.div`
  transition: all 200ms ease-in-out;
  cursor    : pointer;
  overflow  : hidden;
  &:hover {
    border-radius: 4px;
    box-shadow   : ${({theme: {colors: {primary: { rubineLight }}}}) => `0 0 0 3px ${rubineLight}`};
  }
  img {
    width : 64px;
    height: 64px;
  }
`;

RouteDetails.Units = styled.span`
  ${({theme: {colors: {type: { medium }}, fontSizes: { s }}}) => css`
    color       : ${ medium };
    font-size   : ${ s };
    padding-left: 4px;
  `}
`;

RouteDetails.Label = styled.div`
  font-size     : 11px;
  color         : ${({theme: {colors: {type: { medium }}}}) => medium};
  letter-spacing: 0.8px;
`;

RouteDetails.LocationButton = styled(Button)`
  margin-left: -24px;
  margin-top : -6px;
`;

RouteDetails.Photo = styled(RideGalleryPhoto)<{src: string}>`
  background-position: 0% 50%, 0px 0px;
  background-size    : cover,  auto;
  background-image   : ${({src}) => `url(${src})`};
  height             : 64px;
  width              : 64px;
`;
