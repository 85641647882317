import { PropsWithChildren } from "react";
import styled                from "styled-components";

export const PageHeader = ({
  children
}: PropsWithChildren<{}>) => {
  return (
    <PageHeader.Container>
      {children}
    </PageHeader.Container>
  );
};

PageHeader.Container = styled.p`
  text-align: left;

  ${({theme: {fontSizes: { xl }, colors: { neutralBlue }}}) => `
    font-size     : ${xl};
    color         : ${neutralBlue[400]};
    font-weight   : 700;
  `};

  ${({theme: {mediaQueries: { sm }}}) => sm} {
    font-size: 26px;
  }
`;