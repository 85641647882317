import { PropsWithChildren } from "react";
import styled                from "styled-components";

export const PageDescription = ({
  children
}: PropsWithChildren<{}>) => {
    return (
      <PageDescription.Container>
        {children}
      </PageDescription.Container>
    );
}

PageDescription.Container = styled.div`
  font-size     : 15px;
  line-height   : 23px;
  letter-spacing: -0.2px;
  text-align    : left;

  ${({theme: {mediaQueries: {sm}}}) => sm } {
    font-size   : ${({ theme: {fontSizes: {xl}}}) => xl};
    line-height : 28px;
  }
`;