import {
  CSSProperties,
  ReactNode,
}                 from 'react';
import { Loader } from '../Loader';

interface ILoaderWrapperProps {
  isLoading    : boolean;
  children     : ReactNode;
  loaderStyles?: CSSProperties;
}

export const LoaderWrapper = ({
  isLoading,
  children,
  loaderStyles,
}: ILoaderWrapperProps) => isLoading ? <Loader style={loaderStyles} /> : <>{children}</>;
