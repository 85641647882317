import {
  useCallback,
  useEffect,
  useState
}                                        from 'react';
import { useForm }                       from 'react-hook-form';
import { object, string }                from 'yup';
import styled                            from 'styled-components';
import { Button, Grid, TextField }       from '@mui/material';
import { yupResolver }                   from '@hookform/resolvers/yup';
import { useUpdateUserSettingsMutation } from '@modules/Profile/queries';
import { InputError }                    from '@components/InputError';

interface IUserBioProps {
  value?: string;
}

const bioSchema = object().shape({
  bio: string().max(2000, 'Bio should be less than 2000 symbols'),
});

export const UserBio = ({ value }: IUserBioProps) => {
  const [bioEditMode, setBioEditMode] = useState<boolean>(false);
  const [updateUserSettings]          = useUpdateUserSettingsMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm<{ bio: string }>({
    mode         : 'onSubmit',
    defaultValues: { bio: value },
    resolver     : yupResolver(bioSchema),
  });

  const closeEditForm = useCallback(
    () => {
      reset({ bio: value });
      setBioEditMode(false);
    },
    [reset, value]
  );

  useEffect(
    () => reset({ bio: value }),
    [reset, value]
  );

  const onSubmit = (data: { bio: string }) => {
    updateUserSettings(data);
    setBioEditMode(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing="20px">
        {bioEditMode ? (
          <>
            <Grid item xs={12} paddingBottom={1}>
              <TextField
                multiline
                fullWidth
                rows       = {5}
                label      = "Bio"
                variant    = "filled"
                error      = {!!errors?.bio}
                {...register('bio')}
              />
              {!!errors?.bio && <InputError error={errors?.bio?.message ?? ''} />}
            </Grid>

            <Grid item>
              <Button
                color   = "secondary"
                variant = "contained"
                onClick = {closeEditForm}
              >
                Cancel
              </Button>
            </Grid>

            <Grid item>
              <Button variant="contained" type="submit" disabled={!isDirty}>
                Save
              </Button>
            </Grid>
          </>
        ) : (
          <>
            {value && (
              <Grid item xs={12}>
                <UserBio.Wrapper>
                  {value}
                </UserBio.Wrapper>
              </Grid>
            )}

            <Grid item>
              <Button
                variant = "outlined"
                size    = "small"
                onClick = {() => setBioEditMode(true)}
              >
                {value ? 'Edit Bio' : 'Add a Bio'}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
}

UserBio.Wrapper = styled.div`
  font-weight   : 500;
  font-size     : 11px;
  letter-spacing: -0.2px;
  overflow-wrap : anywhere;
  text-align    : left;

  ${({ theme: { mediaQueries: { sm }}}) => sm} {
    font-size: ${({ theme: { fontSizes: { s }}}) => s};
  }
`;
