import {
  useCallback,
  useMemo,
}                      from 'react';
import styled          from 'styled-components';
import {
  AutocompleteRenderInputParams,
  Autocomplete,
  TextField,
  Popper,
}                      from '@mui/material';
import { IConnection } from '@modules/Profile/models/Community';
import { UserOption }  from '@modules/Feed/components/PostModal/components/Mentions/UserOption/index';

interface IMentionsProps {
  connections     : IConnection[];
  selectedUsers   : IConnection[];
  setSelectedUsers: (items: IConnection[]) => void;
}

export const Mentions = ({
  selectedUsers,
  setSelectedUsers,
  connections,
}: IMentionsProps) => {
  const options = useMemo(
    () => {
      const selectedIds = selectedUsers.map(({ id }) => id);

      return connections.filter(({ id }) => !selectedIds.includes(id));
    },
    [connections, selectedUsers]
  );

  const renderInput = useCallback(
    (props: AutocompleteRenderInputParams) => (
      <TextField
        {...props}
        variant     = "outlined"
        placeholder = "Select Connection"
      />
    ),
    [],
  );

  const renderOption = useCallback(
  (_props, item: IConnection) => (
      <UserOption
        {...item}
        onClick = {() => setSelectedUsers([...selectedUsers, item])}
      />
    ),
    [selectedUsers, setSelectedUsers],
  );

  return (
    <Mentions.Container>
      <Autocomplete<IConnection, boolean>
        value           = {selectedUsers}
        options         = {options}
        noOptionsText   = "There are no options"
        getOptionLabel  = {({ username }) => `@${username}`}
        renderInput     = {renderInput}
        renderOption    = {renderOption}
        onChange        = {(_event, items) => setSelectedUsers(items as IConnection[])}
        PopperComponent = {(props) => <Mentions.AutocompletePopper {...props} />}
        multiple
      />
    </Mentions.Container>
  );
};

Mentions.Container = styled.div`
  display        : flex;
  flex-direction : column;
  justify-content: space-between;
`;

Mentions.AutocompletePopper = styled(Popper)`
  .MuiPaper-root {
    background-color: ${({ theme: { colors } }) => colors.neutralGrey[100]}; 
  }
`;
