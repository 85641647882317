import {
  createContext,
  useCallback,
  useMemo,
}                        from 'react';
import {
  Control,
  FormState,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
}                        from 'react-hook-form';
import { Loader }        from '@components/Loader';
import { Notification }  from '@components/Notification';
import { getDiffObject } from '@utils/commonFunctions';
import {
  FSettingsForm,
  ISettingsForm,
}                        from '@modules/Profile/models/Settings';
import {
  EAccountModalContent,
  SettingsPage,
}                        from '@modules/Profile/pages/Settings';
import {
  useGetUserSettingsQuery,
  useUpdateUserSettingsMutation,
}                        from '@modules/Profile/queries';

interface ISettingsContext {
  register           : UseFormRegister<ISettingsForm>;
  setValue           : UseFormSetValue<ISettingsForm>;
  watch              : UseFormWatch<ISettingsForm>;
  getValues          : UseFormGetValues<ISettingsForm>;
  control            : Control<ISettingsForm, object>;
  reset              : UseFormReset<ISettingsForm>;
  formState          : FormState<ISettingsForm>;
  trigger            : UseFormTrigger<ISettingsForm>;
  clearErrors        : UseFormClearErrors<ISettingsForm>;
  defaultValues      : Partial<ISettingsForm>;
  toggleManageAccount: (content?: EAccountModalContent) => void;
}

export const SettingsContext = createContext<ISettingsContext>({} as ISettingsContext);

export const SettingsContainer = () => {
  const { data: settings, isLoading } = useGetUserSettingsQuery();
  const [updateUserSettings]          = useUpdateUserSettingsMutation();

  const defaultValues = useMemo<ISettingsForm>(
    () => FSettingsForm(settings),
    [settings],
  );

  const onSubmit = useCallback(
    (values: ISettingsForm) => {
      const isBirthdateNotCorrect = (values.dayOfBirth || values.monthOfBirth || values.yearOfBirth)
        && !(values.dayOfBirth && values.monthOfBirth && values.yearOfBirth);

      if (isBirthdateNotCorrect) {
        Notification.enqueueSnackbar('Birthdate is not saved. All birthdate fields should be filled in.', 'warning');
      }

      const data = FSettingsForm(values, true);

      const updatedValues = getDiffObject(data, defaultValues, ['weightPounds', 'heightFeet', 'heightInches', 'heightCentimeters']);

      updateUserSettings(updatedValues);
    },
    [defaultValues, updateUserSettings]
  );

  return isLoading || !settings ? <div><Loader /></div> : (
    <SettingsPage
      onSubmit      = {onSubmit}
      defaultValues = {defaultValues}
    />
  );
};
