import { useHistory } from 'react-router';
import { Grid }       from '@mui/material';
import styled         from 'styled-components';
import {
  CardTypes,
  IRideInvitation
}                     from '../../../../../models';
import { DayCard }    from '../DayCard';

interface IGroupRidesProps {
  date           : string;
  groupRideCards?: IRideInvitation[];
}

export const GroupRides = ({ groupRideCards, date }: IGroupRidesProps) => {
  const { push } = useHistory();

  return (
    <>
      {groupRideCards &&
        groupRideCards.map((groupRideCard) => (
          <DayCard
            key            = {`${groupRideCard.plannedRideId}${groupRideCard.ride.startDate}`}
            cardType       = {CardTypes.GroupRide}
            invitation     = {groupRideCard}
            date           = {date}
            onDetailsClick = {() => push(`ride/${groupRideCard.plannedRideId}/${date}`, { prevState: '' })}
          />
        )
      )}

      {(!groupRideCards || groupRideCards?.length === 0) && (
        <NoData>No group rides</NoData>
      )}
    </>
  );
};


export const NoData = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.neutralGrey[200]};
  color           : ${({ theme }) => theme.colors.type.medium};
  font-size       : 16px;
  font-weight     : 700;
  height          : 64px;
  letter-spacing  : -0.2px;
  line-height     : 21.12px;
`;
