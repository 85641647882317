import styled           from 'styled-components';
import {
  Slider,
  SliderProps,
  InputLabel,
  FormControl,
  sliderClasses,
}                       from '@mui/material';
import { TNumberRange } from '@modules/Profile/utils/rangeValues';

export type TRangedInputProps = SliderProps & {
  value         : TNumberRange;
  label         : string;
  valuePostfix? : string;
  renderValues? : (values: TRangedInputProps['value']) => JSX.Element | string;
};

export const RangedInput = ({
  label,
  value,
  valuePostfix,
  renderValues,
  ...props
}: TRangedInputProps) => (
  <RangedInput.FormControl>
    <RangedInput.InfoBar>
      <RangedInput.Label>{label}</RangedInput.Label>

      <RangedInput.Values>
        {renderValues?.(value) ?? `${value[0]} - ${value[1]} ${valuePostfix}`}
      </RangedInput.Values>
    </RangedInput.InfoBar>

    <Slider
      value={value}
      {...props}
    />
  </RangedInput.FormControl>
);

RangedInput.Slider = styled(Slider)`
  ${sliderClasses.thumb}:before {
    border: 1px solid ${({ theme: { colors } }) => colors.primary.white};
  }
`;

RangedInput.Label = styled(InputLabel)`
  position    : static;
  width       : fit-content;
  font-weight : 400;
  margin-left : 0;
  transform   : none;
  line-height : 1.5;
`;

RangedInput.FormControl = styled(FormControl)`
  width      : 100%;
  box-sizing : border-box;
  padding    : 0 25px;
  height     : 64px;
`;

RangedInput.InfoBar = styled.div`
  display         : flex;
  justify-content : space-between;
`;

RangedInput.Values = styled.strong`
  font-size   : 16px;
  line-height : 1.5;
`;
