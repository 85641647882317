import {
  OGAPIResponse,
  EnrichedActivity
}                      from 'getstream';
import { IConnection } from '@modules/Profile/models/Community';

export enum EOgDataType {
  Website = 'website',
  Video   = 'video.other',
  Article = 'article'
}

export enum EPostType {
  Text  = 'Text',
  Video = 'Video',
  Photo = 'Photo',
  Ride  = 'Ride'
}

export enum EPostTypeTitle {
  Text  = 'New Post',
  Video = 'Video',
  Photo = 'Photos',
  Ride  = 'Ride'
}

export interface IPostImage {
  id   : string;
  url  : string;
  state: string;
  file : File;
}

export type TActivityUserType = {
  id               : string;
  username         : string;
  displayName      : string;
  profilePictureUrl: string | null;
  name             : string; // Default required property of EnrichedUser.data field
};

export type Attachments = {
  files? : Array<{ mimeType: string; name: string; url: string }>;
  images?: string[];
  og?    : OGAPIResponse;
};

export interface ISubmitActivity {
  text    : string;
  location: MapboxGeocoder.Result | null;
  images  : IPostImage[];
  mentions: IConnection[];
  og?     : OGAPIResponse;
}

export type TUpdateActivity = Partial<Pick<ISubmitActivity, 'text' | 'location' | 'mentions'> & Pick<IPost, 'id' | 'attachments'>>;

export interface IPost extends EnrichedActivity {
  attachments: Attachments;
  location   : MapboxGeocoder.Result | null;
  text       : string;
  mentions?  : IConnection[];
}
