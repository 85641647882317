import {
  useCallback,
  useEffect,
  useMemo,
  useState,
}                                       from 'react';
import styled                           from 'styled-components';
import { preemFilesHost }               from '@config/api_config';
import { theme }                        from '@styles/theme';
import {
  IRideHistoryItem,
  IRideHistoryParams,
}                                       from '@modules/Profile/models/RideHistory';
import { RideHistoryPage }              from '@modules/Profile/pages/RideHistory';
import { EProcessingJobStatus }         from '@modules/Profile/models/job';
import { TMeasurementSystem }           from '@modules/Profile/models/Settings';
import {
  useDeleteUserRideHistoryMutation,
  useDeleteUserRideHistoriesMutation,
  useGetUserRideHistoryQuery,
  useGetUserSettingsQuery,
  useGetUserJobsQuery,
}                                       from '@modules/Profile/queries';
import { MEASUREMENT_SYSTEMS }          from '@modules/Profile/utils/options';
import { IRideFilters }                 from '@modules/Profile/pages/RideHistory/components/RideFiltersModal';
import {
  IConfigCell,
  ITemporaryItem,
}                                       from '@components/TableData';
import { Avatar }                       from '@components/Avatar';
import { DEFAULT_ROUTE_THUMBNAIL_PATH } from '@components/RouteDetails';
import {
  getDistance,
  getDurationFormat,
  getElevation,
}                                       from '@utils/metrics_utils';
import { formatRideDate }               from '@utils/commonFunctions';
import { useTable }                     from '@utils/hooks/useTable';

const Title = styled.div`
  display: flex;

  p {
    margin-left: 15px;
  }
`;

export const RideHistoryContainer = () => {
  const [deleteRideHistory]              = useDeleteUserRideHistoryMutation();
  const [deleteRideHistories]            = useDeleteUserRideHistoriesMutation();
  // const { data: rideHistoryErrors } = useGetUserRideHistoryErrorsQuery();
  const { data: userSettings }           = useGetUserSettingsQuery();
  const [areFiltersOpen, setFiltersOpen] = useState<boolean>(false);
  const [filters, setFilters]            = useState<IRideHistoryParams>({});

  const handleSaveFilters = useCallback(
    ({ distance, elevation, duration, rideDate }: IRideFilters) => {
      setFilters({
        distanceMin : distance[0],
        distanceMax : distance[1],
        elevationMin: elevation[0],
        elevationMax: elevation[1],
        durationMin : duration[0],
        durationMax : duration[1],
        dateFrom    : rideDate[0],
        dateTo      : rideDate[1],
      });

      setFiltersOpen(false);
    },
    [],
  );

  const configCells: IConfigCell<IRideHistoryItem>[] = useMemo(
    () => [
      {
        label         : 'Date',
        orderBy       : 'date',
        headAlign     : 'left',
        render        : ({ date, routeThumbnailPaths }) => (
          <Title>
            <Avatar
              photoUrl = {`${preemFilesHost}${routeThumbnailPaths?.[0] ?? DEFAULT_ROUTE_THUMBNAIL_PATH}`}
              size     = {40}
            />
            <p>{formatRideDate(new Date(date))}</p>
          </Title>
        ),
        width         : '15%',
        isBold        : true
      },
      {
        label   : 'Ride Title',
        key     : 'name',
        orderBy : 'name',
      },
      {
        label         : 'Distance',
        orderBy       : 'distance',
        headAlign     : 'center',
        cellFlexAlign : 'center',
        width         : '8%',
        render        : (ride) => `${getDistance(userSettings?.measurementSystem, ride.distance)} ${userSettings?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL ? 'mi' : ride.distance >= 1000 ? 'km' : 'm'}`
      },
      {
        label         : 'Elevation',
        orderBy       : 'elevation',
        headAlign     : 'center',
        cellFlexAlign : 'center',
        width         : '8%',
        render        : (ride) => `${getElevation(userSettings?.measurementSystem, ride.elevation)} ${userSettings?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL ? 'ft' : 'm'}`
      },
      {
        label         : 'Time',
        render        : ({ duration }) => getDurationFormat(duration),
        orderBy       : 'duration',
        headAlign     : 'center',
        cellFlexAlign : 'center',
        width         : '8%'
      },
    ],
    [userSettings?.measurementSystem]
  );

  const {
    orderBy,
    order,
    onSort,
    onSelectRow,
    selectedItems,
    setSelectedItems,
    onSelectAll,
    pageSize,
    pageNumber,
    setPageNumber,
    searchText,
    debouncedSearchParam,
    setSearchText,
  } = useTable();

  const {
    data   : userJobs,
    refetch: refetchJobs,
  } = useGetUserJobsQuery(undefined, { pollingInterval: 10000 });

  const {
    data,
    refetch: refetchRides,
    isLoading
  } = useGetUserRideHistoryQuery({
    ...filters,
    pageNumber,
    pageSize,
    sortColumn    : orderBy,
    sortDirection : order,
    name          : debouncedSearchParam,
  }, { pollingInterval: 10000 });

  const {
    content,
    totalPages    = 0,
    totalElements = 0,
  } = data ?? {};

  const refetch = useCallback(
    () => {
      refetchRides();
      refetchJobs();
    },
    [refetchJobs, refetchRides]
  );

  useEffect(refetch, [refetch]);

  const processingJobs = useMemo<ITemporaryItem[]>(
    () => (userJobs ?? [])
      .filter(({ status }) => status !== EProcessingJobStatus.COMPLETE)
      .sort((prev, next) => new Date(next.dateCreated).getTime() - new Date(prev.dateCreated).getTime())
      .map(({ name, status }) => ({
        name,
        text: <>Uploading in progress. Current status: <em style={{ color: theme.colors.primary.rubineDark }}>{status.toLowerCase()}</em></>,
      })),
    [userJobs],
  );

  const rowActions = useMemo(
    () => [{
      label  : 'Remove',
      onClick: ({ id }: IRideHistoryItem) => deleteRideHistory(id)
        .then(() => setSelectedItems(selectedItems.filter((item) => item !== id))),
    }],
    [deleteRideHistory, selectedItems, setSelectedItems],
  );

  const tableActions = useMemo(
    () => [{
      label  : 'Remove',
      onClick: (ids: string[]) => deleteRideHistories(ids)
        .then(() => setSelectedItems(selectedItems.filter((item) => !ids.includes(item))))
    }],
    [deleteRideHistories, selectedItems, setSelectedItems]
  );

  return (
    <RideHistoryPage
      data              = {content ?? []}
      temporaryItems    = {processingJobs}
      configCells       = {configCells}
      isLoading         = {isLoading}
      rowActions        = {rowActions}
      orderBy           = {orderBy}
      order             = {order}
      onSort            = {onSort}
      onSelectRow       = {onSelectRow}
      searchText        = {searchText}
      setSearchText     = {setSearchText}
      selectedItems     = {selectedItems}
      onSelectAll       = {onSelectAll}
      totalPages        = {totalPages}
      totalElements     = {totalElements}
      setPageNumber     = {setPageNumber}
      tableActions      = {tableActions}
      refetch           = {refetch}
      measurementSystem = {userSettings?.measurementSystem as TMeasurementSystem}
      areFiltersOpen    = {areFiltersOpen}
      setFiltersOpen    = {setFiltersOpen}
      onSaveFilters     = {handleSaveFilters}
    />
  )
}
