import styled, { css }                      from 'styled-components';
import { useCallback }                      from 'react';
import { format }                           from 'date-fns';
import { useParams }                        from 'react-router';
import { Button, Grid }                     from '@mui/material';
import EventIcon                            from '@mui/icons-material/Event';
import WatchLaterIcon                       from '@mui/icons-material/WatchLater';
import LocationOnIcon                       from '@mui/icons-material/LocationOn';
import { ReactComponent as DistanceIcon }   from '../../../../images/Icon-Metric-Distance-Black.svg';
import { ReactComponent as ElevationIcon }  from '../../../../images/Icon-Metric-Elevation-Black.svg';
import { ReactComponent as AttendanceIcon } from '../../../../images/Icon-Metric-Attendance-Black.svg';
import { ReactComponent as IntensityIcon }  from '../../../../images/Icon-Metric-Power-Intensity-Black.svg';
import {
  getDistance,
  getDistanceUnits,
  getElevation,
  getElevationMetrics
}                                           from '@utils/metrics_utils';
import {
  cutDateFromISO,
  getDateUTCFromISO,
  getTimeFormat,
}                                           from '@utils/time_utils';
import { getRidePhotoUrl }                  from '@utils/files_utils';
import { IntensityTooltip }                 from '@components/IntensityTooltip';
import { useGetUserSettingsQuery }          from '../../../Profile/queries';
import {
  IRideInvitationStatistics,
  ERideStatus
}                                           from '../../models/ride';
import {
  INVITATION_ICONS,
  INVITATION_STATUS
}                                           from '../../models/invitation';
import { RIGHT_PANEL_CONTENT_TYPE }         from '../../models/rightPanelContent';
import { useDetails }                       from '../../context';
import { RightPanelBlock }                  from '../RightPanelBlock';
import { Popup, POPUP_TYPES }               from '../Popup';
import { RideRouteDetails }                 from '../RideRouteDetails';
import { useGetRidePhotosQuery }            from '../../queries';

interface IRideDetailsProps {
  showPanelBlocks?: boolean;
}

export const RideDetails = ({ showPanelBlocks }: IRideDetailsProps) => {
  const { linkUuid }       = useParams<{ linkUuid?: string }>();
  const { data: settings } = useGetUserSettingsQuery();
  const timeFormat         = getTimeFormat(settings?.timeType);
  const {
    rideId,
    rideDate,
    rideDetails,
    routeDetails,
    nearestRideDetails,
    isPreview,
  } = useDetails();

  const { data: ridePhotos } = useGetRidePhotosQuery({
    rideId,
    rideDate,
    pageNumber: 0,
    pageSize  : 10
  }, { skip: Boolean(linkUuid) });

  const changeMapPosition = useCallback(
    () => {
      RideRouteDetails.moveMapToPosition(rideDetails?.startLocation?.longitude, rideDetails?.startLocation?.latitude);
    },
    [rideDetails?.startLocation?.latitude, rideDetails?.startLocation?.longitude]
  );

  const handleInviteFriends = useCallback(
    () => Popup.open(POPUP_TYPES.INVITE_FRIEND, { rideLink: `${process.env.REACT_APP_PREEM_WEB_URL}/ride/${rideId}/overview` }),
    [rideId]
  );

  const hideAddButton = useCallback(
    () => nearestRideDetails.invitationStatus !== INVITATION_STATUS.ACCEPTED
    ,
    [nearestRideDetails.invitationStatus]
  );

  return (
    <Grid container>
      <Grid item container alignItems="center" columnSpacing="24px" paddingBottom="10px">
        {rideDetails?.rideType && (
          <Grid item>
            <RideDetails.RideType>{rideDetails?.rideType.replace("_", " ")}</RideDetails.RideType>
          </Grid>
        )}

        <Grid item xs="auto" container alignItems="center">
          <EventIcon />

          <b>{format(getDateUTCFromISO(nearestRideDetails.date), 'P')}</b>
        </Grid>

        <Grid item xs="auto" container alignItems="center">
          <WatchLaterIcon />

          <b>{format(getDateUTCFromISO(nearestRideDetails.date), timeFormat)}</b>
        </Grid>

          { // Route Distance
            routeDetails?.distance &&
            (<Grid item xs="auto" container alignItems="center">
              <DistanceIcon />

              <b>{getDistance(settings?.measurementSystem, routeDetails?.distance ?? 0)}</b>
              <RideDetails.Units>{getDistanceUnits(settings?.measurementSystem, routeDetails?.distance ?? 0)}</RideDetails.Units>
            </Grid>)
          }

          { // Elevation Gain
            routeDetails?.elevationGain &&
            (<Grid item xs="auto" container alignItems="center">
              <ElevationIcon />

              <b>{getElevation(settings?.measurementSystem, routeDetails?.elevationGain ?? 0)}</b>
              <RideDetails.Units>{getElevationMetrics(settings?.measurementSystem)}</RideDetails.Units>
            </Grid>)
          }

        <Grid item xs="auto" container alignItems="center">
          <IntensityIcon />

          {rideDetails?.intensity ? (
            <>
              <b>{rideDetails?.intensity}</b>
              <RideDetails.Units>/5</RideDetails.Units>
            </>
          ) : '—'}

          <IntensityTooltip />
        </Grid>
      </Grid>

      <RideDetails.Description item xs={12}>
        {rideDetails?.description}
      </RideDetails.Description>

      {rideDetails?.startLocation && (
        <Grid item paddingBottom="24px">
          <RideDetails.Label>START LOCATION</RideDetails.Label>

          <RideDetails.LocationButton
            startIcon = {<LocationOnIcon />}
            onClick   = {changeMapPosition}
          >
            {rideDetails?.startLocation.placeName || `${rideDetails?.startLocation.longitude}, ${rideDetails?.startLocation.latitude}`}
          </RideDetails.LocationButton>
        </Grid>
      )}

      <Grid item container columnSpacing="30px" paddingBottom="24px">
        {/* todo: uncomment when BE is ready */}
        {/* <Grid item xs={6} sm="auto">
          <RideDetails.Label>LEAVE / HOME BY</RideDetails.Label>
          <div>
            <PlayCircleIcon />
          </div>
          <div>
            <HomeIcon />
          </div>
        </Grid> */}

        <Grid item xs={6} sm="auto">
          <RideDetails.Label>RIDE STOPS</RideDetails.Label>

          <b>{rideDetails?.hasStops ? 'Has Stops' : 'None'}</b>
        </Grid>

        <Grid item xs={6} sm="auto">
          <RideDetails.Label>DROP / NO DROP</RideDetails.Label>

          <b>{rideDetails?.hasDrops ? 'Drop' : 'No Drop'}</b>
        </Grid>

        <Grid item xs={6} sm="auto">
          <RideDetails.Label>REGROUPS</RideDetails.Label>

          <b>{rideDetails?.canRegroup ? 'Can Regroup' : 'None'}</b>
        </Grid>
      </Grid>

      {(rideDetails?.name && showPanelBlocks) && (
        <>
          <RightPanelBlock
            isPreview     = {isPreview}
            title         = "Attendance"
            content       = {RIGHT_PANEL_CONTENT_TYPE.RIDE_ATTENDANCE}
            hideAddButton = {!rideDetails?.isPublic || (rideDetails?.organizer?.username !== settings?.user.username) || nearestRideDetails?.status === ERideStatus.COMPLETED}
            onAddBtnClick = {handleInviteFriends}
          >
            {nearestRideDetails?.status === ERideStatus.COMPLETED ? (
              <Grid item container alignItems="center" xs="auto">
                <RideDetails.InvitationIcon>
                  <AttendanceIcon />
                </RideDetails.InvitationIcon>

                <b>{nearestRideDetails?.attendeesCount}</b>
              </Grid>
              ) : Object.keys(INVITATION_ICONS).map(status => {
                const keyInStatistics = status.toLowerCase() as keyof IRideInvitationStatistics;
                const value           = nearestRideDetails?.invitationStatistics?.[keyInStatistics] || 0;

                return (
                  <Grid item key={status} container alignItems="center" xs="auto">
                    <RideDetails.InvitationIcon>
                      {INVITATION_ICONS[status as INVITATION_STATUS]}
                    </RideDetails.InvitationIcon>

                    <b>{value}</b>
                  </Grid>
                )
              })
            }
          </RightPanelBlock>

          {!linkUuid && (
            <RightPanelBlock
              title         = {`Photos (${ridePhotos?.totalElements ?? 0})`}
              content       = {RIGHT_PANEL_CONTENT_TYPE.RIDE_PHOTOS}
              onAddBtnClick = {() => Popup.open(POPUP_TYPES.PHOTO, { rideId, rideDate: cutDateFromISO(nearestRideDetails?.date ?? '') })}
              hideAddButton = {hideAddButton()}
            >
              <Grid item container columnSpacing="10px" rowSpacing="10px">
                {ridePhotos?.content?.map((photo, index) => (
                  <Grid key={photo.id} item>
                    <RideDetails.Photo
                      onClick = {() => Popup.open(POPUP_TYPES.PHOTO_PREVIEW, { selectedPhotoIndex: index, photos: ridePhotos.content, rideId })}
                      src     = {getRidePhotoUrl(photo.fileLink)}
                    />
                  </Grid>
                ))}
              </Grid>
            </RightPanelBlock>
          )}

          {(nearestRideDetails?.invitationStatus === INVITATION_STATUS.ACCEPTED ||
            nearestRideDetails?.invitationStatus === INVITATION_STATUS.MAYBE) &&
            <RightPanelBlock
              hideAddButton
              isPreview = {isPreview}
              title     = 'Ride Chat'
              content   = {RIGHT_PANEL_CONTENT_TYPE.CHAT}
            />
          }
        </>
      )}
    </Grid>
  );
}

export const RideGalleryPhoto = styled.div`
  transition: all 200ms ease-in-out;
  cursor    : pointer;
  overflow  : hidden;
  &:hover {
    border-radius: 4px;
    box-shadow   : ${({theme: {colors: {primary: { rubineLight }}}}) => `0 0 0 3px ${rubineLight}`};
  }
  img {
    width : 64px;
    height: 64px;
  }
`;

RideDetails.RideType = styled.div`
  ${({theme: {colors: {type: { dark }}}}) => css`
    border       : 2px solid ${ dark };
    border-radius: 4px;
    font-size    : 10px;
    padding      : 0 10px;
    font-weight  : 600;
  `}
`;

RideDetails.Units = styled.span`
  ${({theme: {colors: {type: { medium }}, fontSizes: { s }}}) => css`
    color       : ${ medium };
    font-size   : ${ s };
    padding-left: 4px;
  `}
`;

RideDetails.InvitationIcon = styled.span`
  padding-right: 4px;
  display      : flex;
  
  svg {
    width : 20px;
    height: 20px;
  }
`;

RideDetails.Description = styled(Grid)`
  font-size     : ${({theme: {fontSizes: { l }}}) => l};
  padding-bottom: 24px;
  white-space   : pre-line;
`;

RideDetails.Label = styled.div`
  font-size     : 11px;
  color         : ${({theme: {colors: {type: { medium }}}}) => medium};
  letter-spacing: 0.8px;
`;

RideDetails.LocationButton = styled(Button)`
  margin-left: -24px;
  margin-top : -6px;
`;

RideDetails.Photo = styled(RideGalleryPhoto)<{src: string}>`
  background-position: 0% 50%, 0px 0px;
  background-size    : cover,  auto;
  background-image   : ${({src}) => `url(${src})`};
  height             : 64px;
  width              : 64px;
`;
