import { useCallback } from 'react';

interface IUseSessionStorageReturn {
  setItem   : <TData extends object>(key: string, data: TData) => void;
  getItem   : <TData extends object>(key: string) => TData | null;
  removeItem: (key: string) => void;
}

export const useSessionStorage = (): IUseSessionStorageReturn => {
  const setItem = useCallback(
    <TData extends object>(key: string, data: TData) => {
      sessionStorage.setItem(key, JSON.stringify(data));
    },
    []
  );

  const getItem = useCallback(
    (key: string) => {
      const rawData = sessionStorage.getItem(key);

      return rawData ? JSON.parse(rawData) : null;
    },
    []
  );

  const removeItem = useCallback(
    (key: string) => sessionStorage.removeItem(key),
    []
  );

  return {
    setItem,
    getItem,
    removeItem,
  };
};
