import { CSSProperties } from 'react';
import styled            from 'styled-components';
import loader            from '../../images/loader/loader.svg';

interface ILoaderProps {
  style?: CSSProperties
}

export const Loader = ({ style }: ILoaderProps) => (
  <Loader.Wrapper style={style}>
    <img src={loader} alt="Loader" />
  </Loader.Wrapper>
);

Loader.Wrapper = styled.div`
  width           : 100%;
  height          : 100px;
  margin          : 0 auto;
  display         : flex;
  justify-content : center;
  align-items     : center;
`;
