export const getAgeFromDateString = (rawDate: string) => {
  const today = new Date();
  const birthDate = new Date(rawDate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}

export const isUserOldEnough = (dateString?: string) => {
  if (!dateString) {
      return false;
  }
  const age = getAgeFromDateString(dateString);
  return age > 12; // Ensure user is at least 13 years old
}

export const isAgeValid = (rawDate?: string) => {
  if (!rawDate) {
    return false;
  }   
  const age = getAgeFromDateString(rawDate);
  return (age <= 120); // Ensure user age is valid (enough)
};