import { useCallback, useEffect, useState } from 'react';
import styled                               from 'styled-components';
import { ButtonBase, Grow, Popper }         from '@mui/material';
import {
  usePopupState,
  bindHover,
  bindPopper,
}                                           from 'material-ui-popup-state/hooks';

import { scrimBlack }                       from '../../../../../../styles/theme';
import {
  useCreateUserLocationMutation,
  useGetUserGenLocationsQuery,
}                                           from '../../../../../Profile/queries';

import { AddLocationLink }                  from '../../../ContentHero/components/ContentHeroLocationSelector';
import { LocationModal }                    from '../../../../../../components/LocationModal';
import {
  HighlightBorder,
  LocationLink,
}                                           from '../../../ContentHero/components/ContentHeroLocationSelectorDropdown';

import IconDropdownArrow                    from '../../../../../../images/Icon-Dropdown-Arrow-Grey.svg';
import { IGenLocation }                     from '../../../../../Profile/models/genLocation';
import { getGenLocationById }               from '../../../..';

interface IDropdownArrowProps {
  flipped: boolean;
}

const DropdownArrow = styled.img.attrs({
  src: `${IconDropdownArrow}`,
})<IDropdownArrowProps>`
  transition: all 300ms ease-in-out;
  ${({ flipped }) => flipped && `transform:rotate(180deg)`}
`;

interface ILocationDropdownProps {
  onLocationChange : (location: IGenLocation) => void;
  selectedLocation : IGenLocation | undefined;
  locations        : IGenLocation[];
}

export const LocationDropdown = ({
  onLocationChange,
  selectedLocation,
  locations,
}: ILocationDropdownProps) => {
  const [locationOptions, setLocationOptions] = useState<IGenLocation[]>([]);
  const { refetch : refetchGenLocations }     = useGetUserGenLocationsQuery();
  const [isModalOpen, setIsModalOpen]         = useState(false);
  const [createLocation]                      = useCreateUserLocationMutation<IGenLocation>();
  const handleSaveLocation                    = useCallback(
    (data: { lng: number, lat: number, cityName: string, stateName: string }) => {
      createLocation({ ...data, isPrimary: true }).then(
        (response: any) => {
          const newLocation = response.data;

          if (newLocation) {
            getGenLocationById(newLocation.genLocationId).then((genLocation: IGenLocation) => {
              refetchGenLocations();
              onLocationChange(genLocation);
            })
          }
        }
      );
    },
    [createLocation, onLocationChange, refetchGenLocations]
  );

  const handleMenuItemClick = (location: IGenLocation) => {
    popupState.close();

    onLocationChange(location);
  };

  useEffect(() => {
    if (locations) {
      setLocationOptions(locations);
    }
  }, [locations]);

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'locationSelectorGrid',
  });

  return (
    <LocationDropdown.OuterWrapper>
      {isModalOpen && (
        <LocationModal
          isOpen
          onClose            = {() => setIsModalOpen(false)}
          handleSaveLocation = {handleSaveLocation}
        />
      )}

      <LocationDropdown.Trigger {...bindHover(popupState)}>
        {selectedLocation && selectedLocation.name}
        <DropdownArrow flipped={popupState.isOpen} />
      </LocationDropdown.Trigger>

      <LocationDropdown.Wrapper
        disablePortal
        placement="bottom"
        transition
        {...bindPopper(popupState)}
      >
        {({ TransitionProps }) => (
          <Grow in={popupState.isOpen} timeout={300} {...TransitionProps}>
            <LocationDropdown.ContentWrapper>
              {locationOptions.map(locationOption => (
                <LocationLink
                  onClick={() => {
                    handleMenuItemClick(locationOption);
                  }}
                  key      = {locationOption.id}
                  selected = {selectedLocation && selectedLocation.id === locationOption.id}
                >
                  <HighlightBorder />
                  <div>{locationOption.name}</div>
                </LocationLink>
              ))}
              <AddLocationLink
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                Add a New Location
              </AddLocationLink>
            </LocationDropdown.ContentWrapper>
          </Grow>
        )}
      </LocationDropdown.Wrapper>
    </LocationDropdown.OuterWrapper>
  );
};


LocationDropdown.OuterWrapper = styled.div`
  display       : flex;
  flex-direction: column;
  align-items   : center;
  `;

LocationDropdown.Wrapper = styled(Popper)`
  border-radius : 8px;
  z-index       : 1000;
  padding-top   : 0px;
  padding-bottom: 0px;
`;

LocationDropdown.Trigger = styled(ButtonBase)`
  display         : flex;
  height          : 32px;
  width           : 320px;
  justify-content : space-between;
  margin-left     : 0px;
  margin-top      : 8px;
  margin-bottom   : 24px;
  font-size       : 13px;
  font-weight     : 700;
  padding-left    : 16px;
  padding-right   : 8px;
  color           : ${({ theme }) => theme.colors.type.medium};
  border-radius   : 20px;
  background-image: ${({ theme }) => theme.colors.gradientVertical.grey};
  box-shadow      : 0 1px 2px 0 ${scrimBlack(0.04)}, 0 4px 8px 0 ${scrimBlack(0.04)};
  transition      : all 200ms ease-in-out;
  &:focus {
    outline: 0;
  }
  &:hover {
    box-shadow: 0 4px 16px 0 ${scrimBlack(0.16)};
  }

  &:active {
    background-image: ${({ theme }) => theme.colors.gradientVertical.shadow},
      ${({ theme }) => theme.colors.gradientVertical.grey};
    box-shadow      : 0 0 4px 0 ${scrimBlack(0.16)};
  }
`;

LocationDropdown.ContentWrapper = styled.div`
  min-width       : 320px;
  border-radius   : 8px;
  padding-top     : 8px;
  background-color: ${({ theme }) => theme.colors.primary.white};
`;

