import { useContext }           from 'react';
import styled                   from 'styled-components';
import { Button, Grid }         from '@mui/material';
import { EAccountModalContent } from '@modules/Profile/pages/Settings';
import { Tabset }               from '@components/Tabset';
import { PhoneInput }           from '@components/PhoneInput';
import { FieldWrapper }         from '../../../../FieldWrapper';
import { TitleBlock }           from '../../../../TitleBlock';
import { privacyTabs }          from '../../../../../utils/tabs';
import { SettingsContext }      from '../../../../../containers/SettingsContainer';

export const NOTIFICATIONS_TABSET = [{
  title: 'Email',
  tabs : [{ id: true, label: 'On' }, { id: false, label: 'Off' }],
  name : 'isEmailNotification'
}, {
  title: 'Phone',
  tabs : [{ id: true, label: 'On' }, { id: false, label: 'Off' }],
  name : 'isPhoneNotification'
}];

export const NotificationSettings = () => {
  const {
    control,
    defaultValues,
    toggleManageAccount,
  } = useContext(SettingsContext);

  return (
    <FieldWrapper>
      <Grid container spacing={4} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={4} sx={{ display: { xs: 'none', sm: 'block' }}}>
          <TitleBlock
            tabs         = {[privacyTabs[1]]}
            label        = "Notifications"
            description  = "Preem needs to send you notifications to keep you updated on your ride plans and your community. If you prefer receiving notifications on your phone, we ask that you provide your phone number."
            tooltip      = ""
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <Grid container direction="column" alignItems="flex-end">
            {NOTIFICATIONS_TABSET.map((tabset, index) => (
              <Grid key={index} item xs={12}>
                <Grid container alignItems="center" columnSpacing={2} justifyContent="flex-end">
                  <Grid item>
                    <NotificationSettings.Label>{tabset.title}</NotificationSettings.Label>
                  </Grid>

                  <Grid item>
                    <Tabset
                      tabs    = {tabset.tabs}
                      control = {control}
                      name    = {tabset.name}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}

            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
              <PhoneInput
                label   = "Phone"
                variant = "filled"
                value   = {defaultValues.phoneNumber}
                disabled
              />

              <Button
                variant = "outlined"
                onClick = {() => toggleManageAccount(EAccountModalContent.ChangePhone)}
              >
                Change Phone
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FieldWrapper>
  );
};

NotificationSettings.Label = styled.h4`
  text-align: right;
`;
