import { useCallback, useState }  from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Stack,
  Divider,
  Button
}                                  from '@mui/material';
import EventIcon                   from '@mui/icons-material/Event';
import { format }                  from 'date-fns';
import styled, { css }             from 'styled-components';
import { isMobile }                from 'react-device-detect';
import { IRouteTableRow }          from '@models/RouteModel';
import {
  ReactComponent as DistanceIcon
}                                  from '@/images/Icon-Metric-Distance-Black.svg';
import {
  ReactComponent as ElevationIcon
}                                  from '@/images/Icon-Metric-Elevation-Black.svg';
import { preemFilesHost }          from '@config/api_config';
import { useGetUserSettingsQuery } from '@modules/Profile/queries';
import {
  getDistance,
  getDistanceUnits,
  getElevation,
  getElevationMetrics
}                                  from '@utils/metrics_utils';
import { getDateUTCFromISO }       from '@utils/time_utils';

interface IRouteDetailsProps {
  handleClose      : () => void;
  isOpen           : boolean;
  selectedViewRoute: IRouteTableRow;
  updateRoute      : ({ id, name }: { id: string, name: string }) => void;
}

export const DEFAULT_ROUTE_THUMBNAIL_PATH = 'defaultThumbnails/defaultRoute.png';

export const RouteDetails = ({
  handleClose,
  isOpen,
  selectedViewRoute,
  updateRoute
}: IRouteDetailsProps) => {
  const [name, setName]    = useState<string>(selectedViewRoute.name);
  const { data: settings } = useGetUserSettingsQuery();

  const handleSaveRoute = useCallback(
    () => {
      if (name !== selectedViewRoute.name) {
        updateRoute({
          id: selectedViewRoute.id,
          name
        });

        handleClose();
      }
    },
    [handleClose, name, selectedViewRoute.id, selectedViewRoute.name, updateRoute]
  );

  return (
    <Dialog
      open     = {isOpen}
      maxWidth = "sm"
      fullWidth
    >
      <RouteDetails.Content>
        <Grid container sx={{ height: '100%' }} spacing={3}>
          <Grid item xs={12} md={4}>
            <RouteDetails.Section>
              {selectedViewRoute.routeThumbnailPaths?.[0] ? (
                <RouteDetails.Map
                  route    = {`${preemFilesHost}${selectedViewRoute.routeThumbnailPaths?.[0]}`}
                  $isMobile = {isMobile}
                />
              ) : <p>NO IMAGE</p>}
            </RouteDetails.Section>
          </Grid>

          <Grid item xs={12} md={8}>
            <RouteDetails.Section>
              <RouteDetails.Stack direction="row" spacing={1} alignItems="center" justifyContent={isMobile ? 'center' : 'flex-start'}>
                <EventIcon />

                <b>{format(getDateUTCFromISO(selectedViewRoute.creationDate), 'P')}</b>
              </RouteDetails.Stack>

              <RouteDetails.Stack direction="row" spacing={1} alignItems="center" justifyContent={isMobile ? 'center' : 'flex-start'}>
                <DistanceIcon />

                <b>{getDistance(settings?.measurementSystem, selectedViewRoute.distance)}</b>
                <RouteDetails.Units>{getDistanceUnits(settings?.measurementSystem)}</RouteDetails.Units>
              </RouteDetails.Stack>

              <RouteDetails.Stack direction="row" spacing={1} alignItems="center" justifyContent={isMobile ? 'center' : 'flex-start'}>
                <ElevationIcon />

                <b>{getElevation(settings?.measurementSystem, selectedViewRoute.elevation)}</b>
                <RouteDetails.Units>{getElevationMetrics(settings?.measurementSystem)}</RouteDetails.Units>
              </RouteDetails.Stack>

              <TextField
                label   = "Route Name"
                variant = "filled"
                value   = {name}
                onChange = {event => setName(event.target.value)}
                fullWidth
              />

              <RouteDetails.Divider />

              <RouteDetails.Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                <Button
                  color   = "secondary"
                  variant = "contained"
                  onClick = {handleClose}
                >
                  Cancel
                </Button>

                <Button
                  variant = "contained"
                  color   = "primary"
                  onClick = {handleSaveRoute}
                  disabled = {name === selectedViewRoute.name || !name.length}
                >
                  Save
                </Button>
              </RouteDetails.Stack>
            </RouteDetails.Section>
          </Grid>
        </Grid>
      </RouteDetails.Content>
    </Dialog>
  );
}

RouteDetails.Content = styled(DialogContent)`
  height         : max-content;
  padding-bottom : 20px;
`;

RouteDetails.Section = styled.div`
  height          : 100%;

  p {
    font-size   : 24px;
    font-weight : 500;
    color       : ${({ theme }) => theme.colors.neutralGrey[500]};
  }

  .MuiFormControl-root {
    margin-bottom: 15px;
  }
`;

RouteDetails.Map = styled.div<{ route?: string, $isMobile: boolean }>`
  width              : 100%;
  height             : 100%;
  min-height         : 200px;
  background-image   : ${({ route }) => `url(${route})`};
  background-size    : contain;
  background-repeat  : no-repeat;

  ${({ $isMobile }) => $isMobile && `background-position: center`};
`;

RouteDetails.Units = styled.span`
  ${({theme: {colors: {type: { medium }}, fontSizes: { s }}}) => css`
    color       : ${ medium };
    font-size   : ${ s };
    padding-left: 4px;

    && {
      margin-left: 2px;
    }
  `}
`;

RouteDetails.Stack = styled(Stack)`
  margin-bottom: 10px;
`;

RouteDetails.Divider = styled(Divider)`
  margin-top    : 15px;
  margin-bottom : 25px;
`;
