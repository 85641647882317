import {
  useState,
  useCallback,
}                      from 'react';
import { generateUid } from '@utils/commonFunctions';
import { IPostImage }  from '@models/Feed/post';

interface IUseImageUploadReturn {
  images     : IPostImage[];
  uploadImage: (file: File) => void;
  removeImage: (id: string) => void;
  setImages  : (images: IPostImage[]) => void;
}

export const useImageUpload = (): IUseImageUploadReturn => {
  const [images, setImages] = useState<IPostImage[]>([]);

  const uploadImage = useCallback(
    (file: File) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const newImage = {
          id  : generateUid(),
          state: 'finished',
          url : (reader.result ?? '') as string,
          file
        };

        setImages((prevState) => [...prevState, newImage])
      };

      reader.readAsDataURL(file);
    },
    [],
  );

  const removeImage = useCallback(
    (id: string) => {
      setImages((prevImages) => prevImages.filter((image) => image.id !== id));
    },
    [],
  );

  return {
    images,
    uploadImage,
    removeImage,
    setImages
  };
};
