import { useContext }      from 'react';
import styled              from 'styled-components';
import {
  Box,
  Grid
}                          from '@mui/material';
import { PhoneInput }      from '@components/PhoneInput';
import { TextInput }       from '@components/TextInput';
import { SettingsContext } from '@modules/Profile/containers/SettingsContainer';

interface IChangePhoneProps {
  isConfirmation: boolean;
}

export const ChangePhone = ({ isConfirmation }: IChangePhoneProps) => {
  const {
    getValues,
    setValue,
  } = useContext(SettingsContext);

  return (
    <Box sx={{ marginTop: { xs: '-50px', md: '-80px' }}}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <ChangePhone.TextContainer>
            {isConfirmation ? (
              <p>We&apos;ve sent confirmation code to your new phone number, please enter it.</p>
            ) : (
              <p>Please enter new phone number and we&apos;ll send you confirmation code.</p>
            )}
          </ChangePhone.TextContainer>

          <ChangePhone.InputContainer>
            {isConfirmation ? (
              <TextInput
                dark
                alphanumeric
                placeholder = "Code"
                name        = "code"
                onChange    = {(event) => setValue('code', event.target.value)}
              />
            ) : (
              <PhoneInput
                dark
                label       = "Phone Number"
                variant     = "filled"
                placeholder = "Phone Number"
                value       = {getValues('phoneNumber')}
                onChange    = {(value) => setValue('phoneNumber', value as string)}
              />
            )}
          </ChangePhone.InputContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

ChangePhone.TextContainer = styled.div`
  margin: 0 auto 50px;

  p {
    color     : ${({ theme: { colors } }) => colors.type.white};
    text-align: justify;
  }
`;

ChangePhone.InputContainer = styled.div`
  display       : flex;
  align-items   : center;
  flex-direction: column;
`;
