import { useCallback }                          from 'react';
import { useParams }                            from 'react-router-dom';
import { useFieldArray, useForm }               from 'react-hook-form';
import styled                                   from 'styled-components';
import { yupResolver }                          from '@hookform/resolvers/yup';
import {
  Button,
  Grid,
  IconButton,
  TextField
}                                               from '@mui/material';
import { scrimBlack }                           from '../../../../styles/theme';
import { ReactComponent as PlusIcon }           from '../../../../images/Icon-Add-Rubine.svg';
import { ReactComponent as RemoveIcon }         from '../../../../images/Icon-Remove-Grey.svg';
import { useCreateNonMemberInvitationMutation } from '../../../Profile/queries';
import { InputError }                           from '../../../../components/InputError';
import { Notification }                         from '../../../../components/Notification';
import {
  INonMemberInvitationForm,
  invitationDefaultValues,
  invitationFormValidationSchema
}                                               from '../../models/NonMemberInvite';

const MAX_EMAILS_NUMBER = 10;

interface IInviteFriendForm {
  rideLink      : string;
  onPostSubmit? : () => void;
}

export const InviteFriendForm = ({
  onPostSubmit,
  rideLink
}: IInviteFriendForm) => {
  const [createNonMemberInvitation, { isLoading }] = useCreateNonMemberInvitationMutation();
  const { rideId, rideDate }                       = useParams<{ rideId?: string; rideDate?: string; }>();

  const {
    control,
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors }
  } = useForm<INonMemberInvitationForm>({
    defaultValues: {
      ...invitationDefaultValues,
      plannedRideId: rideId,
      eventDate    : rideDate,
    },
    resolver: yupResolver(invitationFormValidationSchema)
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "emails"
  });

  const onSubmit = (values: INonMemberInvitationForm) => {
    createNonMemberInvitation({
      ...values,
      emails: values.emails.map(email => email.value)}
    )
    .unwrap()
    .then(
      () => {
        reset({ emails: [{ value: '' }], message: '' });

        onPostSubmit && onPostSubmit();
      },
    );
  };

  const addEmailField = useCallback(
    () => {
      if (getValues().emails.length !== MAX_EMAILS_NUMBER) {
        append({value: ''});
      }
    },
    [append, getValues]
  );

  const copyLink = useCallback(
    () => {
      navigator.clipboard.writeText(rideLink);
      Notification.enqueueSnackbar('Copied to clipboard', 'success');
    },
    // eslint-disable-next-line
    []
  );

  return (
    <InviteFriendForm.Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InviteFriendForm.Header>
          <b>Invite friends to Preem so they can join the ride.</b>
        </InviteFriendForm.Header>

        <Grid container rowSpacing="25px">
          {fields.map((_field, index) => (
            <Grid
              item
              container
              key = {index}
              xs  = {12}
            >
              <Grid
                item
                container
                xs={12}
                wrap          = "nowrap"
                alignItems    = "center"
                columnSpacing = "10px"
              >
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label   = "Email Address"
                    variant = "filled"
                    error   = {!!errors?.emails?.[index]?.value}
                    {...register(`emails.${index}.value`)}
                  />
                </Grid>

                {index > 0 &&
                  <Grid item xs="auto">
                    <IconButton color="secondary" onClick={() => remove(index)}>
                      <RemoveIcon />
                    </IconButton>
                  </Grid>
                }
              </Grid>

              <Grid item xs={12}>
                {errors?.emails?.[index] && <InputError error={errors?.emails?.[index]?.value?.message ?? ''} />}
              </Grid>
            </Grid>
          ))}
        </Grid>

        <InviteFriendForm.AnotherEmailBlock container columnSpacing="10px" p="25px 0 20px" alignItems="center">
          <Grid item>
            <IconButton onClick={addEmailField}>
              <PlusIcon />
            </IconButton>
          </Grid>

          <Grid item>
            <span><b>Add another Email</b></span>
          </Grid>
        </InviteFriendForm.AnotherEmailBlock>

        <TextField
          multiline
          fullWidth
          rows       = {2}
          label      = "Add a message..."
          variant    = "filled"
          error      = {!!errors?.message?.message}
          {...register('message')}
        />

        {!!errors?.message?.message && <InputError error={errors?.message?.message ?? ''} />}

        <InviteFriendForm.SendInviteBtn
          fullWidth
          variant  = "contained"
          type     = "submit"
          disabled = {isLoading}
        >
          Send Invite
        </InviteFriendForm.SendInviteBtn>

        <InviteFriendForm.BorderBlock container alignItems="center" wrap="nowrap">
          <Grid item xs={6}/>

          <Grid item xs="auto">
            <span><b>OR</b></span>
          </Grid>

          <Grid item xs={6}/>
        </InviteFriendForm.BorderBlock>

        <InviteFriendForm.Title>
          <b>Link to sign up</b>
        </InviteFriendForm.Title>

        <InviteFriendForm.LinkButton size="small" onClick={copyLink}>
          {rideLink}
        </InviteFriendForm.LinkButton>
      </form>
    </InviteFriendForm.Container>
  );
};

InviteFriendForm.Container = styled.div`
  background-color: ${({theme: {colors: {primary: {white}}}}) => white};
  border-radius   : 8px;
  margin          : 0 auto;
  width           : 352px;
  max-width       : 99%;
  max-height      : 80%;
  overflow        : hidden;

  form {
    height    : 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding   : 40px 24px;
  }

  .MuiIconButton-root {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 4%), 0 1px 2px 0 rgb(0 0 0 / 4%);
    width     : 32px;
    height    : 32px;
  }
`;

InviteFriendForm.Title = styled.div`
  text-align: center;
`;

InviteFriendForm.Header = styled(InviteFriendForm.Title)`
  font-size: ${({theme: {fontSizes: {xl}}}) => xl};
  padding  : 0 10px 35px;
`;

InviteFriendForm.AnotherEmailBlock = styled(Grid)`
  span {
    font-size: 13px;
    color    : ${({theme: {colors: {primary: {rubineLight}}}}) => rubineLight};
  }

  .MuiIconButton-root:hover {
    transform       : scale(1.05);
    box-shadow      : 0 4px 16px 0 rgb(0 0 0 / 16%);
    background-color: ${({theme: {colors: {primary: {white}}}}) => white};
  }
`;

InviteFriendForm.SendInviteBtn = styled(Button)`
  &.MuiButton-root {
    margin         : 25px 0;
    justify-content: center;
  }
`;

InviteFriendForm.BorderBlock = styled(Grid)`
  font-size     : 10px;
  color         : rgba(0, 0, 0, 0.32);
  padding-bottom: 17px;

  span {
    padding: 4px 8px;
  }

  .MuiGrid-item:first-child, .MuiGrid-item:last-child {
    height       : 1px;
    border-bottom: 1px solid ${scrimBlack(0.08)};
  }
`;

InviteFriendForm.LinkButton = styled(Button)`
  &.MuiButton-root {
    overflow-wrap: anywhere;
    line-height  : 14px;
    margin       : 8px 0 -24px;
  }
`;
