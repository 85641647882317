import styled                 from 'styled-components';
import { Grid }               from '@mui/material';
import { scrimBlack }         from '@styles/theme';
import {
  IConfigCell,
  IRowAction,
  ITableAction,
  TableData
}                             from '@components/TableData';
import { IDropdownOption }    from '@components/RoundedDropdownInput';
import { TOrderSortType }     from '@models/types';
import { IRouteTableRow }     from '@models/RouteModel';
import { Layout }             from '../../components/Layout';
import { PageHeader }         from '../../components/PageHeader';
import { PageDescription }    from '../../components/PageDescription';
import {
  IRoutesFilters,
  RouteFiltersModal
}                             from './components/RouteFiltersModal';
import { RoutesUploader }     from './components/RoutesUploader';
import { EmptyTable }         from './components/EmptyTable';
import { TMeasurementSystem } from '../../models/Settings';

interface IRoutesPageProps {
  data                  : IRouteTableRow[];
  configCells            : IConfigCell<IRouteTableRow>[];
  isLoading              : boolean;
  rowActions             : IRowAction<IRouteTableRow>[];
  onSort                 : (orderBy: string, order: TOrderSortType) => void;
  onSelectRow            : (id: string) => void;
  selectedItems          : string[];
  onSelectAll            : (items: IRouteTableRow[]) => void;
  totalPages             : number;
  totalElements          : number;
  setPageNumber          : (pageNumber: number) => void;
  tableActions           : ITableAction[];
  refetch                : () => void;
  measurementSystem      : TMeasurementSystem;
  areFiltersOpen         : boolean;
  setFiltersOpen         : (isOpen: boolean) => void;
  onSaveFilters          : (values: IRoutesFilters) => void;
  searchText             : string;
  setSearchText          : (value: string) => void;
  locationOptions        : IDropdownOption[];
  selectedLocation       : IDropdownOption | null;
  onSelectLocation       : (option: IDropdownOption | null) => void;
  isRideWithGpsConnected : boolean;
  handleImportRideWithGps: () => void;
  orderBy?               : string;
  order?                 : TOrderSortType;
}

export const RoutesPage = ({
  data,
  configCells,
  isLoading,
  rowActions,
  orderBy,
  order,
  onSort,
  onSelectRow,
  selectedItems,
  onSelectAll,
  totalPages,
  totalElements,
  setPageNumber,
  tableActions,
  refetch,
  measurementSystem,
  areFiltersOpen,
  setFiltersOpen,
  onSaveFilters,
  searchText,
  setSearchText,
  locationOptions,
  selectedLocation,
  onSelectLocation,
  isRideWithGpsConnected,
  handleImportRideWithGps,
}: IRoutesPageProps) => (
  <Layout>
    <Grid container justifyContent="space-between">
      <Grid item xs={12}>
        <PageHeader>My Routes</PageHeader>
      </Grid>

      <RoutesPage.Header item container xs={12} sm={5}>
        <PageDescription>
          {`Preem uses the rides you’ve already done to a create a curated list of your best routes, making it easy to plan your favorite rides with friends.`}
        </PageDescription>
      </RoutesPage.Header>

      <RoutesUploader
        isRideWithGpsConnected  = {isRideWithGpsConnected}
        refetch                 = {refetch}
        handleImportRideWithGps = {handleImportRideWithGps}
      />
    </Grid>

    <Grid container>
      <TableData
        configCells         = {configCells}
        data                = {data}
        isLoading           = {isLoading}
        rowActions          = {rowActions}
        tableActions        = {tableActions}
        onSelectRow         = {onSelectRow}
        onSelectAll         = {onSelectAll}
        selectedItems       = {selectedItems}
        order               = {order}
        orderBy             = {orderBy}
        onSort              = {onSort}
        searchText          = {searchText}
        setSearchText       = {setSearchText}
        searchPlaceholder   = "Route title"
        title               = "Routes"
        emptyTable          = {EmptyTable}
        totalPages          = {totalPages}
        totalElements       = {totalElements}
        setPageNumber       = {setPageNumber}
        actionButtonLabel   = "Filter Results"
        onActionButtonClick = {() => setFiltersOpen(true)}
        dropdownItem        = {selectedLocation}
        dropdownPlaceholder = "Select Location"
        dropdownOptions     = {locationOptions}
        onDropdownChange    = {onSelectLocation}
      />
    </Grid>

    <RouteFiltersModal
      isOpen            = {areFiltersOpen}
      measurementSystem = {measurementSystem}
      onClose           = {() => setFiltersOpen(false)}
      onSubmit          = {onSaveFilters}
    />
  </Layout>
);

RoutesPage.Header = styled(Grid)`
  padding-right: 60px;
`;

RoutesPage.ActivitiesLabel = styled.div`
  font-size     : 15px;
  padding-bottom: 20px;

  span {
    font-size: ${({theme: {fontSizes: {l}}}) => l};
  }

  ${({theme: {mediaQueries: {sm}}}) => sm} {
    font-size: ${({theme: {fontSizes: {xl}}}) => xl};

    span {
      font-size: 22px;
    }
  }
`;

RoutesPage.EmptyContainer = styled(Grid)`
  height      : 500px;
  align-items : center;
  
  &.MuiGrid-root {
    background-color: ${scrimBlack(0.04)};
    font-weight     : 500;
    margin-bottom   : 64px;
  }
`;
