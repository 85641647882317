import * as Yup       from 'yup';
import { capitalize } from 'lodash';
import { add }        from 'date-fns';
import {
  IRide,
  ELocationType,
  EFrequencyType,
  EManagementType,
  ERideTypes
}                     from '@modules/Details/models/ride';
import {
  getDayPeriod,
  setCurrentTimeToUTC
}                     from '@utils/time_utils';

export interface IRideForm extends Omit<IRide, 'rideDate' | 'startLocation' | 'timeZoneId' | 'status' | 'date'> {
  startDate   : Date;
  startTime   : Date;
  routeId     : string | undefined;
  inviteeIds  : string[];
}

export const FRideForm = (data: Partial<IRideForm> & { date?: string }): IRideForm => {
  const defaultDate = data.date ? new Date(data.date) : add(new Date(), { hours: 1 });

  return ({
    name            : data.name || `${capitalize(getDayPeriod(defaultDate))} Ride`,
    description     : data.description ?? '',
    isPublic        : data.isPublic ?? false,
    hasStops        : data.hasStops ?? false,
    canRegroup      : data.canRegroup ?? false,
    startDate       : defaultDate,
    startTime       : defaultDate,
    duration        : data.duration,
    intensity       : data.intensity || '',
    frequency       : data.frequency || EFrequencyType.ONCE,
    management      : data.management || EManagementType.USER,
    rideType        : data.rideType || '' as ERideTypes,
    hasDrops        : data.hasDrops ?? true,
    inviteeIds      : data.inviteeIds ?? [],
    rideLocationType: data.rideLocationType || ELocationType.OUTDOOR,
    routeId         : data.routeId
  });
}

export type TCreateRideSubmit = Omit<IRide, 'startLocation' | 'timeZoneId' | 'routeDetails' | 'status' | 'routeType'>

export const FCreateRideSubmit = (data: IRideForm): TCreateRideSubmit => {
  const rideDate     = data.startDate;
  const rideTime     = data.startTime;
  rideDate.setHours(rideTime.getHours());
  rideDate.setMinutes(rideTime.getMinutes());
  rideDate.setSeconds(0);
  rideDate.setMilliseconds(0);


  return {
    name             : data.name,
    description      : data.description,
    rideType         : data.rideType || null,
    hasDrops         : data.hasDrops,
    intensity        : data.intensity,
    frequency        : data.frequency,
    hasStops         : data.hasStops,
    canRegroup       : data.canRegroup,
    management       : data.management,
    isPublic         : data.isPublic,
    routeId          : data.routeId,
    inviteeIds       : data.inviteeIds,
    date             : setCurrentTimeToUTC(rideDate),
    duration         : data.duration,
    rideLocationType : data.rideLocationType || ELocationType.OUTDOOR,
    startLocationId  : data.startLocationId,
  };
};

export type TUpdateRideSubmit = TCreateRideSubmit & { id: string; }

export const FUpdateRideSubmit = (data: IRideForm): TUpdateRideSubmit => ({
  id: data.id ?? '',
  ...FCreateRideSubmit(data)
});

export const rideValidationSchema = Yup.object().shape({
  isPublic   : Yup.boolean(),
  name       : Yup.string().required('Name is required'),
  description: Yup.string().when('isPublic', {
    is       : true,
    then     : Yup.string().required('Description is required'),
    otherwise: Yup.string(),
  }),
  rideType: Yup.string().when('isPublic', {
    is       : true,
    then     : Yup.string().required('Ride Type is required'),
    otherwise: Yup.string(),
  }),
  startDate: Yup.date().required('Start Date is required'),
  startTime: Yup.date().required('Start Time is required'),
  intensity: Yup.number().nullable(true).transform((v) => (v === '' || Number.isNaN(v) ? null : v)).when('isPublic', {
    is       : true,
    then     : Yup.number().nullable(true).transform((v) => (v === '' || Number.isNaN(v) ? null : v)).required('Intensity is required'),
    otherwise: Yup.number().nullable(true).transform((v) => (v === '' || Number.isNaN(v) ? null : v))
  }),
  frequency : Yup.string().required('Frequency is required'),
  management: Yup.string().required('Management is required')
});
