import styled         from 'styled-components';
import { scrimBlack } from '@styles/theme';

interface IAvatarProps {
  photoUrl?  : string;
  size?      : number;
  withMargin?: boolean;
  name?      : string;
}

export const Avatar = ({
  photoUrl,
  size = 96,
  withMargin,
  name
}: IAvatarProps) => (
  <Avatar.Wrapper size={size} withMargin={withMargin} photoUrl={photoUrl}>
    {!photoUrl && (
      <div>
        {name?.substring(0, 1).toUpperCase()}
      </div>
    )}
  </Avatar.Wrapper>
);

Avatar.Wrapper = styled.div<{ size: number, withMargin?: boolean, photoUrl?: string }>`
  display            : flex;
  height             : ${({ size }) => `${size}px`};
  width              : ${({ size }) => `${size}px`};
  min-height         : ${({ size }) => `${size}px`};
  min-width          : ${({ size }) => `${size}px`};
  border             : 2px solid ${({theme: {colors: { primary: { white } } } }) => white};
  padding-top        : 2px;
  background-color   : ${({ theme: {colors: { primary: { white } } } }) => white};
  background-position: center;
  background-size    : cover;
  background-repeat  : no-repeat;
  justify-content    : center;
  align-items        : center;
  box-shadow         : inset 0 0 0 1px ${scrimBlack(0.08)}, 0 1px 2px 0  ${scrimBlack(0.08)}, 0 4px 8px 0  ${scrimBlack(0.08)};
  border-radius      : 100%;
  margin-bottom      : ${({ withMargin }) => withMargin && '8px'};
  font-size          : min(${({ size }) => Math.round(size * 0.8)}px, 28px);
  font-weight        : 500;
  color              : ${({ theme: { colors: { type: { medium } } } }) => medium};

  ${({ photoUrl }) => photoUrl && `
    background-image   : url(${photoUrl})
  `}
`;
