import {
  useCallback,
  useState,
}                           from 'react';
import styled               from 'styled-components';
import { EnrichedUser }     from 'getstream';
import {
  StreamApp,
  FlatFeed,
  Activity,
  InfiniteScrollPaginator,
  useFeedContext,
}                           from 'react-activity-feed';
import { FeedPlaceholder }  from 'react-activity-feed/dist/components/FeedPlaceholder';
import { Button, Grid }     from '@mui/material';
import {
  getStreamAppId,
  getStreamKey,
}                           from '@config/api_config';
import { Loader }           from '@components/Loader';
import { useWindowSize }    from '@utils/hooks/useWindowSize';
import { Header }           from '@modules/Feed/components/Post/components/Header';
import { Content }          from '@modules/Feed/components/Post/components/Content';
import { Footer }           from '@modules/Feed/components/Post/components/Footer';
import {
  useGetGetStreamTokenQuery,
  useGetSelfInformationQuery,
}                           from '@modules/Profile/queries';
import { withAuth }         from '@utils/HOCs/withAuth';
import {
  EPostType,
  TActivityUserType,
  IPost
}                           from '@models/Feed/post';
import { AddPost }          from './components/AddPost';
import { PostModal }        from './components/PostModal';

const FeedBase = () => {
  const { isMobile }                  = useWindowSize();
  const { data: self }                = useGetSelfInformationQuery();
  const { data: tokenData }           = useGetGetStreamTokenQuery();
  const [addPostType, setAddPostType] = useState<EPostType | null>(null);
  const [postToEdit, setPostToEdit]   = useState<IPost | null>(null);

  const handleClosePostModal = useCallback(
    (reason?: string) => {
      if (reason !== 'backdropClick') {
        setAddPostType(null);
        setPostToEdit(null);
      }
    },
    []
  );

  return (
    <FeedBase.Wrapper container padding={isMobile ? '0 6%' : '0 8%'} spacing={8}>
      <Grid item xs={12} sm={7}>
        <StreamApp
          apiKey = {getStreamKey ?? ''}
          appId  = {getStreamAppId ?? ''}
          token  = {tokenData?.token ?? ''}
        >
          <AddPost onAddPost={setAddPostType} />

          {addPostType && (
            <PostModal
              postType   = {addPostType}
              postToEdit = {null}
              onClose    = {handleClosePostModal}
            />
          )}

          <FlatFeed<TActivityUserType>
            notify
            feedGroup                = "owner"
            options                  = {{ limit: 10, withOwnChildren: true, withRecentReactions: true, enrich: true, recentReactionsLimit: 3, withOwnReactions: true }}
            LoadingIndicator         = {<Loader />}
            Placeholder              = {<FeedPlaceholder text="Nothing to display..." style={{ width: '100%', textAlign: 'center', fontStyle: 'italic', background: 'transparent' }} />}
            Notifier                 = {notifyProps => notifyProps.adds?.length ? (
              <FeedBase.NotifierWrapper>
                <Button
                  color   = "primary"
                  variant = "contained"
                  onClick = {notifyProps.onClick}
                >
                  {`You have ${notifyProps.adds?.length} new activities`}
                </Button>
              </FeedBase.NotifierWrapper>
              ) : null}
            Paginator = {(paginatorProps) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const feed = useFeedContext();

              return (
                <>
                  <InfiniteScrollPaginator Loader={<Loader />} {...paginatorProps} />

                  {postToEdit && (
                    <PostModal
                      postType   = {null}
                      postToEdit = {postToEdit}
                      refresh    = {feed.refresh}
                      onClose    = {handleClosePostModal}
                    />
                  )}
                </>
              )
            }}
            Activity  = {activityProps => {
              const userData = (activityProps.activity.actor as EnrichedUser).data as unknown as TActivityUserType | void;

              return (
                <Activity
                  {...activityProps}
                  Header  = {
                    <Header
                      id                = {activityProps.activity.id}
                      profilePictureUrl = {userData?.profilePictureUrl}
                      username          = {userData?.username ?? 'NoUsername'}
                      displayName       = {userData?.displayName ?? 'Post Author'}
                      time              = {activityProps.activity.time}
                      location          = {activityProps.activity.location as MapboxGeocoder.Result | null}
                      isOwnPost         = {self?.id === (activityProps.activity.actor as { id: string }).id || self?.id === activityProps.activity.actor}
                      onEditPost        = {() => setPostToEdit(activityProps.activity as unknown as IPost)}
                    />
                  }
                  Content = {<Content activity={activityProps.activity as unknown as IPost} />}
                  Footer  = {<Footer activity={activityProps.activity as unknown as IPost} />}
                />
              )
            }}
          />
        </StreamApp>
      </Grid>

      <Grid item xs={12} sm={5}>
      </Grid>
    </FeedBase.Wrapper>
  )
}

FeedBase.Wrapper = styled(Grid)`
  height          : 100%;
  margin-top      : 80px;
  background-color: ${({ theme }) => theme.colors.neutralGrey[200]};

  .raf-user-bar {
    display: none;
  }
`;

FeedBase.NotifierWrapper = styled.div`
  width          : 100%;
  display        : flex;
  justify-content: center;
  align-items    : center;
`;

FeedBase.Placeholder = styled.div`
  width     : 100%;
  text-align: center;
  font-style: italic;
`;

export const Feed = withAuth(FeedBase);
