import styled, { css } from 'styled-components';
import { scrimBlack }  from '../../../../../../styles/theme';

interface ITabsetDayProps {
  groupRideCount    : number;
  selectedTab       : TabType | null;
  inviteCount       : number;
  myRideCount       : number;
  pendingInvitations: boolean;
  onTabClick        : (tabClicked: TabType) => void;
  isMobile?         : boolean;
}

export enum TabType {
  MY_RIDES,
  INVITATIONS,
  GROUP_RIDES,
}

export const TabsetDay = ({
  groupRideCount,
  inviteCount,
  myRideCount,
  pendingInvitations,
  onTabClick,
  selectedTab = TabType.MY_RIDES,
  isMobile,
}: ITabsetDayProps) => {
  const handleTabClick = (tabClicked: TabType) => onTabClick(tabClicked);

  return (
    <TabsetDay.Container isMobile={!!isMobile}>
      <TabsetDay.Link
        onClick  = {() => handleTabClick(TabType.MY_RIDES)}
        isMobile = {!!isMobile}
        selected = {selectedTab === TabType.MY_RIDES}
      >
        <TabsetDay.Indicator selected={selectedTab === TabType.MY_RIDES} isMobile={!!isMobile}>
          {myRideCount}
        </TabsetDay.Indicator>

        <TabsetDay.Label isMobile={!!isMobile}>My Rides</TabsetDay.Label>

        {!isMobile && <TabsetDay.Highlight selected={selectedTab === TabType.MY_RIDES} />}
      </TabsetDay.Link>

      <TabsetDay.Link
        onClick  = {() => handleTabClick(TabType.INVITATIONS)}
        isMobile = {!!isMobile}
        selected = {selectedTab === TabType.INVITATIONS}
      >
        {pendingInvitations && (
          <TabsetDay.PendingIndicator>&nbsp;</TabsetDay.PendingIndicator>
        )}

        <TabsetDay.Indicator selected={selectedTab === TabType.INVITATIONS} isMobile={!!isMobile}>
          {inviteCount}
        </TabsetDay.Indicator>

        <TabsetDay.Label isMobile={!!isMobile}>Invitations</TabsetDay.Label>

        {!isMobile && <TabsetDay.Highlight selected={selectedTab === TabType.INVITATIONS} />}
      </TabsetDay.Link>

      <TabsetDay.Link
        onClick  = {() => handleTabClick(TabType.GROUP_RIDES)}
        isMobile = {!!isMobile}
        selected = {selectedTab === TabType.GROUP_RIDES}
      >
        <TabsetDay.Indicator selected={selectedTab === TabType.GROUP_RIDES} isMobile={!!isMobile}>
          {groupRideCount}
        </TabsetDay.Indicator>

        <TabsetDay.Label isMobile={!!isMobile}>Group Rides</TabsetDay.Label>

        {!isMobile && <TabsetDay.Highlight selected={selectedTab === TabType.GROUP_RIDES} />}
      </TabsetDay.Link>
    </TabsetDay.Container>
  );
};

TabsetDay.Container = styled.div<{ isMobile: boolean; }>`
  display   : flex;
  margin-top: auto;
  align-self: stretch;

  ${({ isMobile }) => isMobile && css`
    width         : fit-content;
    flex-direction: column;
    align-items   : start;
  `};
`;

TabsetDay.Highlight = styled.div<{ selected: boolean }>`
  width                  : 100%;
  height                 : 3px;
  border-top-left-radius : 2px;
  border-top-right-radius: 2px;
  transition             : all 300ms ease-in-out;
  flex                   : 0 0 auto;
  background-color       : ${({ selected }) => selected && '#00518f'};
`;

TabsetDay.Link = styled.a<{ selected: boolean; isMobile: boolean; sticky?: boolean; }>`
  display        : flex;
  height         : ${({ sticky }) => (sticky ? '64px' : '80px')};
  margin-right   : 0;
  margin-left    : 0;
  flex-direction : ${({ isMobile }) => isMobile ? 'row' : 'column'};
  justify-content: ${({ isMobile }) => isMobile ? 'center' : 'flex-end'};
  align-items    : center;
  flex           : 1;
  color          : ${({ selected }) => (selected ? '#00518f' : '#77787a')};
  font-size      : 13px;
  line-height    : 100%;
  font-weight    : 700;
  text-decoration: none;
  transition     : all 300ms ease-in-out;

  &:hover {
    cursor: pointer;
    color : ${({ selected }) => (selected ? '#00518f' : '#ed0d6c')};

    & ${TabsetDay.Highlight} {
      background-color: ${({ selected }) => (selected ? '#00518f' : '#ed0d6c')};
    }
  }

  ${({ isMobile }) => isMobile && css`
    gap          : 8px;
    margin-bottom: 8px;
  `};
`;

TabsetDay.Indicator = styled.div<{ selected: boolean; sticky?: boolean; isMobile?: boolean }>`
  position        : relative;
  display         : flex;
  width           : ${({ sticky, isMobile }) => (sticky || isMobile ? '32px' : '40px')};
  height          : ${({ sticky, isMobile }) => (sticky || isMobile ? '32px' : '40px')};
  justify-content : center;
  align-items     : center;
  flex            : 0 0 auto;
  border-radius   : 20px;
  background-color: ${({ selected }) => selected ? '#fff' : `${scrimBlack(0.08)}`};
  font-size       : 14px;

  ${({ isMobile }) => !isMobile && css`
    margin-bottom: 8px;
  
    &:last-of-type {
      margin-bottom: 0;
    }
  `}
`;

TabsetDay.PendingIndicator = styled.div`
  background-color: ${({theme})=>theme.colors.primary.rubineLight};
  position        : relative;
  border-radius   : 100%;
  border          : 2px solid ${({theme})=>theme.colors.primary.white};
  z-index         : 1;
  height          : 10px;
  width           : 10px;
  left            : 14px;
  top             : 12px;
`;

TabsetDay.Label = styled.div<{ sticky?: boolean; isMobile?: boolean; }>`
  padding-bottom: ${({ sticky, isMobile }) => !isMobile && (sticky ? '8px' : '16px')};
`;
