import {
  useCallback,
  useEffect,
  useState
}                                              from 'react';
import { useDispatch, useSelector }            from 'react-redux';
import styled                                  from 'styled-components';
import {
  usePopupState,
  bindHover,
  bindPopper,
}                                              from 'material-ui-popup-state/hooks';
import { Grow, Popper }                        from '@mui/material';
import { format }                              from 'date-fns';
import { getWeatherConditionClassName }        from '@utils/string_utils';
import {
  currentLocation,
  setCurrentLocation,
}                                              from '@store/reducers/location';
import { scrimBlack }                          from '@styles/theme';
import {
  useCreateUserLocationMutation,
  useGetUserGenLocationsQuery,
}                                              from '@modules/Profile/queries';
import { ContentHeroLocationSelectorDropdown } from '@modules/Calendar/components/ContentHero/components/ContentHeroLocationSelectorDropdown';
import { useGetWeatherByGenLocationIdQuery }   from '@modules/Calendar/queries';
import { IGenLocation }                        from '@modules/Profile/models/genLocation';
import { getGenLocationById }                  from '@modules/Calendar';
import {
  ISaveLocationData,
  LocationModal,
}                                              from '@components/LocationModal';
import { WeatherIcon }                         from '@components/WeatherIcon';
import DropdownArrowWhite                      from '@images/Icon-Dropdown-Arrow-White.svg';

export const ContentHeroLocationSelector = () => {
  const contentHeroLocation: IGenLocation       = useSelector(currentLocation);
  const [heroWeatherIcon, setHeroWeatherIcon]   = useState('');
  const [heroLocationName, setHeroLocationName] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<IGenLocation>(contentHeroLocation);
  const [isModalOpen, setIsModalOpen]           = useState(false);
  const dispatch                                = useDispatch();
  const {
    data    : genLocations,
    refetch : refetchGenLocations
  }                                             = useGetUserGenLocationsQuery();
  const [createLocation]                        = useCreateUserLocationMutation();
  const { data: weather }                       = useGetWeatherByGenLocationIdQuery({ genLocationId: selectedLocation.id }, { skip: !selectedLocation.id });

  useEffect(
    () => {
      setSelectedLocation(contentHeroLocation);
    },
    [contentHeroLocation]
  );

  useEffect(
    () => {
      setHeroWeatherIcon(weather && weather[0] ? getWeatherConditionClassName(weather[0].weatherCode) : '');
      setHeroLocationName(selectedLocation?.name ?? '');
    },
    [weather, selectedLocation]
  );

  const handleSaveLocation = useCallback(
    (data: ISaveLocationData) => {
      createLocation({ ...data, isPrimary: true }).then(
        (response: any) => {
          /** TODO: remove any */
          const newLocation = response.data;

          if (newLocation) {
            getGenLocationById(newLocation.genLocationId).then((genLocation: IGenLocation) => {
              refetchGenLocations();
              dispatch(setCurrentLocation(genLocation));
              setSelectedLocation(genLocation);
            })
          }
        }
      );
    },
    [createLocation, dispatch, refetchGenLocations]
  );

  const handleLocationClick = (genLocation: IGenLocation) => {
    dispatch(setCurrentLocation(genLocation));
    setSelectedLocation(genLocation);

    popupState.close();
  };

  const handleAddLocationClick = () => {
    setIsModalOpen(true);
  };

  const popupState = usePopupState({
    variant: "popper",
    popupId: "contentHeroLocationPicker",
  });

  return (
    <>
      <ContentHeroLocationSelector.DropdownToggle {...bindHover(popupState)}>
        {heroWeatherIcon && (
          <WeatherIcon condition={heroWeatherIcon} contentHero={true} />
        )}
        <ContentHeroLocationSelector.HeroLocationDate>
          <ContentHeroLocationSelector.LabelWithArrow>
            <ContentHeroLocationSelector.LabelText>
              {heroLocationName}
            </ContentHeroLocationSelector.LabelText>
            <ContentHeroLocationSelector.DropdownArrow
              flipped={popupState.isOpen}
            />
          </ContentHeroLocationSelector.LabelWithArrow>
          <ContentHeroLocationSelector.DateText>
            {format(new Date(), "eeee MMMM d, yyyy")}
          </ContentHeroLocationSelector.DateText>
        </ContentHeroLocationSelector.HeroLocationDate>
        <ContentHeroLocationSelector.DropdownMenu
          disablePortal
          placement="bottom-start"
          transition
          {...bindPopper(popupState)}
        >
          {({ TransitionProps }) => (
            <Grow in={popupState.isOpen} timeout={300} {...TransitionProps}>
              <ContentHeroLocationSelector.DropdownLocationList>
                <ContentHeroLocationSelectorDropdown
                  clickHandler        = {handleLocationClick}
                  contentHeroLocation = {selectedLocation}
                  locationOptions     = {genLocations || []}
                />
                <AddLocationLink onClick={handleAddLocationClick}>
                  Add a New Location
                </AddLocationLink>
              </ContentHeroLocationSelector.DropdownLocationList>
            </Grow>
          )}
        </ContentHeroLocationSelector.DropdownMenu>
      </ContentHeroLocationSelector.DropdownToggle>

      {isModalOpen && (
        <LocationModal
          isOpen
          key     = {`${new Date()}`}
          onClose = {() => {
            setIsModalOpen(false);
            popupState.close();
          }}
          handleSaveLocation={handleSaveLocation}
        />
      )}
    </>
  );
};

ContentHeroLocationSelector.DropdownToggle = styled.div`
  display        : flex;
  margin-right   : 24px;
  margin-left    : 0px;
  padding        : 0px 4px 2px;
  justify-content: flex-start;
  align-items    : stretch;
  align-self     : flex-start;
  flex           : 0 0 auto;
  border-radius  : 8px;
  transition     : background-color 200ms ease-in-out;
  color          : ${({ theme }) => theme.colors.type.medium};
  line-height    : 100%;
  font-weight    : 600;

  &:hover {
    cursor          : pointer;
    background-color: ${scrimBlack(0.08)};
  }
  &:active {
    background-color: ${scrimBlack(0.16)};
  }
  @media screen and (max-width: 767px) {
    padding-right : 10px;
    padding-bottom: 10px;
  }
`;

ContentHeroLocationSelector.DropdownMenu = styled(Popper)`
  border-radius : 8px;
  z-index       : 1000;
  padding-top   : 0px;
  padding-bottom: 0px;
`;

export const AddLocationLink = styled.a`
  align-items               : center;
  height                    : 56px;
  background                : ${({ theme }) => theme.colors.neutralGrey[100]};
  display                   : flex;
  justify-content           : center;
  color                     : ${({ theme }) => theme.colors.primary.rubineLight};
  font-size                 : 14px;
  font-style                : normal;
  font-weight               : 700;
  line-height               : 14px;
  border-bottom-left-radius : 8px;
  border-bottom-right-radius: 8px;
  &:hover {
    color : ${({ theme }) => theme.colors.primary.rubineDark};
    cursor: pointer;
  }
`;

ContentHeroLocationSelector.DropdownLocationList = styled.div`
  min-width       : 350px;
  border-radius   : 8px;
  background-color: ${({ theme }) => theme.colors.primary.white};
  box-shadow    : 0 2px 8px 0 rgb(0 0 0 / 4%), 0 4px 16px 0 rgb(0 0 0 / 8%);
`;

ContentHeroLocationSelector.HeroLocationDate = styled.div`
  display       : flex;
  flex-direction: column;
  align-items   : flex-start;
  align-self    : center;
  flex          : 0 0 auto;
`;

interface IDropdownArrowProps {
  flipped: boolean;
}

ContentHeroLocationSelector.DropdownArrow = styled.img.attrs({
  src: `${DropdownArrowWhite}`,
})<IDropdownArrowProps>`
  height    : 32px;
  width     : 32px;
  transition: all 300ms ease-in-out;
  ${({ flipped }) => flipped && "transform:rotate(180deg)"}
`;

ContentHeroLocationSelector.LabelWithArrow = styled.div`
  display    : flex;
  align-items: center;
  flex       : 0 0 auto;
`;

ContentHeroLocationSelector.LabelText = styled.div`
  color         : ${({ theme }) => theme.colors.primary.white};
  font-size     : 28px;
  line-height   : 108%;
  font-weight   : 900;
  text-transform: uppercase;
  margin-bottom : 0px;
  white-space   : pre-line;
`;

ContentHeroLocationSelector.DateText = styled.div`
  color         : ${({ theme }) => theme.colors.primary.white};
  opacity       : 0.64;
  font-size     : 11px;
  line-height   : 140%;
  font-weight   : 500;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;
