import styled from "styled-components";

import { scrimBlack } from "../../../../styles/theme";

interface IPhotoCredit{
    creditText: string;
    creditLink: string;
    top?      : string;
    right?    : string;
}

export const PhotoCredit = ({creditText, creditLink, top, right}:IPhotoCredit) =>{
    return (
      <PhotoCredit.Link
        href   = {creditLink}
        target = "_blank"
        top    = {top}
        right  = {right}
      >
        {creditText}
      </PhotoCredit.Link>
    );
}

PhotoCredit.Link = styled.a<{right?: string, top?: string}>`
    background     : ${scrimBlack(0.48)};
    border-radius  : 16px;
    color          : ${({theme})=>theme.colors.primary.white};
    display        : block;
    font-size      : 14px;
    font-weight    : 700;
    height         : 32px;
    line-height    : 32px;
    opacity        : 0.64;
    padding        : 0 16px;
    position       : absolute;
    text-decoration: none;
    right          : ${({ right })=> right? `${right}` : "10%"};
    top            : ${({ top })=> top? `${top}` : "90%"};
    z-index        : 500;
    transition     : all 200ms ease-in-out;

    &:hover{
      opacity         : 1;
      background-color: ${({theme})=>theme.colors.primary.rubineLight};
    }
`;