import axios          from "axios";
import { apiOrigin }  from "../config/api_config";
import { auth }       from "../modules/Auth/adapters/firebase";

const SELF_ENDPOINT                    = "/api/v1/users/self";
const PRIMARY_LOCATION_ENDPOINT        = "/api/v1/locations/primary";
const CTA_RIDE_HISTORY_ENDPOINT        = "/api/v1/cta/import_ride_history";
const CTA_PROFILE_COMPLETED_ENDPOINT   = "/api/v1/cta/complete_profile";
const CTA_CONNECTED_WITH_APPS_ENDPOINT = "/api/v1/cta/connect_with_apps";

/**
 * @decription Call the /self endpoint to determine if user has set a preem handle
 * @return {Promise<boolean>} returns true if handle exists, false if not
 * This method DOES NOT return the preem handle, just whether it has been set
 */
export function currentUserHasPreemHandle(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    if (auth.currentUser !== null) {
      auth.currentUser
        .getIdToken()
        .then((result) => {
          const token = result;
          axios
            .get(`${apiOrigin}${SELF_ENDPOINT}`, {
              headers: { "X-Preem-AccessToken": token },
            })
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              resolve(false);
            });
        })
        .catch(() => {
          resolve(true);
        });
    } else {
      reject();
    }
  });
}

/**
 * @description Call the /locations/primary endpoint to determine if user has set a primary location
 * @return {Promise<boolean>} returns true if primary location exists, false if not
 * This method DOES NOT return the location, just whether it has been set
 */
export function currentUserHasPrimaryLocation(): Promise<boolean> {
  return new Promise((resolve, _reject) => {
    if (auth.currentUser !== null) {
      auth.currentUser?.getIdToken().then((result) => {
        const token = result;
        axios
          .get(`${apiOrigin}${PRIMARY_LOCATION_ENDPOINT}`, {
            headers: { "X-Preem-AccessToken": token },
          })
          .then((result) => {
            if (result.status === 200) {
              resolve(true);
            } else if (result.status === 204) {
              resolve(false);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response, error.response.status);
            }
            resolve(false);
          });
      });
    } else {
      resolve(false);
    }
  });
}

/**
 * @description Check if the user has imported ride files
 * anything other than an explicit TRUE response will resolve as false
 * to err on the side of showing the prompt to the user if we can't get an answer from the API
 *
 * @return  {Promise<boolean>} true if history has been imported, false if not
 * */
export function checkRideHistoryImported(): Promise<boolean> {
  return new Promise((resolve, _reject) => {
    if (auth.currentUser !== null) {
      auth.currentUser?.getIdToken().then((result) => {
        const token = result;
        axios
          .get(`${apiOrigin}${CTA_RIDE_HISTORY_ENDPOINT}`, {
            headers: { "X-Preem-AccessToken": token },
          })
          .then((result) => {
            if (result.status === 200) {
              // result.data should be true/false
              resolve(result.data);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response, error.response.status);
            }
            resolve(false);
          });
      });
    } else {
      resolve(false);
    }
  });
}
/**
 * @description Check if the user has completed their profile
 * anything other than an explicit TRUE response will resolve as false
 * to err on the side of showing the prompt to the user if we can't get an answer from the API
 *
 * @return  {Promise<boolean>} true if profile is completed, false if not
 * */
export function checkProfileCompleted(): Promise<boolean> {
  return new Promise((resolve, _reject) => {
    if (auth.currentUser !== null) {
      auth.currentUser?.getIdToken().then((result) => {
        const token = result;
        axios
          .get(`${apiOrigin}${CTA_PROFILE_COMPLETED_ENDPOINT}`, {
            headers: { "X-Preem-AccessToken": token },
          })
          .then((result) => {
            if (result.status === 200) {
              // result.data should be true/false
              resolve(result.data);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response, error.response.status);
            }
            resolve(false);
          });
      });
    } else {
      resolve(false);
    }
  });
}
/**
 * @description Check if the user has completed their profile
 * anything other than an explicit TRUE response will resolve as false
 * to err on the side of showing the prompt to the user if we can't get an answer from the API
 *
 * @return  {Promise<boolean>} true if profile is completed, false if not
 * */
export function checkAppsConnected(): Promise<boolean> {
  return new Promise((resolve, _reject) => {
    if (auth.currentUser !== null) {
      auth.currentUser?.getIdToken().then((result) => {
        const token = result;
        axios
          .get(`${apiOrigin}${CTA_CONNECTED_WITH_APPS_ENDPOINT}`, {
            headers: { "X-Preem-AccessToken": token },
          })
          .then((result) => {
            if (result.status === 200) {
              // result.data should be true/false
              resolve(result.data);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response, error.response.status);
            }
            resolve(false);
          });
      });
    } else {
      resolve(false);
    }
  });
}

export function getUserStatus(): Promise<userStatus> {
  return new Promise((resolve, _reject) => {
    let needsHandle = false;
    let needsLocation = false;
    currentUserHasPreemHandle()
      .then((result) => {
        needsHandle = !result;
        currentUserHasPrimaryLocation()
          .then((result) => {
            needsLocation = !result;
          })
          .catch((_error) => {
            needsLocation = true;
          })
          .finally(() => {
            resolve({
              loggedIn: true,
              needsHandle: needsHandle,
              needsLocation: needsLocation,
            });
          });
      })
      .catch(() => {
        // if there is no user status, return these values so app can re-route to signup
        resolve({ loggedIn: false, needsHandle: true, needsLocation: true });
      });
  });
}
type userStatus = {
  loggedIn     : boolean;
  needsHandle  : boolean;
  needsLocation: boolean;
};

/**
 * @description   Get the display name of the authenticated user
 * @function
 * @return  {string}  Return the display name of the authenticated user
 */
export function getDisplayName(): string {
  let displayName = "";
  if (auth && auth.currentUser && auth.currentUser.displayName) {
    return auth.currentUser.displayName;
  }
  return displayName;
}

/**
 * @description   Get the first letter of the display name of the authenticated user
 * @function
 * @return  {string}  Return the first letter of the display name of the authenticated user
 */
export function getDisplayNameInitial(): string {
  let displayNameInitial = "";
  if (auth && auth.currentUser && auth.currentUser.displayName) {
    return auth.currentUser.displayName.substring(0, 1);
  }
  return displayNameInitial;
}
