import { IconButton, Tooltip } from '@mui/material';
import styled                  from 'styled-components';
import InfoIcon                from '@mui/icons-material/Info';
import { ReactNode }           from 'react';

interface ITooltipProps {
  tooltipText?: ReactNode;
  withArrow?  : boolean;
}

export const AppTooltip = ({
  tooltipText,
  withArrow
}: ITooltipProps) => {
  return tooltipText ? (
    <AppTooltip.Container 
      placement = "right-end"
      title     = {tooltipText}
      arrow     = {withArrow}
    >
      <IconButton>
        <InfoIcon color="disabled" fontSize="small"/>
      </IconButton>
    </AppTooltip.Container>
  ) : null;
}

AppTooltip.Container = styled(Tooltip)`
  background-color: transparent;
`;