import { PropsWithChildren }             from 'react';
import styled                            from 'styled-components';
import { Button, Grid, IconButton }      from '@mui/material';
import { useHistory }                    from 'react-router';
import { RIGHT_PANEL_CONTENT_TYPE }      from '../../models/rightPanelContent';
import { RideDetailsContainer }          from '../../containers/RideDetails';
import { ReactComponent as ChevronIcon } from '../../../../images/Icon-Chevron-R-Rubine.svg';
import { ReactComponent as PlusIcon }    from '../../../../images/Icon-Add-White.svg';
import { scrimBlack }                    from '../../../../styles/theme';
import { RouteDetailsContainer }         from '../../containers/RouteDetails';

interface IRightPanelBlock {
  title         : string;
  content?      : RIGHT_PANEL_CONTENT_TYPE;
  hideAddButton?: boolean;
  onAddBtnClick?: () => void;
  routeId?      : string;
  isPreview?    : boolean;
}

export const RightPanelBlock = ({
  title,
  content,
  hideAddButton,
  children,
  onAddBtnClick,
  routeId,
  isPreview,
}: PropsWithChildren<IRightPanelBlock>) => {
  const { push } = useHistory();

  const handleRedirectUnauthorized = () => push('/signup');

  const handleOpenPanel = () => content && (routeId ? RouteDetailsContainer.changeRightContent(content) : RideDetailsContainer.changeRightContent(content))

  return (
    <RightPanelBlock.Container container>
      <Grid item marginLeft="-25px">
        <Button
          endIcon = {<ChevronIcon />}
          size    = "large"
          onClick = {isPreview ? handleRedirectUnauthorized : handleOpenPanel}
        >
          {title}
        </Button>
      </Grid>

      <Grid item container alignItems="center" columnSpacing="20px" flexWrap="nowrap">
        {!hideAddButton && (
          <Grid item>
            <RightPanelBlock.AddButton onClick={onAddBtnClick}>
              <PlusIcon />
            </RightPanelBlock.AddButton>
          </Grid>
        )}

        {children}
      </Grid>
    </RightPanelBlock.Container>
  );
}

RightPanelBlock.Container = styled(Grid)`
  border-top: 1px solid ${ scrimBlack(0.08) };
  padding   : 8px 0 16px;

  ${({ theme: {mediaQueries: { sm }}}) => sm } {
    padding: 32px 0 40px;
  }
`;

RightPanelBlock.AddButton = styled(IconButton)`
  width : 32px;
  height: 32px;

  &.MuiIconButton-root, &.MuiIconButton-root:hover {
    background-color: ${({ theme: {colors: {primary: { rubineDark }}}}) => rubineDark };
  }

  &.MuiIconButton-root:hover {
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 16%);
  }
`;
