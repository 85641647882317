import { persistReducer } from 'redux-persist';
import storage            from 'redux-persist/lib/storage';
import { createSlice }    from '@reduxjs/toolkit';
import { IGenLocation }   from '@modules/Profile/models/genLocation';

interface ILocation {
  currentLocation: IGenLocation;
}

interface IStateType {
  location: ILocation;
}

const initialState = {
  currentLocation: {},
};

const persistConfig = {
  key: 'location',
  version: 1,
  storage,
}

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setCurrentLocation: (state, action) => {
      state.currentLocation = action.payload;
    },
  },
});

export const { setCurrentLocation } = locationSlice.actions;

export const currentLocation = (state: IStateType) =>
  state.location.currentLocation;

export const location = persistReducer(persistConfig, locationSlice.reducer);
