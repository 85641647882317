import styled                 from 'styled-components';
import {
  Avatar,
  AvatarGroup,
  Button
}                             from '@mui/material';
import PersonIcon             from '@mui/icons-material/Person';
import { ERequestStatus, IRecommendation }    from '@models/Feed/recommendations';
import { getProfilePhotoUrl } from '@utils/files_utils';

interface IRecommendationCardProps {
  data               : IRecommendation;
  handleAddConnection: (userId: string) => void;
  handleOpenProfile  : (username: string) => void;
}

export const RecommendationCard = ({
  data,
  handleAddConnection,
  handleOpenProfile
}: IRecommendationCardProps) => data && (
  <RecommendationCard.Wrapper>
    <RecommendationCard.Image
      $imageUrl = {data.profilePictureUrl ? getProfilePhotoUrl(data.profilePictureUrl) : null}
      onClick   = {() => handleOpenProfile(data.username)}
    >
      {!data.profilePictureUrl && <PersonIcon />}
    </RecommendationCard.Image>

    <RecommendationCard.Information>
      <h3 onClick={() => handleOpenProfile(data.username)}>{data.displayName}</h3>

      <RecommendationCard.FriendsContainer isVisible={data.mutualFriendsCount > 0}>
        <AvatarGroup total={data.mutualFriendsCount} max={3} spacing="medium">
          {data.topFriendsProfilePictureUrls?.map((friendProfilePictureUrl, index) => (
            friendProfilePictureUrl ? (
                <Avatar key={index} src={getProfilePhotoUrl(friendProfilePictureUrl)} />
              ) : (
                <Avatar key={index}>
                  {data.displayName.substring(0, 1).toUpperCase()}
                </Avatar>
              )
          ))}
        </AvatarGroup>
        <p>
          {data.mutualFriendsCount > 1 ? 'Mutual Friends' : 'Mutual Friend'}
        </p>
      </RecommendationCard.FriendsContainer>

      <Button
        fullWidth
        onClick = {() => handleAddConnection(data.userId)}
        variant = "outlined"
        color   = "primary"
      >
        {data.status ? (data.status === ERequestStatus.Incoming ? 'Accept Invitation' : 'Resend Invitation') : 'Connect'}
      </Button>
    </RecommendationCard.Information>
  </RecommendationCard.Wrapper>
);

RecommendationCard.Wrapper = styled.div`
  min-width       : 270px;
  height          : 100%;
  border-radius   : 10px;
  margin-right    : 20px;
  background-color: ${({ theme }) => theme.colors.neutralGrey[200]};
`;

RecommendationCard.Image = styled.div<{ $imageUrl: string | null }>`
  height                 : 180px;
  width                  : 100%;
  border-top-right-radius: 10px;
  border-top-left-radius : 10px;

  ${({ $imageUrl }) => $imageUrl && `
    background-image   : url(${$imageUrl});
    background-size    : cover;
    background-repeat  : no-repeat;
    background-position: inherit;
  `}

${({ $imageUrl, theme }) => !$imageUrl && `
    background-color       : ${theme.colors.neutralGrey[400]};
    display                : flex;
    justify-content        : center;
    align-items            : center;

    svg {
      font-size: 130px;
      fill     : ${theme.colors.neutralGrey[600]}
    }
  `}
`;

RecommendationCard.Information = styled.div`
  padding: 0px 20px 15px 20px;

  button {
    display        : flex;
    justify-content: center;
  }

  h3 {
    width: max-content;
  }

  h3:hover {
    cursor: pointer;
    color : ${({ theme }) => theme.colors.primary.rubineLight};
  }
`;

RecommendationCard.FriendsContainer = styled.div<{ isVisible: boolean }>`
  display        : flex;
  justify-content: flex-start;
  margin-bottom  : 10px;

  p {
    margin-left: 10px;
    visibility : ${({ isVisible }) => isVisible ? 'initial' : 'hidden'};
  }
`;
