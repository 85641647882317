/**
 * The [origin](https://developer.mozilla.org/en-US/docs/Glossary/Origin) of the
 * API server.  Includes the scheme, the hostname, and the port (if using a
 * non-default port for that scheme).  Does not include any path; does not end
 * with '/'.
 */
 export const apiOrigin           = process.env.REACT_APP_PREEM_API_ORIGIN;
 export const workerServerOrigin  = process.env.REACT_APP_PREEM_WORKER_ORIGIN;
 export const photonOrigin        = process.env.REACT_APP_PREEM_PHOTON_ORIGIN;
 export const preemFilesHost      = process.env.REACT_APP_PREEM_FILES_HOST;
 export const profilePicturesHost = process.env.REACT_APP_PREEM_PROFILE_PICTURES_HOST;
 export const rideEventPhotosHost = process.env.REACT_APP_PREEM_RIDE_EVENT_PHOTOS_HOST
 export const getStreamKey        = process.env.REACT_APP_PREEM_GETSTREAM_KEY;
 export const showFeed            = process.env.REACT_APP_FEATURE_FLAG_SHOW_FEED;
 export const getStreamAppId      = process.env.REACT_APP_PREEM_GETSTREAM_APP_ID;
