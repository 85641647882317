import {
  useCallback,
  useState,
  useRef,
}                            from 'react';
import { useHistory }        from 'react-router';
import styled                from 'styled-components';
import {
  Activity,
  EnrichedReaction,
  Reaction,
  UR
}                            from 'getstream';
import {
  CommentList,
  useFeedContext
}                            from 'react-activity-feed';
import {
  IconButton,
  TextField,
}                            from '@mui/material';
import {
  ThumbUp,
  // Share,
  Chat,
  Send,
}                            from '@mui/icons-material';
import { Comment }           from '@modules/Feed/components/Post/components/Comment';
import {
  useAddUserConnectionMutation,
  useGetRecommendationsQuery,
  useGetSelfInformationQuery
}                            from '@modules/Profile/queries';
import { IPost }             from '@models/Feed/post';
import { ConfirmationModal } from '@components/ConfirmationModal';
import { Recommendations }   from '@modules/Feed/components/Recommendations';

interface IFooterProps {
  activity: IPost;
}

export const Footer = ({ activity }: IFooterProps) => {
  const { push }                                    = useHistory();
  const feed                                        = useFeedContext();
  const { data: recommendations }                   = useGetRecommendationsQuery({ pageNumber: 0, pageSize: 20 });
  const { data: self }                              = useGetSelfInformationQuery();
  const [addConnection]                             = useAddUserConnectionMutation();
  const commentInputRef                             = useRef<HTMLInputElement>(null);
  const [comment, setComment]                       = useState('');
  const [isConfirmModalOpen, setConfirmModalStatus] = useState(false);
  const [commentToDelete, setCommentToDelete]       = useState('');
  const isLiked                                     = Boolean(activity.own_reactions?.like?.length ?? false),
        // isShared                                    = Boolean(activity.own_reactions?.repost?.length ?? false),
        counts                                      = activity.reaction_counts,
        likesCount                                  = counts?.['like'] ?? 0,
        commentsCount                               = counts?.['comment'] ?? 0,
        sharesCount                                 = counts?.['repost'] ?? 0,
        hasReactions                                = likesCount > 0 || commentsCount > 0 || sharesCount > 0;

  const onAddComment = useCallback(
    (text: string) => {
      feed.onAddReaction('comment', activity as Activity, { text }).then(() => {
        setComment('');
        commentInputRef?.current?.blur();
      });
    },
    [activity, feed]
  );

  const index = feed.activityOrder.indexOf(activity.id);

  const showRecommendations = recommendations && (index === 2 || (index !== 0 && index % 12 === 0));

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      onAddComment(comment);
    },
    [comment, onAddComment]
  );

  const handleKeypress = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleSubmit(e);
      }
    },
    [handleSubmit]
  );

  const onReply = useCallback(
    () => {
      commentInputRef.current?.focus();
    },
    []
  );

  const onToggleLikePost = useCallback(
    () => {
      feed.onToggleReaction('like', activity as Activity, {} as UR, { targetFeeds: [] });
    },
    [activity, feed]
  );

  const onRemoveComment = useCallback(
    (commentId: string) => {
      setCommentToDelete(commentId);
      setConfirmModalStatus(true);
    },
    []
  );

  const removeComment = useCallback(
    () => {
      feed.onRemoveReaction('comment', activity as Activity, commentToDelete).then(() => setConfirmModalStatus(false));
    },
    [feed, activity, commentToDelete]
  );

  const onToggleLikeComment = useCallback(
    (reaction: EnrichedReaction) => feed.onToggleChildReaction('like', reaction as Reaction, {} as UR, { targetFeeds: [] }),
    [feed]
  );

  const onRepostClick = useCallback(
    () => {
      const originalActivity =
        activity.verb === 'repost' && typeof activity.object === 'object'
          ? (activity.object as any) : activity;

      feed.onToggleReaction('repost', originalActivity as Activity, originalActivity, {
        targetFeeds: [`owner:${self?.id ?? ''}`],
      })
    },
    [activity, feed, self?.id]
  );

  const handleAddConnection = useCallback(
    (userId: string) => addConnection({ userId }),
    [addConnection]
  );

  const handleOpenProfile = useCallback(
    (username: string) => push(`user/${username}`),
    [push]
  );

  return (
    <div style={{ background: '#F2F2F1' }}>
      <Footer.Wrapper>
        {hasReactions && (
          <Footer.ReactionsContainer>
            <p>{likesCount > 0 ? `${likesCount} Like${likesCount === 1 ? '' : 's'}` : ''}</p>

            <div>
              <p>{commentsCount > 0 ? `${commentsCount} Comment${commentsCount === 1 ? '' : 's'}` : ''}</p>
              <p>{sharesCount > 0 ? `${sharesCount} Share${sharesCount === 1 ? '' : 's'}` : ''}</p>
            </div>
          </Footer.ReactionsContainer>
        )}

        <Footer.ActionsContainer>
          <Footer.ActionItem $isActive={isLiked} onClick={onToggleLikePost}>
            <ThumbUp />

            <p>Like</p>
          </Footer.ActionItem>

          <Footer.ActionItem onClick={() => commentInputRef?.current?.focus()}>
            <Chat />

            <p>Comment</p>
          </Footer.ActionItem>

          {/*<Footer.ActionItem onClick={onRepostClick} $isActive={isShared}>*/}
          {/*  <Share />*/}

          {/*  <p>Share</p>*/}
          {/*</Footer.ActionItem>*/}
        </Footer.ActionsContainer>

        <Footer.CommentsContainer $hasComments={commentsCount > 0}>
          <CommentList
            reverseOrder
            activityId   = {activity.id}
            CommentItem  = {commentProps => (
              <Comment
                comment         = {commentProps.comment}
                isOwnComment    = {self?.id === commentProps.comment.user_id}
                onRemoveComment = {onRemoveComment}
                onReply         = {onReply}
                onLike          = {() => onToggleLikeComment(commentProps.comment)}
              />
            )}
          />
        </Footer.CommentsContainer>

        <Footer.InputContainer onSubmit={handleSubmit}>
          <Footer.Input
            name        = "text"
            placeholder = "Write a comment..."
            inputRef    = {commentInputRef}
            value       = {comment}
            onChange    = {({ target: { value }}) => setComment(value)}
            minRows     = {1}
            onKeyDown   = {handleKeypress}
            multiline
          />

          <IconButton type="submit">
            <Send />
          </IconButton>
        </Footer.InputContainer>

        {isConfirmModalOpen && (
          <ConfirmationModal
            onSubmit         = {removeComment}
            onCancel         = {() => setConfirmModalStatus(false)}
            title            = "Are you sure you want to delete this comment?"
            submitButtonText = 'Delete'
            cancelButtonText = 'Cancel'
          />
        )}
      </Footer.Wrapper>

      {showRecommendations && (
        <Recommendations
          recommendations     = {recommendations ?? []}
          handleAddConnection = {handleAddConnection}
          handleOpenProfile   = {handleOpenProfile}
        />
      )}
    </div>
  );
};

Footer.Wrapper = styled.div`
  background-color: #FFF;

  && .raf-button--info {
    font-family     : 'Inter', sans-serif !important;
    font-weight     : 400;
    color           : ${({ theme }) => theme.colors.primary.rubineLight};
    background-color: transparent;
    border          : none !important;
  }
`;

Footer.ActionsContainer = styled.div`
  display        : flex;
  justify-content: space-around;
  border-top     : 1px solid #ebebeb;
  gap            : 2px;
`;

Footer.ActionItem = styled.div<{ $isActive?: boolean }>`
  width          : 100%;
  display        : flex;
  align-items    : center;
  justify-content: center;
  gap            : 10px;
  cursor         : pointer;
  padding        : 15px 0px;

  svg {
    font-size: 20px;
    fill: ${({ $isActive, theme: { colors } }) => $isActive ? colors.primary.rubineDark : colors.neutralGrey[600]};
  }

  p {
    margin     : 0;
    color      : ${({ $isActive, theme: { colors } }) => $isActive ? colors.primary.rubineDark : colors.neutralGrey[600]};
    user-select: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutralGrey[300]};

    svg {
      fill: ${({ theme: { colors } }) => colors.primary.rubineDark};
    }

    p {
      color: ${({ theme: { colors } }) => colors.primary.rubineDark};
    }
  }
`;

Footer.CommentsContainer = styled.div<{ $hasComments: boolean }>`
  display         : flex;
  flex-direction  : column;
  gap             : 20px;
  background-color: ${({ theme: { colors } }) => colors.neutralGrey[100]};
  padding         : ${({ $hasComments }) => $hasComments ? '20px 30px' : '0px'};
  border-bottom   : 1px solid #ebebeb;

  & > p {
    margin    : 0;
    text-align: center;
    font-style: italic;
  }
`;

Footer.InputContainer = styled.form`
  display                   : flex;
  gap                       : 10px;
  justify-content           : space-between;
  padding                   : 10px 20px;
  background-color          : ${({ theme: { colors } }) => colors.neutralGrey[100]};
  border-bottom-left-radius : 8px;
  border-bottom-right-radius: 8px;

  button {
    border-radius: 0%;
  }
`;

Footer.Input = styled(TextField)`
  flex-grow: 1;

  .MuiInputBase-root {
    border-radius: 20px;
  }

  input {
    padding         : 10px 15px;
    border-radius   : 15px;
    background-color: #EEEEED;
  }
`;

Footer.ReactionsContainer = styled.div`
  display        : flex;
  justify-content: space-between;
  padding        : 20px 15px;
  border-top     : 1px solid #ebebeb;

  p {
    margin: 0;
    color : ${({ theme: { colors } }) => colors.neutralGrey[600]};
  }

  div {
    display: flex;
    gap    : 10px;
  }
`;

Footer.LoadMoreCommentsWrapper = styled.div`
  width          : 100%;
  display        : flex;
  justify-content: center;
`;
