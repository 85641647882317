import { useMemo }           from 'react';
import styled, { css }       from 'styled-components';
import { Grid }              from '@mui/material';
import { statisticsMetrics } from '../../models/statisticsMetrics';
import { 
  IStatisticsMetrics, 
  IUserStatistics 
}                            from '../../../../../../components/UserStatisticsTabs/model/statistics';
import { scrimBlack }        from '../../../../../../styles/theme';

interface IProfileStatisticsProps {
  data? : IUserStatistics;
  units?: string;
}

export const ProfileStatistics = ({
  data,
  units
}: IProfileStatisticsProps) => {
  const getMetricsValue = useMemo(
    () => (metrics: IStatisticsMetrics<IUserStatistics>) => 
      metrics.conversionFn ? metrics.conversionFn(units, data?.[metrics.key]) : data?.[metrics.key],
    [data, units]
  );

  return (
    <ProfileStatistics.Container container wrap="nowrap">
      {statisticsMetrics.map((metrics, index) => (
        <Grid
          item
          container
          xs         = "auto"
          direction  = "column"
          key        = {index}
          alignItems = "center"
        >
          {metrics.icon}

          <ProfileStatistics.MetricsValue>
            {getMetricsValue(metrics) || '—'}

            <span>{' '}{metrics?.units?.(units)}</span>
          </ProfileStatistics.MetricsValue>

          <ProfileStatistics.MetricsName>
            {metrics.label}
          </ProfileStatistics.MetricsName>
        </Grid>
      ))}
    </ProfileStatistics.Container>
  );
}

ProfileStatistics.Container = styled(Grid)`
  overflow-x     : scroll;
  padding-bottom : 64px;
  justify-content: start;
  border-bottom  : 1px solid ${scrimBlack(0.08)};

  ${({theme: {colors: {primary: {black}}, mediaQueries: {sm}, fontSizes: {l}}}) => css`
    ${sm} {
      justify-content: center;
      overflow-x     : hidden;
    }

    path {
      fill: ${black};
    }

    svg {
      width : 30px;
      height: 30px;
    }

    .MuiGrid-item {
      font-weight   : 600;
      letter-spacing: 0.8px;
      padding-left  : 20px;
      font-size     : ${l};

      ${sm} {
          padding: 0 2%;
      }
    }        
  `}
`;

ProfileStatistics.MetricsValue = styled.span`
  font-weight   : 700;
  letter-spacing: -0.2px;
  padding       : 6px 0;

  span {
    ${({theme: {colors: {type: {medium}}, fontSizes: {s}}}) => css`
      color      : ${medium};
      font-size  : ${s};
      font-weight: normal;
    `}
  }
`;

ProfileStatistics.MetricsName = styled.span`
  font-size: 10px;
  color    : ${({theme: {colors: {type: {medium}}}}) => medium};
`;