import { useState }      from 'react';
import styled            from 'styled-components';
import { Avatar }        from '@components/Avatar';
import { IRide }         from '@modules/Details/models/ride';
import { IUserBaseInfo } from '@modules/Profile/models/UserInfo';

interface IUserDetailsProps {
  organizerInfo ?: IUserBaseInfo;
  rideDetails   ?: IRide;
}

export const UserDetailsSection = ({ organizerInfo, rideDetails }: IUserDetailsProps) =>  {
  const [showFullDescription, setShowFullDescription] = useState(false);

  return (
    <UserDetailsSection.Container>
      <UserDetailsSection.Description showFullDescription={showFullDescription}>
        {rideDetails?.description?.length ? rideDetails?.description : 'No description'}
      </UserDetailsSection.Description>
      {
        rideDetails?.description && rideDetails?.description?.length > 145 && (
        <UserDetailsSection.ShowMore onClick={() => setShowFullDescription(!showFullDescription)}>
          Show {showFullDescription ? 'less' : 'more'}
        </UserDetailsSection.ShowMore>
        )
      }
      <UserDetailsSection.UserInfoContainer>
        <Avatar size={35} photoUrl={organizerInfo?.profilePictureUrl} />
        <UserDetailsSection.UserInfo>
          <UserDetailsSection.UserName>{organizerInfo?.firstName} {organizerInfo?.lastName}</UserDetailsSection.UserName>
          <UserDetailsSection.Title>Ride Organizer</UserDetailsSection.Title>
        </UserDetailsSection.UserInfo>
      </UserDetailsSection.UserInfoContainer>
    </UserDetailsSection.Container>
  );
}

UserDetailsSection.Container = styled.div`
  display          : flex;
  align-items      : flex-start;
  flex-direction   : column;
  padding          : 10px;
  background-color : ${({ theme }) => (theme.colors.neutralGrey[200])};
`;

UserDetailsSection.UserInfoContainer = styled.div`
  display         : flex;
  flex-direction  : row;
  justify-content : flex-start;
  align-items     : center;
  width           : 100%;
  padding-top     : 10px;
`;

UserDetailsSection.Description = styled.div<{ showFullDescription: boolean }>`
  display            : -webkit-box;
  -webkit-line-clamp : ${({ showFullDescription }) => showFullDescription ? 10 : 3 };
  -webkit-box-orient : vertical;
  overflow           : hidden;
`;

UserDetailsSection.ShowMore = styled.div`
  cursor           : pointer;
  background-color : ${({ theme }) => (theme.colors.neutralGrey[300])};
  margin-top       : 10px;
  padding          : 3px;
  font-size        : 12px;
`;

UserDetailsSection.UserName = styled.div`
  font-weight: bold;
`;

UserDetailsSection.Title = styled.div`
  font-size: 10px;
  color: ${({ theme }) => (theme.colors.neutralGrey[600])};
`;

UserDetailsSection.UserInfo = styled.div`
  margin-left: 10px;
`;
