import {
  useCallback,
  useEffect,
  useMemo
}                                        from 'react';
import { useLocation,  useHistory }      from 'react-router-dom';
import { ReactComponent as GarminLogo }  from '@images/appsLogos/Garmin.svg';
import { ReactComponent as WahooLogo }   from '@images/appsLogos/Wahoo.svg';
import { ReactComponent as RideWithGps } from '@images/appsLogos/RideWithGps.svg';
import {
  useGetUserGarminAccessQuery,
  useGetGarminConnectionQuery,
  useGetUserWahooAccessQuery,
  useGetWahooAuthUrlQuery,
  useConnectToWahooMutation,
  useConnectToGarminMutation,
  useDeleteGarminConnectionMutation,
  useDeleteWahooConnectionMutation,
  useGetRideWithGpsAuthUrlQuery,
  useGetRideWithGpsStatusQuery,
  useConnectToRideWithGpsMutation,
  useDisconnectFromRideWithGpsMutation,
  useImportRoutesFromRideGpsMutation
}                                        from '@modules/Profile/queries';
import { AppsPage }                      from '@modules/Profile/pages/Apps';
import { useSessionStorage }             from '@utils/hooks/useSessionStorage';

export enum EApp {
  Garmin      = 'Garmin',
  Wahoo       = 'Wahoo',
  RideWithGps = 'RideWithGps'
}

export interface IApp {
  isConnected      : boolean;
  Logo             : JSX.Element;
  title            : string;
  actions          : string[];
  handleConnect    : () => void;
  handleDisconnect : () => void;
}

const APP_CONNECTION_ATTEMPT_KEY = 'app-connection-attempt';

export const AppsContainer = () => {
  const { replace, push } = useHistory();
  const {
    getItem,
    setItem,
    removeItem
  } = useSessionStorage();

  const { data: garminAccess, refetch: refetchGarminAccess } = useGetUserGarminAccessQuery();
  const { data: garminAuthUrl }                              = useGetGarminConnectionQuery();
  const [deleteGarminConnect]                                = useDeleteGarminConnectionMutation();
  const [connectToGarmin, {
    isUninitialized: isUninitializedGarminConnection
  }] = useConnectToGarminMutation();

  const { data: wahooAccess, refetch: refetchWahooAccess } = useGetUserWahooAccessQuery();
  const { data: wahooAuthUrl }                             = useGetWahooAuthUrlQuery();
  const [deleteWahooConnect]                               = useDeleteWahooConnectionMutation();
  const [connectToWahoo, {
    isUninitialized: isUninitializedWahooConnection
  }] = useConnectToWahooMutation();

  const { data: rideWithGpsAccess, refetch: refetchRideWithGpsAccess } = useGetRideWithGpsStatusQuery();
  const { data: rideWithGpsAuthUrl }                                   = useGetRideWithGpsAuthUrlQuery();
  const [importRoutesFromRideWithGps]                                  = useImportRoutesFromRideGpsMutation();
  const [deleteRideWithGpsConnect]                                     = useDisconnectFromRideWithGpsMutation();
  const [connectToRideWithGps, {
    isUninitialized: isUninitializedRideWithGpsConnection,
  }] = useConnectToRideWithGpsMutation();

  const { search } = useLocation();

  useEffect(
    () => {
      const searchParams = new URLSearchParams(search);
      const code         = searchParams.get('code');
      const { app }      = getItem<{ app: EApp; }>(APP_CONNECTION_ATTEMPT_KEY) ?? {};

      if (code && app) {
        if (app === EApp.Wahoo && isUninitializedWahooConnection) {
          connectToWahoo({ code });
        }

        if (app === EApp.RideWithGps && isUninitializedRideWithGpsConnection) {
          connectToRideWithGps({ code })
            .unwrap()
            .then(() => {
              importRoutesFromRideWithGps();

              push('/profile/my-routes');
            });
        }

        removeItem(APP_CONNECTION_ATTEMPT_KEY);
        replace({ search: '' });
      }
    },
    [
      importRoutesFromRideWithGps,
      connectToRideWithGps,
      connectToWahoo,
      getItem,
      removeItem,
      isUninitializedRideWithGpsConnection,
      isUninitializedWahooConnection,
      push,
      replace,
      search
    ]
  );

  useEffect(
    () => {
      const searchParams = new URLSearchParams(search);
      const token        = searchParams.get('oauth_token');
      const verifier     = searchParams.get('oauth_verifier');

      if (token && verifier && isUninitializedGarminConnection) {
        connectToGarmin({ token, verifier });

        replace({ search: '' });
      }
    },
    [
      connectToGarmin,
      isUninitializedGarminConnection,
      replace,
      search
    ],
  );

  const handleConnectApp = useCallback(
    (app: EApp) => {
      if (app === EApp.Garmin) {
        window.open(garminAuthUrl);
      }

      if (app === EApp.Wahoo) {
        setItem<{ app: EApp; }>(APP_CONNECTION_ATTEMPT_KEY, { app: EApp.Wahoo });

        window.open(wahooAuthUrl);
      }

      if (app === EApp.RideWithGps) {
        setItem<{ app: EApp }>(APP_CONNECTION_ATTEMPT_KEY, { app: EApp.RideWithGps });

        window.open(rideWithGpsAuthUrl);
      }
    },
    [
      garminAuthUrl,
      rideWithGpsAuthUrl,
      setItem,
      wahooAuthUrl
    ],
  );

  const apps = useMemo<IApp[]>(
    () => [{
      title  : 'Garmin Connect',
      Logo   : <GarminLogo />,
      actions: [
        'Imports Ride History (Due to Garmin restrictions we can only Import 4 years of ride history)',
        'Syncs new rides with Preem'
      ],
      isConnected     : garminAccess?.connected ?? false,
      handleConnect   : () => handleConnectApp(EApp.Garmin),
      handleDisconnect: () => deleteGarminConnect().then(refetchGarminAccess),
    }, {
      title           : 'Wahoo Fitness',
      Logo            : <WahooLogo />,
      actions         : ['Imports Ride History', 'Syncs new rides with Preem'],
      isConnected     : wahooAccess?.connected ?? false,
      handleConnect   : () => handleConnectApp(EApp.Wahoo),
      handleDisconnect: () => deleteWahooConnect().then(refetchWahooAccess),
    }, {
      title           : 'Ride With Gps',
      Logo            : <RideWithGps />,
      actions         : ['Imports Routes'],
      isConnected     : rideWithGpsAccess?.connected ?? false,
      handleConnect   : () => handleConnectApp(EApp.RideWithGps),
      handleDisconnect: () => deleteRideWithGpsConnect().then(refetchRideWithGpsAccess),
    }],
    [
      deleteGarminConnect,
      deleteRideWithGpsConnect,
      deleteWahooConnect,
      garminAccess?.connected,
      handleConnectApp,
      refetchGarminAccess,
      refetchRideWithGpsAccess,
      refetchWahooAccess,
      rideWithGpsAccess?.connected,
      wahooAccess?.connected
    ],
  );

  return (
    <AppsPage apps={apps}/>
  );
};
