import * as Yup from 'yup';

Yup.addMethod(Yup.array, 'uniqueValueInArray', function(propertyName: string, message: string) {
  return this.test('unique', message, function (values: (any[] | undefined)) {
    if(!values) {
      return true;
    }

    const mapper   = (value: any) => value[propertyName];
    const set      = Array.from(new Set(values.map(mapper)));
    const isUnique = values.length === set.length;

    if (isUnique) {
      return true;
    }

    const idx = values.findIndex((value, i) => mapper(value) !== set[i]);
    const { path } = this;

    return this.createError({
      path: `${path}[${idx}].value`,
      message: message,
    });
  });
});