import { useMemo, useState }    from 'react';
import { useForm }              from 'react-hook-form';
import {
  object,
  string,
  boolean,
}                               from 'yup';
import styled                   from 'styled-components';
import { yupResolver }          from '@hookform/resolvers/yup';
import {
  Button,
  Grid
}                               from '@mui/material';
import { Notification }         from '@components/Notification';
import { Tabset }               from '@components/Tabset';
import { PhoneInput }           from '@components/PhoneInput';
import { TextField }            from '@components/TextField';
import { InputError }           from '@components/InputError';
import { NOTIFICATIONS_TABSET } from '@modules/Profile/components/forms/Settings/components/NotificationSettings';
import {
  useUpdateUserPhoneMutation,
  useUpdateUserSettingsMutation,
  useVerifyUserPhoneMutation,
}                               from '@modules/Profile/queries';
import { formatPhoneNumber }    from '@utils/commonFunctions';

interface ISignupNotificationsProps {
  signupNotificationsVisible: boolean;
  onClickNext               : () => void;
}

interface ISignupNotificationsForm {
  isEmailNotification: boolean;
  isPhoneNotification: boolean;
  phoneNumber        : string | null;
  code               : string | null;
}

const signupNotificationsValidationSchema = object().shape({
  isEmailNotification: boolean().required(),
  isPhoneNotification: boolean().required(),
  phoneNumber        : string().nullable()
    .when('isPhoneNotification', {
      is: true,
      then: string().nullable().required('Phone Number is required'),
    }),
});

const confirmPhoneValidationSchema = object().shape({
  code: string().trim().required('Confirmation code is required').matches(/^\d{6}$/gm, 'Code should have the 6-digit format')
});

export const SignupNotifications = ({ onClickNext }: ISignupNotificationsProps) => {
  const [isConfirmationStage, setConfirmationStage] = useState<boolean>(false);
  const [updateUserSettings]                        = useUpdateUserSettingsMutation();
  const [updateUserPhone]                           = useUpdateUserPhoneMutation();
  const [verifyUserPhone]                           = useVerifyUserPhoneMutation();

  const defaultValues = useMemo(
    () => isConfirmationStage ? ({
      code: '',
    }) : ({
      isEmailNotification: true,
      isPhoneNotification: true,
      phoneNumber        : '',
    }),
    [isConfirmationStage],
  );

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<ISignupNotificationsForm>({
    resolver: yupResolver(isConfirmationStage ? confirmPhoneValidationSchema : signupNotificationsValidationSchema),
    defaultValues,
  });

  const onSubmit = async ({ phoneNumber, isPhoneNotification, isEmailNotification }: ISignupNotificationsForm) => {
    if (isPhoneNotification) {
      const formattedPhoneNumber = formatPhoneNumber(phoneNumber ?? '');

      if (formattedPhoneNumber.length === 1) {
        return Notification.enqueueSnackbar('Phone number is required', 'error');
      }

      updateUserSettings({ isPhoneNotification, isEmailNotification });

      updateUserPhone({ phoneNumber: formattedPhoneNumber })
        .unwrap()
        .then(() => {
          setConfirmationStage(true);
          reset();
        });
    } else {
      updateUserSettings({ isPhoneNotification, isEmailNotification }).then(onClickNext);
    }
  }

  const onValidatePhone = async ({ code }: ISignupNotificationsForm) => {
    verifyUserPhone({ code: code ?? '' })
      .unwrap()
      .then(onClickNext);
  };

  return (
    <SignupNotifications.Wrapper>
      <SignupNotifications.Head>
        <h1>Notifications</h1>

        {isConfirmationStage ? (
          <p>We&apos;ve sent the confirmation code to your phone. Please enter this code below.</p>
        ) : (
          <p>
            Preem needs to send you notifications to keep you updated on your ride plans and your community.
            If you prefer receiving notifications on your phone, we ask that you provide your phone number.
            This information will only be visible to you and you can always manage your notifications in your Settings page.
          </p>
        )}
      </SignupNotifications.Head>

      <form onSubmit={handleSubmit(isConfirmationStage ? onValidatePhone : onSubmit)}>
        {isConfirmationStage ? (
          <>
            <Grid item xs={12} container justifyContent="center">
              <TextField
                control     = {control}
                placeholder = "Confirmation Code"
                name        = "code"
                error       = {errors.code?.message}
                dark
              />

              <SignupNotifications.FieldError>
                {errors.code && <InputError error={errors.code.message ?? ''} />}
              </SignupNotifications.FieldError>
            </Grid>

            <Grid item xs={12}>
              <SignupNotifications.SubmitBtn
                fullWidth
                type     = "submit"
                variant  = "contained"
                size     = "large"
                disabled = {isSubmitting}
              >
                Submit Code
              </SignupNotifications.SubmitBtn>
            </Grid>
          </>
        ) : (
          <Grid container rowSpacing={2}>
            {NOTIFICATIONS_TABSET.map((tabset, index) => (
              <Grid key={index} item xs={12}>
                <SignupNotifications.TabsetContainer>
                  <SignupNotifications.Label>{tabset.title}</SignupNotifications.Label>

                  <Tabset
                    tabs    = {tabset.tabs}
                    control = {control}
                    name    = {tabset.name}
                    dark
                  />
                </SignupNotifications.TabsetContainer>
              </Grid>
            ))}

            {watch('isPhoneNotification') && (
              <Grid item xs={12} container alignItems="center" direction="column" sx={{ marginBottom: '5px' }}>
                <PhoneInput
                  label    = "Phone"
                  variant  = "filled"
                  name     = "phoneNumber"
                  onChange = {(value) => setValue('phoneNumber', value as string)}
                  value    = {getValues('phoneNumber')}
                  error    = {!!errors.phoneNumber}
                  dark
                />

                <SignupNotifications.FieldError>
                  {errors.phoneNumber && <InputError error={errors.phoneNumber.message ?? ''} />}
                </SignupNotifications.FieldError>
              </Grid>
            )}

            <Grid item xs={12}>
              <SignupNotifications.SubmitBtn
                fullWidth
                type     = "submit"
                variant  = "contained"
                size     = "large"
                disabled = {isSubmitting}
              >
                Sign Up
              </SignupNotifications.SubmitBtn>
            </Grid>
          </Grid>
        )}
      </form>
    </SignupNotifications.Wrapper>
  );
};

SignupNotifications.Wrapper = styled.div`
  width    : 500px;
  max-width: 99vw;
  margin   : 0 auto;
`;

SignupNotifications.TabsetContainer = styled.div`
  width          : fit-content;
  margin         : 0 auto;
  display        : flex;
  justify-content: flex-end;
  align-items    : center;
`;

SignupNotifications.FieldError = styled.div`
  position       : relative;
  width          : 100%;
  height         : 10px;
  display        : flex;
  justify-content: center;

  p {
    top: 5px;
  }
`;

SignupNotifications.Head = styled.div`
  color      : ${({ theme: { colors } }) => colors.type.white};
  line-height: 1.5;

  h1 {
    text-align: center;
  }

  p {
    text-align: justify;
    text-indent: 30px;
  }
`;

SignupNotifications.ConfirmationMsg = styled.p`
  text-align: center;
`;

SignupNotifications.Label = styled.h4`
  height      : fit-content;
  width       : 45px;
  color       : ${({ theme: { colors } }) => colors.type.white};
  text-align  : right;
  margin      : 0 10px 0 0;
`;

SignupNotifications.SubmitBtn = styled(Button)`
  width          : 50%;
  margin         : 0 auto;
  display        : flex;
  justify-content: center;
`;

SignupNotifications.ErrorWrapper = styled.div`
  color           : ${({ theme }) => theme.colors.primary.white};
  margin-top      : 20px;
  font-size       : 22px;
  line-height     : 124%;
  font-weight     : 700;
`;
