import { createGlobalStyle } from 'styled-components';
import { theme }             from './theme';

export const BaseStyles = createGlobalStyle`
  * {
    font-family: 'Inter', sans-serif;
  }

  html {
    height            : 100%;
    scroll-padding-top: 80px; /* height of navbar */
  }

  body {
    height                 : 100%;
    background-color       : ${theme.colors.neutralGrey[200]};
    margin                 : 0;
    font-size              : 14px;
    font-family            : 'Inter', sans-serif;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  img {
    max-height: 100%;
  }

  #root {
    height: 100%;
  }
`;
