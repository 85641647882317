import { Button, Grid }                  from '@mui/material';
import styled                            from 'styled-components';
import { ReactComponent as ChevronIcon } from '../../../../images/Icon-Chevron-L-Rubine.svg';

interface IRightBlockHeaderProps {
  title      : string;
  onBackClick: () => void;
}

export const RightBlockHeader = ({
  title,
  onBackClick
}: IRightBlockHeaderProps) => (
  <RightBlockHeader.Container container alignItems="center">
    <Grid item xs={5}>
      <Button 
        startIcon = {<ChevronIcon />}
        onClick   = {onBackClick}
      >
        Back
      </Button>
    </Grid>

    <Grid item>
      <RightBlockHeader.Title><b>{title}</b></RightBlockHeader.Title>
    </Grid>
  </RightBlockHeader.Container>
)

RightBlockHeader.Title = styled.span`
  font-size: 22px;
`;

RightBlockHeader.Container = styled(Grid)`
  padding    : 0 0 16px;
  margin-left: -20px;
  width      : calc(100% + 40px);
  box-shadow : 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04);

  ${({ theme: {mediaQueries: { sm }}}) => sm } {
    margin    : 0;
    width     : 100%;
    box-shadow: none;
  }
`;