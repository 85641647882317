export enum EConnectionStatus {
  PENDING  = 'Pending',
  ACCEPTED = 'Accepted',
  DECLINED = 'Declined'
}

export enum EInviteStatus {
  NONE      = 'NONE',
  INCOMING  = 'INCOMING',
  OUTGOING  = 'OUTGOING',
  CONNECTED = 'CONNECTED',
}

export interface IConnection {
  id                : string;
  username          : string;
  firstName         : string;
  lastName          : string;
  locationName      : string;
  status            : EInviteStatus;
  profilePictureUrl?: string;
  inviterId         : string;
  connectionId?     : string;
}
export interface IConnectionResponse {
  content       : IConnection[];
  totalElements : number;
  totalPages    : number;
  pageNumber    : number;
  pageSize      : number;
}
