import {
  useCallback,
  useEffect,
  useMemo,
  useState
}                                       from 'react';
import { add }                          from 'date-fns';
import styled                           from 'styled-components';
import { orderBy as sortBy }            from 'lodash';
import { useHistory }                   from 'react-router';
import { Checkbox, Link }               from '@mui/material';
import FavoriteBorderIcon               from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon                     from '@mui/icons-material/Favorite';
import { IConfigCell }                  from '@components/TableData';
import {
  IRouteParams,
  IRouteTableRow
}                                       from '@models/RouteModel';
import { useTable }                     from '@utils/hooks/useTable';
import { Avatar }                       from '@components/Avatar';
import { preemFilesHost }               from '@config/api_config';
import { formatRideDate }               from '@utils/commonFunctions';
import { cutDateFromISO }               from '@utils/time_utils';
import { ConfirmationModal }            from '@components/ConfirmationModal';
import { IDropdownOption }              from '@components/RoundedDropdownInput';
import { RouteDetails }                 from '@components/RouteDetails';
import {
  getDistance,
  getElevation
}                                       from '@utils/metrics_utils';
import { RoutesPage }                   from '../pages/Routes';
import {
  useDeleteUserRoutesMutation,
  useGetRideWithGpsAuthUrlQuery,
  useGetRideWithGpsStatusQuery,
  useGetUserGenLocationsAttachedToRoutesQuery,
  useGetUserGenLocationsQuery,
  useGetUserRoutesQuery,
  useGetUserSettingsQuery,
  useImportRoutesFromRideGpsMutation,
  useUpdateUserRouteMutation,
}                                       from '../queries';
import { MEASUREMENT_SYSTEMS }          from '../utils/options';
import { TMeasurementSystem }           from '../models/Settings';
import { IRoutesFilters }               from '../pages/Routes/components/RouteFiltersModal';
import { DEFAULT_ROUTE_THUMBNAIL_PATH } from '@components/RouteDetails';

const Title = styled.div`
  display: flex;

  p {
    margin-left: 15px;
  }
`;

export const RoutesContainer = () => {
  const { push }                                    = useHistory();
  const { data: userSettings }                      = useGetUserSettingsQuery();
  const [deleteRoutes]                              = useDeleteUserRoutesMutation();
  const [updateRoute]                               = useUpdateUserRouteMutation();
  const [removeRouteIds, setRemoveRouteIds]         = useState<string[] | null>(null);
  const [isRouteDetailsOpen, setRouteDetailsStatus] = useState<boolean>(false);
  const [selectedViewRoute, setSelectedViewRoute]   = useState<IRouteTableRow>({} as IRouteTableRow);
  const [selectedLocation, setSelectedLocation]     = useState<IDropdownOption | null>(null);
  const [areFiltersOpen, setFiltersOpen]            = useState<boolean>(false);
  const [filters, setFilters]                       = useState<IRouteParams>({});

  const {
    orderBy,
    order,
    onSort,
    onSelectRow,
    selectedItems,
    onSelectAll,
    pageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    debouncedSearchParam,
  } = useTable({
    orderBy: 'aggWeight',
    order  : 'desc',
  });

  const { data: rideWithGpsAuthUrl }  = useGetRideWithGpsAuthUrlQuery();
  const { data: rideWithGpsStatus }   = useGetRideWithGpsStatusQuery();
  const [importRoutesFromRideWithGps] = useImportRoutesFromRideGpsMutation();

  const {
    data     : userGenLocations,
    isLoading: isLoadingUserGenLocations,
  } = useGetUserGenLocationsQuery();

  const {
    data     : routesGenLocations,
    isLoading: isLoadingRoutesGenLocations
  } = useGetUserGenLocationsAttachedToRoutesQuery();

  const locationOptions = useMemo(
    () => {
      const routeGenLocationIds    = routesGenLocations?.map(({ id }) => id) ?? [];
      const uniqueUserGenLocations = userGenLocations?.filter(({ id }) => !routeGenLocationIds.includes(id)) ?? [];

      return sortBy([...uniqueUserGenLocations, ...(routesGenLocations ?? [])], ['name'], ['asc']);
    },
    [routesGenLocations, userGenLocations],
  );

  const {
    data,
    refetch,
    isLoading: isLoadingUserRoutes
  } = useGetUserRoutesQuery({
    pageNumber,
    pageSize,
    name                    : debouncedSearchParam,
    sortColumn              : orderBy,
    sortDirection           : order,
    dateFrom                : cutDateFromISO(add(new Date(), { years: -5 }).getTime()),
    dateTo                  : cutDateFromISO(new Date().getTime()),
    aggWeightGreaterEqualOne: true,
    ...(selectedLocation ? { genLocationId: String(selectedLocation.id) } : {}),
    ...filters,
  });

  const isLoading = isLoadingUserRoutes || isLoadingRoutesGenLocations || isLoadingUserGenLocations;

  const {
    content,
    totalPages    = 0,
    totalElements = 0,
  } = data ?? {};

  useEffect(
    refetch,
    [refetch]
  );

  const handleSaveFilters = useCallback(
    ({ distance, elevation, importSource }: IRoutesFilters) => {
      setFilters({
        distanceMin : distance[0],
        distanceMax : distance[1],
        elevationMin: elevation[0],
        elevationMax: elevation[1],
        importSource: importSource ?? undefined,
      });

      setFiltersOpen(false);
    },
    [],
  );

  const handleDeleteRoutes = useCallback(
    (ids: string[]) => {
      deleteRoutes(ids).then(() => {
        setRemoveRouteIds(null);
        onSelectAll([]);
      });
    },
    [deleteRoutes, onSelectAll],
  );

  const handleImportRideWithGps = useCallback(
    () => {
      if (rideWithGpsStatus?.connected) {
        importRoutesFromRideWithGps();
      } else {
        window.open(rideWithGpsAuthUrl);
      }
    },
    [
      importRoutesFromRideWithGps,
      rideWithGpsAuthUrl,
      rideWithGpsStatus?.connected
    ],
  );

  const rowActions = useMemo(
    () => [
      {
        label  : 'Edit Route',
        onClick : (route: IRouteTableRow) => {
          setRouteDetailsStatus(true);

          setSelectedViewRoute(route);
        },
      },
      {
        label  : 'Remove',
        onClick : ({ id }: IRouteTableRow) => setRemoveRouteIds([id]),
      },
    ],
    []
  );

  const tableActions = useMemo(
    () => [
      {
        label  : 'Remove',
        onClick : (ids: string[]) => setRemoveRouteIds(ids),
      },
    ],
    []
  );

  const configCells: IConfigCell<IRouteTableRow>[] = useMemo(
    () => [
      {
        label     : 'Date',
        orderBy   : 'date',
        headAlign : 'left',
        width     : '15%',
        render  : ({ creationDate, routeThumbnailPaths }) => (
          <Title>
            <Avatar
              photoUrl = {`${preemFilesHost}${routeThumbnailPaths?.[0] ?? DEFAULT_ROUTE_THUMBNAIL_PATH}`}
              size     = {40}
            />
            <p>{formatRideDate(new Date(creationDate))}</p>
          </Title>
        ),
        isBold: true
      },
      {
        label   : 'Route Title',
        orderBy : 'name',
        width   : '25%',
        render  : (route) => (
          <Link onClick = {() => push(`/route/${route.id}`)}>
            {route.name}
          </Link>
        )
      },
      {
        label   : 'Location',
        key     : 'locationName',
        orderBy : 'location',
      },
      {
        label         : 'Distance',
        orderBy       : 'distance',
        headAlign     : 'center',
        cellFlexAlign : 'center',
        width         : '10%',
        render        : (route) => `${getDistance(userSettings?.measurementSystem, route.distance)} ${userSettings?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL ? 'mi' : route.distance >= 1000 ? 'km' : 'm'}`
      },
      {
        label         : 'Elevation',
        orderBy       : 'elevation',
        headAlign     : 'center',
        cellFlexAlign : 'center',
        width         : '10%',
        render        : (route) => `${getElevation(userSettings?.measurementSystem, route.elevation)} ${userSettings?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL ? 'ft' : 'm'}`
      },
      // {
      //   label         : 'Rides',
      //   key           : 'aggWeight',
      //   orderBy       : 'aggWeight',
      //   headAlign     : 'center',
      //   cellFlexAlign : 'center',
      // },
      {
        label         : 'Favorite',
        orderBy       : 'favorite',
        headAlign     : 'center',
        cellFlexAlign : 'center',
        width         : '10%',
        render        : ({ isFavorite, id, name }: IRouteTableRow) => (
          <Checkbox
            checked     = {!isFavorite}
            onChange    = {() => updateRoute({ id, name, isFavorite: !isFavorite })}
            icon        = {<FavoriteIcon htmlColor="#D63169" />}
            checkedIcon = {<FavoriteBorderIcon htmlColor="#D63169" />}
          />
        )
      },
    ],
    [push, updateRoute, userSettings?.measurementSystem]
  );

  return (
    <>
      {isRouteDetailsOpen && (
        <RouteDetails
          isOpen            = {isRouteDetailsOpen}
          handleClose       = {() => setRouteDetailsStatus(false)}
          selectedViewRoute = {selectedViewRoute}
          updateRoute       = {updateRoute}
        />
      )}

      <RoutesPage
        data                    = {content || []}
        configCells             = {configCells}
        isLoading               = {isLoading}
        rowActions              = {rowActions}
        orderBy                 = {orderBy}
        order                   = {order}
        onSort                  = {onSort}
        onSelectRow             = {onSelectRow}
        selectedItems           = {selectedItems}
        onSelectAll             = {onSelectAll}
        totalPages              = {totalPages}
        totalElements           = {totalElements}
        setPageNumber           = {setPageNumber}
        tableActions            = {tableActions}
        refetch                 = {refetch}
        measurementSystem       = {userSettings?.measurementSystem as TMeasurementSystem}
        searchText              = {searchText}
        setSearchText           = {setSearchText}
        areFiltersOpen          = {areFiltersOpen}
        setFiltersOpen          = {setFiltersOpen}
        onSaveFilters           = {handleSaveFilters}
        selectedLocation        = {selectedLocation}
        locationOptions         = {locationOptions ?? []}
        onSelectLocation        = {setSelectedLocation}
        isRideWithGpsConnected  = {!!rideWithGpsStatus?.connected}
        handleImportRideWithGps = {handleImportRideWithGps}
      />

      {removeRouteIds && (
        <ConfirmationModal
          title            = "Are you sure you want to remove the route?"
          submitButtonText = "Remove Route"
          onSubmit         = {() => handleDeleteRoutes(removeRouteIds)}
          onCancel         = {() => setRemoveRouteIds(null)}
        />
      )}
    </>
  )
}
