import {
  useCallback,
  useEffect,
  useState
}                                        from 'react';
import styled                            from 'styled-components';
import { useForm }                       from 'react-hook-form';
import { yupResolver }                   from '@hookform/resolvers/yup';
import * as Yup                          from 'yup';
import { useHistory }                    from 'react-router';
import { FirebaseError }                 from '@firebase/util';
import { currentUserHasPrimaryLocation } from '../../../services/user_service';
import { LoginSignupFooter }             from '../../../components/LoginSignupFooter';
import { ConfirmationModal }             from '../../../components/ConfirmationModal';
import { LoginForm }                     from './components/LoginForm';
import { LoginButtons }                  from './components/LoginButtons';
import { TextField }                     from '../../../components/TextField';
import { useSendForgotEmailMutation }    from '../../Profile/queries';

const DESCRIPTION_TEXT           = "Don't worry, we've all forgotten a spare tube and a flat kit before";
const SENT_DESCRIPTION_TEXT      = "Your password recovery email has been sent.";
const SENT_DESCRIPTION_HELP_TEXT = "Please click the link when you get it.";

export interface ILoginForm {
  email               : string;
  password            : string;
  forgotPasswordEmail?: string;
}

enum LoginErrors {
  IncorrectEmail    = 'auth/user-not-found',
  IncorrectPassword = 'auth/wrong-password',
  TooManyRequests   = 'auth/too-many-requests',
}

const validationSchema = Yup.object({
  email              : Yup.string().email('Invalid email address').required('Required'),
  password           : Yup.string().required('Required'),
  forgotPasswordEmail: Yup.string().email('Invalid email address').notRequired()
})

const initialValues: ILoginForm = {
  email              : '',
  password           : '',
  forgotPasswordEmail: ''
}

interface ILoginPage {
  signin          : (email: string, password: string) => Promise<unknown>;
  thirdPartyLogin : (providerName: string) => void;
}

export const LoginPage = ({
  signin,
  thirdPartyLogin
}: ILoginPage) => {
  const history                                                       = useHistory();
  const [loginFormVisible, setLoginFormVisible]                       = useState(false);
  const [dimBgImage, setDimBgImage]                                   = useState(false);
  const [error, setError]                                             = useState('');
  const [isForgotPasswordModalOpen, setForgotPasswordModalOpenStatus] = useState<boolean>(false);
  const [isEmailSent, setEmailSentStatus]                             = useState<boolean>(false);
  const [sendForgotEmail]                                             = useSendForgotEmailMutation();

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid, errors },
    getValues,
    setValue,
  } = useForm<ILoginForm>({
    defaultValues: initialValues,
    resolver     : yupResolver(validationSchema),
    mode         : 'onChange',
  });

  // Show the login input form
  useEffect(
    () => {
      let useEffectGate = true;

      let imgTimeout = setTimeout(() => {
        useEffectGate === true && setDimBgImage(true);
      }, 100);

      let loginFormTimeout = setTimeout(() => {
        useEffectGate === true && setLoginFormVisible(true);
      }, 600);

      return () => {
        useEffectGate = false;
        clearTimeout(imgTimeout);
        clearTimeout(loginFormTimeout);
      };
    },
    []
  );

  const onSubmit = useCallback(
    (values: ILoginForm) =>
      signin(values.email, values.password)
        .then(() => {
          setError('');

          currentUserHasPrimaryLocation()
            .then((result) => {
              if (result) {
                history.push('/');
              } else {
                history.push('/signup', { needsLocation: true });
              }
            })
            .catch((error) => {
              console.log(
                `login => currentUserHasPrimaryLocation error`,
                error
              );
            });
        })
        .catch((err: FirebaseError) => {
          if (err.code === LoginErrors.IncorrectPassword || err.code === LoginErrors.IncorrectEmail) {
            setError('The email or password you entered is invalid');
          }

          if (err.code === LoginErrors.TooManyRequests) {
            setError('Too many attempts, please try again later');
          }
        }),
    [history, signin],
  );

  const handleSendRecoveryEmail = useCallback(
    () => {
      const forgotPasswordEmail = getValues().forgotPasswordEmail;

      if (forgotPasswordEmail) {
        // TODO: Send email to API when it will be ready

        sendForgotEmail({ email: forgotPasswordEmail}).then(
          (response: any) => {
            if (!response?.error?.data?.message) {
              setEmailSentStatus(true);
            }
          }
        )
      }
    },
    [getValues, sendForgotEmail]
  );

  const handleCloseRecoveryEmailModal = useCallback(
    () => {
      setValue('forgotPasswordEmail', '');

      setEmailSentStatus(false);
      setForgotPasswordModalOpenStatus(false);
    },
    [setValue]
  );

  return (
    <>
      <LoginPage.Wrapper $dimBgImage={dimBgImage} />

      <LoginPage.Gradient />

      <LoginPage.Container loginFormVisible={loginFormVisible}>
        <LoginForm
          control                       = {control}
          error                         = {error}
          isDirty                       = {isDirty}
          isValid                       = {isValid}
          isSubmitting                  = {isSubmitting}
          submitForm                    = {handleSubmit(onSubmit)}
          handleOpenForgotPasswordModal = {() => setForgotPasswordModalOpenStatus(true)}
        />

        <LoginButtons thirdPartyLogin={thirdPartyLogin} />
      </LoginPage.Container>

      {isForgotPasswordModalOpen && (
        <ConfirmationModal
          onSubmit               = {isEmailSent ? undefined : handleSendRecoveryEmail}
          onCancel               = {handleCloseRecoveryEmailModal}
          isSubmitButtonDisabled = {Boolean(errors.forgotPasswordEmail)}
          title                  = "Recover Password"
          description            = {isEmailSent ? undefined : DESCRIPTION_TEXT}
          submitButtonText       = {isEmailSent ? undefined : "Send Recovery Email"}
          cancelButtonText       = "Go back to login"
          content                = {isEmailSent ? (
            <>
              <p>{SENT_DESCRIPTION_TEXT}</p>
              <p>{SENT_DESCRIPTION_HELP_TEXT}</p>
            </>
          ) : (
            <TextField
              dark
              control     = {control}
              placeholder = "Account Email"
              name        = "forgotPasswordEmail"
            />
          )}
        />)}

      <LoginSignupFooter />
    </>
  );
}

LoginPage.Wrapper = styled.div<{ $dimBgImage: boolean }>`
  opacity             : 1;
  position            : fixed;
  left                : 0;
  top                 : 0;
  right               : 0;
  bottom              : 0;
  z-index             : 75;
  background-image    : url('static/media/Sign-Up-BG.012065c2.jpg');
  background-position : 50% 50%;
  background-size     : cover;
  background-repeat   : no-repeat;
  transition          : opacity 300ms ease-in;

  ${({ $dimBgImage }) => $dimBgImage && 'opacity: 0.24'};
`;

LoginPage.Gradient = styled.div`
  position         : fixed;
  left             : 0;
  top              : 0;
  right            : 0;
  bottom           : 0;
  z-index          : 50;
  background-image :  radial-gradient(circle farthest-corner at 0% 0%, #ed0d6c, #00457a);
  opacity          : 1;
`;

LoginPage.Container = styled.div`
    position        : absolute;
    left            : 0;
    top             : 0;
    right           : 0;
    bottom          : 0;
    z-index         : 500;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    align-items     : center;
    opacity         : 0;
    transition      : opacity 300ms ease-in;
    opacity         : ${({ loginFormVisible }: { loginFormVisible: boolean }) => loginFormVisible ? 1 : 0};
    
  @media screen and (max-width: 767px) {
    position      : relative;
    margin-top    : 68px;
    margin-bottom : 48px;
  }
`;
