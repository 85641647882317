import styled                                   from "styled-components";
import { DISTANCE_UNITS, MEASUREMENT_SYSTEMS }  from "../../../../../Profile/utils/options";
import { metersToFeet, metersToMiles }          from "../../../../../../utils/conversion_utils";
import { useGetUserSettingsQuery }              from "../../../../../Profile/queries";
/**
 * @description WeekPickerMetrics renders the metrics (distance/ascent/time/rides) inside the week picker.
 * @component
 * @example
 * <WeekPickerMetrics />
 */

export const WeekPickerMetrics = ({ metricsData }: IWeekPickerMetricsProps) => {
  const { data:settings } = useGetUserSettingsQuery();

  return (
    <MetricsWrapper>
      <Metric>
        <MetricDetails>
          <MetricNumberWrapper>
            <MetricNumber>
              {settings?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL
                ? metersToMiles(metricsData.distance)
                : Math.round(metricsData.distance / 1000)}
            </MetricNumber>
            <MetricUnit>
              {settings?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL
                ? DISTANCE_UNITS.MI
                : DISTANCE_UNITS.KM}
            </MetricUnit>
          </MetricNumberWrapper>
          <MetricLabel>Distance</MetricLabel>
        </MetricDetails>
      </Metric>
      <Metric>
        <MetricDetails>
          <MetricNumberWrapper>
            <MetricNumber>
              {settings?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL
                ? Math.round(metersToFeet(metricsData.ascent))
                : Math.round(metricsData.ascent)}
            </MetricNumber>
            <MetricUnit>
              {settings?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL
                ? DISTANCE_UNITS.FT
                : DISTANCE_UNITS.M}
            </MetricUnit>
          </MetricNumberWrapper>
          <MetricLabel>Ascent</MetricLabel>
        </MetricDetails>
      </Metric>
      <Metric>
        <MetricDetails>
          <MetricNumberWrapper>
            <MetricNumber>
              {metricsData.hours}:{metricsData.minutes.toString().length === 1 ? `0${metricsData.minutes}`:metricsData.minutes}
            </MetricNumber>
          </MetricNumberWrapper>
          <MetricLabel>Time</MetricLabel>
        </MetricDetails>
      </Metric>
      <Metric>
        <MetricDetails>
          <MetricNumberWrapper>
            <MetricNumber>{metricsData.rides}</MetricNumber>
          </MetricNumberWrapper>
          <MetricLabel>Rides</MetricLabel>
        </MetricDetails>
      </Metric>
    </MetricsWrapper>
  );
};

interface IWeekPickerMetricsProps {
  metricsData: IMetricsData;
}

export interface IMetricsData {
  distance    : number;
  ascent      : number;
  hours       : number;
  minutes     : number;
  rides       : number;
}

const MetricsWrapper = styled.div`
  display    : flex;
  flex       : 0 0 auto;
  margin-left: 30px;
`; //plan-head-metric-list

const Metric = styled.div`
  margin-right: 40px;
  &:last-of-type {
    margin-right: 0px;
  }
`; //plan-head-li

const MetricDetails = styled.div`
  display        : flex;
  box-sizing     : border-box;
  flex-direction : column;
  align-items    : stretch;
  justify-content: center;
  flex-basis     : auto;
  flex-grow      : 0;
  flex-shrink    : 1;
  font-family    : Inter, sans-serif;
  font-size      : 14px;
  font-weight    : 500;
  line-height    : 21.84px;
`; //li-summary-metric plan-summary

const MetricNumberWrapper = styled.div`
  display      : flex;
  margin-bottom: 0px;
  padding-top  : 3px;
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    align-items    : center;
    justify-content: center;
  }
  ${({theme: {mediaQueries: {md}}}) => md} {
    align-items    : baseline;
    justify-content: start;
  }
`; //summary-metric-content summary

const MetricNumber = styled.div`
  color         : ${({ theme }) => theme.colors.primary.rubineLight};
  margin-right  : 4px;
  line-height   : 124%;
  font-weight   : 700;
  letter-spacing: -0.4px;
  margin-bottom : 0px;
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    font-size  : 16px;
    font-weight: 700;
  }
  ${({theme: {mediaQueries: {md}}}) => md} {
    font-size: 22px;
  }
`; //summary-metric-li type heading-l no-bm- rubine

const MetricUnit = styled.div`
  font-weight: 400;
  color      : ${({ theme }) => theme.colors.primary.rubineLight};
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    font-size  : 14px;
  }
  ${({theme: {mediaQueries: {md}}}) => md} {
    font-size: 16px;
  }
  `; //type rubine body-l no-bm

const MetricLabel = styled.div`
  color         : ${({ theme }) => theme.colors.type.medium};
  line-height   : 140%;
  font-weight   : 500;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    font-size  : 10px;
    font-weight: 600;
    text-align : center;
  }
  ${({theme: {mediaQueries: {md}}}) => md} {
    font-size : 11px;
    text-align: left;
  }

`; //type medium label-m
