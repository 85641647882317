import { useMemo, useContext } from 'react';
import { Grid }                from '@mui/material';
import { SettingsContext }     from '../../../../../containers/SettingsContainer';
import { 
  Dropdown, 
  DropdownOptionModel 
}                              from '../../../../../../../components/Dropdown';
import { FieldWrapper }        from '../../../../FieldWrapper';
import { TitleBlock }          from '../../../../TitleBlock';
import { MONTH_OPTIONS }       from '../../../../../utils/options';
import { privacyTabs }         from '../../../../../utils/tabs';
import { SETTINGS_SECTIONS }   from '../../../../../utils/settingsSections';

export const Age = () => {
  const { setValue, getValues, control } = useContext(SettingsContext);

  const dateBirth = useMemo(
    () => {
      const dateArray = getValues().birthdate ? getValues().birthdate?.split('-') : undefined;

      if (dateArray) {
        const year  = dateArray[0];
        const month = dateArray[1];
        const day   = dateArray[2];


        setValue('monthOfBirth', month);
        setValue('dayOfBirth', day);
        setValue('yearOfBirth', year);

        return { year, month, day }
      }

      return dateArray;
    },
    [getValues, setValue]
  );

  const daysOptions: DropdownOptionModel[] = useMemo(
    () => Array.from(Array(31)).reduce(
      (arr, _value, index) => {
        const day = ++index;
        const minuteValue                       = ('0' + day).slice(-2).toString();
        const minuteOption: DropdownOptionModel = { name: day.toString(), value: minuteValue };
    
        arr.push(minuteOption);
        return arr;
      }, 
      []
    ),
    []
  );

  const yearsOptions: DropdownOptionModel[] = useMemo(
    () => {
      const maxYear = new Date().getFullYear() - 13; // Users may be 13 or older
      const minYear = maxYear - 120;
      const years   = [];

      for (let i = maxYear; i >= minYear; i--) {
        years.push({ name: `${i}`, value: `${i}` })
      }

      return years
    },
    []
  );

  return (
    <FieldWrapper>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={4}>
          <TitleBlock 
            tabs        = {privacyTabs}
            name        = "isBirthdatePrivate"
            label       = {SETTINGS_SECTIONS.birthdate.title}
            description = {SETTINGS_SECTIONS.birthdate.description}
            tooltip     = {SETTINGS_SECTIONS.birthdate.tooltip}
          />
        </Grid>

        <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }} />

        <Grid item xs={12} md={6}>
          <Grid container spacing={2} justifyContent="end">
            <Grid item xs={12} sm={4}>
              <Dropdown 
                label        = "Month"
                options      = {MONTH_OPTIONS}
                defaultValue = {dateBirth?.month ?? ''}
                control      = {control}
                name         = "monthOfBirth"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown 
                label        = "Day"
                options      = {daysOptions}
                defaultValue = {dateBirth?.day ?? ''}
                control      = {control}
                name         = "dayOfBirth"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown 
                label        = "Year"
                options      = {yearsOptions}
                defaultValue = {dateBirth?.year ?? ''}
                control      = {control}
                name         = "yearOfBirth"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FieldWrapper>
  )
};
