import {
  useCallback,
  useState
}                             from 'react';
import styled                 from 'styled-components';
import { Chip }               from '@mui/material';
import { Grid }               from '@mui/material';
import { Modal }              from '@components/Modal';
import { RangedInput }        from '@components/RangedInput';
import {
  feetToMeters,
  milesToMeters
}                             from '@utils/conversion_utils';
import {
  DISTANCE_RANGE,
  DISTANCE_RANGE_MILES,
  DISTANCE_STEP,
  ELEVATION_RANGE,
  ELEVATION_RANGE_FEET,
  ELEVATION_STEP,
  TNumberRange
}                             from '../../../../utils/rangeValues';
import {
  DISTANCE_UNITS,
  MEASUREMENT_SYSTEMS
}                             from '../../../../utils/options';
import { TMeasurementSystem } from '@modules/Profile/models/Settings';
import { ERouteImportSource } from '@models/RouteModel';

export interface IRoutesFilters {
  distance    : TNumberRange;
  elevation   : TNumberRange;
  importSource: ERouteImportSource | null;
}

interface IFilterModalProps {
  isOpen           : boolean;
  measurementSystem: TMeasurementSystem;
  onClose          : () => void;
  onSubmit         : (values: IRoutesFilters) => void;
}

export const RouteFiltersModal = ({
  isOpen,
  measurementSystem,
  onClose,
  onSubmit,
}: IFilterModalProps) => {
  const isImperialSystem = measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL;

  const distanceRange  = isImperialSystem ? DISTANCE_RANGE_MILES : DISTANCE_RANGE;
  const elevationRange = isImperialSystem ? ELEVATION_RANGE_FEET : ELEVATION_RANGE

  const [distance, setDistance]         = useState<TNumberRange>(distanceRange);
  const [elevation, setElevation]       = useState<TNumberRange>(elevationRange);
  const [importSource, setImportSource] = useState<ERouteImportSource | null>(null);

  const handleChangeImportSource = (source: ERouteImportSource) => {
    if (importSource === source) {
      setImportSource(null);
    } else {
      setImportSource(source);
    }
  };

  const handleSave = useCallback(
    () => {
      const distanceValues = isImperialSystem ?
        [milesToMeters(distance[0]), milesToMeters(distance[1])] : [distance[0] * 1000, distance[1] * 1000];

      const elevationValues = isImperialSystem ?
        [feetToMeters(elevation[0]), feetToMeters(elevation[1])] : elevation;

      onSubmit({
        elevation: elevationValues as TNumberRange,
        distance : distanceValues as TNumberRange,
        importSource,
      });
    },
    [
      distance,
      elevation,
      importSource,
      isImperialSystem,
      onSubmit
    ],
  );

  return (
    <Modal
      isOpen         = {isOpen}
      submitBtnLabel = "Save Filters"
      itemName       = "Routes"
      onSave         = {handleSave}
      onClose        = {onClose}
    >
      <RouteFiltersModal.Container container>
        <RouteFiltersModal.TagsContainer item xs={12}>
          <RouteFiltersModal.Tag
            label   = "Ride With GPS"
            variant = {importSource === ERouteImportSource.RideWithGps ? 'outlined' : 'filled'}
            $active = {importSource === ERouteImportSource.RideWithGps}
            onClick = {() => handleChangeImportSource(ERouteImportSource.RideWithGps)}
          />

          <RouteFiltersModal.Tag
            label   = "Imported File"
            variant = {importSource === ERouteImportSource.RouteFile ? 'outlined' : 'filled'}
            $active = {importSource === ERouteImportSource.RouteFile}
            onClick = {() => handleChangeImportSource(ERouteImportSource.RouteFile)}
          />

          <RouteFiltersModal.Tag
            label   = "Ride Generated"
            variant = {importSource === ERouteImportSource.RideGenerated ? 'outlined' : 'filled'}
            $active = {importSource === ERouteImportSource.RideGenerated}
            onClick = {() => handleChangeImportSource(ERouteImportSource.RideGenerated)}
          />
        </RouteFiltersModal.TagsContainer>

        <Grid item xs={12}>
          <RangedInput
            label        = "Distance"
            value        = {distance}
            onChange     = {(_event, value) => setDistance(value as [number, number])}
            step         = {DISTANCE_STEP}
            max          = {distanceRange[1]}
            min          = {distanceRange[0]}
            valuePostfix = {isImperialSystem ? DISTANCE_UNITS.MI : DISTANCE_UNITS.KM}
          />
        </Grid>

        <Grid item xs={12}>
          <RangedInput
            label        = "Elevation"
            value        = {elevation}
            onChange     = {(_event, value) => setElevation(value as [number, number])}
            step         = {ELEVATION_STEP}
            max          = {elevationRange[1]}
            min          = {elevationRange[0]}
            valuePostfix = {isImperialSystem ? DISTANCE_UNITS.FT : DISTANCE_UNITS.M}
          />
        </Grid>
      </RouteFiltersModal.Container>
    </Modal>
  );
};

RouteFiltersModal.Container = styled(Grid)`
  .MuiFormControl-root {
    padding-top    : 24px;
    padding-bottom : 24px;
    border-radius  : 0;
    height         : auto;
  }
`;

RouteFiltersModal.TagsContainer = styled(Grid)`
  display: flex;
  gap    : 16px;
  padding: 16px;
`;

RouteFiltersModal.Tag = styled(Chip)<{ $active?: boolean; }>`
  font-weight : 600;
  color       : ${({ $active, theme: { colors } }) => $active ? colors.primary.rubineDark : colors.type.medium};
  border-color: ${({ $active, theme: { colors } }) => $active ? colors.primary.rubineDark : colors.type.medium};
`;
