import {
  useContext,
  useState,
  useEffect,
}
                               from 'react';
import { Grid, TextField }     from '@mui/material';
import { FieldWrapper }        from '../../../../FieldWrapper';
import { TitleBlock }          from '../../../../TitleBlock';
import { privacyTabs }         from '../../../../../utils/tabs';
import { SETTINGS_SECTIONS }   from '../../../../../utils/settingsSections';
import { SettingsContext }     from '../../../../../containers/SettingsContainer';
import { InputError }          from '../../../../../../../components/InputError';
import { MEASUREMENT_SYSTEMS } from '../../../../../utils/options';
import {
  convertPoundsToKgs,
  convertKgsToPounds
}                              from '../../../../../../../utils/commonFunctions';

export const Weight = () => {
  const {
    register,
    getValues,
    setValue,
    watch,
    formState: {
      errors
    }
  } = useContext(SettingsContext);

  useEffect(
    () => {
      const subscription = watch((values, { name }) => {
        if (name === 'measurementSystem') {
          setMeasurementSystem(values.measurementSystem as string);

          values.measurementSystem === MEASUREMENT_SYSTEMS.METRIC ?
            setValue('weight', Number(convertPoundsToKgs(getValues('weightPounds')).toFixed(1))) :
            setValue('weightPounds', Number((convertKgsToPounds(getValues('weight')).toFixed(1))));
        }
      });

      return () => subscription.unsubscribe();
    },
    [getValues, setValue, watch]
  );

  const [measurementSystem, setMeasurementSystem] = useState<string>(getValues().measurementSystem ?? MEASUREMENT_SYSTEMS.METRIC);

  return (
    <FieldWrapper>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={4}>
          <TitleBlock
            tabs        = {privacyTabs}
            name        = "isWeightPrivate"
            label       = {SETTINGS_SECTIONS.weight.title}
            description = {SETTINGS_SECTIONS.weight.description}
            tooltip     = {SETTINGS_SECTIONS.weight.tooltip}
          />
        </Grid>

        <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }} />

        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} justifyContent="end">
            <Grid item xs={12}>
              {measurementSystem === MEASUREMENT_SYSTEMS.METRIC ? (
                <TextField
                  label        = "Kilograms"
                  variant      = "filled"
                  type         = "number"
                  error        = {!!errors.weight}
                  fullWidth
                  {...register('weight')}
                  defaultValue = {getValues('weight') ? Number(getValues('weight')).toFixed(1) : undefined}
                />
              ) : (
                <TextField
                  label        = "Pounds"
                  variant      = "filled"
                  type         = "number"
                  error        = {!!errors.weight}
                  defaultValue = {getValues('weight') ? Number(convertKgsToPounds(getValues('weight')).toFixed(1)) : undefined}
                  fullWidth
                  {...register('weightPounds')}
                />
              )}
              {errors.weight && <InputError error={errors.weight.message ?? ''} />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FieldWrapper>
  )
}
