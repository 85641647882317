import styled, { css } from 'styled-components';
import {
  Button,
  IconButton
}                      from '@mui/material';
import {
  ArrowBackRounded,
  ArrowForwardRounded,
}                      from '@mui/icons-material';
import { scrimBlack }  from '../../../../styles/theme';
import {
  IMetricsData,
  WeekPickerMetrics,
}                      from './components/WeekPickerMetrics';

/**
 * @description WeekPicker lets you change which week is displayed on the homepage
 * @component
 * @example
 * <WeekPicker />
 */
export const WeekPicker = ({
  activeWeekLabel,
  isCurrentWeekActive,
  metrics,
  onBackClick,
  onForwardClick,
  onTodayClick,
}: WeekPickerProps) => {

  return (
    <WeekPicker.Container>
      <WeekPicker.PlanHeadMetrics>
        <WeekPicker.CalendarDateLabel>
          <WeekPicker.CalendarDatesArrow>
            <WeekPicker.ActiveWeekLabel>
              {activeWeekLabel}
            </WeekPicker.ActiveWeekLabel>
          </WeekPicker.CalendarDatesArrow>
          <WeekPicker.CalendarDateLabelText>
            ride plans during
          </WeekPicker.CalendarDateLabelText>
        </WeekPicker.CalendarDateLabel>
        <WeekPickerMetrics metricsData={metrics} />
      </WeekPicker.PlanHeadMetrics>

      <WeekPicker.PlanHeadCTAs>
        <WeekPicker.CTAButtonWrapper>
          <WeekPicker.IconButton onClick={onBackClick}>
            <ArrowBackRounded />
          </WeekPicker.IconButton>
        </WeekPicker.CTAButtonWrapper>

        <WeekPicker.CTAButtonWrapper>
          <WeekPicker.IconButton onClick={onForwardClick}>
            <ArrowForwardRounded />
          </WeekPicker.IconButton>
        </WeekPicker.CTAButtonWrapper>

        <Button
          variant  = "contained"
          disabled = {isCurrentWeekActive}
          onClick  = {isCurrentWeekActive
            ? () => {}
            : () => onTodayClick()
          }
        >
          Today
        </Button>
      </WeekPicker.PlanHeadCTAs>
    </WeekPicker.Container>
  );
};

type WeekPickerProps = {
  activeWeekLabel    : string;
  isCurrentWeekActive: boolean;
  metrics            : IMetricsData;
  onBackClick        : () => void;
  onForwardClick     : () => void;
  onTodayClick       : () => void;
};

WeekPicker.Container = styled.div`
  display          : flex;
  padding-right    : 6%;
  padding-left     : 6%;
  align-items      : stretch;
  position         : relative;
  z-index          : 20;
  min-height       : auto;
  padding-top      : 32px;
  padding-bottom   : 32px;
  flex-direction   : row;
  justify-content  : space-between;
  background-color : ${({ theme }) => theme.colors.primary.white};
  box-shadow       : 0 4px 16px 0 ${scrimBlack(0.08)}, 0 1px 8px 0 ${scrimBlack(0.04)};
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    flex-direction : column;
    align-items    : center;
    padding        : 20px;
    justify-content: space-between;
    flex-wrap      : nowrap;
  }
  ${({theme: {mediaQueries: {md}}}) => md} {
    flex-direction : row;
    padding-top    : 32px;
    padding-bottom : 32px;
    padding-right  : 4%;
    padding-left   : 4%;
    justify-content: center;
    align-content  : flex-end;
  }
  ${({theme: {mediaQueries: {lg}}}) => lg} {
    padding-left : 6%;
    padding-right: 6%;
  }
  ${({theme: {mediaQueries: {xl}}}) => xl} {
    padding-left : 8%;
    padding-right: 8%;
  }
  ${({theme: {mediaQueries: {xxl}}}) => xxl} {
    padding-left : 16%;
    padding-right: 16%;
  }
`;

WeekPicker.PlanHeadMetrics = styled.div`
  display          : flex;
  align-items      : center;
  align-self       : center;
  flex             : 1;
  line-height      : 21.84px;
  font-weight      : 500;
  @media screen and (max-width: 991px) {
    flex-wrap      : wrap;
    align-content  : flex-start;
  }
  @media screen and (max-width: 767px) {
    flex-direction : column;
    align-items    : flex-start;
    flex           : 0 0 auto;
  }
  @media screen and (max-width: 479px) {
    order          : 2;
    width          : 100%;
    align-items    : center;
    align-content  : center;
    &.sticky {
      margin-left  : -12px;
      align-items  : flex-start;
      align-content: flex-start;
      flex         : 0 auto;
    }
  }
`;

WeekPicker.CalendarDateLabel = styled.div`
  display       : flex;
  flex-direction: column;
  align-self    : center;
  flex          : 0 0 auto;
  min-width     : 287px;
  margin-right  : 24px;
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    align-items : center;
    padding     : 6px;
    margin-right: 0px;
  }
  ${({theme: {mediaQueries: {md}}}) => md} {
    padding    : 0 0 6px 0;
    align-items: flex-start;
  }
  ${({theme: {mediaQueries: {lg}}}) => lg} {
    padding    : 0;
    align-items: flex-start;
  }
`;

WeekPicker.CalendarDatesArrow = styled.div`
  display       : flex;
  flex-direction: column;
  align-items   : flex-start;
`;

WeekPicker.ActiveWeekLabel = styled.div`
  line-height   : 108%;
  font-weight   : 900;
  text-transform: uppercase;
  margin-bottom : 0px;
  ${({theme: {mediaQueries: {xs}}}) => xs} {
    font-size: 20px;
  }
  ${({theme: {mediaQueries: {md}}}) => md} {
    font-size: 28px;
  }
`;

WeekPicker.CalendarDateLabelText = styled.div`
  color         : ${({ theme }) => theme.colors.type.medium};
  font-size     : 11px;
  line-height   : 140%;
  font-weight   : 500;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

WeekPicker.PlanHeadCTAs = styled.div`
  display        : flex;
  justify-content: flex-end;
  align-items    : center;
  @media screen and (max-width: 991px) {
    margin-bottom: 0px;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 0px;
    align-self   : center;
    flex         : 0 0 auto;
  }
  @media screen and (max-width: 479px) {
    order        : 1;
    margin-bottom: 8px;
    &.sticky {
      margin-bottom: 0px;
    }
  }
`;

WeekPicker.CTAButtonWrapper = styled.div`
  margin-right: 12px;
  &:hover {
    cursor: pointer;
  }
`;

WeekPicker.IconButton = styled(IconButton)`
  ${({ theme: { colors } }) => css`
    width     : 40px;
    height    : 40px;
    transition: all 200ms ease-in-out;
    background: linear-gradient(180deg, ${colors.neutralGrey[400]}, ${colors.neutralGrey[500]});

    &:hover {
      box-shadow: 0 4px 16px 0 ${scrimBlack(0.16)};
      transform : scale(1.05);
    }

    svg {
      width : 26px;
      height: 26px;
      fill  : ${colors.type.medium};
    }
  `}
`;
