import { useParams }         from 'react-router-dom';
import { CTA }               from '@modules/CreateModal';
import {
  ICreateModalContent,
  ECtaTab,
}                            from '@modules/CreateModal/models/CreateModalContent';
import { CreateContentMenu } from '@modules/CreateModal/components/CreateContentMenu';
import { CreateRideForm }    from '@modules/CreateModal/components/CreateRideForm';
import { Connections }       from '@modules/CreateModal/components/Connections';
import { InviteFriendForm }  from '@modules/CreateModal/components/InviteFriendForm';
import { MyRoutes }          from '@modules/CreateModal/components/MyRoutes';
import { ShareRide }         from '@modules/CreateModal/components/ShareRide';
import { withAuth }          from '@utils/HOCs/withAuth';

export const CTA_CONTENT: Record<ECtaTab, ICreateModalContent> = {
  [ECtaTab.CTAS]: {
    component : <CreateContentMenu />,
  },
  [ECtaTab.CREATE_RIDE]: {
    component : <CreateRideForm />,
    title     : 'CREATE A RIDE'
  },
  [ECtaTab.EDIT_RIDE]: {
    component : <CreateRideForm />,
    title     : 'EDIT A RIDE'
  },
  [ECtaTab.ADD_CONNECTIONS]: {
    component : <Connections />,
    title     : 'ADD CONNECTIONS',
    backIcon  : true,
  },
  [ECtaTab.INVITE_FRIENDS]: {
    component : <InviteFriendForm rideLink={`${process.env.REACT_APP_PREEM_WEB_URL}/signup`} />,
    title     : 'INVITE FRIENDS',
    backIcon  : true,
  },
  [ECtaTab.MY_ROUTES]: {
    component : <MyRoutes />,
    title     : 'MY ROUTES',
    backIcon  : true,
  },
  [ECtaTab.SHARE_RIDE]: {
    component : <ShareRide />,
    title     : 'SHARE THE RIDE'
  }
}

const CTAContainerBase = () => {
  const { tab } = useParams<{ tab: ECtaTab }>();

  return <CTA tab={tab} />;
};

export const CTAContainer = withAuth(CTAContainerBase);
