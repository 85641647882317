import {
  useState,
  useCallback,
}                         from 'react';
import styled, { css }    from 'styled-components';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Checkbox,
  Menu,
  MenuItem,
  TableCell,
  TableSortLabel,
  Pagination,
  Radio,
  Toolbar,
  Button,
  Box,
}                         from '@mui/material';
import { ArrowDropDown }  from '@mui/icons-material';
import { TOrderSortType } from '@models/types';
import { SearchInput }    from '@components/SearchInput';
import {
  IDropdownOption,
  RoundedDropdownInput,
}                         from '@components/RoundedDropdownInput';
import { LoaderWrapper }  from '@components/LoaderWrapper';

type TAlign     = 'inherit' | 'center' | 'left' | 'right' | 'justify';
type TAlignFlex = 'flex-end' | 'center' | 'flex-start';

export interface IConfigCell<T> {
  key?           : keyof T & string;
  orderBy?       : string;
  imageKey?      : keyof T & string;
  label?         : string;
  render?        : (row: T) => void;
  isBold?        : boolean;
  cellFlexAlign? : TAlignFlex;
  headAlign?     : TAlign;
  width?         : string | number;
}

export interface ITemporaryItem {
 name: string;
 text: JSX.Element | string;
}

export interface IRowAction<T> {
  label   : string;
  onClick : (row: T) => void;
}

export interface ITableAction {
  label   : string;
  onClick : (ids: string[]) => void;
}

interface ITableData<T> {
  data                 : T[];
  configCells          : IConfigCell<T>[];
  temporaryItems?      : ITemporaryItem[];
  emptyTable?          : any;
  tableActions?        : ITableAction[];
  title?               : string;
  isLoading?           : boolean;
  onSelectAll?         : (items: T[]) => void;
  selectedItems?       : string[];
  onSort?              : (orderBy: string, order: TOrderSortType) => void;
  order?               : TOrderSortType;
  orderBy?             : string;
  rowActions?          : IRowAction<T>[];
  onSelectRow?         : (id: string) => void;
  searchText?          : string;
  searchPlaceholder?   : string;
  setSearchText?       : (searchText: string) => void;
  totalPages?          : number;
  totalElements?       : number;
  setPageNumber?       : (pageNumber: number) => void;
  isSingleSelect?      : boolean;
  dropdownItem?        : IDropdownOption | null;
  dropdownOptions?     : IDropdownOption[];
  dropdownPlaceholder? : string;
  onDropdownChange?    : (value: IDropdownOption | null) => void;
  actionButtonLabel?   : string;
  onActionButtonClick? : () => void;
  hideHeader?          : boolean;
}

export const TableData = <T extends { id: string; }>({
  data,
  configCells,
  rowActions,
  onSelectRow,
  orderBy,
  order,
  onSort,
  onSelectAll,
  title,
  isLoading,
  emptyTable,
  totalPages = 0,
  totalElements,
  searchText,
  searchPlaceholder,
  setSearchText,
  setPageNumber,
  tableActions,
  selectedItems = [],
  temporaryItems,
  isSingleSelect = false,
  actionButtonLabel,
  onActionButtonClick,
  dropdownItem,
  dropdownPlaceholder,
  onDropdownChange,
  dropdownOptions,
  hideHeader = false
}: ITableData<T>) => {
  const [tableAnchorEl, setTableAnchorEl]   = useState<null | HTMLElement>(null);
  const [rowAnchorEl, setRowAnchorEl]       = useState<null | HTMLElement>(null);
  const [openedMenuItem, setOpenedMenuItem] = useState<null | number>();
  const openTableAction                     = Boolean(tableAnchorEl);
  const handleClickTableAction              = useCallback(
    (event: any) => {
      setTableAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClickRow = useCallback(
    (event: any, rowIndex: number) => {
      setOpenedMenuItem(rowIndex);
      setRowAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClickRowAction = useCallback(
    (action: IRowAction<T>, row: T) => {
      action.onClick(row);
      setRowAnchorEl(null);
      setOpenedMenuItem(null);
    },
    []
  );

  const handleCloseTableAction = useCallback(
    (_event: any) => {
      setTableAnchorEl(null);
    },
    []
  );

  const handleCloseRowAction = useCallback(
    (_event: any) => {
      setRowAnchorEl(null);
      setOpenedMenuItem(null);
    },
    []
  );

  const handleSort = useCallback(
    (orderBy: string, order: TOrderSortType) => {
      onSort && onSort(orderBy, order);
    },
    [onSort]
  );

  const allColumnsSelected = data.length > 0 && data.length === (selectedItems?.length ?? 0);

  return (
    <TableData.Wrapper>
      <LoaderWrapper isLoading={isLoading ?? false}>
        <TableData.Toolbar sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
          {title && (
            <TableData.Title>
              <strong>{totalElements ?? 0}</strong> {title}
            </TableData.Title>
          )}

          <TableData.ToolbarEndslot sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
            {setSearchText && (
              <SearchInput
                value       = {searchText}
                placeholder = {searchPlaceholder}
                onChange    = {(event) => setSearchText(event.target.value)}
              />
            )}

            {onDropdownChange && (
              <RoundedDropdownInput
                selectedItem = {dropdownItem}
                onChange     = {onDropdownChange}
                options      = {dropdownOptions ?? []}
                placeholder  = {dropdownPlaceholder}
                isClearable
              />
            )}

            {onActionButtonClick && (
              <TableData.ActionButton
                color   = "secondary"
                variant = "contained"
                onClick = {onActionButtonClick}
                size    = "small"
              >
                {actionButtonLabel}
              </TableData.ActionButton>
            )}
          </TableData.ToolbarEndslot>
        </TableData.Toolbar>

        {data.length || temporaryItems?.length ? (
          <>
            <TableData.ContentWrapper>
              <Table sx={{ marginBottom: 10 }} aria-label="customized table">
                {!hideHeader && (
                  <TableData.Head>
                    <TableData.Row>
                      {onSelectAll && onSelectRow && (
                        <TableData.Cell width={70}>
                          <TableData.ActionsContainer>
                            <Checkbox
                              color    = "primary"
                              checked  = {allColumnsSelected}
                              onChange = {() => onSelectAll(allColumnsSelected ? [] : data)}
                            />

                            {tableActions && (
                              <>
                                <ArrowDropDown
                                  onClick = {handleClickTableAction}
                                  id      = "action"
                                />

                                <Menu
                                  id            = "table-actions"
                                  anchorEl      = {tableAnchorEl}
                                  open          = {openTableAction}
                                  onClose       = {handleCloseTableAction}
                                  MenuListProps = {{
                                    'aria-labelledby': 'basic-button',
                                  }}
                                >
                                  {tableActions.map(action => (
                                    <MenuItem
                                      key     = {action.label}
                                      onClick = {() => {
                                        selectedItems.length && action.onClick(selectedItems);
                                        setTableAnchorEl(null);
                                      }}
                                    >
                                      {action.label}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </>
                            )}
                          </TableData.ActionsContainer>
                        </TableData.Cell>
                      )}

                      {!onSelectAll && onSelectRow && (
                        <TableData.Cell width={70} />
                      )}

                      {configCells.map((cell, index) => (
                        <TableData.Cell
                          key           = {cell.key ?? `${index}`}
                          align         = {cell.headAlign ?? 'left'}
                          sortDirection = {cell.orderBy === orderBy ? order : false}
                          $isBold
                        >
                          <TableSortLabel
                            key           = {cell.key ?? `${index}`}
                            IconComponent = {ArrowDropDown}
                            direction     = {order}
                            disabled      = {!cell.orderBy}
                            active        = {Boolean(cell.orderBy) && (orderBy === cell.orderBy)}
                            onClick       = {() => {
                              handleSort(
                                cell.orderBy as string,
                                order === 'asc' ? 'desc' : 'asc'
                              )
                            }}
                          >
                            {cell.label ?? ''}
                          </TableSortLabel>
                        </TableData.Cell>
                      ))}
                    </TableData.Row>
                  </TableData.Head>
                )}

                <TableData.Body>
                  {temporaryItems && temporaryItems.map(({ name, text }) => (
                    <TableData.Row key={name} $isGrey>
                      <TableData.Cell width={70} />

                      <TableData.Cell>
                        <p>{name}</p>
                      </TableData.Cell>

                      <TableData.Cell colSpan={4}>
                        <p>{text}</p>
                      </TableData.Cell>
                    </TableData.Row>
                  ))}

                  {data.map((row, rowIndex) => (
                    <TableData.Row key={row.id ?? `${rowIndex}`}>
                      {onSelectRow && (
                        <TableData.Cell width={70}>
                          <TableData.Container align="flex-start">
                            {isSingleSelect ? (
                              <Radio
                                checked  = {selectedItems?.includes(row.id)}
                                value    = {row.id}
                                onChange = {() => onSelectRow(row.id)}
                              />
                            ) : (
                              <Checkbox
                                color    = "primary"
                                checked  = {selectedItems?.includes(row.id)}
                                onChange = {() => onSelectRow(row.id)}
                              />
                            )}

                            {rowActions && (
                              <>
                                <ArrowDropDown
                                  onClick = {(event) => handleClickRow(event, rowIndex)}
                                  id      = "action"
                                />

                                <Menu
                                  id            = "basic-menu"
                                  anchorEl      = {rowAnchorEl}
                                  open          = {openedMenuItem === rowIndex}
                                  onClose       = {handleCloseRowAction}
                                  MenuListProps = {{
                                    'aria-labelledby': 'basic-button',
                                  }}
                                >
                                  {rowActions.map(action => (
                                    <MenuItem
                                      key     = {action.label}
                                      onClick = {() => handleClickRowAction(action, row)}
                                    >
                                      {action.label}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </>
                            )}
                          </TableData.Container>
                        </TableData.Cell>
                      )}

                      {configCells.map((cell, index) => (
                        <TableData.Cell
                          width   = {cell.width}
                          key     = {cell.key || `${index}`}
                          $isBold = {cell.isBold}
                        >
                          <TableData.Container align={cell.cellFlexAlign ?? 'flex-start'}>
                            {cell.imageKey && <TableData.ImageIcon imageUrl={`https://assets.website-files.com/60c295693abafedfdcaacb0a/${row[cell.imageKey]}`} />}
                            {cell.key && row[cell.key]}
                            {cell.render && cell.render(row)}
                          </TableData.Container>
                        </TableData.Cell>
                      ))}
                    </TableData.Row>
                  ))}
                </TableData.Body>
              </Table>
            </TableData.ContentWrapper>

            {totalPages > 0 && (
              <TableData.PaginationWrapper>
                <Pagination
                  count    = {totalPages}
                  onChange = {(_event, page) => setPageNumber?.(--page)}
                  variant  = "outlined"
                  shape    = "rounded"
                />
              </TableData.PaginationWrapper>
            )}
          </>
        ) : (
          <>
            {emptyTable?.()}
          </>
        )}
      </LoaderWrapper>
    </TableData.Wrapper>
  )
}

TableData.Wrapper = styled.div`
  width         : 100%;
  margin-bottom : 50px;
`;

TableData.ContentWrapper = styled.div`
  overflow-x : auto;
  width      : 100%;
`;

TableData.Head = styled(TableHead)`
  cursor: pointer;
  th {
    background-color : #E8E8E7;
    color            : rgb(119, 120, 122);
    font-size        : 14px;
    line-height      : 14px;
    font-weight      : 700;
    padding          : 5px !important;
    position         : relative;
  }
  th:hover {
    color: ${({ theme }) => theme.colors.primary.rubineLight}
  }

  svg:first-child {
    font-size : 24px;
  }
`;

TableData.Cell = styled(TableCell)<{ $isBold?: boolean }>`
  && {
    padding     : 5px;
    font-weight : ${({ $isBold }) => $isBold ? '700' : '500'};
  }
  svg {
    cursor: pointer;
  }
  svg:hover {
    background-color : ${({ theme }) => theme.colors.neutralGrey[400]};
    border-radius    : 50%;
    box-shadow       : 4px 4px 8px 0px rgba(34, 60, 80, 0);
  }
` as any;

TableData.Row = styled(TableRow)<{ $isGrey?: boolean }>`
  background-color: ${({ $isGrey, theme: { colors } }) => $isGrey ? colors.neutralGrey[100] : colors.primary.white};

  ${({ $isGrey }) => $isGrey && css`
    ${TableData.Cell} {
      font-weight: 600;
    }
  `}
`;

TableData.ImageIcon = styled.div`
  margin-right        : 10px;
  position            : relative;
  z-index             : 100;
  display             : flex;
  overflow            : hidden;
  min-width           : 40px;
  min-height          : 40px;
  justify-content     : center;
  align-items         : center;
  flex                : 0 0 auto;
  border-style        : solid;
  border-width        : 2px;
  border-color        : #fff;
  border-radius       : 100%;
  background-image    : ${({ imageUrl }: { imageUrl: any } ) => `url(${imageUrl})`};
  background-position : 50% 50%;
  background-size     : cover;
  background-repeat   : no-repeat;
  box-shadow          : inset 0 0 0 1px rgb(0 0 0 / 8%), 0 1px 2px 0 rgb(0 0 0 / 4%), 0 4px 8px 0 rgb(0 0 0 / 4%);
`;

TableData.Container = styled.div`
  display         : flex;
  align-items     : center;
  justify-content : ${({ align }: { align: TAlignFlex }) => align || 'flex-start'};

  #action {
    color: #77787A;
  }

  span {
    font-size : 13px;
  }
`;


TableData.PaginationWrapper = styled.div`
  width          : 100%;
  display        : flex;
  justify-content: center;
  align-items    : center;
`;

TableData.ActionsContainer = styled.div`
  display     : flex;
  align-items : center;

  #action {
    color: #77787A;
  }
` as any;

TableData.Body = styled(TableBody)`
  background-color: #FFFFFF;
`;

TableData.Title = styled.p`
  text-align : left;
  margin     : 20px 0 8px;
  font-size  : 18px;
  line-height: 156%;

  strong {
    margin-right  : 5px;
    margin-bottom : 8px;
    font-size     : 22px;
    line-height   : 124%;
    font-weight   : 700;
    letter-spacing: -0.4px;
  }
`;

TableData.Toolbar = styled(Toolbar)`
  width                  : 100%;
  overflow-x             : visible;
  min-height             : fit-content;
  padding                : 5px 0;
  display                : flex;
  justify-content        : space-between;
  border-top-left-radius : 8px;
  border-top-right-radius: 8px;
`;

TableData.ActionButton = styled(Button)`
  &:hover {
    transform: none;
  }
`;

TableData.ToolbarEndslot = styled(Box)`
  height      : 100%;
  width       : fit-content;
  display     : flex;
  align-items : center;
  gap         : 24px;
`;
