import { useMemo } from 'react';
import styled      from 'styled-components';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  // Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart
}                  from 'recharts';
import { 
  useGetUserSettingsQuery
}                  from '@modules/Profile/queries';
import { 
  MEASUREMENT_SYSTEMS 
}                  from '@modules/Profile/utils/options';
import { 
  getDistance,
  getElevation
}                  from '@utils/metrics_utils';

interface IRouteChart {
  colorPoints            : string[];
  linePoints             : number[];
  elevations             : number[];
  distances              : number[];
  minElevation           : number;
  maxElevation           : number;
  distance               : number;
  isReccuringRideOverview: boolean;
}

const Y_TICKS_MIN = 3;

export const RouteChart = ({
  colorPoints,
  linePoints,
  elevations,
  distances,
  minElevation,
  maxElevation,
  isReccuringRideOverview
}: IRouteChart) => {
  const { data: settings } = useGetUserSettingsQuery();

  const routeChart = elevations.reduce((acc: any, cur: any, index: number) => {
    const currentDistance = acc.currentDistance ? acc.currentDistance + distances[index] : distances[index];

    acc.currentDistance = currentDistance;

    if (acc.data) {
      acc.data = [...acc.data, { distance: Math.floor(currentDistance), elevation: cur }]
    } else {
      acc.data = [{ distance: Math.floor(currentDistance), elevation: cur }]
    }

    return acc;
  }, {});

  const yTicks = useMemo(
    () => {
      const differenceMinMax = maxElevation - minElevation;

      const diffRange = differenceMinMax * 2 > Y_TICKS_MIN ? differenceMinMax * 2 : Y_TICKS_MIN;

      return [minElevation, Math.floor(minElevation + diffRange), Math.floor(minElevation + (diffRange * 2)), maxElevation];

    },
    [maxElevation, minElevation]
  );

  const yDomainMin = useMemo(
    () => (value: number) => value - (value * 0.025),
    []
  );

  const yTickFormatter = useMemo(
    () => (value: number) => value === distances[0] ? 
      '' : `${Math.floor(getElevation(settings?.measurementSystem, value))} ${settings?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL ? 'ft' : 'm'}`,
    [distances, settings?.measurementSystem]
  );

  const xTickFormatter = useMemo(
    () => (value: number) => value === distances[0] ? 
      '' : `${Math.floor(getDistance(settings?.measurementSystem, value))} ${settings?.measurementSystem === MEASUREMENT_SYSTEMS.IMPERIAL ? 'mi' : value >= 1000 ? 'km' : 'm'}`,
    [distances, settings?.measurementSystem]
  );


  return (
    <RouteChart.Wrapper isReccuringRideOverview={isReccuringRideOverview}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data        = {routeChart.data}
          margin      = {{ top: 0, left: 0, right: 0, bottom: 0 }}
          style       = {{ backgroundColor: '#F2F2F1', maxWidth: '100%', overflow: 'hidden' }}
          stackOffset = "wiggle"
        >
          <CartesianGrid strokeDasharray="4 0" opacity={0.3} />

          <YAxis
            mirror
            tick          = {{ style: { fontSize: '10px' } }}
            tickSize      = {2}
            tickLine      = {false}
            domain        = {[yDomainMin, 'dataMax']}
            ticks         = {yTicks}
            tickFormatter = {yTickFormatter}
            opacity       = {1}
            padding       = {{ top: 40, bottom: 0 }}
          />

          <XAxis
            mirror
            type          = "number"
            tick          = {{ style: { fontSize: '10px', transform: 'translate(-25px,7px)' } }}
            dataKey       = "distance"
            orientation   = 'top'
            tickLine      = {false}
            domain        = {['dataMin', 'dataMax']}
            tickCount     = {8}
            tickFormatter = {xTickFormatter}
            // padding       = {{ left: 50 }}
          />

          {/* <Tooltip /> */}

          <defs>
            <linearGradient id="colorUv">
              {linePoints.map((point: number, index: number) => <stop key={point} offset={point} stopColor={colorPoints[index]} />)}
            </linearGradient>
          </defs>

          <Area
            type        = "monotone"
            dataKey     = "elevation"
            stroke      = 'url(#colorUv)'
            fill        = "#FFF"
            strokeWidth = {7}
            fillOpacity = {1}
          />
        </AreaChart>
      </ResponsiveContainer>
    </RouteChart.Wrapper>
  );
};

RouteChart.Wrapper = styled.div<{ isReccuringRideOverview: boolean }>`
  width     : 100%;
  height    : ${({ isReccuringRideOverview }) => isReccuringRideOverview ? '30%' : '20%'};
`;
