import { 
  ReactElement, 
  useMemo, 
  useState 
}                                              from 'react';
import styled, { useTheme }                    from 'styled-components';
import { 
  Grid, 
  MenuItem, 
  OutlinedInput, 
  Select, 
  SelectChangeEvent 
}                                              from '@mui/material';
import { scrimBlack, theme }                   from '../../styles/theme';
import { ReactComponent as DropdownArrowIcon } from '../../images/Icon-Dropdown-Arrow-White.svg';
import { ReactComponent as NotGoingIcon }      from '../../images/Icon-RSVP-Not-Going.svg';
import { ReactComponent as MaybeIcon }         from '../../images/Icon-RSVP-Maybe.svg';
import { ReactComponent as GoingIcon }         from '../../images/Icon-RSVP-Going.svg';
import { INVITATION_STATUS }                   from "../../modules/Details/models/invitation";

interface IRespondDropdownProps {
  isOnCard?       : boolean;
  defaultBgColor? : string;
  initialResponse?: INVITATION_STATUS;
  label?          : string;
  onChange?       : (status: INVITATION_STATUS) => void;
}

/**
 * @description Respond is the component that allows a user to respond to event invitations
 * @component
 * @property  {INVITATION_STATUS} [initialResponse]  Set an initial response state
 * @example
 * <Respond
 *  initialResponse={INVITATION_STATUS.ACCEPTED}
 * />
 */
export const RespondDropdown = ({
  initialResponse = INVITATION_STATUS.PENDING,
  label           = "Respond",
  defaultBgColor  = theme.colors.primary.rubineLight,
  isOnCard        = false,
  onChange
}: IRespondDropdownProps) => {
  let [selectedResponse, setSelectedResponse] = useState<INVITATION_STATUS>(initialResponse);
  const theme = useTheme();
  
  const invitationResponses: {[key: string]: {icon: ReactElement, color: string, label: string}} = useMemo(
    () => ({
      [INVITATION_STATUS.PENDING]: {
        label: "Pending",
        icon : <GoingIcon />,
        color: theme.colors.messaging.green
      },
      [INVITATION_STATUS.ACCEPTED]: {
        label: "I'm in",
        icon : <GoingIcon />,
        color: theme.colors.messaging.green
      },
      [INVITATION_STATUS.MAYBE]: {
        label: "Maybe",
        icon : <MaybeIcon />,
        color: theme.colors.secondary.gold
      },    
      [INVITATION_STATUS.DECLINED]: {
        label: "I'm out",
        icon : <NotGoingIcon />,
        color: theme.colors.messaging.red
      },
    }), 
    [theme]
  );

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event?.target?.value as INVITATION_STATUS;
    setSelectedResponse(value);
    onChange && onChange(value);
  };

  const renderDropdownValue = (selected: unknown) => {
    if (selected === INVITATION_STATUS.PENDING) {
      return label;
    }

    const response = invitationResponses[selected as string];

    return (
      <Grid container alignItems="center" columnSpacing="4px" alignContent="center" wrap="nowrap">
        <Grid item>{response?.icon}</Grid>
        <Grid item>{response?.label}</Grid>
      </Grid>
    );
  }

  return (
    <RespondDropdown.Select
      $emptyValue     = {selectedResponse === INVITATION_STATUS.PENDING}
      $defaultBgColor = {defaultBgColor}
      $isOnCard       = {isOnCard}
      defaultValue    = {initialResponse}
      value           = {selectedResponse}
      input           = {<OutlinedInput />}
      inputProps      = {{ 'aria-label': 'Without label' }}
      renderValue     = {renderDropdownValue}
      onChange        = {handleChange}
      IconComponent   = {DropdownArrowIcon}
    >
      {Object.keys(invitationResponses).map((response, index) => {
        const responseItem = invitationResponses[response];

        return (
          <RespondDropdown.RespondItem 
            $doNotRender = {index === 0}
            key          = {response}
            value        = {response}
            $bgColor     = {responseItem.color}
          >
            {responseItem.icon}
            {responseItem.label}
          </RespondDropdown.RespondItem>
        );
      })}
    </RespondDropdown.Select>
  );
};

RespondDropdown.Select = styled(Select)<{$emptyValue: boolean, $defaultBgColor: string, $isOnCard: boolean}>`
  background-color: ${({theme: {colors: {primary: {white}}}, $emptyValue, $defaultBgColor}) => $emptyValue ? $defaultBgColor : white};
  color           : ${({theme: {colors: {primary: {black, white}}}, $emptyValue}) => $emptyValue ? white : black};
  border-radius   : 24px;
  box-shadow      : 0 1px 2px 0 rgb(0 0 0 / 4%), 0 4px 8px 0 rgb(0 0 0 / 4%);

  ${({ $isOnCard, $emptyValue }) => $isOnCard && $emptyValue && `
    background-image: linear-gradient(180deg,#E5E5E4,#BBBBBB);
    box-shadow      : 0 4px 8px 0 rgb(0 0 0 / 4%), 0 1px 2px 0 rgb(0 0 0 / 4%);
    color           : #77787A;
    font-size       : 0.875rem;
    letter-spacing  : 0.02857em;
    font-weight     : 700;

    && .MuiSelect-icon {
      color: #77787A;
    }

    && .MuiSelect-select {
      font-weight   : 700;
      padding-top   : 10px;
      padding-bottom: 10px;
    }

    && path {
      fill: #77787A;
    }
  `}

  .MuiSelect-icon path {
    fill: ${({$emptyValue, theme: {colors: {neutralGrey}}}) => !$emptyValue && neutralGrey[700]};
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  &:hover {
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 16%);
  }
  
  &.MuiSelect-root {
    border-radius: 24px;
    color        : ${({theme: {colors: {primary: {white}}}, $emptyValue}) => $emptyValue && white};
    box-shadow   : ${({$emptyValue}) => !$emptyValue && '0 1px 2px 0 rgb(0 0 0 / 4%), 0 4px 8px 0 rgb(0 0 0 / 4%)'};
  }

  .MuiSelect-icon {
    top: unset;
  }

  .MuiSelect-select {
    padding    : 8px 20px;
    font-size  : 13px;
    font-weight: 600;
    font-family: Inter, sans-serif;
  }

  .MuiGrid-root.MuiGrid-item {
    display: flex;
    &:first-of-type {
      padding-left : 0px;
      padding-right: 2px;
    }
  }
`;

RespondDropdown.RespondItem = styled(MenuItem)<{
  $bgColor     : string;
  $doNotRender?: boolean;
}>`
  &.MuiMenuItem-root.Mui-selected,
  &.MuiMenuItem-root.Mui-selected:hover {
    color           : ${({ theme }) => theme.colors.primary.white};
    background-color: ${({ $bgColor }) => $bgColor};
  }
  &.MuiMenuItem-root:hover {
    background-color: ${scrimBlack(0.08)};
  }
  svg {
    margin-right: 6px;
  }
  /* This is here so we don't render a visible/clickable dropdown menu item for the PENDING state */
  display: ${({ $doNotRender }) => $doNotRender === true && "none"};
`;