import { useContext }            from 'react';
import { Grid }                  from '@mui/material';
import { SettingsContext }       from '../../../../../containers/SettingsContainer';
import { Dropdown }              from '../../../../../../../components/Dropdown';
import { FieldWrapper }          from '../../../../FieldWrapper';
import { TitleBlock }            from '../../../../TitleBlock';
import { NATIONALITIES_OPTIONS } from '../../../../../utils/options';
import { privacyTabs }           from '../../../../../utils/tabs';
import { SETTINGS_SECTIONS }     from '../../../../../utils/settingsSections';

export const Nationality = () => {
  const { control } = useContext(SettingsContext);

  return (
    <FieldWrapper>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={4}>
          <TitleBlock 
            tabs        = {privacyTabs}
            name        = "isNationalityPrivate"
            label       = {SETTINGS_SECTIONS.nationality.title}
            description = {SETTINGS_SECTIONS.nationality.description}
            tooltip     = {SETTINGS_SECTIONS.nationality.tooltip}
          />
        </Grid>

        <Grid item sx={{ display: { xs: 'none', sm: 'block' } }} xs />
        
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} justifyContent="end">
            <Grid item xs={12}>
              <Dropdown 
                label        = "Nationality"
                options      = {NATIONALITIES_OPTIONS}
                control      = {control}
                name         = "nationality"
                fullWidth 
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FieldWrapper>
  );
}
