import { INVITATION_STATUS } from './invitation';
import { IUserBaseInfo }     from '../../Profile/models/UserInfo';
import { IUserLocation }     from '../../Profile/models/UserInfo';

export interface IRideInvitationStatistics {
  accepted: number;
  maybe   : number;
  declined: number;
  pending : number;
}

export enum EManagementType {
  USER  = 'USER',
  GROUP = 'GROUP'
}

export enum EFrequencyType {
  ONCE   = 'ONCE',
  WEEKLY = 'WEEKLY'
}

export enum ERideStatus {
  PLANNED   = 'PLANNED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED'
}

export enum ELocationType {
  OUTDOOR = 'OUTDOOR',
  INDOOR  = 'INDOOR',
  VIRTUAL = 'VIRTUAL',
}

export interface IRide {
  name            : string;
  description     : string;
  rideType        : ERideTypes;
  startLocation   : IUserLocation;
  status          : ERideStatus;
  hasDrops        : boolean;
  intensity       : string;
  frequency       : EFrequencyType;
  hasStops        : boolean;
  canRegroup      : boolean;
  management      : EManagementType;
  isPublic        : boolean;
  timeZoneId      : string;
  date            : string;
  duration?       : number | null;
  routeId?        : string;
  rideLocationType: ELocationType;
  organizer?      : IUserBaseInfo;
  startLocationId?: string;
  id?             : string;
  rideId?         : string;
  inviteeIds?     : string[];
}

export interface INearestRideDetails {
  id                   : string;
  date                 : string;
  attendeesCount       : number;
  status               : ERideStatus;
  invitationStatus?    : INVITATION_STATUS;
  invitationStatistics?: IRideInvitationStatistics;
}

export interface IRidePhoto {
  dateCreated : string;
  fileLink    : string;
  id          : string;
  rideEvent   : {
    date : string;
    id   : string;
  }
  user        : IUserBaseInfo;
}

export interface IRideInvitationDetails extends IRide {
  rideEventDetails: INearestRideDetails;
}

export enum ERideTypes {
  ADAPTIVE     = 'ADAPTIVE',
  BIKE_PACKING = 'BIKE_PACKING',
  CROSS        = 'CROSS',
  GRAVEL       = 'GRAVEL',
  MTB          = 'MTB',
  OTHER        = 'OTHER',
  RECUMBENT    = 'RECUMBENT',
  ROAD         = 'ROAD',
  TOURING      = 'TOURING',
  TRACK        = 'TRACK',
  TRIATHLON    = 'TRIATHLON',
  URBAN        = 'URBAN'
}
