import {
  useState,
  useCallback,
  MouseEvent,
}                                from 'react';
import { useHistory }            from 'react-router-dom';
import styled                    from 'styled-components';
import ReactPlayer               from 'react-player'
import { Gallery }               from 'react-activity-feed';
import { Chip }                  from '@mui/material';
import { PlayCircleFilledWhite } from '@mui/icons-material';
import { IPost }                 from '@models/Feed/post';
import { urlify }                from '@utils/commonFunctions';
import { LinkPreview }           from '../../../LinkPreview';
import { Repost }                from '../Repost';
import 'react-activity-feed/dist/index.css';

interface IContentProps {
  activity: IPost;
}

const MAX_WORDS_COUNT = 100;

export const Content = ({ activity }: IContentProps) => {
  const history                         = useHistory();
  const [showReadMore, setShowReadMore] = useState<boolean>(true);
  const {
    text,
    attachments,
    verb,
    object,
    mentions,
  } = activity;

  const needShortenText = (text ?? '').length > MAX_WORDS_COUNT;
  const shortenedText   = needShortenText ? `${(text ?? '').slice(0, MAX_WORDS_COUNT)}…` : text ?? '';
  const textContent     = (showReadMore ? shortenedText : (text ?? '')).replaceAll('<br>', '');

  const handleReadMore = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      setShowReadMore((prevValue) => !prevValue);
    },
    [],
  );

  return (
    <Content.Wrapper>
      <Content.TextContainer>
        {attachments?.og?.videos && <Content.VideoSourceText>{attachments?.og.site_name}</Content.VideoSourceText>}

        {activity.text && <Content.Text dangerouslySetInnerHTML={{ __html: urlify(textContent) }} />}

        {needShortenText && (
          <Content.ReadMore href="#" onClick={handleReadMore}>
            {showReadMore ? 'Read More' : 'Read Less'}
          </Content.ReadMore>
        )}
      </Content.TextContainer>

      {activity.attachments?.og?.videos && (
        <ReactPlayer
          light
          playIcon = {<Content.PlayIconWrapper><Content.PlayIcon /></Content.PlayIconWrapper>}
          url      = {activity.attachments?.og?.url}
          width    = "100%"
          height   = "450px"
          controls
        />
      )}

      {attachments?.og && !attachments.og.videos && <LinkPreview data={attachments.og} />}

      {!!mentions?.length && (
        <Content.Tags>
          {mentions?.map(({ id, username }) => (
            <Content.UserTag
              key     = {id}
              label   = {`@${username}`}
              onClick = {() => history.push(`/user/${username}`)}
            />
          ))}
        </Content.Tags>
      )}

      {!!attachments?.images?.length && (
          <Gallery images={attachments.images} />
      )}

      {verb === 'repost' &&
        typeof object === 'object' && (
          <Repost activity={activity.object as IPost} />
        )}
    </Content.Wrapper>
  );
};

Content.Wrapper = styled.div`
  background-color: #FFF;
  padding-bottom  : 20px;

  .raf-gallery .img img {
    min-height: 100%;
    cursor    : pointer;
  }
`;

Content.PhotoGallery = styled.div`
  border                : 1px solid #fff;
  display               : -webkit-flex;
  display               : flex;
  -webkit-flex-direction: column;
  flex-direction        : column;
  -webkit-flex-wrap     : wrap;
  flex-wrap             : wrap;
  height                : 300px;
  overflow              : hidden;
  width                 : 100%;
`;

Content.TextContainer = styled.div`
  padding: 20px 20px 15px;
`;

Content.Text = styled.p`
  margin     : 0;
  line-height: 1.5;
  white-space: pre-wrap;

  a {
    color          : ${({ theme }) => theme.colors.neutralBlue[300]};
    font-size      : 14px;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;


Content.ReadMore = styled.a`
  display        : block;
  color          : ${({ theme: { colors } }) => colors.primary.rubineDark};
  font-weight    : 600;
  text-decoration: none;
  margin-top     : 15px;
`;

Content.Tags = styled.div`
  display      : flex;
  gap          : 5px;
  padding      : 10px 15px;
  border-bottom: 1px solid #ebebeb;
`;

Content.UserTag = styled(Chip)`
  font-weight     : 600;
  font-size       : ${({ theme: { fontSizes } }) => fontSizes.s};
  color           : ${({ theme: { colors } }) => colors.type.medium};
  background-color: #ebebeb;
`;

Content.PlayIconWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.rubineLight};
  display         : flex;
  align-items     : center;
  justify-content : center;
  width           : 40px;
  height          : 40px;
  border-radius   : 100%;
`;

Content.PlayIcon = styled(PlayCircleFilledWhite)`
  font-size: 80px;
  fill     : #FFF;
`;

Content.VideoSourceText = styled.p`
  margin-top: 0px;
  font-style: italic;
`;
