import {
  useCallback,
  useEffect,
  useRef,
  useState
}                               from 'react';
import {
  Dialog,
  Grid,
  List
}                               from '@mui/material';
import styled                   from 'styled-components';
import { useForm }              from 'react-hook-form';
import * as yup                 from 'yup';
import {
  Prompt,
  useHistory,
  useLocation,
}                               from 'react-router-dom';
import { yupResolver }          from '@hookform/resolvers/yup';
import { SaveFormMessage }      from '@components/SaveFormMessage';
import { Layout }               from '@components/Layout';
import { ConfirmationModal }    from '@components/ConfirmationModal';
import { Notification }         from '@components/Notification';
import { ChangePhone }          from '@modules/Profile/components/MyAccountContent/ChangePhone';
import { formatPhoneNumber }    from '@utils/commonFunctions';
import { phoneRegex }           from '@utils/string_utils';
import {
  useDeleteCurrentUserMutation,
  useUpdatePasswordMutation,
  useUpdateUserPhoneMutation,
  useVerifyUserPhoneMutation
}                               from '../../queries';
import { SettingsSectionsType } from '../../utils/settingsSections';
import { SettingsContext }      from '../../containers/SettingsContainer';
import { SettingsForm }         from '../../components/forms/Settings';
import {
  ISettingsForm,
  SETTINGS_SCHEMA,
}                               from '../../models/Settings';
import { PageHeader }           from '../../components/PageHeader';
import { PageDescription }      from '../../components/PageDescription';
import { ManageAccount }        from '../../components/MyAccountContent/ManageAccount';
import { ChangePassword }       from '../../components/MyAccountContent/ChangePassword';
import { useAuth }              from '../../../Auth/contexts/AuthContext';

export enum EAccountModalContent {
  Manage,
  ChangePassword,
  ChangePhone,
  DeleteAccount
}

interface ISettingsPageProps {
  onSubmit     : (values: ISettingsForm) => void;
  defaultValues: ISettingsForm;
}

export const SettingsPage = ({
  onSubmit,
  defaultValues,
}: ISettingsPageProps) => {
  const { push, location }                            = useHistory();
  const { signout }                                   = useAuth();
  const [openedSection, setOpenedSection]             = useState<SettingsSectionsType | null>(null);
  const [changedLocation, setChangedLocation]         = useState<string>('');
  const [navigationIsAllowed, setNavigationIsAllowed] = useState<boolean>(false);
  const [mustBeScrolled, setMustBeScrolled]           = useState<boolean>(false);
  const [isPhoneConfirmation, setPhoneConfimation]    = useState<boolean>(false);
  const [accountModalContent, setAccountModalContent] = useState<null | EAccountModalContent>(null);
  const [deleteCurrentUser]                           = useDeleteCurrentUserMutation();
  const [updatePassword]                              = useUpdatePasswordMutation();
  const [updatePhone]                                 = useUpdateUserPhoneMutation();
  const [verifyPhone]                                 = useVerifyUserPhoneMutation();
  const search                                        = useLocation().search;
  const pagePositionParam                             = new URLSearchParams(search).get('position');
  const containerElement                              = useRef<HTMLDivElement | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    watch,
    control,
    reset,
    formState,
    trigger,
    clearErrors
  } = useForm<ISettingsForm>({
    mode             : 'onSubmit',
    reValidateMode   : 'onChange',
    shouldFocusError : true,
    resolver         : yupResolver(SETTINGS_SCHEMA),
    defaultValues,
  });

  const { isSubmitting, isDirty } = formState;

  useEffect(
    () => {
      if (navigationIsAllowed && changedLocation) {
        push(changedLocation);
      }
    },
    [changedLocation, push, navigationIsAllowed]
  );

  const handleSubmitFromModal = useCallback(
    () => {
      handleSubmit(
        (values) => {
          onSubmit(values);
          setNavigationIsAllowed(true);
          reset(values);
        },
        () => setChangedLocation('')
      )();
    },
    [onSubmit, handleSubmit, reset]
  );

  useEffect(
    () => {
      if (pagePositionParam && (pagePositionParam === 'location') && mustBeScrolled) {
        window.scrollTo(0, document.body.scrollHeight);
      }
    },
    [pagePositionParam, mustBeScrolled]
  );

  const setContainerRef = useCallback(
    (ref: HTMLDivElement | null) => {
      containerElement.current = ref;
      setMustBeScrolled(true);
    },
    []
  );

  const toggleManageAccount = useCallback(
    (modalContent?: EAccountModalContent) => {
      const newContent = modalContent ?? EAccountModalContent.Manage;

      setAccountModalContent(content => content === null ? newContent : null);
    },
    [],
  );

  const handleChangePassword = useCallback(
    async () => {
      const password             = getValues().password;
      const confirmPassword      = getValues().confirmPassword;
      const passwordError        = !await yup.string().min(8).required().isValid(password);
      const confirmPasswordError = !await yup.string().min(8).oneOf([password]).required().isValid(confirmPassword);

      if (passwordError) {
        setError('password', { message: "Invalid password" });

        return;
      }

      if (confirmPasswordError) {
        setError('confirmPassword', { message: "Password don't match" });

        return;
      }

      if (password) {
        updatePassword({ newPassword: password }).then(() => {
          setAccountModalContent(null);
        });
      }
    },
    [getValues, setError, updatePassword]
  );

  const handleDeleteUser = useCallback(
    () => {
      deleteCurrentUser().then(() => {
        setAccountModalContent(null);
        signout();
      })
    },
    [deleteCurrentUser, signout]
  );

  const handleChangePhone = useCallback(
    async () => {
      if (!isPhoneConfirmation) {
        const phoneNumber = formatPhoneNumber(getValues('phoneNumber'));

        if (phoneRegex.test(phoneNumber)) {
          clearErrors();

          updatePhone({ phoneNumber })
            .unwrap()
            .then(() => setPhoneConfimation(true));
        } else {
          Notification.enqueueSnackbar('Invalid phone number', 'error');
        }
      } else {
        const code = getValues('code');

        if (code && code.length === 6) {
          verifyPhone({ code })
            .unwrap()
            .then(() => setAccountModalContent(null));
        } else {
          Notification.enqueueSnackbar('Invalid code', 'error');
        }
      }
    },
    [
      clearErrors,
      getValues,
      isPhoneConfirmation,
      updatePhone,
      verifyPhone
    ],
  );

  const handleCancelPhoneUpdate = useCallback(
    () => {
      toggleManageAccount();

      setPhoneConfimation(false);
    },
    [toggleManageAccount],
  );

  return (
    <SettingsContext.Provider
      value={{
        setValue,
        register,
        getValues,
        watch,
        control,
        reset,
        formState,
        trigger,
        toggleManageAccount,
        clearErrors,
        defaultValues,
      }}
    >
      <div ref={setContainerRef}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Layout>
            <SettingsPage.Header>
              <PageHeader>Settings</PageHeader>

              <PageDescription>
                Manage settings to control your online presence and improve your experience in Preem.
              </PageDescription>
            </SettingsPage.Header>

            <SettingsPage.Divider />

            <SettingsForm
              openedSection    = {openedSection}
              setOpenedSection = {setOpenedSection}
            />

          </Layout>

          <Prompt
            when    = {!navigationIsAllowed}
            message = {(newLocation) => {
              if (location.pathname !== newLocation.pathname){
                setChangedLocation(newLocation.pathname);
              }

              return !isDirty;
            }}
          />

          {isDirty  && !changedLocation &&
            <SaveFormMessage
              isLoading = {isSubmitting}
              onSubmit  = {handleSubmitFromModal}
              onCancel  = {() => reset()}
            />
          }

          {changedLocation &&
            <ConfirmationModal
              onSubmit         = {handleSubmitFromModal}
              onCancel         = {() => setNavigationIsAllowed(true)}
              title            = "You have made changes"
              description      = "If you do not  press the “Save Changes” button to confirm your changes they will not be submitted."
              submitButtonText = "Save changes"
              cancelButtonText = "Don’t Save and Leave Page"
            />
          }

          {accountModalContent === EAccountModalContent.Manage && (
            <ConfirmationModal
              onCancel         = {toggleManageAccount}
              title            = "Manage Account"
              description      = "Protect your information like you would protect white cycling shoes"
              cancelButtonText = "Go back to settings"
              content          = {
                <ManageAccount
                  onChangePassword = {() => setAccountModalContent(EAccountModalContent.ChangePassword)}
                  onChangePhone    = {() => setAccountModalContent(EAccountModalContent.ChangePhone)}
                  onDeleteAccount  = {() => setAccountModalContent(EAccountModalContent.DeleteAccount)}
                />
              }
            />
          )}

          {accountModalContent === EAccountModalContent.ChangePassword && (
            <ConfirmationModal
              onSubmit         = {handleChangePassword}
              onCancel         = {toggleManageAccount}
              title            = "Change Password"
              submitButtonText = "Change Password"
              cancelButtonText = "Go back to settings"
              content          = {<ChangePassword />}
            />
          )}

          {accountModalContent === EAccountModalContent.ChangePhone && (
            <ConfirmationModal
              onSubmit         = {handleChangePhone}
              onCancel         = {handleCancelPhoneUpdate}
              title            = {isPhoneConfirmation ? 'Confirm Phone' : 'Change Phone'}
              submitButtonText = {isPhoneConfirmation ? 'Submit Code' : 'Change Phone'}
              cancelButtonText = "Go back to settings"
              content          = {<ChangePhone isConfirmation={isPhoneConfirmation} />}
            />
          )}

          {accountModalContent === EAccountModalContent.DeleteAccount && (
            <ConfirmationModal
              onSubmit         = {handleDeleteUser}
              onCancel         = {toggleManageAccount}
              title            = "Delete Account"
              submitButtonText = "Delete Account"
              cancelButtonText = "Don't delete and go back to settings"
              content          = {
                <Grid container justifyContent="center" sx={{ marginTop: { xs: '-50px', md: '-80px' } }}>
                  <Grid item xs={12} md={10}>
                    <p>
                      Are you sure you want to delete your account?
                    </p>

                    <p>
                      Doing so will delete your profile information, including connections, ride history, rides and routes.
                      This action cannot be undone
                    </p>
                  </Grid>
                </Grid>
              }
            />
          )}
        </form>
      </div>
    </SettingsContext.Provider>
  );
};

SettingsPage.Header = styled.div`
  width: 100%;

  ${({theme: {mediaQueries: {xl}}}) => xl } {
    width: 54%;
  }
`;

SettingsPage.Divider = styled.div`
  width            : 100%;
  margin-top       : 35px;
  height           : 1px;
  background-color : ${({ theme }) => theme.colors.neutralGrey[400]}
`;

SettingsPage.MobileList = styled(List)`
  padding-bottom: 40px;

  padding-bottom: 40px;

  .MuiListItemButton-root {
    padding: 8px 0;
  }

  .MuiListItemIcon-root {
    min-width: unset;
  }

  .MuiTypography-root {
    font-weight: 700;
  }

  &.MuiList-root {
    padding: 0 0 40px;
  }

  .MuiListItem-root {
    padding: 0;
  }
`;

SettingsPage.Dialog = styled(Dialog)`
  .MuiDialogTitle-root {
    background-color : ${({ theme: { colors: { primary: {white} } } }) => white};
    box-shadow       : 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04);
    padding          : 8px 4px;
  }

  .MuiPaper-root {
    background-color: ${({ theme }) => theme.colors.neutralGrey[100]};
  }
`;
