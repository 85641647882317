import { format, add } from 'date-fns';

export const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
export const HOUR_IN_SECONDS     = 60 * 60;

export const getTimeFormat = (timeType?: string) => (timeType === 'HOURS_12') ? "h':'mm a" : 'HH:mm';

export const getRouteDurationByDistance = (
  distance: number,
  speedInKMPH?: number
) => {
  const DEFAULT_SPEED = 24;

  const rideLength        = distance / 1000 / (speedInKMPH ?? DEFAULT_SPEED);
  const rideLengthHours   = Math.trunc(rideLength);
  const rideLengthMinutes = Math.trunc((rideLength % 1) * 60);

  return `${rideLengthHours}:${rideLengthMinutes}`;
};

export const getRideStartEndTime = (
  distance      : number,
  date          : string,
  timeType      = 'HOURS_12',
  speedInKMPH   = 24
): IRideStartEndTime => {
  const durationStr = getRouteDurationByDistance(distance, speedInKMPH);
  const [
    rideLengthHours,
    rideLengthMinutes
  ] = durationStr.split(':').map((duration) => Number(duration));

  const rideStartTime          = getDateUTCFromISO(date);
  const rideStartTimeFormatted = format(rideStartTime, getTimeFormat(timeType));
  const rideEndTime            = add(rideStartTime, { hours: rideLengthHours, minutes: rideLengthMinutes });
  const rideEndTimeFormatted   = format(rideEndTime, getTimeFormat(timeType));
  return {
    rideLengthHours,
    rideLengthMinutes,
    startTime         : rideStartTime,
    startTimeFormatted: rideStartTimeFormatted,
    endTime           : rideEndTime,
    endTimeFormatted  : rideEndTimeFormatted,
  };
};

export const cutDateFromISO = (date: string | number) => {
  if (date) {
    const formattedDate = new Date(date);

    return formattedDate.toISOString().substring(0, 10);
  }

  return '';
}

export const setCurrentTimeToUTC = (date: Date) => {
  const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

  return isoDate;
}

export const getDateUTCFromISO = (date: string) => {
  const startDate = new Date(date);
  // const dateWithoutTimeZoneOffset = new Date(startDate.valueOf() + startDate.getTimezoneOffset() * 60 * 1000);

  return startDate;
}

enum EDayPeriod {
  Morning   = 'morning',
  Afternoon = 'afternoon',
  Evening   = 'evening',
}

export const getDayPeriod = (date: Date): EDayPeriod => {
  const hours = date.getHours();

  if (hours < 12) {
    return EDayPeriod.Morning;
  }

  if (hours < 18) {
    return EDayPeriod.Afternoon;
  }

  return EDayPeriod.Evening;
};

export const hoursToSecs = (hours: number): number => hours * HOUR_IN_SECONDS;
export const minutesToSecs = (minutes: number): number => minutes * 60;

export const secsToHoursAndMinutes = (secs: number): { hours: number; minutes: number; } => {
  const hours   = Math.floor(secs / HOUR_IN_SECONDS);
  const minutes = (secs % HOUR_IN_SECONDS) / 60;

  return { hours, minutes };
};

export interface IRideStartEndTime {
  rideLengthHours   : number;
  rideLengthMinutes : number;
  startTime         : Date,
  startTimeFormatted: string,
  endTime           : Date,
  endTimeFormatted  : string
}
