import {
  useCallback,
  useRef,
  useState
}                 from 'react';
import styled     from 'styled-components';
import {
  Box,
  Collapse,
  TextField,
  TextFieldProps,
  IconButton,
}                 from '@mui/material';
import { Search } from '@mui/icons-material';

type TSearchFieldProps = TextFieldProps & {};

export const SearchInput = (props: TSearchFieldProps) => {
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const inputRef                  = useRef<HTMLInputElement>(null);

  const handleOpen = useCallback(
    () => {
      setExpanded(true);

      inputRef.current?.focus();
    },
    [],
  );

  return (
    <SearchInput.Container alignContent="center" sx={{ height: '100%' }}>
      <SearchInput.Icon onClick={handleOpen}>
        <Search />
      </SearchInput.Icon>

      <Collapse in={isExpanded} orientation="horizontal" timeout={300} sx={{ width: '100%' }}>
        <SearchInput.Field
          {...props}
          inputRef    = {inputRef}
          variant     = "standard"
          onBlur      = {() => !props.value && setExpanded(false)}
          InputProps  = {{
            endAdornment: null,
            ...props.InputProps,
          }}
          fullWidth
        />
      </Collapse>
    </SearchInput.Container>
  )
};

SearchInput.Container = styled(Box)`
  position : relative;
  height   : fit-content;
`;

SearchInput.Field = styled(TextField)`
  input {
    width            : 100%;
    padding-right    : 40px;
    background-color : transparent;
  }
`;

SearchInput.Icon = styled(IconButton)`
  position : absolute;
  bottom   : -5px;
  right    : 0;

  svg {
    width  : 24px;
    height : 24px;
    fill   : ${({ theme: { colors } }) => colors.iconBarBG}; 
  }
`;
