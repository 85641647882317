import { profilePicturesHost, rideEventPhotosHost } from '@config/api_config';

export const getUserAvatarUrl = (settingsUrl?: string | null, currentUserAvatar?: string): string => {
  return settingsUrl ? `${profilePicturesHost}${settingsUrl}` : (currentUserAvatar || '');
};

export const getUploadingProgress = (progressEvent: any) => {
  return Math.round((progressEvent.loaded * 100) / progressEvent.total);
}

export const getImageFromFile = (
  file: File,
  onImageReady: (url: string) => void
) => {
  const reader = new FileReader();

  reader.onloadend = () => {
    if (typeof reader.result === 'string') {
      onImageReady(reader.result);
    }

    onImageReady('');
  };

  reader.readAsDataURL(file)
};

export const getRidePhotoUrl    = (photoUrl: string) => `${rideEventPhotosHost}${photoUrl}`
export const getProfilePhotoUrl = (photoUrl: string) => `${profilePicturesHost}${photoUrl}`;
