import { Tabs, Tab }                        from '@mui/material';
import { useCallback, useState }            from 'react';
import styled                               from 'styled-components';
import { StatisticsPeriod, statisticsTabs } from './model/statistics';

interface IUserStatisticsTabsProps {
  onPeriodChanged: (period: StatisticsPeriod) => void;
}

export const UserStatisticsTabs = ({
  onPeriodChanged
}: IUserStatisticsTabsProps) => {
  const [currentTab, setCurrentTab] = useState(StatisticsPeriod.ALL);

  const changeStatisticsTab = useCallback(
    (_event, value: StatisticsPeriod) => {
      setCurrentTab(value);
      onPeriodChanged(value);
    }, 
    [onPeriodChanged]
  );
  
  return (
    <Tabs
      value    = {currentTab}
      onChange = {changeStatisticsTab}
      variant  = "scrollable"
    >
      {statisticsTabs.map((tab) => (
        <UserStatisticsTabs.Tab key={tab.value} label={tab.label} value={tab.value} />
      ))}
    </Tabs>
  );
}

UserStatisticsTabs.Tab = styled(Tab)`
  &.MuiTab-root {
    padding: 12px 32px;
  }
`;